import { gql } from '@apollo/client';

export const companyLoginMethodQuery = gql`
query CompanyLoginMethod($companyId: ID!) {
  merchantCompany(id: $companyId) {
    id
    loginMethod
    sso {
      config {
        tenantId
        providerId 
      }
    }
  }
}
`;

export const updateCompanyLoginMethodMutation = gql`
mutation UpdateCompanyLoginMethod($companyId: ID!, $input: LoginMethodInput!) {
  updateCompanyLoginMethod(id: $companyId, input: $input) {
    id
    loginMethod
  }
}
`;
import React from 'react';
import {useLazyQuery, useQuery as useApolloQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import InfoTile, { InfoTextRow } from '../../../Tile/Info/Info';
import { orderAuthenticationQuery, activityAuthenticationQuery } from './query';
import AuthenticationSkeleton from './AuthenticationSkeleton';

const Authentication = () => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(activityAuthenticationQuery, {
    variables: {
      orderId,
    }
  });

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(
    orderAuthenticationQuery,
    {
      variables: {
        orderId,
      },
      onCompleted: data => !data.order && fetchInsights(),
    },
  );

  const transaction = orderStack?.last_transaction ?? orderInsights?.last_transaction;

  if (!transaction) return <AuthenticationSkeleton />;

  return (
    <InfoTile name="authentication" title="Authentication">
      <InfoTextRow label="Status" value={transaction.authentication_result} />
      <InfoTextRow label="Version" value={transaction.threeds_data?.version} />
      <InfoTextRow label="Authentication identifier" value={transaction.threeds_data?.x_id} />
      <InfoTextRow label="ECI" value={transaction.threeds_data?.eci} />
      <InfoTextRow label="Issuer" value={transaction.card_issuer} />
      <InfoTextRow
        label="ACS identifier"
        value={transaction.threeds_data?.cavv || transaction.threeds_data?.ucaf_aav || undefined}
      />
      <InfoTextRow label="Directory server identifier" value={transaction.threeds_data?.directory_veres} />
    </InfoTile>
  );
};

export default Authentication;

import React from 'react';
import TextLongIcon from '@2fd/ant-design-icons/lib/TextLong';
import ShimmerIcon from '@2fd/ant-design-icons/lib/Shimmer';

export const TableDisplay = {
  RICH: 'rich',
  TEXT: 'text',
};

const tableDisplays = {
  [TableDisplay.RICH]: { value: TableDisplay.RICH, title: 'Rich display', icon: <ShimmerIcon /> },
  [TableDisplay.TEXT]: { value: TableDisplay.TEXT, title: 'Text display', icon: <TextLongIcon /> },
};

export const tableDisplayList = Object.values(tableDisplays);

export default tableDisplays;

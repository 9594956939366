import React from 'react';
import useFieldAsyncOption from '../../../hooks/useFieldAsyncOption';
import OptionCell from './OptionCell';

const AsyncListTableCell = ({ field, value }) => {
  const { option, loading, error } = useFieldAsyncOption(field, value);
  if (error) return 'err';
  if (loading) return '...';
  if (!option) return null;
  return <OptionCell field={field} option={option} />;
};

export default AsyncListTableCell;

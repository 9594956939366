import React from 'react';
import {useTranslation} from 'react-i18next';
import {getText, getValue} from '../utils';

const UpdateRemoved = ({log}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      Removed:
      <br/>
      {log.log_update.removed.map(rem => (
        <>
          {getText(rem[0], log.log_entity_type, t)}: {getValue(rem[0], log.log_entity_type, t, i18n, rem[1])}
          <br/>
        </>
      ))}
    </>
  );
};

export default UpdateRemoved;
import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Button, DatePicker, Form, Input, message, Select, Space, Tag, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import InfoTile, { InfoDateRow, InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import styles from '../PartnerView.module.scss';
import { useCan } from '../../../../../../contexts/ability.context';
import { CompanyUpdateMutation } from '../../../Company/query';

const CompanyActivity = ({ company }) => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);

  const [updateCompany, { loading }] = useMutation(CompanyUpdateMutation, {
    onCompleted: () => {
      message.success('Company successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    updateCompany({
      variables: { id: company.id, input: values },
    });
  };

  // return edit form tile
  if (editMode) {
    const formCompany = {
      ...company,
      dateStart: moment(company.dateStart),
    };

    return (
      <InfoTile name="company-activity" title="Company">
        <Form
          form={form}
          title="Activity"
          layout="vertical"
          initialValues={formCompany}
          onFinish={handleFinish}
          requiredMark={false}
          className="tile-form"
        >
          <Form.Item label="Company name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Activity" name="activity">
            <Input />
          </Form.Item>
          <Form.Item label="Specialization" name="specialization">
            <Input />
          </Form.Item>
          <Form.Item
            label="MCC"
            name="mcc"
            // eslint-disable-next-line max-len
            tooltip="A Merchant Category Code (MCC) is a four-digit number listed in ISO 18245 for retail financial services. An MCC is used to classify a business by the types of goods or services it provides."
          >
            <Input />
          </Form.Item>
          <Form.Item label="Websites" name="website">
            <Select mode="tags" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="Since" name="dateStart">
            {/* disable futures dates */}
            <DatePicker disabledDate={(current) => current > Date.now()} />
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  // return info tile
  return (
    <InfoTile
      name="company-activity"
      title="Company"
      extra={
        can('update', 'company') && <Button onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
      }
    >
      <InfoTextRow label="Company name" value={company.name} />
      <InfoTextRow label="Activity" value={company.activity} />
      <InfoTextRow label="Specialization" value={company.specialization} />
      <InfoTextRow label="MCC" value={company.mcc} />
      <InfoTextRow
        label="Website"
        value={company.website?.map((url) => (
          <Tag key={url}>
            <Typography.Link href={`https://${url}`} target="_blank">
              {url}
            </Typography.Link>
          </Tag>
        ))}
      />
      <InfoDateRow label="Since" value={company.dateStart} />
    </InfoTile>
  );
};

export default CompanyActivity;

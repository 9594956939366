import React from 'react';
import _ from 'norbr-shared-lib/constants/transaction/status/results/enum';
import statusResults from 'norbr-shared-lib/constants/transaction/status/results';
import LockIcon from '@2fd/ant-design-icons/lib/Lock';
import LockOpenIcon from '@2fd/ant-design-icons/lib/LockOpen';
import LockOpenOutlineIcon from '@2fd/ant-design-icons/lib/LockOpenOutline';

// + icon : Component
const iconByResult = {
  [_.ACCEPTED]: ({ style, ...props }) => <LockIcon {...props} style={{ color: '#389e0d', ...style }} />,
  [_.ATTEMPTED]: ({ style, ...props }) => <LockOpenIcon {...props} style={{ color: '#fa8c16', ...style }} />,
  [_.UNAVAILABLE]: LockOpenOutlineIcon,
  [_.DECLINED]: ({ style, ...props }) => <LockOpenIcon {...props} style={{ color: '#f5222d', ...style }} />,
  // DEPRECATED
  [_.SUCCESS]: ({ style, ...props }) => <LockIcon {...props} style={{ color: '#389e0d', ...style }} />,
  [_.PENDING]: ({ style, ...props }) => <LockOpenIcon {...props} style={{ color: '#fa8c16', ...style }} />,
  [_.MANUAL_REVIEW]: ({ style, ...props }) => <LockOpenIcon {...props} style={{ color: '#fa8c16', ...style }} />,
  [_.NOT_AVAILABLE]: LockOpenOutlineIcon,
  [_.FAILURE]: ({ style, ...props }) => <LockOpenIcon {...props} style={{ color: '#f5222d', ...style }} />,
  [_.EXPIRED]: ({ style, ...props }) => <LockOpenIcon {...props} style={{ color: '#f5222d', ...style }} />,
};

const AUTHENTICATION_RESULTS = [
  _.ACCEPTED,
  _.ATTEMPTED,
  _.UNAVAILABLE,
  _.DECLINED,
]
  .map((key) => statusResults[key])
  .reduce(
    (memo, result) => ({
      ...memo,
      [result.id]: {
        value: result.id,
        label: result.label,
        icon: iconByResult[result.id],
      },
    }),
    {},
  );

const DEPRECATED_AUTHENTICATION_RESULTS = [
  _.SUCCESS,
  _.PENDING,
  _.MANUAL_REVIEW,
  _.NOT_AVAILABLE,
  _.FAILURE,
  _.EXPIRED,
]
  .map((key) => statusResults[key])
  .reduce(
    (memo, result) => ({
      ...memo,
      [result.id]: {
        value: result.id,
        label: result.label,
        icon: iconByResult[result.id],
      },
    }),
    {},
  );

// as list
export const AUTHENTICATION_RESULT_LIST = Object.values(AUTHENTICATION_RESULTS);

export default { ...AUTHENTICATION_RESULTS, ...DEPRECATED_AUTHENTICATION_RESULTS };

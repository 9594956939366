import React, { useEffect, useState } from 'react';
import { Dropdown, Input } from 'antd';
import { search } from '../../../../util/string';
import styles from './MultiSelectDropdown.module.scss';

const MultiSelectDropdown = ({ children, onSelect, onDeselect, options, value, multiple = false }) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearch] = useState();

  useEffect(() => {
    if (!open) {
      setSearch(undefined);
    }
  }, [open]);

  return (
    <Dropdown
      className={styles.dropdown}
      overlayClassName={styles.menu}
      trigger={['click']}
      onOpenChange={setOpen}
      open={open}
      menu={{
        items: [
          {
            key: 'search-input',
            label: (
              <Input
                onKeyDown={(e) => {
                  // stop left and right, allow up and down to navigate in menu
                  if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
                    e.stopPropagation();
                  }
                }}
                bordered={false}
                autoFocus
                placeholder="Search"
                allowClear
                value={searchValue}
                onChange={(e) => setSearch(e.target.value)}
              />
            ),
          },
          ...options.filter((field) => search(searchValue, field.label) || search(searchValue, field.key)),
        ],
        onSelect: ({ key, domEvent }) => {
          domEvent.preventDefault();
          if (key !== 'search-input') {
            onSelect(key);
            if (!multiple) setOpen(false);
          }
        },
        onDeselect: ({ key }) => onDeselect?.(key),
        multiple,
        selectable: true,
        selectedKeys: value,
      }}
      destroyPopupOnHide
    >
      {children}
    </Dropdown>
  );
};

export default MultiSelectDropdown;

import { gql } from '@apollo/client';

const query = `{
  _id
  program_manager {
    displayName
  }
  last_transaction {
    _id
    payment_channel
    status
    is_authorized
    is_cancelled
    amount
    order_merchant_id
    order_id
    currency
    captured_amount
    refunded_amount
    refund_reason
    chargeback_amount
    authentication_result
    createdAt
    updatedAt
    scheduled_capture_date
  }
}`;

export const orderStatusQuery = gql`
  query OrderStatus($orderId: ID!) {
      order(id: $orderId) ${query}
  }
`;

export const activityStatusQuery = gql`
  query ActivityStatus($orderId: ID!) {
      activity(id: $orderId) ${query}
  }
`;

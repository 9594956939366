import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Drawer, Form, Input, message, Select, Space } from 'antd';
import { searchByProp } from '../../../../../util/string';
import { useResetFormOnCloseModal } from '../../../Common/Form/utils';
import { ProgramManagerListQuery } from '../../Company/query';
import ProgramManagerAvatar from '../../../Common/ProgramManager/Avatar';
import useNavigateWithSearch from '../../../../../util/navigate';
import { MerchantGroupCreateMutation, ProgramManagerQuery } from './query';
import styles from './MerchantGroupCreate.module.scss';

const MerchantGroupCreate = ({ visible, onCancel, programManager }) => {
  const navigate = useNavigateWithSearch();

  const [form] = Form.useForm();

  const [selectedProgramManager, setSelectedProgramManager] = useState(programManager);
  const handleProgramManagerChange = (pm) => {
    setSelectedProgramManager(pm);
    form.resetFields(['merchantGroups']);
  };

  const programManagersQuery = useQuery(ProgramManagerListQuery, { skip: !!programManager });
  const programManagerQuery = useQuery(ProgramManagerQuery, {
    variables: { programManager: selectedProgramManager },
    skip: !selectedProgramManager,
  });

  const [create, { loading }] = useMutation(MerchantGroupCreateMutation, {
    refetchQueries: ['MerchantGroupListQuery'],
    onCompleted: ({ createMerchantGroup }) => {
      message.success('Group successfully created');
      navigate(`/merchant-groups/${createMerchantGroup.id}`);
    },
  });

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const submit = (values) => {
    create({
      variables: {
        input: values,
      },
    });
    onCancel();
  };

  return (
    <Drawer
      title="Create Group"
      open={visible}
      onClose={onCancel}
      confirmLoading={loading}
      wrapClassName={styles.modal}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
    >
      <Form form={form} layout="vertical" name="merchantGroupForm" onFinish={submit}>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input maxLength={80} />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input maxLength={255} />
        </Form.Item>
        <Form.Item
          name="programManager"
          label="Program Manager"
          rules={[{ required: true }]}
          initialValue={programManager}
          hidden={!!programManager}
        >
          <Select
            allowClear
            showSearch
            filterOption={searchByProp('label')}
            optionLabelProp="label"
            loading={programManagersQuery.loading}
            onChange={handleProgramManagerChange}
            disabled={!!programManager}
          >
            {programManagersQuery.data?.programManagers?.map((pm) => (
              <Select.Option key={pm.id} value={pm.id} label={pm.name}>
                <Space>
                  <ProgramManagerAvatar programManager={pm} noLink />
                  {pm.name}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="baseMerchantGroup" label="Merchant companies & accounts based on">
          <Select
            allowClear
            showSearch
            filterOption={searchByProp('label')}
            optionLabelProp="label"
            disabled={!selectedProgramManager}
          >
            {programManagerQuery.data?.programManager.merchantGroups.map((mg) => (
              <Select.Option key={mg.id} value={mg.id} label={mg.name}>
                <span title={mg.description}>{mg.name}</span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Space>
            <Button type="text" disabled={loading} onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" size="large" loading={loading}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default MerchantGroupCreate;

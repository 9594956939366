import { gql } from "@apollo/client";

export const removeWebhookHeaderMutation = gql`
  mutation RemoveWebhookHeader ($id: ID!, $headerKey: String!) {
    removeWebhookHeader (id: $id, headerKey: $headerKey) {
      id
      additional_headers
    }
  }
`;

export const updateWebhookHeaderMutation = gql`
  mutation UpdateWebhookHeader ($id: ID!, $input: WebhookHeaderInput!) {
    updateWebhookHeader (id: $id, input: $input) {
      id
      additional_headers
    }
  }
`;
import { CopyOutlined } from '@ant-design/icons';
import { message, Tooltip } from 'antd';
import React from 'react';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import styles from './TooltipWithCopyBtn.module.scss';

const TooltipWithCopyBtn = ({ value, trunc = true }) => {
  const { t } = useTranslation();

  // Custom from/to truncate format: abc...xyz
  const truncFromTo = (str, int = 5) => {
    if (str && str.length > 11) {
      return `${str.substring(0, int)}...${str.substring(str.length - int)}`;
    }
    return str;
  };

  return (
    <Tooltip
      overlayClassName={styles.copyTooltip}
      placement="top"
      title={
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            message.success(t('common.successfullyCopied'));
            copy(value);
          }}
        >
          {value}
          <CopyOutlined />
        </div>
      }
    >
      {trunc ? truncFromTo(value) : value}
    </Tooltip>
  );
};

export default TooltipWithCopyBtn;

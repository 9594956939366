import React, { useEffect, useState } from 'react';
import { Drawer, Form, Radio, Select, Space, Button, message, Divider } from 'antd';
import { useMutation } from '@apollo/client';
import ImgUploader from '../../../../../Common/Uploader/ImgUploader';
import LoaderPreview from './LoaderPreview/LoaderPreview';
import { updateThemeLoaderMutation } from './query';

const LoaderDrawer = ({ visible, onClose, metaProgramManagerId, loader: actualLoader }) => {
  const [jsonError, setJsonError] = useState(false);
  const [jsonFileError, setJsonFileError] = useState(false);
  const [loaderFile, setLoaderFile] = useState(undefined);

  const [loader, setLoader] = useState(actualLoader);

  useEffect(() => {
    setLoader(actualLoader);
  }, [actualLoader]);

  const [updateLoader] = useMutation(updateThemeLoaderMutation, {
    variables: {
      id: metaProgramManagerId,
    },
  });

  const fileToJSON = async (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (event) => resolve(event.target.result);
      fileReader.onerror = (error) => setJsonFileError(true);
      fileReader.readAsText(file);
    });

  useEffect(() => {
    if (loaderFile) {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          setLoader({
            ...loader,
            img: reader.result,
          });
        },
        false,
      );

      if (loaderFile) {
        reader.readAsDataURL(loaderFile);
      }
    }
    setLoader({
      ...loader,
      img: null,
    });
  }, [loaderFile]);

  useEffect(() => {
    if (loader?.type === 'json') {
      try {
        JSON.parse(loader?.img);
        setJsonError(false);
      } catch (e) {
        setJsonError(true);
      }
    }
  }, [loader?.img, loader?.type]);

  return (
    <Drawer open={visible} onClose={onClose}>
      <Form layout="vertical">
        <Form.Item label="Image type">
          <Select
            onChange={(value) =>
              setLoader({
                type: value,
              })
            }
            defaultValue="css"
            value={loader?.type}
            options={[
              {
                label: 'Animated image',
                value: 'css',
              },
              {
                label: 'GIF',
                value: 'gif',
              },
              // Not active yet
              // {
              //   label: 'JSON',
              //   value: 'json'
              // },
            ]}
          />
        </Form.Item>
        {(() => {
          switch (loader?.type) {
            case 'css':
              return (
                <>
                  <Form.Item>
                    <ImgUploader
                      label="Loader icon"
                      value={loader?.img}
                      onChange={setLoaderFile}
                      info="Recommended: SVG, ratio 1/1"
                    />
                  </Form.Item>
                  <Form.Item label="Animation type">
                    <Radio.Group
                      onChange={(event) =>
                        setLoader({
                          ...loader,
                          animation: event.target.value,
                        })
                      }
                      value={loader?.animation}
                    >
                      <Space direction="vertical">
                        <Radio value="rotate">Rotation</Radio>
                        <Radio value="bounce">Bouncing</Radio>
                        <Radio value="heartbeat">HeartBeat</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </>
              );
            case 'gif':
              return (
                <ImgUploader
                  label="Loader icon"
                  value={loader?.img}
                  onChange={setLoaderFile}
                  info="GIF"
                  accept="image/gif"
                />
                // <Upload
                //   label="Loader icon"
                //   accept="image/gif"
                //   customRequest={async ({ file }) => {
                //     setLoaderFile(file);
                //   }}
                //   onRemove={() => setLoaderFile(null)}
                //   value={loader?.img}
                // >
                //   <Button icon={<UploadOutlined />}>Upload</Button>
                // </Upload>
              );
            // Not active yet, CORS issue
            // case 'json':
            //   return (
            //     <Space
            //       direction='vertical'
            //     >
            //       {/*<Form.Item*/}
            //       {/*  label='Paste JSON'*/}
            //       {/*>*/}
            //       {/*  <Input.TextArea*/}
            //       {/*    rows={8}*/}
            //       {/*    value={loader?.img}*/}
            //       {/*    onChange={(event) => setLoader({*/}
            //       {/*      ...loader,*/}
            //       {/*      img: event.target.value,*/}
            //       {/*    })}*/}
            //       {/*    status={jsonError && 'error'}*/}
            //       {/*  />*/}
            //       {/*</Form.Item>*/}
            //       {/*<div>*/}
            //       {/*  Or*/}
            //       {/*</div>*/}
            //       <Upload
            //         label="Load .json file"
            //         accept='application/json'
            //         customRequest={async ({ file }) => {
            //           setLoaderFile(file);
            //           // setLoader({
            //           //   ...loader,
            //           //   img: file(file),
            //           // })
            //         }}
            //       >
            //         <Button icon={<UploadOutlined />}>Upload</Button>
            //       </Upload>
            //     </Space>
            //   )
            default:
              return '';
          }
        })()}
      </Form>
      <Divider />
      <Space
        direction="vertical"
        style={{
          display: 'flex',
        }}
      >
        <div>Preview</div>
        <LoaderPreview loader={loader} />
        {((loader?.type === 'json' && loader?.img) ||
          (loader?.type === 'gif' && loader?.img) ||
          (loader?.type === 'css' && loader?.img && loader?.animation)) &&
          JSON.stringify(loader) !== JSON.stringify(actualLoader) && (
            <Button
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { img, __typename, ...input } = loader;
                if (loaderFile) {
                  input.img = loaderFile;
                }
                updateLoader({
                  variables: {
                    input,
                  },
                }).then(() => {
                  message.success('Loader updated');
                  onClose();
                });
              }}
              block
              type="primary"
            >
              Submit
            </Button>
          )}
      </Space>
    </Drawer>
  );
};

export default LoaderDrawer;

import React, { useEffect } from 'react';
import { Layout as AntLayout } from 'antd';
import styles from './Layout.module.scss';
import LayoutContext from './layout.context';
import Menu from './Menu/Menu';
import Routes from './Routes/Routes';
import useToggle from '../hooks/useToggle';

const Layout = () => {
  const [isMenuCollapsed, { toggle: toggleMenu }] = useToggle(true);

  // dispatch fake resize event, used by dashboard react-grid-layout
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }, [isMenuCollapsed]);

  return (
    <LayoutContext.Provider value={{ isMenuCollapsed, toggleMenu }}>
      <AntLayout className={styles.root}>
        <Menu />
        <Routes />
      </AntLayout>
    </LayoutContext.Provider>
  );
};

export default Layout;

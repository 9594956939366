import React, { useState } from 'react';
import { Button, Form, Input, message, Space, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import InfoTile, { InfoImgRow, InfoRow, InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import styles from '../PartnerView.module.scss';
import { useCan } from '../../../../../../contexts/ability.context';
import { CompanyThemeImgUpload, CompanyThemeUpdate, CompanyUpdateMutation } from '../../../Company/query';
import ColorPicker from '../../../../../ThemeEditor/ColorPicker/ColorPicker';
import ImgUploader from '../../../../Common/Uploader/ImgUploader';

const CompanyMisc = ({ company }) => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);

  const [updateCompany, { loading }] = useMutation(CompanyUpdateMutation, {
    onCompleted: () => {
      message.success('Company successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    updateCompany({
      variables: { id: company.id, input: values },
    });
  };

  const [uploadCompanyThemeImg] = useMutation(CompanyThemeImgUpload, {
    refetchQueries: ['PartnerViewQuery'],
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleUploadIcon = (file) => {
    uploadCompanyThemeImg({ variables: { id: company.id, type: 'icon', file } });
  };

  const [updateCompanyTheme] = useMutation(CompanyThemeUpdate, {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleChangeColor = (color) => {
    updateCompanyTheme({
      variables: {
        id: company.id,
        theme: {
          primaryColor: color,
        },
      },
      optimisticResponse: {
        updateCompanyTheme: {
          __typename: 'PartnerCompany',
          id: company.id,
          theme: {
            ...company.theme,
            primaryColor: color,
          },
        },
      },
    });
  };

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="company-misc" title="Company Info">
        <Form
          form={form}
          title="Misc"
          layout="vertical"
          initialValues={company}
          onFinish={handleFinish}
          requiredMark={false}
          className="tile-form"
        >
          <Space direction="vertical">
            <ImgUploader
              label="Logo"
              value={company.theme?.icon}
              onChange={handleUploadIcon}
              info="Recommended: SVG, ratio 1/1"
            />
            <ColorPicker
              label="Main color"
              value={company.theme?.primaryColor || '#7b7d7d'}
              onChange={handleChangeColor}
            />
          </Space>
          <Form.Item label="Legal Form" name="legalForm">
            <Input />
          </Form.Item>
          <Form.Item label="VAT number" name="vatNumber">
            <Input />
          </Form.Item>
          <Form.Item label="CEO" name="ceo">
            <Input />
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  // return info tile
  return (
    <InfoTile
      name="company-misc"
      title="Company Info"
      extra={
        can('update', 'company') && <Button onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
      }
    >
      <InfoImgRow
        value={company.theme?.icon && /([^/-]*)$/.exec(company.theme?.icon)[1]}
        img={company.theme?.icon}
        label="Logo"
      />
      <InfoRow value={company.theme?.primaryColor} label="Main color">
        <Tag color={company.theme?.primaryColor}>{company.theme?.primaryColor?.toUpperCase()}</Tag>
      </InfoRow>
      <InfoTextRow label="Legal form" value={company.legalForm} />
      <InfoTextRow label="VAT number" value={company.vatNumber} />
      <InfoTextRow label="CEO" value={company.ceo} />
    </InfoTile>
  );
};

export default CompanyMisc;

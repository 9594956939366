import React from 'react';
import _ from 'norbr-shared-lib/constants/transaction/authenticationIndicators/enum';
import AuthenticationIndicatorList from 'norbr-shared-lib/constants/transaction/authenticationIndicators/list';
import LockOutlineIcon from '@2fd/ant-design-icons/lib/LockOutline';
import LockIcon from '@2fd/ant-design-icons/lib/Lock';

const iconByIndicator = {
  [_.NO_3DS]: LockOutlineIcon,
  [_.ASK_3DS]: LockIcon,
  [_.FORCE_3DS]: ({ style, ...props }) => <LockIcon {...props} style={{ color: '#389e0d', ...style }} />,
};

// + icon : Component
const AUTHENTICATION_INDICATORS = AuthenticationIndicatorList.reduce(
  (memo, authenticationIndicator) => ({
    ...memo,
    [authenticationIndicator.id]: {
      ...authenticationIndicator,
      value: authenticationIndicator.id,
      label: authenticationIndicator.label,
      icon: iconByIndicator[authenticationIndicator.id],
    },
  }),
  {},
);

// as list
export const AUTHENTICATION_INDICATOR_LIST = Object.values(AUTHENTICATION_INDICATORS);

export default AUTHENTICATION_INDICATORS;

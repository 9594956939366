import { gql } from '@apollo/client';

export const exportQuery = gql`
  query Export($exportId: ID!) {
    export(id: $exportId) {
      id
      name
      description
      status
      createdAt
      schedule
      format
      delimiter
      sendingMethod
      sftpConnection {
        _id
        username
        host
        port
        sshAlgorithm
        dirname
      }
      reportCount
      reports {
        id
        name
        createdAt
        view
        filters {
          dateFilter
          searchFilters
        }
        fileUrl
        status
        sendingMethod
        delimiter
        format
        fileSize
        rowCount
        expirationDate
        gdpr
      }
      filters {
        dateFilter
        searchFilters
      }
      view
      scheduleCron {
        nextRunAt
        disabled
        failCount
      }
    }
  }
`;

export const runExportMutation = gql`
  mutation RunExportMutation($exportId: ID!) {
    runExport(id: $exportId) {
      id
      name
      status
      schedule
      reports {
        id
        name
        createdAt
        view
        filters {
          dateFilter
          searchFilters
        }
        fileUrl
        status
        sendingMethod
        delimiter
        format
        fileSize
        rowCount
      }
    }
  }
`;

export const pauseExportMutation = gql`
  mutation PauseExportMutation($exportId: ID!) {
    pauseExport(id: $exportId) {
      id
      name
      description
      status
      schedule
      scheduleCron {
        nextRunAt
        disabled
        failCount
      }
    }
  }
`;

export const unpauseExportMutation = gql`
  mutation UnpauseExportMutation($exportId: ID!) {
    unpauseExport(id: $exportId) {
      id
      name
      description
      status
      schedule
      scheduleCron {
        nextRunAt
        disabled
        failCount
      }
    }
  }
`;

export const updateExportMutation = gql`
  mutation UpdateExportMutation($exportId: ID!, $input: ExportInput!) {
    updateExport(id: $exportId, input: $input) {
      id
      name
      description
      status
      schedule
      format
      delimiter
      sendingMethod
      sftpConnection {
        _id
        username
        host
        port
        sshAlgorithm
        dirname
      }
      scheduleCron {
        nextRunAt
        disabled
        failCount
      }
    }
  }
`;

export const deleteExportMutation = gql`
  mutation DeleteExportMutation($exportId: ID!) {
    deleteExport(id: $exportId) {
      id
    }
  }
`;

export const generateReportFileUrlMutation = gql`
  mutation GenerateReportFileUrlMutation($reportId: ID!, $hash: String!) {
    generateReportFileUrl(id: $reportId, hash: $hash)
  }
`;

import {gql} from '@apollo/client';

export const payinMerchantContractsQuery = gql`
  query PayinMerchantContracts($merchantAccount: ID!) {
    merchantAccount (id: $merchantAccount) {
      id
      merchant_contracts (filters: {status: {in: ["active"]}}) {
        id
        name
        partner {
          type   
        }
      }
    }
  } 
`
import { gql } from '@apollo/client';

export const programManagerAvailableServicesQuery = gql`
  query ProgramManagerAvailableServices($id: ID!) {
    programManager(id: $id) {
      id
      availableServices {
        getStatus
        updateToken
        updateOrder
        hostedFields
        hostedPage
        directApiTokenization
        sdk
        cmsModule
      }
    }
  }
`;

export const updateProgramManagerAvailableServicesMutation = gql`
  mutation updateProgramManagerAvailableServices($id: ID!, $input: ProgramManagerAvailableServicesInput!) {
    updateProgramManagerAvailableServices(id: $id, input: $input) {
      id
      availableServices {
        getStatus
        updateToken
        updateOrder
        hostedFields
        hostedPage
        directApiTokenization
        sdk
        cmsModule
      }
    }
  }
`;

const NumberFormats = {
  FR: 'fr',
  US: 'us',
  DE: 'de',
};

export const numberFormats = {
  [NumberFormats.FR]: { value: NumberFormats.FR, label: '12 345', locale: 'fr-FR' },
  [NumberFormats.US]: { value: NumberFormats.US, label: '12,345', locale: 'en-US' },
  [NumberFormats.DE]: { value: NumberFormats.DE, label: '12.345', locale: 'de-DE' },
};

export const numberFormatList = Object.values(numberFormats);

export default NumberFormats;

import React from 'react';
import ListFilter from './ListFilter';
import useListFilter from './useListFilter';

/**
 * HOC for ListFilter + useListFilter
 * @param props
 * @returns {*}
 * @constructor
 */
const ListFilterHOC = (props) => {
  const { children, initialList, options } = props;
  const [filteredList, listFilterProps] = useListFilter(initialList, options);
  return [<ListFilter key="list-filter" {...listFilterProps} />, children(filteredList)];
};

export default ListFilterHOC;

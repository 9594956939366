import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import Item from '../../../../../../Common/SiderList/Item';

const AddableItem = ({ id, label, icon, color, onAdd, extra }) => (
  <Item
    id={id}
    label={label}
    title={label}
    icon={icon}
    color={color}
    onClick={onAdd}
    hoverIcon={<PlusCircleOutlined />}
    extra={extra}
  />
);

export default AddableItem;

import { gql } from '@apollo/client';

export const MetaProgramManagerPartnersQuery = gql`
  query MetaProgramManagerPartners($id: ID!) {
    metaProgramManager(id: $id) {
      id
      name
      theme {
        icon
        primaryColor
        secondaryColor
      }
      activePartners {
        partner {
          id
          name
          type
          company {
            id
            theme {
              icon
            }
          }
        }
        paymentMethods {
          id
          name
          imgUrl
        }
      }
    }
    partners(filters: { status: "active" }) {
      id
      name
      type
      company {
        id
        theme {
          icon
        }
      }
      payment_methods {
        id
        name
        imgUrl
      }
    }
  }
`;

export const PartnersQuery = gql`
  {
    partners {
      id
      name
      type
      company {
        theme {
          icon
        }
      }
    }
  }
`;

export const PartnerPaymentMethodsQuery = gql`
  query PartnerPaymentMethodsQuery($id: ID!) {
    partner(id: $id) {
      type
      payment_methods {
        id
        name
        imgUrl
      }
    }
  }
`;

export const ImpactMerchantContractsQuery = gql`
  query ImpactMerchantContractsQuery($id: ID!, $paymentMethods: [String], $programManager: ID) {
    partner(id: $id) {
      partner_routes(filters: { payment_method: $paymentMethods }) {
        payment_method {
          id
          name
          imgUrl
        }
        merchantRoutes(filters: { programManager: $programManager }) {
          merchant_account {
            id
            name
            company {
              id
              name
              theme {
                icon
              }
            }
            programManager {
              id
              name
              theme {
                icon
                primaryColor
                secondaryColor
              }
            }
          }
          merchant_contract {
            id
            name
          }
        }
      }
    }
  }
`;

export const MetaProgramManagerSetActivePartnerMutation = gql`
  mutation MetaProgramManagerSetActivePartnerMutation($id: ID!, $partner: ID!, $paymentMethods: [String]!) {
    metaProgramManagerSetActivePartner(id: $id, partner: $partner, paymentMethods: $paymentMethods) {
      id
      activePartners {
        partner {
          id
          name
        }
        paymentMethods {
          id
          name
          imgUrl
        }
      }
    }
  }
`;

export const MetaProgramManagerRemoveActivePartnerMutation = gql`
  mutation MetaProgramManagerRemoveActivePartner($id: ID!, $partner: ID!) {
    metaProgramManagerRemoveActivePartner(id: $id, partner: $partner) {
      id
      activePartners {
        partner {
          id
          name
        }
        paymentMethods {
          id
          name
          imgUrl
        }
      }
    }
  }
`;

export default {};

import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { decodeFilter, encodeFilter } from './useSearchFilters';
import useFavoriteView from './useFavoriteView';
import { updatePaginationInSearchParams } from './usePagination';
import usePage from './usePage';

/**
 * Update sort in SearchParams (react-router)
 * @param searchParams
 * @param value
 * @param prefix
 * @returns {Object}
 */
export const updateSortInSearchParams = (searchParams, value, prefix) => {
  return searchParams.set(`${prefix}_sort`, encodeFilter(value));
};

/**
 * Manage Table sort
 */
const useSort = () => {
  const page = usePage();
  const { sort: userSort } = useFavoriteView();

  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(`${page.prefix}_sort`);

  const sort = useMemo(() => (paramValue ? decodeFilter(paramValue) : userSort), [paramValue]);

  const setSort = useCallback(
    (newSort = userSort ?? page.defaultView.sort) => {
      const newSearchParams = new URLSearchParams(searchParams);
      updateSortInSearchParams(newSearchParams, newSort, page.prefix);
      updatePaginationInSearchParams(newSearchParams, 1, null, page.prefix);
      setSearchParams(newSearchParams);
    },
    [searchParams, page],
  );

  useEffect(() => {
    // init search url with default sort
    if (paramValue === null) setSort();
  }, []);

  return [sort, setSort];
};

export default useSort;

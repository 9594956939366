import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Layout, Row, Space, Switch, Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useMutation, useQuery } from '@apollo/client';
import {
  BgColorsOutlined,
  CodeOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  IdcardOutlined,
  TableOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import DomainIcon from '@2fd/ant-design-icons/lib/Domain';
import ViewCarouselIcon from '@2fd/ant-design-icons/lib/ViewCarousel';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { CompanyAuditLogsQuery, CompanyUpdateCheckoutMutation, CompanyViewQuery } from '../query';
import HandleApolloError from '../../../Common/HandleApolloError/HandleApolloError';
import CompanyUserList from './CompanyUserList/CompanyUserList';
import CompanyMerchantList from './CompanyMerchantList/CompanyMerchantList';
import CompanyTheme from './CompanyTheme/CompanyTheme';
import styles from './CompanyView.module.scss';
import CompanyActivity from './CompanyActivity/CompanyActivity';
import CompanyAddress from './CompanyAddress/CompanyAddress';
import CompanyExecutive from './CompanyExecutive/CompanyExecutive';
import CompanyMisc from './CompanyMisc/CompanyMisc';
import { companyStatuses } from '../constants';
import AnchorList from '../../../Common/AnchorList/AnchorList';
import CompanyOverview from './CompanyOverview/CompanyOverview';
import AuditLogs from '../../../Common/Tile/AuditLogs/AuditLogs';
import ConnectAsGuest from './ConnectAsGuest/ConnectAsGuest';
import { useCan } from '../../../../../contexts/ability.context';
import ProgramManagerAvatar from '../../../Common/ProgramManager/Avatar';
import Loader from '../../../Common/Loader/Loader';
import useNavigateWithSearch from '../../../../../util/navigate';
import CompanyContacts from './CompanyContacts/CompanyContacts';
import CompanyLoginMethod from './CompanyLoginMethod/CompanyLoginMethod';
import CompanySso from './CompanySso/CompanySso';
import Error from '../../../Common/Pages/Error';

const { Content, Sider } = Layout;

const anchors = [
  {
    hash: '#tile-company-activity',
    icon: DomainIcon,
    label: 'Activity',
  },
  {
    hash: '#tile-company-address',
    icon: EnvironmentOutlined,
    label: 'Address',
  },
  {
    hash: '#tile-company-executive',
    icon: IdcardOutlined,
    label: 'Executive',
  },
  {
    hash: '#tile-company-contacts',
    icon: IdcardOutlined,
    label: 'Contacts',
  },
  {
    hash: '#tile-company-misc',
    icon: UnorderedListOutlined,
    label: 'Financial',
  },
  {
    hash: '#tile-company-theme',
    icon: BgColorsOutlined,
    label: 'Company Theme',
  },
  {
    hash: '#tile-company-users',
    icon: TeamOutlined,
    label: 'Users',
  },
  {
    hash: '#tile-company-merchant-accounts',
    icon: ViewCarouselIcon,
    label: 'Merchant Accounts',
  },
  {
    hash: '#tile-company-overview',
    icon: TableOutlined,
    label: 'Overview',
  },
  {
    hash: '#tile-entity-logs',
    icon: CodeOutlined,
    label: 'Logs',
  },
];

const breadcrumbRender = (id, name) => () => (
  <Breadcrumb
    map={{
      '/': <HomeOutlined />,
      '/companies': 'Companies',
      [`/companies/${id}`]: name,
    }}
  />
);

const AsyncLogs = () => {
  const { companyId } = useParams();
  const { data, loading } = useQuery(CompanyAuditLogsQuery, {
    variables: {
      id: companyId,
    },
  });
  return loading ? <Loader /> : <AuditLogs entity={data?.merchantCompany} />;
};

const CompanyView = () => {
  const can = useCan();
  const navigate = useNavigateWithSearch();
  const { companyId } = useParams();
  const { data, loading, error } = useQuery(CompanyViewQuery, {
    fetchPolicy: 'cache-first',
    variables: { companyId },
  });

  const [updateCompanyUseCheckout] = useMutation(CompanyUpdateCheckoutMutation, {
    variables: {
      id: companyId,
    },
  });

  if (error) {
    return <Error message={error.message} />;
  }

  if (loading || !data) {
    return (
      <PageHeader
        title="..."
        breadcrumbRender={breadcrumbRender(companyId, '...')}
        onBack={() => navigate('/companies')}
      >
        <Loader size="large" />
      </PageHeader>
    );
  }

  const company = data.merchantCompany;

  return (
    <PageHeader
      title={company.name}
      avatar={{
        src: company.theme.icon,
        icon: <DomainIcon />,
        style: { backgroundColor: company.theme.primaryColor },
      }}
      breadcrumbRender={breadcrumbRender(company.id, company.name)}
      onBack={() => navigate('/companies')}
      tags={
        <Tag key="status-tag" color={companyStatuses[company.status]?.badge}>
          {companyStatuses[company.status]?.label}
        </Tag>
      }
      extra={[
        can('update', 'company') && ['MerchantCompany', 'ProgramManagerCompany'].includes(company.__typename) && (
          <Space key="checkout">
            Uses checkout :
            <Switch
              size="small"
              checked={company.useCheckout}
              onChange={(value) =>
                updateCompanyUseCheckout({
                  variables: {
                    useCheckout: value,
                  },
                  optimisticResponse: {
                    merchantCompany: {
                      id: company.id.toString(),
                      __typename: 'MerchantCompany',
                      useCheckout: value,
                    },
                  },
                })
              }
            />
          </Space>
        ),
        can('create', 'guest_user') && <ConnectAsGuest key="connect-as-guest" company={company} />,
        <ProgramManagerAvatar key="program-manager-avatar" programManager={company.programManager} />,
      ]}
    >
      <HandleApolloError error={error}>
        <Layout className={styles.subPage}>
          <Sider className={styles.anchorSider} width={24}>
            <AnchorList anchors={anchors} />
          </Sider>
          <Content>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <CompanyActivity />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <CompanyAddress />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <CompanyExecutive />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <CompanyContacts />
              </Col>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <CompanyTheme />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <CompanyMisc />
              </Col>
              {can('read', 'company-login') && (
                <>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <CompanyLoginMethod />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <CompanySso />
                  </Col>
                </>
              )}
              {can('read', 'user') && (
                <Col span={24}>
                  <CompanyUserList />
                </Col>
              )}
              {can('read', 'merchant_account') && (
                <Col span={24}>
                  <CompanyMerchantList />
                </Col>
              )}
              {can('read', 'user') && can('read', 'merchant_account') && (
                <Col span={24}>
                  <CompanyOverview />
                </Col>
              )}
              <Col span={24}>
                <AsyncLogs />
              </Col>
            </Row>
          </Content>
        </Layout>
      </HandleApolloError>
    </PageHeader>
  );
};

export default CompanyView;

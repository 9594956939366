import { gql } from '@apollo/client';

export const ProgramManagerMerchantAclProfilesQuery = gql`
  query ProgramManagerMerchantAclProfiles($id: ID!) {
    programManager(id: $id) {
      merchantAclProfiles {
        id
        name
        description
        roles {
          id
        }
        useCount
        programManager {
          id
          name
          theme {
            icon
            primaryColor
            secondaryColor
          }
        }
      }
    }
  }
`;

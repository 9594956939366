import useFieldAsyncOptionList from './useFieldAsyncOptionList';

const useFieldAsyncOption = (field, value) => {
  const { options, loading, error } = useFieldAsyncOptionList(field);
  return {
    option: options?.find((o) => o.value === value),
    loading,
    error,
  };
};

export default useFieldAsyncOption;

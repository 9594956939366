import React from 'react';
import { Button, Col, Row } from 'antd';
import useToggle from '../../../../hooks/useToggle';
import SearchInput from './SearchInput';
import ListFilterForm from './ListFilterForm';

const ListFilter = (props) => {
  const { fields, onSearch, onFiltersChange, searchInputProps } = props;

  // display form fields
  const [visible, { toggle }] = useToggle(false);

  const handleSearch = (e) => onSearch(e.target.value);
  const handleValuesChange = (changedValues) => onFiltersChange(changedValues);

  return [
    <Row key="list-filter-search-input" gutter={8} style={{ marginBottom: 16 }}>
      <Col flex="auto" xl={8} lg={12} md={24}>
        <SearchInput onChange={handleSearch} {...searchInputProps} />
      </Col>
      {!!fields?.length && (
        <Col>
          <Button type="text" onClick={toggle}>
            Advanced search
          </Button>
        </Col>
      )}
    </Row>,
    <ListFilterForm key="list-filter-form" fields={fields} onValuesChange={handleValuesChange} visible={visible} />,
  ];
};

export default ListFilter;

import React from 'react';
import { Form, Input, Select } from 'antd';
import { ExclamationCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { useGdpr } from '../../../../../../contexts/me.context';
import usePage from '../../../hooks/usePage';
import FormItemDate from '../FormItemBuilder/FormItemDate';
import { TargetEntity } from '../../constants/targetEntities';
import { compareByProp } from '../../../../../../util/array';

const DateFilterItemBuilder = React.forwardRef(({ fieldKey }, ref) => {
  const page = usePage();
  const isGdpr = useGdpr();

  const field = page.fields[fieldKey];
  if (!field) return null;

  const disabledByEntity = !page.searchEntities.includes(field.entity);
  const disabledByGdpr = !isGdpr && field.gdpr;
  const disabled = disabledByEntity || disabledByGdpr;

  return (
    <Form.Item
      ref={ref}
      label="Reference date"
      name={['dateFilter']}
      tooltip={{
        title: (
          <span>
            {disabledByEntity && [`Not applicable on ${page.label} module.`, <br />]}
            {disabledByGdpr && [`Not applicable without access to personal data.`, <br />]}
            {field.description}
          </span>
        ),
        icon: disabled ? <ExclamationCircleFilled style={{ color: 'var(--ant-error-color)' }} /> : <InfoCircleFilled />,
      }}
    >
      <Input.Group style={{ display: 'flex', flexDirection: 'column' }}>
        <Form.Item name={['dateFilter', 'key']} noStyle>
          {/**
           Reference date
           Options for reference dates should not include
           financialOperations dates as they are not applicable on Brainpower
          */}
          <Select
            options={Object.values(page.fields)
              .filter(
                (elem) =>
                  elem.type === 'date' &&
                  elem.filter &&
                  page.searchEntities.includes(elem.entity) &&
                  elem.entity !== TargetEntity.FINANCIAL_OPERATION,
              )
              .map((elem) => ({
                value: elem.key,
                label: elem.label,
              }))
              .sort(compareByProp('label'))
          }
            value={fieldKey}
            style={{ marginBottom: 8 }}
          />
        </Form.Item>
        <Form.Item name={['dateFilter', 'value']} noStyle>
          <FormItemDate field={field} />
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );
});

export default DateFilterItemBuilder;

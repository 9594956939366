import React, {useCallback, useMemo, useState} from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Input, Space } from 'antd';
import { useDebounce, useList } from 'react-use';
import moment from 'moment-timezone';
import { gql, useMutation } from '@apollo/client';
import { useMe } from '../../../../../contexts/me.context';
import InfoTile from '../../../Brainpower/OrderDetail/Tile/Info/Info';
import styles from './NotePad.module.scss';
import { search } from '../../../../../util/string';
import { omitTypename } from '../../../../../util/object';
import SortableList from '../../../Common/SortableList/SortableList';
import SortableItem from '../../../Common/SortableList/SortableItem';
import {reorder} from '../../../../../util/array';
import Handle from '../../../Common/SiderList/Handle';

export const SET_NOTEPAD = gql`
  mutation SetNotePad($notePad: [NoteInput]!) {
    setNotePad(notePad: $notePad) {
      id
      notePad {
        title
        content
        createdAt
      }
    }
  }
`;

const NotePad = () => {
  const me = useMe();
  const [value, setValue] = useState();
  const [searchValue, setSearchValue] = useState();
  const initialList = omitTypename(me.notePad);
  const [list, { insertAt, updateAt, removeAt }] = useList(initialList);

  const [setNotePad] = useMutation(SET_NOTEPAD);

  useDebounce(
    () => {
      if (initialList !== list) {
        setNotePad({
          variables: { notePad: list },
          optimisticResponse: { setNotePad: { notePad: list, id: me.id, __typename: 'InternalUser' } },
        });
      }
    },
    400,
    [list],
  );

  const handleCreate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    insertAt(0, {
      title: value,
      content: '',
      createdAt: moment().format(),
    });
    setValue(undefined);
  };

  const handleChangeTitle = (index) => (e) => {
    updateAt(index, {
      ...list[index],
      title: e.target.value,
    });
  };

  const handleChangeContent = (index) => (e) => {
    updateAt(index, {
      ...list[index],
      content: e.target.value,
    });
  };

  const handleDelete = (index) => () => removeAt(index);

  const handleChangeSearch = (e) => setSearchValue(e.target.value);

  const noteList = useMemo(() => omitTypename(me.notePad), [me.notePad]);

  const handleSort = useCallback(
    (sourceIndex, destinationIndex) => {
      const newList = reorder(noteList, sourceIndex, destinationIndex);
      setNotePad({
        variables: { notePad: newList },
        optimisticResponse: { setNotePad: { notePad: newList, id: me.id, __typename: 'InternalUser' } },
      });
    },
    [noteList, setNotePad],
  );

  return (
    <InfoTile
      name="notePad"
      title="My notes"
      extra={[
        <Input.Search
          key="note-search-input"
          allowClear
          onChange={handleChangeSearch}
          className={styles.searchInput}
        />,
      ]}
    >
      <Input
        placeholder="Add note"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onPressEnter={handleCreate}
        className={styles.addInput}
      />
      <SortableList
        className={styles.list}
        pagination={false}
        bordered={false}
        droppableId='notes'
        type='note'
        onSort={handleSort}
      >
        {noteList.map(({ title, content, createdAt }, index) => {
          return (!searchValue || search(searchValue, title) || search(searchValue, content)) && (
            <SortableItem key={`${index}-${createdAt}`} id={index.toString()} dataFormat="availableAccount" index={index} isDragDisabled={false}>
              <Card
                className={styles.noteCard}
                title={<Input defaultValue={title} bordered={false} size="small" onChange={handleChangeTitle(index)} />}
                bordered={false}
                extra={
                  <Space>
                    <span className={styles.noteDate}>{moment(createdAt).calendar()}</span>
                    <Button
                      size="small"
                      icon={<DeleteOutlined />}
                      shape="circle"
                      type="text"
                      onClick={handleDelete(index)}
                    />
                  </Space>
                }
              >
                <div
                  className={styles.cardContent}
                >
                  <Input.TextArea
                    defaultValue={content}
                    autoSize
                    bordered={false}
                    size="small"
                    autoFocus={index === 0 && moment().isSame(createdAt, 'second')} // focus on content for newly created note
                    onChange={handleChangeContent(index)}
                  />
                  <div className={styles.handleIcon}>
                    <Handle/>
                  </div>
                </div>
              </Card>
            </SortableItem>
          )
        })}
      </SortableList>
    </InfoTile>
  );
};

export default NotePad;

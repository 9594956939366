import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, List, Tag, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useQuery } from '@apollo/client';
import { DeleteOutlined, EditOutlined, EyeOutlined, HomeOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { ProfileListQuery, useProfileDeleteMutation } from '../query';
import MetaProgramManagerAccessControlCreate from '../MetaProgramManagerAccessControlCreate/MetaProgramManagerAccessControlCreate';
import { useCan } from '../../../../../contexts/ability.context';
import { useMetaProgramManager } from '../../../../../contexts/metaProgramManager.context';
import MetaProgramManagerBadge from '../../../Common/MetaProgramManager/Badge';

const MetaProgramManagerAccessControlList = () => {
  const can = useCan();
  const metaProgramManager = useMetaProgramManager();
  const { data, loading } = useQuery(ProfileListQuery);

  const [deleteProfile] = useProfileDeleteMutation();

  const [visible, setVisible] = useState(false);

  const showCreationModal = () => {
    setVisible(true);
  };

  const hideCreationModal = () => {
    setVisible(false);
  };

  const handleDelete =
    ({ id, name }) =>
    () => {
      deleteProfile({ id, name });
    };

  return (
    <PageHeader
      title={metaProgramManager ? 'Internal Access Control List' : 'Meta Program Manager Access Control List'}
      breadcrumbRender={() => (
        <Breadcrumb
          map={{
            '/': <HomeOutlined />,
            '/meta-program-access-control-list': metaProgramManager
              ? 'Internal Access Control'
              : 'Meta Program Manager Access Control',
          }}
        />
      )}
      extra={[
        can('create', 'meta-program-manager-access-control') && (
          <Button key="create" icon={<PlusOutlined />} onClick={showCreationModal}>
            Create profile
          </Button>
        ),
        <Link key="overview" to="/meta-program-access-control-list/overview">
          <Button icon={<EyeOutlined />}>Overview</Button>
        </Link>,
      ]}
    >
      <Typography.Paragraph>
        Profiles, roles and privileges defining {metaProgramManager ? 'internal' : 'meta program managers'} users rights.
      </Typography.Paragraph>
      <List
        itemLayout="horizontal"
        dataSource={data?.metaProgramManagerAclProfiles}
        loading={loading}
        bordered
        renderItem={(item) => (
          <MetaProgramManagerBadge metaProgramManager={item.metaProgramManager}>
            <List.Item
              actions={[
                <Tag key="useCount" icon={<UserOutlined />} title={`Used by ${item.useCount} users`}>
                  {item.useCount}
                </Tag>,
                ...(can('update', 'meta-program-manager-access-control')
                  ? [
                      <Link key="edit" to={`/meta-program-access-control-list/${item.id}`}>
                        <Button type="text" shape="circle" icon={<EditOutlined />} title="Edit profile" />
                      </Link>,
                    ]
                  : []),
                ...(can('delete', 'meta-program-manager-access-control')
                  ? [
                      <Button
                        key="delete"
                        type="text"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        title={item.useCount > 0 ? 'Cannot delete used profile' : 'Delete profile'}
                        disabled={item.useCount > 0}
                        onClick={handleDelete(item)}
                      />,
                    ]
                  : []),
              ]}
            >
              <List.Item.Meta
                title={<Link to={`/meta-program-access-control-list/${item.id}`}>{item.name}</Link>}
                description={item.description || item.name}
              />
            </List.Item>
          </MetaProgramManagerBadge>
        )}
      />
      <MetaProgramManagerAccessControlCreate visible={visible} onCancel={hideCreationModal} metaProgramManager={metaProgramManager?.id} />
    </PageHeader>
  );
};

export default MetaProgramManagerAccessControlList;

import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';
import { ApartmentOutlined } from '@ant-design/icons';
import { useProgramManagerId } from '../../../../contexts/app.context';

const ProgramManagerAvatar = ({ programManager, size = 'small', noLink = false }) => {
  const [programManagerId] = useProgramManagerId();
  // displayed only for internals
  if (programManagerId) return null;
  // check exist
  if (!programManager) return null;

  return noLink ? (
    <Avatar
      shape="square"
      title={programManager.name}
      src={programManager.theme.icon}
      icon={<ApartmentOutlined />}
      size={size}
      style={{ backgroundColor: programManager.theme.secondaryColor }}
    />
  ) : (
    <Link to={`/program-managers/${programManager.id}`}>
      <Avatar
        shape="square"
        title={programManager.name}
        src={programManager.theme.icon}
        icon={<ApartmentOutlined />}
        size={size}
        style={{ backgroundColor: programManager.theme.secondaryColor }}
      />
    </Link>
  );
};

export default ProgramManagerAvatar;

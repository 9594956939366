import {gql} from '@apollo/client';

export const CompanyMerchantListQuery = gql`
  query CompanyMerchantListQuery($companyId: ID!) {
    merchantCompany(id: $companyId) {
      id
      merchantAccounts {
        id
        name
        status
        cms_name
        cms_version
        userCount
        source
      }
      programManager {
        id
      }
    }
  }
`;
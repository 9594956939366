import React from 'react';
import CloseCircleIcon from '@2fd/ant-design-icons/lib/CloseCircle';
import styles from './CloseIcon.module.scss';

const CloseIcon = ({ handleClick, count }) => {
  let text = `Close ${count ?? 'all'} messages`;
  if (count === 1) {
    text = 'Close message';
  }
  return (
    <div
      role='presentation'
      className={styles.root}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <span
        className={styles.text}
      >
        {text}
      </span>
      <CloseCircleIcon
        className={styles.icon}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleClick();
        }}
      />
    </div>
  );
}

export default CloseIcon;
import React, { useState } from 'react';
import { Alert, Divider, Drawer, Form, message, Steps } from 'antd';
import { useMutation } from '@apollo/client';
import { useProgramManagerId } from '../../../../../contexts/app.context';
import StepCreateAnnouncementMessage from './StepCreateAnnouncementMessage';
import StepSelectAnnouncementRecipients from './StepSelectAnnouncementRecipients/StepSelectAnnouncementRecipients';
import StepScheduleAnnouncement from './StepScheduleAnnouncement';
import { CreateAnnouncementMutation } from './query';

const CreateAnnouncement = ({ visible, onClose }) => {

  const [programManagerId] = useProgramManagerId();

  const [current, setCurrent] = useState(0);
  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  const [createAnnouncement, { error }] = useMutation(CreateAnnouncementMutation, {
    onCompleted: () => {
      message.success('Announcement successfully created !');
      onClose();
    },
    errorPolicy: 'all',
    refetchQueries: ['AnnouncementsQuery'],
  });

  return (
    <Drawer
      title="Create Announcement"
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
    >
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === 'schedule') {
            const { content, recipients, schedule } = forms;
            createAnnouncement({
              variables: {
                input: {
                  ...(programManagerId ? { programManagers: [programManagerId] } : {}),
                  ...content.getFieldsValue(),
                  ...recipients.getFieldsValue(),
                  ...schedule.getFieldsValue(),
                },
              },
            }).then(() => {
              content.resetFields();
              recipients.resetFields();
              schedule.resetFields();
              setCurrent(0);
            });
          } else {
            next();
          }
        }}
      >
        <Steps current={current} size="small">
          <Steps.Step title="Create Message" />
          <Steps.Step title="Select Recipients" />
          <Steps.Step title="Schedule" />
        </Steps>
        <Divider />
        <StepCreateAnnouncementMessage visible={current === 0} />
        <StepSelectAnnouncementRecipients prev={prev} visible={current === 1} />
        <StepScheduleAnnouncement prev={prev} visible={current === 2} />
      </Form.Provider>
      {error && <Alert type="error" message={error.message} />}
    </Drawer>
  );
};

export default CreateAnnouncement;

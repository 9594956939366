import { gql } from '@apollo/client';

const query = `{
  _id
  program_manager {
    displayName
  }
  transactions {
    _id
    id
    chargeback_date
    payin_partner
    authentication_partner
    risk_assessment_partner
    createdAt
    payment_method_name
    account_number
    status
    status_history {
      status
      date
    }
    order_transaction_number
    operations {
      request_date
      request_status
      result_response
      result_date
      result_status
    }
  }
}`;

export const orderTimelineQuery = gql`
  query OrderTimeline($orderId: ID!) {
    order(id: $orderId) ${query}
  }
`;

export const activityTimelineQuery = gql`
  query ActivityTimeline($orderId: ID!) {
    activity(id: $orderId) ${query}
  }
`;

import {DeploymentUnitOutlined, NodeIndexOutlined} from '@ant-design/icons';
import React from 'react';

const Partners = (can) => {
  if (can('read', 'partner')) {
    return [
      {
        type: 'group',
        label: 'Partners',
        children: [
          { key: '/partners', icon: <DeploymentUnitOutlined />, label: 'Partners' },
          ...(can('read', 'partner-route')
            ? [{ key: '/partner-routes', icon: <NodeIndexOutlined />, label: 'Routes' }]
            : []),
        ],
      },
    ]
  }
  return [];
}

export default Partners;
import React, { useCallback, useContext, useMemo } from 'react';
import { FunctionOutlined } from '@ant-design/icons';
import SortableList from './SortableList/SortableList';
import SortableItem from './SortableList/SortableItem';
import { config as Indicators } from '../../../constants/indicators';
import { reorder } from '../../../../../../../util/array';
import { config as WidgetTypes } from '../../../constants/widgetTypes';
import WidgetContext from './widget.context';

const IndicatorSortableList = () => {
  const { widget, updateWidget, editable } = useContext(WidgetContext);

  const widgetType = useMemo(() => {
    if (widget) {
      if (WidgetTypes[widget.type]) {
        return WidgetTypes[widget.type];
      }
      throw new Error(`Widget type ${widget.type} does not exist. (${widget.i})`);
    }
    return null;
  }, [widget]);

  const updateWidgetIndicators = useCallback(
    (newIndicators) =>
      updateWidget({
        ...widget,
        indicators: newIndicators,
      }),
    [widget, updateWidget],
  );

  const handleRemove = useCallback(
    (removedId) => {
      const newIndicators = widget.indicators.filter((i) => i !== removedId);
      updateWidgetIndicators(newIndicators);
    },
    [widget, updateWidget],
  );

  const handleSort = useCallback(
    (sourceIndex, destinationIndex) => {
      // reorder indicators
      const newIndicators = reorder(widget.indicators, sourceIndex, destinationIndex);
      updateWidgetIndicators(newIndicators);
    },
    [widget, updateWidget],
  );

  const indicators = useMemo(
    () =>
      widget?.indicators.map((i) => {
        if (Indicators[i]) {
          return Indicators[i];
        }
        throw new Error(`Indicator ${i} does not exist. (${widget.i})`);
      }),
    [widget],
  );

  // disable remove button based on min indicators
  const isRemovable = editable && widget?.indicators.length > widgetType.minIndicators;

  return (
    <SortableList
      droppableId="indicators"
      type="indicator"
      label="Indicators"
      separatorIndex={widgetType.maxIndicators}
      onSort={handleSort}
    >
      {indicators.map((indicator, index) => (
        <SortableItem
          key={indicator.id}
          id={indicator.id}
          label={indicator.label}
          color={indicator.color}
          icon={FunctionOutlined}
          index={index}
          onRemove={handleRemove}
          isRemovable={isRemovable}
          isDragDisabled={!editable || widget.indicators.length === 1}
          isDisabled={index >= widgetType.maxIndicators}
        />
      ))}
    </SortableList>
  );
};

export default IndicatorSortableList;

import React from 'react';
import { useQuery } from '@apollo/client';
import { Alert, Avatar, Space } from 'antd';
import { useProgramManagerId } from '../../../../../../contexts/app.context';
import { initials, isValidDomain } from '../../../../../../util/string';
import { MetaProgramManagerUsersQuery } from '../MetaProgramManagerUsers/query';

const ImpactedUsers = ({ metaProgramManager, emailDomains }) => {
  const [programManagerId] = useProgramManagerId();
  const { data } = useQuery(MetaProgramManagerUsersQuery, { variables: { id: metaProgramManager } });

  if (!data) {
    return <Alert type="warning" showIcon message="Suppressing authorized email domains may disable users' access." />;
  }

  const impactedUsers = data.programManager.users.filter((u) => isValidDomain(u.email, emailDomains));

  if (impactedUsers.length === 0) return null;

  if (impactedUsers.length === data.programManager.users.length) {
    return (
      <Alert
        type="warning"
        showIcon
        message={programManagerId ? 'Every user, including yourself, will be locked.' : 'Every user will be locked.'}
      />
    );
  }

  return (
    impactedUsers.length !== 0 && (
      <Alert
        type="warning"
        showIcon
        message={
          <Space>
            <span>{impactedUsers.length} users impacted, will be locked :</span>
            <Avatar.Group maxCount={10} size="small">
              {impactedUsers.map((u) => (
                <Avatar src={u.avatar} title={`${u.full_name} (${u.email})`}>
                  {initials(u.full_name)}
                </Avatar>
              ))}
            </Avatar.Group>
          </Space>
        }
      />
    )
  );
};

export default ImpactedUsers;

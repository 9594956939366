import React from 'react';
import InfoTile, { InfoSkeletonRow } from '../../../Tile/Info/Info';

const BalancesSkeleton = () => (
  <InfoTile name="balances" title="Balances">
    <InfoSkeletonRow label="Capture balance" />
    <InfoSkeletonRow label="Refund balance" />
  </InfoTile>
);

export default BalancesSkeleton;

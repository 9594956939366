import React from 'react';
import InfoTile, { InfoSkeletonRow } from '../../../Tile/Info/Info';

const ShippingDetails = () => (
  <InfoTile name="shipping-details" title="Shipping details">
    <InfoSkeletonRow label="Delivery method" />
    <InfoSkeletonRow label="Street" />
    <InfoSkeletonRow label="ZIP code" />
    <InfoSkeletonRow label="City" />
    <InfoSkeletonRow label="State" />
    <InfoSkeletonRow label="Country" />
    <InfoSkeletonRow label="Email address" />
    <InfoSkeletonRow label="Shipping ID" />
    <InfoSkeletonRow label="Carrier" />
    <InfoSkeletonRow label="Shipping price" />
  </InfoTile>
);

export default ShippingDetails;

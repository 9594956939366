import { useEffect, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import useSearchFilters from '../../hooks/useSearchFilters';
import usePagination from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import useBaseCurrency from '../../hooks/useBaseCurrency';
import useBaseGranularity from '../../hooks/useBaseGranularity';

export const checkoutListQuery = gql`
  query Checkouts($offset: Int, $limit: Int, $filters: [SearchFilter], $sort: [SearchSort]) {
    checkouts(offset: $offset, limit: $limit, filters: $filters, sort: $sort)
  }
`;

const useCheckoutListQuery = () => {
  const { offset, limit } = usePagination();
  const { list } = useSearchFilters();
  const [sort] = useSort();

  const [baseCurrency] = useBaseCurrency();

  const [fetch, { data, previousData, loading, error }] = useLazyQuery(checkoutListQuery, {
    variables: {
      offset,
      limit,
      filters: list,
      sort,
      currency: baseCurrency,
    },
  });

  const [result, total] = useMemo(
    () => [
      (loading
        ? previousData?.checkouts.data?.map((row) => ({ ...row, [Fields.CURRENCY]: row[Fields.CHECKOUT_CURRENCY] }))
        : data?.checkouts.data?.map((row) => ({ ...row, [Fields.CURRENCY]: row[Fields.CHECKOUT_CURRENCY] }))) ?? [],
      (loading ? previousData?.checkouts.count : data?.checkouts.count) ?? [],
    ],
    [data, loading, previousData],
  );

  useEffect(() => {
    fetch();
  }, []);

  return { result, total, loading, error };
};

export default useCheckoutListQuery;

import React from 'react';
import PinIcon from '@2fd/ant-design-icons/lib/Pin';
import styles from './PinBadge.module.scss';

const PinBadge = () => (
  <div title="Pinned as default" className={styles.container}>
    <PinIcon className={styles.pinIcon} />
  </div>
);

export default PinBadge;

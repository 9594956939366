import React, {useContext, useEffect} from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Badge, Space} from 'antd';
import { exportQuery } from '../../Layout/Routes/Brainpower/ExportDetail/query';
import styles from './messages.module.scss';
import CloseIcon from './CloseIcon/CloseIcon';
import MessagesContainer from './MessagesContainer/MessagesContainer';
import {ackMessagesMutation, meDashboardQuery, messageListQuery} from './query';
import {compareByProp} from '../../util/array';


export const MessagesContext = React.createContext(undefined);

export const useMessages = () => useContext(MessagesContext);

const MessagesProvider = ({ children }) => {

  const { data: { messages } = { messages: [] }, startPolling, stopPolling } = useQuery(messageListQuery);

  useEffect(() => {
    startPolling(10000);
    return () => {
      stopPolling();
    }
  }, [startPolling, stopPolling])

  const [refreshExport] = useLazyQuery(exportQuery);
  const [refreshDashboards] = useLazyQuery(meDashboardQuery);

  const [ackMessages] = useMutation(ackMessagesMutation,
    {
      // Remove acked Messages from Apollo cache
      update(cache, { data: { ackMessages: ids } }) {
        ids.forEach((id) => {
          const normalizedId = cache.identify({ id, __typename: 'Message' });
          cache.evict({ id: normalizedId });
        });
        cache.gc();
      },
    },
  );

  // remove obsolete notifications
  const filteredMessages = messages.filter(message => {
    if (message.type === 'dashboard_shared' && !message.content.dashboard) {
      ackMessages({
        variables: {
          messageIds: [message.id]
        }
      })
      return false;
    }
    return true;
  });

  useEffect(() => {
    if (filteredMessages.find(message => message.type === 'report_completed')) {
      refreshExport()
    }
    if (filteredMessages.find(message => message.type === 'dashboard_shared')) {
      refreshDashboards()
    }
  }, [messages]);

  const messagesByTypes = filteredMessages.reduce((acc, message) => ({
    ...acc,
    [message.type]: [
      ...acc[message.type] ?? [],
      message,
    ]
  }), {});

  return (
    <MessagesContext.Provider
      value={{
        allMessages: filteredMessages?.filter((message) => !message.read) ?? [],
        messagesByType: (type) => filteredMessages?.filter((message) => type === message.type) ?? [],
        ack: (messageIds) => {
          if (messageIds.length) {
            ackMessages({
              variables: {
                messageIds,
              },
            });
          }
        },
      }}
    >
      {filteredMessages.length ? (
        <div
          key='messages'
          className={styles.root}
        >
          {Object.keys(messagesByTypes).length > 1 && (
            <div
              className={styles.closeAll}
            >
              <div>
                <Space>Messages <Badge count={filteredMessages.length} className={styles.messageCount}/></Space>
              </div>
              <CloseIcon
                handleClick={() => ackMessages({
                  variables: {
                    messageIds: filteredMessages.map(m => m.id),
                  }
                })}
              />
            </div>
          )}
          {Object.keys(messagesByTypes)
            .sort((a, b) => Math.min(messagesByTypes[a].map(m => m.createdAt)) < Math.min(messagesByTypes[b].map(m => m.createdAt)) ? 1 : -1)
            .map((messageType) => (
              <MessagesContainer
                type={messageType}
                messages={messagesByTypes[messageType].sort(compareByProp('createdAt'))}
                ackMessages={ackMessages}
              />
            )
          )}
        </div>
      ) : null}
      {children}
    </MessagesContext.Provider>
  );
};

export default MessagesProvider;

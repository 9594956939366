import React from 'react';
import { Button, DatePicker, Form, Space } from 'antd';
import moment from 'moment-timezone';
import DateFormats from '../../../../../constants/dateFormats';

const StepScheduleAnnouncement = ({ announcement, visible, prev }) => {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      layout="vertical"
      name="schedule"
      hidden={!visible}
      initialValues={
        announcement && {
          triggerAt: announcement.triggerAt ? moment(announcement.triggerAt) : undefined,
          expireAt: announcement.expireAt ? moment(announcement.expireAt) : undefined,
        }
      }
    >
      <Form.Item
        name="triggerAt"
        label="Trigger at"
        rules={[
          { required: true },
          {
            validator(_, value) {
              if (!value || moment().diff(value) < 0) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(
                  'The announcement will be sent immediately without giving you the option to cancel or update it.',
                ),
              );
            },
            warningOnly: true,
          },
        ]}
        tooltip='Select the moment to trigger the announcement. If you select "Now", the announcement will be sent immediately without giving you the option to cancel or update it.'
      >
        <DatePicker
          format={DateFormats.DATETIME}
          showTime={{ format: 'HH:mm', defaultValue: moment('00:00', 'HH:mm') }}
          disabledDate={(current) => current < moment().startOf('day')}
        />
      </Form.Item>
      <Form.Item
        name="expireAt"
        label="Expiration date"
        tooltip="Select when the announcement will expire. If some users have not read it before, they will no longer receive the announcement."
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || value.isAfter(getFieldValue('triggerAt'))) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The expiration date must be later than the trigger date.'));
            },
          }),
        ]}
      >
        <DatePicker
          format={DateFormats.DATETIME}
          showTime={{ format: 'HH:mm', defaultValue: moment('00:00', 'HH:mm') }}
          disabledDate={(current) => current < Date.now()}
        />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="default" onClick={prev}>
            Previous
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default StepScheduleAnnouncement;

import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styles from './Markdown.module.scss';

const _ = ({ children }) => (
  <Markdown className={styles.markdownBody} remarkPlugins={[remarkGfm]}>
    {children}
  </Markdown>
);

export default _;

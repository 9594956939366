import { gql } from '@apollo/client';

export const UserListQuery = gql`
  query UserListQuery {
    merchantUsers {
      id
      email
      full_name
      first_name
      last_name
      avatar
      status
      disabled
      createdAt
      lastConnection
      companies {
        company {
          id
          name
          status
          theme {
            icon
            primaryColor
            secondaryColor
          }
        }
        aclProfile {
          id
          name
        }
        isGdpr
        authorizedMerchantAccountsCount
      }
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
        metaProgramManager {
          id
          name
          theme {
            icon
            primaryColor
            secondaryColor
          }
        }
      }
    }
  }
`;

export default {};

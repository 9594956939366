import { useMemo, useState } from 'react';

/**
 * Hook to manage filters from ListFilter component
 * @param initialList
 * @param options
 * @returns {[unknown, {searchInput, onSearch: (function(*=): void), fields, onFiltersChange: (function(*): void)}]}
 */
const useListFilter = (initialList = [], options = {}) => {
  const { searchInput, fields, sorter } = options;

  const { filter: searchFilter, ...searchInputProps } = searchInput;

  const [filters, setFilters] = useState({});

  const filteredList = useMemo(() => {
    // no data / filters > skip filter
    if (initialList.length === 0 || Object.keys(filters).length === 0) {
      return [...initialList].sort(sorter);
    }

    // apply filter for every fields + search input
    return initialList
      .filter(
        (item) =>
          fields.every((field) => {
            if (!filters[field.name]) return true;
            return field.filter(filters[field.name], item);
          }) && searchFilter(filters.search, item),
      )
      .sort(sorter);
  }, [initialList, filters, sorter]);

  const onSearch = (value) => setFilters((prev) => ({ ...prev, search: value }));
  const onFiltersChange = (value) => setFilters((prev) => ({ ...prev, ...value }));

  // props to forward to ListFilter
  const listFilterProps = { searchInputProps, onSearch, fields, onFiltersChange };

  return [filteredList, listFilterProps];
};

export default useListFilter;

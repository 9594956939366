import { gql } from '@apollo/client';

export const createExportMutation = gql`
  mutation CreateExportMutation($input: ExportInput!) {
    createExport(input: $input) {
      id
      name
      description
      status
      schedule
      sendingMethod
      reportCount
    }
  }
`;

export default {};

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, message, Radio, Select, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import referenceCurrencies from 'norbr-shared-lib/constants/referenceCurrencies';
import { useTranslation } from 'react-i18next';
import { cmsList, getCms } from '../../../../../../constants/CMSS';
import InfoTile, { InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { MerchantAccountUpdateMutation } from '../query';
import styles from '../MerchantAccountView.module.scss';
import { getIntegrationType, integrationTypeList } from '../../../../../../constants/INTEGRATION_TYPES';
import { useCan } from '../../../../../../contexts/ability.context';
import SOURCES, { SOURCE_LIST } from '../../../../../../constants/SOURCES';

const AccountInformation = ({ merchantAccount }) => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();

  const [updateMerchantAccount, { loading }] = useMutation(MerchantAccountUpdateMutation, {
    onCompleted: () => {
      message.success('Merchant account successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    updateMerchantAccount({
      variables: { id: merchantAccount.id, input: values },
    });
  };

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="ma-information" title="Account Information">
        <Form
          form={form}
          title="Activity"
          layout="vertical"
          initialValues={merchantAccount}
          onFinish={handleFinish}
          // requiredMark={false}
          className="tile-form"
        >
          <Form.Item
            label="Account name"
            name="name"
            rules={[
              {
                required: merchantAccount.attributeListRequiredByPartners.includes('merchantAccount.name'),
                message: 'Name is required by a partner configuration.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="CMS name"
            name="cms_name"
            rules={[
              {
                required: merchantAccount.attributeListRequiredByPartners.includes('merchantAccount.cms_name'),
                message: 'CMS name is required by a partner configuration.',
              },
            ]}
          >
            <Select options={cmsList} />
          </Form.Item>
          <Form.Item
            label="CMS version"
            name="cms_version"
            rules={[
              {
                required: merchantAccount.attributeListRequiredByPartners.includes('merchantAccount.cms_version'),
                message: 'CMS version is required by a partner configuration.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Integration type" name="integration_type">
            <Select options={integrationTypeList} />
          </Form.Item>
          <Form.Item
            label="Authorized IP addresses"
            name="authorize_ip_adresses"
            tooltip="You can type many ip addresses separated with comma ','"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Authorized IP ranges"
            name="authorize_ip_ranges"
            tooltip="You can type many ip ranges separated with comma ','"
          >
            <Input />
          </Form.Item>
          <Form.Item label="Reference currency" name="reference_currency" tooltip="Used for routing and SCA rules">
            <Select
              disabled
              options={Object.values(referenceCurrencies).map((currency) => ({
                label: currency.label,
                value: currency.id,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Source"
            name="source"
            tooltip="Defines the source of activities"
            rules={[{ required: true }]}
          >
            <Radio.Group disabled options={SOURCE_LIST} />
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  return (
    <InfoTile
      name="ma-information"
      title="Account Information"
      extra={[
        can('update', 'merchant_account') && (
          <Button
            key="update-merchant_account"
            onClick={() => setEditMode(true)}
            shape="text"
            icon={<EditOutlined />}
          />
        ),
      ]}
    >
      <InfoTextRow label="Account name" value={merchantAccount.name} />
      <InfoTextRow label="CMS name" value={getCms(merchantAccount.cms_name).label} />
      <InfoTextRow label="CMS version" value={merchantAccount.cms_version} />
      <InfoTextRow label="Integration type" value={getIntegrationType(merchantAccount.integration_type).label} />
      <InfoTextRow label="Authorized IP addresses" value={merchantAccount.authorize_ip_adresses} />
      <InfoTextRow label="Authorized IP ranges" value={merchantAccount.authorize_ip_ranges} />
      <InfoTextRow label="Reference currency" value={referenceCurrencies[merchantAccount.reference_currency].label} />
      <InfoTextRow label="Source" value={t(`sources.${merchantAccount.source}`)} />
    </InfoTile>
  );
};

export default AccountInformation;

import React from 'react';
import InfoTile, { InfoSkeletonRow } from '../../../Tile/Info/Info';

const Voucher = () => (
  <InfoTile name="voucher" title="Voucher / Gift cards">
    <InfoSkeletonRow label="Used" />
    <InfoSkeletonRow label="Amount" />
    <InfoSkeletonRow label="Voucher number" />
  </InfoTile>
);

export default Voucher;

import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Avatar, Button, Space, Table, Tabs, Tag } from 'antd';
import { DeleteOutlined, DeploymentUnitOutlined, EditOutlined } from '@ant-design/icons';
import { MetaProgramManagerPartnersQuery } from './query';
import { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import { Link } from '../../../../../../util/navigate';
import { search } from '../../../../../../util/string';
import { useCan } from '../../../../../../contexts/ability.context';
import AddActivePartner from './AddActivePartner/AddActivePartner';
import EditActivePartner from './EditActivePartner/EditActivePartner';
import InfoTile from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { partnerTypeList, PartnerTypes, partnerTypes } from '../../../Partners/constants';

const ActivePartners = ({ id }) => {
  const can = useCan();

  const [addDrawerIsVisible, setAddDrawerVisible] = useState(false);

  const [editedPartner, setEditedPartner] = useState(null);
  const [removedPartner, setRemovedPartner] = useState(null);

  const {
    data: { metaProgramManager, partners } = {
      metaProgramManager: { activePartners: [] },
      partners: [],
    },
    loading,
  } = useQuery(MetaProgramManagerPartnersQuery, {
    variables: {
      id,
    },
  });

  const dataSource = metaProgramManager.activePartners.map((activePartner) => ({
    key: activePartner.partner.id,
    partner: activePartner.partner,
    activePaymentMethods: activePartner.paymentMethods,
    availablePaymentMethods:
      partners
        .find((partner) => partner.id === activePartner.partner.id)
        ?.payment_methods.filter(
          (paymentMethod) => !activePartner.paymentMethods.map((pm) => pm.id).includes(paymentMethod.id),
        ) ?? [],
  }));

  const columnsByType = (type) => [
    {
      title: 'Partner',
      dataIndex: 'partner',
      key: 'partner',
      fixed: 'left',
      width: 160,
      render: (partner) => (
        <Link to={`/partners/${partner.id}`}>
          <Space>
            <Avatar
              src={partner.company.theme.icon}
              icon={<DeploymentUnitOutlined />}
              style={{ backgroundColor: partnerTypes[partner.type].color }}
              shape="square"
            />
            {partner.name}
          </Space>
        </Link>
      ),
      ...getColumnFilterSearchInput((value, activePartner) => search(value, activePartner.partner.name)),
      sorter: (a, b) => (a.partner.name.toLowerCase() > b.partner.name.toLowerCase() ? 1 : -1),
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
    },
    ...([PartnerTypes.PAYIN, PartnerTypes.PAYOUT].includes(type)
      ? [
          {
            title: 'Payment methods',
            dataIndex: 'activePaymentMethods',
            key: 'activePaymentMethods',
            render: (activePaymentMethods, activePartner) => (
              <div style={{ justifyContent: 'space-between', display: 'flex', gap: 12 }}>
                <Space>
                  {activePaymentMethods.map((paymentMethod) => (
                    <img
                      key={paymentMethod.id}
                      src={paymentMethod.imgUrl}
                      alt=""
                      style={{ width: 24 }}
                      title={paymentMethod.name}
                    />
                  ))}
                </Space>
                &nbsp;
                <Space>
                  {activePartner.availablePaymentMethods.map((paymentMethod) => (
                    <img
                      key={paymentMethod.id}
                      src={paymentMethod.imgUrl}
                      alt=""
                      style={{
                        width: 24,
                        filter: 'grayscale(90%)',
                        opacity: 0.7,
                      }}
                      title={paymentMethod.name}
                    />
                  ))}
                </Space>
              </div>
            ),
          },
        ]
      : [{ render: () => <div style={{ justifyContent: 'space-between', display: 'flex', gap: 12 }} /> }]), // here to keep tables similar
    ...(can('update', 'meta-program-manager-active-partner')
      ? [
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            fixed: 'right',
            width: 60,
            render: (value, activePartner) => (
              <Space>
                {[PartnerTypes.PAYIN, PartnerTypes.PAYOUT].includes(type) && (
                  <Button
                    key="edit-active-partner"
                    icon={<EditOutlined />}
                    onClick={() => setEditedPartner(activePartner)}
                    title="Edit partner payment methods"
                    shape="circle"
                    type="text"
                  />
                )}
                <Button
                  key="delete-active-partner"
                  icon={<DeleteOutlined />}
                  onClick={() => setRemovedPartner(activePartner)}
                  title="Delete active partner"
                  shape="circle"
                  type="text"
                />
              </Space>
            ),
          },
        ]
      : []),
  ];

  return [
    <InfoTile
      key="meta-program-manager-active-partners"
      name="meta-program-manager-active-partners"
      title="Active Partners"
      extra={[
        <Button key="add-partner" onClick={() => setAddDrawerVisible(true)}>
          Add partner
        </Button>,
      ]}
    >
      <Tabs defaultActiveKey={PartnerTypes.PAYIN}>
        {partnerTypeList.map((type) => (
          <Tabs.TabPane key={type.value} tab={<Tag color={type.color}>{type.label}</Tag>}>
            <Table
              columns={columnsByType(type.value)}
              loading={loading}
              dataSource={dataSource.filter((partner) => partner.partner.type === type.value)}
              size="small"
              scroll={{ x: 'auto' }}
              pagination={false}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </InfoTile>,
    <AddActivePartner
      key="add-active-partner-drawer"
      metaProgramManager={metaProgramManager}
      visible={addDrawerIsVisible}
      onClose={() => setAddDrawerVisible(false)}
    />,
    editedPartner && (
      <EditActivePartner
        key="edit-active-partner-drawer"
        metaProgramManager={metaProgramManager}
        activePartner={editedPartner}
        onClose={() => setEditedPartner(null)}
        visible
      />
    ),
    removedPartner && (
      <EditActivePartner
        key="delete-active-partner-drawer"
        metaProgramManager={metaProgramManager}
        activePartner={removedPartner}
        onClose={() => setRemovedPartner(null)}
        visible
        toRemove
      />
    ),
  ];
};

export default ActivePartners;

import {ApartmentOutlined, SafetyCertificateOutlined} from '@ant-design/icons';
import AccountMultipleIcon from '@2fd/ant-design-icons/lib/AccountMultiple';
import React from 'react';

const ProgramManagers = (can) => {
  if (can('read', 'program-manager')) {
    return [
      {
        type: 'group',
        label: 'Program Managers',
        children: [
          ...(can('read', 'program-manager')
            ? [{ key: '/program-managers', icon: <ApartmentOutlined />, label: 'Program Managers' }]
            : []),
          ...(can('read', 'program-manager-user')
            ? [{ key: '/program-users', icon: <AccountMultipleIcon />, label: 'PM users' }]
            : []),
          ...(can('read', 'program-manager-access-control')
            ? [{ key: '/program-access-control-list', icon: <SafetyCertificateOutlined />, label: 'PM rights' }]
            : []),
        ],
      },
    ]
  }
  return [];
};

export default ProgramManagers;
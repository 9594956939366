import {gql} from '@apollo/client';

export const CompanyUserQuery = gql`
  query CompanyUserQuery($companyId: ID!) {
    merchantCompany(id: $companyId) {
      id
      users {
        id
        user {
          id
          full_name
          email
          status
          disabled
          avatar
        }
        aclProfile {
          id
          name
        }
        isGdpr
        guest
        authorizedMerchantAccounts {
          id
        }
        authorizedMerchantAccountsCount
      }
      programManager {
        id
      }
    }
  }
`;
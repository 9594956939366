import { DataRoles } from '../Matcher/MatcherCashier/fields';
import useColumnSet from './useColumnSet';
import usePage from './usePage';

const useIndicators = () => {
  const page = usePage();
  const [columnSet] = useColumnSet();
  const indicators = columnSet
    .map((c) => c.field)
    .filter((fieldKey) => page.fields[fieldKey]?.dataRole === DataRoles.INDICATOR);
  return [indicators];
};

export default useIndicators;

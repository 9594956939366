import React from 'react';
import ReactHeight from 'react-height';
import moment from 'moment-timezone';
import { Button, Col, Input, Row, Space, Table, Typography } from 'antd';
import { CheckOutlined, SearchOutlined } from '@ant-design/icons';
import tileStyles from '../Tile.module.scss';
import { capitalize } from '../../../../../../util/string';
import { DATETIME_FORMAT, DATE_FORMAT } from '../../../../../../constants/DATES';
import styles from './Table.module.scss';

export const renderAmount = (value, context) =>
  value?.toLocaleString('fr-FR', {
    style: 'currency',
    currency: context.currency,
  });
export const renderDate = (value) => value ? moment(value).format(DATE_FORMAT) : null;
export const renderDatetime = (value) => value ? moment(value).format(DATETIME_FORMAT) : null;
export const renderText = (value) => capitalize(value);
export const renderCopyable = (value) => <Typography.Text copyable>{value}</Typography.Text>;

export const getColumnFilterSearchInput = (searchFn) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => [
    <Input
      key="search-input"
      autoFocus
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => confirm()}
    />,
    <Button
      key="submit-btn"
      type="primary"
      shape="circle"
      size="small"
      style={{ position: 'absolute', right: -12, top: 4 }}
      onClick={() => confirm()}
    >
      <CheckOutlined />
    </Button>,
  ],
  filterIcon: <SearchOutlined />,
  onFilter: searchFn, // (value, record) => boolean
});

const TableTile = (props) => {
  const {
    name,
    title,
    titleIcon: Icon,
    extra,
    description,
    onHeightReady = () => null,
    columns,
    dataSource,
    loading,
    expandable,
    onRow,
    scroll,
    onChange,
    pagination = false,
    size = 'middle',
    showHeader = true,
    rowClassName,
    rowKey,
    rowSelection,
    bordered,
    components,
  } = props;

  return (
    <div className={tileStyles.root}>
      <ReactHeight onHeightReady={onHeightReady}>
        <div id={`tile-${name}`}>
          <Row justify="space-between" align="middle" gutter={8} className={styles.titleRow}>
            {Icon && <Icon style={{ fontSize: 20 }} />}
            <Col flex={1} className={tileStyles.title}>
              {title}
            </Col>
            <Col>
              <Space>{extra}</Space>
            </Col>
          </Row>
          {description && <Typography.Paragraph className={styles.description}>{description}</Typography.Paragraph>}
          <div>
            <Table
              rowClassName={rowClassName}
              columns={columns}
              loading={loading}
              dataSource={dataSource}
              scroll={scroll}
              onChange={onChange}
              pagination={pagination}
              expandable={expandable}
              size={size}
              onRow={onRow}
              showHeader={showHeader}
              bordered={bordered}
              components={components}
              rowSelection={rowSelection}
              rowKey={rowKey}
            />
          </div>
        </div>
      </ReactHeight>
    </div>
  );
};

export default TableTile;

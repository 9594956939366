import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useMutation } from '@apollo/client';
import { Alert, Button, Form, InputNumber, Modal, Result, Space, Table, Typography } from 'antd';
import Lottie from 'react-lottie-player';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import REFUND_REASON_LIST from 'norbr-shared-lib/constants/transaction/refundReasons/list';
import validationAnimation from '../../../../../../assets/icons/used/000-validation-orange.json';
import styles from '../Maintenance.module.scss';
import { REFUND_ORDER_MUTATION } from './query';

const RefundModal = ({ order, onClose }) => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState();
  const [reason, setReason] = useState();

  const [refund, { called, error, loading, data: refundData }] = useMutation(REFUND_ORDER_MUTATION, {
    variables: { id: order.id },
  });

  const isPartialRefundAvailable = order.last_transaction.is_partial_refund_available;
  const isMultipleRefundsAvailable = order.last_transaction.is_multiple_refunds_available;

  const hasSingleCapture = Object.keys(dataSource ?? {}).length === 1;

  const calculateRefundedAmountOnCapture = (captureId) =>
    order.last_transaction.operations
      .filter((o) => o.type === 'refund' && o.linked_operation_id === captureId)
      .reduce((amount, refundOp) => amount + refundOp.amount, 0);

  useEffect(() => {
    if (order && !dataSource) {
      const initialDataSource = order.last_transaction.operations
        .filter((op) => op.result_status === 'capture_successful')
        .map((op, i, arr) => ({
          key: op.id,
          opId: op.id,
          date: op.result_date,
          refundableAmount:
            arr.length === 1
              ? order.last_transaction.refundable_amount
              : op.amount - calculateRefundedAmountOnCapture(op.id),
          refundAmount:
            arr.length === 1
              ? order.last_transaction.refundable_amount
              : op.amount - calculateRefundedAmountOnCapture(op.id),
          currency: op.currency,
        }))
        .filter((op) => op.refundableAmount > 0)
        .reduce(
          (memo, op) => ({
            ...memo,
            [op.opId]: op,
          }),
          {},
        );

      setDataSource(initialDataSource);
      setSelectedRowKeys(Object.keys(initialDataSource));
    }
  }, [order, dataSource]);

  const columns = [
    {
      title: 'Operation ID',
      dataIndex: 'opId',
      key: 'opId',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      render: (value) => value && <div>{moment(value).format('L LT')}</div>,
    },
    {
      title: 'Amount',
      dataIndex: 'refundAmount',
      key: 'refundAmount',
      width: 140,
      align: 'right',
      render: (value, { key, refundableAmount, currency }) =>
        isPartialRefundAvailable
          ? [
              <Space key="edit-amount">
                {CURRENCIES[currency].symbol}
                <InputNumber
                  className={styles.amountInput}
                  value={value}
                  size="small"
                  stringMode
                  precision={2}
                  onChange={(val) => {
                    setDataSource((prevState) => ({
                      ...prevState,
                      [key]: {
                        ...prevState[key],
                        refundAmount: Math.max(0, Math.min(refundableAmount, parseFloat(val))),
                      },
                    }));
                  }}
                  onFocus={(event) => event.target.select()}
                />
              </Space>,
              <div key="max-amount" className={styles.maxAmountSpan}>
                max {CURRENCIES[currency].symbol} {refundableAmount.toFixed(2)}
              </div>,
            ]
          : `${CURRENCIES[currency].symbol} ${refundableAmount.toFixed(2)}`,
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const toRefundAmount = selectedRowKeys.reduce((memo, key) => memo + dataSource[key].refundAmount, 0);

  const submit = () => {
    if (isMultipleRefundsAvailable || selectedRowKeys.length === 1) {
      // Case classic
      // -> send capture id and amount for each selected operation
      refund({
        variables: {
          reason: reason || undefined,
          input: selectedRowKeys.map((key) => ({
            captureId: key,
            amount: dataSource[key].refundAmount,
          })),
        },
      });
    } else {
      // Case with multi captures but no multi refunds
      // -> do not send capture id
      // -> sum refunded amount
      refund({
        variables: {
          reason: reason || undefined,
          input: [{ amount: toRefundAmount }],
        },
      });
    }
  };

  if (!dataSource) return null;

  if (called && !loading && !error) {
    return (
      <Modal destroyOnClose visible onCancel={onClose} footer={false}>
        <Result
          icon={
            <Lottie
              loop={false}
              animationData={validationAnimation}
              play
              style={{ width: 150, height: 150, margin: 'auto' }}
              onComplete={() => onClose()}
            />
          }
          status="success"
          title={
            refundData.refundOrder.last_transaction.status === 'refund_successful' ? 'Refunded' : 'Refund requested'
          }
          subTitle={
            <Space>
              {CURRENCIES[order.currency].symbol}
              {toRefundAmount.toFixed(2)}
            </Space>
          }
        />
      </Modal>
    );
  }

  let label = '';
  if (isPartialRefundAvailable) {
    label = hasSingleCapture ? 'Enter the amount you want to refund' : 'Select the operations you would like to refund';
  }

  return (
    <Modal
      destroyOnClose
      visible
      onCancel={onClose}
      width={664}
      footer={
        <Space>
          <Button type="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            loading={loading}
            disabled={toRefundAmount === 0 || !!error}
            onClick={form.submit}
          >
            Refund &nbsp;{CURRENCIES[order.currency].symbol}
            &nbsp;<b>{toRefundAmount.toFixed(2)}</b>
          </Button>
        </Space>
      }
    >
      <Typography.Title level={1}>Refund</Typography.Title>
      {isPartialRefundAvailable && !isMultipleRefundsAvailable && (
        <Alert type="warning" showIcon message="This transaction only allows one refund operation." />
      )}
      <Form form={form} layout="vertical" className="tile-form" onFinish={submit} size="large">
        <Form.Item name="refundReason" label="Select a reason">
          <Button.Group size="small">
            {REFUND_REASON_LIST.map((r) => (
              <Button
                key={r.id}
                onClick={() => setReason(r.id === reason ? null : r.id)}
                type={r.id === reason ? 'primary' : 'default'}
              >
                {r.label}
              </Button>
            ))}
          </Button.Group>
        </Form.Item>
        <Form.Item label={label}>
          <Table
            columns={columns}
            dataSource={Object.values(dataSource)}
            rowSelection={!hasSingleCapture && isPartialRefundAvailable && rowSelection}
            rowClassName={(hasSingleCapture || !isPartialRefundAvailable) && 'ant-table-row-selected'}
            size="middle"
            pagination={false}
            showHeader={false}
          />
        </Form.Item>
      </Form>
      {!!error && <Alert type="error" showIcon message="An error occurred, please try again later." />}
    </Modal>
  );
};

export default RefundModal;

import React from 'react';
import { Badge, Divider, Space, Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import TableTile, { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import styles from '../MerchantAccountView.module.scss';
import { search } from '../../../../../../util/string';
import usePaymentMethods from '../../../../../../hooks/common/usePaymentMethods';
import { partnerTypes } from '../../../Partners/constants';
import Sources from '../../../../../../constants/SOURCES';
import ConnectAsGuest from '../../../Company/CompanyView/ConnectAsGuest/ConnectAsGuest';
import { useCan } from '../../../../../../contexts/ability.context';

const merchantContractStatuses = {
  active: {
    label: 'Active',
    color: 'green',
    badge: 'success',
  },
  inactive: {
    label: 'Inactive',
    color: 'gold',
    badge: 'warning',
  },
  deleted: {
    label: 'Deleted',
    color: 'red',
    badge: 'error',
  },
};

const MerchantContracts = ({ merchantAccount }) => {
  const can = useCan();
  const paymentMethods = usePaymentMethods();
  const { t } = useTranslation();

  const dataSource = merchantAccount?.merchant_contracts
    ?.map((merchantContract) => ({
      key: merchantContract.id,
      name: merchantContract.partner?.name,
      type: merchantContract.partner?.type,
      accountLabel: merchantContract.name,
      paymentMethods: merchantContract.payment_methods.map((pm) => pm.id),
      status: merchantContract.status || 'inactive',
      source: merchantContract.source,
      isDemo: merchantContract.is_demo,
    }))
    .sort((a, b) => {
      const statuses = ['active', 'inactive', 'deleted'];
      if (statuses.indexOf(a.status) === statuses.indexOf(b.status)) {
        return a.accountLabel.toLowerCase() < b.accountLabel.toLowerCase() ? -1 : 1;
      }
      return statuses.indexOf(a.status) - statuses.indexOf(b.status);
    });

  const columns = [
    {
      title: 'Partner name',
      dataIndex: 'name',
      key: 'name',
      render: (name, { status }) => <Badge status={merchantContractStatuses[status].badge} text={name} />,
      ...getColumnFilterSearchInput((value, record) => search(value, record.name)),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => partnerTypes[type]?.label,
      filters: Object.values(partnerTypes).map((pt) => ({ ...pt, text: pt.label })),
      onFilter: (value, record) => value === record.type,
    },
    {
      title: 'Account label',
      dataIndex: 'accountLabel',
      key: 'accountLabel',
      render: (name, account) => (
        <Space>
          {name}
          {account.isDemo && <Tag color="yellow">Demo</Tag>}
        </Space>
      ),
      ...getColumnFilterSearchInput((value, record) => search(value, record.accountLabel)),
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Payment methods',
      dataIndex: 'paymentMethods',
      key: 'paymentMethods',
      render: (list) => (
        <Space>
          {list.length > 7
            ? [
                ...list
                  .slice(0, 6)
                  .map(
                    (pm) =>
                      paymentMethods[pm] && (
                        <img
                          key={pm}
                          src={paymentMethods[pm].imgUrl}
                          alt=""
                          style={{ width: 24 }}
                          title={paymentMethods[pm].name}
                        />
                      ),
                  ),
                <span key="more" style={{ fontSize: 11 }}>
                  + {list.length - 6}
                </span>,
              ]
            : list?.map(
                (pm) =>
                  paymentMethods[pm] && (
                    <img
                      key={pm}
                      src={paymentMethods[pm].imgUrl}
                      alt=""
                      style={{ width: 24 }}
                      title={paymentMethods[pm].name}
                    />
                  ),
              )}
        </Space>
      ),
      filters: Object.values(paymentMethods).map((pm) => ({ value: pm.api_name, text: pm.name })),
      onFilter: (value, record) => record.paymentMethods?.includes(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) =>
        status &&
        merchantContractStatuses[status] && (
          <Tag color={merchantContractStatuses[status].color}>{merchantContractStatuses[status].label}</Tag>
        ),
      filters: [
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
      ],
      onFilter: (value, record) => value === record.status,
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      render: (source) => <Tag>{t(`sources.${source}`)}</Tag>,
      filters: [
        { text: t(`sources.${Sources.INSIGHTS}`), value: Sources.INSIGHTS },
        { text: t(`sources.${Sources.STACK}`), value: Sources.STACK },
      ],
      onFilter: (value, record) => value === record.source,
    },
  ];

  // return info tile
  return (
    <TableTile
      name="ma-merchant-contracts"
      title="Merchant Contracts"
      columns={columns}
      dataSource={dataSource}
      pagination={{
        showSizeChanger: true,
        defaultPageSize: 5,
        pageSizeOptions: [5, 10, 20, 50, 100],
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} contracts`,
      }}
      size="small"
      scroll={{ x: 1000 }}
      expandable={{
        expandedRowRender: (record) => (
          <div>
            <Divider className={styles.divider} orientation="left">
              Payment methods
            </Divider>
            {record.paymentMethods.map((pm) => (
              <Tag color="lightgrey">{pm}</Tag>
            ))}
          </div>
        ),
        rowExpandable: (record) => record.paymentMethods?.length > 7,
        fixed: 'right',
      }}
      extra={[
        can('create', 'guest_user') && <ConnectAsGuest key="connect-as-guest" company={merchantAccount?.company} />,
      ]}
    />
  );
};

export default MerchantContracts;

import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Button, Modal, Tag, Typography } from 'antd';
import { CodeOutlined } from '@ant-design/icons';
import { compareByProp } from '../../../../../../util/array';
import TableTile, { renderDatetime } from '../../../OrderDetail/Tile/Table/Table';
import CallsSkeleton from './CallsSkeleton';
import { callsQuery } from './query';

const Calls = () => {
  const { notificationId } = useParams();

  const { data, loading } = useQuery(callsQuery, {
    variables: {
      notificationId,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (!data?.notification || loading) return <CallsSkeleton />;

  const columns = [
    {
      title: '',
      dataIndex: 'index',
      key: 'index',
      sorter: (a, b) => b.index - a.index,
      width: 24,
      align: 'center',
    },
    {
      title: 'CREATION',
      dataIndex: 'date',
      key: 'date',
      sorter: compareByProp('date'),
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      render: renderDatetime,
    },
    {
      title: 'RESULT',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => b.code - a.code,
      render: (code, call) =>
        call.body && [
          <Tag color="darkgrey">
            {call.body.status} - {call.body.statusText}
          </Tag>,
          <Button
            shape="circle"
            type="text"
            icon={<CodeOutlined />}
            title="See response body"
            onClick={() => {
              Modal.info({
                content: [
                  <Typography.Paragraph>
                    <pre>{call.body.data}</pre>
                  </Typography.Paragraph>,
                ],
                title: (
                  <Tag color="darkgrey">
                    {call.body.status} - {call.body.statusText}
                  </Tag>
                ),
                icon: null,
                width: '95%',
                bodyStyle: { maxHeight: 'calc(100vh - 188px)', padding: '8px 12px', overflowY: 'scroll' },
                onOk() {},
              });
            }}
          />,
        ],
    },
    {
      title: 'SIGNATURE',
      dataIndex: 'signature',
      key: 'signature',
      sorter: (a, b) => b.signature - a.signature,
    },
    {
      title: 'TIMESTAMP',
      dataIndex: 'timestamp',
      key: 'timestamp',
      sorter: (a, b) => b.timestamp - a.timestamp,
    },
  ];

  return (
    <TableTile
      name="calls"
      title="Calls"
      size="small"
      columns={columns}
      dataSource={data?.notification?.calls?.map((c, index) => ({ ...c, index: index + 1 })).reverse() ?? []}
    />
  );
};

export default Calls;

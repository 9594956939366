import { gql } from '@apollo/client';

export const MerchantAccountDetailQuery = gql`
  query MerchantAccountDetailQuery($id: ID!) {
    merchantAccount(id: $id) {
      id
      name
      status
      cms_name
      users {
        id
        user {
          id
          email
          full_name
          avatar
        }
        aclProfile {
          id
          name
        }
      }
      company {
        users {
          id
          user {
            id
            email
            full_name
            avatar
          }
          aclProfile {
            id
            name
          }
        }
      }
    }
  }
`;

export default {};

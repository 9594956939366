export const CompanyStatuses = {
  INITIALIZED: 'initialized',
  VALIDATED: 'validated',
  CANCELED: 'canceled',
  DEACTIVATED: 'deactivated',
  DECLINED: 'declined',
};

export const companyStatuses = {
  [CompanyStatuses.INITIALIZED]: {
    value: CompanyStatuses.INITIALIZED,
    badge: 'default',
    color: 'lightgrey',
    label: 'Initialized',
  },
  [CompanyStatuses.VALIDATED]: {
    value: CompanyStatuses.VALIDATED,
    badge: 'success',
    color: '#52c41a',
    label: 'Validated',
  },
  [CompanyStatuses.CANCELED]: {
    value: CompanyStatuses.CANCELED,
    badge: 'error',
    color: 'red',
    label: 'Canceled',
  },
  [CompanyStatuses.DEACTIVATED]: {
    value: CompanyStatuses.DEACTIVATED,
    badge: 'warning',
    color: 'gold',
    label: 'Deactivated',
  },
  [CompanyStatuses.DECLINED]: {
    value: CompanyStatuses.DECLINED,
    badge: 'error',
    color: 'red',
    label: 'Declined',
  },
};

export const companyStatusList = Object.values(companyStatuses);

export default {};

const DATES = {
  minutes: 'YY/MM/DD hh:mm A',
  seconds: 'YY/MM/DD hh:mm:ss A',
  day: 'DD MMMM YYYY',
  hours: 'hh[h]mm[m]ss[s]',
  hoursAM: 'hh[h]mm[m]ss[s] A',
  fr: 'DD/MM/YYYY',
};

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const DATETIME2_FORMAT = 'DD/MM/YYYY HH:mm';

export default DATES;

import React from 'react';
import { Avatar, Button, Card, List, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { HomeOutlined, PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { useCan } from '../../../../../contexts/ability.context';
// import ProgramManagerCreate from './ProgramManagerCreate/ProgramManagerCreate';
import useToggle from '../../../../../hooks/useToggle';
import { contrast } from '../../../../../util/theme';
import { Link } from '../../../../../util/navigate';
import { metaProgramManagerListQuery } from './query';
import MetaProgramManagerCreate from './MetaProgramManagerCreate/MetaProgramManagerCreate';

const MetaProgramManagerList = () => {
  const can = useCan();

  const { data, loading } = useQuery(metaProgramManagerListQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const [showAddMetaProgramManager, { toggle }] = useToggle(false);

  return (
    <PageHeader
      title="Meta Program managers"
      breadcrumbRender={() => (
        <Breadcrumb
          map={{
            '/': <HomeOutlined />,
            '/meta-program-managers': 'Meta program managers',
          }}
        />
      )}
      extra={[
        can('create', 'meta-program-manager') && (
          <Button key="create" type="primary" shape="round" icon={<PlusOutlined />} onClick={toggle}>
            Create meta program manager
          </Button>
        ),
      ]}
    >
      <Typography.Paragraph>Manage the meta program managers, users, access rights and partners</Typography.Paragraph>
      <List
        itemLayout="vertical"
        size="large"
        dataSource={data?.metaProgramManagers}
        loading={loading}
        grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
        renderItem={(metaProgramManager) => (
          <Link to={`/meta-program-managers/${metaProgramManager.id}`}>
            <List.Item key={metaProgramManager.id}>
              <Card
                style={{ backgroundColor: metaProgramManager.theme.secondaryColor }}
                cover={
                  metaProgramManager.theme.logo ? (
                    <img alt="logo" src={metaProgramManager.theme.logo} style={{ height: 80, objectFit: 'contain' }} />
                  ) : (
                    <div style={{ height: 80, width: '100%', backgroundColor: 'lightgrey' }} />
                  )
                }
                hoverable
              >
                <Card.Meta
                  avatar={<Avatar src={metaProgramManager.theme.icon} />}
                  title={
                    <span
                      style={{
                        color: contrast(metaProgramManager.theme.secondaryColor),
                      }}
                    >
                      {metaProgramManager.name}
                    </span>
                  }
                  description={metaProgramManager.payfac ? 'Payment Facilitator' : ''}
                />
              </Card>
            </List.Item>
          </Link>
        )}
      />
      <MetaProgramManagerCreate visible={showAddMetaProgramManager} toggle={toggle} />
    </PageHeader>
  );
};

export default MetaProgramManagerList;

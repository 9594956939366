import React from 'react';
import { Button, Drawer, Form, Input, message, Space } from 'antd';
import { useMutation } from '@apollo/client';
import styles from '../../MetaProgramManagerView/MetaProgramManagerContacts/ContactForm/ContactForm.module.scss';
import { createMetaProgramManagerMutation } from './query';
import useNavigateWithSearch from '../../../../../../util/navigate';

const MetaProgramManagerCreate = ({ visible, toggle }) => {
  const navigate = useNavigateWithSearch();
  const [form] = Form.useForm();

  const [create, { loading: loadingCreate }] = useMutation(createMetaProgramManagerMutation, {
    refetchQueries: ['MetaProgramManagers'],
    onCompleted: ({ createMetaProgramManager }) => {
      message.success('Meta program manager successfully created');
      navigate(`/meta-program-managers/${createMetaProgramManager.id}`);
    },
    onError: (e) => {
      const error = e.graphQLErrors.find((ee) => ee.extensions.code === '409');
      if (error) {
        Object.keys(error.extensions.existing).forEach((eKey) =>
          message.error(
            `Unique field ${eKey} already exists : ${error.extensions.existing[eKey]}`,
          )
        )
      } else {
        message.error('An error occurred, please try again later.');
      }
    },
  });

  return (
    <Drawer
      open={visible}
      onClose={toggle}
      title="Create Meta Program manager"
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(input) => {
          create({
            variables: {
              input,
            },
          });
        }}
      >
        <Form.Item label="Name" name="name" required>
          <Input />
        </Form.Item>
        <Form.Item
          label="Display name"
          name="displayName"
          rules={[{ required: true }]}
          tooltip="Name displayed on the merchant console."
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Code"
          name="code"
          rules={[{ required: true }, { len: 4, message: 'Code should have 4 characters.' }]}
          tooltip="Technical code (e.g. used to prefix API keys like XXXX_PR_123456789...)."
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="newCompany"
          label="Company name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="adminDomain"
          label="Admin domain"
          rules={[
            { required: true },
            {
              validator: (_, value) =>
                value.indexOf('http') === 0 ? Promise.reject(new Error('Invalid format')) : Promise.resolve(),
              message: 'Invalid format. Please enter the domain, not the URL.',
            },
          ]}
          tooltip="Domain of admin application."
        >
          <Input />
        </Form.Item>
        <Form.Item className={styles.submitItem}>
          <Space>
            <Button onClick={toggle}>Cancel</Button>
            <Button onClick={() => form.resetFields()}>Reset</Button>
            <Button type="primary" htmlType="submit" loading={loadingCreate}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default MetaProgramManagerCreate;
import { gql } from '@apollo/client';

const query = `{
  _id
  id
  currency
  baskets {
    _id
    id
    product_id
    product_name
    product_description
    product_quantity
    product_price
    product_tax_amount
    product_type
    total_amount
    seller_id
    seller_name
    seller_commission
  }
}`;

export const orderBasketDetailsQuery = gql`
  query OrderBasketDetails($orderId: ID!) {
    order(id: $orderId) ${query}
  }
`;

export const activityBasketDetailsQuery = gql`
  query ActivityBasketDetails($orderId: ID!) {
    activity(id: $orderId) ${query}
  }
`;

import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import { useLazyQuery } from '@apollo/client';
import InfoTile, { InfoTextRow } from '../../../Tile/Info/Info';
import { orderReferenceQuery, activityReferenceQuery } from './query';
import OrderReferenceSkeleton from './OrderReferenceSkeleton';

const OrderReference = () => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(
    activityReferenceQuery,
    {
      variables: {
        orderId,
      },
    },
  );

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(orderReferenceQuery, {
    variables: {
      orderId,
    },
    onCompleted: (data) => !data.order && fetchInsights(),
  });

  const order = orderStack ?? orderInsights;
  const transaction = order?.last_transaction;

  if (!transaction) return <OrderReferenceSkeleton />;

  return (
    <InfoTile name="order-reference" title="Order reference">
      <InfoTextRow label={`${order.program_manager?.displayName} ID`} value={transaction.order_id} />
      <InfoTextRow label="Account ID" value={order.merchant_account?.id} />
      <InfoTextRow label="Account name" value={order.merchant_account?.name} />
      <InfoTextRow label="Contract ID" value={transaction.merchant_contract?.id} />
      <InfoTextRow label="Contract name" value={transaction.merchant_contract?.name} />
      <InfoTextRow label="Merchant reference" value={transaction.order_merchant_id} />
      <InfoTextRow label="PSP reference" value={transaction.psp_transaction_id} />
      <InfoTextRow label="Number of transactions" value={order.transaction_count} />
    </InfoTile>
  );
};

export default OrderReference;

import { gql } from '@apollo/client';

export const merchantContractQuery = gql`
  query MerchantContract($id: ID!) {
    merchantContract(id: $id) {
      id
      payment_methods {
          id
      }
    }
  } 
`
import React from 'react';
import { Col, Descriptions, Row, Space, Tag, Typography } from 'antd';
import OPERATION_TYPE_ENUM from 'norbr-shared-lib/constants/reconciliation/operationTypes/enum';
import TargetEntities, { TargetEntity } from '../../Common/constants/targetEntities';
import styles from './MatcherDrawer.module.scss';
import OperationsTable from './OperationsTable';

const MatcherTransactionDescription = ({ transaction, selectedOperationId, setSelectedOperationId }) => {
  if (!transaction) return null;
  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: '#f5f4f4',
        margin: '0 8px',
        borderRadius: 8,
        overflow: 'hidden',
      }}
    >
      <Row justify="space-between" style={{ padding: '8px 12px' }}>
        <Col>
          <Space style={{ fontSize: '14px' }}>
            {TargetEntities[TargetEntity.TRANSACTION].icon()}
            Transaction
          </Space>
        </Col>
        {/*<Col>*/}
        {/*  <Space>*/}
        {/*    <span style={{ color: '#343434' }}>Payment operations</span>*/}
        {/*    <Tag className={styles.tagCell} style={{ color: '#818080', backgroundColor: '#eae9e9' }}>*/}
        {/*      {reconciledOpxCount}/{transactionalOperations.length}*/}
        {/*    </Tag>*/}
        {/*  </Space>*/}
        {/*</Col>*/}
      </Row>
      <Row justify="space-between" style={{ padding: '8px 12px' }}>
        <Descriptions
          column={2}
          size="small"
          colon={false}
          labelStyle={{ color: '#868686' }}
          className={styles.descriptions}
        >
          <Descriptions.Item label="Transaction">
            <Typography.Text ellipsis copyable>
              {transaction.id}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="File name">
            <Typography.Text ellipsis style={{ width: 300 }} copyable>
              {transaction.pos_file_name ?? 'n/a'}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      </Row>
      <Row
        style={{
          backgroundColor: '#eaeaea',
          padding: '8px 12px',
          borderTop: '#cbcbcb 1px solid',
        }}
      >
        <OperationsTable
          transaction={transaction}
          selectedOperationId={selectedOperationId}
          setSelectedOperationId={setSelectedOperationId}
        />
      </Row>
    </div>
  );

  // return (
  //   <div
  //     style={{
  //       position: 'relative',
  //       zIndex: 20,
  //       backgroundColor: 'white',
  //       boxShadow: '0 1px 4px 0 rgba(23, 23, 23, 0.35)',
  //       padding: '8px 12px',
  //       margin: '0 8px',
  //       borderRadius: 6,
  //     }}
  //   >
  //     <Descriptions
  //       column={2}
  //       size="small"
  //       colon={false}
  //       labelStyle={{ color: 'lightgrey' }}
  //       className={styles.descriptions}
  //     >
  //       <Descriptions.Item label="Transaction">
  //         <Typography.Text ellipsis copyable>
  //           {transaction.id}
  //         </Typography.Text>
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Partner ID">
  //         <Typography.Text ellipsis copyable>
  //           {transaction.psp_transaction_id}
  //         </Typography.Text>
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Terminal ID">
  //         <Typography.Text ellipsis copyable>
  //           {transaction.merchant_terminal_id ?? 'n/a'}
  //         </Typography.Text>
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Date">{moment(transaction.createdAt).format(DATETIME_FORMAT)}</Descriptions.Item>
  //       <Descriptions.Item label="Payment method">
  //         <Space>
  //           <img
  //             style={{
  //               paddingTop: 0,
  //               paddingBottom: 0,
  //               height: 24,
  //             }}
  //             alt=""
  //             src={paymentMethods[transaction.payment_method_name]?.imgUrl}
  //           />
  //           <Typography.Text ellipsis>{paymentMethods[transaction.payment_method_name]?.name}</Typography.Text>
  //         </Space>
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Amount">
  //         {amountFormatter(transaction.amount, transaction.currency, AmountFormats.FR_CURRENCY)}
  //       </Descriptions.Item>
  //     </Descriptions>
  //     <OperationsTable
  //       transaction={transaction}
  //       selectedOperationId={selectedOperationId}
  //       setSelectedOperationId={setSelectedOperationId}
  //     />
  //   </div>
  // );
};

export default MatcherTransactionDescription;

import React, { useLayoutEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Button, Space, Tag } from 'antd';
import ArrowUpBoldIcon from '@2fd/ant-design-icons/lib/ArrowUpBold';
import TrashCanIcon from '@2fd/ant-design-icons/lib/TrashCan';
import Drawers from '../../../Common/constants/drawers';
import FieldTypes from '../../../Common/constants/fieldTypes';
import useDrawer from '../../../Common/useDrawer';
import styles from './SearchFilterTags.module.scss';
import ListFilterTag from '../../../../Common/FilterTag/ListFilterTag';
import NumberFilterTag from '../../../../Common/FilterTag/NumberFilterTag';
import DateFilterTag from '../../../../Common/FilterTag/DateFilterTag';
import TextFilterTag from '../../../../Common/FilterTag/TextFilterTag';
import { DATETIME2_FORMAT } from '../../../../../../constants/DATES';
import { exportStatuses } from '../../constants/exportStatuses';
import { sendingMethods } from '../../constants/sendingMethods';
import { reportDelimiters } from '../../constants/reportDelimiters';
import { reportFormats } from '../../constants/reportFormats';
import FilterTag from '../../../../Common/FilterTag/FilterTag';

const FilterTagMapper = ({ fieldKey, value, onRemove }) => {
  const [, setDrawer] = useDrawer();

  const handleRemove = (e) => {
    e.stopPropagation();
    onRemove(fieldKey);
  };

  const fields = {
    name: {
      type: FieldTypes.TEXT,
      label: 'Name',
    },
    description: {
      type: FieldTypes.TEXT,
      label: 'Description',
    },
    status: {
      type: FieldTypes.LIST,
      label: 'Status',
      displayOptions: {
        getOption: (v) => exportStatuses[v],
        tag: true,
      },
    },
    sendingMethod: {
      type: FieldTypes.LIST,
      label: 'Sending method',
      displayOptions: {
        getOption: (v) => sendingMethods[v],
      },
    },
    delimiter: {
      type: FieldTypes.LIST,
      label: 'Delimiter',
      displayOptions: {
        getOption: (v) => ({ label: reportDelimiters[v].symbol }),
      },
    },
    format: {
      type: FieldTypes.LIST,
      label: 'Format',
      displayOptions: {
        getOption: (v) => reportFormats[v],
      },
    },
    createdAt: {
      type: FieldTypes.DATE,
      label: 'Creation date',
    },
    lastExecutionDate: {
      type: FieldTypes.DATE,
      label: 'Last execution date',
    },
  };

  const { type, label, displayOptions = {} } = fields[fieldKey];

  const filterTagProps = {
    id: `filter-${fieldKey}`,
    onClick: () => setDrawer(Drawers.SEARCH),
    onRemove: handleRemove,
    label,
    displayOptions,
    value,
  };

  if (value === '__NULL__') return <FilterTag {...filterTagProps}><Tag>Null</Tag></FilterTag>;

  switch (type) {
    case FieldTypes.LIST:
    case FieldTypes.BOOLEAN:
      return <ListFilterTag {...filterTagProps} />;
    case FieldTypes.AMOUNT:
    case FieldTypes.NUMBER:
    case FieldTypes.COUNT:
      return <NumberFilterTag {...filterTagProps} />;
    case FieldTypes.DATE:
      return (
        <DateFilterTag
          {...filterTagProps}
          value={{ from: value[0]?.format(DATETIME2_FORMAT), to: value[1]?.format(DATETIME2_FORMAT) }}
        />
      );
    case FieldTypes.TEXT:
      return <TextFilterTag {...filterTagProps} value={[value]} />;
    default:
      throw new Error(`Field type does not exist. (id: ${fieldKey}, type: ${type})`);
  }
};

const SearchFilterTags = ({ list, onSearch }) => {
  const [drawer] = useDrawer();
  const containerRef = useRef();
  const [collapsed, setCollapsed] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);

  useLayoutEffect(() => {
    setHasOverflow(containerRef?.current?.scrollHeight > 34);
  });

  if (list.length === 0) return null;

  const handleRemove = (fieldKey) => {
    onSearch(
      list.filter(({ key }) => key !== fieldKey).reduce((memo, curr) => ({ ...memo, [curr.key]: curr.value }), {}),
    );
  };

  return (
    <div className={styles.filterTagsContainer}>
      <span>Filters</span>
      <div ref={containerRef} className={classnames(styles.collapsable, { [styles.collapsed]: collapsed })}>
        <Space size={[4, 2]} wrap>
          {list.map(
            ({ key, value }) =>
              value && <FilterTagMapper key={key} fieldKey={key} value={value} onRemove={handleRemove} />,
          )}
          {drawer !== Drawers.SEARCH && list.length > 0 && (
            <TrashCanIcon className={styles.trashIcon} onClick={() => onSearch({})} title="Remove all filters" />
          )}
        </Space>
      </div>
      {hasOverflow && (
        <Button
          className={classnames(styles.collapseButton, { [styles.collapsed]: collapsed })}
          shape="circle"
          type="default"
          onClick={() => setCollapsed((prev) => !prev)}
          title={collapsed ? 'Deploy filters' : 'Collapse filters'}
        >
          <ArrowUpBoldIcon />
        </Button>
      )}
    </div>
  );
};

export default SearchFilterTags;

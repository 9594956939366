import React, {useEffect, useState} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Button, Drawer, Form, Input, message, Select, Space, Switch } from 'antd';
import DomainIcon from '@2fd/ant-design-icons/lib/Domain';
import { compareByProp } from '../../../../../util/array';
import { searchByProp } from '../../../../../util/string';
import { useResetFormOnCloseModal } from '../../../Common/Form/utils';
import ProgramManagerAvatar from '../../../Common/ProgramManager/Avatar';
import { ProgramManagerQuery } from '../../MerchantAccounts/MerchantAccountCreate/query';
import { ProgramManagerListQuery } from '../../Company/query';
import { CompanyMerchantAccountListQuery, UserCreateMutation } from './query';
import useNavigateWithSearch from "../../../../../util/navigate";

const UserCreate = ({ visible, onCancel, companyId, programManager }) => {
  const navigate = useNavigateWithSearch();

  const [form] = Form.useForm();

  const [selectedProgramManager, setSelectedProgramManager] = useState(programManager);

  useEffect(() => {
    setSelectedProgramManager(programManager)
  }, [programManager]);

  const handleProgramManagerChange = (pm) => {
    setSelectedProgramManager(pm);
    form.resetFields(['company', 'authorizedMerchantAccounts', 'aclProfile']);
  };

  const [selectedCompany, setSelectedCompany] = useState(companyId);
  const handleCompanyChange = (c) => {
    setSelectedCompany(c);
    form.resetFields(['authorizedMerchantAccounts']);
  };

  const programManagersQuery = useQuery(ProgramManagerListQuery, { skip: programManager });
  const programManagerQuery = useQuery(ProgramManagerQuery, {
    variables: { programManager: selectedProgramManager },
    skip: !selectedProgramManager,
  });

  const merchantAccountsQuery = useQuery(CompanyMerchantAccountListQuery, {
    variables: { companyId: selectedCompany },
    skip: !selectedCompany,
  });

  const [create, { loading }] = useMutation(UserCreateMutation, {
    refetchQueries: ['UserListQuery'],
    onCompleted: ({ createMerchantUser }) => {
      message.success('User successfully created');
      navigate(`/users/${createMerchantUser.id}`);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error(error.message);
    },
  });

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const submit = (values) => {
    create({
      variables: {
        input: values,
      },
    });
  };

  return (
    <Drawer
      title="Create User"
      onClose={onCancel}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
    >
      <Form form={form} layout="vertical" name="userForm" onFinish={submit}>
        <Form.Item name="email" label="Email" rules={[{ required: true }, { type: 'email' }]}>
          <Input placeholder="email@address.com" />
        </Form.Item>
        <Form.Item name="first_name" label="Firstname" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="last_name" label="Lastname" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Phone">
          <Space>
            <Form.Item name={['phone', 'code']} noStyle>
              <Input style={{ width: 65 }} placeholder="31" prefix="+" />
            </Form.Item>
            <Form.Item name={['phone', 'number']} noStyle>
              <Input placeholder="123456789" />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item
          name="programManager"
          label="Program Manager"
          rules={[{ required: true }]}
          initialValue={programManager}
          hidden={!!programManager}
        >
          <Select
            allowClear
            showSearch
            filterOption={searchByProp('label')}
            optionLabelProp="label"
            loading={programManagersQuery.loading}
            onChange={handleProgramManagerChange}
            defaultValue={programManager}
            disabled={!!programManager}
          >
            {programManagersQuery.data?.programManagers.map((pm) => (
              <Select.Option key={pm.id} value={pm.id} label={pm.name}>
                <Space>
                  <ProgramManagerAvatar programManager={pm} />
                  {pm.name}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="company"
          label="Company"
          rules={[{ required: true }]}
          initialValue={companyId}
          hidden={!!companyId}
        >
          <Select
            allowClear
            showSearch
            filterOption={searchByProp('label')}
            optionLabelProp="label"
            loading={programManagerQuery.loading}
            onChange={handleCompanyChange}
            defaultValue={companyId}
            disabled={!selectedProgramManager || !!companyId}
          >
            {programManagerQuery.data?.programManager.merchantCompanies.map((company) => (
              <Select.Option key={company.id} value={company.id} label={company.name}>
                <Space>
                  <Avatar src={company.theme?.icon} icon={<DomainIcon />} size="small" />
                  {company.name}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="authorizedMerchantAccounts" label="Authorized merchant accounts" initialValue={[]}>
          <Select
            loading={merchantAccountsQuery.loading}
            options={merchantAccountsQuery.data?.merchantCompany.merchantAccounts
              .map(({ id, name }) => ({
                value: id,
                label: name,
              }))
              .sort(compareByProp('label', 'asc', 'string'))}
            mode="multiple"
            disabled={!selectedCompany}
            showSearch
            filterOption={searchByProp('label')}
          />
        </Form.Item>
        <Form.Item name="aclProfile" label="Profile" rules={[{ required: true }]}>
          <Select
            loading={programManagerQuery.loading}
            options={programManagerQuery.data?.programManager.merchantAclProfiles.map((p) => ({
              value: p.id,
              label: p.name,
            }))}
            disabled={!selectedProgramManager}
            showSearch
            filterOption={searchByProp('label')}
          />
        </Form.Item>
        <Form.Item name="isGdpr" label="Access Personal Data" valuePropName="checked">
          <Switch defaultChecked={false} />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Space>
            <Button type="text" disabled={loading} onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" size="large" loading={loading}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UserCreate;

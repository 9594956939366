import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Avatar, Card, Col, Divider, Row } from 'antd';
import { DeploymentUnitOutlined } from '@ant-design/icons';
import ViewCarouselIcon from '@2fd/ant-design-icons/lib/ViewCarousel';
import DomainIcon from '@2fd/ant-design-icons/lib/Domain';
import ProgramManagerBadge from '../../../Common/ProgramManager/Badge';
import styles from './RecentAccess.module.scss';
import { Link } from '../../../../../util/navigate';
import { useCan } from '../../../../../contexts/ability.context';
import { useProgramManagerId } from '../../../../../contexts/app.context';

const homePageReportQuery = gql`
  query {
    lastMerchantCompanies {
      id
      name
      theme {
        icon
        primaryColor
        secondaryColor
      }
      programManager {
        id
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
    }
    lastMerchantAccounts {
      id
      name
      company {
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
      programManager {
        id
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
    }
    lastPartners {
      id
      name
      company {
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
    }
  }
`;

const RecentAccess = () => {
  const can = useCan();
  const { data, loading } = useQuery(homePageReportQuery, {
    errorPolicy: 'all',
  });
  const [programManagerId] = useProgramManagerId();

  return (
    <Row gutter={[24, 24]}>
      {can('read', 'company') && (
        <Col xl={8} lg={12} xs={24}>
          <Divider dashed orientation="left" orientationMargin={0}>
            Recent companies
          </Divider>
          {!loading && data.lastMerchantCompanies && (
            <Row gutter={[24, 24]}>
              {data.lastMerchantCompanies.map((company) => (
                <Col key={company.id}>
                  <ProgramManagerBadge programManager={company.programManager}>
                    <Link to={`/companies/${company.id}`}>
                      <Card
                        hoverable
                        size="small"
                        className={styles.entityCard}
                        cover={
                          company.theme.icon ? (
                            <img src={company.theme.icon} alt="" />
                          ) : (
                            <div className={styles.avatar}>
                              <Avatar
                                icon={<DomainIcon />}
                                size={40}
                                style={{ backgroundColor: company.theme.primaryColor }}
                              />
                            </div>
                          )
                        }
                      >
                        <Card.Meta title={company.name} />
                      </Card>
                    </Link>
                  </ProgramManagerBadge>
                </Col>
              ))}
            </Row>
          )}
        </Col>
      )}
      {can('read', 'merchant_account') && (
        <Col xl={8} lg={12} xs={24}>
          <Divider dashed orientation="left" orientationMargin={0}>
            Recent accounts
          </Divider>
          {!loading && data.lastMerchantAccounts && (
            <Row gutter={[24, 24]}>
              {data.lastMerchantAccounts.map((account) => (
                <Col key={account.id}>
                  <ProgramManagerBadge programManager={account.programManager}>
                    <Link to={`/merchant-accounts/${account.id}`}>
                      <Card
                        hoverable
                        size="small"
                        className={styles.entityCard}
                        cover={
                          account.company.theme.icon ? (
                            <img src={account.company.theme.icon} alt="" />
                          ) : (
                            <div className={styles.avatar}>
                              <Avatar
                                icon={<ViewCarouselIcon />}
                                size={40}
                                style={{ backgroundColor: account.company.theme.primaryColor }}
                              />
                            </div>
                          )
                        }
                      >
                        <Card.Meta title={account.name} />
                      </Card>
                    </Link>
                  </ProgramManagerBadge>
                </Col>
              ))}
            </Row>
          )}
        </Col>
      )}
      {can('read', 'partner') && !programManagerId && (
        <Col xl={8} lg={12} xs={24}>
          <Divider dashed orientation="left" orientationMargin={0}>
            Recent partners
          </Divider>
          {!loading && data.lastPartners && (
            <Row gutter={[24, 24]}>
              {data.lastPartners.map((partner) => (
                <Col key={partner.id}>
                  <Link to={`/partners/${partner.id}`}>
                    <Card
                      hoverable
                      size="small"
                      className={styles.entityCard}
                      cover={
                        partner.company.theme.icon ? (
                          <img src={partner.company.theme.icon} alt="" />
                        ) : (
                          <div className={styles.avatar}>
                            <Avatar
                              icon={<DeploymentUnitOutlined />}
                              size={40}
                              shape="square"
                              style={{ backgroundColor: partner.company.theme.primaryColor }}
                            />
                          </div>
                        )
                      }
                    >
                      <Card.Meta title={partner.name} />
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          )}
        </Col>
      )}
    </Row>
  );
};

export default RecentAccess;

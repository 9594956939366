import { gql } from '@apollo/client';

export const InternalUserCreateMutation = gql`
  mutation InternalUserCreate($input: InternalUserCreationInput!) {
    createInternalUser(input: $input) {
      id
      full_name
      phone {
        code
        number
      }
      status
      aclProfile {
        id
        name
      }
    }
  }
`;

export default {};

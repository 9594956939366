import React, { useState } from 'react';
import { Badge, Button, Divider, message, Space, Switch, Table, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import TableTile, { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import styles from '../MerchantAccountView.module.scss';
import { useCan } from '../../../../../../contexts/ability.context';
import WebhookCreate from './WebhookCreate/WebhookCreate';
import TRANSACTION_STATUSES, { TRANSACTION_STATUS_LIST } from '../../../../../../constants/TRANSACTION_STATUSES';
import { components } from '../../../../Common/EditableCell/EditableCell';
import { WebhookUpdateMutation } from './query';
import { search } from '../../../../../../util/string';
import { compareByProp } from '../../../../../../util/array';
import Loader from '../../../../Common/Loader/Loader';

const Webhooks = ({ merchantAccount }) => {
  const can = useCan();
  const [visible, setVisible] = useState(false);

  const showCreationModal = () => {
    setVisible(true);
  };

  const hideCreationModal = () => {
    setVisible(false);
  };

  const [update, { loading }] = useMutation(WebhookUpdateMutation, {
    // onCompleted: () => message.success('Webhook successfully updated'),
    onError: (err) => message.error(err.message),
  });

  const dataSource = merchantAccount?.webhooks
    ?.map((webhook) => ({
      key: webhook.id,
      name: webhook.name,
      url: webhook.url,
      trigger: webhook.trigger,
      active: webhook.status === 'active',
      notify_last_transaction_only: webhook.notify_last_transaction_only,
    }))
    .sort(compareByProp('name', 'asc', 'string'));

  const handleSave = (row) => {
    update({
      variables: {
        id: row.key,
        input: {
          name: row.name,
          url: row.url,
          trigger: row.trigger,
          status: row.active ? 'active' : 'disabled',
          notify_last_transaction_only: row.notify_last_transaction_only,
        },
      },
      optimisticResponse: {
        updateWebhook: {
          __typename: 'Webhook',
          id: row.key,
          name: row.name,
          url: row.url,
          trigger: row.trigger,
          status: row.active ? 'active' : 'disabled',
          notify_last_transaction_only: row.notify_last_transaction_only,
        },
      },
    });
  };

  const columns = [
    {
      title: 'Label',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      editable: true,
      render: (label, { active }) => <Badge status={active ? 'success' : 'warning'} text={label} />,
      ...(can('update', 'webhook')
        ? {
            editable: can('update', 'webhook'),
            onCell: (record) => ({
              title: 'Label',
              dataIndex: 'name',
              editable: true,
              record,
              handleSave,
            }),
          }
        : {}),
      ...getColumnFilterSearchInput((value, record) => search(value, record.name)),
    },
    {
      title: 'Destination',
      dataIndex: 'url',
      key: 'url',
      ellipsis: true,
      editable: true,
      ...(can('update', 'webhook')
        ? {
            editable: can('update', 'webhook'),
            onCell: (record) => ({
              title: 'Destination',
              dataIndex: 'url',
              editable: true,
              record,
              handleSave,
            }),
          }
        : {}),
      ...getColumnFilterSearchInput((value, record) => search(value, record.url)),
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Triggers',
      dataIndex: 'trigger',
      key: 'trigger',
      editable: true,
      // eslint-disable-next-line react/destructuring-assignment
      render: (triggers) => triggers && Array.isArray(triggers) && <Tag color="lightgrey">{triggers.length}</Tag>,
      ...(can('update', 'webhook')
        ? {
            editable: can('update', 'webhook'),
            onCell: (record) => ({
              title: 'Triggers',
              dataIndex: 'trigger',
              editable: true,
              record,
              handleSave,
              formItem: 'select',
              formItemProps: {
                options: TRANSACTION_STATUS_LIST,
                mode: 'multiple',
                maxTagCount: 2,
              },
            }),
          }
        : {}),
    },
    {
      title: 'Notify last transaction only',
      dataIndex: 'notify_last_transaction_only',
      key: 'notify_last_transaction_only',
      align: 'center',
      // eslint-disable-next-line react/destructuring-assignment
      render: (lastOnly, row) => (
        <Switch
          checked={lastOnly}
          disabled={!can('update', 'webhook')}
          title={lastOnly ? 'Yes' : 'No'}
          onChange={(value) => handleSave({ ...row, notify_last_transaction_only: value })}
        />
      ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active, row) => (
        <Switch
          checked={active}
          disabled={!can('update', 'webhook')}
          title={active ? 'Active' : 'Inactive'}
          onChange={(value) => handleSave({ ...row, active: value })}
        />
      ),
      filters: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
      onFilter: (value, record) => value === record.active,
      fixed: 'right',
      width: 100,
    },
  ];

  // return info tile
  return (
    <>
      <TableTile
        name="ma-webhooks"
        title={
          <Space>
            Webhooks
            {loading && <Loader />}
          </Space>
        }
        columns={columns}
        dataSource={dataSource}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 5,
          pageSizeOptions: [5, 10, 20, 50, 100],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} webhooks`,
        }}
        size="small"
        scroll={{ x: 1000 }}
        expandable={{
          expandedRowRender: ({ trigger }) => (
            <div>
              <Divider className={styles.divider} orientation="left">
                Triggers
              </Divider>
              {trigger.map(
                (t) =>
                  TRANSACTION_STATUSES[t] && (
                    <Tag key={t} color="lightgrey">
                      {TRANSACTION_STATUSES[t].label}
                    </Tag>
                  ),
              )}
            </div>
          ),
          rowExpandable: ({ trigger }) => trigger && Array.isArray(trigger) && trigger.length > 0,
          fixed: 'right',
        }}
        extra={[
          can('create', 'webhook') && (
            <Button key="add-webhook" icon={<PlusOutlined />} onClick={showCreationModal}>
              Webhook
            </Button>
          ),
        ]}
        components={components}
        rowClassName={can('update', 'webhook') && 'editable-row'}
      />
      <WebhookCreate visible={visible} onClose={hideCreationModal} merchantAccount={merchantAccount} />
    </>
  );
};

export default Webhooks;

import React from 'react';
import { useQuery } from '@apollo/client';
import { ProgramManagerMerchantAclProfilesQuery } from './query';
import InfoTile from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import ProfilesOverviewTable from '../../../Common/ProfilesOverviewTable/ProfilesOverviewTable';

const AvailableInterfaces = ({ id }) => {
  const { data, loading } = useQuery(ProgramManagerMerchantAclProfilesQuery, {
    variables: {
      id,
    },
  });

  return (
    <InfoTile name="interfaces-overview" title="Available interfaces">
      <ProfilesOverviewTable loading={loading} aclProfiles={data?.programManager?.merchantAclProfiles ?? []} />
    </InfoTile>
  );
};

export default AvailableInterfaces;

import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { message } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useBrainpowerDashboardSettings } from '../hooks/useBrainpowerSettings';
import { useCompany } from '../../../../contexts/app.context';

export const DashboardContext = createContext({
  selectedWidgetId: undefined,
});

export const useSelectedWidgetId = () => {
  const { selectedWidgetId, setSelectedWidgetId } = useContext(DashboardContext);
  return [selectedWidgetId, setSelectedWidgetId];
};

export const useDroppingItem = () => {
  const { droppingItem, setDroppingItem } = useContext(DashboardContext);
  return [droppingItem, setDroppingItem];
};

const addDashboardMutation = gql`
  mutation AddDashboardMutation($dashboard: DashboardInput!) {
    addDashboard(dashboard: $dashboard) {
      id
      brainpower {
        dashboard {
          favorite
          dashboards {
            id
            name
            layout
            widgets
            createdBy {
              id
              full_name
              avatar
            }
          }
        }
      }
    }
  }
`;

const removeDashboardMutation = gql`
  mutation RemoveDashboardMutation($dashboardId: ID!) {
    removeDashboard(dashboardId: $dashboardId) {
      id
      brainpower {
        dashboard {
          dashboards {
            id
            name
            layout
            widgets
          }
        }
      }
    }
  }
`;

const updateDashboardMutation = gql`
  mutation UpdateDashboardMutation($id: ID!, $dashboard: DashboardInput!) {
    updateDashboard(id: $id, dashboard: $dashboard) {
      id
      name
      layout
      widgets
    }
  }
`;

const setFavoriteDashboardMutation = gql`
  mutation SetFavoriteDashboardMutation($dashboardId: ID!) {
    setFavoriteDashboard(dashboardId: $dashboardId) {
      id
      brainpower {
        dashboard {
          favorite
        }
      }
    }
  }
`;

const addCompanyDashboardMutation = gql`
  mutation AddCompanyDashboardMutation($dashboard: DashboardInput!) {
    addCompanyDashboard(dashboard: $dashboard) {
      id
      dashboards {
        id
        name
        layout
        widgets
      }
    }
  }
`;

const updateCompanyDashboardMutation = gql`
  mutation UpdateCompanyDashboardMutation($id: ID!, $dashboard: DashboardInput!) {
    updateCompanyDashboard(id: $id, dashboard: $dashboard) {
      id
      name
      layout
      widgets
    }
  }
`;

const removeCompanyDashboardMutation = gql`
  mutation RemoveCompanyDashboardMutation($dashboardId: ID!) {
    removeCompanyDashboard(dashboardId: $dashboardId) {
      id
      dashboards {
        id
        name
        layout
        widgets
      }
    }
  }
`;

export const CompanyDashboardsQuery = gql`
  query CompanyDashboards($id: ID!) {
    merchantCompany(id: $id) {
      id
      name
      dashboards {
        id
        name
        layout
        widgets
        createdBy {
          id
          full_name
          avatar
        }
        company {
          id
          name
        }
      }
    }
  }
`;

export const useDashboards = () => {
  const [settings] = useBrainpowerDashboardSettings();
  const { favorite, dashboards } = settings;

  const [company] = useCompany();
  const { data, refetch: refetchCompanyDashboards } = useQuery(CompanyDashboardsQuery, {
    variables: { id: company },
    skip: !company,
  });
  const companyDashboards = data?.merchantCompany.dashboards ?? [];

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedDashboardId = searchParams.get('dashboard');
  const setSelectedDashboardId = useCallback(
    (value) => {
      searchParams.set('dashboard', value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const [_addDashboard] = useMutation(addDashboardMutation);
  const addDashboard = useCallback(
    (dashboard, copy = false) =>
      _addDashboard({
        variables: { dashboard },
        onCompleted: () =>
          copy
            ? message.success('Dashboard successfully duplicated')
            : message.success('Dashboard successfully created'),
      }),
    [_addDashboard],
  );

  const [_removeDashboard] = useMutation(removeDashboardMutation);
  const removeDashboard = useCallback(
    (dashboardId) =>
      _removeDashboard({
        variables: { dashboardId },
        onCompleted: () => {
          message.success('Dashboard successfully deleted');
          setSelectedDashboardId(favorite);
        },
      }),
    [_removeDashboard, setSelectedDashboardId, favorite],
  );

  const [_updateDashboard] = useMutation(updateDashboardMutation);
  const updateDashboard = useCallback(
    (dashboard) => {
      const { id, name, layout, widgets } = dashboard;
      return _updateDashboard({
        variables: { id, dashboard: { name, layout, widgets } },
        optimisticResponse: {
          updateDashboard: dashboard,
        },
        context: {
          debounceKey: `updateDashboard-${id}`,
          debounceTimeout: 500,
        },
      });
    },
    [_updateDashboard],
  );

  const [_setFavoriteDashboard] = useMutation(setFavoriteDashboardMutation);
  const setFavoriteDashboard = useCallback(
    (dashboardId) => _setFavoriteDashboard({ variables: { dashboardId } }),
    [_setFavoriteDashboard],
  );

  const [_addCompanyDashboardMutation] = useMutation(addCompanyDashboardMutation);
  const addCompanyDashboard = useCallback(
    (dashboard) => {
      const { name, layout, widgets } = dashboard;
      return _addCompanyDashboardMutation({ variables: { dashboard: { name, layout, widgets } } });
    },
    [_addCompanyDashboardMutation],
  );

  const [_updateCompanyDashboardMutation] = useMutation(updateCompanyDashboardMutation);
  const updateCompanyDashboard = useCallback(
    (dashboard) => {
      const { id, name, layout, widgets } = dashboard;
      return _updateCompanyDashboardMutation({
        variables: { id, dashboard: { name, layout, widgets } },
        optimisticResponse: {
          updateDashboard: dashboard,
        },
        context: {
          debounceKey: `updateCompanyDashboard-${id}`,
          debounceTimeout: 2000,
        },
      });
    },
    [_updateCompanyDashboardMutation],
  );

  const [_removeCompanyDashboard] = useMutation(removeCompanyDashboardMutation, {
    onError: () => message.error('Failed to delete dashboard.'),
  });
  const removeCompanyDashboard = useCallback(
    (dashboardId) => _removeCompanyDashboard({ variables: { dashboardId } }),
    [_removeCompanyDashboard],
  );

  return {
    favorite,
    dashboards,
    addDashboard,
    removeDashboard,
    updateDashboard,
    setFavoriteDashboard,
    selectedDashboardId,
    setSelectedDashboardId,
    addCompanyDashboard,
    updateCompanyDashboard,
    removeCompanyDashboard,
    companyDashboards,
    refetchCompanyDashboards,
  };
};

export const useDashboard = () => {
  const { dashboards, companyDashboards, favorite, updateDashboard, selectedDashboardId, setSelectedDashboardId } =
    useDashboards();

  const [dashboard, editable] = useMemo(() => {
    const userDashboard = dashboards.find((d) => d.id === selectedDashboardId);
    if (userDashboard) return [userDashboard, true];
    const companyDashboard = companyDashboards.find((d) => d.id === selectedDashboardId);
    if (companyDashboard) return [companyDashboard, false];
    return [null, false];
  }, [dashboards, companyDashboards, selectedDashboardId]);

  useEffect(() => {
    if (!selectedDashboardId || !dashboard) {
      setSelectedDashboardId(favorite ?? dashboards[0].id);
    }
  }, [selectedDashboardId, dashboard]);

  const updateLayout = useCallback(
    (layout) =>
      updateDashboard({
        ...dashboard,
        layout: layout.map((element) => ({
          x: element.x,
          y: element.y,
          h: element.h,
          w: element.w,
          minH: element.minH,
          minW: element.minW,
          isResizable: element.isResizable,
          i: element.i,
        })),
      }),
    [updateDashboard, dashboard],
  );

  const addWidget = useCallback(
    (widget, layout) =>
      updateDashboard({
        ...dashboard,
        layout,
        widgets: {
          ...dashboard.widgets,
          [widget.i]: widget,
        },
      }),
    [updateDashboard, dashboard],
  );

  const updateWidget = useCallback(
    (widget) =>
      updateDashboard({
        ...dashboard,
        widgets: {
          ...dashboard.widgets,
          [widget.i]: widget,
        },
      }),
    [updateDashboard, dashboard],
  );

  const removeWidget = useCallback(
    (widgetId) =>
      updateDashboard({
        ...dashboard,
        layout: dashboard.layout.filter((element) => element.i !== widgetId),
        widgets: {
          ...dashboard.widgets,
          [widgetId]: undefined,
        },
      }),
    [updateDashboard, dashboard],
  );

  return {
    dashboard,
    updateLayout,
    addWidget,
    updateWidget,
    removeWidget,
    editable,
  };
};

import React from 'react';
import classNames from 'classnames';
import { Form, Row } from 'antd';
import styles from './style.module.scss';
import FormField from './FormField';

const ListFilterForm = ({ fields, onValuesChange, visible }) => (
  <Form
    className={classNames(styles.searchForm, { [styles.hidden]: !visible })}
    name="advanced_search"
    onValuesChange={onValuesChange}
  >
    <Row gutter={24}>
      {fields
        .filter((f) => !f.hidden)
        .map((field) => (
          <FormField
            key={field.name}
            name={field.name}
            label={field.label}
            fieldType={field.fieldType}
            fieldProps={field.fieldProps}
            formItemProps={field.formItemProps}
          />
        ))}
    </Row>
  </Form>
);

export default ListFilterForm;

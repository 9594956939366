import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Select, Space } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { COUNTRY_LIST } from '../../../../../../constants/COUNTRIES';
import { useCan } from '../../../../../../contexts/ability.context';
import InfoTile, { InfoCountryRow, InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { CompanyUpdateMutation } from '../../../Company/query';
import styles from './ProgramManagerCompanyInformation.module.scss';
import { programManagerCompanyQuery } from './query';

const ProgramManagerCompanyInformation = ({ id }) => {
  const can = useCan();

  const [editMode, setEditMode] = useState(false);

  const { data: { programManager: { company } } = { programManager: { company: null } } } = useQuery(
    programManagerCompanyQuery,
    {
      variables: {
        id,
      },
    },
  );

  const [updateCompany, { loading }] = useMutation(CompanyUpdateMutation, {
    onCompleted: () => {
      message.success('Company successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = ({ name, country }) => {
    updateCompany({
      variables: { id: company?.id, input: { name, country } },
    });
  };

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="program-manager-company-information" title="Company">
        <Form
          form={form}
          title="Company"
          layout="vertical"
          initialValues={company}
          onFinish={handleFinish}
          requiredMark={false}
          className="tile-form"
        >
          <Form.Item label="Company name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Company ID" name="id">
            <Input disabled />
          </Form.Item>
          <Form.Item label="Country" name="country">
            <Select
              showSearch
              placeholder="Select a country"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {COUNTRY_LIST.map((c) => (
                <Select.Option key={c.value} value={c.value}>
                  <Space>
                    <img src={c.img} alt={c.value} style={{ width: 24 }} />
                    {c.label}
                  </Space>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  return (
    <InfoTile
      name="program-manager-company"
      title="Company"
      extra={
        can('update', 'program-manager') && (
          <Button onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        )
      }
    >
      <InfoTextRow label="Company name" value={company?.name} />
      <InfoTextRow label="Company ID" value={company?.id} />
      <InfoCountryRow label="Country" value={company?.country} />
    </InfoTile>
  );
};

export default ProgramManagerCompanyInformation;

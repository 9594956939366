import React from 'react';
import Loader from '../../../../Common/Loader/Loader';
import styles from './common.module.scss';

const LoadingChart = () => (
  <div className={styles.container}>
    <Loader width={50} height={50} />
  </div>
);

export default LoadingChart;

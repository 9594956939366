import { useState } from 'react';
import { useInterval } from 'react-use';
import moment, { isMoment } from 'moment-timezone';

/**
 * @param tillDate Moment
 * @returns {*}
 * @constructor
 */
const Countdown = ({ tillDate }) => {
  const [countdown, setCountdown] = useState('...');

  useInterval(() => {
    if (isMoment(tillDate)) {
      setCountdown(`${-moment().diff(tillDate, 'minutes')} minutes`);
    }
  }, 1000);

  return countdown;
};

export default Countdown;

import ChallengePreferences from 'norbr-shared-lib/constants/strongCustomerAuthentication/challengePreferences/list';
import ExemptionReasons from 'norbr-shared-lib/constants/strongCustomerAuthentication/exemptionReasons/list';

export const ResultType = {
  SCA_EXEMPTION_REASON: 'sca_exemption_reason',
  CHALLENGE_PREFERENCE: 'challenge_preference',
};

export const challengePreferenceOptions = ChallengePreferences.map((o) => ({
  value: o.id,
  label: o.label,
  selectLabel: `Challenge - ${o.label}`,
  type: ResultType.CHALLENGE_PREFERENCE,
}));
export const exemptionReasonOptions = ExemptionReasons.map((o) => ({
  value: o.id,
  label: o.label,
  selectLabel: `Exemption reason - ${o.label}`,
  type: ResultType.SCA_EXEMPTION_REASON,
}));

export default {};

import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import { useLazyQuery } from '@apollo/client';
import InfoTile, { InfoTextRow } from '../../../Tile/Info/Info';
import { activityCustomerAddressQuery, orderCustomerAddressQuery } from './query';
import CustomerAddressSkeleton from './CustomerAddressSkeleton';

const CustomerAddress = () => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(
    activityCustomerAddressQuery,
    {
      variables: {
        orderId,
      },
    },
  );

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(orderCustomerAddressQuery, {
    variables: {
      orderId,
    },
    onCompleted: (data) => !data.order && fetchInsights(),
  });

  const transaction = orderStack?.last_transaction ?? orderInsights?.last_transaction;

  if (!transaction) return <CustomerAddressSkeleton />;

  return (
    <InfoTile name="customer-address" title="Customer address">
      <InfoTextRow label="Street name" value={transaction.customer_street_name} />
      <InfoTextRow label="City" value={transaction.customer_city} />
      <InfoTextRow label="ZIP code" value={transaction.customer_zip_code} />
      <InfoTextRow label="State" value={transaction.customer_state} />
      <InfoTextRow label="Country" value={transaction.customer_country} />
    </InfoTile>
  );
};

export default CustomerAddress;

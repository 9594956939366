import { useCallback, useState } from 'react';

const DEFAULT_PAGE_SIZE = 50;

const useStatePagination = (defaultPageSize = DEFAULT_PAGE_SIZE) => {
  const [page, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const setPage = useCallback(
    (newPage = 1, newPageSize = defaultPageSize) => {
      setPageNumber(newPage);
      setPageSize(newPageSize);
    },
    [page, setPageNumber, pageSize, setPageSize],
  );

  const nextPage = useCallback(() => setPageNumber(page + 1), [page, setPageNumber]);

  const offset = (page - 1) * pageSize;
  const limit = pageSize;

  return { page, pageSize, setPage, offset, limit, nextPage };
};

export default useStatePagination;

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from '@amcharts/amcharts4/charts';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import { compareByProp } from '../../../../../../util/array';

/**
 * Lifetime value
 * @param divId
 * @param widget
 * @param data
 * @param args
 * @returns {XYChart}
 */
export default (divId, widget, data, args) => {
  // Create chart instance
  const chart = am4core.create(divId, XYChart);

  // Month's X axis
  const monthAxis = chart.xAxes.push(new DateAxis());
  monthAxis.dataFields.dateX = 'first_purchase_month';
  monthAxis.baseInterval = { timeUnit: 'month', count: 1 };
  monthAxis.dateFormatter.dateFormat = 'MMMM YYYY';
  monthAxis.renderer.grid.template.disabled = true;

  // Value's Y axis
  const valueAxis = chart.yAxes.push(new ValueAxis());
  valueAxis.title.text = `Lifetime value - ${CURRENCIES[args.currency].symbol}`;
  valueAxis.renderer.grid.template.disabled = true;

  // Customer's Y axis
  const customerAxis = chart.yAxes.push(new ValueAxis());
  customerAxis.title.text = 'Customers';
  customerAxis.renderer.opposite = true;

  const customerSeries = chart.series.push(new ColumnSeries());
  customerSeries.name = 'New customers';
  customerSeries.dataFields.valueY = 'customers';
  customerSeries.dataFields.dateX = 'first_purchase_month';
  customerSeries.yAxis = customerAxis;

  const ltv3Series = chart.series.push(new LineSeries());
  ltv3Series.name = 'LTV 3 months';
  ltv3Series.dataFields.valueY = 'ltv_3_months';
  ltv3Series.dataFields.dateX = 'first_purchase_month';
  ltv3Series.strokeWidth = 2;
  ltv3Series.yAxis = valueAxis;
  const ltv6Series = chart.series.push(new LineSeries());
  ltv6Series.name = 'LTV 6 months';
  ltv6Series.dataFields.valueY = 'ltv_6_months';
  ltv6Series.dataFields.dateX = 'first_purchase_month';
  ltv6Series.strokeWidth = 2;
  ltv6Series.yAxis = valueAxis;
  const ltv12Series = chart.series.push(new LineSeries());
  ltv12Series.name = 'LTV 12 months';
  ltv12Series.dataFields.valueY = 'ltv_12_months';
  ltv12Series.dataFields.dateX = 'first_purchase_month';
  ltv12Series.strokeWidth = 2;
  ltv12Series.yAxis = valueAxis;
  const ltv24Series = chart.series.push(new LineSeries());
  ltv24Series.name = 'LTV 24 months';
  ltv24Series.dataFields.valueY = 'ltv_24_months';
  ltv24Series.dataFields.dateX = 'first_purchase_month';
  ltv24Series.strokeWidth = 2;
  ltv24Series.yAxis = valueAxis;

  chart.cursor = new am4charts.XYCursor();

  // Single aggregated tooltip
  customerSeries.adapter.add('tooltipText', () => {
    let text = '[#000]{dateX}[/]\n';
    text += `[${customerSeries.stroke.hex}]●[/][#000] ${customerSeries.name}: {${customerSeries.dataFields.valueY}}[/]\n`;
    [ltv3Series, ltv6Series, ltv12Series, ltv24Series].forEach((item) => {
      text += `[${item.stroke.hex}]●[/][#000] ${item.name}: {${item.dataFields.valueY}.formatNumber('#,###.00')} ${CURRENCIES[args.currency].symbol}[/]\n`;
    });
    return text;
  });
  customerSeries.tooltip.background.fill = am4core.color('#fff');
  customerSeries.tooltip.getFillFromObject = false;

  // Add legend.
  chart.legend = new am4charts.Legend();
  const markerTemplate = chart.legend.markers.template;
  markerTemplate.width = 15;
  markerTemplate.height = 15;

  chart.data = [...data.data].sort(compareByProp('first_purchase_month'));

  return chart;
};

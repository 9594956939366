import React from 'react';
import InfoTile, { InfoSkeletonRow } from '../../../Tile/Info/Info';

const CustomerDetails = () => (
  <InfoTile name="customer-details" title="Customer details">
    <InfoSkeletonRow label="Email address" />
    <InfoSkeletonRow label="Customer ID" />
    <InfoSkeletonRow label="First name" />
    <InfoSkeletonRow label="Surname" />
    <InfoSkeletonRow label="ID number" />
    <InfoSkeletonRow label="Gender" />
    <InfoSkeletonRow label="Date of birth" />
    <InfoSkeletonRow label="Phone number" />
    <InfoSkeletonRow label="Phone number type" />
    <InfoSkeletonRow label="IP address" />
    <InfoSkeletonRow label="IP country" />
    <InfoSkeletonRow label="Device type" />
    <InfoSkeletonRow label="Login type" />
  </InfoTile>
);

export default CustomerDetails;

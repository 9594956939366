import { gql } from "@apollo/client";

export const removeListMutation = gql`
  mutation RemoveList ($id: ID!) {
    removeList (id: $id) {
      id
    }
  }
`;

export const listContentQuery = gql`
  query ListContent ($id: ID!) {
    list (id: $id) {
      id
      content
    }
  }
`;
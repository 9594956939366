import React from 'react';
import classnames from 'classnames';
import { Space } from 'antd';
import { Link } from '../../../util/navigate';
import { useProgramManager } from '../../../contexts/programManager.context';
import logoNorbr from '../../../assets/images/logo/norbr-white.svg';
import logoN from '../../../assets/images/logo/n-white.svg';
import { useLayoutMenu } from '../../layout.context';
import styles from './TopLogo.module.scss';
import {useMetaProgramManager} from '../../../contexts/metaProgramManager.context';

const TopLogo = () => {
  const programManager = useProgramManager();
  const metaProgramManager = useMetaProgramManager();
  const icon = (programManager || metaProgramManager) ? programManager?.theme?.icon ?? metaProgramManager?.theme?.icon : logoN;
  const logo = (programManager || metaProgramManager) ? programManager?.theme?.logo ?? metaProgramManager?.theme?.logo : logoNorbr;
  const [isMenuCollapsed] = useLayoutMenu();

  const noImgAvatar = (
    <Space>
      <div
        className={classnames(styles.nameContainer, {[styles.collapsed]: isMenuCollapsed})}
      >
        {(programManager?.displayName ?? metaProgramManager?.displayName)}
      </div>
    </Space>
  )

  if (isMenuCollapsed) {
    return (
      <Link className={styles.root} to="/home">
        {icon ? (
          <img src={icon} alt="company-icon1" />
        ) : ( noImgAvatar )}
      </Link>
    )
  }
  return (
    <Link className={styles.root} to="/home">
      {logo ? (
        <img src={logo} alt="logo" />
      ) : ( noImgAvatar )}
    </Link>
  );
};

export default TopLogo;

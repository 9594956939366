import React, { useContext } from 'react';
import { Checkbox, Form, Tooltip } from 'antd';
import classnames from 'classnames';
import { PaymentMethodContext } from '../../PaymentMethodForm';
import styles from '../RoutesForm.module.scss';
import LordIcon from '../../../../../../../../../assets/icons/LordIcon';
import { MerchantContractContext } from '../../../MerchantContractDrawer';

const { Item } = Form;

const AvailableServices = () => {
  const paymentMethod = useContext(PaymentMethodContext);

  const { merchantContract } = useContext(MerchantContractContext);

  return (
    <Item label="Available services" className={styles.root}>
      {[
        {
          value: 'is_cancellable',
          label: 'Cancel',
        },
        {
          value: 'is_capturable',
          label: 'Capture',
        },
        {
          value: 'is_3ds_available',
          label: '3D Secure',
        },
        {
          value: 'is_refundable',
          label: 'Refund',
        },
        {
          value: 'is_partial_refund_available',
          label: 'Partial refund',
        },
        {
          value: 'is_differed_capture_available',
          label: 'Differed capture',
        },
        {
          value: 'is_partial_capture_available',
          label: 'Partial capture',
        },
        {
          value: 'is_multiple_captures_available',
          label: 'Multi-capture',
        },
        {
          value: 'is_multiple_refunds_available',
          label: 'Multi-refund',
        },
      ].map((service) => {
        if (
          paymentMethod.partner_routes
            .filter((partnerRoute) =>
              merchantContract.merchant_routes.find(
                (merchantRoute) => merchantRoute.partner_route.id === partnerRoute.id,
              ),
            )
            .reduce((acc, partnerRoute) => {
              if (partnerRoute[service.value] !== true && partnerRoute[service.value] !== false) {
                return partnerRoute.partner[service.value] && acc;
              }
              return partnerRoute[service.value] && acc;
            }, !!paymentMethod.partner_routes.length)
        ) {
          return (
            <div className={styles.checkboxContainer}>
              <Checkbox disabled checked>
                {service.label}
              </Checkbox>
            </div>
          );
        }
        if (
          paymentMethod.partner_routes
            .filter((partnerRoute) =>
              merchantContract.merchant_routes.find(
                (merchantRoute) => merchantRoute.partner_route.id === partnerRoute.id,
              ),
            )
            .reduce((acc, partnerRoute) => {
              if (partnerRoute[service.value] !== true && partnerRoute[service.value] !== false) {
                return partnerRoute.partner[service.value] || acc;
              }
              return partnerRoute[service.value] || acc;
            }, false)
        ) {
          return (
            <div className={classnames(styles.checkboxContainer, styles.partialCheckboxContainer)}>
              <Checkbox disabled checked>
                {service.label}
              </Checkbox>
              <Tooltip title="This service is not available for all authorization currencies or sales channels">
                <LordIcon key="warningIcon" classes={{ [styles.warningIcon]: true }} type="error" height={24} />
              </Tooltip>
            </div>
          );
        }
        return (
          <div className={styles.checkboxContainer}>
            <Checkbox disabled checked={false}>
              {service.label}
            </Checkbox>
          </div>
        );
      })}
    </Item>
  );
};

export default AvailableServices;

import { gql, useMutation } from '@apollo/client';
import { useMe } from '../../../../contexts/me.context';

export const Granularity = {
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
};

export const granularities = {
  [Granularity.HOUR]: { value: Granularity.HOUR, label: 'Hour', format: 'DD/MM/YYYY HH[h]' },
  [Granularity.DAY]: { value: Granularity.DAY, label: 'Day', format: 'DD/MM/YYYY' },
  [Granularity.WEEK]: { value: Granularity.WEEK, label: 'Week', format: 'YYYY [W]WW' },
  [Granularity.MONTH]: { value: Granularity.MONTH, label: 'Month', format: 'YYYY MMMM' },
  [Granularity.QUARTER]: { value: Granularity.QUARTER, label: 'Quarter', format: 'YYYY [Q]Q' },
};

export const granularityList = Object.values(granularities);

const UserMeMutation = gql`
  mutation UserMeMutation($input: UserUpdateInput!) {
    updateMe(input: $input) {
      __typename
      id
      baseGranularity
    }
  }
`;

const useBaseGranularity = () => {
  const me = useMe();
  const [updateUserMe] = useMutation(UserMeMutation);

  const baseGranularity = me?.baseGranularity ?? Granularity.DAY;
  const setBaseGranularity = (value) => updateUserMe({ variables: { input: { baseGranularity: value } } });

  return [baseGranularity, setBaseGranularity];
};

export default useBaseGranularity;

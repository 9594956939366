import React from 'react';
import classnames from "classnames";
import { Button, Space } from 'antd';
import styles from '../../Brainpower.module.scss';

const HeaderButton = ({ drawerState, id, label, icon: Icon, ...buttonProps }) => (
  <Button className={classnames(styles.headerButton, {[styles.active]: drawerState === id})} id={id} type="text" {...buttonProps}>
    <Space>
      {label}
      <Icon />
    </Space>
  </Button>
);

export default HeaderButton;
import { gql } from '@apollo/client';

export const metaProgramManagerListQuery = gql`
  query MetaProgramManagers {
    metaProgramManagers {
      id
      name
      company {
        name
      }
      theme {
        logo
        icon
        primaryColor
        secondaryColor
      }
    }
  }
`;

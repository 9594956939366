import { gql } from '@apollo/client';
import { FragmentPartnerRouteOptions } from '../constants';

export const PartnerRoutesUpdateMutation = gql`
  mutation PartnerRoutesUpdateMutation($idList: [ID!]!, $input: PartnerRouteUpdateInput!) {
    updatePartnerRoutes(idList: $idList, input: $input) {
      __typename
      id
      status
      ${FragmentPartnerRouteOptions}
      request_expiration_delay
    }
  }
`;

export const ImpactMerchantContractsQuery = gql`
  query ImpactMerchantContracts($filters: Object) {
    partnerRoutes(filters: $filters) {
      data {
        payment_method {
          id
          name
          imgUrl
        }
        merchantRoutes {
          merchant_account {
            id
            name
            company {
              id
              name
              theme {
                icon
              }
            }
            programManager {
              id
              name
              theme {
                icon
                primaryColor
                secondaryColor
              }
            }
          }
          merchant_contract {
            id
            name
          }
        }
      }
      count
    }
  }
`;

export default {};

import React, { useEffect, useState } from 'react';
import { Button, Col, Drawer, Form, Input, InputNumber, Row, Select, Slider, Space } from 'antd';
import { useMutation } from '@apollo/client';
import styles from './ContactForm.module.scss';
import { addMetaProgramManagerContactMutation, updateMetaProgramManagerContactMutation } from './query';
import { contactTypes } from '../constants';

const ContactForm = ({ visible, metaProgramManager, toggle, contact }) => {
  const [form] = Form.useForm();

  const [priorityLevel, setPriorityLevel] = useState(contact?.priorityLevel ?? 5);

  const [addContact, { loading: loadingCreate }] = useMutation(addMetaProgramManagerContactMutation, {
    variables: {
      id: metaProgramManager.id,
    },
  });

  const [updateContact, { loading: loadingUpdate }] = useMutation(updateMetaProgramManagerContactMutation, {
    variables: {
      id: metaProgramManager.id,
      contactId: contact?._id,
    },
  });

  useEffect(() => {
    form.initialValues = contact;
    form.resetFields();
    setPriorityLevel(contact?.priorityLevel ?? 5);
  }, [contact]);

  return (
    <Drawer
      title={contact ? 'Edit contact' : 'Add new Contact'}
      open={visible}
      onClose={toggle}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(input) => {
          if (contact) {
            updateContact({
              variables: {
                input: {
                  ...input,
                  priorityLevel,
                },
              },
            });
          } else {
            addContact({
              variables: {
                input: {
                  ...input,
                  priorityLevel,
                },
              },
              optimisticResponse: {
                addMetaProgramManagerContact: {
                  ...metaProgramManager,
                  contacts: [
                    ...metaProgramManager.contacts,
                    {
                      _id: 'temp',
                      ...input,
                      priorityLevel,
                    },
                  ],
                },
              },
            });
          }
          form.resetFields();
          setPriorityLevel(5);
          toggle();
        }}
        initialValues={contact}
      >
        <Form.Item label="First name" name="firstname" rules={[{ required: true, message: '* required' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Last name" name="surname" rules={[{ required: true, message: '* required' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="Phone">
          <Space>
            <Form.Item name={['phone', 'code']} noStyle>
              <Input style={{ width: 65 }} placeholder="31" prefix="+" />
            </Form.Item>
            <Form.Item name={['phone', 'number']} noStyle>
              <Input placeholder="123456789" />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="Title" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Type" name="type" rules={[{ required: true }]}>
          <Select options={contactTypes} />
        </Form.Item>
        <Form.Item label="Level" name="priorityLevel">
          <Row justify="space-between">
            <Col span={16}>
              <Slider
                marks={{
                  1: 'High',
                  10: 'Low',
                }}
                value={priorityLevel}
                step={1}
                min={1}
                max={10}
                onChange={(value) => setPriorityLevel(value)}
                included={false}
              />
            </Col>
            <Col span={6}>
              <InputNumber
                style={{
                  width: 60,
                }}
                min={1}
                max={10}
                step={1}
                value={priorityLevel}
                onChange={(value) => setPriorityLevel(value)}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item className={styles.submitItem}>
          <Space>
            <Button onClick={toggle}>Cancel</Button>
            <Button onClick={() => form.resetFields()}>Reset</Button>
            <Button type="primary" htmlType="submit" loading={loadingUpdate || loadingCreate}>
              Save
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ContactForm;

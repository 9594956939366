import React from 'react';
import InfoTile, { InfoSkeletonRow } from '../../../Tile/Info/Info';

const PaymentDetailsSkeleton = () => (
  <InfoTile name="payment-details" title="Payment details">
    <InfoSkeletonRow label="Status" />
    <InfoSkeletonRow label="Card / Account number" />
    <InfoSkeletonRow label="Card token" />
    <InfoSkeletonRow label="Payment method" />
    <InfoSkeletonRow label="Holder" />
    <InfoSkeletonRow label="Customer scheme name" />
    <InfoSkeletonRow label="Real scheme" />
    <InfoSkeletonRow label="Secondary scheme" />
    <InfoSkeletonRow label="Card category" />
    <InfoSkeletonRow label="Card type" />
    <InfoSkeletonRow label="Authentication indicator" />
    <InfoSkeletonRow label="Authentication result" />
    <InfoSkeletonRow label="Challenge preference" />
    <InfoSkeletonRow label="SCA exemption reason" />
    <InfoSkeletonRow label="Authorization code" />
    <InfoSkeletonRow label="Issuer / Bank" />
    <InfoSkeletonRow label="Issuing country" />
    <InfoSkeletonRow label="Account IBAN" />
    <InfoSkeletonRow label="Account BIC" />
    <InfoSkeletonRow label="Device fingerprint" />
  </InfoTile>
);

export default PaymentDetailsSkeleton;

const ExportStatuses = {
  PENDING: 'pending',
  SUCCESS: 'success',
  SCHEDULED: 'scheduled',
  CREATED: 'created',
  DELETED: 'deleted',
  FAILED_TO_EXTRACT: 'failed_to_extract',
  FAILED_TO_SEND: 'failed_to_send',
  PAUSED: 'paused',
};

export const exportStatuses = {
  [ExportStatuses.PENDING]: {
    value: ExportStatuses.PENDING,
    label: 'Pending',
    badge: 'processing',
    color: 'orange',
  },
  [ExportStatuses.SUCCESS]: {
    value: ExportStatuses.SUCCESS,
    label: 'Success',
    badge: 'success',
    color: 'green',
  },
  [ExportStatuses.SCHEDULED]: { value: ExportStatuses.SCHEDULED, label: 'Scheduled', badge: 'default', color: 'grey' },
  [ExportStatuses.CREATED]: { value: ExportStatuses.CREATED, label: 'Created', badge: 'default', color: 'grey' },
  [ExportStatuses.DELETED]: { value: ExportStatuses.DELETED, label: 'Deleted', badge: 'error', color: 'red' },
  [ExportStatuses.FAILED_TO_EXTRACT]: {
    value: ExportStatuses.FAILED_TO_EXTRACT,
    label: 'Failed to extract',
    badge: 'error',
    color: 'red',
  },
  [ExportStatuses.FAILED_TO_SEND]: {
    value: ExportStatuses.FAILED_TO_SEND,
    label: 'Failed to send',
    badge: 'error',
    color: 'red',
  },
  [ExportStatuses.PAUSED]: { value: ExportStatuses.PAUSED, label: 'Paused', badge: 'warning', color: 'orange' },
};

export const exportStatusList = Object.values(exportStatuses);

export default ExportStatuses;

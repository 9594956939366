import { gql } from '@apollo/client';

export const InternalUserListQuery = gql`
  query InternalUserListQuery {
    internalUsers {
      id
      email
      full_name
      first_name
      last_name
      avatar
      status
      disabled
      createdAt
      lastConnection
      isGdpr
      aclProfile {
        id
        name
      }
    }
  }
`;

export default {};

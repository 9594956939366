import { gql } from '@apollo/client';

export const UserThemeUpdateMutation = gql`
  mutation UserThemeUpdateMutation($input: UserUpdateInput!) {
    updateMe(input: $input) {
      __typename
      id
      theme {
        primaryColor
        secondaryColor
      }
    }
  }
`;

export default {};

import { gql } from '@apollo/client';

const merchantAccountsQuery = gql`
  query MerchantAccounts($company: ID!) {
    merchantAccounts(filters: { company: $company }) {
      id
      name
      source
    }
  }
`;

export default merchantAccountsQuery;

import React, { useCallback, useContext, useMemo } from 'react';
import { CodeSandboxOutlined } from '@ant-design/icons';
import { reorder } from '../../../../../../../util/array';
import { useSelectedWidgetId } from '../../../dashboard.context';
import { config as Dimensions } from '../../../constants/dimensions';
import Axis from '../../../constants/axis';
import { config as WidgetTypes } from '../../../constants/widgetTypes';
import WidgetContext from './widget.context';
import SortableList from './SortableList/SortableList';
import SortableItem from './SortableList/SortableItem';

const DimensionSortableList = () => {
  const [widgetId] = useSelectedWidgetId();

  const { widget, updateWidget, editable } = useContext(WidgetContext);

  const widgetType = useMemo(() => {
    if (widget) {
      if (WidgetTypes[widget.type]) {
        return WidgetTypes[widget.type];
      }
      throw new Error(`Widget type ${widget.type} does not exist. (${widgetId})`);
    }
    return null;
  }, [widget]);

  const updateWidgetDimensions = useCallback(
    (newDimensions) =>
      updateWidget({
        ...widget,
        dimensions: newDimensions,
      }),
    [widget, updateWidget],
  );

  const handleRemove = useCallback(
    (removedId) => {
      const newDimensions = widget.dimensions.filter((d) => d !== removedId);
      updateWidgetDimensions(newDimensions);
    },
    [widget, updateWidget],
  );

  const handleSort = useCallback(
    (sourceIndex, destinationIndex) => {
      // reorder dimensions
      const newDimensions = reorder(widget.dimensions, sourceIndex, destinationIndex);
      updateWidgetDimensions(newDimensions);
    },
    [widget, updateWidget],
  );

  const dimensions = useMemo(
    () =>
      widget?.dimensions.map((d) => {
        if (Dimensions[d]) {
          return Dimensions[d];
        }
        throw new Error(`Dimension ${d} does not exist. (${widgetId})`);
      }),
    [widget],
  );

  // disable remove button based on min dimensions
  const isRemovable = editable && widget?.dimensions.length > widgetType.minDimensions;

  return (
    <SortableList
      droppableId="dimensions"
      type="dimension"
      label="Dimensions"
      separatorIndex={widgetType.maxDimensions}
      onSort={handleSort}
    >
      {dimensions.map((dimension, index) => (
        <SortableItem
          key={dimension.id}
          id={dimension.id}
          label={dimension.label}
          color={dimension.color}
          icon={CodeSandboxOutlined}
          index={index}
          onRemove={handleRemove}
          isRemovable={isRemovable}
          isDragDisabled={!editable || widget.dimensions.length === 1}
          isDisabled={
            index >= widgetType.maxDimensions ||
            (widgetType.id !== 'table' && dimension.axis === Axis.Date && index > 0) // date dimensions only available as 1st dim
          }
        />
      ))}
    </SortableList>
  );
};

export default DimensionSortableList;

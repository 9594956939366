import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import {useLazyQuery} from '@apollo/client';
import InfoTile, { InfoCountryRow, InfoTextRow } from '../../../Tile/Info/Info';
import { orderBillingDetailsQuery, activityBillingDetailsQuery } from './query';
import BillingDetailsSkeleton from './BillingDetailsSkeleton';

const BillingDetails = () => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(activityBillingDetailsQuery, {
    variables: {
      orderId,
    }
  });

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(
    orderBillingDetailsQuery,
    {
      variables: {
        orderId,
      },
      onCompleted: data => !data.order && fetchInsights(),
    },
  );

  const transaction = orderStack?.last_transaction ?? orderInsights?.last_transaction;

  if (!transaction) return <BillingDetailsSkeleton />;

  return (
    <InfoTile name="billing-details" title="Billing details">
      <InfoTextRow label="First name" value={transaction.customer_billing_first_name} />
      <InfoTextRow label="Surname" value={transaction.customer_billing_last_name} />
      <InfoTextRow label="Street" value={transaction.customer_billing_street_name} />
      <InfoTextRow label="ZIP code" value={transaction.customer_billing_zip_code} />
      <InfoTextRow label="City" value={transaction.customer_billing_city} />
      <InfoTextRow label="State" value={transaction.customer_billing_state} />
      <InfoCountryRow label="Country" value={transaction.customer_billing_country} />
    </InfoTile>
  );
};

export default BillingDetails;

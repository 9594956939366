import { gql } from '@apollo/client';

export const CAPTURE_ORDER_MUTATION = gql`
  mutation CaptureOrder($id: ID!, $amount: Float!) {
    captureOrder(id: $id, amount: $amount) {
      _id
      id
      last_transaction {
        _id
        id
        status
        status_history {
          date
          status
        }
        is_cancellable
        is_refundable
        is_capturable
        currency
        capturable_amount
        refundable_amount
        captured_amount
        refunded_amount
        chargeback_amount
        operations {
          _id
          id
          request_date
          request_status
          result_response
          result_date
          result_status
          type
          amount
        }
      }
    }
  }
`;

export default {};

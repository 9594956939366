import { PartitionOutlined, SafetyCertificateOutlined} from '@ant-design/icons';
import React from 'react';
import AccountMultipleIcon from '@2fd/ant-design-icons/lib/AccountMultiple';

const MetaProgramManagers = (can) => {
  if (can('read', 'meta-program-manager')) {
    return [
      {
        type: 'group',
        label: 'Meta Program Managers',
        children: [
          ...(can('read', 'meta-program-manager')
            ? [{ key: '/meta-program-managers', icon: <PartitionOutlined />, label: 'Meta Program Managers' }]
            : []),
          ...(can('read', 'meta-program-manager-user')
            ? [{ key: '/meta-program-users', icon: <AccountMultipleIcon />, label: 'Meta PM users' }]
            : []),
          ...(can('read', 'meta-program-manager-access-control')
            ? [{ key: '/meta-program-access-control-list', icon: <SafetyCertificateOutlined />, label: 'Meta PM rights' }]
            : []),
        ],
      },
    ]
  }
  return [];
}

 export default MetaProgramManagers;
import React from 'react';
import { Checkbox, Col, Form, Input, Select, Space } from 'antd';

/**
 * FormField - return a Col > Form.Item > Field (based on fieldType)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormField = (props) => {
  const { name, label, fieldType, fieldProps, formItemProps = {} } = props;
  let formField;
  switch (fieldType) {
    case 'select':
      // eslint-disable-next-line no-case-declarations
      const { options = [], ...selectProps } = fieldProps;
      formField = (
        <Select {...selectProps} allowClear>
          {options.map((o) => (
            <Select.Option key={o.value} value={o.value} label={o.label}>
              <Space>
                {o.img && <img src={o.img} alt={o.value} style={{ width: 24, backgroundColor: o.color }} />}
                {o.label}
              </Space>
            </Select.Option>
          ))}
        </Select>
      );
      break;
    case 'checkbox':
      formItemProps.valuePropName = 'checked';
      formField = <Checkbox {...fieldProps} />;
      break;
    case 'input':
      formField = <Input {...fieldProps} allowClear />;
      break;
    default:
      throw new Error(`Unknown form field type "${fieldType}".`);
  }

  return (
    <Col sm={24} md={12} lg={8} xl={6}>
      <Form.Item name={name} label={label} {...formItemProps}>
        {formField}
      </Form.Item>
    </Col>
  );
};

export default FormField;

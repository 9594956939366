import React from 'react';
import { Button, Divider, Form, Select, Space, Typography } from 'antd';
import CURRENCY_LIST from 'norbr-shared-lib/constants/currencies/list';
import { getSelectAllProps } from '../../../../../util/antd';
import { searchByProp } from '../../../../../util/string';
import { usePaymentMethodList } from '../../../../../hooks/common/usePaymentMethods';
import { PAYMENT_CHANNEL_LIST } from '../../../../../constants/PAYMENT_CHANNELS';
import PartnerRouteOptionsForm from '../Common/PartnerRouteOptionsForm/PartnerRouteOptionsForm';
import { partnerRouteStatusList } from '../constants';

const RoutePropertiesForm = ({ form, onSubmit }) => {
  const paymentMethodList = usePaymentMethodList();

  const valid = async () => {
    const values = await form.validateFields();
    onSubmit(values);
  };

  return [
    <Typography.Paragraph key="info">
      Select properties then each combination will generate one partner route.
    </Typography.Paragraph>,
    <Form key="form" form={form} layout="vertical" name="partnerRoutesForm" onFinish={valid}>
      <Form.Item name="paymentMethods" label="Payment methods" rules={[{ required: true }]}>
        <Select
          loading={!paymentMethodList.length}
          showSearch
          filterOption={searchByProp('label')}
          allowClear
          mode="multiple"
        >
          {paymentMethodList.map((paymentMethod) => (
            <Select.Option key={paymentMethod.api_name} value={paymentMethod.api_name} label={paymentMethod.name}>
              <Space>
                {paymentMethod.imgUrl && <img src={paymentMethod.imgUrl} alt="" style={{ width: 24 }} />}
                {paymentMethod.name}
              </Space>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="paymentChannels" label="Payment channels" rules={[{ required: true }]}>
        <Select
          showSearch
          filterOption={searchByProp('label')}
          allowClear
          mode="multiple"
        >
          {PAYMENT_CHANNEL_LIST.map(({ id, name, icon: Icon }) => (
            <Select.Option key={id} value={id} label={name}>
              <Space>
                {Icon && <Icon />}
                {name}
              </Space>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="authorizationCurrencies" label="Authorization currencies" rules={[{ required: true }]}>
        <Select
          showSearch
          filterOption={searchByProp('label')}
          allowClear
          mode="multiple"
          maxTagCount={3}
          {...getSelectAllProps({
            form,
            name: 'authorizationCurrencies',
            options: CURRENCY_LIST.map(({ alpha, name }) => ({
              value: alpha,
              label: `[${alpha}] ${name}`,
            })),
          })}
        />
      </Form.Item>
      <Form.Item name="settlementCurrencies" label="Settlement currencies" rules={[{ required: true }]}>
        <Select
          options={CURRENCY_LIST.map(({ alpha, name }) => ({
            value: alpha,
            label: `[${alpha}] ${name}`,
          }))}
          showSearch
          filterOption={searchByProp('label')}
          allowClear
          mode="multiple"
          maxTagCount={3}
        />
      </Form.Item>
      <Divider />
      <Form.Item name="status" label="Status" rules={[{ required: true }]}>
        <Select
          options={partnerRouteStatusList.filter((s) => s.value !== 'deleted')}
          showSearch
          filterOption={searchByProp('label')}
          allowClear
        />
      </Form.Item>
      <Typography.Paragraph>Add options that will override partner default routes options</Typography.Paragraph>
      <PartnerRouteOptionsForm form={form} />
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Generate routes
        </Button>
      </Form.Item>
    </Form>,
  ];
};

export default RoutePropertiesForm;

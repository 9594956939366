import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { Badge, Button, Descriptions, Divider, Modal, Space, Table, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import { compareByProp } from '../../../../../../util/array';
import { capitalize } from '../../../../../../util/string';

const rfmSegments = {
  lost: {
    order: 0,
    segment: 'lost',
    label: 'Lost',
    info: 'These customers generate a tiny percentage of your overall revenue and did not purchase in a relatively long time.',
    color: '#B03A2E',
  },
  about_to_sleep: {
    order: 1,
    segment: 'about_to_sleep',
    label: 'About to sleep',
    info: 'The customers in this segment did not purchase in a relatively long time. The customer value of this segment is average.',
    color: '#E67E22',
  },
  cannot_lose_them: {
    order: 2,
    segment: 'cannot_lose_them',
    label: 'Cannot lose them',
    info: "The customer value of this segment's members is above average, but they have not made a purchase recently.",
    color: '#884EA0',
  },
  need_attention: {
    order: 3,
    segment: 'need_attention',
    label: 'Need attention',
    info: 'The customers in this segment did not purchase recently but the customer value of members of this group is above average.',
    color: '#9f78a8',
  },
  hibernating: {
    order: 4,
    segment: 'hibernating',
    label: 'Hibernating',
    info: 'The customers in this segment did not purchase recently, the frequency is low and the customer value of members of this group is average or below.',
    color: '#F7DC6F',
  },
  at_risk: {
    order: 5,
    segment: 'at_risk',
    label: 'At risk',
    info: 'The customers in this segment have placed orders regularly but did not purchase recently.',
    color: '#B7950B',
  },
  new_customers: {
    order: 6,
    segment: 'new_customers',
    label: 'New customers',
    info: 'These customers bought from you for the first time relatively recently.',
    color: '#85C1E9',
  },
  potential_loyalist: {
    order: 7,
    segment: 'potential_loyalist',
    label: 'Potential loyalist',
    info: 'These customers bought from you recently, they ordered in the past on a regular basis.',
    color: '#1ABC9C',
  },
  promising: {
    order: 8,
    segment: 'promising',
    label: 'Promising',
    info: 'These customers bought from you recently for high value, and ordered in the past on a regular basis.',
    color: '#2ECC71',
  },
  loyal: {
    order: 9,
    segment: 'loyal',
    label: 'Loyal',
    info: 'These customers are very active and therefore precious customers. They bought recently, on a regular basis. The customer value is above average.',
    color: '#148F77',
  },
  champions: {
    order: 10,
    segment: 'champions',
    label: 'Champions',
    info: 'These customers are very active and therefore precious customers. They bought recently, on a regular basis and the customer value is the highest.',
    color: '#239B56',
  },
};

export default (divId, widget, data, args) => {
  // Create chart
  const chart = am4core.create(divId, am4charts.TreeMap);

  chart.numberFormatter.numberFormat = '#.';
  chart.dataFields.value = 'customers';
  chart.dataFields.name = 'label';
  chart.dataFields.children = 'children';
  chart.dataFields.color = 'color';

  chart.data = data.data.map((segment) => ({ ...segment, ...rfmSegments[segment.classification] }));

  const level1 = chart.seriesTemplates.create('0');

  const level1Column = level1.columns.template;
  level1Column.column.cornerRadius(10, 10, 10, 10);
  level1Column.stroke = am4core.color('#fff');
  level1Column.strokeWidth = 5;
  level1Column.strokeOpacity = 1;

  level1Column.tooltipHTML =
    '<div style="text-align:center">' +
    `<div style="font-size:24px">{name}</div>` +
    `<div style="font-size:12px">{customers} customers<br>{percent}%</div>` +
    '</div>';

  const bulletHtmlBySize = (bullet) => {
    if (!bullet) {
      return (
        '<div style="text-align:center">' +
        `<div style="font-size:24px">{name}</div>` +
        `<div style="font-size:12px">{customers} customers<br>{percent}%</div>` +
        '</div>'
      );
    }

    if (bullet.properties.maxHeight < 40) return '';

    const size = Math.min(Math.floor((24 * bullet.properties.maxWidth * 0.9) / (bullet._absoluteWidth + 1)), 24);
    const secondarySize = Math.min(
      Math.floor((12 * bullet.properties.maxWidth * 0.9) / (bullet._absoluteWidth + 1)),
      12,
    );

    if (size < 9) return '';

    return `<div style="text-align:center"><div style="font-size:${size}px">{name}</div>${
      secondarySize > 9 && bullet.properties.maxHeight > 80
        ? `<div style="font-size:${secondarySize}px">{customers} customers<br>{percent}%</div>`
        : ''
    }</div>`;
  };

  const level1Bullet = level1.bullets.push(new am4charts.LabelBullet());
  level1Bullet.locationY = 0.5;
  level1Bullet.locationX = 0.5;
  level1Bullet.label.html = bulletHtmlBySize();
  level1Bullet.label.fill = am4core.color('#fff');

  level1Column.events.on('sizechanged', (ev) => {
    ev.target.dataItem?.bullets?.each((id, bullet) => {
      // eslint-disable-next-line no-param-reassign
      bullet.label.html = bulletHtmlBySize(bullet);
    });
  });

  level1Column.events.on('hit', (e) => {
    const dataContext = e.target.dataItem.dataContext._dataContext;
    Modal.info({
      width: '80%',
      style: { maxWidth: 800 },
      icon: false,
      okText: 'Close',
      content: (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Descriptions
            title={dataContext.label}
            bordered
            size="small"
            column={2}
            extra={
              <Button
                onClick={() => {
                  Modal.info({
                    width: '80%',
                    icon: false,
                    okText: 'Close',
                    closable: true,
                    content: [
                      <Table
                        size="small"
                        bordered
                        columns={[
                          {
                            title: 'Customers segments',
                            dataIndex: 'label',
                            key: 'label',
                            render: (label, { color }) => <Badge color={color} text={label} />,
                          },
                          {
                            title: 'Segments definitions',
                            dataIndex: 'info',
                            key: 'info',
                          },
                        ]}
                        dataSource={Object.values(rfmSegments).sort(compareByProp('order', 'desc'))}
                        pagination={false}
                      />,
                      <Divider />,
                      <Typography.Paragraph>
                        <b>New customers</b> placed their first authorized order during the period covered by the RFM.
                      </Typography.Paragraph>,
                      <Typography.Paragraph>
                        <b>Returning customers</b> placed orders before the period covered by the RFM.
                      </Typography.Paragraph>,
                      data.period && (
                        <Typography.Paragraph>
                          Period <b>{capitalize(data.period.type)}</b>, &nbsp;from{' '}
                          {moment(data.period.from).format('DD/MM/YYYY')}
                          &nbsp;to {moment(data.period.to).format('DD/MM/YYYY')}.
                        </Typography.Paragraph>
                      ),
                    ],
                  });
                }}
                icon={<InfoCircleOutlined />}
                shape="round"
              >
                RFM
              </Button>
            }
          >
            <Descriptions.Item label="Activity" span={12}>
              {dataContext.info}
            </Descriptions.Item>
            <Descriptions.Item label="New customers">{dataContext.new_customers}</Descriptions.Item>
            <Descriptions.Item label="Returning customers">{dataContext.returning_customers}</Descriptions.Item>
            <Descriptions.Item label="Newest customer from">{dataContext.newest_customer_from} days</Descriptions.Item>
            <Descriptions.Item label="Oldest customer from">{dataContext.oldest_customer_from} days</Descriptions.Item>
          </Descriptions>
          <Table
            size="small"
            bordered
            columns={[
              {
                dataIndex: 'type',
                key: 'type',
              },
              {
                title: 'Min',
                dataIndex: 'min',
                key: 'min',
                align: 'right',
              },
              {
                title: 'Max',
                dataIndex: 'max',
                key: 'max',
                align: 'right',
              },
              {
                title: 'Average',
                dataIndex: 'average',
                key: 'average',
                align: 'right',
              },
            ]}
            dataSource={[
              {
                type: 'Recency',
                min: dataContext.min_recency_order,
                max: dataContext.max_recency_order,
                average: dataContext.avg_recency.toFixed(0),
              },
              {
                type: 'Frequency',
                min: dataContext.min_frequency,
                max: dataContext.max_frequency,
                average: dataContext.avg_frequency.toFixed(0),
              },
              {
                type: 'Monetary',
                min: `${dataContext.min_value?.toFixed(2)} ${CURRENCIES[args.currency].symbol}`,
                max: `${dataContext.max_value?.toFixed(2)} ${CURRENCIES[args.currency].symbol}`,
                average: `${dataContext.avg_value?.toFixed(2)} ${CURRENCIES[args.currency].symbol}`,
              },
            ]}
            pagination={false}
          />
          {data.period && (
            <Typography.Paragraph>
              Period <b>{capitalize(data.period.type)}</b>, &nbsp;from {moment(data.period.from).format('DD/MM/YYYY')}
              &nbsp;to {moment(data.period.to).format('DD/MM/YYYY')}.
            </Typography.Paragraph>
          )}
        </Space>
      ),
    });
  });

  return chart;
};

import { gql, useQuery } from '@apollo/client';
import { unflattenByProp } from '../../util/array';

export const PartnersQuery = gql`
  {
    partners {
      id
      api_name
      name
      type
      company {
        theme {
          icon
          primaryColor
        }
      }
    }
  }
`;

export const usePartnerList = () => {
  const { data } = useQuery(PartnersQuery);
  if (!data) return [];
  return data.partners;
};

const usePartners = () => {
  const partnerList = usePartnerList();
  return unflattenByProp(partnerList, 'api_name');
};

export default usePartners;

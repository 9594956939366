import React from 'react';
import { Layout } from 'antd';
import classnames from 'classnames';
import styles from './Page.module.scss';
import Header from './Header/Header';

const { Content } = Layout;

const Page = (props) => {
  const { children, headerContent, centerHeader, scrollable = true, title } = props;

  return (
    <Layout className={styles.layout}>
      <Header centerContent={centerHeader} title={title}>{headerContent}</Header>
      <Content className={classnames(styles.content, { [styles.scroll]: scrollable })}>{children}</Content>
    </Layout>
  );
};

export default Page;

import { gql } from '@apollo/client';

const query = `{
  _id
  last_transaction {
    _id
    customer_email
    customer_id
    customer_first_name
    customer_last_name
    customer_identification_number
    customer_gender
    customer_birth_date
    customer_phone_number
    customer_phone_number_type
    customer_ip
    customer_ip_country
    device_type
    customer_account_login_type
  }
}`;

export const orderCustomerDetailsQuery = gql`
  query OrderCustomerDetails($orderId: ID!) {
      order(id: $orderId) ${query}
  }
`;

export const activityCustomerDetailsQuery = gql`
  query ActivityCustomerDetails($orderId: ID!) {
      activity(id: $orderId) ${query}
  }
`;

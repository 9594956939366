import React from 'react';
import { useTheme } from '../../../../contexts/theme.context';
import LoaderFromTheme from './LoaderFromTheme';

const Loader = (props) => {
  const theme = useTheme();
  return <LoaderFromTheme {...props} theme={theme?.userTheme} />;
};

export default Loader;

import React from 'react';
import InfoTile, { InfoSkeletonRow } from '../../../Tile/Info/Info';

const AccountHistorySkeleton = () => (
  <InfoTile name="account-history" title="Account history">
    <InfoSkeletonRow label="Creation date" />
    <InfoSkeletonRow label="Last update" />
    <InfoSkeletonRow label="Last password update" />
    <InfoSkeletonRow label="# attempts in last 24 hours" />
    <InfoSkeletonRow label="# attempts in last year" />
    <InfoSkeletonRow label="Pay. method registration date" />
  </InfoTile>
);

export default AccountHistorySkeleton;

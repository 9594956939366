import React, { useContext, useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Switch } from 'antd';
import ArrowLeftIcon from '@2fd/ant-design-icons/lib/ArrowLeft';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import ErrorBoundary from '../../../../Common/ErrorBoundary/ErrorBoundary';
import { Block } from '../../../../Common/Sider';
import { DashboardContext, useDashboard, useDashboards } from '../../dashboard.context';
import WidgetList from './WidgetList';
import WidgetAddableList from './WidgetAddableList';
import WidgetEditDrawer from './WidgetEditDrawer/WidgetEditDrawer';
import styles from './DashboardEditDrawer.module.scss';
import Loading from '../../../../Common/Pages/Loading';
import useDebounce from '../../../../../../hooks/useDebounce';

const DashboardEditContent = ({ onClose, onBack }) => {
  const { favorite, updateDashboard, removeDashboard, setFavoriteDashboard } = useDashboards();
  const { dashboard, editable } = useDashboard();

  const { selectedWidgetId, setSelectedWidgetId } = useContext(DashboardContext);

  // update name with debounce
  const [name, setName] = useState(dashboard?.name);
  const debouncedName = useDebounce(name, 300);
  useEffect(() => {
    if (debouncedName && debouncedName !== dashboard?.name) {
      updateDashboard({ ...dashboard, name: debouncedName });
    }
  }, [updateDashboard, dashboard, debouncedName]);

  const handleChangeName = (e) => setName(e.target.value);

  if (!dashboard) return <Loading />;

  return (
    <ErrorBoundary>
      <Block key="edit-dashboard" title="Edit dashboard" description="Update name and pin as default dashboard">
        <Form key="dashboard-edit-form" layout="vertical" requiredMark={false}>
          <Form.Item label="Name">
            <Input value={name} onChange={handleChangeName} disabled={!editable} />
          </Form.Item>
          <Form.Item label="Pin dashboard as default">
            <Switch
              checked={dashboard.id === favorite}
              disabled={dashboard.id === favorite}
              onChange={(checked) => {
                if (checked) setFavoriteDashboard(dashboard.id);
              }}
            />
          </Form.Item>
        </Form>
      </Block>
      <WidgetList />
      {editable && [
        <WidgetAddableList key="widget-addable-list" />,
        dashboard.id !== favorite && (
          <Button
            key="delete-dashboard"
            className={styles.deleteButton}
            onClick={() => removeDashboard(dashboard.id).then(() => onBack())}
          >
            Delete dashboard
          </Button>
        ),
      ]}
      <WidgetEditDrawer open={!!selectedWidgetId} onClose={onClose} onBack={() => setSelectedWidgetId()} />
    </ErrorBoundary>
  );
};

const DashboardEditDrawer = ({ open, onClose, onBack }) => (
  <Drawer
    width={470}
    open={open}
    getContainer="#dashboard"
    style={{ position: 'absolute', zIndex: 51 }}
    bodyStyle={{ padding: 0 }}
    onClose={onClose}
    destroyOnClose
    title="Edit Dashboard"
    closable
    closeIcon={<WindowCloseIcon />}
    extra={<ArrowLeftIcon onClick={onBack} />}
    mask={false}
    push={false}
    autoFocus={false}
  >
    <DashboardEditContent onClose={onClose} onBack={onBack} />
  </Drawer>
);

export default DashboardEditDrawer;

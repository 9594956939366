import React, { useState, useEffect } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Space, Tabs, Typography } from 'antd';
import { useMutation } from '@apollo/client';
import styles from './ThemeEditor.module.scss';
import CropImgUploader from '../Routes/Common/Uploader/CropImgUploader';
import { useMe } from '../../contexts/me.context';
import ColorPicker from './ColorPicker/ColorPicker';
import { useCan } from '../../contexts/ability.context';
import { applyTheme } from '../../util/theme';
import { useTheme } from '../../contexts/theme.context';
import { CompanyThemeImgUpload, CompanyThemeUpdate } from '../Routes/Admin/Company/query';
import { UserAvatarUpload } from '../Routes/Admin/Users/UserView/query';
import { UserThemeUpdateMutation } from './query';

const { TabPane } = Tabs;

const ThemeEditor = ({ isOpen, handleClose }) => {
  const can = useCan();

  const me = useMe();
  const { userTheme, companyTheme, programManagerTheme } = useTheme();

  const company = me?.companies?.[0]?.company;

  const [activeTab, setActiveTab] = useState('user');

  // Apply theme on every change
  useEffect(() => {
    applyTheme(activeTab === 'user' ? userTheme : companyTheme);
  }, [activeTab, userTheme, companyTheme]);

  const [uploadUserAvatar] = useMutation(UserAvatarUpload, {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleUpload = (file) => uploadUserAvatar({ variables: { id: me?.id, file } });

  const [updateUserTheme] = useMutation(UserThemeUpdateMutation, {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleUserChangeColor = (name) => (color) => {
    updateUserTheme({
      variables: {
        input: {
          theme: {
            primaryColor: userTheme.primaryColor,
            secondaryColor: userTheme.secondaryColor,
            [name]: color,
          },
        },
      },
      optimisticResponse: {
        updateMe: {
          __typename: me.__typename,
          id: me?.id,
          theme: {
            primaryColor: userTheme.primaryColor,
            secondaryColor: userTheme.secondaryColor,
            [name]: color,
          },
        },
      },
    });
  };

  const applyCompanyTheme = () => {
    updateUserTheme({
      variables: {
        input: {
          theme: {
            primaryColor: companyTheme.primaryColor,
            secondaryColor: companyTheme.secondaryColor,
          },
        },
      },
      optimisticResponse: {
        updateMe: {
          __typename: me.__typename,
          id: me?.id,
          theme: {
            primaryColor: companyTheme.primaryColor,
            secondaryColor: companyTheme.secondaryColor,
          },
        },
      },
    });
  };

  const applyDefaultTheme = () => {
    updateUserTheme({
      variables: {
        input: {
          theme: {
            primaryColor: programManagerTheme.primaryColor,
            secondaryColor: programManagerTheme.secondaryColor,
          },
        },
      },
      optimisticResponse: {
        updateMe: {
          __typename: me.__typename,
          id: me?.id,
          theme: {
            primaryColor: programManagerTheme.primaryColor,
            secondaryColor: programManagerTheme.secondaryColor,
          },
        },
      },
    });
  };

  const [uploadCompanyThemeImg] = useMutation(CompanyThemeImgUpload, {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleCompanyUpload = (type) => (file) => {
    uploadCompanyThemeImg({ variables: { id: company?.id, type, file } });
  };

  const [updateCompanyTheme] = useMutation(CompanyThemeUpdate, {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleCompanyChangeColor = (name) => (color) => {
    updateCompanyTheme({
      variables: {
        id: company?.id,
        theme: {
          primaryColor: companyTheme.primaryColor,
          secondaryColor: companyTheme.secondaryColor,
          [name]: color,
        },
      },
      optimisticResponse: {
        updateCompanyTheme: {
          __typename: 'MerchantCompany',
          id: company?.id,
          theme: {
            logo: company?.theme?.logo,
            icon: company?.theme?.icon,
            banner: company?.theme?.banner,
            primaryColor: companyTheme.primaryColor,
            secondaryColor: companyTheme.secondaryColor,
            [name]: color,
          },
        },
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      title="Customization"
      onCancel={() => {
        applyTheme(userTheme ?? companyTheme);
        handleClose();
      }}
      footer={null}
    >
      <Tabs className={styles.tabRoot} onChange={setActiveTab} defaultActiveKey="user">
        {company && can('update', 'theme-company') && (
          <TabPane tab="Company theme" key="company">
            <Space direction="vertical">
              <Typography.Title level={3}>{company.name}</Typography.Title>
              <CropImgUploader
                label="Company logo"
                value={company?.theme?.logo}
                onChange={handleCompanyUpload('logo')}
                aspect={4} // ratio 4/1 - horizontal rectangle
                info="Recommended: PNG, ratio 4/1"
              />
              <CropImgUploader
                label="Company icon"
                value={company?.theme?.icon}
                onChange={handleCompanyUpload('icon')}
                aspect={1} // ratio 1/1 - square
                info="Recommended: PNG, ratio 1/1"
              />
              <CropImgUploader
                label="Company banner"
                value={company?.theme?.banner}
                onChange={handleCompanyUpload('banner')}
                aspect={4} // ratio 4/1 - horizontal rectangle
                info="Recommended: PNG, ratio 4/1"
              />
              <ColorPicker
                label="Primary color"
                value={companyTheme.primaryColor}
                onChange={handleCompanyChangeColor('primaryColor')}
                description="Color applied on active/focused elements."
              />
              <ColorPicker
                label="Background color"
                value={companyTheme.secondaryColor}
                onChange={handleCompanyChangeColor('secondaryColor')}
                description="Color used on some backgrounds."
              />
            </Space>
          </TabPane>
        )}
        <TabPane tab="My theme" key="user">
          <Space direction="vertical">
            <CropImgUploader
              label="Avatar"
              value={me?.avatar}
              onChange={handleUpload}
              aspect={1} // ratio 1/1 - square
              info="Recommended: PNG, ratio 1/1"
            />
            <ColorPicker
              label="Primary color"
              value={userTheme.primaryColor}
              onChange={handleUserChangeColor('primaryColor')}
              description="Color applied on active/focused elements."
            />
            <ColorPicker
              label="Background color"
              value={userTheme.secondaryColor}
              onChange={handleUserChangeColor('secondaryColor')}
              description="Color used on some backgrounds."
            />
            <Space>
              {company && <Button onClick={applyCompanyTheme}>Apply company theme</Button>}
              <Button onClick={applyDefaultTheme}>Apply default theme</Button>
            </Space>
          </Space>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default ThemeEditor;

import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Layout, Row, Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useQuery } from '@apollo/client';
import {
  ApiOutlined,
  AppstoreOutlined,
  CodeOutlined,
  DeploymentUnitOutlined,
  HomeOutlined,
  KeyOutlined,
  ProfileOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import ViewCarouselIcon from '@2fd/ant-design-icons/lib/ViewCarousel';
import DomainIcon from '@2fd/ant-design-icons/lib/Domain';
import { MerchantAccountAuditLogsQuery, MerchantAccountViewQuery } from './query';
import styles from './MerchantAccountView.module.scss';
import CompanyInformation from './CompanyInformation/CompanyInformation';
import Users from './Users/Users';
import AccountInformation from './AccountInformation/AccountInformation';
import Webhooks from './Webhooks/Webhooks';
import ApiKeys from './ApiKeys/ApiKeys';
import MerchantContracts from './MerchantContracts/MerchantContracts';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import HandleApolloError from '../../../Common/HandleApolloError/HandleApolloError';
import AnchorList from '../../../Common/AnchorList/AnchorList';
import AuditLogs from '../../../Common/Tile/AuditLogs/AuditLogs';
import RedirectUrls from './RedirectUrls/RedirectUrls';
import ProgramManagerAvatar from '../../../Common/ProgramManager/Avatar';
import { useCan } from '../../../../../contexts/ability.context';
import Loader from '../../../Common/Loader/Loader';
import useNavigateWithSearch from '../../../../../util/navigate';
import { SOURCES } from '../../../../../constants/SOURCES';
import PartnerData from './PartnerData/PartnerData';

const { Content, Sider } = Layout;

const merchantAccountStatuses = {
  active: {
    label: 'Active',
    color: 'green',
    badge: 'success',
  },
  inactive: {
    label: 'Inactive',
    color: 'gold',
    badge: 'warning',
  },
};

const breadcrumbRender = (id, name) => () => (
  <Breadcrumb
    map={{
      '/': <HomeOutlined />,
      '/merchant-accounts': 'Merchant Accounts',
      [`/merchant-accounts/${id}`]: name,
    }}
  />
);

const AsyncLogs = () => {
  const { merchantAccountId } = useParams();
  const { data, loading } = useQuery(MerchantAccountAuditLogsQuery, {
    variables: {
      id: merchantAccountId,
    },
  });
  return loading ? <Loader size="large" /> : <AuditLogs entity={data?.merchantAccount} />;
};

const MerchantAccountView = () => {
  const can = useCan();
  const navigate = useNavigateWithSearch();

  const { merchantAccountId } = useParams();

  const { data, loading, error } = useQuery(MerchantAccountViewQuery, {
    variables: {
      id: merchantAccountId,
    },
    errorPolicy: 'all',
  });

  if (loading) {
    return (
      <PageHeader
        title="..."
        breadcrumbRender={breadcrumbRender(merchantAccountId, '...')}
        onBack={() => navigate('/merchant-accounts')}
      >
        <Loader size="large" />
      </PageHeader>
    );
  }

  const { merchantAccount } = data;

  const status = merchantAccountStatuses[merchantAccount?.status];

  const anchors = [
    {
      hash: '#tile-ma-information',
      icon: ViewCarouselIcon,
      label: 'Account Information',
    },
    {
      hash: '#tile-ma-company',
      icon: DomainIcon,
      label: 'Company',
    },
    {
      hash: '#tile-ma-api-keys',
      icon: KeyOutlined,
      label: 'Api Keys',
      hidden: !can('read', 'api_key'),
    },
    {
      hash: '#tile-ma-webhooks',
      icon: ApiOutlined,
      label: 'Webhooks',
      hidden: !can('read', 'webhook'),
    },
    {
      hash: '#tile-ma-redirect-urls',
      icon: ProfileOutlined,
      label: 'Redirect Urls',
    },
    {
      hash: '#tile-ma-partner-data',
      icon: DeploymentUnitOutlined,
      label: 'Partner Data',
    },
    {
      hash: '#tile-ma-merchant-contracts',
      icon: AppstoreOutlined,
      label: 'Merchant Contracts',
      hidden: !can('read', 'merchant_contract'),
    },
    {
      hash: '#tile-ma-users',
      icon: TeamOutlined,
      label: 'Users',
      hidden: !can('read', 'user'),
    },
    {
      hash: '#tile-entity-logs',
      icon: CodeOutlined,
      label: 'Logs',
    },
  ];

  return (
    <PageHeader
      title={merchantAccount.name}
      breadcrumbRender={breadcrumbRender(merchantAccount.id, merchantAccount.name)}
      onBack={() => navigate('/merchant-accounts')}
      tags={
        merchantAccount?.status && (
          <Tag key="status-tag" color={status.color}>
            {status.label}
          </Tag>
        )
      }
      extra={[
        <Tag key="tag-source" color={SOURCES[merchantAccount.source].color}>{SOURCES[merchantAccount.source].label}</Tag>,
        <ProgramManagerAvatar key="program-manager-avatar" programManager={merchantAccount.programManager} />,
      ]}
    >
      <HandleApolloError error={error}>
        <Layout className={styles.subPage}>
          <Sider className={styles.anchorSider} width={24}>
            <AnchorList anchors={anchors} />
          </Sider>
          <Content>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <AccountInformation merchantAccount={merchantAccount} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <CompanyInformation merchantAccount={merchantAccount} />
              </Col>
              {can('read', 'api_key') && (
                <Col span={24}>
                  <ApiKeys merchantAccount={merchantAccount} />
                </Col>
              )}
              {can('read', 'webhook') && (
                <Col span={24}>
                  <Webhooks merchantAccount={merchantAccount} />
                </Col>
              )}
              <Col span={24}>
                <RedirectUrls merchantAccount={merchantAccount} />
              </Col>
              <Col span={24}>
                <PartnerData id={merchantAccount.id} />
              </Col>
              {can('read', 'merchant_contract') && (
                <Col span={24}>
                  <MerchantContracts merchantAccount={merchantAccount} />
                </Col>
              )}
              {can('read', 'user') && (
                <Col span={24}>
                  <Users merchantAccount={merchantAccount} />
                </Col>
              )}
              <Col span={24}>
                <AsyncLogs />
              </Col>
            </Row>
          </Content>
        </Layout>
      </HandleApolloError>
    </PageHeader>
  );
};

export default MerchantAccountView;

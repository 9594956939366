import { gql } from "@apollo/client";

export const addCompanyListMutation = gql`
  mutation AddCompanyList ($id: ID!, $input: ListInput!) {
    addMerchantCompanyList (id: $id, input: $input) {
      id
      lists {
        id
        name
        type
      }
    }
  }
`;
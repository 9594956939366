import { useEffect, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import useSearchFilters from '../../hooks/useSearchFilters';
import usePagination from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';

export const transactionListQuery = gql`
  query Transactions($offset: Int, $limit: Int, $filters: [SearchFilter], $sort: [SearchSort]) {
    transactions(offset: $offset, limit: $limit, filters: $filters, sort: $sort)
  }
`;
export const transactionsCountQuery = gql`
  query TransactionsCount($filters: [SearchFilter]) {
    transactionsCount(filters: $filters)
  }
`;

const useTransactionListQuery = () => {
  const { offset, limit } = usePagination();
  const { list } = useSearchFilters();
  const [sort] = useSort();

  const [fetch, { data, previousData, loading, error }] = useLazyQuery(transactionListQuery, {
    variables: {
      offset,
      limit,
      filters: list,
      sort,
    },
  });

  const [fetchCount, { data: count, previousData: previousCount, loading: loadingCount, error: errorCount }] =
    useLazyQuery(transactionsCountQuery, {
      variables: {
        filters: list,
      },
    });

  const result = useMemo(
    () => (loading ? previousData?.transactions : data?.transactions) ?? [],
    [data, loading, previousData],
  );

  const total = useMemo(
    () => (loadingCount ? previousCount?.transactionsCount : count?.transactionsCount) ?? 0,
    [count, loadingCount, previousCount],
  );

  useEffect(() => {
    fetch();
    if (!total || offset === 0) fetchCount();
  }, [offset, limit, total]);

  return { result, total, loading, error };
};

export default useTransactionListQuery;

import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, Form, Input, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import LordIcon from '../../../assets/icons/LordIcon';
import { useProgramManager } from '../../../contexts/programManager.context';
import styles from '../AppUnlogged.module.scss';

export const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation SendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email)
  }
`;

const RecoverPassword = () => {
  const programManager = useProgramManager();

  const [sendPasswordResetEmail, { data, loading, error }] = useMutation(SEND_PASSWORD_RESET_EMAIL);

  const [targetEmail, setTargetEmail] = useState();

  const onFinish = ({ email }) => {
    sendPasswordResetEmail({ variables: { email: email.toLowerCase() } })
      // eslint-disable-next-line no-console
      .catch((e) => console.error(e));
    setTargetEmail(email);
  };

  if (data) {
    return (
      <div id="recover-password-success-message" className={styles.block}>
        <LordIcon type="envelope-mail-send-outline-edited" width={120} height={120} isStopped={false} autoplay />
        <Typography.Title level={4}>Successfully Sent Recovery Password Email !</Typography.Title>
        <Typography.Paragraph className={styles.description}>
          If {targetEmail} is a valid {programManager?.displayName ?? 'NORBr'} account, please check your inbox and
          follow the provided recovery link.
        </Typography.Paragraph>
      </div>
    );
  }

  return (
    <div className={styles.block}>
      <Typography.Title level={4}>Recover password</Typography.Title>
      <Typography.Paragraph className={styles.description}>
        Don’t worry, it happens to the best of us.
      </Typography.Paragraph>
      <Form
        name="recover-password"
        className={styles.form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          name="email"
          label={`${programManager?.displayName ?? 'NORBr'} account email`}
          rules={[{ required: true, message: '* required' }]}
          validateStatus={!!error && 'error'}
        >
          <Input prefix={<UserOutlined />} placeholder="email" type="email" />
        </Form.Item>

        {!!error && (
          <Typography.Text className={styles.error} type="danger">
            {error.message}
          </Typography.Text>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" className={styles.submitBtn} loading={loading}>
            email me a recovery link
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RecoverPassword;

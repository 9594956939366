const colors = [
  '#4485F8',
  '#4D4457',
  '#D34031',
  '#009B9A',
  '#8E2542',
  '#FFCF41',
  '#00A8D7',
  '#A6BC5F',
  '#F5E8EC',
  '#00A8A8',
  '#8CA246',
  '#E0C3AD',
  '#109d57',
  '#DB8167',
  '#928490',
  '#9117BE',
  '#378848',
  '#EC4B5C',
  '#BCF1DD',
  '#C7BB44',
  '#476075',
  '#F7EF74',
  '#E98AA2',
  '#F6755B',
  '#668B6B',
  '#917C69',
  '#A3C1E8',
  '#00C68B',
  '#D0DC3D',
  '#435DC0',
  '#A54297',
  '#8CF6FB',
  '#D5B0D4',
  '#EABAA9',
  '#FDC036',
  '#F3B04B',
  '#0D1E6C',
  '#97D4F8',
];

export class ColorGenerator {
  index = 0;

  constructor({ startIndex = 0 }) {
    this.index = startIndex;
  }

  next = () => {
    const nextColor = colors[this.index];
    this.index = (this.index + 1) % colors.length;
    return nextColor;
  };
}

export default colors;

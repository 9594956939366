/**
 * Editable Cell for AntDesign Table
 * See ApiKeys for usage
 * or antd docs - https://ant.design/components/table/#components-table-demo-edit-cell
 */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, message, Select } from 'antd';

export const EditableContext = React.createContext(null);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  formItem,
  formItemProps,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (e) {
      message.error(e.errorFields[0].errors[0]);
    }
  };

  let childNode = children;

  if (editable) {
    if (editing) {
      switch (formItem) {
        case 'select':
          childNode = (
            <Form.Item
              style={{ margin: 0 }}
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: `${title} is required.`,
                },
              ]}
              {...formItemProps}
            >
              <Select ref={inputRef} onBlur={save} {...formItemProps} />
            </Form.Item>
          );
          break;
        case 'input':
        default:
          childNode = (
            <Form.Item
              style={{ margin: 0 }}
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: `${title} is required.`,
                },
              ]}
              {...formItemProps}
            >
              <Input ref={inputRef} onPressEnter={save} onBlur={save} {...formItemProps} />
            </Form.Item>
          );
          break;
      }
    } else {
      childNode = (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
            height: 32,
          }}
          onClick={toggleEdit}
          onKeyPress={toggleEdit}
          role="button"
          tabIndex={0}
        >
          {children}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

export const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

export default EditableCell;

import { gql } from '@apollo/client';

export const metaProgramManagerSsoConfigQuery = gql`
query MetaProgramManagerSsoConfig($id: ID!) {
  metaProgramManager(id: $id) {
    id
    sso {
      draft {
        tenantId
        providerId
        idpEntityId
        ssoURL
        rpEntityId
        x509Certificates
      }
      config {
        tenantId
        providerId
        idpEntityId
        ssoURL
        rpEntityId
        x509Certificates  
      }
    }
  }
}
`;

export const updateSsoDraftMutation = gql`
  mutation UpdateSsoDraft($id: ID!, $input: SsoConfigInput!) {
    updateMetaProgramManagerSsoDraft(id: $id, input: $input) {
      id
      sso {
        draft {
          tenantId
          providerId
          idpEntityId
          ssoURL
          rpEntityId
          x509Certificates 
        }
      }
    }
  }
`;

export const applySsoConfigMutation = gql`
  mutation UpdateMetaProgramManagerApplySsoDraftSso($id: ID!) {
    updateMetaProgramManagerApplySsoDraft(id: $id) {
      id
      sso {
        config {
          tenantId
          providerId
          idpEntityId
          ssoURL
          rpEntityId
          x509Certificates
        }
        draft {
          tenantId
          providerId
          idpEntityId
          ssoURL
          rpEntityId
          x509Certificates 
        }
      }
    }
  }
`;
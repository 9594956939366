import { gql } from '@apollo/client';

const query = `{
  _id
  transactions {
    _id
    id
    order_transaction_number
    routingMode
    routingResponse
    merchant_contract {
      name
      id
    }
  }
}`;

export const orderRoutingQuery = gql`
  query OrderRouting($orderId: ID!) {
    order(id: $orderId) ${query}
  }
`;

export const activityRoutingQuery = gql`
  query ActivityRouting($orderId: ID!) {
    activity(id: $orderId) ${query}
  }
`;

import React from 'react';
import { Space, Tag } from 'antd';
import styles from './FormItem.module.scss';

const FormItemBoolean = ({ field, value = [], onChange }) => {
  const handleChange = (key, checked) => {
    onChange(checked ? [...value, key] : value.filter((v) => v !== key));
  };

  return (
    <div className={styles.booleanInput}>
      <Space size={[0, 8]}>
        {[
          { value: true, label: field.displayOptions.trueElement ?? 'Yes' },
          { value: false, label: field.displayOptions.falseElement ?? 'No' },
          { value: '__NULL__', label: 'Null' },
        ].map((option) => (
          <Tag.CheckableTag
            checked={value.includes(option.value)}
            onChange={(checked) => handleChange(option.value, checked)}
          >
            {option.label}
          </Tag.CheckableTag>
        ))}
      </Space>
    </div>
  );
};
export default FormItemBoolean;

import { gql } from '@apollo/client';

export const merchantAccountCertificatesQuery = gql`
  query MerchantAccountCertificates($id: ID!) {
    merchantAccount(id: $id) {
      id
      certificates {
        id
        name
        status
      }
    }
  }
`;
import React from 'react';
import moment from 'moment-timezone';
import { Avatar, Descriptions, Space, Typography } from 'antd';
import RECONCILIATION_TYPES from 'norbr-shared-lib/constants/transaction/reconciliationTypes';
import RECONCILIATION_REASONS from 'norbr-shared-lib/constants/transaction/reconciliationReasons';
import WITHDRAW_REASONS from 'norbr-shared-lib/constants/transaction/withdrawReasons';
import MATCH_LATER_REASONS from 'norbr-shared-lib/constants/transaction/matchLaterReasons';
import ArchiveIcon from '@2fd/ant-design-icons/lib/Archive';
import RECONCILIATION_TYPE_ENUM from 'norbr-shared-lib/constants/transaction/reconciliationTypes/enum';
import { DATETIME_FORMAT } from '../../../../../constants/DATES';
import { initials } from '../../../../../util/string';
import styles from './MatcherDrawer.module.scss';
import { useCan } from '../../../../../contexts/ability.context';

const MatcherReconciliationDetail = ({ operation }) => {
  const can = useCan();

  if (!operation) return null;
  if (!operation.is_reconciled && operation.reconciliation_type !== RECONCILIATION_TYPE_ENUM.PENDING) return null;

  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: '#f5f4f4',
        padding: '8px 12px',
        margin: '0 8px',
        borderRadius: 8,
      }}
    >
      <Space style={{ fontSize: '14px' }}>
        <ArchiveIcon />
        Details
      </Space>
      <Descriptions
        column={2}
        size="small"
        colon={false}
        labelStyle={{ color: '#868686' }}
        className={styles.descriptions}
      >
        <Descriptions.Item label="Reconciliation">
          <Typography.Text ellipsis>
            {RECONCILIATION_TYPES[operation.reconciliation_type]?.label ?? 'n/a'}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Date">
          {moment(operation.reconciliation_date).format(DATETIME_FORMAT)}
        </Descriptions.Item>
        {operation.reconciliation_information?.reconciliation_reason && (
          <Descriptions.Item label="Reason">
            {{
              ...RECONCILIATION_REASONS,
              ...WITHDRAW_REASONS,
              ...MATCH_LATER_REASONS,
            }[operation.reconciliation_information?.reconciliation_reason]?.label ?? 'n/a'}
          </Descriptions.Item>
        )}
        {can('read', 'matcher-withdraw') && operation.reconciliation_information?.withdraw_reason && (
          <Descriptions.Item label="Withdraw reason">
            {{
              ...RECONCILIATION_REASONS,
              ...WITHDRAW_REASONS,
              ...MATCH_LATER_REASONS,
            }[operation.reconciliation_information?.withdraw_reason]?.label ?? 'n/a'}
          </Descriptions.Item>
        )}
        {operation.reconciliation_information?.author && (
          <Descriptions.Item label="Author">
            <Space>
              <Avatar
                src={operation.reconciliation_information?.author.avatar}
                size="small"
                title={operation.reconciliation_information?.author.full_name}
              >
                {initials(operation.reconciliation_information?.author.full_name)}
              </Avatar>
              {operation.reconciliation_information?.author.full_name}
            </Space>
          </Descriptions.Item>
        )}
        {operation.reconciliation_information?.note && (!operation.is_withdraw || can('read', 'matcher-withdraw')) && (
          <Descriptions.Item label="Comment">{operation.reconciliation_information?.note}</Descriptions.Item>
        )}
      </Descriptions>
    </div>
  );
};

export default MatcherReconciliationDetail;

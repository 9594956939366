import React from 'react';
import MeProvider from '../../contexts/me.context';
import MessagesProvider from '../../contexts/messages/messages.context';
import ThemeProvider from '../../contexts/theme.context';
import AbilityProvider from '../../contexts/ability.context';
import Layout from '../../Layout/Layout';

const AppLogged = () => (
  <MeProvider>
    <ThemeProvider>
      <AbilityProvider>
        <MessagesProvider>
          <Layout />
        </MessagesProvider>
      </AbilityProvider>
    </ThemeProvider>
  </MeProvider>
);

export default AppLogged;

import React from 'react';
import { Typography } from 'antd';
import styles from './common.module.scss';

const ForbiddenChart = () => (
  <div className={styles.containerForbidden}>
    <Typography.Text type="danger">Permission required</Typography.Text>
  </div>
);

export default ForbiddenChart;

import React from 'react';
import { Tag } from 'antd';
import FilterTag from './FilterTag';

const TextFilterTag = ({ value = [], ...filterTagProps }) => (
  <FilterTag {...filterTagProps}>
    {value.map((v) => (
      <Tag key={v}>{v === '__NULL__' ? 'Null' : v}</Tag>
    ))}
  </FilterTag>
);

export default TextFilterTag;

import { gql, useMutation } from '@apollo/client';
import _ from 'norbr-shared-lib/constants/currencies/enum';
import { useMe } from '../../../../contexts/me.context';

const UserMeMutation = gql`
  mutation UserMeMutation($input: UserUpdateInput!) {
    updateMe(input: $input) {
      __typename
      id
      baseCurrency
    }
  }
`;

const useBaseCurrency = () => {
  const me = useMe();
  const [updateUserMe] = useMutation(UserMeMutation);

  const baseCurrency = me?.baseCurrency ?? _.EUR;
  const setBaseCurrency = (value) => updateUserMe({ variables: { input: { baseCurrency: value } } });

  return [baseCurrency, setBaseCurrency];
};

export default useBaseCurrency;

import React from 'react';
import InfoTile, { InfoSkeletonRow } from '../../../Tile/Info/Info';

const OrderReferenceSkeleton = () => (
  <InfoTile name="order-reference" title="Order reference">
    <InfoSkeletonRow label="... ID" />
    <InfoSkeletonRow label="Account ID" />
    <InfoSkeletonRow label="Account name" />
    <InfoSkeletonRow label="Contract ID" />
    <InfoSkeletonRow label="Contract name" />
    <InfoSkeletonRow label="Merchant reference" />
    <InfoSkeletonRow label="PSP reference" />
    <InfoSkeletonRow label="Number of transactions" />
  </InfoTile>
);

export default OrderReferenceSkeleton;

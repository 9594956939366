export const SortOrder = {
  ASCEND: 'ascend',
  DESCEND: 'descend',
};

const sortOrders = {
  [SortOrder.ASCEND]: { value: SortOrder.ASCEND, label: 'Ascend' },
  [SortOrder.DESCEND]: { value: SortOrder.DESCEND, label: 'Descend' },
};

export default sortOrders;

import { gql } from '@apollo/client';

const query = `{
  _id
  last_transaction {
    _id
    customer_browser_info_accept_header
    customer_browser_info_color_depth
    customer_browser_info_java_enabled
    customer_browser_info_js_enabled
    customer_browser_info_language
    customer_browser_info_screen_height
    customer_browser_info_screen_width
    customer_browser_info_time_zone
  }
}`;

export const orderBrowserInformationQuery = gql`
  query OrderBrowserInformation($orderId: ID!) {
      order(id: $orderId) ${query}
  }
`;

export const activityBrowserInformationQuery = gql`
  query ActivityBrowserInformation($orderId: ID!) {
      activity(id: $orderId) ${query}
  }
`;

import { gql } from '@apollo/client';

const query = `{
  _id
  id
  amount
  currency
  baskets {
    product_tax_amount
    seller_id
  }
}`;

export const orderBasketSummaryQuery = gql`
  query OrderBasketSummary($orderId: ID!) {
    order(id: $orderId) ${query}
  }
`;

export const activityBasketSummaryQuery = gql`
  query ActivityBasketSummary($orderId: ID!) {
    activity(id: $orderId) ${query}
  }
`;

import React, { useState } from 'react';
import { Button, Form, Input, message, Select, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import { CompanyUpdateMutation } from '../../query';
import InfoTile, { InfoCountryRow, InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import styles from '../CompanyView.module.scss';
import { COUNTRY_LIST } from '../../../../../../constants/COUNTRIES';
import { useCan } from '../../../../../../contexts/ability.context';
import {CompanyAddressQuery} from './query';

const CompanyAddress = () => {
  const can = useCan();
  const { companyId } = useParams();
  const [editMode, setEditMode] = useState(false);


  const { data: { merchantCompany } = { merchantCompany: null}, loading} = useQuery(CompanyAddressQuery, {
    variables: {
      companyId
    }
  });

  const [updateCompany, { loading: loadingUpdate }] = useMutation(CompanyUpdateMutation, {
    onCompleted: () => {
      message.success('Company successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    updateCompany({
      variables: { id: companyId, input: values },
    });
  };

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="company-address" title="Address">
        <Form
          form={form}
          title="Address"
          layout="vertical"
          initialValues={merchantCompany}
          onFinish={handleFinish}
          requiredMark={false}
          className="tile-form"
        >
          <Form.Item
            label="Country"
            name="country"
            rules={[
              {
                required: merchantCompany.attributeListRequiredByPartners.includes('company.country'),
                message: 'Country is required by a partner configuration.',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a country"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {COUNTRY_LIST.map((c) => (
                <Select.Option key={c.value} value={c.value}>
                  <Space>
                    <img src={c.img} alt={c.value} className={styles.imgOption} />
                    {c.label}
                  </Space>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Street"
            name="address"
            rules={[
              {
                required: merchantCompany.attributeListRequiredByPartners.includes('company.address'),
                message: 'Address is required by a partner configuration.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[
              {
                required: merchantCompany.attributeListRequiredByPartners.includes('company.city'),
                message: 'City is required by a partner configuration.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prevValues, curValues) => curValues.country !== prevValues.country}>
            {() => (
              <Form.Item
                label="State"
                name="state"
                tooltip={
                  <a href="https://fr.wikipedia.org/wiki/ISO_3166-2" target="_blank" rel="noopener noreferrer">
                    ISO 3166-2 standard
                  </a>
                }
              >
                <Input prefix={`${form.getFieldValue('country') || 'XX'}-`} maxLength={3} />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            label="ZIP code"
            name="zipCode"
            rules={[
              {
                required: merchantCompany.attributeListRequiredByPartners.includes('company.zipCode'),
                message: 'ZIP code is required by a partner configuration.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loadingUpdate}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  // return info tile
  return (
    <InfoTile
      name="company-address"
      title="Address"
      extra={
        can('update', 'company') && !loading && <Button onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
      }
    >
      <InfoCountryRow label="Country" value={merchantCompany?.country} loading={loading} />
      <InfoTextRow label="Street" value={merchantCompany?.address} loading={loading} />
      <InfoTextRow label="City" value={merchantCompany?.city} loading={loading} />
      <InfoTextRow label="State" value={merchantCompany?.state} loading={loading} />
      <InfoTextRow label="ZIP code" value={merchantCompany?.zipCode} loading={loading} />
    </InfoTile>
  );
};

export default CompanyAddress;

import usePage from './usePage';

/**
 * Update sort in SearchParams (react-router)
 * @param searchParams
 * @param value
 * @param prefix
 * @returns {Object}
 */
export const updateTargetEntityInSearchParams = (searchParams, value, prefix) =>
  searchParams.set(`${prefix}_entity`, value);

// Since the split Order/Transaction target entity should be deduced from the current page
const useTargetEntity = () => {
  const page = usePage();
  return [page.defaultView.entity, () => console.log('DEPRECATED - setTargetEntity should not be used anymore')];
};

export default useTargetEntity;

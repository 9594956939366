import {gql} from '@apollo/client';

export const merchantAccountContractsQuery = gql`
    query MerchantAccountContracts($id: ID!, $filters: MerchantContractFilters) {
        merchantAccount(id: $id) {
            id
            reference_currency
            merchant_contracts(filters: $filters) {
                id
                type
                status
                name
                payment_methods {
                    id
                }
                authorization_currencies
                partner {
                    id
                }
            }
        }
    }
`;

export const setContractSplitterMutation = gql`
    mutation SetContractSplitter($id: ID!, $contractId: ID) {
        setContractSplitter(id: $id, contractId: $contractId) {
            id
            contract {
                id
                partner {
                    id
                }
            }
            blocking
        }
    }
`;

export const setSplitterBlockingMutation = gql`
    mutation SetSplitterBlocking($id: ID!) {
        setSplitterBlocking(id: $id) {
            id
            contract {
                id
                partner {
                    id
                }
            }
            blocking
        }
    }
`;

export const setContractBranchMutation = gql`
    mutation UpdateBranchContract($id: ID!, $contractId: ID) {
        updateBranchContract(id: $id, contractId: $contractId) {
            id
            contract {
                id
                partner {
                    id
                }
            }
            blocking
        }
    }
`;


export const setBranchBlockingMutation = gql`
    mutation SetBranchBlocking($id: ID!) {
        setBranchBlocking(id: $id) {
            id
            contract {
                id
                partner {
                    id
                }
            }
            blocking
        }
    }
`;
import { gql } from '@apollo/client';
import { FragmentCompanyInfo } from '../../Company/query';
import { FragmentPartnerRouteOptions } from '../../PartnerRoutes/constants';

export const PartnerViewQuery = gql`
  query PartnerViewQuery($id: ID!) {
    partner(id: $id) {
      __typename
      id
      allow_insights
      allow_insights_pos
      allow_stack
      allow_payfac
      api_name
      name
      code
      type
      status
      company {
        id
        name
        status
        legalForm
        country
        theme {
          primaryColor
          icon
        }
        ${FragmentCompanyInfo}
      }
      mainContact {
        firstname
        surname
        email
        phone {
            code
            number
        }
      }
      billingContact {
        firstname
        surname
        email
        phone {
            code
            number
        }
      }
      ${FragmentPartnerRouteOptions}
      risk_assessment_service
      support_authentication
      partner_data_configuration
      partner_reconciliation_configuration
      partner_payfac_configuration
      merchant_attribute_configuration
      partner_reconciliation_data
      endpoints {
        name
        value
        method
        specific
      }
      base_url
      payment_methods {
        id
        imgUrl
        name
      }
      partner_routes {
        status
      }
    }
  }
`;

export const PartnerRoutesQuery = gql`
  query PartnerRoutesQuery($id: ID!) {
    partner(id: $id) {
      __typename
      id
      name
      partner_routes {
        __typename
        id
        status
        authorization_currency
        settlement_currency
        channel
        paymentMethodName
        partner {
          id
          ${FragmentPartnerRouteOptions}
        }
        ${FragmentPartnerRouteOptions}
        request_expiration_delay
        merchantRoutes {
          id
          merchant_contract {
            id
            name
          }
          merchant_account {
            id
            name
            company {
              id
              name
              theme {
                icon
              }
            }
          }
        }
      }
      ${FragmentPartnerRouteOptions}
    }
  }
`;

export const PartnerAuditLogsQuery = gql`
  query PartnerAuditLogsQuery($id: ID!) {
    partner(id: $id) {
      id
      createdAt
      createdBy {
        id
        full_name
        email
        avatar
      }
      updatedAt
      updatedBy {
        id
        full_name
        email
        avatar
      }
      auditLogs {
        id
        action
        entityId
        entityName
        timestamp
        createdBy {
          email
          full_name
          avatar
        }
      }
    }
  }
`;

export const PartnerUpdateMutation = gql`
  mutation PartnerUpdateMutation($id: ID!, $input: PartnerInfoInput!) {
    updatePartner(id: $id, input: $input) {
      id
      allow_stack
      allow_insights
      allow_insights_pos
      allow_payfac
      api_name
      name
      status
      company {
        id
        name
        status
        legalForm
        country
        theme {
          icon
          primaryColor
        }
        ${FragmentCompanyInfo}
      }
      mainContact {
        firstname
        surname
        email
        phone {
          code
          number
        }
      }
      billingContact {
        firstname
        surname
        email
        phone {
            code
            number
        }
      }
      ${FragmentPartnerRouteOptions}
      risk_assessment_service
      support_authentication
      partner_data_configuration
      partner_reconciliation_configuration
      partner_payfac_configuration
      merchant_attribute_configuration
      partner_reconciliation_data
      endpoints {
        name
        value
        method
        specific
      }
      base_url
    }
  }
`;

export const PartnerActiveOnProgramManagerQuery = gql`
  query PartnerActiveOnProgramManagerQuery($id: ID!) {
    partner(id: $id) {
      id
      name
      activeProgramManagers {
        programManager {
          id
          name
          theme {
            icon
            primaryColor
            secondaryColor
          }
        }
        paymentMethods {
          id
          name
          imgUrl
        }
      }
    }
  }
`;

export default {};

import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Button, Form, Modal, Select, Space, Steps, Tag, Typography } from 'antd';
import { ArrowRightOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { useMe } from '../../../../../../contexts/me.context';
import Countdown from '../../../../../Menu/Countdown';
import { useCan } from '../../../../../../contexts/ability.context';
import { useToken } from '../../../../../../contexts/app.context';
import ProgramManagerAvatar from '../../../../Common/ProgramManager/Avatar';
import { CompanyProfileListQuery } from '../../../AccessControl/query';
import { createGuestAccessMutation } from './query';

const ConnectAsGuest = ({ company }) => {
  const me = useMe();
  const [token] = useToken();
  const can = useCan();

  const [step, setStep] = useState(0);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const profilesQuery = useQuery(CompanyProfileListQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { companyId: company.id },
    skip: !can('create', 'guest_admin') || !can('read', 'access-control') ,
  });

  const [createGuestAccess, { data, loading }] = useMutation(createGuestAccessMutation, {
    refetchQueries: ['UserMe'],
    variables: { companyId: company?.id, userId: me.id, aclProfileId: selectedProfile },
    onCompleted: () => setStep(1),
    onError: () => setStep(-1),
  });

  const existingAccessCompany = me.companies?.[0];

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setStep(0);
    setIsModalVisible(false);
  };

  return [
    <Button
      key="connect-as-guest-btn"
      icon={<UserSwitchOutlined />}
      onClick={showModal}
      type="text"
      title="Connect as guest"
    />,
    <Modal
      key="connect-as-guest-modal"
      destroyOnClose
      title={`Connect as ${company?.name} guest.`}
      open={isModalVisible}
      footer={[
        step === 0 && (
          <Button key="create" type="primary" onClick={createGuestAccess} loading={loading}>
            Create guest access
          </Button>
        ),
        step === 1 && (
          <Button
            key="redirect"
            type="link"
            target="_blank"
            icon={<ArrowRightOutlined />}
            href={`https://${data?.createGuestAccess.company.programManager.merchantDomain}/login?token=${token}`}
            onClick={handleCancel}
          >
            Open in new tab
          </Button>
        ),
      ]}
      onCancel={handleCancel}
    >
      <Steps size="small" current={step} style={{ marginBottom: 12 }}>
        <Steps.Step title="Create access" status={loading && 'process'} />
        <Steps.Step title="Redirection" />
      </Steps>
      {step === -1 && <Typography.Paragraph>Oups, an error occurred. Please retry later.</Typography.Paragraph>}
      {step === 0 && [
        existingAccessCompany && (
          <Typography.Paragraph key="existing-access">
            You currently have guest access to&nbsp;
            <Tag color={existingAccessCompany.company.theme?.primaryColor}>{existingAccessCompany.company.name}</Tag>
            for&nbsp;
            <Countdown tillDate={moment(existingAccessCompany.createdAt).add(60, 'minutes')} />.
            <br />
          </Typography.Paragraph>
        ),
        <Typography.Paragraph key="description">
          Click on the following button to generate a temporary access to connect to&nbsp;
          <Tag color={company?.theme?.primaryColor}>{company?.name}</Tag>as a guest.
        </Typography.Paragraph>,
        can('create', 'guest_admin') && can('read', 'access-control') ? (
          <Form.Item key="profile-select" label="Choose profile">
            <Select value={selectedProfile} onChange={setSelectedProfile}>
              <Select.Option key={null} value={null} label="Guest">
                Guest
              </Select.Option>
              {profilesQuery.data?.merchantCompany.programManager?.merchantAclProfiles.map((p) => (
                <Select.Option key={p.id} value={p.id} label={p.name}>
                  <Space>
                    <ProgramManagerAvatar programManager={profilesQuery.data.merchantCompany.programManager} />
                    {p.name}
                  </Space>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <Typography.Paragraph key="profile">
            With read-only <b>Guest</b> profile.
          </Typography.Paragraph>
        ),
        existingAccessCompany && 'Existing access will be removed.',
      ]}
      {step === 1 && (
        <Typography.Paragraph key="existing-access">
          You have access to&nbsp;
          <Tag color={company.theme?.primaryColor}>{company.name}</Tag>
          as a guest for&nbsp;
          <Countdown tillDate={moment(data?.createGuestAccess.createdAt).add(60, 'minutes')} />.
        </Typography.Paragraph>
      )}
    </Modal>,
  ];
};

export default ConnectAsGuest;

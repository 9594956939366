import React, {useContext, useEffect} from 'react';
import {useLazyQuery} from '@apollo/client';
import {payinMerchantContractsQuery} from './query';
import {MerchantContractContext} from '../../../MerchantContractDrawer';
import {MerchantAccountContext} from '../../../../../../merchantAccount.context';
import {Form, Select} from 'antd';
import {ContractEditionContext} from '../../../../../PaymentNetwork';

const { Item } = Form;

const MerchantContractPayinContract = () => {
  const { merchantContract, updateMerchantContract } = useContext(MerchantContractContext);
  const { merchantAccount } = useContext(MerchantAccountContext);
  const { isEdited, isSaving } = useContext(ContractEditionContext);

  const [getPayinContracts, {data: {merchantAccount: {merchant_contracts: merchantContracts}} = {merchantAccount: {merchant_contracts: []}}}] = useLazyQuery(payinMerchantContractsQuery);

  useEffect(() => {
    if (merchantAccount) {
      getPayinContracts({
        variables: {
          merchantAccount: merchantAccount.id,
        }
      })
    }
  }, [merchantAccount]);

  return (
    <Item label="Payment contracts">
      <Select
        disabled={!isEdited || isSaving}
        options={merchantContracts.filter(merchantContractElem => merchantContractElem.partner.type === 'payin').map(merchantContractElem => ({
          label: merchantContractElem.name,
          value: merchantContractElem.id,
        }))}
        value={merchantContract?.payin_contracts.map(contract => contract.id)}
        onChange={(value) => {
          updateMerchantContract({
            variables: {
              input: {
                payin_contracts: value,
              },
            },
            optimisticResponse: {
              updateContract: {
                ...merchantContract,
                payin_contracts: value.map(elem => ({
                  id: elem,
                  __typename: 'MerchantContract',
                })),
              },
            },
          });
        }}
        mode='multiple'
        allowClear
      />
    </Item>
  )
};

export default MerchantContractPayinContract;
export const INTEGRATION_TYPES = {
  direct_api: {
    value: 'direct_api',
    label: 'Direct API',
  },
  hosted_fields: {
    value: 'hosted_fields',
    label: 'Hosted fields',
  },
  hosted_elements: {
    value: 'hosted_elements',
    label: 'Hosted elements',
  },
  hosted_page: {
    value: 'hosted_page',
    label: 'Hosted page',
  },
  client_side_encryption: {
    value: 'client_side_encryption',
    label: 'Client side encryption',
  },
  cms_module: {
    value: 'cms_module',
    label: 'CMS module',
  },
  other: {
    value: 'other',
    label: 'Other',
  },
};

const defaultValue = {
  value: null,
  label: null,
};

export const integrationTypeList = Object.values(INTEGRATION_TYPES);
export const getIntegrationType = (value) =>
  value && INTEGRATION_TYPES[value] ? INTEGRATION_TYPES[value] : defaultValue;

export default INTEGRATION_TYPES;

// min <= value < max
enum AmountRanges {
  _LT_0 = '-0',
  _0_20 = '0-20',
  _20_50 = '20-50',
  _50_100 = '50-100',
  _100_200 = '100-200',
  _200_500 = '200-500',
  _500_1000 = '500-1000',
  _1000_2000 = '1000-2000',
  _2000_5000 = '2000-5000',
  _5000_10000 = '5000-10000',
  _GT_10000 = '10000+',
}

export const config = {
  [AmountRanges._LT_0]: {
    id: AmountRanges._LT_0,
    label: '-0',
    filter: { max: 0 },
  },
  [AmountRanges._0_20]: {
    id: AmountRanges._0_20,
    label: '0 - 20',
    filter: { min: 0, max: 20 },
  },
  [AmountRanges._20_50]: {
    id: AmountRanges._20_50,
    label: '20 - 50',
    filter: { min: 20, max: 50 },
  },
  [AmountRanges._50_100]: {
    id: AmountRanges._50_100,
    label: '50 - 100',
    filter: { min: 50, max: 100 },
  },
  [AmountRanges._100_200]: {
    id: AmountRanges._100_200,
    label: '100 - 200',
    filter: { min: 100, max: 200 },
  },
  [AmountRanges._200_500]: {
    id: AmountRanges._200_500,
    label: '200 - 500',
    filter: { min: 200, max: 500 },
  },
  [AmountRanges._500_1000]: {
    id: AmountRanges._500_1000,
    label: '500 - 1000',
    filter: { min: 500, max: 1000 },
  },
  [AmountRanges._1000_2000]: {
    id: AmountRanges._1000_2000,
    label: '1000 - 2000',
    filter: { min: 1000, max: 2000 },
  },
  [AmountRanges._2000_5000]: {
    id: AmountRanges._2000_5000,
    label: '2000 - 5000',
    filter: { min: 2000, max: 5000 },
  },
  [AmountRanges._5000_10000]: {
    id: AmountRanges._5000_10000,
    label: '5000 - 10000',
    filter: { min: 5000, max: 10000 },
  },
  [AmountRanges._GT_10000]: {
    id: AmountRanges._GT_10000,
    label: '10000+',
    filter: { min: 10000 },
  },
};

export const list = Object.values(AmountRanges);

export default AmountRanges;

import React from 'react';
import styles from './icon.module.scss';

const Bar = ({ color = 'inherit' }) => (
  <span className={styles.icon}>
    <svg width="20" height="20" viewBox="0 0 300 300">
      <path
        fill={color}
        // eslint-disable-next-line max-len
        d="M15454,960h-25V660h25V935h275v25Zm222-44V692h25V916Zm-47,0V729h25V916Zm-47,0V791h25V916Zm-47,0V756h25V916Zm-47,0V815h25V916Z"
        transform="translate(-15429 -659.999)"
      />
    </svg>
  </span>
);

export default Bar;

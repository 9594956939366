import React from 'react';
import {Link} from '../../../../../util/navigate';

const  getPath = (key, entity) => {
  let fullKey = key;
  if (key.includes('/')) {
    if (key.split('/')[key.split('/').length-1]?.includes('[]')) {
      fullKey = key.split('/').slice(0, -1).reduce((acc, k) => `${acc}${k}.`, '').slice(0, -1);
    } else {
      fullKey = key.split('/').reduce((acc, k) => `${acc}${k}.`, '').slice(0, -1);
    }
  }
  return `logs.${entity}.${fullKey}`;
}

export const getText = (key, entity, t) => {
  const path = getPath(key, entity);
  // Exceptions
  if (path.includes('logs.MerchantContract.configuration')) {
    return `Connection settings`
  }
  if (path.includes('logs.MerchantContract.reconciliation_configuration')) {
    return `Reconciliation settings`
  }
  if (path.includes('logs.Webhook.additional_headers')) {
    return `Webhook header ${path.split('.')[path.split('.').length-1]}`
  }
  return t(path);
}

export const getLinkTo = (log) => {

  const partnerTypes = {
    payin: 'payment',
    risk_assessment: 'riskAssessment',
    authentication: 'authentication',
  }

  switch (log.log_entity_type) {
    case 'ApiKey':
      return `/router/notifications?maccount=${log.log_merchant_account_id}&api=security-credentials-${log.log_entity_id}`;
    case 'List':
      return `/router/lists/${log.log_entity_id}`;
    case 'MerchantAccount':
      // Redirect fallbackSettings updates to fallback settings, other to api & notif
      if (log.log_update?.edited?.[0]?.[0]?.includes('fallback_settings') ||
        log.log_update?.added?.[0]?.[0]?.includes('fallback_settings') ||
        log.log_update?.removed?.[0]?.[0]?.includes('fallback_settings')) {
        return `/router/optimizer/general-settings?maccount=${log.log_merchant_account_id}`;
      }
      return `/router/notifications?maccount=${log.log_merchant_account_id}`;
    case 'MerchantContract':
      return `/router/payment-network?maccount=${log.log_merchant_account_id}&pn=serviceProviders-${partnerTypes[log.log_update.partner_type]}${log.log_update.partner_type === 'payin' ? `-${log.log_update.partner_id}`: ''}-${log.log_entity_id}`;
    case 'Order':
      return `/brainpower/orders/${log.log_entity_id}`;
    case 'Rule':
      return `/router/payment-network/rule/${log.log_entity_id}?maccount=${log.log_merchant_account_id}`;
    case 'User':
      return `/teams/users/${log.log_entity_id}`;
    case 'Webhook':
      return `/router/notifications?maccount=${log.log_merchant_account_id}&api=notifications-${log.log_entity_id}`;
    default:
      return null;
  }
}

export const getValue = (key, entity, t, i18n, value) => {
  const path = getPath(key, entity);
  if (
    [
      'logs.MerchantAccount.hosted_page_configuration.logo',
      'logs.MerchantCompany.logo',
      'logs.MerchantCompany.banner',
      'logs.MerchantCompany.icon',
      'logs.User.avatar',
    ].includes(path)
  ) {
    return value ? <img src={value} style={{ maxHeight: 40, maxWidth: 64 }} alt='log-img'/> : 'null';
  }
  if (
    [
      'logs.MerchantCompany.primaryColor',
      'logs.MerchantCompany.secondaryColor',
      'logs.MerchantAccount.hosted_page_configuration.primary_color'
    ].includes(path)
  ) {
    return <span style={{color: value}}>{value}</span>
  }
  if (path.includes('logs.User.authorizedMerchantAccounts')) {
    return <Link to={getLinkTo({ log_entity_type: 'MerchantAccount', log_merchant_account_id: value})}>{value}</Link>;
  }
  if (path.includes('logs.MerchantContract.configuration') || path.includes('logs.MerchantContract.reconciliation_configuration')) {
    return '**********';
  }
  if (i18n.exists(`${path}__options`)) {
    return t(`${path}__options.${value}`)
  }
  return value?.toString() || 'null';
}
import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Avatar, Button, Descriptions, Divider, Drawer, message, Popconfirm, Space, Tag } from 'antd';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { AnnouncementMessage } from '../../../../../contexts/messages/MessagesContainer/Message/Message';
import { initials } from '../../../../../util/string';
import { useProgramManager } from '../../../../../contexts/programManager.context';
import { DATETIME_FORMAT } from '../../../../../constants/DATES';
import styles from '../../../Common/FilterTag/FilterTag.module.scss';
import { MODES, useMode } from '../../../../../contexts/mode.context';

export const UnsubscribeAnnouncementMutation = gql`
  mutation UnsubscribeAnnouncementMutation($id: ID!) {
    unsubscribeAnnouncement(id: $id) {
      id
    }
  }
`;

const AnnouncementDetail = ({ announcement, visible, onClose }) => {
  const mode = useMode();
  const programManager = useProgramManager();

  const [unsubscribe] = useMutation(UnsubscribeAnnouncementMutation, {
    variables: { id: announcement.id },
    onCompleted: () => {
      message.success('Announcement successfully cancelled !');
      onClose();
    },
    errorPolicy: 'all',
    refetchQueries: ['AnnouncementsQuery'],
  });

  return (
    <Drawer
      title="Announcement"
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
      extra={[
        (mode === MODES.PM || mode === MODES.META_PM) && moment().diff(announcement.triggerAt) < 0 && (
          <Popconfirm
            key="unsubscribe-btn"
            title={
              <span>
                Are you sure？
                <br />
                It will delete this announcement.
                <br />
                Your internal and merchant users will not receive it.
              </span>
            }
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={() => unsubscribe()}
          >
            <Button danger icon={<DeleteOutlined />}>
              DO NOT SEND
            </Button>
          </Popconfirm>
        ),
      ]}
    >
      <div style={{ backgroundColor: 'whitesmoke', padding: 8, width: '100%' }}>
        <div style={{ width: 400, margin: 'auto' }}>
          <AnnouncementMessage message={{ content: announcement }} preview />
        </div>
      </div>
      <Divider />
      <Descriptions title="Recipients" column={1} size="small" bordered>
        {!programManager && (
          <Descriptions.Item label="Internal Profiles">
            {announcement.internalAclProfiles?.length > 0
              ? announcement.internalAclProfiles?.map((p) => <Tag key={p.id}>{p.name}</Tag>)
              : 'None'}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Program Managers">
          {announcement.programManagers?.length > 0 ? (
            <Avatar.Group maxCount={10}>
              {announcement.programManagers?.map((pm) => (
                <Avatar
                  key={pm.id}
                  src={pm.theme.icon}
                  title={pm.name}
                  style={{ backgroundColor: pm.theme.secondaryColor }}
                >
                  {initials(pm.name)}
                </Avatar>
              ))}
            </Avatar.Group>
          ) : (
            'None'
          )}
        </Descriptions.Item>
        <Descriptions.Item label={programManager ? 'Internal Profiles' : 'Program Manager Profiles'}>
          {announcement.programManagerAclProfiles?.length > 0
            ? announcement.programManagerAclProfiles?.map((p) => (
                <Tag
                  key={p.id}
                  className={styles.imageTag}
                  style={{ paddingLeft: 0 }}
                  icon={
                    <img
                      alt=""
                      src={p.programManager.theme.icon}
                      title={p.programManager.name}
                      style={{
                        backgroundColor: p.programManager.theme.secondaryColor,
                        height: 16,
                        margin: '-1px 4px 0px 2px',
                        borderRadius: 10,
                      }}
                    />
                  }
                >
                  {p.name}
                </Tag>
              ))
            : 'None'}
        </Descriptions.Item>
        <Descriptions.Item label="Merchant Companies">
          {announcement.merchantCompanies?.length > 0 ? (
            <Avatar.Group maxCount={16} style={{ display: 'block' }}>
              {announcement.merchantCompanies?.map((m) => (
                <Avatar
                  key={m.id}
                  src={m.theme.icon}
                  title={m.name}
                  style={{ backgroundColor: m.theme.secondaryColor }}
                >
                  {initials(m.name)}
                </Avatar>
              ))}
            </Avatar.Group>
          ) : (
            'None'
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Merchant Profiles">
          {announcement.merchantAclProfiles.map((p) => (
            <Tag
              key={p.id}
              className={styles.imageTag}
              style={{ paddingLeft: 0 }}
              icon={
                <img
                  alt=""
                  src={p.programManager.theme.icon}
                  title={p.programManager.name}
                  style={{
                    backgroundColor: p.programManager.theme.secondaryColor,
                    height: 16,
                    margin: '-1px 4px 0px 2px',
                    borderRadius: 10,
                  }}
                />
              }
            >
              {p.name}
            </Tag>
          ))}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions title="Schedule" column={1} size="small" bordered>
        <Descriptions.Item label="Trigger">
          {moment(announcement.triggerAt).fromNow()} ({moment(announcement.triggerAt).format(DATETIME_FORMAT)})
        </Descriptions.Item>
        {announcement.expireAt && (
          <Descriptions.Item label="Expire">
            {moment(announcement.expireAt).fromNow()} ({moment(announcement.expireAt).format(DATETIME_FORMAT)})
          </Descriptions.Item>
        )}
      </Descriptions>
      <Divider />
      {announcement.createdBy ? (
        <Space>
          Created by
          <Avatar
            src={announcement.createdBy.avatar}
            size="small"
            title={`Created by ${announcement.createdBy.full_name}`}
          >
            {initials(announcement.createdBy.full_name)}
          </Avatar>
          {announcement.createdBy.full_name}
        </Space>
      ) : (
        'Created by NORBr'
      )}
    </Drawer>
  );
};

export default AnnouncementDetail;

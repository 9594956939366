import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';
import { PartitionOutlined } from '@ant-design/icons';
import { useMetaProgramManagerId } from '../../../../contexts/app.context';

const MetaProgramManagerAvatar = ({ metaProgramManager, size = 'small', noLink = false }) => {
  const [metaProgramManagerId] = useMetaProgramManagerId();
  // displayed only for internals
  if (metaProgramManagerId) return null;
  // check exist
  if (!metaProgramManager) return null;

  return noLink ? (
    <Avatar
      shape="square"
      title={metaProgramManager.name}
      src={metaProgramManager.theme.icon}
      icon={<PartitionOutlined />}
      size={size}
      style={{ backgroundColor: metaProgramManager.theme.secondaryColor }}
    />
  ) : (
    <Link to={`/meta-program-managers/${metaProgramManager.id}`}>
      <Avatar
        shape="square"
        title={metaProgramManager.name}
        src={metaProgramManager.theme.icon}
        icon={<PartitionOutlined />}
        size={size}
        style={{ backgroundColor: metaProgramManager.theme.secondaryColor }}
      />
    </Link>
  );
};

export default MetaProgramManagerAvatar;

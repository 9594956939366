import { gql } from '@apollo/client';

export const ProgramManagerPartnersQuery = gql`
  query ProgramManagerPartners($id: ID!) {
    programManager(id: $id) {
      id
      name
      theme {
        icon
        primaryColor
        secondaryColor
      }
      activePartners {
        partner {
          id
          name
          type
          company {
            id
            theme {
              icon
            }
          }
        }
        paymentMethods {
          id
          name
          imgUrl
        }
      }
      payfac
      displayPartners
      metaProgramManager {
        id
      }
    }
    partners(filters: { status: "active" }) {
      id
      name
      type
      company {
        id
        theme {
          icon
        }
      }
      payment_methods {
        id
        name
        imgUrl
      }
    }
  }
`;

export const PartnersQuery = gql`
  {
    partners {
      id
      name
      type
      company {
        theme {
          icon
        }
      }
    }
  }
`;

export const PartnerPaymentMethodsQuery = gql`
  query PartnerPaymentMethodsQuery($id: ID!, $programManagerId: ID) {
    partner(id: $id) {
      type
      payment_methods(programManagerId: $programManagerId) {
        id
        name
        imgUrl
      }
    }
  }
`;

export const ImpactMerchantContractsQuery = gql`
  query ImpactMerchantContractsQuery($id: ID!, $paymentMethods: [String], $programManager: ID) {
    partner(id: $id) {
      partner_routes(filters: { payment_method: $paymentMethods }) {
        payment_method {
          id
          name
          imgUrl
        }
        merchantRoutes(filters: { programManager: $programManager }) {
          merchant_account {
            id
            name
            company {
              id
              name
              theme {
                icon
              }
            }
            programManager {
              id
              name
              theme {
                icon
                primaryColor
                secondaryColor
              }
            }
          }
          merchant_contract {
            id
            name
          }
        }
      }
    }
  }
`;

export const ProgramManagerSetActivePartnerMutation = gql`
  mutation ProgramManagerSetActivePartnerMutation($id: ID!, $partner: ID!, $paymentMethods: [String]!) {
    programManagerSetActivePartner(id: $id, partner: $partner, paymentMethods: $paymentMethods) {
      id
      activePartners {
        partner {
          id
          name
        }
        paymentMethods {
          id
          name
          imgUrl
        }
      }
    }
  }
`;

export const ProgramManagerRemoveActivePartnerMutation = gql`
  mutation ProgramManagerRemoveActivePartner($id: ID!, $partner: ID!) {
    programManagerRemoveActivePartner(id: $id, partner: $partner) {
      id
      activePartners {
        partner {
          id
          name
        }
        paymentMethods {
          id
          name
          imgUrl
        }
      }
    }
  }
`;

export default {};

import React, { useState } from 'react';
import { Avatar, Button, Space, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import TableTile, { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import { initials, search } from '../../../../../../util/string';
import UserCreate from '../../../MetaProgramManagerUsers/UserCreate/UserCreate';
import { useCan } from '../../../../../../contexts/ability.context';
import { compareByProp } from '../../../../../../util/array';
import { Link } from '../../../../../../util/navigate';
import USER_STATUSES, { userStatusList } from '../../../../../../constants/USER_STATUSES';
import { MetaProgramManagerUsersQuery } from './query';

const MetaProgramManagerUsers = ({ id }) => {
  const can = useCan();

  const { data } = useQuery(MetaProgramManagerUsersQuery, { variables: { id } });

  const dataSource = data?.metaProgramManager.users?.map((user) => ({
    key: user.id,
    userId: user.id,
    avatar: user.avatar,
    name: user.full_name,
    email: user.email,
    status: user.status,
    disabled: user.disabled,
    aclProfile: user.aclProfile.id,
    aclProfileName: user.aclProfile.name,
  }));

  const columns = [
    {
      title: 'Fullname',
      dataIndex: 'name',
      key: 'name',
      render: (name, { userId, avatar }) => (
        <Link to={`/meta-program-users/${userId}`}>
          <Space>
            <Avatar src={avatar}>{initials(name)}</Avatar>
            {name}
          </Space>
        </Link>
      ),
      fixed: 'left',
      ...getColumnFilterSearchInput((value, record) => search(value, record.name)),
      sorter: compareByProp('name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      ...getColumnFilterSearchInput((value, record) => search(value, record.email)),
      sorter: compareByProp('email'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, { disabled }) =>
        disabled ? (
          <Tag color="volcano">Locked</Tag>
        ) : (
          status &&
          USER_STATUSES[status] && <Tag color={USER_STATUSES[status].color}>{USER_STATUSES[status].label}</Tag>
        ),
      filters: [
        { value: 'disabled', text: 'Locked' },
        ...userStatusList.map((s) => ({
          value: s.value,
          text: s.label,
        })),
      ],
      onFilter: (value, record) => (record.disabled ? value === 'disabled' : value === record.status),
    },
    {
      title: 'Profile',
      dataIndex: 'aclProfile',
      key: 'aclProfile',
      width: 220,
      render: (value, { aclProfileName }) => aclProfileName,
      filters: data?.metaProgramManagerAclProfiles.map((p) => ({
        value: p.name,
        text: p.name,
      })),
      onFilter: (value, record) => value === record.aclProfileName,
      sorter: compareByProp('aclProfileName'),
    },
  ];

  const [visible, setVisible] = useState(false);

  const showCreationModal = () => {
    setVisible(true);
  };

  const hideCreationModal = () => {
    setVisible(false);
  };

  // return info tile
  return (
    <>
      <TableTile
        name="meta-program-manager-users"
        title="Users"
        columns={columns}
        dataSource={dataSource}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} users`,
        }}
        size="small"
        scroll={{ x: 1000 }}
        extra={[
          can('create', 'meta-program-manager-user') && (
            <Button key="create" icon={<PlusOutlined />} onClick={showCreationModal}>
              Create Meta PM user
            </Button>
          ),
        ]}
      />
      <UserCreate visible={visible} onCancel={hideCreationModal} metaProgramManager={id} />
    </>
  );
};

export default MetaProgramManagerUsers;

import React, { useState } from 'react';
import { Button, Col, Form, message, Row, Space, Switch } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { useCan } from '../../../../../../contexts/ability.context';
import InfoTile, { InfoBooleanRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { programManagerAvailableServicesQuery, updateProgramManagerAvailableServicesMutation } from './query';

const serviceList = [
  { value: 'getStatus', label: 'Get status' },
  { value: 'updateToken', label: 'Update token' },
  { value: 'updateOrder', label: 'Update order' },
  { value: 'hostedFields', label: 'Hosted fields' },
  { value: 'hostedPage', label: 'Hosted page' },
  { value: 'directApiTokenization', label: 'Direct api tokenization' },
  { value: 'sdk', label: 'SDK' },
  { value: 'cmsModule', label: 'CMS module' },
];

const AvailableServices = ({ id }) => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);

  const { data } = useQuery(programManagerAvailableServicesQuery, { variables: { id } });

  const [updateProgramManagerAvailableServices, { loading }] = useMutation(
    updateProgramManagerAvailableServicesMutation,
    {
      onCompleted: () => {
        message.success('Program manager successfully updated');
        setEditMode(false);
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        message.error('An error occurred, please try again later.');
      },
    },
  );

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    updateProgramManagerAvailableServices({
      variables: { id, input: values },
    });
  };

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="available-services" title="Available services">
        <Form
          form={form}
          title="Default options"
          layout="vertical"
          size="small"
          initialValues={data?.programManager.availableServices}
          onFinish={handleFinish}
          requiredMark={false}
          className="tile-form"
        >
          <Row gutter={24}>
            {serviceList.map((option) => (
              <Col key={option.value} xs={24} sm={12} md={8} lg={8} xl={6}>
                <Form.Item name={option.value} label={option.label} valuePropName="checked" colon={false}>
                  <Switch />
                </Form.Item>
              </Col>
            ))}
          </Row>
          <Form.Item style={{ textAlign: 'right', marginTop: 16 }}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  return (
    <InfoTile
      name="available-services"
      title="Available services"
      extra={[
        can('update', 'program-manager-available-services') && (
          <Button
            key="update-available-services"
            onClick={() => setEditMode(true)}
            shape="text"
            icon={<EditOutlined />}
          />
        ),
      ]}
    >
      <Row gutter={24}>
        {serviceList.map((option) => (
          <Col key={option.value} xs={24} sm={12} md={8} lg={8} xl={6}>
            <InfoBooleanRow
              key={option.value}
              value={!!data?.programManager.availableServices?.[option.value]}
              label={option.label}
            />
          </Col>
        ))}
      </Row>
    </InfoTile>
  );
};

export default AvailableServices;

import { gql } from '@apollo/client';

const query = `{
  _id
  transactions {
    _id
    id
    operations {
      id
      request_date
      request_status
      result_response
      result_date
      type
      result_status
      amount
      currency
    }
  }
}`;

export const orderPaymentOperationsQuery = gql`
  query OrderPaymentOperations($orderId: ID!) {
    order(id: $orderId) ${query}
  }
`;

export const activityPaymentOperationsQuery = gql`
  query ActivityPaymentOperations($orderId: ID!) {
    activity(id: $orderId) ${query}
  }
`;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Page from '../../Common/Page/Page';
import ErrorBoundary from '../../Common/ErrorBoundary/ErrorBoundary';
import ProgramManagerList from './ProgramManagerList/ProgramManagerList';
import ProgramManagerView from './ProgramManagerView/ProgramManagerView';

const ProgramManagers = () => (
  <Page>
    <ErrorBoundary>
      <Routes>
        <Route index element={<ProgramManagerList />} />
        <Route path=":programManagerId" element={<ProgramManagerView />} />
      </Routes>
    </ErrorBoundary>
  </Page>
);

export default ProgramManagers;

import { gql } from '@apollo/client';

export const updateNotificationMutation = gql`
  mutation UpdateWebhook($id: ID!, $input: WebhookInput!) {
    updateWebhook(id: $id, input: $input) {
      id
      name
      url
      trigger
      status
      notify_last_transaction_only
    }
  }
`;

export const triggersQuery = gql`
  {
    triggers
  }
`;

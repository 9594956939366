import { gql } from '@apollo/client';

export const addMetaProgramManagerContactMutation = gql`
  mutation AddMetaProgramManagerContact($id: ID!, $input: ContactInput!) {
    addMetaProgramManagerContact(id: $id, input: $input) {
      id
      contacts {
        _id
        firstname
        surname
        email
        phone {
          code
          number
        }
        title
        type
        priorityLevel
      }
    }
  }
`;

export const updateMetaProgramManagerContactMutation = gql`
  mutation UpdateMetaProgramManagerContact($id: ID!, $contactId: ID!, $input: ContactInput!) {
    updateMetaProgramManagerContact(id: $id, contactId: $contactId, input: $input) {
      id
      contacts {
        _id
        firstname
        surname
        email
        phone {
          code
          number
        }
        title
        type
        priorityLevel
      }
    }
  }
`;

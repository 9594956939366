import { gql } from '@apollo/client';

export const callsQuery = gql`
  query CallsStatus($notificationId: ID!) {
    notification(id: $notificationId) {
      _id
      calls {
        code
        date
        body {
          status
          statusText
          data
        }
        signature
        timestamp
      }
    }
  }
`;

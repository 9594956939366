import React, { useCallback, useState } from 'react';
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  Layout,
  Pagination,
  Row,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import ReactHeight from 'react-height';
import classnames from 'classnames';
import FeatureSearchIcon from '@2fd/ant-design-icons/lib/FeatureSearch';
import { FunctionOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { useGdpr } from '../../../../contexts/me.context';
import { TableDisplay, tableDisplayList } from '../Common/constants/tableDisplays';
import useDrawer from '../Common/useDrawer';
import Drawers from '../Common/constants/drawers';
import usePagination from '../hooks/usePagination';
import useTableDisplay from '../hooks/useTableDisplay';
import SearchFilterTags from '../Common/SearchFilterTags/SearchFilterTags';
import { buildColumnProps } from '../Common/AdvancedTable/buildColumns';
import styles from '../Matcher/MatcherTable/MatcherTable.module.scss';
import { config as dimensionConfig } from '../Dashboard/constants/dimensions';
import FieldTypes from '../Common/constants/fieldTypes';
import useOverviewQuery from './useOverviewQuery';
import { list as indicatorList } from '../Dashboard/constants/indicators';
import useDimensions from '../hooks/useDimensions';
import useSort from '../hooks/useSort';
import useSearchFilters from '../hooks/useSearchFilters';
import fields, { fieldTypes } from '../Common/constants/fields';
import useBaseGranularity, { granularities } from '../hooks/useBaseGranularity';
import GranularitySelector from '../Common/GranularitySelector/GranularitySelector';
import CurrencySelector from '../Common/CurrencySelector/CurrencySelector';
import { DATETIME2_FORMAT } from '../../../../constants/DATES';
import { config as amountRanges } from '../Dashboard/constants/tables/amount_ranges';
import useColumnSet from '../hooks/useColumnSet';
import usePage from '../hooks/usePage';

const buildSearchFiltersFromDimensions = (dimensions, record, { granularity = 'day' }) =>
  dimensions.reduce((memo, d) => {
    const dimension = dimensionConfig[d];
    const value = record[d];

    let filter;

    // specific overview
    if (dimension.fieldType === 'NO_DIMENSION') return memo;
    if (dimension.fieldType === 'AMOUNT_RANGE') {
      filter = { value: value === 'No label' ? '__NULL__' : amountRanges[value]?.filter, excluded: false };
    } else {
      const field = fields[dimension.field];
      switch (field.type) {
        case FieldTypes.ASYNC_LIST:
        case FieldTypes.LIST:
        case FieldTypes.TEXT:
        case FieldTypes.CUSTOM:
          filter = { value: value === 'No label' ? ['__NULL__'] : [value], excluded: false };
          break;
        case FieldTypes.AMOUNT:
        case FieldTypes.NUMBER:
        case FieldTypes.COUNT:
          filter = { value: value === 'No label' ? '__NULL__' : { min: value, max: value }, excluded: false };
          break;
        case FieldTypes.DATE:
          filter = {
            value:
              value === 'No label'
                ? '__NULL__'
                : {
                    from: moment(value).format(DATETIME2_FORMAT),
                    to: moment(value).endOf(granularity).format(DATETIME2_FORMAT),
                  },
            excluded: false,
          };
          break;
        case FieldTypes.BOOLEAN:
          switch (value) {
            case 'No label':
              filter = { value: ['__NULL__'], excluded: false };
              break;
            case false:
            case 'False':
              filter = { value: [false], excluded: false };
              break;
            case true:
            case 'True':
              filter = { value: [true], excluded: false };
              break;
            default:
              break;
          }
          break;
        default:
          throw new Error(`Field type ${field.type} do not exist.`);
      }
    }

    return {
      ...memo,
      [dimension.field]: filter,
    };
  }, {});

/**
 * Build AntD Table columns from columnSet and context
 */
const useColumns = () => {
  const page = usePage();
  const [columnSet] = useColumnSet();
  const [sort] = useSort();
  const [tableDisplay] = useTableDisplay();
  const isGdpr = useGdpr();
  const [baseGranularity] = useBaseGranularity();

  return columnSet.map((c, index, { length }) => {
    const sortIndex = sort.findIndex((s) => s.field === c.field);

    const field = {
      ...page.fields[c.field],
      ...(c.field === 'amount_range' ? fieldTypes.AMOUNT_RANGE : fieldTypes[page.fields[c.field].type]),
    };

    // Exception for type Date, format should be deduced from baseGranularity
    const column = field.type === FieldTypes.DATE ? { ...c, format: granularities[baseGranularity].format } : c;

    return {
      key: c.field,
      dataIndex: c.field,
      title: indicatorList.includes(c.field) ? (
        <Space>
          <FunctionOutlined />
          {c.label}
        </Space>
      ) : (
        c.label
      ),
      ellipsis: true,
      width: Math.max(c.label.length) * 14 + 30,
      sorter: { multiple: sortIndex === -1 ? length : sortIndex },
      sortOrder: sort[sortIndex]?.sortOrder,
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      fixed: index === 0 ? 'left' : false,
      ...buildColumnProps(field, column, isGdpr, tableDisplay === TableDisplay.TEXT),
    };
  });
};

const OverviewTable = () => {
  const { dateFilter, searchFilters, setAllAndRedirect } = useSearchFilters();

  const [, setDrawer] = useDrawer();

  const [height, setHeight] = useState();

  const [dimensions] = useDimensions();

  const [baseGranularity] = useBaseGranularity();
  const [tableDisplay, setTableDisplay] = useTableDisplay();
  const { page, pageSize, setPage } = usePagination();
  const [, setSort] = useSort();

  const columns = useColumns();

  const handleTableChange = (_p, _f, sorter) => {
    // handle single sort, default from favorite view
    if (Array.isArray(sorter)) {
      setSort([{ field: sorter[1].field, sortOrder: sorter[1].order }]);
    } else if (sorter.order) {
      setSort([{ field: sorter.field, sortOrder: sorter.order }]);
    } else {
      setSort();
    }
  };

  const { result, total, count, loading, error } = useOverviewQuery();

  const redirectToMatcher = useCallback(
    (record) => {
      const newFilters = {
        dateFilter,
        searchFilters: {
          ...searchFilters,
          ...buildSearchFiltersFromDimensions(dimensions, record, { granularity: baseGranularity }),
        },
      };
      setAllAndRedirect(newFilters, '/brainpower/orders');
    },
    [dateFilter, searchFilters, setAllAndRedirect, baseGranularity],
  );

  return (
    <>
      <Layout>
        <Layout.Header className={styles.layoutHeader}>
          <Row gutter={24} style={{ position: 'relative' }}>
            <Col>
              <Space>
                Results
                <Tag style={{ color: 'darkgrey' }}>{count}</Tag>
              </Space>
            </Col>
            <Col flex="1" style={{ position: 'initial' }}>
              <SearchFilterTags />
            </Col>
            <Col>
              <GranularitySelector />
            </Col>
            <Col>
              <CurrencySelector />
            </Col>
          </Row>
        </Layout.Header>
        <Layout
          id="summary-table"
          className={styles.layoutContent}
          style={{ backgroundColor: 'white', boxShadow: '1px 1px 4px #d0d0d0', zIndex: 20 }}
        >
          <Layout.Content>
            <ReactHeight onHeightReady={setHeight} style={{ height: '100%' }}>
              {height ? (
                <ConfigProvider
                  renderEmpty={() => (
                    <Empty
                      className={styles.empty}
                      style={{ height: height - 74 }}
                      image={loading ? null : undefined}
                      description={(() => {
                        if (loading) {
                          return null;
                        }
                        if (error) {
                          return (
                            <Typography.Text type="danger">An error occurred, please contact support.</Typography.Text>
                          );
                        }
                        return [
                          <div key="no-results-found">No results found</div>,
                          <Button
                            key="adjust-filters"
                            type="link"
                            icon={<FeatureSearchIcon style={{ fontSize: 18 }} />}
                            onClick={() => setDrawer(Drawers.SEARCH)}
                          >
                            Adjust filters
                          </Button>,
                        ];
                      })()}
                    />
                  )}
                >
                  <Table
                    className={classnames(styles.table)}
                    rowClassName={styles.tableRow}
                    columns={columns}
                    dataSource={result}
                    loading={loading && { size: 'large' }}
                    size="small"
                    scroll={{ x: 'max-content', y: height - 96 }} // available height - head row height
                    pagination={false}
                    summary={() =>
                      total && (
                        <Table.Summary fixed>
                          <Table.Summary.Row className={styles.tableSummaryRow}>
                            {columns.map((c, index) =>
                              index === 0 ? (
                                <Table.Summary.Cell index={0}>TOTAL</Table.Summary.Cell>
                              ) : (
                                <Table.Summary.Cell index={index} {...c}>
                                  {c.render(total[c.dataIndex], total)}
                                </Table.Summary.Cell>
                              ),
                            )}
                          </Table.Summary.Row>
                        </Table.Summary>
                      )
                    }
                    onRow={(record, index) => ({
                      onClick: () => redirectToMatcher(record),
                    })}
                    onChange={handleTableChange}
                  />
                </ConfigProvider>
              ) : (
                <div />
              )}
            </ReactHeight>
          </Layout.Content>
          <Layout.Footer className={styles.layoutFooter}>
            <Row gutter={24} justify="space-between">
              <Col>
                <Space>
                  <Pagination
                    className={styles.pagination}
                    total={count}
                    current={page}
                    pageSize={pageSize}
                    onChange={setPage}
                    showSizeChanger={false}
                    showLessItems
                    size="small"
                  />
                  <Select
                    value={pageSize}
                    onSelect={(value) => setPage(1, value)}
                    options={[{ value: 10 }, { value: 20 }, { value: 50 }]}
                    bordered={false}
                  />
                </Space>
              </Col>
              <Col>
                <Segmented
                  value={tableDisplay}
                  onChange={setTableDisplay}
                  options={tableDisplayList}
                  style={{ fontSize: 18 }}
                />
              </Col>
            </Row>
          </Layout.Footer>
        </Layout>
      </Layout>
    </>
  );
};

export default OverviewTable;

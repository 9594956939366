import { gql } from '@apollo/client';

export const accountPartnersQuery = gql`
  query MerchantAccount($id: ID!) {
    merchantAccount(id: $id) {
      id
      source
      merchant_contracts(filters: { status: { in: ["active", "inactive"] } }) {
        id
        partner {
          id
          name
          partner_data_configuration
          type
        }
        configuration
      }
      partners(filters: { type: "authentication" }) {
        id
        name
        partner_data_configuration
      }
    }
  }
`;

export const merchantAccountContractsQuery = gql`
  query MerchantAccount($id: ID!) {
    merchantAccount(id: $id) {
      id
      merchant_contracts(filters: { status: { in: ["active", "inactive"] } }) {
        id
        name
        partner {
          id
          name
          type
        }
      }
    }
  }
`;

export const authenticationPartnersQuery = gql`
  {
    partners(filters: { type: "authentication", status: "active" }) {
      id
      name
      type
    }
  }
`;

import { gql } from '@apollo/client';

export const UserViewQuery = gql`
  query UserViewQuery($id: ID!) {
    merchantUser(id: $id) {
      id
      full_name
      first_name
      last_name
      email
      phone {
        code
        number
      }
      avatar
      status
      disabled
      disabledAt
      disabledBy {
        id
        email
        full_name
        avatar
      }
      activated
      companies {
        id
        company {
          id
          name
          status
          country
          theme {
            logo
            icon
            primaryColor
            secondaryColor
          }
          merchantAccounts {
            id
            name
          }
        }
        aclProfile {
          id
          name
        }
        isGdpr
        authorizedMerchantAccounts {
          id
          name
          status
          cms_name
          cms_version
          userCount
        }
      }
      email_verification_token
      email_verification_token_expires_at
      lastConnection
      programManager {
        id
        name
        merchantDomain
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
      deleted
    }
  }
`;

export const UserAuditLogsQuery = gql`
  query UserAuditLogsQuery($id: ID!) {
    merchantUser(id: $id) {
      id
      createdAt
      createdBy {
        id
        full_name
        email
        avatar
      }
      updatedAt
      updatedBy {
        id
        full_name
        email
        avatar
      }
      auditLogs {
        id
        action
        entityId
        entityName
        timestamp
        createdBy {
          email
          full_name
          avatar
        }
      }
    }
  }
`;

export const UserUpdateMutation = gql`
  mutation UserUpdateMutation($id: ID!, $input: UserUpdateInput!) {
    updateMerchantUser(id: $id, input: $input) {
      id
      first_name
      last_name
      full_name
      avatar
      phone {
        code
        number
      }
    }
  }
`;

export const UserAvatarUpload = gql`
  mutation UserAvatarUpload($id: ID!, $file: Upload) {
    uploadUserAvatar(id: $id, file: $file) {
      id
      avatar
    }
  }
`;

export const UserDisabledUpdateMutation = gql`
  mutation UserDisabledUpdateMutation($id: ID!, $disabled: Boolean!) {
    updateUserDisabled(id: $id, disabled: $disabled) {
      __typename
      id
      status
      disabled
      disabledBy {
        full_name
        avatar
      }
      disabledAt
    }
  }
`;

export const UserDeleteMutation = gql`
  mutation UserDeleteMutation($id: ID!) {
    deleteUser(id: $id)
  }
`;

export default {};

import React, { useContext, useEffect, useState } from 'react';
import { defaultTheme, applyTheme } from '../util/theme';
import { useMe } from './me.context';
import { useProgramManager } from './programManager.context';
import AppLoading from '../AppLoading';
import {useMetaProgramManager} from './metaProgramManager.context';
import {MODES, useMode} from './mode.context';

export const ThemeContext = React.createContext(undefined);

export const useTheme = () => useContext(ThemeContext);

const ThemeProvider = (props) => {
  const { children } = props;
  const programManager = useProgramManager();
  const metaProgramManager = useMetaProgramManager();
  const mode = useMode();
  const me = useMe();

  const [themeApplied, setThemeApplied] = useState(false);

  const metaProgramManagerTheme = {
    primaryColor: (metaProgramManager ?? programManager?.metaProgramManager)?.theme.primaryColor || defaultTheme.primaryColor,
    secondaryColor: (metaProgramManager ?? programManager?.metaProgramManager)?.theme.secondaryColor || defaultTheme.secondaryColor,
    loader: (metaProgramManager ?? programManager?.metaProgramManager)?.theme?.loader,
  };

  const programManagerTheme = {
    primaryColor: programManager?.theme.primaryColor || metaProgramManagerTheme.primaryColor,
    secondaryColor: programManager?.theme.secondaryColor || metaProgramManagerTheme.secondaryColor,
    loader: programManager?.theme?.loader ?? metaProgramManagerTheme.loader,
  };

  const companyTheme = [MODES.META_PM, MODES.INTERNAL, MODES.PM].includes(mode)
    ? programManagerTheme
    : {
      primaryColor: me?.companies?.[0]?.company?.theme.primaryColor || programManagerTheme.primaryColor,
      secondaryColor: me?.companies?.[0]?.company?.theme.secondaryColor || programManagerTheme.secondaryColor,
      loader: programManagerTheme.loader,
    }

  const userTheme = {
    primaryColor: me?.theme.primaryColor || companyTheme.primaryColor,
    secondaryColor: me?.theme.secondaryColor || companyTheme.secondaryColor,
    loader: programManagerTheme.loader,
  };

  // Apply Theme
  // user > company > default
  useEffect(() => {
    applyTheme(userTheme);
    setThemeApplied(true);
  }, []);

  if (!themeApplied) return <AppLoading />;

  return (
    <ThemeContext.Provider value={{ userTheme, companyTheme, programManagerTheme }}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;

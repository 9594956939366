import SharedFieldTypes from 'norbr-shared-lib/constants/order/fields/fieldTypes';
import { MODES } from '../../../../contexts/mode.context';
import { fieldTypes } from '../../Brainpower/Common/constants/fields';
import FieldTypes from '../../Brainpower/Common/constants/fieldTypes';
import TRANSACTION_STATUSES from '../../../../constants/TRANSACTION_STATUSES';

export const Fields = {
  PROGRAM_MANAGER: 'program_manager',
  PROGRAM_MANAGER_NAME: 'program_manager_name',
  COMPANIES: 'companies',
  COMPANY_COUNT: 'company_count',
  MERCHANT_ACCOUNTS: 'merchant_accounts',
  MERCHANT_ACCOUNT_COUNT: 'merchant_account_count',
  USER_COUNT: 'user_count',
  AUTHOR: 'author',
  CREATION_DATE: 'creation_date',
  NAME: 'name',
  DESCRIPTION: 'description',
};

const fields = (mode) => ({
  ...([MODES.INTERNAL, MODES.META_PM].includes(mode)
    ? {
        [Fields.PROGRAM_MANAGER]: {
          key: Fields.PROGRAM_MANAGER,
          label: 'Program manager',
          type: SharedFieldTypes.PROGRAM_MANAGER,
          entity: 'merchant_group',
          path: 'programManager.id',
          description: 'The program manager of the merchant account.',
          sortField: Fields.PROGRAM_MANAGER_NAME,
          filter: true,
          ...fieldTypes[SharedFieldTypes.PROGRAM_MANAGER],
        },
        [Fields.PROGRAM_MANAGER_NAME]: {
          key: Fields.PROGRAM_MANAGER_NAME,
          label: 'Program manager name',
          type: SharedFieldTypes.TEXT,
          entity: 'merchant_group',
          path: 'programManager.name',
          description: 'The name of the program manager.',
          filter: false,
          ...fieldTypes[SharedFieldTypes.TEXT],
        },
      }
    : {}),
  [Fields.COMPANIES]: {
    key: Fields.COMPANIES,
    label: 'Companies',
    type: SharedFieldTypes.COMPANY,
    entity: 'merchant_group',
    path: 'merchantCompanies',
    description: 'Merchant companies of the group.',
    sortField: Fields.COMPANY_COUNT,
    filter: true,
    ...fieldTypes[SharedFieldTypes.COMPANY],
    displayOptions: {
      avatar: true,
      hideLabel: true,
    },
  },
  [Fields.MERCHANT_ACCOUNTS]: {
    key: Fields.MERCHANT_ACCOUNTS,
    label: 'Merchant accounts',
    type: SharedFieldTypes.MERCHANT_ACCOUNT,
    entity: 'merchant_group',
    path: 'merchantAccounts',
    description: 'Merchant accounts of the group.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.MERCHANT_ACCOUNT],
    displayOptions: {
      tag: true,
      active: true,
    },
  },
  [Fields.USER_COUNT]: {
    key: Fields.USER_COUNT,
    label: 'User count',
    type: SharedFieldTypes.COUNT,
    entity: 'merchant_group',
    path: 'userCount',
    description: 'The count of users using the group.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.COUNT],
  },
  [Fields.NAME]: {
    key: Fields.NAME,
    label: 'Name',
    type: SharedFieldTypes.TEXT,
    entity: 'merchant_group',
    path: 'name',
    description: 'The name of the group.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.TEXT],
  },
  [Fields.DESCRIPTION]: {
    key: Fields.DESCRIPTION,
    label: 'Description',
    type: SharedFieldTypes.TEXT,
    entity: 'merchant_group',
    path: 'description',
    description: 'The description of the group.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.TEXT],
  },
  [Fields.CREATION_DATE]: {
    key: Fields.CREATION_DATE,
    label: 'Creation date',
    type: SharedFieldTypes.DATE,
    entity: 'merchant_group',
    path: 'createdAt',
    description: 'The creation date of the group.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.DATE],
  },
});
export default fields;

import { gql } from '@apollo/client';

export const addProgramManagerContactMutation = gql`
  mutation AddProgramManagerContact($id: ID!, $input: ContactInput!) {
    addProgramManagerContact(id: $id, input: $input) {
      id
      contacts {
        _id
        firstname
        surname
        email
        phone {
          code
          number
        }
        title
        type
        priorityLevel
      }
    }
  }
`;

export const updateProgramManagerContactMutation = gql`
  mutation UpdateProgramManagerContact($id: ID!, $contactId: ID!, $input: ContactInput!) {
    updateProgramManagerContact(id: $id, contactId: $contactId, input: $input) {
      id
      contacts {
        _id
        firstname
        surname
        email
        phone {
          code
          number
        }
        title
        type
        priorityLevel
      }
    }
  }
`;

import React from 'react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';

const SortableList = (props) => {
  const { children, droppableId, type, onSort } = props;

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination || result.destination.droppableId !== droppableId) {
      return;
    }
    onSort(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={droppableId} type={type}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SortableList;

import { gql } from '@apollo/client';

export const MerchantGroupListQuery = gql`
  query MerchantGroupListQuery {
    merchantGroups {
      id
      name
      description
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
      merchantCompanies {
        id
        name
        theme {
          primaryColor
        }
      }
      merchantCompanyCount
      merchantAccounts {
        id
        name
      }
      merchantAccountCount
      userCount
      createdBy {
        id
        full_name
        avatar
      }
      createdAt
    }
  }
`;

export default {};

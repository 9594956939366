import React from 'react';
import { useCan } from '../../contexts/ability.context';
import NotAuthorized from './Common/Pages/NotAuthorized';
import { useProgramManagerId } from '../../contexts/app.context';

const RestrictedPage = (props) => {
  const { children, action, subject, internalOnly } = props;
  const can = useCan();

  const [programManagerId] = useProgramManagerId();

  if (!can(action, subject) || (internalOnly && programManagerId)) {
    return <NotAuthorized message={`${action} ${subject}`} />;
  }

  return children;
};

export default RestrictedPage;

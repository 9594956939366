import React from 'react';
import PollIcon from '@2fd/ant-design-icons/lib/Poll';
import TableEyeIcon from '@2fd/ant-design-icons/lib/TableEye';
import CartIcon from '@2fd/ant-design-icons/lib/Cart';
import ShoppingIcon from '@2fd/ant-design-icons/lib/Shopping';
import BookmarkIcon from '@2fd/ant-design-icons/lib/Bookmark';
import SwapVerticalCircleIcon from '@2fd/ant-design-icons/lib/SwapVerticalCircle';
import CurrencyEurIcon from '@2fd/ant-design-icons/lib/CurrencyEur';
import BellIcon from '@2fd/ant-design-icons/lib/Bell';
import {Badge} from 'antd';
import InboxArrowDownIcon from '@2fd/ant-design-icons/lib/InboxArrowDown';
import classnames from 'classnames';
import styles from '../Menu.module.scss';
import {useMessages} from '../../../contexts/messages/messages.context';

const Brainpower = (can) => {
  const { messagesByType } = useMessages();

  return [{
    type: 'group',
    label: 'Brainpower',
    children: [
      {
        key: '/brainpower/dashboard',
        icon: <PollIcon />,
        label: 'Dashboard',
      },
      can('read', 'overview') && {
        key: '/brainpower/overview',
        icon: <TableEyeIcon />,
        label: 'Overview',
      },
      can('read', 'checkout') && {
        key: '/brainpower/checkouts',
        icon: <CartIcon />,
        label: 'Checkouts',
      },
      can('read', 'order') && {
        key: '/brainpower/orders',
        icon: <ShoppingIcon />,
        label: 'Orders',
      },
      can('read', 'order') && {
        key: '/brainpower/transactions',
        icon: <BookmarkIcon />,
        label: 'Transactions',
      },
      can('read', 'matcher') && {
        key: '/brainpower/matcher',
        icon: <SwapVerticalCircleIcon />,
        label: 'Matcher',
        children: [
          {
            label: 'Cashier',
            key: '/brainpower/matcher-cashier-summary',
          },
          {
            label: 'Payment partner',
            key: '/brainpower/matcher-payment-partner',
          },
          {
            label: 'Matcher engine',
            key: '/brainpower/matcher-engine',
          },
        ]
      },
      can('read', 'financial-operation') && {
        key: '/brainpower/financial-operations',
        icon: <CurrencyEurIcon />,
        label: 'Finance',
      },
      can('read', 'notification') && {
        key: '/brainpower/notifications',
        icon: <BellIcon />,
        label: 'Notifications',
      },
      can('read', 'report') && {
        key: '/brainpower/exports',
        icon: (
          <Badge
            size="small"
            count={messagesByType('report_completed').length}
            overflowCount={9}
            className={styles.exportBadge}
            style={{
              top: 20,
              right: 12,
            }}
          >
            <InboxArrowDownIcon className={classnames(styles.exportIcon, 'ant-menu-item-icon')} />
          </Badge>
        ),
        label: 'Exports',
        id: 'export',
      },
    ],
  }];
}

export default Brainpower;
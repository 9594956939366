import { gql } from '@apollo/client';

export const programManagerSsoConfigQuery = gql`
query ProgramManagerSsoConfig($id: ID!) {
  programManager(id: $id) {
    id
    sso {
      draft {
        tenantId
        providerId
        idpEntityId
        ssoURL
        rpEntityId
        x509Certificates
      }
      config {
        tenantId
        providerId
        idpEntityId
        ssoURL
        rpEntityId
        x509Certificates  
      }
    }
  }
}
`;

export const updateSsoDraftMutation = gql`
  mutation UpdateSsoDraft($id: ID!, $input: SsoConfigInput!) {
    updateProgramManagerSsoDraft(id: $id, input: $input) {
      id
      sso {
        draft {
          tenantId
          providerId
          idpEntityId
          ssoURL
          rpEntityId
          x509Certificates 
        }
      }
    }
  }
`;

export const applySsoConfigMutation = gql`
  mutation UpdateProgramManagerApplySsoDraftSso($id: ID!) {
    updateProgramManagerApplySsoDraft(id: $id) {
      id
      sso {
        config {
          tenantId
          providerId
          idpEntityId
          ssoURL
          rpEntityId
          x509Certificates
        }
        draft {
          tenantId
          providerId
          idpEntityId
          ssoURL
          rpEntityId
          x509Certificates 
        }
      }
    }
  }
`;
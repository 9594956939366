import React, { useState } from 'react';
import { Button, Divider, Modal, Space, Tag } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {useParams} from 'react-router-dom';
import TableTile, { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import { search } from '../../../../../../util/string';
import { useCan } from '../../../../../../contexts/ability.context';
import useToggle from '../../../../../../hooks/useToggle';
import { compareByProp } from '../../../../../../util/array';
import ContactForm from './ContactForm/ContactForm';
import { companyContactsQuery, removeCompanyContactMutation } from './query';
import { contactTypes } from './constants';

const CompanyContacts = () => {
  const can = useCan();
  const { companyId } = useParams();

  const [showAddContact, { toggle }] = useToggle(false);
  const [editedContact, setEditedContact] = useState(null);

  const {
    data: { merchantCompany: company } = {
      merchantCompany: {
        contacts: [],
        attributeListRequiredByPartners: [],
      },
    },
    loading,
  } = useQuery(companyContactsQuery, {
    variables: {
      companyId,
    },
  });

  const { contacts = [] } = company;

  const [removeContact] = useMutation(removeCompanyContactMutation, {
    variables: {
      companyId,
    },
    refetchQueries: ['CompanyContacts'],
  });

  const handleRemoveContact = (contact) => () => {
    Modal.confirm({
      title: `Remove contact ${contact.email ?? `${contact.firstname} ${contact.surname}`}`,
      content: 'Are you sure you want to delete this contact?',
      onOk: () =>
        removeContact({
          variables: {
            contactId: contact._id,
          },
          // optimisticResponse: {
          //   removeCompanyContact: {
          //     ...company,
          //     contacts: company.contacts.filter((contactElem) => contactElem._id !== contact._id),
          //   },
          // },
        }),
    });
  };

  const columns = [
    {
      title: 'Level',
      dataIndex: 'priorityLevel',
      key: 'priorityLevel',
      width: 120,
      align: 'center',
      sorter: compareByProp('priorityLevel'),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, contact) => (
        <Space>
          {contact.firstname}
          {contact.surname}
          {contact.main && <Tag color={company.theme?.primaryColor}>main</Tag>}
        </Space>
      ),
      ...getColumnFilterSearchInput((value, contact) => search(value, `${contact.firstname} ${contact.surname}`)),
      sorter: (a, b) =>
        `${a.firstname} ${a.surname}`.toLowerCase() > `${b.firstname} ${b.surname}`.toLowerCase() ? 1 : -1,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      filters: contactTypes.map((contactType) => ({
        ...contactType,
        text: contactType.label,
      })),
      onFilter: (value, contact) => value === contact.type,
      render: (type) => contactTypes.find((contactType) => contactType.value === type)?.label,
    },
  ];

  if (can('update', 'company')) {
    columns.push({
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 160,
      render: (value, contact) => [
        <Button
          key="edit-contact"
          icon={<EditOutlined />}
          onClick={() => setEditedContact(contact === editedContact ? null : contact)}
          title="Edit contact"
          shape="circle"
          type="text"
        />,
        <Divider key="divider" type="vertical" />,
        <Button
          key="remove-contact"
          icon={<DeleteOutlined />}
          onClick={handleRemoveContact(contact)}
          title={contact.main ? 'Cannot remove main contact' : 'Remove contact from company'}
          shape="circle"
          type="text"
          disabled={contact.main}
        />,
      ],
    });
  }

  return (
    <>
      <TableTile
        name="company-contacts"
        title="Contacts"
        columns={columns}
        loading={loading}
        dataSource={contacts
          .map((contact) => ({
            ...contact,
            key: contact._id,
          }))
          .sort(compareByProp('priorityLevel'))}
        extra={[
          can('update', 'company') && !loading && (
            <Button key="add-contact" icon={showAddContact ? <RightOutlined /> : <PlusOutlined />} onClick={toggle}>
              Contact
            </Button>
          ),
        ]}
      />
      <ContactForm
        visible={showAddContact || editedContact}
        company={company}
        toggle={editedContact ? () => setEditedContact(null) : toggle}
        contact={editedContact}
      />
    </>
  );
};

export default CompanyContacts;

import { gql } from '@apollo/client';

export const webhookQuery = gql`
  query Webhook($notificationId: ID!) {
    notification(id: $notificationId) {
      _id
      merchantAccount {
        _id
      }
      webhook {
        id
        name
        url
        trigger
        status
        additional_headers
        notify_last_transaction_only
        createdAt
        updatedAt
      }
    }
  }
`;

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import { compareByProp } from '../../../../../../util/array';
import { config as Indicators } from '../../constants/indicators';
import Units from '../../constants/units';

/**
 * PieInColumn
 * @param divId
 * @param widget
 * @param data
 * @param args
 * @returns {XYChart}
 */
export default (divId, widget, data, args) => {
  const { dimensions, indicators, options } = widget;

  const dimension = dimensions[0];
  const indicator = Indicators[indicators[0]];

  const formatData = data.data
    .map((row) => {
      const { [dimension]: category, ...pieSeries } = row;
      return {
        category,
        total: Object.values(pieSeries).reduce((sum, val) => sum + val, 0),
        pie: Object.keys(pieSeries).map((key) => ({
          title: key,
          value: pieSeries[key],
        })),
      };
    })
    .filter((row) => row.total > 0)
    .sort(compareByProp('total', 'desc'))
    .slice(0, options?.slice_items || 8);

  // Create chart instance
  const chart = am4core.create(divId, am4charts.XYChart);
  chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

  // Add data
  chart.data = formatData;

  // Create axes
  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = 'category';
  categoryAxis.renderer.grid.template.disabled = true;

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  // eslint-disable-next-line prefer-destructuring
  valueAxis.title.text = Indicators[indicators[0]].label;
  valueAxis.min = 0;
  valueAxis.renderer.baseGrid.disabled = true;
  valueAxis.renderer.grid.template.strokeOpacity = 0.07;

  // Create series
  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.valueY = 'total';
  series.dataFields.categoryX = 'category';
  series.tooltip.pointerOrientation = 'vertical';

  // add tooltip on column, not template, so that slices could also have tooltip
  const columnTemplate = series.columns.template;

  switch (indicator.unit) {
    case Units.Percent:
      columnTemplate.column.tooltipText = `{categoryX}: {valueY.formatNumber('#.##')}%`;
      break;
    case Units.Transaction:
      columnTemplate.column.tooltipText = `{categoryX}: {valueY.formatNumber('#,###')} transactions`;
      break;
    case Units.Order:
      columnTemplate.column.tooltipText = `{categoryX}: {valueY.formatNumber('#,###')} orders`;
      break;
    case Units.Checkout:
      columnTemplate.column.tooltipText = `{categoryX}: {valueY.formatNumber('#,###')} checkouts`;
      break;
    case Units.Operation:
      columnTemplate.column.tooltipText = `{categoryX}: {valueY.formatNumber('#,###')} operations`;
      break;
    case Units.Ticket:
      columnTemplate.column.tooltipText = `{categoryX}: {valueY.formatNumber('#,###')} tickets`;
      break;
    case Units.FinancialOperation:
      columnTemplate.column.tooltipText = `{categoryX}: {valueY.formatNumber('#,###')} financial operations`;
      break;
    case Units.Amount:
      columnTemplate.column.tooltipText = `{categoryX}: {valueY.formatNumber('#,###.00')}  ${CURRENCIES[args.currency].symbol}`;
      break;
    default:
      columnTemplate.column.tooltipText = `{categoryX}: {valueY.formatNumber('#,###')}`;
      break;
  }
  columnTemplate.column.tooltipY = 0;
  columnTemplate.column.cornerRadiusTopLeft = 20;
  columnTemplate.column.cornerRadiusTopRight = 20;
  columnTemplate.strokeOpacity = 0;

  // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
  columnTemplate.adapter.add('fill', (fill, target) => chart.colors.getIndex(target.dataItem.index * 3));

  // create pie chart as a column child
  const pieChart = series.columns.template.createChild(am4charts.PieChart);
  pieChart.width = am4core.percent(80);
  pieChart.height = am4core.percent(80);
  pieChart.align = 'center';
  pieChart.valign = 'middle';
  pieChart.dataFields.data = 'pie';

  const pieSeries = pieChart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = 'value';
  pieSeries.dataFields.category = 'title';
  pieSeries.labels.template.disabled = true;
  pieSeries.ticks.template.disabled = true;
  pieSeries.slices.template.strokeWidth = 1;

  switch (indicator.unit) {
    case Units.Percent:
      pieSeries.slices.template.tooltipText = `{category}: {value.percent.formatNumber('#,###.00')}% ({value.value.formatNumber('#.##')}%)`;
      break;
    case Units.Transaction:
      pieSeries.slices.template.tooltipText = `{category}: {value.percent.formatNumber('#,###.00')}% ({value.value.formatNumber('#,###')} transactions)`;
      break;
    case Units.Order:
      pieSeries.slices.template.tooltipText = `{category}: {value.percent.formatNumber('#,###.00')}% ({value.value.formatNumber('#,###')} orders)`;
      break;
    case Units.Checkout:
      pieSeries.slices.template.tooltipText = `{category}: {value.percent.formatNumber('#,###.00')}% ({value.value.formatNumber('#,###')} checkouts)`;
      break;
    case Units.Operation:
      pieSeries.slices.template.tooltipText = `{category}: {value.percent.formatNumber('#,###.00')}% ({value.value.formatNumber('#,###')} operations)`;
      break;
    case Units.Ticket:
      pieSeries.slices.template.tooltipText = `{category}: {value.percent.formatNumber('#,###.00')}% ({value.value.formatNumber('#,###')} tickets)`;
      break;
    case Units.FinancialOperation:
      pieSeries.slices.template.tooltipText = `{category}: {value.percent.formatNumber('#,###.00')}% ({value.value.formatNumber('#,###')} financial operations)`;
      break;
    case Units.Amount:
      pieSeries.slices.template.tooltipText = `{category}: {value.percent.formatNumber('#,###.00')}% ({value.value.formatNumber('#,###.00')} ${CURRENCIES[args.currency].symbol})`;
      break;
    default:
      pieSeries.slices.template.tooltipText = `{category}: {value.percent.formatNumber('#,###.00')}% ({value.value.formatNumber('#,###')})`;
      break;
  }

  pieSeries.slices.template.adapter.add('stroke', (stroke, target) =>
    chart.colors.getIndex(target.parent.parent.dataItem.index * 3),
  );

  pieSeries.slices.template.adapter.add('fill', () => am4core.color('#ffffff'));

  pieSeries.slices.template.adapter.add('fillOpacity', (fillOpacity, target) => (target.dataItem.index + 1) * 0.2);

  pieSeries.hiddenState.properties.startAngle = -90;
  pieSeries.hiddenState.properties.endAngle = 270;

  // this moves the pie out of the column if column is too small
  pieChart.adapter.add('verticalCenter', (verticalCenter, target) => {
    const point = am4core.utils.spritePointToSprite({ x: 0, y: 0 }, target.seriesContainer, chart.plotContainer);
    point.y -= target.dy;

    if (point.y > chart.plotContainer.measuredHeight - 20) {
      // eslint-disable-next-line no-param-reassign
      target.dy = -target.seriesContainer.measuredHeight - 15;
    } else {
      // eslint-disable-next-line no-param-reassign
      target.dy = 0;
    }
    return verticalCenter;
  });

  return chart;
};

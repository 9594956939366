import { gql } from '@apollo/client';

const query = `{
  _id
  last_transaction {
    _id
    authentication_indicator
    status
    account_number
    account_holder_first_name
    account_holder_last_name
    token {
      norbr
      holder
      customer_scheme_name
      real_scheme
      secondary_scheme
      type1
      type2
    }
    payment_method_name
    authentication_result
    authorization_code
    card_issuer
    card_country
    account_iban
    account_bic
    sca_exemption_reason
    risk_assessment_device_fingerprint
    challenge_preference
  }
}`;

export const orderPaymentDetailsQuery = gql`
  query OrderPaymentDetails($orderId: ID!) {
      order(id: $orderId) ${query}
  }
`;

export const activityPaymentDetailsQuery = gql`
  query ActivityPaymentDetails($orderId: ID!) {
      activity(id: $orderId) ${query}
  }
`;

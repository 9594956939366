import { CheckCircleOutlined, ExclamationCircleOutlined, LockOutlined } from '@ant-design/icons';

const USER_STATUSES = {
  created: {
    value: 'created',
    label: 'Created',
    color: 'gold',
    icon: ExclamationCircleOutlined,
  },
  declined: {
    value: 'declined',
    label: 'Declined',
    color: 'red',
    icon: ExclamationCircleOutlined,
  },
  awaiting_validation: {
    value: 'awaiting_validation',
    label: 'Awaiting validation',
    color: 'gold',
    icon: ExclamationCircleOutlined,
  },
  email_confirmed: {
    value: 'email_confirmed',
    label: 'Email confirmed',
    color: 'gold',
    icon: ExclamationCircleOutlined,
  },
  active: {
    value: 'active',
    label: 'Active',
    color: 'green',
    icon: CheckCircleOutlined,
  },
  disabled: {
    value: 'disabled',
    label: 'Locked',
    color: 'red',
    icon: LockOutlined,
  },
};

const defaultValue = {
  value: null,
  label: 'Undefined',
  color: 'grey',
  icon: ExclamationCircleOutlined,
};

export const userStatusList = Object.values(USER_STATUSES);
export const getUserStatus = (value) => (value && USER_STATUSES[value] ? USER_STATUSES[value] : defaultValue);

export default USER_STATUSES;

import React, { useState } from 'react';
import { Button, List, Tag, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useQuery } from '@apollo/client';
import { DeleteOutlined, EditOutlined, EyeOutlined, HomeOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { search } from '../../../../../util/string';
import { Link } from '../../../../../util/navigate';
import { useProgramManager } from '../../../../../contexts/programManager.context';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { ListFilterHOC } from '../../../Common/ListFilter';
import ProgramManagerBadge from '../../../Common/ProgramManager/Badge';
import AccessControlCreate from '../AccessControlCreate/AccessControlCreate';
import { ProgramManagerListQuery } from '../../Company/query';
import { ProfileListQuery, useProfileDeleteMutation } from '../query';
import { useCan } from '../../../../../contexts/ability.context';

const breadcrumbRender = () => (
  <Breadcrumb
    map={{
      '/': <HomeOutlined />,
      '/access-control-list': 'Access Control',
    }}
  />
);

const AccessControlList = () => {
  const programManager = useProgramManager();
  const can = useCan();

  const { data, loading } = useQuery(ProfileListQuery);
  const programManagersQuery = useQuery(ProgramManagerListQuery, { skip: programManager });

  const [deleteProfile] = useProfileDeleteMutation();

  const [visible, setVisible] = useState(false);

  const showCreationModal = () => {
    setVisible(true);
  };

  const hideCreationModal = () => {
    setVisible(false);
  };

  const handleDelete =
    ({ id, name }) =>
    () => {
      deleteProfile({ id, name });
    };

  const listFilterOptions = {
    searchInput: {
      placeholder: 'Profile name or ID',
      filter: (value, item) => search(value, item.name) || search(value, item.id),
    },
    fields: [
      {
        name: 'name',
        label: 'Name',
        fieldType: 'input',
        fieldProps: { placeholder: 'Profile name' },
        filter: (value, item) => search(value, item.name),
      },
      {
        name: 'roles',
        label: 'Roles',
        fieldType: 'select',
        fieldProps: {
          mode: 'multiple',
          showSearch: true,
          optionFilterProp: 'label',
          placeholder: 'Select roles',
          options: data?.merchantAclRoles?.map((p) => ({
            value: p.id,
            label: p.name,
          })),
          loading,
        },
        filter: (value, item) => value.length === 0 || value.every((v) => item.roles.find((r) => r.id === v)),
        hidden: programManager,
      },
      {
        name: 'programManager',
        label: 'Program',
        fieldType: 'select',
        fieldProps: {
          mode: 'multiple',
          showSearch: true,
          optionFilterProp: 'label',
          placeholder: 'Select programs',
          options: programManagersQuery.data?.programManagers?.map((p) => ({
            value: p.id,
            label: p.name,
            img: p.theme.icon,
          })),
          loading: programManagersQuery.loading,
        },
        filter: (value, item) => value.length === 0 || value.includes(item.programManager?.id),
        hidden: programManager,
      },
    ],
  };

  return (
    <PageHeader
      title="Access Control List"
      breadcrumbRender={breadcrumbRender}
      extra={[
        can('create', 'access-control') && (
          <Button key="create" icon={<PlusOutlined />} onClick={showCreationModal}>
            Create profile
          </Button>
        ),
        <Link key="overview" to="/access-control-list/overview">
          <Button icon={<EyeOutlined />}>Overview</Button>
        </Link>,
      ]}
    >
      <Typography.Paragraph>Profiles, roles and privileges defining users rights.</Typography.Paragraph>
      <ListFilterHOC initialList={data?.merchantAclProfiles} options={listFilterOptions}>
        {(filteredList) => (
          <List
            key="profile-list"
            itemLayout="horizontal"
            dataSource={filteredList}
            loading={loading}
            bordered
            renderItem={(item) => (
              <ProgramManagerBadge programManager={item.programManager}>
                <List.Item
                  actions={[
                    <Tag key="useCount" icon={<UserOutlined />} title={`Used by ${item.useCount} users`}>
                      {item.useCount}
                    </Tag>,
                    ...(can('update', 'access-control')
                      ? [
                          <Link key="edit" to={`/access-control-list/${item.id}`}>
                            <Button type="text" shape="circle" icon={<EditOutlined />} title="Edit profile" />
                          </Link>,
                        ]
                      : []),
                    ...(can('delete', 'access-control')
                      ? [
                          <Button
                            key="delete"
                            type="text"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            title={item.useCount > 0 ? 'Cannot delete used profile' : 'Delete profile'}
                            disabled={item.useCount > 0}
                            onClick={handleDelete(item)}
                          />,
                        ]
                      : []),
                  ]}
                >
                  <List.Item.Meta
                    title={<Link to={`/access-control-list/${item.id}`}>{item.name}</Link>}
                    description={item.description || item.name}
                  />
                </List.Item>
              </ProgramManagerBadge>
            )}
          />
        )}
      </ListFilterHOC>
      <AccessControlCreate visible={visible} onCancel={hideCreationModal} programManager={programManager?.id} />
    </PageHeader>
  );
};

export default AccessControlList;

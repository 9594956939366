import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Checkbox, Drawer, Form, Input, message, Select, Switch } from 'antd';
import { PartnerCompaniesQuery } from '../../query';
import { PartnerCreateMutation } from './query';
import { compareByProp } from '../../../../../util/array';
import { search } from '../../../../../util/string';
import { partnerTypes } from '../constants';
import { useResetFormOnCloseModal } from '../../../Common/Form/utils';
import useNavigateWithSearch from '../../../../../util/navigate';
import SOURCES, { SOURCE_LIST } from '../../../../../constants/SOURCES';

const PartnerCreate = ({ type, onCancel }) => {
  const navigate = useNavigateWithSearch();

  const [form] = Form.useForm();

  const companiesQuery = useQuery(PartnerCompaniesQuery);

  const [create, { loading }] = useMutation(PartnerCreateMutation, {
    refetchQueries: ['PartnerListQuery'],
    onCompleted: ({ createPartner }) => {
      message.success('Partner successfully created');
      navigate(`/partners/${createPartner.id}`);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error(error.message);
    },
  });

  useResetFormOnCloseModal({
    form,
    visible: !!type,
  });

  const submit = (values) => {
    const { source, payfac, ...input } = values;
    create({
      variables: {
        input: {
          type,
          allow_stack: source.includes(SOURCES.STACK),
          allow_insights: source.includes(SOURCES.INSIGHTS),
          allow_insights_pos: source.includes(SOURCES.INSIGHTS_POS),
          allow_payfac: payfac,
          ...input,
        },
      },
    });
  };

  const [selectedCompany, selectCompany] = useState();

  return (
    <Drawer
      title={`Create ${partnerTypes[type]?.label} Partner`}
      onClose={onCancel}
      open={!!type}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
      destroyOnClose
    >
      <Form form={form} layout="vertical" name="partnerForm" onFinish={submit}>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="api_name"
          label="API name"
          rules={[{ required: true }]}
          tooltip="String used as identifier by payment."
        >
          <Input />
        </Form.Item>
        <Form.Item name="existingCompany" label="Select an existing company">
          <Select
            loading={companiesQuery.loading}
            options={companiesQuery.data?.partnerCompanies
              .map((c) => ({
                value: c.id,
                label: c.name,
              }))
              .sort(compareByProp('label', 'asc', 'string'))}
            showSearch
            filterOption={(inputValue, option) => search(inputValue, option.label)}
            allowClear
            onChange={selectCompany}
          />
        </Form.Item>
        <Form.Item name="newCompany" label="Or create new company">
          <Input disabled={!!selectedCompany} />
        </Form.Item>
        <Form.Item label="Source" name="source" tooltip="Defines the sources allowed by partner">
          <Checkbox.Group options={SOURCE_LIST} />
        </Form.Item>
        <Form.Item label="Allow Payment Facilitator" name="payfac" valuePropName="checked" initialValue={false}>
          <Switch>Allow Payment Facilitator</Switch>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default PartnerCreate;

import { gql } from '@apollo/client';

export const addCertificateMutation = gql`
  mutation AddCertificate($id: ID!, $type: CertificateType) {
    addCertificate(id: $id, type: $type) {
      id
      certificates {
        id
        status
        deleted
        name
        csr
        pem
      }
    }
  }
`;

export const merchantAccountCertificatesQuery = gql`
  query MerchantAccountCertificates($merchantAccount: ID!) {
    merchantAccount(id: $merchantAccount) {
      id
      certificates {
        id
        status
        deleted
        name
        csr
        pem
      }
    }   
  }
`;
import { gql } from '@apollo/client';

export const notificationQuery = gql`
  query Notification($notificationId: ID!) {
    notification(id: $notificationId) {
      _id
      status
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
      company {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
    }
  }
`;

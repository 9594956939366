import { gql } from '@apollo/client';

const query = `{
  _id
  last_transaction {
    _id
    order_id
    order_merchant_id
    psp_transaction_id
    order_transaction_number
    merchant_contract {
      id
      name
    }
  }
  transaction_count
  merchant_account {
    id
    name
  }
  program_manager {
    id
    name
    displayName
  }
}`;

export const orderReferenceQuery = gql`
  query OrderReference($orderId: ID!) {
      order(id: $orderId) ${query}
  }
`;

export const activityReferenceQuery = gql`
  query ActivityReference($orderId: ID!) {
      activity(id: $orderId) ${query}
  }
`;

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import OrderList from './OrderList/OrderList';
import OrderDetail from './OrderDetail/OrderDetail';
import ErrorBoundary from '../Common/ErrorBoundary/ErrorBoundary';
import RestrictedPage from '../RestrictedPage';
import { useMe } from '../../../contexts/me.context';
import { useCan } from '../../../contexts/ability.context';
import Page from '../Common/Page/Page';
import styles from './Brainpower.module.scss';
import LordIcon from '../../../assets/icons/LordIcon';
import { isModeMerchant } from '../../../constants/mode';
import ExportList from './ExportList/ExportList';
import ExportDetail from './ExportDetail/ExportDetail';
import FinancialOperationList from './FinancialOperationList/FinancialOperationList';
import NotificationList from './NotificationList/NotificationList';
import NotificationDetail from './NotificationDetail/NotificationDetail';
import Matcher from './Matcher/Matcher';
import Overview from './Overview/Overview';
import CheckoutList from './CheckoutList/CheckoutList';
import TransactionList from './TransactionList/TransactionList';
import MatcherCashierDetails from './Matcher/MatcherCashier/MatcherCashierDetails/MatcherCashierDetails';
import MatcherCashierSummary from './Matcher/MatcherCashier/MatcherCashierSummary/MatcherCashierSummary';
import MatcherPaymentPartner from './Matcher/MatcherPaymentPartner/MatcherPaymentPartner';

const Brainpower = () => {
  const me = useMe();
  const can = useCan();

  if (
    isModeMerchant &&
    (me.companies[0].company.merchantAccountCount === 0 ||
      (!can('access', 'all-accounts') && me.companies[0].authorizedMerchantAccounts.length === 0))
  ) {
    return (
      <Page>
        <div className={styles.noAccess}>
          <LordIcon type="roadblock-lineal-edited" width={136} height={136} isStopped={false} autoplay />
          <div>You don&apos;t have access to any account.</div>
          <div>Please contact your administrator.</div>
        </div>
      </Page>
    );
  }

  return (
    <ErrorBoundary>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route
          path="checkouts"
          element={
            <RestrictedPage action="read" subject="checkout">
              <CheckoutList />
            </RestrictedPage>
          }
        />
        <Route
          path="orders"
          element={
            <RestrictedPage action="read" subject="order">
              <OrderList />
            </RestrictedPage>
          }
        />
        <Route
          path="orders/:orderId"
          element={
            <RestrictedPage action="read" subject="order">
              <OrderDetail />
            </RestrictedPage>
          }
        />
        <Route
          path="transactions"
          element={
            <RestrictedPage action="read" subject="order">
              <TransactionList />
            </RestrictedPage>
          }
        />
        <Route
          path="overview"
          element={
            <RestrictedPage action="read" subject="overview">
              <Overview />
            </RestrictedPage>
          }
        />
        <Route
          path="matcher-engine/*"
          element={
            <RestrictedPage action="read" subject="matcher">
              <Matcher />
            </RestrictedPage>
          }
        />
        <Route
          path={"matcher-cashier-summary/*"}
          element={
            <RestrictedPage action="read" subject="matcher">
              <MatcherCashierSummary />
            </RestrictedPage>
          }
        />
        <Route
          path={"matcher-cashier-details/*"}
          element={
            <RestrictedPage action="read" subject="matcher">
              <MatcherCashierDetails />
            </RestrictedPage>
          }
        />
        <Route
          path={"matcher-payment-partner/*"}
          element={
            <RestrictedPage action="read" subject="matcher">
              <MatcherPaymentPartner />
            </RestrictedPage>
          }
        />
        <Route
          path="financial-operations"
          element={
            <RestrictedPage action="read" subject="financial-operation">
              <FinancialOperationList />
            </RestrictedPage>
          }
        />
        <Route
          path="notifications"
          element={
            <RestrictedPage action="read" subject="notification">
              <NotificationList />
            </RestrictedPage>
          }
        />
        <Route
          path="notifications/:notificationId"
          element={
            <RestrictedPage action="read" subject="notification">
              <NotificationDetail />
            </RestrictedPage>
          }
        />
        <Route
          path="exports"
          element={
            <RestrictedPage action="read" subject="report">
              <ExportList />
            </RestrictedPage>
          }
        />
        <Route
          path="exports/:exportId"
          element={
            <RestrictedPage action="read" subject="report">
              <ExportDetail />
            </RestrictedPage>
          }
        />
        <Route path="*" element={<Navigate to="dashboard" />} />
      </Routes>
    </ErrorBoundary>
  );
};
export default Brainpower;

import moment from 'moment-timezone';

export const periodTypeToCustom = (period) => {
  let from;
  let to;
  let previousFrom;
  let previousTo;
  switch (period) {
    case 'last_hour':
      from = moment().subtract(1, 'hour').startOf('minute');
      to = moment().endOf('minute');
      previousFrom = moment().subtract(2, 'hour').startOf('minute');
      previousTo = moment().subtract(1, 'hour').endOf('minute');
      break;
    case 'last_4_hours':
      from = moment().subtract(4, 'hours').startOf('minute');
      to = moment().endOf('minute');
      previousFrom = moment().subtract(8, 'hours').startOf('minute');
      previousTo = moment().subtract(4, 'hours').endOf('minute');
      break;
    case 'last_12_hours':
      from = moment().subtract(12, 'hours').startOf('minute');
      to = moment().endOf('minute');
      previousFrom = moment().subtract(24, 'hours').startOf('minute');
      previousTo = moment().subtract(12, 'hours').endOf('minute');
      break;
    case 'last_24_hours':
      from = moment().subtract(24, 'hours').startOf('minute');
      to = moment().endOf('minute');
      previousFrom = moment().subtract(48, 'hours').startOf('minute');
      previousTo = moment().subtract(24, 'hours').endOf('minute');
      break;
    case 'today':
      from = moment().startOf('day');
      to = moment().endOf('day');
      previousFrom = from.clone().subtract(1, 'day');
      previousTo = to.clone().subtract(1, 'day');
      break;
    case 'yesterday':
      from = moment().subtract(1, 'day').startOf('day');
      to = moment().subtract(1, 'day').endOf('day');
      previousFrom = moment().subtract(2, 'day').startOf('day');
      previousTo = moment().subtract(2, 'day').endOf('day');
      break;
    case 'current_week':
      from = moment().startOf('week');
      to = moment().endOf('week');
      previousFrom = from.clone().subtract(1, 'week');
      previousTo = to.clone().subtract(1, 'week');
      break;
    case 'previous_week':
      from = moment().subtract(1, 'week').startOf('week');
      to = moment().subtract(1, 'week').endOf('week');
      previousFrom = moment().subtract(2, 'week').startOf('week');
      previousTo = moment().subtract(2, 'week').endOf('week');
      break;
    case 'current_month':
      from = moment().startOf('month');
      to = moment().endOf('month');
      previousFrom = from.clone().subtract(1, 'month');
      previousTo = to.clone().subtract(1, 'month');
      break;
    case 'previous_month':
      from = moment().subtract(1, 'month').startOf('month');
      to = moment().subtract(1, 'month').endOf('month');
      previousFrom = moment().subtract(2, 'month').startOf('month');
      previousTo = moment().subtract(2, 'month').endOf('month');
      break;
    case 'current_quarter':
      from = moment().startOf('quarter');
      to = moment().endOf('quarter');
      previousFrom = from.clone().subtract(1, 'quarter');
      previousTo = to.clone().subtract(1, 'quarter');
      break;
    case 'previous_quarter':
      from = moment().subtract(1, 'quarter').startOf('quarter');
      to = moment().subtract(1, 'quarter').endOf('quarter');
      previousFrom = moment().subtract(2, 'quarter').startOf('quarter');
      previousTo = moment().subtract(2, 'quarter').endOf('quarter');
      break;
    default:
      return {
        from: moment('2020-01-01'),
        to: moment().endOf('day'),
        label: 'all',
      };
  }
  return {
    from,
    to,
    previous_from: previousFrom,
    previous_to: previousTo,
    label: period,
  };
};

export const getPreviousDateRange = (from, to) => ({
  previous_from: from.clone().subtract(to.diff(from), 'ms').startOf('minute'),
  previous_to: from.clone().subtract(1, 'ms').endOf('minute'),
});

export default {};

import React from 'react';
import { ApolloClient, ApolloLink, ApolloProvider as Apollo, InMemoryCache } from '@apollo/client';
// To use for subscriptions
// import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
// import { getMainDefinition } from '@apollo/client/utilities';
// import { createClient } from 'graphql-ws';
import { onError } from '@apollo/client/link/error';
import { message } from 'antd';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import DebounceLink from 'apollo-link-debounce';
import {useCompany, useLogout, useMetaProgramManagerId, useProgramManagerId, useToken} from './app.context';

const ApolloProvider = ({ children }) => {
  const logout = useLogout();
  const [token] = useToken();
  const [programManagerId] = useProgramManagerId();
  const [metaProgramManagerId] = useMetaProgramManagerId();
  const [company] = useCompany();

  const uploadLink = createUploadLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
    headers: { 'apollo-require-preflight': true },
  });

  const errorLink = onError(({ networkError }) => {
    if (networkError?.statusCode === 401) {
      logout();
    } else if (networkError?.statusCode === 400) {
      message.error('Bad request');
    }
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      ...(programManagerId && { programManager: programManagerId }),
      ...(metaProgramManagerId && { metaProgramManager: metaProgramManagerId }),
      ...(company && { company }),
      ...headers,
    },
  }));

  const debounceLink = new DebounceLink(10000);

  // Subscription link
  // const wsLink = new GraphQLWsLink(createClient({
  //   url: process.env.REACT_APP_WS_URL,
  // }));

  // const splitLink = split(
  //   ({ query }) => {
  //     const definition = getMainDefinition(query);
  //     return (
  //       definition.kind === 'OperationDefinition' &&
  //       definition.operation === 'subscription'
  //     );
  //   },
  //   wsLink,
  //   ApolloLink.from([debounceLink, errorLink, authLink, uploadLink]),
  // );

  const client = new ApolloClient({
    link: ApolloLink.from([debounceLink, errorLink, authLink, uploadLink]),
    cache: new InMemoryCache({
      possibleTypes: {
        User: ['MerchantUser', 'ProgramManager', 'InternalUser'],
      },
      typePolicies: {
        Dashboard: {
          fields: {
            layout: {
              merge(existing, incoming) {
                return incoming;
              },
            },
          },
        },
        MerchantAccount: {
          fields: {
            merchant_contracts: {
              merge(existing, incoming) {
                return incoming;
              },
            },
            partners: {
              merge(existing, incoming) {
                return incoming;
              },
            },
          },
        },
        MerchantCompany: {
          fields: {
            theme: {
              merge: (_, incoming) => incoming,
            },
          }
        },
        MerchantContract: {
          fields: {
            merge(existing, incoming) {
              return {
                ...existing,
                ...incoming,
              };
            },
            merchant_routes: {
              merge(existing, incoming) {
                return incoming;
              },
            },
            payment_methods: {
              merge(existing, incoming) {
                return incoming;
              },
            },
          },
        },
        Splitter: {
          fields: {
            branches: {
              merge(existing, incoming) {
                return incoming;
              },
            },
          },
        },
        User: {
          fields: {
            brainpower: {
              merge(existing, incoming) {
                return {
                  ...existing,
                  ...incoming,
                };
              },
            },
          },
        },
        Query: {
          fields: {
            profiles: {
              merge: false,
            },
            profile(_, { args, toReference }) {
              return toReference({
                __typename: 'AclProfile',
                id: args.id,
              });
            },
          },
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
    },
  });

  return <Apollo client={client}>{children}</Apollo>;
};

export default ApolloProvider;

import React from 'react';
import { Typography } from 'antd';
import styles from './widget.module.scss';

const WidgetError = () => (
  <div className={styles.root}>
    <div className={styles.error}>
      <Typography.Text type="danger">An error occurred, please contact support.</Typography.Text>
    </div>
  </div>
);

export default WidgetError;

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import { config as dimensionsConfig } from '../../constants/dimensions';
import { config as indicatorsConfig } from '../../constants/indicators';
import { compareByProp } from '../../../../../../util/array';
import Units from '../../constants/units';

export default (divId, widget, data, args) => {
  const { dimensions, indicators, options } = widget;

  const dimension = dimensionsConfig[dimensions[0]];

  // Create chart
  const chart = am4core.create(divId, am4charts.PieChart);

  chart.innerRadius = am4core.percent(40);
  chart.numberFormatter.numberFormat = '#.';
  chart.hiddenState.properties.opacity = 0;

  // Inject data
  chart.data = [...data.data]
    .sort(compareByProp(indicators[0], 'desc'))
    .slice(0, options?.slice_items || 8)
    // use dimension config
    .map((row) => ({
      ...row,
      [dimension.id]: dimension.values?.[row[dimension.id]]?.label ?? row[dimension.id],
      color: dimension.values?.[row[dimension.id]]?.color,
    }));

  const series = chart.series.push(new am4charts.PieSeries());
  series.dataFields = {
    value: indicators[0],
    radiusValue: indicators[0],
    category: dimensions[0],
  };

  series.labels.template.disabled = true;
  // series.labels.template.text = "{value.percent.formatNumber('#.')}%";
  series.slices.template.cornerRadius = 6;
  series.slices.template.propertyFields.stroke = 'color';
  series.slices.template.propertyFields.fill = 'color';
  // series.colors.step = 5;
  series.hiddenState.properties.endAngle = -90;

  series.tooltip.background.cornerRadius = 0;
  series.tooltip.background.strokeOpacity = 0;
  switch (indicatorsConfig[indicators[0]].unit) {
    case Units.Amount:
      series.slices.template.tooltipText =
        '[#fff font-size: 16px text-transform: capitalize word-break: keep-all]{category}[/]\n' +
        '[font-size: 12px]' +
        "{value.percent.formatNumber('#.##')}%\n" +
        `{value.value.formatNumber('#,###.00')} ${CURRENCIES[args.currency].symbol}\n` +
        '{additional}[/]';
      break;
    case Units.Percent:
      series.slices.template.tooltipText =
        '[#fff font-size: 16px text-transform: capitalize word-break: keep-all]{category}[/]\n' +
        '[font-size: 12px]' +
        `{value.value.formatNumber('#.')} %\n` +
        '{additional}[/]';
      break;
    default:
      series.slices.template.tooltipText =
        '[#fff font-size: 16px text-transform: capitalize word-break: keep-all]{category}[/]\n' +
        '[font-size: 12px]' +
        "{value.percent.formatNumber('#.##')}%\n" +
        `{value.value.formatNumber('#.')} ${
          [
            args.currency,
            'orders',
            '%',
            'transactions',
            'customers',
            'checkouts',
            'operations',
            'tickets',
            'financial operations',
          ][indicatorsConfig[indicators[0]].unit]
        }\n` +
        '{additional}[/]';
      break;
  }

  // Add legend
  chart.legend = new am4charts.Legend();
  const markerTemplate = chart.legend.markers.template;
  markerTemplate.width = 15;
  markerTemplate.height = 15;
  return chart;
};

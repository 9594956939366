import React from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { message, Modal } from 'antd';
import useNavigateWithSearch from "../../../../util/navigate";

export const InternalProfileListQuery = gql`
  query InternalProfileList {
    internalAclProfiles {
      __typename
      id
      name
      description
      useCount
    }
  }
`;

export const InternalProfileOverviewQuery = gql`
  query InternalProfileOverview {
    internalAclProfiles {
      __typename
      id
      name
      roles {
        id
      }
    }
    internalAclRoles {
      __typename
      id
      name
    }
  }
`;

export const InternalProfileViewQuery = gql`
  query InternalProfileViewQuery($profileId: ID!) {
    internalAclProfile(id: $profileId) {
      __typename
      id
      name
      description
      roles {
        id
      }
      useCount
    }
    internalAclRoles {
      __typename
      id
      name
      privileges {
        id
      }
      useBy {
        type
        id
        name
      }
    }
  }
`;

export const InternalProfileCreateMutation = gql`
  mutation InternalProfileCreateMutation($createProfileInput: AclProfileInput!, $baseProfile: ID) {
    createInternalAclProfile(input: $createProfileInput, baseProfile: $baseProfile) {
      __typename
      id
      name
      roles {
        id
      }
    }
  }
`;

export const InternalProfileUpdateMutation = gql`
  mutation InternalProfileUpdateMutation($id: ID!, $updateProfileInput: AclProfileInput!) {
    updateInternalAclProfile(id: $id, input: $updateProfileInput) {
      __typename
      id
      name
      description
      roles {
        id
      }
    }
  }
`;

export const InternalProfileDeleteMutation = gql`
  mutation InternalProfileDeleteMutation($id: ID!) {
    deleteInternalAclProfile(id: $id) {
      __typename
      id
    }
  }
`;

/**
 * Hook to Profile Delete mutation
 * - add modal confirmation before submit
 * - add information messages (success, error)
 * @param {*} options
 * @returns
 */
export const useInternalProfileDeleteMutation = (options = {}) => {
  const navigate = useNavigateWithSearch();
  const [deleteProfile, { data, loading, error }] = useMutation(InternalProfileDeleteMutation, {
    onCompleted: () => {
      if (options.onCompleted) options.onCompleted();
    },
    onError: (err) => {
      if (options.onError) options.onError(err);
      message.error(err.message);
    },
    // update ProfileList without refetch
    update(cache, { data: { deleteInternalAclProfile } }) {
      const normalizedId = cache.identify(deleteInternalAclProfile);
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const onDelete = ({ id, name }) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: (
        <span>
          Are you sure you want to delete <strong>{name}</strong>?
        </span>
      ),
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: () => {
        deleteProfile({
          variables: { id },
          optimisticResponse: { deleteInternalAclProfile: { id, __typename: 'InternalAclProfile' } },
        });
        navigate('/internal-access-control-list');
      },
    });
  };

  return [onDelete, { data, loading, error }];
};

export default {};

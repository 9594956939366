import { gql } from '@apollo/client';

const query = `{
  _id
  last_transaction {
    _id
    authentication_result
    card_issuer
    threeds_data
  }
}`;

export const orderAuthenticationQuery = gql`
  query OrderAuthentication($orderId: ID!) {
    order(id: $orderId) ${query}
  }
`;

export const activityAuthenticationQuery = gql`
  query ActivityAuthentication($orderId: ID!) {
    activity(id: $orderId) ${query}
  }
`;

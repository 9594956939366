import { gql } from '@apollo/client';

export const messageListQuery = gql`
  query Messages {
    messages {
      id
      read
      type
      content {
        __typename
        ... on MessageDashboardSharedContent {
          dashboard {
            id
            name
          }
          createdBy {
            full_name
            id
            email
            avatar
          }
        }
        ... on MessageReportCompletedContent {
          export {
            id
            name
            sendingMethod
            status
          }
          report {
            id
          }
        }
        ... on MessageAnnouncementContent {
          type
          title
          content
          body
        }
      }
      createdAt
      updatedAt
      userId
    }
  }
`;

export const meDashboardQuery = gql`
  query MeDashboards {
    me {
      brainpower {
        dashboard {
          favorite
          dashboards {
            id
            name
            layout
            widgets
            createdBy {
              id
              full_name
              avatar
            }
          }
        }
      }
    }
  }
`;

export const ackMessagesMutation = gql`
  mutation AckMessages($messageIds: [ID!]!) {
    ackMessages(messageIds: $messageIds)
  }
`;

import React from 'react';
import { Link as Lk, Navigate, useNavigate, useSearchParams } from 'react-router-dom';

const mergePathSearch = (path, searchParams) => {
  const [targetPath, targetSearch = ''] = path.split('?');
  const targetSearchParams = new URLSearchParams(targetSearch);
  const newSearchParams = new URLSearchParams({
    ...Object.fromEntries(searchParams),
    ...Object.fromEntries(targetSearchParams),
  });
  return `${targetPath}?${newSearchParams.toString()}`;
};

const useNavigateWithSearch = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  return (path, newSearchParams = searchParams, options = {}) => {
    navigate(mergePathSearch(path, newSearchParams), options);
  };
};

export const NavigateWithSearch = ({ to, ...props }) => {
  const [searchParams] = useSearchParams();
  return <Navigate {...props} to={mergePathSearch(to, searchParams)} />;
};

export const Link = ({ to, ...props }) => {
  const [searchParams] = useSearchParams();
  return <Lk {...props} to={mergePathSearch(to, searchParams)} />;
};

export default useNavigateWithSearch;

const ReportFormats = {
  CSV: 'csv',
};

export const reportFormats = {
  [ReportFormats.CSV]: { value: ReportFormats.CSV, label: 'CSV', extension: 'csv' },
};

export const reportFormatList = Object.values(reportFormats);

export default ReportFormats;

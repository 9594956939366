import React, { useMemo } from 'react';
import { Col, Row, Skeleton, Statistic } from 'antd';
import { useQuery as useApolloQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { brainpowerQuery } from '../Common/query';
import useSearchFilters from '../../../hooks/useSearchFilters';
import { DATETIME2_FORMAT } from '../../../../../../constants/DATES';
import { getPreviousDateRange, periodTypeToCustom } from '../../../../../../util/date';
import useBaseCurrency from '../../../hooks/useBaseCurrency';
import LordIcon from '../../../../../../assets/icons/LordIcon';
import useHover from '../../../../../../hooks/useHover';
import { amountFormatter } from '../../../../../../util/formatter';
import { useCan } from '../../../../../../contexts/ability.context';
import styles from '../widget.module.scss';
import WidgetError from '../WidgetError';
import WidgetForbidden from '../WidgetForbidden';

const _ = {
  AUTHORIZE: 'authorize',
  AVERAGE_BASKET: 'average_basket',
  CAPTURE: 'capture',
  CHARGEBACK: 'chargeback',
  REFUND: 'refund',
  SUCCESS_RATE: 'success_rate',
  MATCHED_OPERATIONS: 'matched_operations',
  UNMATCHED_OPERATIONS: 'unmatched_operations',
  PENDING_OPERATIONS: 'pending_operations',
  WITHDRAW_OPERATIONS: 'withdraw_operations',
  MATCHED_NOTIFICATIONS: 'matched_notifications',
  UNMATCHED_NOTIFICATIONS: 'unmatched_notifications',
};

const KpiLayout = ({ label, icon, value, precision = 2, info, variation, unit }) => {
  // animate icon on hover
  const [ref, isHover] = useHover();

  const [variationColor, variationIcon] = useMemo(() => {
    if (variation > 0) return ['#2ECC71', <ArrowUpOutlined />];
    if (variation < 0) return ['#E74C3C', <ArrowDownOutlined />];
    return [undefined, undefined];
  }, [variation]);

  return (
    <Row
      ref={ref}
      align="middle"
      style={{ backgroundColor: 'white', height: '100%', padding: '4px 8px', margin: 0, whiteSpace: 'nowrap' }}
      gutter={12}
    >
      <Col xs={0} sm={0} md={0} lg={8} xl={6}>
        <LordIcon type={icon} width={68} height={68} isStopped={!isHover} loop autoplay />
      </Col>
      <Col xs={24} sm={24} md={24} lg={16} xl={18}>
        <Row style={{ overflow: 'hidden' }}>
          <Statistic title={label} value={value} precision={precision} suffix={unit} />
        </Row>
        <Row justify="space-between" align="middle">
          <Col>
            {info && (
              <Statistic
                value={info.value}
                precision={info.precision ?? 0}
                valueStyle={{ fontSize: '11px' }}
                suffix={info.suffix}
              />
            )}
          </Col>

          {variation != null && (
            <Col>
              <Statistic
                value={variation}
                precision={0}
                valueStyle={{ fontSize: '11px', color: variationColor }}
                prefix={variationIcon}
                suffix="%"
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

const KpiLoading = () => (
  <div className={styles.error}>
    <Skeleton avatar={{ size: 'large' }} paragraph={{ rows: 1 }} loading />
  </div>
);

const Kpi = (props) => {
  const { widget } = props;

  const can = useCan();
  const [currency] = useBaseCurrency();

  const { dateFilter, list } = useSearchFilters();

  const parameters = {
    widget: widget.options?.kpi,
    filters: list.map((filter) => {
      if (filter.key === dateFilter.key) {
        return {
          dates: filter.value.period
            ? periodTypeToCustom(filter.value.period)
            : {
                from: moment(filter.value.from, DATETIME2_FORMAT).startOf('minute'),
                to: moment(filter.value.to, DATETIME2_FORMAT).endOf('minute'),
                ...getPreviousDateRange(
                  moment(filter.value.from, DATETIME2_FORMAT),
                  moment(filter.value.to, DATETIME2_FORMAT),
                ),
              },
        };
      }
      return {
        name: filter.key,
        value: filter.value,
        excluded: filter.excluded,
      };
    }),
    indicators: [],
    dimensions: [],
    currency,
  };

  const { data, loading, error } = useApolloQuery(brainpowerQuery, {
    variables: {
      service: 'kpi',
      parameters,
    },
  });

  if (loading) return <KpiLoading />;
  if (error) return <WidgetError />;

  switch (widget.options?.kpi) {
    case _.AUTHORIZE:
      return (
        <KpiLayout
          label="Authorize turnover"
          icon="coins-outline-edited"
          value={amountFormatter(
            data?.brainpower?.data?.[0]?.authorized_turnover ?? 0,
            currency,
            widget.options?.amount_format,
          )}
          info={{
            value: data?.brainpower?.data?.[1]?.authorized_orders ?? 0,
            suffix: 'orders',
          }}
          variation={data?.brainpower?.data?.[2]?.authorized_turnover_variation}
        />
      );
    case _.AVERAGE_BASKET:
      return (
        <KpiLayout
          label="Average basket"
          icon="basket-trolley-shopping-card-outline-edited"
          value={amountFormatter(
            data?.brainpower?.data?.[0]?.average_basket ?? 0,
            currency,
            widget.options?.amount_format,
          )}
          variation={data?.brainpower?.data?.[1]?.average_basket_variation}
        />
      );
    case _.CAPTURE:
      return (
        <KpiLayout
          label="Captured turnover"
          icon="money-bag-euro-outline-edited"
          value={amountFormatter(
            data?.brainpower?.data?.[0]?.captured_turnover ?? 0,
            currency,
            widget.options?.amount_format,
          )}
          info={{
            value: data?.brainpower?.data?.[1]?.captured_orders ?? 0,
            suffix: 'orders',
          }}
          variation={data?.brainpower?.data?.[2]?.captured_turnover_variation}
        />
      );
    case _.CHARGEBACK:
      return (
        <KpiLayout
          label="Chargebacks"
          icon="thumb-down-outline-edited"
          value={amountFormatter(
            data?.brainpower?.data?.[0]?.chargeback_amount ?? 0,
            currency,
            widget.options?.amount_format,
          )}
          info={{
            value: data?.brainpower?.data?.[1]?.chargeback_orders ?? 0,
            suffix: 'orders',
          }}
          variation={data?.brainpower?.data?.[2]?.chargeback_amount_variation}
        />
      );
    case _.SUCCESS_RATE:
      return (
        <KpiLayout
          label="Success rate"
          icon="matched-operations"
          value={data?.brainpower?.data?.[0]?.success_rate ?? 0}
          unit="%"
          variation={data?.brainpower?.data?.[1]?.success_rate_variation}
        />
      );
    case _.REFUND:
      return (
        <KpiLayout
          label="Refunds"
          icon="complaint-outline-edited"
          value={amountFormatter(
            data?.brainpower?.data?.[0]?.refunded_amount ?? 0,
            currency,
            widget.options?.amount_format,
          )}
          info={{
            value: data?.brainpower?.data?.[1]?.refunded_orders ?? 0,
            suffix: 'orders',
          }}
          variation={data?.brainpower?.data?.[2]?.refunded_amount_variation}
        />
      );
    case _.MATCHED_OPERATIONS:
      if (!can('read', 'matcher')) return <WidgetForbidden />;
      return (
        <KpiLayout
          label="Matched tickets"
          icon="matched-operations"
          value={amountFormatter(
            data?.brainpower?.data?.[0]?.matched_amount ?? 0,
            currency,
            widget.options?.amount_format,
          )}
          info={{
            value: data?.brainpower?.data?.[1]?.matched_operations ?? 0,
            suffix: 'tickets',
          }}
        />
      );
    case _.UNMATCHED_OPERATIONS:
      if (!can('read', 'matcher')) return <WidgetForbidden />;
      return (
        <KpiLayout
          label="Unmatched tickets"
          icon="unmatched-operations"
          value={amountFormatter(
            data?.brainpower?.data?.[0]?.unmatched_amount ?? 0,
            currency,
            widget.options?.amount_format,
          )}
          info={{
            value: data?.brainpower?.data?.[1]?.unmatched_operations ?? 0,
            suffix: 'tickets',
          }}
        />
      );
    case _.PENDING_OPERATIONS:
      if (!can('read', 'matcher')) return <WidgetForbidden />;
      return (
        <KpiLayout
          label="Pending tickets"
          icon="pending-operations"
          value={amountFormatter(
            data?.brainpower?.data?.[0]?.pending_amount ?? 0,
            currency,
            widget.options?.amount_format,
          )}
          info={{
            value: data?.brainpower?.data?.[1]?.pending_operations ?? 0,
            suffix: 'tickets',
          }}
        />
      );
    case _.WITHDRAW_OPERATIONS:
      if (!(can('read', 'matcher') && can('read', 'matcher-withdraw'))) return <WidgetForbidden />;
      return (
        <KpiLayout
          label="Withdraw tickets"
          icon="withdraw-operations"
          value={amountFormatter(
            data?.brainpower?.data?.[0]?.withdraw_amount ?? 0,
            currency,
            widget.options?.amount_format,
          )}
          info={{
            value: data?.brainpower?.data?.[1]?.withdraw_operations ?? 0,
            suffix: 'tickets',
          }}
        />
      );
    case _.MATCHED_NOTIFICATIONS:
      if (!can('read', 'matcher')) return <WidgetForbidden />;
      return (
        <KpiLayout
          label="Matched notifications"
          icon="matched-operations"
          value={amountFormatter(
            data?.brainpower?.data?.[0]?.matched_notifications_amount ?? 0,
            currency,
            widget.options?.amount_format,
          )}
          info={{
            value: data?.brainpower?.data?.[1]?.matched_notifications ?? 0,
            suffix: 'notifications',
          }}
        />
      );
    case _.UNMATCHED_NOTIFICATIONS:
      if (!can('read', 'matcher')) return <WidgetForbidden />;
      return (
        <KpiLayout
          label="Unmatched notifications"
          icon="unmatched-operations"
          value={amountFormatter(
            data?.brainpower?.data?.[0]?.unmatched_notifications_amount ?? 0,
            currency,
            widget.options?.amount_format,
          )}
          info={{
            value: data?.brainpower?.data?.[1]?.unmatched_notifications ?? 0,
            suffix: 'notifications',
          }}
        />
      );
    default:
      return <WidgetError />;
  }
};

export default Kpi;

import React, { useState } from 'react';
import { Checkbox, Col, Row, Space, Typography } from 'antd';
import Loader from '../../../../../Common/Loader/Loader';

const SelectPaymentMethods = ({ loading, options, checkedList, setCheckedList }) => {
  const [checkAll, setCheckAll] = useState(false);

  const handleChange = (list) => {
    setCheckedList(list);
    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? options.map((pm) => pm.id) : []);
    setCheckAll(e.target.checked);
  };

  if (loading) return <Loader />;

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Text>Select authorized payment methods</Typography.Text>
      {options.length === 0 ? (
        <Typography.Text disabled italic>
          No payment methods available
        </Typography.Text>
      ) : (
        [
          <Checkbox key="check-all" onChange={onCheckAllChange} checked={checkAll}>
            Select all
          </Checkbox>,
          <Checkbox.Group key="check-group" value={checkedList} onChange={handleChange} style={{ width: '100%' }}>
            <Row>
              {options.map((pm) => (
                <Col key={pm.id} sm={12} md={12} lg={8} xl={8} xxl={8}>
                  <Checkbox value={pm.id} style={{ lineHeight: '32px' }}>
                    <Space>
                      <img src={pm.imgUrl} alt="" width={24} />
                      {pm.name}
                    </Space>
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>,
        ]
      )}
    </Space>
  );
};

export default SelectPaymentMethods;

import React, { useState } from 'react';
import moment from 'moment-timezone';
import {useParams} from 'react-router-dom';
import { Button, DatePicker, Form, Input, InputNumber, message, Select, Space, Tag, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import { CompanyUpdateMutation } from '../../query';
import InfoTile, { InfoAmountRow, InfoDateRow, InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import styles from '../CompanyView.module.scss';
import { useCan } from '../../../../../../contexts/ability.context';
import {CompanyActivityQuery} from './query';

const CompanyActivity = () => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);
  const { companyId } = useParams();

  const { data: { merchantCompany } = { merchantCompany: null}, loading} = useQuery(CompanyActivityQuery, {
    variables: {
      companyId
    }
  });

  const [updateCompany, { loading: loadingUpdate }] = useMutation(CompanyUpdateMutation, {
    onCompleted: () => {
      message.success('Company successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    updateCompany({
      variables: { id: companyId, input: values },
    });
  };

  // return edit form tile
  if (editMode) {
    const formCompany = {
      ...merchantCompany,
      dateStart: moment(merchantCompany.dateStart),
    };

    return (
      <InfoTile name="company-activity" title="Activity">
        <Form
          form={form}
          title="Activity"
          layout="vertical"
          initialValues={formCompany}
          onFinish={handleFinish}
          requiredMark={false}
          className="tile-form"
        >
          <Form.Item
            label="Activity"
            name="activity"
            rules={[
              {
                required: merchantCompany.attributeListRequiredByPartners.includes('company.activity'),
                message: 'Activity is required by a partner configuration.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Specialization"
            name="specialization"
            rules={[
              {
                required: merchantCompany.attributeListRequiredByPartners.includes('company.specialization'),
                message: 'Specialization is required by a partner configuration.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="MCC"
            name="mcc"
            // eslint-disable-next-line max-len
            tooltip="A Merchant Category Code (MCC) is a four-digit number listed in ISO 18245 for retail financial services. An MCC is used to classify a business by the types of goods or services it provides."
            rules={[
              {
                required: merchantCompany.attributeListRequiredByPartners.includes('company.mcc'),
                message: 'MCC is required by a partner configuration.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Websites"
            name="website"
            rules={[
              {
                required: merchantCompany.attributeListRequiredByPartners.includes('company.website'),
                message: 'Website is required by a partner configuration.',
              },
            ]}
          >
            <Select mode="tags" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label="Average basket"
            name="averageBasket"
            rules={[
              {
                required: merchantCompany.attributeListRequiredByPartners.includes('company.averageBasket'),
                message: 'Average basket is required by a partner configuration.',
              },
            ]}
          >
            <InputNumber style={{ width: '50%', minWidth: '200px' }} min={0} formatter={(value) => `€ ${value}`} />
          </Form.Item>
          <Form.Item
            label="Turnover"
            name="turnover"
            rules={[
              {
                required: merchantCompany.attributeListRequiredByPartners.includes('company.turnover'),
                message: 'Turnover is required by a partner configuration.',
              },
            ]}
          >
            <InputNumber style={{ width: '50%', minWidth: '200px' }} min={0} formatter={(value) => `€ ${value}`} />
          </Form.Item>
          <Form.Item
            label="Legal Form"
            name="legalForm"
            rules={[
              {
                required: merchantCompany.attributeListRequiredByPartners.includes('company.legalForm'),
                message: 'Legal Form is required by a partner configuration.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Since"
            name="dateStart"
            rules={[
              {
                required: merchantCompany.attributeListRequiredByPartners.includes('company.dateStart'),
                message: 'Start date is required by a partner configuration.',
              },
            ]}
          >
            {/* disable futures dates */}
            <DatePicker disabledDate={(current) => current > Date.now()} />
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loadingUpdate}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  // return info tile
  return (
    <InfoTile
      name="company-activity"
      title="Activity"
      extra={
        can('update', 'company') && !loading && <Button onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
      }
    >
      <InfoTextRow label="Activity" value={merchantCompany?.activity} loading={loading} />
      <InfoTextRow label="Specialization" value={merchantCompany?.specialization} loading={loading} />
      <InfoTextRow label="MCC" value={merchantCompany?.mcc} loading={loading} />
      <InfoTextRow
        label="Website"
        value={merchantCompany?.website?.map((url) => (
          <Tag key={url}>
            <Typography.Link href={`https://${url}`} target="_blank">
              {url}
            </Typography.Link>
          </Tag>
        ))}
        loading={loading}
      />
      <InfoAmountRow label="Average basket" value={merchantCompany?.averageBasket} currency="EUR" loading={loading} />
      <InfoAmountRow label="Turnover" value={merchantCompany?.turnover} currency="EUR" loading={loading} />
      <InfoTextRow label="Legal form" value={merchantCompany?.legalForm} loading={loading} />
      <InfoDateRow label="Since" value={merchantCompany?.dateStart} loading={loading} />
    </InfoTile>
  );
};

export default CompanyActivity;

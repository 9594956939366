import React from 'react';
import { Anchor } from 'antd';

const AnchorList = ({ anchors = [] }) => (
  <Anchor
    // apply norbr custom styles
    className="norbr-anchor"
  >
    {anchors
      .filter((a) => !a.hidden)
      .map(({ hash, icon: Icon, label }, index) => (
        <Anchor.Link
          key={hash.concat(index)}
          href={hash}
          title={[<Icon key="icon" />, <span key="label">{label}</span>]}
        />
      ))}
  </Anchor>
);

export default AnchorList;

import React from 'react';
import { Drawer } from 'antd';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import LastExportList from './LastExportList';
import CreateExport from './CreateExport';

const ExportDrawer = ({ open, onClose, containerId }) => (
  <Drawer
    width={470}
    open={open}
    getContainer={`#${containerId}`}
    style={{ position: 'absolute', zIndex: 50 }}
    bodyStyle={{ padding: 0 }}
    onClose={onClose}
    destroyOnClose
    title="Export"
    closable
    closeIcon={<WindowCloseIcon />}
    mask={false}
    push={false}
  >
    <CreateExport onClose={onClose} />
    <LastExportList />
  </Drawer>
);

export default ExportDrawer;

import { gql } from '@apollo/client';

export const createMetaProgramManagerMutation = gql`
  mutation CreateMetaProgramManager($input: MetaProgramManagerInput!) {
    createMetaProgramManager(input: $input) {
      id
      name
      displayName
      code
    }
  }
`;
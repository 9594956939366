import { gql } from '@apollo/client';

export const ProgramManagerUserViewQuery = gql`
  query ProgramManagerUserViewQuery($id: ID!) {
    programManagerUser(id: $id) {
      id
      full_name
      first_name
      last_name
      email
      phone {
        code
        number
      }
      avatar
      aclProfile {
        id
        name
      }
      isGdpr
      status
      disabled
      disabledAt
      disabledBy {
        id
        email
        full_name
        avatar
      }
      activated
      email_verification_token
      email_verification_token_expires_at
      lastConnection
      programManager {
        id
        name
        adminDomain
        merchantDomain
        emailDomains
        theme {
          icon
          primaryColor
          secondaryColor
        }
        programManagerAclProfiles {
          id
          name
        }
      }
    }
  }
`;

export const ProgramManagerUserAuditLogsQuery = gql`
  query ProgramManagerUserAuditLogsQuery($id: ID!) {
    programManagerUser(id: $id) {
      id
      createdAt
      createdBy {
        id
        full_name
        email
        avatar
      }
      updatedAt
      updatedBy {
        id
        full_name
        email
        avatar
      }
      auditLogs {
        id
        action
        entityId
        entityName
        timestamp
        createdBy {
          email
          full_name
          avatar
        }
      }
    }
  }
`;

export const UserUpdateMutation = gql`
  mutation UserUpdateMutation($id: ID!, $input: UserUpdateInput!) {
    updateProgramManagerUser(id: $id, input: $input) {
      id
      first_name
      last_name
      full_name
      phone {
        code
        number
      }
      avatar
    }
  }
`;

export const UserAvatarUpload = gql`
  mutation UserAvatarUpload($id: ID!, $file: Upload) {
    uploadUserAvatar(id: $id, file: $file) {
      id
      avatar
    }
  }
`;

export const UserDisabledUpdateMutation = gql`
  mutation UserDisabledUpdateMutation($id: ID!, $disabled: Boolean!) {
    updateDisabledOfProgramManagerUser(id: $id, disabled: $disabled) {
      id
        status
      disabled
      disabledAt
      disabledBy {
        id
        email
        full_name
        avatar
      }
    }
  }
`;

export const UserDeleteMutation = gql`
  mutation UserDeleteMutation($id: ID!) {
    deleteProgramManagerUser(id: $id)
  }
`;


export default {};

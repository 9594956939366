import React from 'react';

const UpdateDelete = ({ log }) => (
  <>
    Deleted:
    <br />
    {log.log_entity_type}: {log.log_entity_id} {log.log_entity_name && `(${log.log_entity_name})`}
  </>
);

export default UpdateDelete;
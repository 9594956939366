import React from 'react';
import styles from '../Brainpower.module.scss';

/**
 * String to <span> with underline on the last 2 chars
 * @param str
 * @returns {JSX.Element}
 */
export const formatEndUnderline = (str) => (
  <span>
    {str.slice(0, -2)}
    <span className={styles.underline}>{str.slice(-2)}</span>
  </span>
);
import { gql } from '@apollo/client';

export const programManagerLoginMethodQuery = gql`
query ProgramManagerLoginMethod($id: ID!) {
  programManager(id: $id) {
    id
    loginMethod
    sso {
      config {
        tenantId
        providerId 
      }
    }
  }
}
`;

export const updateProgramManagerLoginMethodMutation = gql`
mutation UpdateProgramManagerLoginMethod($id: ID!, $input: LoginMethodInput!) {
  updateProgramManagerLoginMethod(id: $id, input: $input) {
    id
    loginMethod
  }
}
`;
import React from 'react';
import styles from './LoaderPreview.module.scss';
import Loader from '../../../../../../Common/Loader/Loader';

const LoaderPreview = ({ loader }) => {
  const loaderPreview = (
    <div className={styles.preview}>
      <Loader loader={loader} width={60} height={60} />
    </div>
  );
  switch (loader?.type) {
    case 'json':
      if (loader?.img) {
        return loaderPreview;
      }
      return <div className={styles.noPreview}>No preview available because your JSON is not valid</div>;
    case 'gif':
      if (loader?.img) {
        return loaderPreview;
      }
      return <div className={styles.noPreview}>Please upload your loader gif file</div>;
    case 'css':
      if (loader?.img && loader?.animation) {
        return loaderPreview;
      }
      return <div className={styles.noPreview}>Please upload your logo and select an animation</div>;
    default:
      return (
        <div>
          <div className={styles.noPreview}>No custom loader has been selected so the default one is used</div>
          <Loader height={60} width={60} />
        </div>
      );
  }
};

export default LoaderPreview;

import { gql } from '@apollo/client';

export const metaProgramManagerLoginMethodQuery = gql`
query MetaProgramManagerLoginMethod($id: ID!) {
  metaProgramManager(id: $id) {
    id
    loginMethod
    sso {
      config {
        tenantId
        providerId 
      }
    }
  }
}
`;

export const updateMetaProgramManagerLoginMethodMutation = gql`
mutation UpdateMetaProgramManagerLoginMethod($id: ID!, $input: LoginMethodInput!) {
  updateMetaProgramManagerLoginMethod(id: $id, input: $input) {
    id
    loginMethod
  }
}
`;
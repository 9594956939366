import { useEffect, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import useSearchFilters from '../../hooks/useSearchFilters';
import usePagination from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';

export const orderListQuery = gql`
  query Orders($offset: Int, $limit: Int, $filters: [SearchFilter], $sort: [SearchSort]) {
    orders(offset: $offset, limit: $limit, filters: $filters, sort: $sort)
  }
`;
export const ordersCountQuery = gql`
  query OrdersCount($filters: [SearchFilter]) {
    ordersCount(filters: $filters)
  }
`;

const useOrderListQuery = () => {
  const { offset, limit } = usePagination();
  const { list } = useSearchFilters();
  const [sort] = useSort();

  const [fetch, { data, previousData, loading, error }] = useLazyQuery(orderListQuery, {
    variables: {
      offset,
      limit,
      filters: list,
      sort,
    },
  });

  const [fetchCount, { data: count, previousData: previousCount, loading: loadingCount, error: errorCount }] = useLazyQuery(
    ordersCountQuery,
    {
      variables: {
        filters: list,
      },
    },
  );

  const result = useMemo(
    () =>
      (loading ? previousData?.orders : data?.orders) ?? [],
    [data, loading, previousData],
  );

  const total = useMemo(
    () =>
      (loadingCount ? previousCount?.ordersCount : count?.ordersCount) ?? 0,
[count, loadingCount, previousCount]
  );

  useEffect(() => {
    fetch();
    fetchCount();
  }, []);

  return { result, total, loading, error, loadingCount };
};

export default useOrderListQuery;

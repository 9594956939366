import { mergeDeep as apolloMergeDeep } from '@apollo/client/utilities';

export const isObject = (object: object): boolean => object != null && typeof object === 'object';

export const isEqual = (obj1: any, obj2: any): boolean => {
  const props1 = Object.getOwnPropertyNames(obj1);
  const props2 = Object.getOwnPropertyNames(obj2);
  if (props1.length !== props2.length) {
    return false;
  }
  for (let i = 0; i < props1.length; i += 1) {
    const val1 = obj1[props1[i]];
    const val2 = obj2[props1[i]];
    const isObjects = isObject(val1) && isObject(val2);
    if ((isObjects && !isEqual(val1, val2)) || (!isObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
};

export const mergeDeep = apolloMergeDeep;

export const omitDeep = (value: any, key: string): any => {
  if (Array.isArray(value)) return value.map((v) => omitDeep(v, key));
  if (isObject(value)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [key]: omit, ...res } = value;
    return Object.keys(res).reduce((memo, k) => ({ ...memo, [k]: omitDeep(res[k], key) }), {});
  }
  return value;
};

export const omitTypename = (value: any): any => omitDeep(value, '__typename');

/**
 * Get value by path
 * @param obj
 * @param path
 * @returns {undefined|*}
 */
export const getValueByPath = (obj: any, path: string): any => {
  let value = obj;
  const keys = path.split('.').filter((k) => k !== ''); // Split the path and remove empty entries

  for (let i = 0; i < keys.length; i += 1) {
    let key = keys[i];

    // If the key is in brackets, it's an array index
    const index = key.match(/\[(\d+)\]/)?.[1];
    if (index) {
      key = key.replace(/\[(\d+)\]/, '');
    }

    // Otherwise, move to the next object in the path
    value = index ? value[key]?.[index] : value[key];

    // If the key doesn't exist in the current object, return undefined
    if (value == null) return undefined;

    // If this is the last key, return the value
    if (i === keys.length - 1) return value;
  }

  // Explicitly return undefined if the path is not found
  return undefined;
};

export default {};

import { gql } from '@apollo/client';

export const programManagerCompanyExecutiveQuery = gql`
  query ProgramManagerCompanyExecutive($id: ID!) {
    programManager(id: $id) {
      id
      company {
        id
        directors
        ceo
        executive
      }
    }
  }
`;

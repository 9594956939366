import React, { useState } from 'react';
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  Layout,
  Pagination,
  Row,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import ReactHeight from 'react-height';
import classnames from 'classnames';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import FeatureSearchIcon from '@2fd/ant-design-icons/lib/FeatureSearch';
import useNavigateWithSearch from '../../../../../util/navigate';
import { useGdpr } from '../../../../../contexts/me.context';
import { TableDisplay, tableDisplayList } from '../../Common/constants/tableDisplays';
import { TargetEntity } from '../../Common/constants/targetEntities';
import useDrawer from '../../Common/useDrawer';
import Drawers from '../../Common/constants/drawers';
import usePagination from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import useTableDisplay from '../../hooks/useTableDisplay';
import SearchFilterTags from '../../Common/SearchFilterTags/SearchFilterTags';
import styles from '../../OrderList/OrderTable/OrderTable.module.scss';
import useTransactionListQuery from './useTransactionListQuery';
import buildColumns from '../../Common/AdvancedTable/buildColumns';
import useColumnSet from '../../hooks/useColumnSet';

const TransactionTable = () => {
  const navigate = useNavigateWithSearch();

  const [, setDrawer] = useDrawer();

  const [height, setHeight] = useState();

  const [columnSet] = useColumnSet();

  const [tableDisplay, setTableDisplay] = useTableDisplay();
  const { page, pageSize, setPage } = usePagination();
  const [sort, setSort] = useSort();
  const isGdpr = useGdpr();

  const columns = buildColumns(columnSet, sort, tableDisplay, TargetEntity.TRANSACTION, isGdpr);
  const handleTableChange = (_p, _f, sorter) => {
    // handle single sort, default from favorite view
    if (Array.isArray(sorter)) {
      setSort([{ field: sorter[1].field, sortOrder: sorter[1].order }]);
    } else if (sorter.order) {
      setSort([{ field: sorter.field, sortOrder: sorter.order }]);
    } else {
      setSort();
    }
  };

  const { result, total, loading, error } = useTransactionListQuery();

  return (
    <Layout>
      <Layout.Header className={styles.layoutHeader}>
        <Row gutter={24} style={{ position: 'relative' }}>
          <Col>
            <Space>
              Results
              <Tag style={{ color: 'darkgrey' }}>{total}</Tag>
            </Space>
          </Col>
          <Col flex="1" style={{ position: 'initial' }}>
            <SearchFilterTags />
          </Col>
        </Row>
      </Layout.Header>
      <Layout id="transaction-table" className={styles.layoutContent}>
        <Layout.Content>
          <ReactHeight onHeightReady={setHeight} style={{ height: '100%' }}>
            {height && (
              <ConfigProvider
                renderEmpty={() => (
                  <Empty
                    className={styles.empty}
                    style={{ height: height - 74 }}
                    image={loading ? null : undefined}
                    description={(() => {
                      if (loading) {
                        return null;
                      }
                      if (error) {
                        return (
                          <Typography.Text type="danger">An error occurred, please contact support.</Typography.Text>
                        );
                      }
                      return [
                        <div key="no-results-found">No results found</div>,
                        <Button
                          key="adjust-filters"
                          type="link"
                          icon={<FeatureSearchIcon style={{ fontSize: 18 }} />}
                          onClick={() => setDrawer(Drawers.SEARCH)}
                        >
                          Adjust filters
                        </Button>,
                      ];
                    })()}
                  />
                )}
              >
                <Table
                  className={classnames(styles.table, {
                    [styles.richDisplay]: tableDisplay === TableDisplay.RICH,
                  })}
                  columns={columns}
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  // dataSource={result.map(({ children, ...row }) => row)}
                  dataSource={result}
                  loading={
                    loading && {
                      size: 'large',
                    }
                  }
                  size="small"
                  scroll={{ x: 'max-content', y: height - 40 }} // available height - head row height
                  pagination={false}
                  onRow={(record) => ({
                    onClick: () => {
                      if (record[Fields.SOURCE] !== 'insights_pos')
                        navigate(`/brainpower/orders/${record[Fields.ORDER_ID]}`); // click row
                    },
                  })}
                  rowClassName={(record) => record[Fields.SOURCE] === 'insights_pos' && styles.noClick}
                  rowKey={(record) => record[Fields.TRANSACTION_ID]}
                  onChange={handleTableChange}
                />
              </ConfigProvider>
            )}
          </ReactHeight>
        </Layout.Content>
        <Layout.Footer className={styles.layoutFooter}>
          <Row gutter={24} justify="space-between">
            <Col>
              <Space>
                <Pagination
                  className={styles.pagination}
                  total={total}
                  current={page}
                  pageSize={pageSize}
                  onChange={setPage}
                  showSizeChanger={false}
                  showLessItems
                  size="small"
                  hideOnSinglePage
                />
                <Select
                  value={pageSize}
                  onSelect={(value) => setPage(1, value)}
                  options={[{ value: 10 }, { value: 20 }, { value: 50 }]}
                  bordered={false}
                />
              </Space>
            </Col>
            <Col>
              <Segmented
                value={tableDisplay}
                onChange={setTableDisplay}
                options={tableDisplayList}
                style={{ fontSize: 18 }}
              />
            </Col>
          </Row>
        </Layout.Footer>
      </Layout>
    </Layout>
  );
};

export default TransactionTable;

import { gql } from '@apollo/client';

export const updateNextMutation = gql`
  mutation UpdateNext($id: ID!, $input: SplitterInput!) {
    updateNextBranch(id: $id, input: $input) {
      id
      color
      condition
      next {
        id
        type
        color
        blocking
        other {
          id
        }
        branches {
          id
        }
        contract {
          id
        }
      }
      contract {
        id
      }
      blocking
    }
  }
`;

export const removeSplitterBranchMutation = gql`
  mutation RemoveBranch($id: ID!) {
    removeBranch(id: $id) {
      id
    }
  }
`;

export const setContractBranchMutation = gql`
  mutation UpdateBranchContract($id: ID!, $contractId: ID) {
    updateBranchContract(id: $id, contractId: $contractId) {
      id
      contract {
        id
      }
      blocking
    }
  }
`;

import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import {useLazyQuery} from '@apollo/client';
import InfoTile, { InfoDateRow, InfoTextRow } from '../../../Tile/Info/Info';
import { orderAccountHistoryQuery, activityAccountHistoryQuery } from './query';
import AccountHistorySkeleton from './AccountHistorySkeleton';

const AccountHistory = () => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(activityAccountHistoryQuery, {
    variables: {
      orderId,
    }
  });

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(
    orderAccountHistoryQuery,
    {
      variables: {
        orderId,
      },
      onCompleted: data => !data.order && fetchInsights(),
    },
  );

  const transaction = orderStack?.last_transaction ?? orderInsights?.last_transaction;

  if (!transaction) return <AccountHistorySkeleton />;

  return (
    <InfoTile name="account-history" title="Account history">
      <InfoDateRow label="Creation date" value={transaction.customer_account_creation_date} />
      <InfoDateRow label="Last update" value={transaction.customer_account_change_date} />
      <InfoDateRow label="Last password update" value={transaction.customer_account_change_password_date} />
      <InfoTextRow label="# attempts in last 24 hours" value={transaction.customer_account_attempts_order_day} />
      <InfoTextRow label="# attempts in last year" value={transaction.customer_account_attempts_order_year} />
      <InfoDateRow label="Pay. method registration date" value={transaction.customer_account_add_payment_means_date} />
    </InfoTile>
  );
};

export default AccountHistory;

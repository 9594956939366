/* eslint-disable no-param-reassign */
import Units from '../../constants/units';

export const percentGroupDataItemAdapter = (val) => {
  const valueKey = val.dataItem.component.dataFields.valueY;
  let numerator = 0;
  let denominator = 0;
  val.dataItem.groupDataItems.forEach((item) => {
    numerator += item.dataContext[`${valueKey}_numerator`] ?? 0;
    denominator += item.dataContext[`${valueKey}_denominator`] ?? 0;
  });
  val.value = denominator > 0 ? (numerator / denominator) * 100 : 0;
  return val;
};

const sumProp = (arr, prop) => arr.reduce((sum, row) => sum + row[prop] ?? 0, 0);
const sumPercent = (arr, prop) => (sumProp(arr, `${prop}_numerator`) / sumProp(arr, `${prop}_denominator`)) * 100;

export const calculateTotals = (dimensions, indicators, data) => {
  let totals;
  switch (dimensions.length) {
    case 1:
      // sum foreach indicators
      totals = indicators.reduce((memo, ind) => {
        if (ind.unit === Units.Percent) {
          return {
            ...memo,
            [ind.id]: sumPercent(data, ind.id),
          };
        }
        return {
          ...memo,
          [ind.id]: sumProp(data, ind.id),
        };
      }, {});
      break;
    case 2:
      // map over data to find and sum on category
      totals = data.reduce((memo, row) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [dimensions[0].id]: _, ...series } = row;
        return {
          ...memo,
          ...Object.keys(series).reduce(
            (memo2, key) => ({
              ...memo2,
              [key]:
                indicators[0].unit === Units.Percent
                  ? {
                      numerator: (memo[key]?.numerator ?? 0) + series[key][`${indicators[0].id}_numerator`],
                      denominator: (memo[key]?.denominator ?? 0) + series[key][`${indicators[0].id}_denominator`],
                    }
                  : (memo[key] ?? 0) + series[key],
            }),
            {},
          ),
        };
      }, {});

      if (indicators[0].unit === Units.Percent) {
        totals = Object.keys(totals).reduce(
          (memo, key) => ({ ...memo, [key]: (totals[key].numerator / totals[key].denominator) * 100 }),
          {},
        );
      }

      break;
    default:
      throw new Error('Too many dimensions.');
  }
  return totals;
};

export default {};

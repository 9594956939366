import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import useSearchFilters from '../hooks/useSearchFilters';
import useBaseCurrency from '../hooks/useBaseCurrency';
import useBaseGranularity from '../hooks/useBaseGranularity';
import useDimensions from '../hooks/useDimensions';
import useIndicators from '../hooks/useIndicators';
import usePagination from '../hooks/usePagination';
import useSort from '../hooks/useSort';
import { config as Dimensions } from '../Dashboard/constants/dimensions';

export const summaryQuery = gql`
  query Brainpower($service: String!, $parameters: Object!) {
    brainpower(service: $service, parameters: $parameters)
  }
`;

const useOverviewQuery = () => {
  const { list } = useSearchFilters();

  const [dimensions] = useDimensions();
  const [indicators] = useIndicators();

  const [baseCurrency] = useBaseCurrency();
  const [baseGranularity] = useBaseGranularity();

  const [sort] = useSort();
  const { offset, limit } = usePagination();

  const timezone = 'UTC';

  const params = useMemo(
    () => ({
      filters: list.map((filter) => ({
        name: filter.key,
        value: filter.value,
        excluded: filter.excluded,
      })),
      dimensions,
      indicators,
      timezone,
      currency: baseCurrency,
      granularity: baseGranularity,
      include_null_date: true,
      include_null_values: true,
      offset,
      limit,
      sort: {
        field_type: Dimensions[sort[0]?.field] ? 'dimension' : 'indicator',
        name: sort[0]?.field ?? dimensions[0].field,
        direction: sort[0]?.sortOrder ?? 'ascend',
      },
    }),
    [dimensions, indicators, baseCurrency, baseGranularity, list, offset, limit, sort],
  );

  const { data, previousData, loading, error } = useQuery(summaryQuery, {
    variables: {
      service: 'generic_query',
      parameters: params,
    },
  });

  return {
    result: ((loading ? previousData?.brainpower?.data : data?.brainpower?.data) ?? []).map((row, index) => ({
      key: `row-${index}`,
      [Fields.CURRENCY]: baseCurrency,
      ...row,
    })),
    total: {
      [Fields.CURRENCY]: baseCurrency,
      ...((loading ? previousData?.brainpower?.total : data?.brainpower?.total) ?? {}),
    },
    count: (loading ? previousData?.brainpower?.count : data?.brainpower?.count) ?? 0,
    loading,
    error,
  };
};

export default useOverviewQuery;

import { gql } from '@apollo/client';

export const rulesNameQuery = gql`
    query RulesName ($id: ID!){
        merchantAccount (id: $id) {
            rules {
                _id
                name
            }
        }
    }
`;
import { gql } from '@apollo/client';

export const MerchantAccountPartnerDataQuery = gql`
  query MerchantAccountPartnerDataQuery($id: ID!) {
    merchantAccount(id: $id) {
      id
      source
      partnerData
      programManager {
        id
        name
        activePartners {
          partner {
            id
            api_name
            name
            type
            company {
              theme {
                icon
              }
            }
            merchant_attribute_configuration
          }
        }
      }
    }
  }
`;

export const MerchantAccountPartnerDataUpdateMutation = gql`
  mutation MerchantAccountPartnerDataUpdateMutation($id: ID!, $partner: String!, $key: String!, $value: String) {
    updateMerchantAccountPartnerData(id: $id, partner: $partner, key: $key, value: $value) {
      id
      partnerData
    }
  }
`;

export const MerchantAccountGeneratePartnerDataGenerateMutation = gql`
  mutation MerchantAccountGeneratePartnerDataGenerateMutation($id: ID!, $partner: String!, $key: String!) {
    generateMerchantAccountPartnerData(id: $id, partner: $partner, key: $key) {
      id
      partnerData
    }
  }
`;

export default {};

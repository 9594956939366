import { gql } from '@apollo/client';

export const paymentMethodQuery = gql`
  query PaymentMethodByApiName($apiName: String!, $partnerRouteFilters: PartnerRouteFilters) {
    paymentMethodByApiName(apiName: $apiName) {
      id
      api_name
      partner_routes(filters: $partnerRouteFilters) {
        id
        is_cancellable
        is_capturable
        is_3ds_available
        is_refundable
        is_partial_refund_available
        is_differed_capture_available
        is_partial_capture_available
        is_multiple_captures_available
        is_multiple_refunds_available
        channel
        authorization_currency
        settlement_currency
        partner {
          id
          is_cancellable
          is_capturable
          is_3ds_available
          is_refundable
          is_partial_refund_available
          is_differed_capture_available
          is_partial_capture_available
          is_multiple_captures_available
          is_multiple_refunds_available
        }
      }
    }
  }
`;

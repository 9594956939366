import {gql} from '@apollo/client';

export const countriesQuery = gql`
    {
        regions {
            id
            label
            subregions {
                id
                label
                countries {
                    id
                    label
                }
            }
        }
    }
`;
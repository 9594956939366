import moment from 'moment-timezone';

/**
 * Supported format
 * ========
 * *    *    *    *    *    *
 * ┬    ┬    ┬    ┬    ┬    ┬
 * │    │    │    │    │    │
 * │    │    │    │    │    └ day of week (0 - 7, 1L - 7L) (0 or 7 is Sun)
 * │    │    │    │    └───── month (1 - 12)
 * │    │    │    └────────── day of month (1 - 31, L)
 * │    │    └─────────────── hour (0 - 23)
 * │    └──────────────────── minute (0 - 59)
 * └───────────────────────── second (0 - 59, optional)
 */

const Schedules = {
  ONE_TIME: 'one_time',
  DAILY: 'daily',
  WEEKLY_CURRENT_DAY: 'weekly_monday',
  // MONTHLY_FIRST_MONDAY: 'monthly_first_monday',
  EVERY_WEEKDAY: 'every_weekday',
  CUSTOM: 'custom',
};

export const schedules = {
  // [Schedules.ONE_TIME]: {
  //   value: Schedules.ONE_TIME,
  //   label: 'One time',
  // },
  [Schedules.DAILY]: {
    value: Schedules.DAILY,
    label: 'Daily',
    cron: '* * *',
  },
  [Schedules.WEEKLY_CURRENT_DAY]: {
    value: Schedules.WEEKLY_CURRENT_DAY,
    label: `Weekly on ${moment().format('dddd')}`,
    cron: `* * ${Number.parseInt(moment().format('d'), 10)}`,
  },
  // [Schedules.MONTHLY_FIRST_MONDAY]: {
  //   value: Schedules.MONTHLY_FIRST_MONDAY,
  //   label: 'Monthly on the first Monday',
  //   cron: '* * 2#1',
  // },
  [Schedules.EVERY_WEEKDAY]: {
    value: Schedules.EVERY_WEEKDAY,
    label: 'Every weekday (Monday to Friday)',
    cron: '* * 1,2,3,4,5',
  },
  [Schedules.CUSTOM]: {
    value: Schedules.CUSTOM,
    label: 'Custom',
    cron: '* * 1',
  },
};

export const scheduleList = Object.values(schedules);

export default Schedules;

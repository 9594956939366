import React, { useContext } from 'react';

export const MODES = {
  INTERNAL: 'internal',
  META_PM: 'metaPm',
  PM: 'pm',
  MERCHANT: 'merchant',
}

export const ModeContext = React.createContext(undefined);

export const useMode = () => useContext(ModeContext);

import React, {useEffect, useState} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Alert, Avatar, Button, Drawer, message, Select, Space, Typography } from 'antd';
import { DeploymentUnitOutlined } from '@ant-design/icons';
import { PartnersQuery, MetaProgramManagerPartnersQuery } from '../../../../query';
import { searchByProp } from '../../../../../../../util/string';
import { PartnerTypes, partnerTypes } from '../../../../Partners/constants';
import { PartnerPaymentMethodsQuery, ProgramManagerSetActivePartnerMutation } from '../query';
import SelectPaymentMethods from './SelectPaymentMethods';
import { compareByProp } from '../../../../../../../util/array';
import {useMetaProgramManagerId} from '../../../../../../../contexts/app.context';
import {MODES, useMode} from '../../../../../../../contexts/mode.context';

const AddActivePartner = ({ programManager, visible, onClose }) => {
  const [selectedPartner, setSelectedPartner] = useState();
  const [checkedList, setCheckedList] = useState([]);
  const [metaProgramManagerId] = useMetaProgramManagerId();
  const mode = useMode();

  const [partners, setPartners] = useState(null);

  const [getPartners] = useLazyQuery(PartnersQuery);
  const [getMetaPartners] = useLazyQuery(MetaProgramManagerPartnersQuery, {
    variables: {
      id: metaProgramManagerId,
    }
  });

  useEffect(() => {
    if (programManager) {
      if (mode === MODES.INTERNAL && !programManager?.metaProgramManager?.id) {
        getPartners().then(result => setPartners(result.data.partners))
      } else if ([MODES.INTERNAL, MODES.META_PM].includes(mode) && programManager?.metaProgramManager?.id) {
        getMetaPartners({
          variables: {
            id: programManager?.metaProgramManager?.id,
          }
        }).then(result => setPartners(result.data.metaProgramManager.activePartners.map(ap => ap.partner)))
          .catch(() => message.error('An error occurred, please try again later.'))
      }
    }
  }, [programManager]);

  const [loadPartnerPaymentMethods, partnerPaymentMethodsQuery] = useLazyQuery(PartnerPaymentMethodsQuery);
  const [addPartner, { loading, error }] = useMutation(ProgramManagerSetActivePartnerMutation, {
    variables: { id: programManager.id },
    onCompleted: (res) => {
      if (res.programManagerSetActivePartner) {
        message.success('Partner successfully activate');
        setSelectedPartner(undefined);
        setCheckedList([]);
        onClose();
      }
    },
    errorPolicy: 'all',
  });

  const submit = () => addPartner({ variables: { partner: selectedPartner, paymentMethods: checkedList } });

  const onPartnerChange = (partnerId) => {
    setCheckedList([]);
    setSelectedPartner(partnerId);
    return loadPartnerPaymentMethods({
      variables: {
        id: partnerId,
        programManagerId: programManager.id,
      }
    });
  };

  return (
    <Drawer
      title="Add active partner"
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Text>
          {programManager.payfac ? 'Select partner that allow Payment Facilitator' : 'Select partner'}
        </Typography.Text>
        <Select
          style={{ width: '100%' }}
          size="large"
          allowClear
          showSearch
          filterOption={searchByProp('label')}
          optionLabelProp="label"
          loading={!partners}
          onChange={onPartnerChange}
          value={selectedPartner}
        >
          {Object.values(
            partners
              // filter partner if payfac is not allowed
              ?.filter((partner) => partner.allow_payfac || !programManager.payfac)
              // filter already active partners
              .filter((partner) => !programManager.activePartners.find((ap) => ap.partner.id === partner.id))
              .reduce(
                (memo, partner) => ({
                  ...memo,
                  [partner.type]: {
                    type: partner.type,
                    partnerList: [...(memo[partner.type]?.partnerList ?? []), partner],
                  },
                }),
                {},
              ) ?? {},
          ).map((partnerType) => (
            <Select.OptGroup label={partnerTypes[partnerType.type].label}>
              {partnerType.partnerList.sort(compareByProp('name', 'asc', 'string')).map((partner) => (
                <Select.Option key={partner.id} value={partner.id} label={partner.name}>
                  <div style={{ display: 'inline-flex', gap: 8, width: '100%' }}>
                    <Avatar
                      src={partner.company?.theme?.icon}
                      icon={<DeploymentUnitOutlined />}
                      size="small"
                      shape="square"
                      style={{ backgroundColor: partnerTypes[partner.type].color }}
                      title={partnerTypes[partner.type].label}
                    />
                    <span style={{ flex: 1 }}>{partner.name}</span>
                  </div>
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
        {selectedPartner &&
          [PartnerTypes.PAYIN, PartnerTypes.PAYOUT].includes(partnerPaymentMethodsQuery.data?.partner.type) && (
            <SelectPaymentMethods
              loading={partnerPaymentMethodsQuery.loading}
              options={partnerPaymentMethodsQuery.data?.partner.payment_methods}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
            />
          )}
        {selectedPartner && (
          <div>
            <Button type="text" onClick={onClose}>
              Cancel
            </Button>
            <Button type="primary" onClick={submit} hidden={!selectedPartner} loading={loading}>
              Submit
            </Button>
          </div>
        )}
        {error && <Alert type="error" message={error.message} />}
      </Space>
    </Drawer>
  );
};

export default AddActivePartner;

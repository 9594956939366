import { useEffect, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import useSearchFilters from '../../Brainpower/hooks/useSearchFilters';
import usePagination from '../../Brainpower/hooks/usePagination';
import useSort from '../../Brainpower/hooks/useSort';

export const logListQuery = gql`
  query Logs($offset: Int, $limit: Int, $filters: [SearchFilter], $sort: [SearchSort]) {
    logs(offset: $offset, limit: $limit, filters: $filters, sort: $sort)
    logsCount(filters: $filters)
  }
`;

const useLogListQuery = () => {
  const { offset, limit } = usePagination();
  const { list } = useSearchFilters();
  const [sort] = useSort();

  const [fetch, { data, previousData, loading, error }] = useLazyQuery(logListQuery, {
    variables: {
      offset,
      limit,
      filters: list,
      sort,
    },
  });

  const [result, total] = useMemo(
    () => [
      (loading ? previousData?.logs : data?.logs) ?? [],
      (loading ? previousData?.logsCount : data?.logsCount) ?? 0,
    ],
    [data, loading, previousData],
  );

  useEffect(() => {
    fetch();
  }, [offset, limit]);

  return { result, total, loading, error };
};

export default useLogListQuery;

import React from 'react';
import { Space } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import CURRENCY_LIST from 'norbr-shared-lib/constants/currencies/list';
import { compareByProp } from '../../../../util/array';
import {
  partnerResponseTypeList,
  partnerResponseTypes,
  partnerRouteOptionList,
  partnerRouteStatuses,
  partnerRouteStatusList,
} from '../PartnerRoutes/constants';
import usePaymentMethods from '../../../../hooks/common/usePaymentMethods';
import { useCan } from '../../../../contexts/ability.context';
import PAYMENT_CHANNELS, { PAYMENT_CHANNEL_LIST } from '../../../../constants/PAYMENT_CHANNELS';

const usePartnerRoutesColumns = () => {
  const paymentMethods = usePaymentMethods();
  const can = useCan();

  return [
    {
      title: 'Payment method',
      dataIndex: 'paymentMethod',
      key: 'payment_method',
      width: 200,
      render: (paymentMethod) =>
        paymentMethods[paymentMethod] ? (
          <Space>
            {paymentMethods[paymentMethod].imgUrl && (
              <img src={paymentMethods[paymentMethod].imgUrl} alt="" style={{ width: 24 }} />
            )}
            {paymentMethods[paymentMethod].name}
          </Space>
        ) : (
          paymentMethod
        ),
      filters: Object.values(paymentMethods)
        .map((pm) => ({ value: pm.api_name, text: pm.name }))
        .sort(compareByProp('text', 'asc', 'string')),
      filterSearch: true,
    },
    {
      title: 'Channel',
      dataIndex: 'channel',
      key: 'channel',
      width: 200,
      render: (channel) => {
        if (PAYMENT_CHANNELS[channel]) {
          const Icon = PAYMENT_CHANNELS[channel].icon;
          return (
            <Space>
              {Icon && <Icon />}
              {PAYMENT_CHANNELS[channel].label}
            </Space>
          );
        }
        return channel;
      },
      filters: PAYMENT_CHANNEL_LIST.map((c) => ({ value: c.api_name, text: c.name })).sort(
        compareByProp('text', 'asc', 'string'),
      ),
    },
    {
      title: 'Auth',
      dataIndex: 'authorizationCurrency',
      key: 'authorization_currency',
      width: 100,
      align: 'center',
      filters: CURRENCY_LIST.map((c) => ({ value: c.alpha, text: `[${c.alpha}] ${c.name}` })).sort(
        compareByProp('text', 'asc', 'string'),
      ),
      filterSearch: true,
    },
    {
      title: 'Settl',
      dataIndex: 'settlementCurrency',
      key: 'settlement_currency',
      width: 100,
      align: 'center',
      filters: CURRENCY_LIST.map((c) => ({ value: c.alpha, text: `[${c.alpha}] ${c.name}` })).sort(
        compareByProp('text', 'asc', 'string'),
      ),
      filterSearch: true,
    },
    ...partnerRouteOptionList
      .map((option) => {
        switch (option.type) {
          case 'PartnerResponseType':
            return {
              title: option.label,
              dataIndex: option.value,
              key: option.value,
              align: 'center',
              width: 200,
              render: (value, record) => {
                if (value === null) {
                  if (!record.partner) return <span title="Missing partner">?</span>;
                  const Icon = partnerResponseTypes[record.partner[option.value]]?.iconComponent;
                  return Icon ? (
                    <Icon
                      style={{ color: 'grey' }}
                      title={`${
                        partnerResponseTypes[record.partner[option.value]]?.label
                      } - inherit from partner configuration`}
                    />
                  ) : (
                    record.partner[option.value]
                  );
                }
                const Icon = partnerResponseTypes[value]?.iconComponent;
                return Icon ? <Icon title={partnerResponseTypes[value]?.label} /> : value;
              },
              filters: [
                ...partnerResponseTypeList.map(({ value, label }) => ({ value, text: label })),
                { value: null, text: 'Inherit' },
              ],
            };
          case 'Int':
            return {
              title: option.label,
              dataIndex: option.value,
              key: option.value,
              align: 'center',
              width: 200,
              render: (value, record) => record[option.value],
            };
          case 'Boolean':
          default:
            return {
              title: option.label,
              dataIndex: option.value,
              key: option.value,
              align: 'center',
              width: 200,
              render: (value, record) => {
                if (value === null) {
                  if (!record.partner) return <span title="Missing partner">?</span>;
                  return record.partner[option.value] ? (
                    <CheckOutlined style={{ color: 'grey' }} title="Inherit from partner configuration" />
                  ) : (
                    <CloseOutlined style={{ color: 'grey' }} title="Inherit from partner configuration" />
                  );
                }
                return value ? (
                  <CheckOutlined style={{ color: 'green' }} />
                ) : (
                  <CloseOutlined style={{ color: 'red' }} />
                );
              },
              filters: [
                { value: true, text: 'Yes' },
                { value: false, text: 'No' },
              ],
            };
        }
      })
      .sort(compareByProp('title')),
    ...(can('read', 'merchant_account')
      ? [
          {
            title: 'Contracts',
            dataIndex: 'linkedContractCount',
            key: 'linkedContractCount',
            fixed: 'right',
            align: 'right',
            width: 100,
          },
        ]
      : []),
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      fixed: 'right',
      width: 160,
      render: (status) => partnerRouteStatuses[status]?.label,
      filters: partnerRouteStatusList
        .map((s) => ({ value: s.value, text: s.label }))
        .sort(compareByProp('text', 'asc', 'string')),
    },
  ];
};

export default usePartnerRoutesColumns;

import { ConfigProvider } from 'antd';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { addLicense } from '@amcharts/amcharts4/core';
import moment from 'moment-timezone';

import 'antd/dist/antd.variable.min.css';
import './App.scss';

import AppProvider from './contexts/app.context';
import ApolloProvider from './contexts/apollo.context';
import ProgramManagerProvider from './contexts/programManager.context';
// import SubscriptionsProvider from './contexts/subscription.context';
import AppLogged from './App/AppLogged/AppLogged';
import AppUnlogged from './App/AppUnlogged/AppUnlogged';

// add amcharts licenses
addLicense('CH272831119'); // charts license
addLicense('MP272831119'); // maps license

// apply EN local (custom day-of-week at monday)
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

// Antd config - https://ant.design/components/config-provider
const configProvider = {
  form: {
    validateMessages: {
      // eslint-disable-next-line no-template-curly-in-string
      required: '${label} is required.',
    },
  },
};

const App = () => (
  <div id="app">
    <ConfigProvider {...configProvider}>
      <Router>
        <AppProvider>
          {(token) => (
            <ApolloProvider>
              <ProgramManagerProvider>
                {/* <SubscriptionsProvider> */}
                  {token ? <AppLogged /> : <AppUnlogged />}
                {/* </SubscriptionsProvider> */}
              </ProgramManagerProvider>
            </ApolloProvider>
          )}
        </AppProvider>
      </Router>
    </ConfigProvider>
  </div>
);

export default App;

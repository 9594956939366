enum Units {
  Amount,
  Order,
  Percent,
  Transaction,
  Customer,
  Checkout,
  Operation,
  Ticket, // Matcher Operations
  FinancialOperation,
}

export default Units;

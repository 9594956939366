import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

/**
 * Search input with custom styles
 * @param onChange
 * @param placeholder
 * @returns {JSX.Element}
 * @constructor
 */
const SearchInput = ({ onChange, placeholder }) => (
  <Input
    className="norbr-search-input"
    onChange={onChange}
    placeholder={placeholder || 'search'}
    addonBefore={<SearchOutlined />}
    allowClear
  />
);
export default SearchInput;

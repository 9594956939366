import React, { useState } from 'react';
import { Badge, Button, ConfigProvider, Empty, Layout, Table, Tag, Typography } from 'antd';
import ReactHeight from 'react-height';
import classnames from 'classnames';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import DownloadIcon from '@2fd/ant-design-icons/lib/Download';
import moment from 'moment-timezone';
import { DATETIME2_FORMAT } from '../../../../../constants/DATES';
import { compareByProp } from '../../../../../util/array';
import { renderDateCell, renderListCell, renderNumberCell } from '../../Common/AdvancedTable/buildColumns';
import ExportStatuses, { exportStatuses } from '../../ExportList/constants/exportStatuses';
import { reportFormats } from '../../ExportList/constants/reportFormats';
import { sendingMethods } from '../../ExportList/constants/sendingMethods';
import targetEntities from '../../Common/constants/targetEntities';
import { exportQuery, generateReportFileUrlMutation } from '../query';
import styles from './ReportTable.module.scss';
import Loader from '../../../Common/Loader/Loader';
import { useGdpr } from '../../../../../contexts/me.context';

const nextReportStatus = { label: 'Next report', badge: 'default', color: 'orange' };

const ReportTable = () => {
  const isGdpr = useGdpr();
  const { exportId } = useParams();

  const { data, loading, error } = useQuery(exportQuery, { variables: { exportId }, fetchPolicy: 'cache-and-network' });

  const [generateReportFileUrl] = useMutation(generateReportFileUrlMutation);

  const [height, setHeight] = useState();
  const [isDownloading, setIsDownloading] = useState(null);

  if (!data) return null;

  const downloadAs = (url, filename, hash) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.setRequestHeader('browser-hash', hash);
    xhr.send();
    xhr.onload = () => {
      // Tip to force Excel to read file as utf8 encoded
      const bomIndicator = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bomIndicator, xhr.response], { type: 'application/csv' });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = filename;
      a.click();
      setIsDownloading(null);
    };
  };

  const downloadReportFile = (reportId, name, format) => {
    setIsDownloading(reportId);
    const hash = crypto.randomUUID();
    generateReportFileUrl({ variables: { reportId, hash } }).then((res) => {
      if (res?.data?.generateReportFileUrl) {
        downloadAs(res.data.generateReportFileUrl, `${name}.${reportFormats[format].extension}`, hash);
      }
    });
  };

  const dataSource = [
    ...(data.export.scheduleCron?.nextRunAt && data.export.status !== 'paused'
      ? [
          {
            createdAt: data.export.scheduleCron.nextRunAt,
            view: data.export.view,
            status: 'next',
            sendingMethod: data.export.sendingMethod,
          },
        ]
      : []),
    ...data.export.reports,
  ];

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      render: (id, record, index) => dataSource.length - index,
      width: 40,
      align: 'center',
    },
    {
      title: 'REPORT',
      dataIndex: 'createdAt',
      render: renderDateCell(null, { format: DATETIME2_FORMAT }),
      sorter: compareByProp('createdAt'),
      defaultSortOrder: 'descend',
      width: 200,
    },
    {
      title: 'ENTITY',
      dataIndex: 'view',
      render: (view) =>
        renderListCell({
          displayOptions: {
            getOption: (value) => targetEntities[value],
            icon: true,
          },
        })(view.entity),
      sorter: compareByProp('entity'),
    },
    {
      title: 'FILE SIZE',
      dataIndex: 'fileSize',
      render: renderNumberCell(null, { unit: 'byte' }),
      sorter: compareByProp('fileSize'),
      width: 100,
      align: 'right',
    },
    {
      title: 'ROWS',
      dataIndex: 'rowCount',
      render: renderNumberCell(),
      sorter: compareByProp('rowCount'),
      width: 100,
      align: 'right',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: renderListCell({
        displayOptions: {
          getOption: (value) => (value === 'next' ? nextReportStatus : exportStatuses[value]),
          tag: true,
        },
      }),
      sorter: compareByProp('status'),
    },
    {
      title: 'METHOD',
      dataIndex: 'sendingMethod',
      render: renderListCell({
        displayOptions: {
          getOption: (value) => sendingMethods[value],
        },
      }),
      sorter: compareByProp('sendingMethod'),
      width: 120,
    },
    {
      title: 'DOWNLOAD',
      dataIndex: 'id',
      render: (id, { name, format, status, expirationDate, gdpr }) => {
        if (status !== ExportStatuses.SUCCESS) return null;
        if (moment().isAfter(expirationDate))
          return (
            <Tag title="45 day retention" style={{ backgroundColor: 'lightgrey', color: 'black' }}>
              Expired
            </Tag>
          );
        return (
          <Badge
            dot={moment().add(7, 'days').isAfter(expirationDate)}
            title={`Report expires ${moment(expirationDate).fromNow()}`}
          >
            {isDownloading === id ? (
              <Loader />
            ) : (
              <Button
                type="primary"
                icon={<DownloadIcon />}
                size="small"
                disabled={status !== ExportStatuses.SUCCESS || (gdpr && !isGdpr)}
                title={gdpr && !isGdpr ? 'Require GDPR access rights' : null}
                onClick={() => downloadReportFile(id, name, format)}
              />
            )}
          </Badge>
        );
      },
      align: 'center',
      width: 120,
    },
  ];

  return (
    <Layout id="report-table" className={styles.layoutContent}>
      <Layout.Content>
        <ReactHeight onHeightReady={setHeight} style={{ height: '100%' }}>
          {height && (
            <ConfigProvider
              renderEmpty={() => (
                <Empty
                  className={styles.empty}
                  style={{ height: height - 74 }}
                  image={loading ? null : undefined}
                  description={(() => {
                    if (loading) {
                      return null;
                    }
                    if (error) {
                      return (
                        <Typography.Text type="danger">An error occurred, please contact support.</Typography.Text>
                      );
                    }
                    return <div key="no-results-found">No results found</div>;
                  })()}
                />
              )}
            >
              <Table
                className={classnames(styles.table, styles.richDisplay)}
                columns={columns}
                dataSource={dataSource}
                loading={loading && { size: 'large' }}
                size="small"
                scroll={{ x: 'max-content', y: height - 40 }} // available height - head row height
                pagination={false}
                rowKey={(record) => record.id}
                showSorterTooltip={false}
                sortDirections={['descend', 'ascend']}
              />
            </ConfigProvider>
          )}
        </ReactHeight>
      </Layout.Content>
    </Layout>
  );
};

export default ReportTable;

import { gql } from '@apollo/client';

export const merchantAccountHostedPageConfigurationQuery = gql`
  query MerchantAccount($id: ID!) {
    merchantAccount(id: $id) {
      id
      company {
        theme {
          primaryColor
        }
      }
      programManager {
        theme {
          primaryColor
        }
      }
      hostedPageConfiguration {
        status
        logo
        primary_color
        show_pci_logo
        host
        url
        hosted_fields {
          display_buttons
          display_cardholder
          display_save
          use_auto_submit
          version
          ask_zip_code
          ask_cin
        }
      }
    }
  }
`;

export const merchantAccountHostedPageConfigurationMutation = gql`
  mutation UpdateMerchantAccountHostedPageConfiguration(
    $id: ID!
    $hostedPageConfigurationInput: HostedPageConfigurationInput
    $hostedFieldsInput: HostedFieldsInput
  ) {
    updateMerchantAccountHostedPageConfiguration(
      id: $id
      hostedPageConfigurationInput: $hostedPageConfigurationInput
      hostedFieldsInput: $hostedFieldsInput
    ) {
      id
      hostedPageConfiguration {
          status
          logo
          primary_color
          show_pci_logo
          host
          url
          hosted_fields {
              display_buttons
              display_cardholder
              display_save
              use_auto_submit
              version
              ask_zip_code
              ask_cin
          }
      }
    }
  }
`;

export const merchantAccountHostedPageConfigurationLogoUpload = gql`
  mutation updateMerchantAccountHostedPageConfigurationLogoUpload($id: ID!, $file: Upload) {
    uploadMerchantAccountHostedPageConfigurationLogo(id: $id, file: $file) {
      id
      hostedPageConfiguration {
        logo
      }
    }
  }
`;

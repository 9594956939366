import {gql} from '@apollo/client';
import {splitterFragment} from '../../../../query';

export const updateSplitterMutation = gql`
    ${splitterFragment()}
    mutation UpdateSplitter($id: ID!, $input: MerchantDataInput!) {
        updateMerchantDataSplitter(id: $id, input: $input) {
            ...SplitterRecursive
        }
    }
`;
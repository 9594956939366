import React from 'react';
import TableTile from '../../../Tile/Info/Info';
import { Skeleton } from "antd";

const Routing = () => {
  const columns = ['Trx number', 'Mode', 'Merchant contract', 'Reason'].map((column, index) => ({
    title: column,
    key: index,
    render: () => (
      <Skeleton.Button
        title={false}
        style={{
          height: 20,
          width: Math.floor(Math.random() * 180 + 40),
        }}
        active
      />
    ),
  }));
  return (
    <TableTile
      name="routing"
      title="Routing information"
      coluns={columns}
      dataSource={[{ key: 1 }, { key: 2 }]}
    />
  );
};

export default Routing;

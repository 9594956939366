import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'antd';
import { ApartmentOutlined } from '@ant-design/icons';
import styles from './Badge.module.scss';
import { MODES, useMode } from '../../../../contexts/mode.context';
import { contrast } from '../../../../util/theme';

const ProgramManagerBadge = ({ programManager, children }) => {
  const mode = useMode();
  // displayed only for internals and metaProgramManager
  if (![MODES.INTERNAL, MODES.META_PM].includes(mode) || !programManager) return children;
  return (
    <Badge.Ribbon
      className={styles.badge}
      text={
        <Link to={`/program-managers/${programManager.id}`}>
          {programManager?.theme?.icon ? (
            <img
              alt=""
              src={programManager.theme.icon}
              title={programManager.name}
              style={{ backgroundColor: programManager.theme.secondaryColor }}
            />
          ) : (
            <div
              title={programManager.name}
              style={{
                backgroundColor: programManager.theme.secondaryColor,
                color: contrast(programManager.theme.secondaryColor),
                width: 22,
                textAlign: 'center',
              }}
            >
              <ApartmentOutlined />
            </div>
          )}
        </Link>
      }
    >
      {children}
    </Badge.Ribbon>
  );
};

export default ProgramManagerBadge;

import React, { useState } from "react";
import { Button, Form, InputNumber, Radio, Select, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { partnerResponseTypeList, partnerRouteOptionList, partnerRouteOptions } from "../../constants";
import { compareByProp } from "../../../../../../util/array";

const PartnerRouteOptionsForm = ({form, isUpdate}) => {
  const [, forceUpdate] = useState();

  return (
    <Form.List name="options" initialValue={[]}>
      {(fields, { add, remove }) => (
        <Space direction='vertical'>
          {fields.map(({ key, name, fieldKey, ...restField }) => (
            <Space key={key} align="baseline">
              <Form.Item
                {...restField}
                name={[name, 'key']}
                fieldKey={[fieldKey, 'key']}
                rules={[{ required: true, message: 'Missing option' }]}
              >
                <Select
                  options={partnerRouteOptionList.sort(compareByProp('label')).map((o) => ({
                    ...o,
                    disabled: form
                      .getFieldValue('options')
                      .map((f) => f?.key)
                      .includes(o.value),
                  }))}
                  style={{ width: 300 }}
                  dropdownMatchSelectWidth={false}
                  optionLabelProp="label"
                  onChange={forceUpdate}
                />
              </Form.Item>
              {(() => {
                switch (partnerRouteOptions[form.getFieldValue('options')[name]?.key]?.type) {
                  case 'PartnerResponseType':
                    return (
                      <Form.Item {...restField} name={[name, 'value']} fieldKey={[fieldKey, 'value']}>
                        <Select style={{ width: 200 }}>
                          {partnerResponseTypeList.map((responseType) => (
                            <Select.Option key={responseType.value} value={responseType.value} label={responseType.label}>
                              <Space>
                                <responseType.iconComponent />
                                {responseType.label}
                              </Space>
                            </Select.Option>
                          ))}
                          <Select.Option key="inherit" value={null} label="Inherit">
                            Inherit
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    );
                  case 'Boolean':
                    return (
                      <Form.Item {...restField} name={[name, 'value']} fieldKey={[fieldKey, 'value']}>
                        <Radio.Group>
                          <Radio.Button value>True</Radio.Button>
                          <Radio.Button value={false}>False</Radio.Button>
                          {isUpdate && <Radio.Button value={null}>Inherit</Radio.Button>}
                        </Radio.Group>
                      </Form.Item>
                    );
                  case 'Int':
                    return (
                      <Form.Item {...restField} name={[name, 'value']} fieldKey={[fieldKey, 'value']}>
                        <InputNumber
                          type='number'
                          min={0}
                          style={{
                            width: 80,
                          }}
                        />
                      </Form.Item>
                    )
                }
              })()}
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))
          }
          <Form.Item key="add-option">
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add option
            </Button>
          </Form.Item>
        </Space>
      )}
    </Form.List>
  );
};

export default PartnerRouteOptionsForm;
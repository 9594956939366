import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorBoundary from '../../Common/ErrorBoundary/ErrorBoundary';
import Page from '../../Common/Page/Page';
import MetaProgramManagerAccessControlView from './MetaProgramManagerAccessControlView/MetaProgramManagerAccessControlView';
import MetaProgramManagerAccessControlList from './MetaProgramManagerAccessControlList/MetaProgramManagerAccessControlList';
import MetaProgramManagerAccessControlOverview from './MetaProgramManagerAccessControlOverview/MetaProgramManagerAccessControlOverview';

const MetaProgramManagerAccessControl = () => (
  <Page>
    <ErrorBoundary>
      <Routes>
        <Route index element={<MetaProgramManagerAccessControlList />} />
        <Route path="overview" element={<MetaProgramManagerAccessControlOverview />} />
        <Route path=":profileId" element={<MetaProgramManagerAccessControlView />} />
      </Routes>
    </ErrorBoundary>
  </Page>
);

export default MetaProgramManagerAccessControl;

import React from 'react';
import { Typography } from 'antd';
import InfoTile, { InfoSkeletonRow } from '../../../OrderDetail/Tile/Info/Info';

const RequestBodySkeleton = () => (
  <InfoTile name="request-body" title="Request body">
    <Typography.Text>Payload</Typography.Text>
    <InfoSkeletonRow />
  </InfoTile>
);

export default RequestBodySkeleton;

import React, { useState } from 'react';
import { Button, Tag } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { PlusOutlined, TeamOutlined } from '@ant-design/icons';
import TableTile, { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import { cmsList, getCms } from '../../../../../../constants/CMSS';
import MerchantAccountCreate from '../../../MerchantAccounts/MerchantAccountCreate/MerchantAccountCreate';
import { useCan } from '../../../../../../contexts/ability.context';
import { search } from '../../../../../../util/string';
import { compareByProp } from '../../../../../../util/array';
import { Link } from '../../../../../../util/navigate';
import { SOURCES } from '../../../../../../constants/SOURCES';
import MerchantAccountDemoCreate from './MerchantAccountDemoCreate/MerchantAccountDemoCreate';
import {MODES, useMode} from '../../../../../../contexts/mode.context';
import { CompanyMerchantListQuery } from './query';

const CompanyMerchantList = () => {
  const can = useCan();
  const mode = useMode();
  const { companyId } = useParams();

  const { data: { merchantCompany } = { merchantCompany: null}, loading} = useQuery(CompanyMerchantListQuery, {
    variables: {
      companyId
    }
  });

  const [visible, setVisible] = useState(false);
  const [demoVisible, setDemoVisible] = useState(false);

  const showCreationModal = () => {
    setVisible(true);
  };

  const hideCreationModal = () => {
    setVisible(false);
  };

  const dataSource = merchantCompany?.merchantAccounts.map(
    // eslint-disable-next-line camelcase
    ({ id, name, status, cms_name, cms_version, userCount, source }) => ({
      key: id,
      name,
      status,
      cmsName: cms_name,
      cmsVersion: cms_version,
      userCount,
      source,
    }),
  );

  const columns = [
    {
      title: 'Merchant account',
      dataIndex: 'name',
      key: 'name',
      render: (name, { key }) => <Link to={`/merchant-accounts/${key}`}>{name}</Link>,
      ...getColumnFilterSearchInput((value, record) => search(value, record.name)),
      sorter: (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value) => {
        if (value === 'active') return <Tag color="success">Active</Tag>;
        if (value === 'inactive') return <Tag color="default">Inactive</Tag>;
        return null;
      },
      filters: [
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
      ],
      onFilter: (value, record) => value === record.status,
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      render: (value) => value && <Tag color={SOURCES[value].color}>{SOURCES[value].label}</Tag>,
      sorter: compareByProp('source'),
    },
    {
      dataIndex: 'userCount',
      key: 'userCount',
      render: (value) => <Tag icon={<TeamOutlined />}>{value}</Tag>,
      sorter: compareByProp('userCount'),
    },
    {
      title: 'CMS',
      dataIndex: 'cmsName',
      key: 'cmsName',
      render: (value) => getCms(value).label,
      filters: cmsList.map((cms) => ({ value: cms.value, text: cms.label })),
      onFilter: (value, record) => value === record.cmsName,
    },
  ];

  // return info tile
  return (
    <>
      <TableTile
        name="company-merchant-accounts"
        title="Merchant Accounts"
        columns={columns}
        dataSource={dataSource}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} accounts`,
        }}
        size="small"
        loading={loading}
        extra={[
          can('create', 'merchant_account') && !loading && (
            <>
              {['localhost', 'dev', 'staging', 'sandbox'].includes(process.env.REACT_APP_ENVIRONMENT) && mode === MODES.INTERNAL ? (
                <Button key="createDemo" icon={<PlusOutlined />} onClick={() => setDemoVisible(true)}>
                  Create demo account
                </Button>
              ) : null}
              <Button key="create" icon={<PlusOutlined />} onClick={showCreationModal}>
                Create account
              </Button>
            </>
          ),
        ]}
      />
      <MerchantAccountCreate
        visible={visible}
        onCancel={hideCreationModal}
        companyId={merchantCompany?.id}
        programManager={merchantCompany?.programManager.id}
      />
      {['localhost', 'dev', 'staging', 'sandbox'].includes(process.env.REACT_APP_ENVIRONMENT) && mode === MODES.INTERNAL ? (
        <MerchantAccountDemoCreate
          visible={demoVisible}
          onClose={() => setDemoVisible(false)}
          companyId={merchantCompany?.id}
          programManagerId={merchantCompany?.programManager.id}
        />
      ) : null}
    </>
  );
};

export default CompanyMerchantList;

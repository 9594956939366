import React, { useContext, useEffect } from 'react';
import { Form, Select } from 'antd';
import { useLazyQuery as useApolloLazyQuery } from '@apollo/client/react/hooks/useLazyQuery';
import { MerchantContractContext } from '../../../MerchantContractDrawer';
import { contractsByAccountQuery } from '../../../query';
import { useMerchantAccount } from '../../../../../../merchantAccount.context';
import { ContractEditionContext } from '../../../../../PaymentNetwork';

const { Item } = Form;

const MerchantContractRiskAssessment = () => {
  const { merchantContract, updateMerchantContract } = useContext(MerchantContractContext);
  const { isEdited } = useContext(ContractEditionContext);
  const { selectedMerchantAccount } = useMerchantAccount();
  const [getRiskPartners, { loading, data }] = useApolloLazyQuery(contractsByAccountQuery);

  useEffect(() => {
    if (selectedMerchantAccount) {
      getRiskPartners({
        variables: {
          merchantAccountId: selectedMerchantAccount,
        },
      });
    }
  }, [selectedMerchantAccount]);

  return (
    <Item label="Risk assessment">
      <Select
        disabled={!isEdited}
        value={merchantContract?.risk_assessment_contract?.id || merchantContract?.risk_assessment_managed}
        onChange={(value) => {
          let input;
          let optimistic;
          if (value === 'byPsp') {
            input = {
              risk_assessment_managed: value,
              risk_assessment_contract: null,
            };
            optimistic = input;
          } else if (value === null) {
            input = {
              risk_assessment_managed: null,
              risk_assessment_contract: null,
            };
            optimistic = input;
          } else {
            input = {
              risk_assessment_managed: 'by3rdParty',
              risk_assessment_contract: value,
            };
            optimistic = {
              risk_assessment_managed: 'by3rdParty',
              risk_assessment_contract: data?.merchantAccount?.merchant_contracts.find(
                (merchantContract) => merchantContract.id === value,
              ),
            };
          }
          updateMerchantContract({
            variables: {
              input,
            },
            optimisticResponse: {
              updateContract: {
                ...merchantContract,
                ...optimistic,
              },
            },
          });
        }}
        options={[
          ...(merchantContract.partner.risk_assessment_service === 'embedded_not_deactivable' ? [] : [{
            value: null,
            label: 'None',
          }]),
          {
            value: 'byPsp',
            label: 'Managed by PSP',
          },
          ...(data?.merchantAccount?.merchant_contracts
            ?.filter((merchantContract) => merchantContract.type === 'risk_assessment')
            ?.map((merchantContract) => ({
              value: merchantContract.id,
              label: merchantContract.partner.name,
            })) ?? []),
        ]}
        loading={loading}
      />
    </Item>
  );
};

export default MerchantContractRiskAssessment;

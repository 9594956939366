/**
 * FieldType
 * The field type will define how to filter on a field.
 */
const FieldTypes = {
  ASYNC_LIST: 'async_list', // multiple select from async fetch field.asyncOptions [query, formatOption]
  BOOLEAN: 'boolean', // true - false - undefined
  DATE: 'date', // date range picker : from + to
  LIST: 'list', // multiple select from field.options
  NUMBER: 'number', // range input : min + max
  AMOUNT: 'amount', // range input : min + max
  TEXT: 'text', // multiple select tag
  CUSTOM: 'custom', // custom : no filter
  COUNT: 'count', // count : button, no filter
};

export default FieldTypes;

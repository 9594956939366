import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Drawer, List } from 'antd';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import ErrorBoundary from '../../../Common/ErrorBoundary/ErrorBoundary';
import { Block } from '../../../Common/Sider';
import styles from '../../Common/SetUpDrawer/SetUpDrawer.module.scss';
import { DashboardContext, useDashboards } from '../dashboard.context';
import DashboardEditDrawer from './DashboardEditDrawer/DashboardEditDrawer';
import DashboardListItem from './DashboardListItem';
import DashboardShareDrawer from './DashboardShareDrawer/DashboardShareDrawer';
import { useMessages } from '../../../../../contexts/messages/messages.context';
import { useCan } from '../../../../../contexts/ability.context';
import { isModeMerchant } from '../../../../../constants/mode';

const SetUpContent = ({ onClose }) => {
  const can = useCan();

  const { ack, messagesByType } = useMessages();
  useEffect(() => {
    ack(messagesByType('dashboard_shared').map((message) => message.id));
  }, [messagesByType('dashboard_shared')]);

  const {
    favorite,
    dashboards,
    companyDashboards,
    removeCompanyDashboard,
    refetchCompanyDashboards,
    addDashboard,
    removeDashboard,
    setSelectedDashboardId,
    selectedDashboardId,
  } = useDashboards();

  const { selectedWidgetId } = useContext(DashboardContext);

  const [editMode, setEditMode] = useState(!!selectedWidgetId);
  const [shareDashboard, setShareDashboard] = useState();

  useEffect(() => {
    if (selectedWidgetId) setEditMode(true);
  }, [selectedWidgetId]);

  const getDashboardIndex = useCallback(
    (index) => (dashboards.find((d) => d.name === `New Dashboard ${index}`) ? getDashboardIndex(index + 1) : index),
    [dashboards],
  );

  const handleAddDashboard = (
    dashboard = {
      name: `New Dashboard ${getDashboardIndex(1)}`,
      layout: [],
      widgets: {},
    },
    copy = false,
  ) => {
    addDashboard(dashboard, copy).then(({ data }) => {
      setEditMode(true);
      const newDashboardList = data.addDashboard.brainpower.dashboard.dashboards;
      setSelectedDashboardId(newDashboardList[newDashboardList.length - 1].id);
    });
  };

  return (
    <ErrorBoundary>
      <Block key="my-dashboards" title="My dashboards" description="Analyze and edit dashboards" collapsable>
        <List className={styles.list} bordered={false} loading={!dashboards}>
          {[...dashboards]
            ?.sort((a, b) => {
              switch (favorite) {
                case a.id:
                  return -1;
                case b.id:
                default:
                  return 1;
              }
            })
            .map((dashboard) => (
              <DashboardListItem
                key={dashboard.id}
                dashboard={dashboard}
                isActive={dashboard.id === selectedDashboardId}
                isFavorite={dashboard.id === favorite}
                editable
                onEdit={(e) => {
                  e.stopPropagation();
                  setSelectedDashboardId(dashboard.id);
                  setEditMode(true);
                }}
                duplicable
                onDuplicate={(e) => {
                  e.stopPropagation();
                  handleAddDashboard({
                    name: `Copy of ${dashboard.name}`,
                    layout: dashboard.layout,
                    widgets: dashboard.widgets,
                  });
                }}
                shareable={can('share', 'dashboard')}
                onShare={(e) => {
                  e.stopPropagation();
                  setSelectedDashboardId(dashboard.id)
                  setShareDashboard(dashboard);
                }}
                removable={dashboard.id !== favorite}
                onRemove={(e) => {
                  e.stopPropagation();
                  removeDashboard(dashboard.id);
                  if (dashboard.id === selectedDashboardId) setSelectedDashboardId(favorite);
                }}
                onClick={() => setSelectedDashboardId(dashboard.id)}
              />
            ))}
        </List>
        <Button className={styles.addViewBtn} block icon={<PlusOutlined />} onClick={() => handleAddDashboard()}>
          ADD DASHBOARD
        </Button>
      </Block>
      {isModeMerchant && can('read', 'company-dashboard') && (
        <Block
          key="company-dashboards"
          title="Company dashboards"
          description="Explore business analysis"
          collapsable
          extra={
            <Button title="Refresh" icon={<SyncOutlined />} type="text" onClick={() => refetchCompanyDashboards()} />
          }
        >
          <List className={styles.list} bordered={false}>
            {companyDashboards.map((dashboard) => (
              <DashboardListItem
                key={dashboard.id}
                dashboard={dashboard}
                isActive={dashboard.id === selectedDashboardId}
                isFavorite={dashboard.id === favorite}
                onEdit={(e) => {
                  e.stopPropagation();
                  setSelectedDashboardId(dashboard.id);
                  setEditMode(true);
                }}
                duplicable
                onDuplicate={(e) => {
                  e.stopPropagation();
                  handleAddDashboard(
                    {
                      name: `Copy of ${dashboard.name}`,
                      layout: dashboard.layout,
                      widgets: dashboard.widgets,
                    },
                    true,
                  );
                }}
                removable={can('delete', 'company-dashboard') && dashboard.id !== favorite}
                onRemove={(e) => {
                  e.stopPropagation();
                  removeCompanyDashboard(dashboard.id);
                  if (dashboard.id === selectedDashboardId) setSelectedDashboardId(favorite);
                }}
                onClick={() => setSelectedDashboardId(dashboard.id)}
              />
            ))}
          </List>
        </Block>
      )}
      <DashboardEditDrawer open={editMode} onClose={onClose} onBack={() => setEditMode(false)} />
      <DashboardShareDrawer
        open={!!shareDashboard}
        dashboard={shareDashboard}
        onClose={onClose}
        onBack={() => setShareDashboard(false)}
      />
    </ErrorBoundary>
  );
};

const SetUpDrawer = ({ open, onClose }) => (
  <Drawer
    width={470}
    open={open}
    getContainer="#dashboard"
    style={{ position: 'absolute', zIndex: 50 }}
    bodyStyle={{ padding: 0 }}
    onClose={onClose}
    destroyOnClose
    title="Display"
    closable
    closeIcon={<WindowCloseIcon />}
    mask={false}
    push={false}
  >
    <SetUpContent onClose={onClose} />
  </Drawer>
);

export default SetUpDrawer;

import React, { useState } from 'react';
import moment from 'moment-timezone';
import { useList } from 'react-use';
import { Button, Drawer, Form, List } from 'antd';
import { PlusCircleOutlined, StarFilled } from '@ant-design/icons';
import CogIcon from '@2fd/ant-design-icons/lib/Cog';
import TrashCanIcon from '@2fd/ant-design-icons/lib/TrashCan';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import { useGdpr } from '../../../../../contexts/me.context';
import { compareByProp } from '../../../../../util/array';
import { Block } from '../../../Common/Sider';
import MultiSelectDropdown from '../../../Common/MultiSelectDropdown/MultiSelectDropdown';
import usePage from '../../hooks/usePage';
import useSearchFilters, { useDefaultSearch } from '../../hooks/useSearchFilters';
import { useBrainpowerSearchSettings } from '../../hooks/useBrainpowerSettings';
import FieldTypes from '../constants/fieldTypes';
import targetEntities from '../constants/targetEntities';
import FormItemBuilder from './FormItemBuilder/FormItemBuilder';
import SavedSearchEditDrawer from './SavedSearchEditDrawer/SavedSearchEditDrawer';
import SavedSearchSaveDrawer from './SavedSearchSaveDrawer/SavedSearchSaveDrawer';
import styles from './SearchDrawer.module.scss';
import DrawerListItem from '../DrawerListItem/DrawerListItem';
import DateFilterItemBuilder from './DateFilterItemBuilder/DateFilterItemBuilder';

/**
 * Clean field value
 * - AMOUNT/NUMBER : remove null/undefined from value object
 */
const cleanFieldValue = (field, value) => {
  let cleanValue;
  switch (field.type) {
    case FieldTypes.AMOUNT:
    case FieldTypes.NUMBER:
    case FieldTypes.COUNT:
      if (value === '__NULL__') {
        return value;
      }
      if (value?.min == null && value?.max == null) return undefined;
      cleanValue = {};
      if (value.min != null) cleanValue.min = value.min;
      if (value.max != null) cleanValue.max = value.max;
      return cleanValue;
    default:
      return value;
  }
};

const SearchDrawerContent = ({ containerId, onClose }) => {
  const isGdpr = useGdpr();

  const page = usePage();

  const defaultSearch = useDefaultSearch();

  const { searchFilters, dateFilter, setAll, unsetAll } = useSearchFilters();

  const [form] = Form.useForm();

  const [formFilterList, { push, removeAt, set }] = useList(Object.keys(searchFilters ?? {}));

  const [{ favorite, searches }, update] = useBrainpowerSearchSettings();

  const addFilter = (fieldKey) => {
    push(fieldKey);
    form.setFieldValue(fieldKey, { value: page.fields[fieldKey].filterOptions.defaultValue, excluded: false });
  };

  const removeFilter = (fieldKey, index) => {
    removeAt(index);
    form.setFieldValue(fieldKey, undefined);
  };

  const reset = () => {
    set(Object.keys(page.search.requiredSearchFilters ?? {}));
    form.setFieldsValue(page.search.requiredSearchFilters ?? {});
    unsetAll();
  };

  const handleFinish = (values) => {
    const { dateFilter: newDateFilter = {}, ...newSearchFilters } = values;
    setAll({
      dateFilter: newDateFilter,
      searchFilters: Object.keys(newSearchFilters).reduce(
        (memo, fieldKey) => ({
          ...memo,
          [fieldKey]: {
            ...values[fieldKey],
            value: cleanFieldValue(page.fields[fieldKey], values[fieldKey].value),
          },
        }),
        {},
      ),
    });
  };

  const applySearch = (search) => () => {
    set(Object.keys(search.searchFilters ?? {}));
    form.setFieldsValue({ dateFilter: search.dateFilter, ...search.searchFilters });
    setAll({ dateFilter: search.dateFilter, searchFilters: search.searchFilters });
    update({
      favorite,
      searches: searches.map((searchElem) =>
        searchElem._id === search._id
          ? {
              ...searchElem,
              lastUse: moment().toISOString(),
            }
          : searchElem,
      ),
    });
  };

  const [editSearch, setEditSearch] = useState();
  const [saveSearch, setSaveSearch] = useState();

  const renderListItem = (search) => (
    <DrawerListItem
      key={search.id}
      onClick={applySearch(search)}
      label={search.label}
      extra={[
        <Button
          key="edit"
          type="text"
          icon={<CogIcon />}
          title="Edit saved view"
          onClick={(e) => {
            e.stopPropagation();
            setEditSearch(search);
          }}
        />,
      ]}
      pinned={favorite === search._id}
    />
  );

  return [
    <Block key="saved-searchs" title="Saved searches" description="Click to apply a saved search" collapsable>
      <List className={styles.list} bordered={false}>
        {[defaultSearch, ...searches]
          .sort((a, b) => {
            switch (favorite) {
              case a._id:
                return -1;
              case b._id:
                return 1;
              default:
                return b.lastUse > a.lastUse ? 1 : -1;
            }
          }) // pinned first then sorted by last use
          .map((search) => renderListItem(search))}
      </List>
    </Block>,
    <Block key="search-form" title="Search" description="Select fields and values">
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        initialValues={{
          dateFilter,
          ...searchFilters,
        }}
      >
        {dateFilter?.key && <DateFilterItemBuilder key={dateFilter.key} fieldKey={dateFilter.key} />}
        {formFilterList.map((fieldKey, index) => (
          <FormItemBuilder key={fieldKey} fieldKey={fieldKey} onRemove={() => removeFilter(fieldKey, index)} />
        ))}
        <Form.Item>
          <MultiSelectDropdown
            options={Object.values(page.fields)
              .filter((f) => f.filter)
              .filter((f) => isGdpr || !f.gdpr)
              .filter((f) => page.searchEntities.includes(f.entity))
              .sort(compareByProp('label'))
              .map((field) => ({
                key: field.key,
                label: field.filterLabel ?? field.label,
                icon: targetEntities[field.entity]?.icon,
              }))}
            onSelect={addFilter}
            value={[...formFilterList, ...(dateFilter?.key ? [dateFilter.key] : [])]}
          >
            <Button block icon={<PlusCircleOutlined />} style={{ margin: '20px 0' }}>
              ADD FILTER
            </Button>
          </MultiSelectDropdown>
          <div className={styles.filterButtons}>
            <Button
              block
              type="default"
              htmlType="submit"
              icon={<StarFilled />}
              onClick={() => {
                const { dateFilter: newDateFilter, ...newSearchFilters } = form.getFieldsValue();
                setSaveSearch({
                  dateFilter: newDateFilter,
                  searchFilters: newSearchFilters,
                });
              }}
            >
              SAVE SEARCH
            </Button>
            <Button block type="default" icon={<TrashCanIcon />} onClick={reset}>
              RESET FILTERS
            </Button>
          </div>
          <Button block type="primary" htmlType="submit">
            SEARCH
          </Button>
        </Form.Item>
      </Form>
    </Block>,
    <SavedSearchEditDrawer
      key="saved-search-edit-drawer"
      search={editSearch}
      open={!!editSearch}
      containerId={containerId}
      onClose={onClose}
      onBack={() => setEditSearch(undefined)}
    />,
    <SavedSearchSaveDrawer
      key="saved-search-save-drawer"
      search={saveSearch}
      open={!!saveSearch}
      containerId={containerId}
      onClose={onClose}
      onBack={() => setSaveSearch(undefined)}
    />,
  ];
};

const SearchDrawer = ({ open, onClose, containerId }) => (
  <Drawer
    key={`search-${containerId}`}
    width={470}
    open={open}
    getContainer={`#${containerId}`}
    style={{ position: 'absolute', zIndex: 50 }}
    bodyStyle={{ padding: 0 }}
    onClose={onClose}
    destroyOnClose
    title="Search"
    closable
    closeIcon={<WindowCloseIcon />}
    mask={false}
    push={false}
  >
    <SearchDrawerContent containerId={containerId} onClose={onClose} />
  </Drawer>
);

export default SearchDrawer;

import {gql} from '@apollo/client';

export const CompanyActivityQuery = gql`
  query CompanyActivityQuery($companyId: ID!) {
    merchantCompany(id: $companyId) {
      id
      dateStart
      attributeListRequiredByPartners
      activity
      specialization
      mcc
      website
      averageBasket
      turnover
      legalForm
    }
  }
`;

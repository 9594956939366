import React from 'react';
import Icon from '@ant-design/icons';

const RoutingSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="currentColor">
    <path
      d="M15,0V2.5H13.75a2.177,2.177,0,0,0-1.95.95L8.275,7.9,4.45,3.45A2.325,2.325,0,0,0,2.5,2.5H0V5H2.5c-.125,0,
      .025.1.075.075L6.65,9.85,2.5,15H0v2.5H2.5a2.177,2.177,0,0,0,1.95-.95l3.825-4.775,4.15,4.775a2.325,2.325,0,0,
      0,1.95.95H15V20l5-3.75L15,12.5V15h-.55c-.025-.025-.125-.1-.15-.075L9.925,9.775,13.75,5H15V7.5l5-3.75L15,0Z"
      transform="translate(6 6)"
    />
  </svg>
);

const RoutingIcon = (props) => <Icon component={RoutingSvg} {...props} />;

export default RoutingIcon;

import React from 'react';
import Lottie from 'react-lottie-player';
import classnames from 'classnames';
import styles from './LordIcon.module.scss';
import bundleLoader from '../../bundle-loader';

const icons = bundleLoader.importFiles();

const LordIcon = ({
  classes,
  autoplay,
  loop,
  type,
  animationData,
  onClick,
  onComplete,
  onLoopComplete,
  onMouseEnter,
  onMouseLeave,
  height,
  width,
  isStopped,
  color,
}) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <div
    className={classnames(styles.root, {
      [styles.clickable]: onClick,
      ...classes,
    })}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <Lottie
      className={classnames(styles.lottie, {
        [styles.colored]: !!color,
      })}
      loop={loop}
      autoPlay={autoplay}
      animationData={type ? icons[type] : animationData}
      style={{
        width,
        height,
        ...(color && {
          fill: color,
          stroke: color,
        }),
      }}
      play={!isStopped}
      onLoopComplete={onLoopComplete}
      onComplete={onComplete}
    />
  </div>
);

LordIcon.defaultProps = {
  autoplay: false,
  isStopped: true,
  loop: false,
  width: 28,
  height: 28,
};

export default LordIcon;

import React from 'react';
import { Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import TableTile, { renderAmount, renderCopyable } from '../../../Tile/Table/Table';
import { orderBasketDetailsQuery, activityBasketDetailsQuery } from './query';
import BasketDetailsSkeleton from './BasketDetailsSkeleton';
import styles from './BasketDetails.module.scss';

const BasketDetails = ({ onHeightReady }) => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(
    activityBasketDetailsQuery,
    {
      variables: {
        orderId,
      },
    },
  );

  const { data: { order: orderStack } = { order: null } } = useQuery(orderBasketDetailsQuery, {
    variables: {
      orderId,
    },
    onCompleted: (data) => !data.order && fetchInsights(),
  });

  const order = orderStack ?? orderInsights;

  if (!order) return <BasketDetailsSkeleton />;

  const columns = [
    {
      title: 'Product ID',
      dataIndex: 'product_id',
      key: 'product_id',
      render: renderCopyable,
    },
    {
      title: 'Name',
      dataIndex: 'product_name',
      key: 'product_name',
      render: (value, context) => <Tooltip title={context.product_description}>{value}</Tooltip>,
    },
    {
      title: 'Quantity',
      dataIndex: 'product_quantity',
      key: 'product_quantity',
      render: (value) => (value ? `x${value}` : ''),
    },
    {
      title: 'Unit price',
      key: 'product_price',
      dataIndex: 'product_price',
      render: renderAmount,
      align: 'right',
    },
    // {
    //   title: 'Type',
    //   key: 'product_type',
    //   dataIndex: 'product_type',
    // },
    {
      title: 'Tax',
      key: 'product_tax_amount',
      dataIndex: 'product_tax_amount',
      render: renderAmount,
      align: 'right',
    },
    {
      title: 'Amount',
      key: 'total_amount',
      dataIndex: 'total_amount',
      render: renderAmount,
      align: 'right',
    },
  ];

  const dataSource = order.baskets?.map((a) => ({ key: a.id, currency: order.currency, ...a }));

  // const expandedRowRender = (value) => {
  //   const subColumns = [
  //     {
  //       title: 'Seller ID',
  //       key: 'seller_id',
  //       dataIndex: 'seller_id',
  //     },
  //     {
  //       title: 'Seller name',
  //       key: 'seller_name',
  //       dataIndex: 'seller_name',
  //     },
  //     {
  //       title: 'Seller commission',
  //       key: 'seller_commission',
  //       dataIndex: 'seller_commission',
  //       render: renderAmount,
  //     },
  //   ];
  //
  //   return <Table columns={subColumns} dataSource={[value]} pagination={false} />;
  // };

  return (
    <TableTile
      name="basket-details"
      title="Basket details"
      columns={columns}
      dataSource={dataSource}
      // TODO - expandable rows for details
      // expandable={{
      //   expandedRowRender,
      //   defaultExpandAllRows: true,
      //   expandIconColumnIndex: -1,
      // }}
      onHeightReady={onHeightReady}
      rowClassName={styles.tableRow}
      scroll={{ x: 1000 }}
    />
  );
};

export default BasketDetails;

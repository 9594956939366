import React from 'react';
import { useMutation } from '@apollo/client';
import { Form, Input, message, Modal, Select, Switch } from 'antd';
import { WebhookAddMutation } from '../query';
import { TRANSACTION_STATUS_LIST } from '../../../../../../../constants/TRANSACTION_STATUSES';
import { useResetFormOnCloseModal } from '../../../../../Common/Form/utils';

const initialValues = { active: true, is_public: false };

const WebhookCreate = ({ visible, onClose, merchantAccount }) => {
  const [create, { loading }] = useMutation(WebhookAddMutation, {
    onCompleted: () => {
      message.success('Webhook successfully created');
      onClose();
    },
    onError: (err) => message.error(err.message),
  });

  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const onOk = () => {
    form.submit();
  };

  const submit = ({ active, ...values }) => {
    create({
      variables: {
        input: {
          ...values,
          status: active ? 'active' : 'disabled',
        },
        merchantAccountId: merchantAccount.id,
      },
    });
  };

  return (
    <Modal title="Add Webhook" open={visible} onOk={onOk} onCancel={onClose} confirmLoading={loading}>
      <Form form={form} layout="vertical" name="webhookForm" onFinish={submit} initialValues={initialValues}>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="url" label="URL" rules={[{ required: true }, { type: 'url', warningOnly: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="trigger" label="Triggers" rules={[{ required: true }]}>
          <Select mode="multiple" options={TRANSACTION_STATUS_LIST} allowClear />
        </Form.Item>
        <Form.Item name="active" label="Is active ?" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name="notify_last_transaction_only" label="Notify last transaction only?" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default WebhookCreate;

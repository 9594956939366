import {gql} from '@apollo/client';
import {splitterFragment} from '../query';

export const removeDraftMutation = gql`
    ${splitterFragment()}
    mutation RemoveDraft($id: ID!) {
        removeDraft(id: $id) {
            id
            name
            status
            fullEntrySplitter
            draft_splitter {
                ...SplitterRecursive
            }
            merchant_account {
                id
            }
        }
    }
`;
import { gql } from '@apollo/client';

const query = `
  _id
  last_transaction {
    _id
    amount
    payment_method_name
    order_id
    currency
    status
    authentication_result
    risk_assessment_result
  }
`;

export const orderLastTransactionQuery = gql`
  query OrderLastTransaction($orderId: ID!) {
    order(id: $orderId) {
      ${query}   
    }
  }
`;

export const activityLastTransactionQuery = gql`
  query ActivityLastTransaction($orderId: ID!) {
    activity(id: $orderId) {
      source
      ${query}
    }
  }
`;

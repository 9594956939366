import React from 'react';
import { useMutation } from '@apollo/client';
import { Form, Input, message, Modal, Switch } from 'antd';
import { MetaProgramManagerApiKeyAddMutation } from '../query';
import { useResetFormOnCloseModal } from '../../../../../Common/Form/utils';

const MetaProgramManagerApiKeyCreate = ({ visible, onClose, id }) => {
  const [create, { loading }] = useMutation(MetaProgramManagerApiKeyAddMutation, {
    onCompleted: () => {
      message.success('Api key successfully created');
      onClose();
    },
    onError: (err) => message.error(err.message),
  });

  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const onOk = () => {
    form.submit();
  };

  const submit = (values) => {
    create({
      variables: {
        input: {
          name: values.name,
          status: values.active ? 'active' : 'inactive',
        },
        metaProgramManagerId: id,
      },
    });
  };

  return (
    <Modal title="Add Api Key" open={visible} onOk={onOk} onCancel={onClose} confirmLoading={loading}>
      <Form form={form} layout="vertical" name="apiKeyForm" onFinish={submit} initialValues={{ active: true }}>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="active" label="Is active" valuePropName="checked" tooltip="Api Keys are active by default.">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MetaProgramManagerApiKeyCreate;

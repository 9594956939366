import React, { useContext } from 'react';
import { Form, Input, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { MerchantContractContext } from '../../../MerchantContractDrawer';
import styles from './MerchantContractId.module.scss';

const { Item } = Form;

const MerchantContractId = () => {
  const { merchantContract } = useContext(MerchantContractContext);

  return (
    <Item
      key="merchantContractId"
      id="merchantContractId"
      label={
        <div className={styles.labelContainer}>
          <div>Merchant contract ID</div>
          <CopyOutlined
            className={styles.copyIcon}
            onClick={() => navigator.clipboard.writeText(merchantContract?.id).then(() => message.success('Copied !'))}
          />
        </div>
      }
      className={styles.root}
    >
      <Input value={merchantContract?.id} disabled />
    </Item>
  );
};

export default MerchantContractId;

import {gql} from '@apollo/client';

export const MetaProgramManagersQuery = gql`
  {
    metaProgramManagers {
      id
      name
    }
  }
`;

export const AttachMetaProgramManagerMutation = gql`
  mutation AttachMetaProgramManager ($id: ID!, $metaProgramManagerId: ID!) {
    attachMetaProgramManager (id: $id, metaProgramManagerId: $metaProgramManagerId) {
      id
      metaProgramManager {
        id
      }
    }
  }
`;
import { gql } from '@apollo/client';

export const updateMerchantContractConfiguration = gql`
  mutation UpdateContractConfiguration($id: ID!, $input: MerchantContractUpdateInput!) {
    updateContract(id: $id, input: $input) {
      id
      configuration
    }
  }
`;

import React from 'react';
import { Select, Tag } from 'antd';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FormItemText = ({ field, ...formItemProps }) => (
  <Select
    mode="tags"
    tokenSeparators={[',', ';', '\n']}
    tagRender={({ value }) => (value === '__NULL__' ? <Tag>Null</Tag> : <Tag>{value}</Tag>)}
    maxTagCount="responsive"
    maxTagPlaceholder={(v) => <span title={v.map(({ value }) => value).join(', ')}>+{v.length}</span>}
    allowClear
    options={[{ value: '__NULL__', label: <Tag>Null</Tag> }]}
    {...formItemProps}
  />
);

export default FormItemText;

import React from 'react';
import moment from 'moment-timezone';
import { Link, useParams } from 'react-router-dom';
import { Alert, Avatar, Button, Col, Layout, message, Modal, Row, Space, Switch, Tag, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useMutation, useQuery } from '@apollo/client';
import {
  ApartmentOutlined,
  CodeOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  IdcardOutlined,
  LockFilled,
  UnlockOutlined,
} from '@ant-design/icons';
import DomainIcon from '@2fd/ant-design-icons/lib/Domain';
import { UserViewQuery, UserDisabledUpdateMutation, UserDeleteMutation, UserAuditLogsQuery } from './query';
import styles from './UserView.module.scss';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import HandleApolloError from '../../../Common/HandleApolloError/HandleApolloError';
import AnchorList from '../../../Common/AnchorList/AnchorList';
import Profile from './Profile/Profile';
import Company from './Company/Company';
import AuditLogs from '../../../Common/Tile/AuditLogs/AuditLogs';
import { initials } from '../../../../../util/string';
import { useCan } from '../../../../../contexts/ability.context';
import { getUserStatus } from '../../../../../constants/USER_STATUSES';
import ProgramManagerAvatar from '../../../Common/ProgramManager/Avatar';
import Loader from '../../../Common/Loader/Loader';
import useNavigateWithSearch from '../../../../../util/navigate';
import { SEND_PASSWORD_RESET_EMAIL } from '../../../../../App/AppUnlogged/RecoverPassword/RecoverPassword';

const { Content, Sider } = Layout;
const { confirm } = Modal;

const EmailVerificationAlert = ({ user }) => {
  const [sendPasswordResetEmail] = useMutation(SEND_PASSWORD_RESET_EMAIL);
  const handleClickResetPassword = () => {
    confirm({
      title: 'Are you sure you want to reset the password?',
      icon: <ExclamationCircleOutlined />,
      content: <Space direction="vertical">An email will be send at {user.email} to reset the password.</Space>,
      okText: 'APPLY AND PROCEED',
      okType: 'danger',
      cancelText: 'CANCEL',
      onOk: () => {
        sendPasswordResetEmail({ variables: { email: user.email }, refetchQueries: ['UserViewQuery'] });
      },
    });
  };

  if (!user.email_verification_token || !user.email_verification_token_expires_at) return null;
  if (moment() > moment(user.email_verification_token_expires_at)) {
    return (
      <Alert
        className={styles.alert}
        message="Password creation URL is expired."
        description="Please contact the support team."
        type="warning"
        action={<Button onClick={handleClickResetPassword}>RESET PASSWORD</Button>}
      />
    );
  }
  return (
    <Alert
      className={styles.alert}
      message="Password creation required"
      description={
        <div>
          <Typography.Paragraph>
            The user will be inactive until he set his password. Please share him this URL.
          </Typography.Paragraph>
          <Typography.Paragraph copyable>
            {`https://${user.programManager.merchantDomain}/set-password?email_verification_token=${user.email_verification_token}`}
          </Typography.Paragraph>
          <Typography.Paragraph>
            This URL will expire&nbsp;
            {moment(user.email_verification_token_expires_at).fromNow()}.
          </Typography.Paragraph>
        </div>
      }
      type="warning"
    />
  );
};

const UserDisabledInfo = ({ user }) => {
  if (!user?.disabled || !user?.activated) return null;

  if (!user.disabledBy) {
    return (
      <div className={styles.description}>
        Locked by&nbsp;
        <b title="Potentials reasons : user failed 3 login attempts">Security</b>
        &nbsp;on&nbsp;
        <b>{moment(user.disabledAt).format('LLL')}</b>.
      </div>
    );
  }

  return (
    <div className={styles.description}>
      Locked by&nbsp;
      {user.disabledBy.avatar && <Avatar src={user.disabledBy.avatar} size={12} />}
      &nbsp;
      <b>{user.disabledBy.full_name}</b>
      &nbsp;on&nbsp;
      <b>{moment(user.disabledAt).format('LLL')}</b>.
    </div>
  );
};

const UserStatusTag = ({ user }) => {
  const status = getUserStatus(user?.status);
  if (user?.deleted) {
    return (
      <Tag key="deleted-tag" color="volcano">
        Deleted
      </Tag>
    );
  }
  if (user?.disabled) {
    return (
      <Tag key="disabled-tag" color="volcano">
        Locked
      </Tag>
    );
  }
  return (
    <Tag key="status-tag" color={status.color}>
      {status.label}
    </Tag>
  );
};

const breadcrumbRender = (id, name) => () => (
  <Breadcrumb
    map={{
      '/': <HomeOutlined />,
      '/users': 'User List',
      [`/users/${id}`]: name,
    }}
  />
);

const AsyncLogs = () => {
  const { userId } = useParams();
  const { data, loading } = useQuery(UserAuditLogsQuery, {
    variables: {
      id: userId,
    },
  });
  return loading ? <Loader /> : <AuditLogs entity={data?.merchantUser} />;
};

const UserView = () => {
  const navigate = useNavigateWithSearch();
  const { userId } = useParams();
  const can = useCan();

  const { data, loading, error } = useQuery(UserViewQuery, {
    variables: {
      id: userId,
    },
  });

  // UserDisabledUpdateMutation
  const [updateUserDisabled, { loading: loadingDisabled }] = useMutation(UserDisabledUpdateMutation, {
    // onCompleted: (res) => message.success(res.updateUserDisabled?.disabled ? 'User successfully disabled' : 'User successfully enabled'),
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      message.error('An error occurred, please try again later.');
    },
  });

  const [deleteUser, deleteUserState] = useMutation(UserDeleteMutation, {
    refetchQueries: ['UserListQuery'],
    onCompleted: () => {
      message.success('User successfully deleted');
      navigate('/users');
    },
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      message.error('An error occurred, please try again later.');
    },
  });

  if (loading) {
    return (
      <PageHeader title="..." breadcrumbRender={breadcrumbRender(userId, '...')} onBack={() => navigate('/users')}>
        <Loader size="large" />
      </PageHeader>
    );
  }

  const { merchantUser: user } = data;

  const anchors = [
    {
      hash: '#tile-user-profile',
      icon: IdcardOutlined,
      label: 'Profile',
    },
    ...(user.companies?.map((c) => ({
      hash: `#tile-user-company-${c.company.id}`,
      icon: DomainIcon,
      label: c.company.name,
    })) || []),
    {
      hash: '#tile-entity-logs',
      icon: CodeOutlined,
      label: 'Logs',
    },
  ];

  const handleChangeDisabled = (value) =>
    updateUserDisabled({
      variables: { id: userId, disabled: !value },
    });

  const handleDelete = () => {
    Modal.confirm({
      title: `Delete ${user.full_name}`,
      content: 'Are you sure you want to delete this user?',
      onOk: () =>
        deleteUser({
          variables: { id: userId },
        }),
    });
  };

  return (
    <PageHeader
      key={userId}
      title={user.full_name}
      breadcrumbRender={breadcrumbRender(user.id, user.full_name)}
      onBack={() => navigate('/users')}
      tags={[<UserStatusTag user={user} />]}
      avatar={{ src: user.avatar, children: initials(user.full_name) }}
      subTitle={user.email}
      extra={[
        !user?.deleted && (
          <Switch
            key="switch-disabled"
            checked={!user.disabled}
            onChange={handleChangeDisabled}
            checkedChildren={<UnlockOutlined />}
            unCheckedChildren={<LockFilled />}
            title={user.disabled ? 'Unlock user' : 'Lock user'}
            disabled={!can('update-disabled', 'user') || !user.activated}
            loading={loadingDisabled}
          />
        ),
        can('delete', 'user') && !user?.deleted && (
          <Button
            key="delete-user"
            title="Delete user"
            icon={<DeleteOutlined />}
            shape="circle"
            onClick={handleDelete}
            loading={deleteUserState.loading}
          />
        ),
        <ProgramManagerAvatar key="program-manager-avatar" programManager={user.programManager} />,
        <Link to={`/companies/${user.companies[0]?.company?.id}`}>
          <Avatar
            shape="square"
            title={user.companies[0]?.company?.name}
            src={user.companies[0]?.company?.theme?.icon}
            icon={<ApartmentOutlined />}
            size="small"
            style={{ backgroundColor: user.companies[0]?.company?.theme?.secondaryColor }}
          />
        </Link>,
      ]}
    >
      <HandleApolloError error={error}>
        <UserDisabledInfo user={user} />
        <EmailVerificationAlert user={user} />
        <Layout className={styles.subPage}>
          <Sider className={styles.anchorSider} width={24}>
            <AnchorList anchors={anchors} />
          </Sider>
          <Content>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Profile user={user} />
              </Col>
              {user.companies?.map((c) => (
                <Col key={c.id} xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Company userCompany={c} />
                </Col>
              ))}
              <Col span={24}>
                <AsyncLogs />
              </Col>
            </Row>
          </Content>
        </Layout>
      </HandleApolloError>
    </PageHeader>
  );
};

export default UserView;

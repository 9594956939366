import React from 'react';
import periods from '../../../../constants/periods';
import FilterTag from './FilterTag';

const DateFilterTag = ({ value, ...filterTagProps }) => {
  const { from, to, period } = value;
  return (
    <FilterTag {...filterTagProps}>
      {period ? periods()[period].label : `${from} to ${to}`}
      &nbsp;
    </FilterTag>
  );
};

export default DateFilterTag;

import React from 'react';
import { Skeleton, Timeline } from 'antd';
import styles from './Timeline.module.scss';

const TimelineSkeleton = () => (
  <div className={styles.root}>
    <div key="head">
      <div className={styles.block}>
        <Skeleton.Avatar size={68} active />
        <div className={styles.blockCard} style={{ borderColor: 'grey' }}>
          <div>
            <Skeleton.Button
              style={{
                height: 16,
                width: 200,
              }}
              active
            />
          </div>
          <div>
            <Skeleton.Button
              style={{
                height: 23,
                width: 36,
                marginRight: 8,
                marginBottom: 8,
              }}
              active
            />
            <Skeleton.Button
              style={{
                height: 22,
                width: 127,
              }}
              active
            />
          </div>
          <div>
            <Skeleton.Button
              style={{
                height: 20,
                width: 60,
              }}
              active
            />
          </div>
        </div>
      </div>
      <Timeline className={styles.timeline}>
        {new Array(4).fill(undefined).map((i, index) => (
          <Timeline.Item key={`ti-${index + 1}`} dot={<Skeleton.Avatar size={36} active />}>
            <div key="itemContent" className={styles.itemContent}>
              <div className={styles.itemTitle}>
                <Skeleton.Button
                  style={{
                    height: 20,
                    width: 146,
                  }}
                  active
                />
              </div>
              <div key="itemCard" className={styles.itemCard}>
                <div key="itemCardTitle" className={styles.itemCardTitle}>
                  <Skeleton.Button
                    style={{
                      height: 22,
                      width: 160,
                      marginBottom: 8,
                    }}
                    active
                  />
                </div>
                <div key="itemCardInfo" className={styles.itemCardInfo}>
                  <Skeleton.Button
                    style={{
                      height: 20,
                      width: 60,
                    }}
                    active
                  />
                </div>
              </div>
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  </div>
);

export default TimelineSkeleton;

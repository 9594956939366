import { gql } from '@apollo/client';

export const metaProgramManagerThemeQuery = gql`
  query MetaProgramManagerTheme($id: ID!) {
    metaProgramManager(id: $id) {
      id
      theme {
        favicon
        logo
        icon
        background
        loader {
          img
          type
          animation
        }
        primaryColor
        secondaryColor
      }
    }
  }
`;

export const MetaProgramManagerThemeUpdate = gql`
  mutation MetaProgramManagerThemeUpdate($id: ID!, $theme: CompanyThemeInput!) {
    updateMetaProgramManagerTheme(id: $id, theme: $theme) {
      id
      theme {
        favicon
        logo
        icon
        loader {
          img
          type
          animation
        }
        primaryColor
        secondaryColor
      }
    }
  }
`;

export const MetaProgramManagerThemeImgUpload = gql`
  mutation MetaProgramManagerThemeImgUpload($id: ID!, $type: PmImgType!, $file: Upload) {
    uploadMetaProgramManagerThemeImg(id: $id, type: $type, file: $file) {
      id
      theme {
        favicon
        logo
        icon
        loader {
          img
          type
          animation
        }
        primaryColor
        secondaryColor
      }
    }
  }
`;

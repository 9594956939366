import React, { useState } from 'react';
import { Button, message, Select, Switch, Table, Tag } from 'antd';
import {
  ArrowRightOutlined,
  CheckOutlined,
  DeleteOutlined,
  PlusOutlined,
  RightOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { useCan } from '../../../../../../contexts/ability.context';
import { cmsList, getCms } from '../../../../../../constants/CMSS';
import { compareByProp } from '../../../../../../util/array';
import { Link } from '../../../../../../util/navigate';
import { search } from '../../../../../../util/string';
import InfoTile, {
  InfoTextRow,
  InfoLinkRow,
  InfoBooleanRow,
  BaseRow,
} from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import ConnectAsGuest from '../../../Company/CompanyView/ConnectAsGuest/ConnectAsGuest';
import { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import { CompanyProfileListQuery } from '../../../AccessControl/query';
import {
  CompanyUserAuthorizedMerchantAccountMutation,
  CompanyUserIsGdprMutation,
  CompanyUserProfileMutation,
} from '../../../Company/query';
import useToggle from '../../../../../../hooks/useToggle';
import styles from '../UserView.module.scss';

const Company = ({ userCompany }) => {
  const can = useCan();

  const profilesQuery = useQuery(CompanyProfileListQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { companyId: userCompany.company.id },
  });

  const [updateCompanyUserProfile] = useMutation(CompanyUserProfileMutation, {
    variables: {
      id: userCompany.id,
    },
    onCompleted: () => {
      message.success('User profile successfully updated.');
    },
    onError: () => {
      message.error('An error occurred, please try again later.');
    },
  });

  const handleChangeProfile = (profileId) => {
    updateCompanyUserProfile({
      variables: {
        aclProfile: profileId,
      },
    });
  };

  const [updateCompanyUserIsGdpr] = useMutation(CompanyUserIsGdprMutation, {
    variables: {
      id: userCompany.id,
    },
    onCompleted: () => {
      message.success('User GDPR access successfully updated.');
    },
    onError: () => {
      message.error('An error occurred, please try again later.');
    },
  });

  const handleChangeIsGdpr = (isGdpr) => {
    updateCompanyUserIsGdpr({
      variables: {
        isGdpr,
      },
    });
  };

  const [showUserSelect, { toggle }] = useToggle(false);
  const [selectedAccount, setSelectedAccount] = useState();

  const [updateCompanyUser] = useMutation(CompanyUserAuthorizedMerchantAccountMutation, {
    variables: { id: userCompany.id },
  });

  const submitAccount = () => {
    updateCompanyUser({
      variables: { merchantAccount: selectedAccount, toAdd: true },
    });
    setSelectedAccount(null);
    toggle();
  };

  const dataSource = userCompany.authorizedMerchantAccounts
    .map(
      // eslint-disable-next-line camelcase
      ({ id, name, status, cms_name, cms_version, userCount }) => ({
        key: id,
        name,
        status,
        cmsName: cms_name,
        cmsVersion: cms_version,
        userCount,
      }),
    )
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

  const columns = [
    {
      title: 'Merchant account',
      dataIndex: 'name',
      key: 'name',
      render: (name, { key }) => <Link to={`/merchant-accounts/${key}`}>{name}</Link>,
      ...getColumnFilterSearchInput((value, record) => search(value, record.name)),
      sorter: (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value) => {
        if (value === 'active') return <Tag color="success">Active</Tag>;
        if (value === 'inactive') return <Tag color="default">Inactive</Tag>;
        return null;
      },
      filters: [
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
      ],
      onFilter: (value, record) => value === record.status,
    },
    {
      title: 'Users',
      dataIndex: 'userCount',
      key: 'userCount',
      render: (value) => <Tag icon={<TeamOutlined />}>{value}</Tag>,
      sorter: compareByProp('userCount'),
    },
    {
      title: 'CMS',
      dataIndex: 'cmsName',
      key: 'cmsName',
      render: (value) => getCms(value).label,
      filters: cmsList.map((cms) => ({ value: cms.value, text: cms.label })),
      onFilter: (value, record) => value === record.cmsName,
    },
  ];

  if (can('manage-users', 'company')) {
    columns.push({
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 80,
      render: (value, { key: merchantAccountId }) => [
        <Button
          key="remove-user-access"
          icon={<DeleteOutlined />}
          onClick={() => {
            updateCompanyUser({
              variables: { merchantAccount: merchantAccountId, toAdd: false },
            });
          }}
          title="Remove account access"
          shape="circle"
          type="text"
        />,
      ],
    });
  }

  return (
    <InfoTile
      name={`user-company-${userCompany.company.id}`}
      title={userCompany.company.name}
      extra={[
        can('create', 'guest_user') && <ConnectAsGuest key="connect-as-guest" company={userCompany.company} />,
        <Link key="link-company" to={`/companies/${userCompany.company?.id}`}>
          <Button shape="text" icon={<ArrowRightOutlined />} />
        </Link>,
      ]}
    >
      <InfoTextRow label="Company ID" value={userCompany.company?.id} />
      {can('update-rights', 'user') ? (
        <BaseRow label="Profile">
          <Select
            value={userCompany.aclProfile.id}
            loading={profilesQuery.loading}
            options={profilesQuery.data?.merchantCompany?.programManager.merchantAclProfiles.map((p) => ({
              value: p.id,
              label: p.name,
            }))}
            onChange={handleChangeProfile}
            bordered={false}
            dropdownMatchSelectWidth={false}
          />
        </BaseRow>
      ) : (
        <InfoLinkRow
          label="Profile"
          value={userCompany.aclProfile.name}
          to={`/access-control-list/${userCompany.aclProfile.id}`}
          disabled={!can('update', 'access-control')}
        />
      )}
      {can('update-rights', 'user') ? (
        <BaseRow label="Access personal data">
          <Switch size="small" checked={userCompany.isGdpr} onChange={handleChangeIsGdpr} />
        </BaseRow>
      ) : (
        <InfoBooleanRow label="Access personal data" value={userCompany.isGdpr} />
      )}
      {can('manage-users', 'company') && (
        <div className={styles.addAccounts}>
          <Button key="add-accounts" icon={showUserSelect ? <RightOutlined /> : <PlusOutlined />} onClick={toggle}>
            Merchant accounts
          </Button>
          {showUserSelect && [
            <Select
              key="select-user"
              options={userCompany.company.merchantAccounts
                .filter(
                  ({ id: cmaid }) => !userCompany.authorizedMerchantAccounts.find(({ id: maid }) => cmaid === maid),
                )
                .map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              style={{ width: 300 }}
              maxTagCount={1}
              onChange={setSelectedAccount}
              showSearch
              optionFilterProp="label"
            />,
            <Button
              key="submit-accounts"
              shape="circle"
              icon={<CheckOutlined />}
              type="primary"
              size="small"
              onClick={submitAccount}
              disabled={!selectedAccount}
            />,
          ]}
        </div>
      )}
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 5,
          pageSizeOptions: [5, 10, 20, 50, 100],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} accounts`,
        }}
        size="small"
      />
    </InfoTile>
  );
};

export default Company;

import React from 'react';
import { Typography, Badge } from 'antd';
import styles from '../AppUnlogged.module.scss';
import { useProgramManager } from "../../../contexts/programManager.context";
import LoginForm from './LoginForm/LoginForm';
import LoginSso from './LoginSso/LoginSso';
import { isModeAdmin } from '../../../constants/mode';
import {useMetaProgramManager} from '../../../contexts/metaProgramManager.context';

const env = {
  prod: 'Production',
  sandbox: 'Sandbox',
  staging: 'Staging',
  dev: 'Development',
  localhost: 'Localhost',
}[process.env.REACT_APP_ENVIRONMENT];

const Login = ({ redirectAfter }) => {
  const programManager = useProgramManager();
  const metaProgramManager = useMetaProgramManager();

  const useSso = ((metaProgramManager ?? programManager)?.loginMethod === 'sso' || (!programManager && !metaProgramManager)) && isModeAdmin;

  return (
    <Badge.Ribbon text={env}>
      <div className={styles.block}>
        <Typography.Title level={4}>Login</Typography.Title>
        <div
          className={styles.loginContent}
          style={useSso ? {
            flexGrow: 1,
          } : {}}
        >
          {useSso ? (
            <LoginSso
              redirectAfter={redirectAfter}
            />
          ) : (
            <LoginForm
              redirectAfter={redirectAfter}
            />
          )}
        </div>
      </div>
    </Badge.Ribbon>
  );
};

export default Login;

module.exports = {
  importFiles: () => {
    const context = require.context('./assets/icons/used/', false, /\.json/);

    const icons = {};

    context.keys().forEach((key) => {
      const iconName = key
        .split('./')
        .pop()
        .replace(/[\d]+-/, '')
        .replace('.json', '');
      icons[iconName] = context(key);
    });
    return icons;
  },
};

import { gql } from '@apollo/client';

const query = `{
  _id
  giftcard_used
  giftcard_currency
  giftcard_amount
  giftcard_number
}`;

export const orderVoucherQuery = gql`
  query OrderVoucher($orderId: ID!) {
    order(id: $orderId) 
    ${query}
  }
`;

export const activityVoucherQuery = gql`
  query ActivityVoucher($orderId: ID!) {
    activity(id: $orderId) 
    ${query}
  }
`;
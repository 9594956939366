import { gql } from '@apollo/client';

export const ProgramManagerUserAuthorizationManagementQuery = gql`
  query ProgramManagerUserAuthorizationManagementQuery($id: ID!) {
    programManagerUser(id: $id) {
      id
      isGdpr
      hasAllCompaniesAccess
      aclProfile {
        id
        name
        roles {
          privileges {
            action
            subject
          }
        }
      }
      authorizedMerchantCompanies {
        id
        name
        theme {
          primaryColor
        }
        merchantAccounts {
          id
          name
        }
      }
      authorizedMerchantAccounts {
        id
        name
      }
      authorizedMerchantGroups {
        id
        name
        description
        merchantCompanies {
          id
          name
          theme {
            primaryColor
          }
          merchantAccounts {
            id
            name
          }
        }
        merchantAccounts {
          id
          name
        }
      }
      programManager {
        id
        name
        programManagerAclProfiles {
          id
          name
        }
        merchantCompanies {
          id
          name
          theme {
            logo
            icon
            primaryColor
            secondaryColor
          }
          merchantAccounts {
            id
            name
          }
        }
        merchantGroups {
          id
          name
          description
          merchantCompanies {
            id
            name
            theme {
              primaryColor
            }
          }
          merchantAccounts {
            id
            name
          }
        }
      }
    }
  }
`;

export const ProgramManagerUserAclProfileUpdateMutation = gql`
  mutation ProgramManagerUserAclProfileUpdateMutation($id: ID!, $aclProfile: ID!) {
    updateRightsOfProgramManagerUser(id: $id, aclProfile: $aclProfile) {
      id
      aclProfile {
        id
        name
      }
    }
  }
`;

export const ProgramManagerUserAllCompaniesAccessUpdateMutation = gql`
  mutation ProgramManagerUserAllCompaniesAccessUpdateMutation($id: ID!, $hasAllCompaniesAccess: Boolean!) {
    updateProgramManagerUserAllCompaniesAccess(id: $id, hasAllCompaniesAccess: $hasAllCompaniesAccess) {
      id
      hasAllCompaniesAccess
    }
  }
`;

export const ProgramManagerUserGdprUpdateMutation = gql`
  mutation ProgramManagerUserGdprUpdateMutation($id: ID!, $isGdpr: Boolean!) {
    updateProgramManagerUserGdpr(id: $id, isGdpr: $isGdpr) {
      id
      isGdpr
    }
  }
`;

export const AddMerchantCompanyAuthorizationMutation = gql`
  mutation AddMerchantCompanyAuthorizationMutation($id: ID!, $merchantCompanyId: ID!) {
    addMerchantCompanyAuthorization(id: $id, merchantCompanyId: $merchantCompanyId) {
      id
      authorizedMerchantCompanies {
        id
        name
        theme {
          primaryColor
        }
        merchantAccounts {
          id
          name
        }
      }
    }
  }
`;
export const RemoveMerchantCompanyAuthorizationMutation = gql`
  mutation RemoveMerchantCompanyAuthorizationMutation($id: ID!, $merchantCompanyId: ID!) {
    removeMerchantCompanyAuthorization(id: $id, merchantCompanyId: $merchantCompanyId) {
      id
      authorizedMerchantCompanies {
        id
        name
        theme {
          primaryColor
        }
        merchantAccounts {
          id
          name
        }
      }
    }
  }
`;
export const AddMerchantAccountAuthorizationMutation = gql`
  mutation AddMerchantAccountAuthorizationMutation($id: ID!, $merchantAccountId: ID!) {
    addMerchantAccountAuthorization(id: $id, merchantAccountId: $merchantAccountId) {
      id
      authorizedMerchantAccounts {
        id
        name
      }
    }
  }
`;
export const RemoveMerchantAccountAuthorizationMutation = gql`
  mutation RemoveMerchantAccountAuthorizationMutation($id: ID!, $merchantAccountId: ID!) {
    removeMerchantAccountAuthorization(id: $id, merchantAccountId: $merchantAccountId) {
      id
      authorizedMerchantAccounts {
        id
        name
      }
    }
  }
`;

export default {};

import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Avatar, Button, Space, Table, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { DeploymentUnitOutlined, EditOutlined, HomeOutlined, PlusOutlined } from '@ant-design/icons';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { useCan } from '../../../../../contexts/ability.context';
import { PartnersQuery } from '../../query';
import { PartnerRouteListQuery } from './query';
import { partnerTypes } from '../../Partners/constants';
import PartnerRoutesCreate from '../PartnerRoutesCreate/PartnerRoutesCreate';
import styles from './PartnerRouteList.module.scss';
import PartnerRoutesUpdate from '../PartnerRoutesUpdate/PartnerRoutesUpdate';
import usePartnerRoutesColumns from '../../Common/usePartnerRoutesColumns';
import { compareByProp } from '../../../../../util/array';
import { Link } from '../../../../../util/navigate';
import ProgramManagerAvatar from '../../../Common/ProgramManager/Avatar';
import useStatePagination from '../../../Brainpower/hooks/useStatePagination';

const breadcrumbRender = () => (
  <Breadcrumb
    map={{
      '/': <HomeOutlined />,
      '/partner-routes': 'Partner routes',
    }}
  />
);

export const linkedMerchantContractsExpandedRowRender = (record) => (
  <div>
    <Typography.Title level={5}>Linked merchant contracts</Typography.Title>
    <Table
      showHeader={false}
      pagination={false}
      size="small"
      columns={[
        {
          dataIndex: 'merchantContractName',
          key: 'merchantContractName',
        },
        {
          dataIndex: 'merchantAccountName',
          key: 'merchantAccountName',
          render: (value, { merchantAccountId }) => <Link to={`/merchant-accounts/${merchantAccountId}`}>{value}</Link>,
        },
        {
          dataIndex: 'companyName',
          key: 'companyName',
          render: (value, { company }) => (
            <Link to={`/companies/${company?.id}`}>
              <Space>
                <Avatar src={company?.theme?.icon} size="small" />
                {value}
              </Space>
            </Link>
          ),
        },
        {
          dataIndex: 'programManager',
          key: 'programManager',
          render: (value, { programManager }) => <ProgramManagerAvatar programManager={programManager} />,
        },
      ]}
      dataSource={record.merchantContracts}
      style={{ maxWidth: '80%', margin: 'auto' }}
    />
  </div>
);

const PartnerRouteList = () => {
  const can = useCan();

  const { page, pageSize, setPage, offset, limit } = useStatePagination();

  const [filters, setFilters] = useState({});

  const handleChange = (_, newFilters) => setFilters(newFilters);

  const { data, previousData, loading } = useQuery(PartnerRouteListQuery, {
    variables: { filters, limit, offset },
    errorPolicy: 'all',
  });

  const partnersQuery = useQuery(PartnersQuery);

  const [visible, setVisible] = useState(false);
  const showCreationModal = () => setVisible(true);
  const hideCreationModal = () => setVisible(false);

  const [updateModalIsVisible, setUpdateModalVisible] = useState(false);
  const showUpdateModal = () => setUpdateModalVisible(true);
  const hideUpdateModal = () => setUpdateModalVisible(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const dataSource = (loading ? previousData : data)?.partnerRoutes.data.map((partnerRoute) => ({
    ...partnerRoute,
    key: partnerRoute.id,
    partnerId: partnerRoute.partner?.id,
    paymentMethod: partnerRoute.paymentMethodName,
    channel: partnerRoute.channel,
    authorizationCurrency: partnerRoute.authorization_currency,
    settlementCurrency: partnerRoute.settlement_currency,
    linkedContractCount: partnerRoute.merchantRoutes?.filter((mr) => !!mr.merchant_contract).length,
    merchantContracts: Object.values(
      partnerRoute.merchantRoutes
        ?.filter((mr) => !!mr.merchant_contract)
        .reduce(
          (memo, merchantRoute) => ({
            ...memo,
            [merchantRoute.merchant_contract?.id]: {
              key: merchantRoute.merchant_contract?.id,
              merchantContractName: merchantRoute.merchant_contract?.name,
              merchantAccountId: merchantRoute.merchant_account?.id,
              merchantAccountName: merchantRoute.merchant_account?.name,
              companyName: merchantRoute.merchant_account?.company?.name,
              company: merchantRoute.merchant_account?.company,
              programManager: merchantRoute.merchant_account?.programManager,
            },
          }),
          {},
        ),
    ),
  }));

  const columns = [
    {
      title: 'Route ID',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Partner',
      dataIndex: 'partnerId',
      key: 'partner',
      render: (partnerId, record) =>
        partnerId ? (
          <Space>
            <Avatar src={record.partner?.company?.theme?.icon} icon={<DeploymentUnitOutlined />} shape="square" />
            <Link to={`/partners/${partnerId}`}>{record.partner?.name}</Link>
          </Space>
        ) : (
          record.partner?.name
        ),
      filters: partnersQuery.data?.partners
        .map((partner) => ({
          value: partner.id,
          text: `[${partnerTypes[partner.type].label}] ${partner.name}`,
        }))
        .sort(compareByProp('text', 'asc', 'string')),
      filterSearch: true,
    },
    ...usePartnerRoutesColumns(),
  ];
  return (
    <PageHeader
      title="Partner Routes"
      breadcrumbRender={breadcrumbRender}
      extra={[
        can('update', 'partner-route') && selectedRowKeys.length > 0 && (
          <Button
            key="update-partner-routes"
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            onClick={showUpdateModal}
          >
            Edit selected routes
          </Button>
        ),
        can('create', 'partner-route') && (
          <Button
            key="create-partner-routes"
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            onClick={showCreationModal}
          >
            Create partner routes
          </Button>
        ),
      ]}
    >
      <Typography.Paragraph className={styles.description}>
        Routes are unique by Partner + Payment method + Channel + Authorization currency + Settlement currency.
      </Typography.Paragraph>
      <Typography.Paragraph className={styles.description}>
        Options are set for each routes or inherit from partner default routes options.
      </Typography.Paragraph>
      <Typography.Text className={styles.description}>
        {selectedRowKeys.length > 0 ? `Selected ${selectedRowKeys.length} routes.` : 'Select routes to edit.'}
      </Typography.Text>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        size="small"
        scroll={{ x: 'max-content' }}
        bordered
        onChange={handleChange}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 50,
          position: ['topRight', 'bottomRight'],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} routes`,
          onChange: setPage,
          current: page,
          pageSize,
          total: (loading ? previousData : data)?.partnerRoutes.count,
          loading,
        }}
        rowSelection={
          can('update', 'partner-route') && {
            selectedRowKeys,
            onChange: setSelectedRowKeys,
            fixed: true,
          }
        }
        expandable={
          can('read', 'merchant_account') && {
            expandedRowRender: linkedMerchantContractsExpandedRowRender,
            rowExpandable: (record) => record.merchantContracts?.length > 0,
            expandIconColumnIndex: columns.length - 1,
          }
        }
      />
      <PartnerRoutesCreate visible={visible} onClose={hideCreationModal} />
      {selectedRowKeys.length > 0 && (
        <PartnerRoutesUpdate
          key="partner-routes-update"
          visible={updateModalIsVisible}
          onClose={hideUpdateModal}
          selectedRouteIdList={selectedRowKeys}
        />
      )}
    </PageHeader>
  );
};

export default PartnerRouteList;

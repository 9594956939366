const CMSS = {
  magento: {
    value: 'magento',
    label: 'Magento',
  },
  prestashop: {
    value: 'prestashop',
    label: 'Prestashop',
  },
  shopify: {
    value: 'shopify',
    label: 'Shopify',
  },
  salesforce: {
    value: 'salesforce',
    label: 'SalesForce',
  },
  woocommerce: {
    value: 'woocommerce',
    label: 'WooCommerce',
  },
  etsy: {
    value: 'etsy',
    label: 'Etsy',
  },
  drupal: {
    value: 'drupal',
    label: 'Drupal',
  },
  bigcommerce: {
    value: 'bigcommerce',
    label: 'BigCommerce',
  },
  '3dcart': {
    value: '3dcart',
    label: '3dcart',
  },
  other: {
    value: 'other',
    label: 'Other',
  },
};

const defaultValue = {
  value: null,
  label: null,
  img: null,
};

export const cmsList = Object.values(CMSS);
export const getCms = (value) => (value && CMSS[value] ? CMSS[value] : defaultValue);

export default CMSS;

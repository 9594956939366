import React from 'react';
import {useLazyQuery, useQuery as useApolloQuery} from '@apollo/client';
import { useParams } from 'react-router-dom';
import InfoTile, { InfoAmountRow } from '../../../Tile/Info/Info';
import BalancesSkeleton from './BalancesSkeleton';
import { orderBalancesQuery, activityBalancesQuery } from './query';

const Balances = () => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(activityBalancesQuery, {
    variables: {
      orderId,
    }
  });

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(
    orderBalancesQuery,
    {
      variables: {
        orderId,
      },
      fetchPolicy: "cache-and-network",
      onCompleted: data => !data.order && fetchInsights(),
    },
  );

  const transaction = orderStack?.last_transaction ?? orderInsights?.last_transaction;

  if (!transaction) return <BalancesSkeleton />;

  return (
    <InfoTile name="balances" title="Balances">
      <InfoAmountRow label="Capture balance" value={transaction.capturable_amount} currency={transaction.currency} />
      <InfoAmountRow label="Refund balance" value={transaction.refundable_amount} currency={transaction.currency} />
    </InfoTile>
  );
};

export default Balances;

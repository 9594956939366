import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorBoundary from '../../Common/ErrorBoundary/ErrorBoundary';
import Page from '../../Common/Page/Page';
import ProgramManagerAccessControlView from './ProgramManagerAccessControlView/ProgramManagerAccessControlView';
import ProgramManagerAccessControlList from './ProgramManagerAccessControlList/ProgramManagerAccessControlList';
import ProgramManagerAccessControlOverview from './ProgramManagerAccessControlOverview/ProgramManagerAccessControlOverview';

const ProgramManagerAccessControl = () => (
  <Page>
    <ErrorBoundary>
      <Routes>
        <Route index element={<ProgramManagerAccessControlList />} />
        <Route path="overview" element={<ProgramManagerAccessControlOverview />} />
        <Route path=":profileId" element={<ProgramManagerAccessControlView />} />
      </Routes>
    </ErrorBoundary>
  </Page>
);

export default ProgramManagerAccessControl;

import React from 'react';
import moment from 'moment-timezone';
import { useMutation } from '@apollo/client';
import { Alert, Button, Form, Modal, Result, Space, Table, Typography } from 'antd';
import Lottie from 'react-lottie-player';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import validationAnimation from '../../../../../../assets/icons/used/000-validation-grey.json';
import { CANCEL_ORDER_MUTATION } from './query';

const CancelModal = ({ order, onClose }) => {
  const [cancel, { called, error, loading, data: cancelData }] = useMutation(CANCEL_ORDER_MUTATION, {
    variables: { id: order.id },
  });

  const [form] = Form.useForm();

  const columns = [
    {
      title: 'Transaction ID',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      render: (value) => value && <div>{moment(value).format('L LT')}</div>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 140,
      align: 'right',
      render: (value, { amount, currency }) => `${CURRENCIES[currency].symbol} ${amount.toFixed(2)}`,
    },
  ];

  const dataSource = [
    {
      transaction_id: order.last_transaction.id,
      date: order.last_transaction.createdAt,
      amount: order.last_transaction.amount,
      currency: order.last_transaction.currency,
    },
  ];

  const submit = () => {
    cancel();
  };

  if (called && !loading && !error) {
    return (
      <Modal destroyOnClose visible onCancel={onClose} footer={false}>
        <Result
          icon={
            <Lottie
              loop={false}
              animationData={validationAnimation}
              play
              style={{ width: 150, height: 150, margin: 'auto' }}
              onComplete={() => onClose()}
            />
          }
          status="success"
          title={
            cancelData.cancelOrder.last_transaction.status === 'cancel_successful' ? 'Cancelled' : 'Cancel requested'
          }
          subTitle={
            <Space>
              {order.last_transaction.amount.toFixed(2)}
              {CURRENCIES[order.last_transaction.currency].symbol}
            </Space>
          }
        />
      </Modal>
    );
  }

  return (
    <Modal
      destroyOnClose
      visible
      onCancel={onClose}
      width={664}
      footer={
        <Space>
          <Button type="text" onClick={onClose}>
            No
          </Button>
          <Button type="primary" size="large" loading={loading} onClick={form.submit}>
            Yes
          </Button>
        </Space>
      }
    >
      <Typography.Title level={1}>Cancel</Typography.Title>
      <Form form={form} layout="vertical" className="tile-form" onFinish={submit} size="large">
        <Form.Item label="Do you want to cancel this transaction ?">
          <Table
            columns={columns}
            dataSource={dataSource}
            rowClassName="ant-table-row-selected"
            size="middle"
            pagination={false}
            showHeader={false}
          />
        </Form.Item>
      </Form>
      {!!error && <Alert type="error" showIcon message="An error occurred, please try again later." />}
    </Modal>
  );
};

export default CancelModal;

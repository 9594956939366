export const contactTypes = [
  {
    label: 'Business',
    value: 'business',
  },
  {
    label: 'Support',
    value: 'support',
  },
  {
    label: 'Technical',
    value: 'technical',
  },
  {
    label: 'Customer Support',
    value: 'customerSupport',
  },
  {
    label: 'Financial',
    value: 'financial',
  },
  {
    label: 'Accounting',
    value: 'accounting',
  },
  {
    label: 'Executive',
    value: 'executive',
  },
];

import React from 'react';
import { Form, Radio, Space } from 'antd';
import { amountFormatList } from '../../../../../../constants/amountFormats';
import { dateFormatList } from '../../../../../../constants/dateFormats';
import FieldTypes from '../../constants/fieldTypes';

/**
 * ColumnSettingForm to customize display, formats, ...
 * @param field
 * @param column
 * @param onValuesChange
 * @returns {JSX.Element|null}
 * @constructor
 */
const ColumnSettingForm = ({ field, column, onValuesChange }) => {
  switch (field.type) {
    case FieldTypes.AMOUNT:
      return (
        <Form layout="vertical" onValuesChange={onValuesChange} initialValues={column}>
          <Form.Item name="format" label="Amount format">
            <Radio.Group>
              <Space direction="vertical">
                {amountFormatList.map((o) => (
                  <Radio key={o.value} value={o.value}>
                    {o.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      );
    case FieldTypes.DATE:
      return (
        <Form layout="vertical" onValuesChange={onValuesChange} initialValues={column}>
          <Form.Item name="format" label="Date format">
            <Radio.Group>
              <Space direction="vertical">
                {(field.displayOptions?.dateFormatList ?? dateFormatList).map((o) => (
                  <Radio key={o.value} value={o.value}>
                    {o.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      );
    default:
  }
  return null;
};

export default ColumnSettingForm;

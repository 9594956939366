import { gql } from '@apollo/client';

export const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password)
  }
`;

export const getIsSsoQuery = gql`
  query GetIsSso($email: String!) {
    getIsSso(email: $email)
  }
`;

export const SSO_SIGN_IN = gql`
    mutation SsoSignIn($token: String!, $tenantId: String) {
        ssoSignIn(token: $token, tenantId: $tenantId)
    }
`;
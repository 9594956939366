import React, { useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Slider,
  Space,
  Table,
  Typography,
} from 'antd';
import { useMutation } from '@apollo/client';
import { EditOutlined, ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { difference } from '../../../../../../util/array';
import { useCan } from '../../../../../../contexts/ability.context';
import InfoTile from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { PartnerUpdateMutation } from '../query';
import styles from '../PartnerView.module.scss';

const charOptions = {
  letter: { value: 'letter', label: 'Letter', sample: 'X' },
  digit: { value: 'digit', label: 'Digit', sample: '0' },
  symbol: { value: 'symbol', label: 'Symbol', sample: '#' },
};

const defaultGeneratorProperties = {
  prefix: '',
  suffix: '',
  len: 11,
  chars: ['letter', 'digit'],
};
const GeneratorPropertiesForm = ({ onChange, value = defaultGeneratorProperties }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const onSubmit = (_value) => {
    onChange(_value);
    setOpen(false);
  };
  const onCancel = () => {
    form.resetFields();
    setOpen(false);
  };
  return [
    <Button
      type="primary"
      size="small"
      onClick={() => {
        setOpen(true);
      }}
      icon={<EditOutlined />}
    />,
    <Modal
      open={open}
      title="Generator properties"
      okText="APPLY"
      cancelText="CANCEL"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSubmit(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="generator_properties_form" initialValues={value}>
        <Form.Item name="prefix" label="Prefix">
          <Input />
        </Form.Item>
        <Form.Item name="suffix" label="Suffix">
          <Input />
        </Form.Item>
        <Row>
          <Col flex={1}>
            <Form.Item name="len" label="Length" tooltip="Length of the generated string (excluding prefix and suffix)">
              <Slider defaultValue={11} min={8} max={20} style={{ minWidth: 120 }} dots />
            </Form.Item>
          </Col>
          <Col>
            {/* The label is a white space for alignment matters */}
            <Form.Item name="len" label=" ">
              <InputNumber
                min={1}
                max={20}
                defaultValue={11}
                formatter={(v) => `${v} chars`}
                parser={(v) => v.replace(' chars', '')}
                bordered={false}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="chars" label="Char types">
          <Checkbox.Group options={Object.values(charOptions)} />
        </Form.Item>
      </Form>
    </Modal>,
  ];
};

const GeneratorPropertiesSample = (props) => {
  const { prefix, suffix, len, chars } = { ...defaultGeneratorProperties, ...props };
  return `${prefix}${new Array(len)
    .fill('')
    .map((_, index) => charOptions[chars[index % chars.length]].sample)
    .join('')}${suffix}`;
};

const attributes = {
  'company.name': {
    label: 'Name',
    pathLabel: 'Company / Name',
    value: 'company.name',
    entity: 'Company',
  },
  'company.mainContact.email': {
    label: 'Email',
    pathLabel: 'Company / Contacts (main) / Email',
    value: 'company.mainContact.email',
    entity: 'Company',
  },
  'company.mainContact.phoneNumber': {
    label: 'Phone',
    pathLabel: 'Company / Contacts (main) / Phone',
    value: 'company.mainContact.phoneNumber',
    entity: 'Company',
  },
  'company.address': { label: 'Address', pathLabel: 'Company / Address', value: 'company.address', entity: 'Company' },
  'company.city': { label: 'City', pathLabel: 'Company / City', value: 'company.city', entity: 'Company' },
  'company.zipCode': {
    label: 'ZIP Code',
    pathLabel: 'Company / ZIP Code',
    value: 'company.zipCode',
    entity: 'Company',
  },
  'company.country': { label: 'Country', pathLabel: 'Company / Country', value: 'company.country', entity: 'Company' },
  'company.numberEmployee': {
    label: 'Number of employees',
    pathLabel: 'Company / Number of employees',
    value: 'company.numberEmployee',
    entity: 'Company',
  },
  'company.dateStart': {
    label: 'Start date',
    pathLabel: 'Company / Start date',
    value: 'company.dateStart',
    entity: 'Company',
  },
  'company.legalForm': {
    label: 'Legal form',
    pathLabel: 'Company / Legal form',
    value: 'company.legalForm',
    entity: 'Company',
  },
  'company.vatNumber': {
    label: 'VAT Number',
    pathLabel: 'Company / VAT Number',
    value: 'company.vatNumber',
    entity: 'Company',
  },
  'company.ape': { label: 'APE', pathLabel: 'Company / APE', value: 'company.ape', entity: 'Company' },
  'company.duns': { label: 'DUNS', pathLabel: 'Company / DUNS', value: 'company.duns', entity: 'Company' },
  'company.mcc': { label: 'MCC', pathLabel: 'Company / MCC', value: 'company.mcc', entity: 'Company' },
  'company.ceo': { label: 'CEO', pathLabel: 'Company / CEO', value: 'company.ceo', entity: 'Company' },
  'company.sharesHolder': {
    label: 'Shares holder',
    pathLabel: 'Company / Shares holder',
    value: 'company.sharesHolder',
    entity: 'Company',
  },
  'company.activity': {
    label: 'Activity',
    pathLabel: 'Company / Activity',
    value: 'company.activity',
    entity: 'Company',
  },
  'company.specialization': {
    label: 'Specialization',
    pathLabel: 'Company / Specialization',
    value: 'company.specialization',
    entity: 'Company',
  },
  'merchantAccount.name': {
    label: 'Name',
    pathLabel: 'Merchant account / Name',
    value: 'merchantAccount.name',
    entity: 'Merchant account',
  },
  'merchantAccount.cms_name': {
    label: 'CMS',
    pathLabel: 'Merchant account / CMS',
    value: 'merchantAccount.cms_name',
    entity: 'Merchant account',
  },
  'merchantAccount.cms_version': {
    label: 'CMS version',
    pathLabel: 'Merchant account / CMS version',
    value: 'merchantAccount.cms_version',
    entity: 'Merchant account',
  },
  'merchantAccount.accept_url': {
    label: 'Accept url',
    pathLabel: 'Merchant account / Accept url',
    value: 'merchantAccount.accept_url',
    entity: 'Merchant account',
  },
  'merchantAccount.decline_url': {
    label: 'Decline url',
    pathLabel: 'Merchant account / Decline url',
    value: 'merchantAccount.decline_url',
    entity: 'Merchant account',
  },
  'merchantAccount.pending_url': {
    label: 'Pending url',
    pathLabel: 'Merchant account / Pending url',
    value: 'merchantAccount.pending_url',
    entity: 'Merchant account',
  },
  'merchantAccount.exception_url': {
    label: 'Exception url',
    pathLabel: 'Merchant account / Exception url',
    value: 'merchantAccount.exception_url',
    entity: 'Merchant account',
  },
};

/**
 * Merchant Attribute Configuration
 * Configure some attributes of MerchantAccount & MerchantCompany that may be required by the Partner
 *
 * @param partner
 * @returns {JSX.Element}
 * @constructor
 */
const MerchantAttributeConfiguration = ({ partner }) => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);

  const initialConfigurationFields = Object.keys(partner.merchant_attribute_configuration ?? {}).reduce(
    (memo, key, index) => [
      ...memo,
      {
        key,
        ...partner.merchant_attribute_configuration[key],
        ...partner.merchant_attribute_configuration[key].sources,
        uniqKey: `${index}_${key}`,
      },
    ],
    [],
  );

  const [configurationFields, setConfigurationFields] = useState(initialConfigurationFields);

  // fields validation
  const errors = useMemo(
    () =>
      configurationFields.reduce(
        (memo, row, index) => [
          ...memo,
          ...(row.key === '' ? [{ index, message: `Missing key` }] : []),
          ...(!/^[\w-]+$/.test(row.key) ? [{ index, message: `Invalid format` }] : []),
          ...(configurationFields.filter(({ key }) => key === row.key).length > 1
            ? [{ index, message: `Duplicate key` }]
            : []),
          ...(row.type === 'Enum' && !(row.enum?.length > 0) ? [{ index, message: `Missing enum values` }] : []),
        ],
        [],
      ),
    [configurationFields],
  );

  const onCellChange = (rowIndex, key, value) => {
    setConfigurationFields((prevFields) =>
      prevFields.map((row, index) => (index === rowIndex ? { ...row, [key]: value } : row)),
    );
  };

  const [updatePartner, { loading }] = useMutation(PartnerUpdateMutation, {
    onCompleted: () => {
      message.success('Partner successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const onSubmit = (value) => {
    setConfigurationFields(
      Object.keys(value ?? {}).reduce((memo, key) => [...memo, { key, ...value[key], ...value[key].sources }], []),
    );
    return updatePartner({
      variables: {
        id: partner.id,
        input: {
          merchant_attribute_configuration: value,
        },
      },
    });
  };

  const handleFinish = () => {
    const newFieldsConfiguration = configurationFields.reduce(
      (memo, { key, stack, insights, insights_pos, ...field }) =>
        key === '' ? memo : { ...memo, [key]: { ...field, sources: { stack, insights, insights_pos } } },
      {},
    );

    const removedKeys = difference(
      Object.keys(partner.merchant_attribute_configuration ?? {}),
      Object.keys(newFieldsConfiguration),
    );

    // if a key is removed or updated, confirm before apply changes
    if (removedKeys.length > 0) {
      Modal.confirm({
        title: 'Are you sure you want to delete this keys?',
        icon: <ExclamationCircleOutlined />,
        content: [
          <Typography.Paragraph>
            You are about to delete this keys:
            <br />
            {removedKeys.map((k) => (
              <Typography.Text code strong ellipsis="rows" type="warning">
                {k}
              </Typography.Text>
            ))}
            .
          </Typography.Paragraph>,
          <Typography.Text>
            If you want to update the name of existing keys, please contact technical support.
          </Typography.Text>,
        ],
        okText: 'Yes',
        cancelText: 'Cancel',
        onOk: () => onSubmit(newFieldsConfiguration),
      });
    } else {
      onSubmit(newFieldsConfiguration);
    }
  };

  const addField = () => {
    setConfigurationFields([
      ...configurationFields,
      {
        key: `field_${configurationFields.length + 1}`,
        attribute: null,
        stack: 'optional',
        insights: 'optional',
        insights_pos: 'optional',
      },
    ]);
  };

  const removeField = (index) => setConfigurationFields(configurationFields.toSpliced(index, 1));

  const resetFields = () => setConfigurationFields(initialConfigurationFields);

  const renderTextCell = (key) => (value, row, index) => {
    if (editMode) {
      const handleChange = (e) => onCellChange(index, key, e.target.value);
      return <Input onBlur={handleChange} bordered={false} defaultValue={value} />;
    }
    return value;
  };
  const renderAttributeCell = (key) => (value, row, index) => {
    const { generatorProps } = row;

    if (editMode) {
      const handleChange = (_value) => onCellChange(index, key, _value);
      const handleChangeGeneratorProperties = (_value) => onCellChange(index, 'generatorProps', _value);
      return (
        <Space>
          <Select
            placeholder="Select an attribute"
            defaultValue={value}
            onChange={handleChange}
            options={[
              {
                label: 'Partner ID',
                options: [
                  { label: 'String', value: 'partner_id_string' },
                  { label: 'Generated', value: 'partner_id_generated' },
                ],
              },
              {
                label: 'Company',
                options: Object.values(attributes).filter((a) => a.entity === 'Company'),
              },
              {
                label: 'Merchant account',
                options: Object.values(attributes).filter((a) => a.entity === 'Merchant account'),
              },
            ]}
            optionLabelProp="pathLabel"
            bordered={false}
            style={{ minWidth: 240 }}
            showSearch
            optionFilterProp="label"
          />
          {value === 'partner_id_generated' && (
            <>
              <GeneratorPropertiesSample {...generatorProps} />
              <GeneratorPropertiesForm value={generatorProps} onChange={handleChangeGeneratorProperties} />
            </>
          )}
        </Space>
      );
    }

    switch (value) {
      case 'partner_id_string':
        return <span title="Field to be filled on merchant accounts">Partner ID (string)</span>;
      case 'partner_id_generated':
        return (
          <Space>
            <Typography.Text title="Field automatically generated on merchant accounts">
              Partner ID (generated)
            </Typography.Text>
            <Typography.Text italic disabled>
              <GeneratorPropertiesSample {...generatorProps} />
            </Typography.Text>
          </Space>
        );
      default:
        return attributes[value]?.pathLabel ?? value;
    }
  };

  const sourceModes = {
    required: {
      label: 'Required',
      value: 'required',
    },
    optional: {
      label: 'Optional',
      value: 'optional',
    },
    excluded: {
      label: 'Excluded',
      value: 'excluded',
    },
  };
  const renderSourceCell = (key) => (value, row, index) => {
    if (editMode) {
      const handleChange = (selectedValue) => onCellChange(index, key, selectedValue);
      return <Select value={value} onChange={handleChange} options={Object.values(sourceModes)} bordered={false} />;
    }
    return sourceModes[value]?.label;
  };

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      render: renderTextCell('key'),
      width: 240,
      fixed: 'left',
    },
    {
      title: 'Attribute',
      dataIndex: 'attribute',
      render: renderAttributeCell('attribute'),
    },
    ...(partner.allow_stack
      ? [
          {
            title: 'Stack',
            dataIndex: 'stack',
            width: 140,
            render: renderSourceCell('stack'),
          },
        ]
      : []),
    ...(partner.allow_insights
      ? [
          {
            title: 'Insights',
            dataIndex: 'insights',
            width: 140,
            render: renderSourceCell('insights'),
          },
        ]
      : []),
    ...(partner.allow_insights_pos
      ? [
          {
            title: 'Insights POS',
            dataIndex: 'insights_pos',
            width: 140,
            render: renderSourceCell('insights_pos'),
          },
        ]
      : []),
    ...(editMode
      ? [
          {
            dataIndex: 'actions',
            align: 'center',
            render: (_, __, index) => [
              <MinusCircleOutlined key="remove-field" onClick={() => removeField(index)} title="Remove field" />,
            ],
          },
        ]
      : []),
  ];

  return (
    <InfoTile
      name="merchant-attribute-configuration"
      title="Payment Facilitator's merchant attribute configuration"
      extra={[
        !editMode && can('update', 'partner') && (
          <Button key="update-partner" onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        ),
      ]}
    >
      {configurationFields?.length > 0 || editMode ? (
        <Row gutter={[12, 12]} justify="end">
          <Col span={24}>
            <Table
              size="small"
              bordered={false}
              dataSource={configurationFields}
              columns={columns}
              rowKey="uniqKey"
              rowClassName={(row, index) =>
                errors?.find((error) => error.index === index) ? styles.errorFieldRow : null
              }
              pagination={false}
            />
          </Col>
          {editMode && [
            <Col span={24}>
              <Button type="dashed" onClick={addField} block icon={<PlusOutlined />}>
                Add field
              </Button>
            </Col>,
            <Col span={24}>
              {errors.map((error) => (
                <div>
                  <Typography.Text type="danger">
                    #{error.index + 1} - {error.message}
                  </Typography.Text>
                </div>
              ))}
            </Col>,
            <Col>
              <Button
                onClick={() => {
                  resetFields();
                  setEditMode(false);
                }}
              >
                Cancel
              </Button>
            </Col>,
            <Col>
              <Button onClick={() => resetFields()}>Reset</Button>
            </Col>,
            <Col>
              <Button type="primary" onClick={handleFinish} loading={loading} disabled={errors.length > 0}>
                Save
              </Button>
            </Col>,
          ]}
        </Row>
      ) : (
        <Empty />
      )}
    </InfoTile>
  );
};

export default MerchantAttributeConfiguration;

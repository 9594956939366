import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Drawer, Form, Input, InputNumber, Row, Select, Slider, Space } from 'antd';
import { useMutation } from '@apollo/client';
import styles from './ContactForm.module.scss';
import { addCompanyContactMutation, updateCompanyContactMutation } from './query';
import { contactTypes } from '../constants';

const ContactForm = ({ visible, company, toggle, contact }) => {
  const [form] = Form.useForm();

  const [priorityLevel, setPriorityLevel] = useState(contact?.priorityLevel ?? 5);

  const [addContact, { loading: loadingCreate }] = useMutation(addCompanyContactMutation, {
    variables: {
      id: company.id,
    },
    refetchQueries: ['CompanyContacts'],
  });

  const [updateContact, { loading: loadingUpdate }] = useMutation(updateCompanyContactMutation, {
    variables: {
      id: company.id,
      contactId: contact?._id,
    },
    refetchQueries: ['CompanyContacts'],
  });

  useEffect(() => {
    form.initialValues = contact;
    form.resetFields();
    setPriorityLevel(contact?.priorityLevel ?? 5);
  }, [contact]);

  return (
    <Drawer
      title={contact ? 'Edit contact' : 'Add new Contact'}
      open={visible}
      onClose={toggle}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(input) => {
          if (contact) {
            updateContact({
              variables: {
                input: {
                  ...input,
                  priorityLevel,
                },
              },
            });
          } else {
            addContact({
              variables: {
                input: {
                  ...input,
                  priorityLevel,
                },
              },
              optimisticResponse: {
                addCompanyContact: {
                  ...company,
                  contacts: [
                    ...company.contacts,
                    {
                      _id: 'temp',
                      ...input,
                      priorityLevel,
                    },
                  ],
                },
              },
            });
          }
          form.resetFields();
          setPriorityLevel(5);
          toggle();
        }}
        initialValues={contact}
      >
        <Form.Item label="First name" name="firstname" rules={[{ required: true, message: '* required' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Last name" name="surname" rules={[{ required: true, message: '* required' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[{ required: true }, { type: 'email' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phoneNumber"
          rules={[
            {
              required: company.attributeListRequiredByPartners.includes('company.mainContact.phoneNumber'),
              message: 'Phone number is required by a partner configuration.',
            },
          ]}
        >
          <Input placeholder="123456789" maxLength={20} />
        </Form.Item>
        <Form.Item label="Title" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Type" name="type" rules={[{ required: true }]}>
          <Select options={contactTypes} />
        </Form.Item>
        <Form.Item label="Level" name="priorityLevel">
          <Row justify="space-between">
            <Col span={16}>
              <Slider
                marks={{
                  1: 'High',
                  10: 'Low',
                }}
                value={priorityLevel}
                step={1}
                min={1}
                max={10}
                onChange={(value) => setPriorityLevel(value)}
                included={false}
              />
            </Col>
            <Col span={6}>
              <InputNumber
                style={{
                  width: 60,
                }}
                min={1}
                max={10}
                step={1}
                value={priorityLevel}
                onChange={(value) => setPriorityLevel(value)}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="Main contact" name="main" valuePropName="checked" disabled={contact?.main}>
          <Checkbox disabled={contact?.main} />
        </Form.Item>
        <Form.Item className={styles.submitItem}>
          <Space>
            <Button onClick={toggle}>Cancel</Button>
            <Button onClick={() => form.resetFields()}>Reset</Button>
            <Button type="primary" htmlType="submit" loading={loadingUpdate || loadingCreate}>
              Save
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ContactForm;

import React from 'react';
import InfoTile, { InfoSkeletonRow } from '../../../Tile/Info/Info';

const OrderStatusSkeleton = () => (
  <InfoTile name="order-status" title="Order status">
    <InfoSkeletonRow label="Status" />
    <InfoSkeletonRow label="Merchant reference" />
    <InfoSkeletonRow label="... ID" />
    <InfoSkeletonRow label="Authorized amount" />
    <InfoSkeletonRow label="Captured amount" />
    <InfoSkeletonRow label="Scheduled capture date" />
    <InfoSkeletonRow label="Refunded amount" />
    <InfoSkeletonRow label="Charged back amount" />
    <InfoSkeletonRow label="Authentication status" />
    <InfoSkeletonRow label="Payment channel" />
    <InfoSkeletonRow label="Creation date" />
    <InfoSkeletonRow label="Last update" />
  </InfoTile>
);

export default OrderStatusSkeleton;

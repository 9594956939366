import React, { useCallback, useContext, useEffect, useState } from 'react';
import {Button, message} from 'antd';
import TrashCanIcon from "@2fd/ant-design-icons/lib/TrashCan";
import { useSearchParams } from 'react-router-dom';
import GeneralSettings from './GeneralSettings/GeneralSettings';
import ConnectionSettings from './ConnectionSettings/ConnectionSettings';
import ReconciliationSettings from './ReconciliationSettings/ReconciliationSettings';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import { MerchantContractContext } from '../MerchantContractDrawer';
import HandleRoutesModal from '../HandleRoutesModal/HandleRoutesModal';
import { ContractEditionContext } from '../../../PaymentNetwork';
import { useCan } from '../../../../../../../contexts/ability.context';
import BlockContent from "../../../../../Common/Sider/BlockContent/BlockContent";
import SOURCES from '../../../../../../../constants/SOURCES';

const ContractForm = ({ editedPaymentMethod, setEditedPaymentMethod, setForcedActiveColumns }) => {
  const can = useCan();
  const [searchParams] = useSearchParams();

  const { merchantContract, updateMerchantContract } = useContext(MerchantContractContext);
  const { isEdited } = useContext(ContractEditionContext);

  const [pmIssues, setPmIssues] = useState(null);

  const paymentMethodsRef = React.useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (editedPaymentMethod === false) {
      paymentMethodsRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }, [paymentMethodsRef]);

  const updateContractStatus = useCallback(
    (force) => {
      return updateMerchantContract({
       variables: {
         input: {
           status: 'deleted',
         },
         force,
       },
       update: (
         cache,
         {
           data: {
             updateContract: { id },
           },
         },
       ) => {
         const normalizedId = cache.identify({ id, __typename: 'MerchantContract' });
         cache.evict({ id: normalizedId });
         cache.gc();
       },
       onCompleted: () => {
         setForcedActiveColumns(searchParams.get('pn').split('-').slice(0, 3));
       },
     })
    },
    [merchantContract],
  );

  return (
    <>
      <GeneralSettings />
      {can('update', 'merchant_contract') && <ConnectionSettings />}
      {can('update', 'merchant_contract') && <ReconciliationSettings />}
      { merchantContract?.source === SOURCES.STACK ? (
        <div ref={paymentMethodsRef}>
          <PaymentMethods ref={paymentMethodsRef} setEditedPaymentMethod={setEditedPaymentMethod} />
        </div>
      ): null}
      <BlockContent>
        {isEdited && merchantContract?.has_authorized_transactions === false && (
          <Button
            block
            icon={<TrashCanIcon />}
            onClick={() =>
              updateContractStatus()
                .catch((e) => {
                  const error = e.graphQLErrors.find((ee) => ee.extensions.code === '409');
                  if (error.extensions.type === 'cashier') {
                    message.error(`This contract is used by cashier contract${error?.extensions?.issues.length > 1 ? 's' : ''}: ${error?.extensions?.issues.map(issue => issue.name ? issue.name : issue.id).join(', ')}`)
                  } else {
                    setPmIssues(error?.extensions?.issues)
                  }
                })
                .then(() => setIsLoading(false))
            }
            disabled={!isEdited || isLoading}
          >
            DELETE MERCHANT CONTRACT
          </Button>
        )}
      </BlockContent>
      <HandleRoutesModal
        issues={pmIssues}
        onCancel={() => setPmIssues(null)}
        onConfirm={async () => {
          await updateContractStatus(true);
          setPmIssues(null);
        }}
      />
    </>
  );
};

export default ContractForm;

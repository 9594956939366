import { gql } from '@apollo/client';

export const ProgramManagerUserCreateMutation = gql`
  mutation ProgramManagerUserCreate($input: ProgramManagerUserCreationInput!) {
    createProgramManagerUser(input: $input) {
      id
      full_name
      phone {
        code
        number
      }
      status
      aclProfile {
        id
        name
      }
    }
  }
`;

export const ProgramManagerQuery = gql`
  query ProgramManagerQuery($id: ID!) {
    programManager(id: $id) {
      __typename
      id
      name
      emailDomains
      programManagerAclProfiles {
        id
        name
      }
    }
  }
`;

export default {};

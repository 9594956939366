import React from 'react';
import { useQuery } from '@apollo/client';
import { Space } from 'antd';
import { Link } from '../../../../../../util/navigate';
import TableTile from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import ProgramManagerAvatar from '../../../../Common/ProgramManager/Avatar';
import { PartnerActiveOnProgramManagerQuery } from '../query';

const ActiveOnProgramManagers = ({ partnerId }) => {
  const { data, loading } = useQuery(PartnerActiveOnProgramManagerQuery, {
    variables: { id: partnerId },
  });

  const columns = [
    {
      title: 'Program manager',
      dataIndex: 'programManager',
      key: 'programManager',
      render: (programManager) => (
        <Link to={`/program-managers/${programManager.id}`}>
          <Space>
            <ProgramManagerAvatar programManager={programManager} noLink />
            {programManager.name}
          </Space>
        </Link>
      ),
    },
    {
      key: 'paymentMethods',
      dataIndex: 'paymentMethods',
      title: 'Payment methods',
      render: (paymentMethods) => (
        <Space>
          {paymentMethods.map((pm) => (
            <img key={pm.id} src={pm.imgUrl} alt="" style={{ width: 24 }} />
          ))}
        </Space>
      ),
    },
  ];

  return (
    <TableTile
      name="program-managers"
      title="Program Managers"
      dataSource={
        data?.partner.activeProgramManagers.map((apm) => ({
          programManagerId: apm.programManager.id,
          programManager: apm.programManager,
          paymentMethods: apm.paymentMethods,
        })) ?? []
      }
      columns={columns}
      loading={loading}
      rowKey="programManagerId"
    />
  );
};

export default ActiveOnProgramManagers;

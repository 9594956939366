import { gql } from '@apollo/client';

export const programManagerOptionsQuery = gql`
  query ProgramManagerOptions($id: ID!) {
    programManager(id: $id) {
      id
      displayName
      code
      merchantDomain
      adminDomain
      alternativeMerchantDomain
      alternativeAdminDomain
      apiDomain
      metaToken
      emailDomains
      notificationUrl
      website
      displayPartners
    }
  }
`;

export const updateProgramManagerOptionsMutation = gql`
  mutation UpdateProgramManagerOptions($id: ID!, $input: ProgramManagerOptionsInput!) {
    updateProgramManagerOptions(id: $id, input: $input) {
      id
      displayName
      code
      merchantDomain
      adminDomain
      alternativeMerchantDomain
      alternativeAdminDomain
      apiDomain
      metaToken
      emailDomains
      notificationUrl
      website
        displayPartners
    }
  }
`;

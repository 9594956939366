import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import { config as Indicators } from '../../constants/indicators';
import Units from '../../constants/units';
import WidgetOptions from '../../constants/widgetOptions';
import { config as WidgetTypes } from '../../constants/widgetTypes';

export default (divId, widget, data, args) => {
  const chart = am4core.create(divId, am4charts.XYChart);
  chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

  const { dimensions, indicators, id, options = {} } = widget;

  const widgetType = WidgetTypes.bubble;

  const indicatorsConfig = indicators.slice(0, widgetType.maxIndicators).map((i) => {
    if (Indicators[i]) {
      return Indicators[i];
    }
    throw new Error(`Indicator ${i} does not exist.`, id);
  });

  chart.data = data.data;
  chart.padding(10, 30, 0, 0);

  // default
  const valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());
  valueAxisX.renderer.ticks.template.disabled = true;
  valueAxisX.renderer.axisFills.template.disabled = true;
  valueAxisX.paddingTop = 10;
  valueAxisX.color = indicatorsConfig[0].color;

  valueAxisX.title.text = indicatorsConfig[0].label;
  if (indicatorsConfig[0].unit === Units.Percent) {
    valueAxisX.renderer.labels.template.adapter.add('text', (text, label) => `${label.dataItem.value}%`);
    valueAxisX.min = 0;
    valueAxisX.max = 100;
  }

  const valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxisY.renderer.ticks.template.disabled = true;
  valueAxisY.renderer.axisFills.template.disabled = true;
  valueAxisY.title.text = indicatorsConfig[1].label;
  valueAxisY.color = indicatorsConfig[1].color;

  // apply options
  if (options?.[WidgetOptions.logarithmicAxis]) {
    valueAxisY.logarithmic = true;
    valueAxisY.title.text = `${indicatorsConfig[1].label} (logarithmic axis)`;
  }

  const series = chart.series.push(new am4charts.LineSeries());
  series.dataFields = {
    title: dimensions[0],
    valueX: indicators[0],
    valueY: indicators[1],
    value: indicators[2],
    ...indicatorsConfig.reduce((memo, i) => ({ ...memo, [i.id]: i.id }), {}),
  };
  series.strokeOpacity = 0;
  series.sequencedInterpolation = true;

  series.tooltip.background.cornerRadius = 0;
  series.tooltip.background.strokeOpacity = 0;

  const bullet = series.bullets.push(new am4core.Circle());
  const colorSet = new am4core.ColorSet();

  bullet.strokeOpacity = 0;
  bullet.strokeWidth = 1;
  bullet.fillOpacity = 1;
  bullet.adapter.add('fill', (fill, target) => colorSet.getIndex(target.dataItem.index + 1));
  bullet.hiddenState.properties.opacity = 1;
  const labelUnits = [args.currency, 'orders', '%', 'transactions'];

  bullet.tooltipText = `${indicatorsConfig.reduce((memo, indicator) => {
    switch (indicator.unit) {
      case Units.Percent:
        return `${memo}\n${indicator.label}: {${indicator.id}.formatNumber('.##')} %`;
      case Units.Amount:
        return `${memo}\n${indicator.label}: {${indicator.id}.formatNumber('#,###.00')} ${CURRENCIES[args.currency].symbol}`;
      default:
        return `${memo}\n${indicator.label}: {${indicator.id}.formatNumber('.##')} ${labelUnits[indicator.unit]}`;
    }
  }, '[font-weight:500 font-size: 16px text-transform: capitalize word-break: keep-all]{title}[/]\n[font-size: 12px]')}{additional}[/]`;

  const outline = chart.plotContainer.createChild(am4core.Circle);
  outline.fillOpacity = 0.4;
  outline.strokeOpacity = 0.2;
  outline.stroke = am4core.color('#FFF');
  outline.strokeWidth = 1;
  // outline.hide(0);

  const blurFilter = new am4core.BlurFilter();
  outline.filters.push(blurFilter);

  bullet.events.on('over', (event) => {
    const { target } = event;
    outline.radius = target.pixelRadius + 2;
    outline.x = target.pixelX;
    outline.y = target.pixelY;
    outline.show();
  });

  bullet.events.on('out', () => {
    outline.hide();
  });

  const hoverState = bullet.states.create('hover');
  hoverState.properties.fillOpacity = 1;
  hoverState.properties.strokeOpacity = 0;
  // hoverState.properties.cornerRadius = 0;

  series.heatRules.push({
    target: bullet,
    min: 10,
    max: 25,
    property: 'radius',
  });

  bullet.adapter.add('tooltipX', (tooltipX, target) => -target.radius);

  return chart;
};

import { BorderOutlined, FileSyncOutlined, GlobalOutlined, PhoneOutlined, ShopOutlined } from '@ant-design/icons';
import PaymentChannelList from 'norbr-shared-lib/constants/paymentChannels/list';
import _ from 'norbr-shared-lib/constants/paymentChannels/enum';
import { compareByProp } from '../util/array';

export const iconByChannel = {
  [_.MOTO]: PhoneOutlined,
  [_.E_COMMERCE]: GlobalOutlined,
  [_.POS]: ShopOutlined,
  [_.RECURRING]: FileSyncOutlined,
};

// + icon : Component
const PAYMENT_CHANNELS = PaymentChannelList.reduce(
  (memo, paymentChannel) => ({
    ...memo,
    [paymentChannel.id]: {
      ...paymentChannel,
      value: paymentChannel.id,
      label: paymentChannel.label,
      icon: iconByChannel[paymentChannel.id] || BorderOutlined,
    },
  }),
  {},
);

// as list
export const PAYMENT_CHANNEL_LIST = Object.values(PAYMENT_CHANNELS).sort(compareByProp('label', 'asc', 'string'));

export default PAYMENT_CHANNELS;

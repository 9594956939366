import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import usePage from './usePage';

const DEFAULT_PAGE_SIZE = 50;

/**
 * Update pagination in SearchParams (react-router)
 * @param searchParams
 * @param page
 * @param pageSize
 * @param prefix
 * @returns {Object}
 */
export const updatePaginationInSearchParams = (searchParams, page, pageSize, prefix) => {
  if (page) searchParams.set(`${prefix}_p`, page);
  if (pageSize) searchParams.set(`${prefix}_size`, pageSize);
};

/**
 * Manage Table pagination
 */
const usePagination = () => {
  const { prefix } = usePage();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get(`${prefix}_p`) ?? 1, 10);
  const pageSize = parseInt(searchParams.get(`${prefix}_size`) ?? DEFAULT_PAGE_SIZE, 10);

  const setPage = useCallback(
    (newPage = 1, newPageSize = DEFAULT_PAGE_SIZE) => {
      const newSearchParams = new URLSearchParams(searchParams);
      updatePaginationInSearchParams(newSearchParams, newPage, newPageSize, prefix);
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams, prefix],
  );

  useEffect(() => {
    // init search url with user|default page & pageSize
    if (page === null || pageSize === null) setPage();
  }, []);

  const offset = (page - 1) * pageSize;
  const limit = pageSize;

  return { page, pageSize, setPage, offset, limit };
};

export default usePagination;

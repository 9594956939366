import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Checkbox, Col, Row, Space, Table, Tag, Typography } from 'antd';
import { useMutation } from '@apollo/client';
import { initials, search } from '../../../../../../util/string';
import tileStyles from '../../../../Brainpower/OrderDetail/Tile/Tile.module.scss';
import { useCan } from '../../../../../../contexts/ability.context';
import { MerchantGroupMembersSetMutation } from './query';
import { compareByProp } from '../../../../../../util/array';
import { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import useToggle from '../../../../../../hooks/useToggle';
import SearchInput from '../../../../Common/ListFilter/SearchInput';
import useStatePagination from '../../../../Brainpower/hooks/useStatePagination';
import USER_STATUSES, { userStatusList } from '../../../../../../constants/USER_STATUSES';

const Members = ({ merchantGroup }) => {
  const can = useCan();

  const { page, pageSize, setPage } = useStatePagination(20);
  const [quickSearch, setQuickSearch] = useState();
  const [onlySelected, { toggle }] = useToggle(true);

  const [members, setMembers] = useState(merchantGroup.users.map((u) => u.id));

  const [mutate] = useMutation(MerchantGroupMembersSetMutation);

  const handleChange = (newTargetKeys) => {
    setMembers(newTargetKeys);
    mutate({ variables: { id: merchantGroup.id, memberIds: newTargetKeys } });
  };

  const dataSource = useMemo(
    () =>
      merchantGroup.programManager.users.map((user) => ({
        key: user.id,
        userId: user.id,
        avatar: user.avatar,
        name: user.full_name,
        status: user.status,
        aclProfile: user.aclProfile.id,
        aclProfileName: user.aclProfile.name,
      })),
    [merchantGroup],
  );

  const filteredDataSource = useMemo(
    () =>
      dataSource.filter(
        (u) => (search(quickSearch, u.id) || search(quickSearch, u.name)) && (!onlySelected || members.includes(u.key)),
      ),
    [dataSource, quickSearch, onlySelected, members],
  );

  return (
    <div className={tileStyles.root} id="tile-mg-members">
      <Row justify="space-between" align="middle" gutter={8}>
        <Col flex={1} className={tileStyles.title}>
          Members
        </Col>
      </Row>
      <Typography.Paragraph>Authorized users on selected merchant companies / accounts.</Typography.Paragraph>
      <Row>
        <Col span={24}>
          <Table
            title={() => (
              <Space size={48}>
                <SearchInput
                  onChange={(e) => {
                    setQuickSearch(e.target.value);
                    setPage(1);
                  }}
                  placeholder="Search by ID or name"
                />
                <Checkbox checked={onlySelected} onClick={toggle}>
                  Only selected
                </Checkbox>
              </Space>
            )}
            columns={[
              {
                title: 'Full name',
                dataIndex: 'name',
                key: 'name',
                render: (name, { userId, avatar }) => (
                  <Link to={`/program-users/${userId}`}>
                    <Space>
                      <Avatar src={avatar}>{initials(name)}</Avatar>
                      {name}
                    </Space>
                  </Link>
                ),
                fixed: 'left',
                ...getColumnFilterSearchInput((value, record) => search(value, record.name)),
                sorter: compareByProp('name'),
              },
              {
                title: 'Profile',
                dataIndex: 'aclProfile',
                key: 'aclProfile',
                render: (value, { aclProfileName }) => aclProfileName,
                filters: merchantGroup.programManager.programManagerAclProfiles.map((p) => ({
                  value: p.name,
                  text: p.name,
                })),
                onFilter: (value, record) => value === record.aclProfileName,
                sorter: compareByProp('aclProfileName'),
              },
              {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (status) =>
                  status &&
                  USER_STATUSES[status] && <Tag color={USER_STATUSES[status].color}>{USER_STATUSES[status].label}</Tag>,
                filters: userStatusList.map((s) => ({
                  value: s.value,
                  text: s.label,
                })),
                onFilter: (value, record) => value === record.status,
                sorter: compareByProp('status'),
              },
            ]}
            dataSource={filteredDataSource}
            rowSelection={{
              selectedRowKeys: members,
              onChange: handleChange,
              getCheckboxProps: () => ({
                disabled: !can('update-access', 'program-manager-user'),
              }),
            }}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 20,
              position: 'bottomRight',
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} users`,
              onChange: setPage,
              current: page,
              pageSize,
              total: filteredDataSource.length,
            }}
            size="small"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Members;

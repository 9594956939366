import AccountMultipleIcon from '@2fd/ant-design-icons/lib/AccountMultiple';
import {NotificationOutlined, SafetyCertificateOutlined} from '@ant-design/icons';
import React from 'react';

const Internal = (can) => [
  {
    type: 'group',
    label: 'NORBr',
    children: [
      ...(can('read', 'internal_user')
        ? [{ key: '/internal-users', icon: <AccountMultipleIcon />, label: 'Internal users' }]
        : []),
      ...(can('update', 'internal-access-control')
        ? [
          {
            key: '/internal-access-control-list',
            icon: <SafetyCertificateOutlined />,
            label: 'Internal rights',
          },
        ]
        : []),
      ...(can('read', 'announcement')
        ? [{ key: '/announcements', icon: <NotificationOutlined />, label: 'Announcements' }]
        : []),
    ],
  },
];

export default Internal;
import React from 'react';
import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, message, Radio, Row, Select, Space, Typography } from 'antd';
import moment from 'moment-timezone';
import DownloadBoxIcon from '@2fd/ant-design-icons/lib/DownloadBox';
import { useMe } from '../../../../../contexts/me.context';
import { Block } from '../../../Common/Sider';
import CronScheduleInput from '../../../Common/Form/CronScheduleInput/CronScheduleInput';
import SftpConnectionInput from '../../../Common/Form/SftpConnectionInput/SftpConnectionInput';
import useSearchFilters from '../../hooks/useSearchFilters';
import ReportFormats, { reportFormatList } from '../../ExportList/constants/reportFormats';
import ReportDelimiters, { reportDelimiterList } from '../../ExportList/constants/reportDelimiters';
import SendingMethods, { sendingMethodList } from '../../ExportList/constants/sendingMethods';
import ExportStatuses from '../../ExportList/constants/exportStatuses';
import useColumnSet from '../../hooks/useColumnSet';
import useSort from '../../hooks/useSort';
import useTargetEntity from '../../hooks/useTargetEntity';
import { createExportMutation } from './query';
import styles from './ExportDrawer.module.scss';
import AbsolutePeriodWarning from './AbsolutePeriodWarning';

const CreateExport = ({ onClose }) => {
  const me = useMe();
  const [columnSet] = useColumnSet();
  const [sort] = useSort();
  const [entity] = useTargetEntity();
  const { dateFilter, list } = useSearchFilters();

  const [createExport, { loading, error }] = useMutation(createExportMutation, {
    onCompleted: (data) => {
      if (!data.createExport) return;
      if (data.createExport.schedule) {
        message.success('Export have been successfully scheduled.');
      } else if (data.createExport.status !== ExportStatuses.PENDING) {
        message.warning(
          <Typography.Text>
            An error occurred while running <strong>{data.createExport.name}</strong> export.
          </Typography.Text>,
        );
      } else {
        message.loading(
          <Typography.Text>
            Export <strong>{data.createExport.name}</strong> is running.
          </Typography.Text>,
        );
      }
      onClose();
    },
    errorPolicy: 'all',
  });

  const [form] = Form.useForm();

  const formSchedule = Form.useWatch('schedule', form);
  const formSendingMethod = Form.useWatch('sendingMethod', form);

  const onFinish = (input) => {
    const { format, delimiter, name, description, schedule, sendingMethod, sftpConnection } = input;

    return createExport({
      variables: {
        input: {
          name,
          description,
          format,
          delimiter,
          schedule,
          timezone: moment.tz.guess(true),
          filters: {
            dateFilter,
            searchFilters: list.filter(filter => filter !== dateFilter),
          },
          view: {
            columnSet,
            sort,
            entity,
          },
          sendingMethod,
          sftpConnection,
        },
      },
    });
  };

  const initialValues = {
    format: ReportFormats.CSV,
    delimiter: ReportDelimiters.SEMICOLON,
    sendingMethod: SendingMethods.NONE,
    email: me.email,
    schedule: undefined,
  };

  return (
    <Block
      key="create-export"
      title="Create new export"
      description="Export or set a frequency to create a recurring export"
      collapsable
    >
      <Form
        name="create-export"
        initialValues={initialValues}
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        requiredMark="optional"
      >
        <Row gutter={8}>
          <Col span="15">
            <Form.Item label="Format" name="format" required>
              <Select options={reportFormatList} />
            </Form.Item>
          </Col>
          <Col span="9">
            <Form.Item label="Delimiter" name="delimiter" required>
              <Select options={reportDelimiterList} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item noStyle name="schedule" required>
          <CronScheduleInput />
        </Form.Item>
        {!formSchedule || <AbsolutePeriodWarning />}
        <Form.Item label="Name" name="name" required={!!formSchedule} rules={[{ required: !!formSchedule }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input />
        </Form.Item>
        <Form.Item label="Sending method" name="sendingMethod" required>
          <Radio.Group>
            <Space direction="horizontal">
              {sendingMethodList.map((o) => (
                <Radio key={o.value} value={o.value}>
                  {o.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        {formSendingMethod === SendingMethods.SFTP && (
          <Form.Item
            name="sftpConnection"
            label="SFTP connection"
            required
            rules={[{ required: true, message: 'SFTP connection is required.' }]}
          >
            <SftpConnectionInput />
          </Form.Item>
        )}
        {error && (
          <Space direction="vertical">
            {error.graphQLErrors.map((err) => (
              <Typography.Text type="danger">{err.message}</Typography.Text>
            ))}
          </Space>
        )}
        <Form.Item className={styles.submitItem}>
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={loading}
            icon={<DownloadBoxIcon style={{ fontSize: 22, verticalAlign: 'middle' }} />}
          >
            {!formSchedule ? 'GENERATE REPORT' : 'CREATE SCHEDULED EXPORT'}
          </Button>
        </Form.Item>
      </Form>
    </Block>
  );
};

export default CreateExport;

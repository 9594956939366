import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import LordIcon from '../../../../assets/icons/LordIcon';
import styles from './Loader.module.scss';

const LoaderFromTheme = ({
  theme = {},
  loader: previewLoader,
  height: h,
  width: w,
  size = 'default',
  className,
  stopped = false,
}) => {
  let height;
  let width;
  switch (size) {
    case 'small':
      height = 14;
      width = 14;
      break;
    case 'large':
      height = 32;
      width = 32;
      break;
    case 'default':
    default:
      height = h || 20;
      width = w || 20;
  }

  const loader = previewLoader || theme.loader;

  const [animationData, setAnimationData] = useState();

  useEffect(() => {
    if (loader?.type === 'json' && loader?.img) {
      fetch(loader.img)
        .then(async (res) => {
          setAnimationData(await res.json());
        })
        .catch((err) => console.log(err));
    }
  }, [loader]);

  if (loader?.type === 'json') {
    if (loader?.img) {
      // let animationData = null;
      // try {
      //   animationData = JSON.parse(await fileToJSON(loader?.img))
      // } catch (e) {
      //
      // }
      // if (animationData) {
      return (
        <LordIcon
          path={loader?.img}
          animationData={animationData}
          width={width}
          height={height}
          autoplay
          isStopped={stopped}
          loop
          classes={{ [className]: true }}
        />
      );
      // }
    }
  }
  if (loader?.type === 'css') {
    if (loader?.img && loader?.animation) {
      return (
        <img
          alt=""
          src={loader?.img}
          width={width}
          height={height}
          style={{
            width,
            height,
          }}
          className={!stopped && classnames(styles[loader?.animation], styles.animation, className)}
        />
      );
    }
  }
  if (loader?.type === 'gif' && loader?.img) {
    return stopped ? (
      <div className={styles.gifStaticPreview}>GIF</div>
    ) : (
      <img id="loader-img" alt="" width={width} height={height} src={loader.img} className={className} />
    );
  }
  // Default loader
  return (
    <LordIcon
      type="loader"
      width={width}
      height={height}
      autoplay
      isStopped={stopped}
      loop
      color={theme.primaryColor}
      classes={{ [className]: true }}
    />
  );
};

export default LoaderFromTheme;

import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Row, Space, Tag, Typography } from 'antd';
import classnames from 'classnames';
import CloseCircleIcon from '@2fd/ant-design-icons/lib/CloseCircle';
import CogIcon from '@2fd/ant-design-icons/lib/Cog';
import DragIcon from '@2fd/ant-design-icons/lib/Drag';
import InformationIcon from '@2fd/ant-design-icons/lib/Information';
import { CodeSandboxOutlined, FunctionOutlined } from '@ant-design/icons';
import useDebounce from '../../../../../../hooks/useDebounce';
import FieldTypes from '../../constants/fieldTypes';
import targetEntities from '../../constants/targetEntities';
import styles from './Item.module.scss';
import ColumnSettingForm from './ColumnSettingForm';
import { DataRoles } from '../../../Matcher/MatcherCashier/fields';

const Item = ({ column, field, open, toggleOpen, onRemove, removable = true, onChange }) => {

  // update label with debounce
  const [label, setLabel] = useState(column.label);
  const debouncedLabel = useDebounce(label, 300);
  useEffect(() => {
    if (debouncedLabel && debouncedLabel !== column?.label) {
      onChange({ ...column, label: debouncedLabel })
    }
  }, [column, debouncedLabel]);

  const handleChange = (values) => onChange({ ...column, ...values });

  const hasSettings = [FieldTypes.DATE, FieldTypes.AMOUNT].includes(field.type);

  return (
    <div className={classnames(styles.setUpItem, { [styles.open]: open, [styles.large]: hasSettings })}>
      <Row align="middle">
        <Col flex="none">
          <DragIcon className={styles.dragIcon} />
        </Col>
        <Col flex="auto">
          <Input
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            bordered={false}
            className={styles.labelInput}
            maxLength={34}
          />
        </Col>
        {field.dataRole === DataRoles.INDICATOR && (
          <Col flex="none">
            <FunctionOutlined style={{ fontSize: '1em' }} title="Indicator" />
          </Col>
        )}
        {field.dataRole === DataRoles.DIMENSION && (
          <Col flex="none">
            <CodeSandboxOutlined style={{ fontSize: '1em' }} title="Dimension" />
          </Col>
        )}
        <Col flex="none">
          <Button
            type="text"
            shape="circle"
            size="large"
            icon={hasSettings ? <CogIcon /> : <InformationIcon />}
            onClick={(e) => toggleOpen(column.field, e)}
          />
        </Col>
      </Row>
      <div className={styles.content}>
        <Space direction="vertical">
          <Space direction="vertical" size={0}>
            <Tag icon={targetEntities[field.entity]?.icon()}>{field.label}</Tag>
            <Typography.Text>{field.description ?? 'Missing field description'}</Typography.Text>
            {[FieldTypes.LIST, FieldTypes.ASYNC_LIST].includes(field.type) && !field.displayOptions?.asId && (
              <Typography.Text>If you want to display the name, please activate the text mode.</Typography.Text>
            )}
            {hasSettings && <Typography.Text>Below, you can adapt the format to facilitate exports.</Typography.Text>}
            <Typography.Text disabled>
              Source : {field.entity} | Key : {field.key}
            </Typography.Text>
          </Space>
          <ColumnSettingForm column={column} field={field} onValuesChange={handleChange} />
        </Space>
      </div>
      {removable && (
        <Button
          className={styles.removeButton}
          shape="circle"
          type="text"
          icon={<CloseCircleIcon />}
          onClick={(e) => onRemove(column.field, e)}
          title="Remove column"
        />
      )}
    </div>
  );
};

export default Item;

import { gql } from '@apollo/client';

export const updateThemeLoaderMutation = gql`
  mutation UpdateThemeLoader($id: ID!, $input: LoaderInput!) {
    uploadProgramManagerThemeLoader(id: $id, input: $input) {
      id
      theme {
        loader {
          img
          type
          animation
        }
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const MerchantGroupContentUpdateMutation = gql`
  mutation MerchantGroupContentUpdateMutation($id: ID!, $merchantCompanies: [ID!]!, $merchantAccounts: [ID!]!) {
    updateMerchantGroupContent(id: $id, merchantCompanies: $merchantCompanies, merchantAccounts: $merchantAccounts) {
      id
      merchantCompanies {
        id
        name
        status
        country
        activity
        userCount
        merchantAccountCount
        theme {
          logo
          icon
          primaryColor
          secondaryColor
        }
      }
      merchantAccounts {
        id
        name
        status
        cms_name
        cms_version
        userCount
        source
        company {
          country
          theme {
            logo
            icon
            primaryColor
            secondaryColor
          }
        }
      }
    }
  }
`;

export default {};

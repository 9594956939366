import React from 'react';
import { Button, Drawer, Form, Input, Select } from 'antd';
import ArrowLeftIcon from '@2fd/ant-design-icons/lib/ArrowLeft';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import moment from 'moment-timezone';
import useColumnSet from '../../../hooks/useColumnSet';
import useSort from '../../../hooks/useSort';
import useTableDisplay from '../../../hooks/useTableDisplay';
import useTargetEntity from '../../../hooks/useTargetEntity';
import { useBrainpowerViewSettings } from '../../../hooks/useBrainpowerSettings';
import usePage from '../../../hooks/usePage';
import useDimensions from '../../../hooks/useDimensions';
import useIndicators from '../../../hooks/useIndicators';

const SavedViewSaveForm = ({ views, form, onFinish }) => {
  const viewId = Form.useWatch('view', form);
  const labelValue = Form.useWatch('label', form);

  return [
    <Form
      key="saved-view-save-form"
      form={form}
      onFinish={onFinish}
      layout="vertical"
      style={{ padding: '24px 24px' }}
      requiredMark={false}
    >
      {views.length ? (
        <Form.Item name="view" label="Update view">
          <Select
            disabled={!!labelValue}
            options={views.map((value) => ({
              value: value._id,
              label: value.label,
            }))}
            onChange={(value) =>
              form.setFieldsValue({
                view: value,
                label: '',
              })
            }
            onClear={() => form.setFieldValue('view', null)}
            allowClear
          />
        </Form.Item>
      ) : null}
      <Form.Item name="label" label="New view">
        <Input disabled={!!viewId} />
      </Form.Item>
      <Form.Item shouldUpdate style={{ marginTop: 40 }}>
        {() => (
          <Button block type="primary" htmlType="submit" disabled={!labelValue && !viewId}>
            SAVE
          </Button>
        )}
      </Form.Item>
    </Form>,
  ];
};

const SavedListViewSave = ({ onClose }) => {
  const [settings, update] = useBrainpowerViewSettings();
  const { favorite = 'default', views = [] } = settings;

  const [columnSet] = useColumnSet();
  const [sort] = useSort();
  const [display] = useTableDisplay();
  const [entity] = useTargetEntity();

  const [form] = Form.useForm();

  const viewId = Form.useWatch('view', form);
  const labelValue = Form.useWatch('label', form);

  const handleFinish = () => {
    // update existing saved view
    if (viewId) {
      update({
        favorite,
        views: views.map((s) =>
          s._id === viewId
            ? {
                ...s,
                columnSet,
                sort,
                display,
                entity,
              }
            : s,
        ),
      }).then(() => onClose());
    }
    // save new view
    else {
      update({
        favorite,
        views: [
          ...views,
          {
            label: labelValue,
            columnSet,
            sort,
            display,
            entity,
            createdAt: moment().toISOString(),
          },
        ],
      }).then(() => onClose());
    }
  };

  return <SavedViewSaveForm views={views} form={form} onFinish={handleFinish} />;
};
const SavedOverviewViewSave = ({ onClose }) => {
  const [settings, update] = useBrainpowerViewSettings();
  const { favorite = 'default', views = [] } = settings;

  const [columnSet] = useColumnSet();
  const [dimensions] = useDimensions();
  const [indicators] = useIndicators();
  const [display] = useTableDisplay();
  const [sort] = useSort();

  const [form] = Form.useForm();

  const viewId = Form.useWatch('view', form);
  const labelValue = Form.useWatch('label', form);

  const handleFinish = () => {
    // update existing saved view
    if (viewId) {
      update({
        favorite,
        views: views.map((s) =>
          s._id === viewId
            ? {
                ...s,
                columnSet,
                dimensions,
                indicators,
                display,
                sort,
              }
            : s,
        ),
      }).then(() => onClose());
    }
    // save new view
    else {
      update({
        favorite,
        views: [
          ...views,
          {
            label: labelValue,
            columnSet,
            dimensions,
            indicators,
            display,
            sort,
            createdAt: moment().toISOString(),
          },
        ],
      }).then(() => onClose());
    }
  };

  return <SavedViewSaveForm views={views} form={form} onFinish={handleFinish} />;
};

const SavedViewSaveContent = ({ onClose }) => {
  const page = usePage();

  switch (page.type) {
    case 'list':
      return <SavedListViewSave onClose={onClose} />;
    case 'overview':
      return <SavedOverviewViewSave onClose={onClose} />;
    default:
      return null;
  }
};

const SavedViewSaveDrawer = ({ open, onClose, onBack, containerId }) => (
  <Drawer
    width={470}
    open={open}
    getContainer={`#${containerId}`}
    style={{ position: 'absolute', zIndex: 50 }}
    bodyStyle={{ padding: 0 }}
    onClose={onClose}
    destroyOnClose
    title="View / Save view"
    closable
    closeIcon={<WindowCloseIcon />}
    extra={<ArrowLeftIcon onClick={onBack} />}
    mask={false}
    push={false}
    autoFocus={false}
  >
    <SavedViewSaveContent onClose={onClose} />
  </Drawer>
);

export default SavedViewSaveDrawer;

import React, { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Checkbox, Col, Row, Space, Table } from 'antd';
import { Link } from 'react-router-dom';
import { useCan } from '../../../../../../contexts/ability.context';
import {
  MerchantGroupMemberAddMutation,
  MerchantGroupMemberRemoveMutation,
} from '../../../MerchantGroups/MerchantGroupView/Members/query';
import SearchInput from '../../../../Common/ListFilter/SearchInput';
import TargetEntities, { TargetEntity } from '../../../../Brainpower/Common/constants/targetEntities';
import useStatePagination from '../../../../Brainpower/hooks/useStatePagination';
import useToggle from '../../../../../../hooks/useToggle';
import { search } from '../../../../../../util/string';

const MerchantGroupsTable = ({ user }) => {
  const can = useCan();
  const [selectedRowKeys, setSelectedRowKeys] = useState(user.authorizedMerchantGroups.map((mg) => mg.id));

  const [add] = useMutation(MerchantGroupMemberAddMutation, {
    refetchQueries: ['ProgramManagerUserAuthorizationManagementQuery'],
  });
  const [remove] = useMutation(MerchantGroupMemberRemoveMutation, {
    refetchQueries: ['ProgramManagerUserAuthorizationManagementQuery'],
  });

  // QuickSearch on MerchantCompany & MerchantAccount
  const [quickSearch, setQuickSearch] = useState();
  const { page, pageSize, setPage } = useStatePagination(5);
  const [onlySelected, { toggle }] = useToggle(true);

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    selectedRowKeys,
    onSelect: (merchantGroup, selected, selectedRows) => {
      setSelectedRowKeys(selectedRows.map((r) => r.id));
      if (selected) add({ variables: { id: merchantGroup.id, memberId: user.id } });
      else remove({ variables: { id: merchantGroup.id, memberId: user.id } });
    },
    getCheckboxProps: () => ({
      disabled: !can('update-access', 'program-manager-user'),
    }),
  };

  const dataSource = useMemo(
    () =>
      user.programManager.merchantGroups.filter(
        (mg) =>
          (search(quickSearch, mg.id) || search(quickSearch, mg.name)) &&
          (!onlySelected || selectedRowKeys.includes(mg.id)),
      ),
    [user, onlySelected, quickSearch],
  );

  return (
    <div>
      <Table
        title={() => (
          <Row justify="space-between">
            <Col>
              <Space size={48}>
                <SearchInput
                  onChange={(e) => {
                    setQuickSearch(e.target.value);
                    setPage(1);
                  }}
                  placeholder="Search by ID or name"
                />
                <Checkbox checked={onlySelected} onClick={toggle}>
                  Only selected
                </Checkbox>
              </Space>
            </Col>
            <Col>
              <Space>
                <Button
                  type="primary"
                  size="small"
                  style={{
                    border: 'none',
                    backgroundColor: 'var(--ant-primary-color-active-deprecated-d-02)',
                    color: 'var(--ant-primary-color)',
                  }}
                  icon={TargetEntities[TargetEntity.MERCHANT_GROUP].icon()}
                  title={`${selectedRowKeys.length} groups authorized`}
                >
                  {`${selectedRowKeys.length} / ${user.programManager.merchantGroups.length}`}
                </Button>
              </Space>
            </Col>
          </Row>
        )}
        columns={[
          {
            key: 'name',
            dataIndex: 'name',
            ...(can('read', 'merchant-group')
              ? {
                  render: (name, row) => <Link to={`/merchant-groups/${row.id}`}>{name}</Link>,
                }
              : {}),
          },
          { key: 'description', dataIndex: 'description' },
        ]}
        dataSource={dataSource}
        showHeader={false}
        rowSelection={rowSelection}
        rowKey="id"
        bordered={false}
        pagination={{
          hideOnSinglePage: true,
          showSizeChanger: true,
          position: 'bottomRight',
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} groups`,
          onChange: setPage,
          current: page,
          pageSize,
          total: dataSource.length,
        }}
      />
    </div>
  );
};

export default MerchantGroupsTable;

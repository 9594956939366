import { gql } from '@apollo/client';

export const FallbackSettingsQuery = gql`
  query FallbackSettingsQuery($id: ID!) {
    merchantAccount(id: $id) {
      __typename
      id
      reference_currency
      fallbackSettings {
        status
        max_attempts
        max_retries
        count_psp_unavailability
        ask_3ds_default {
          type
          value
        }
        force_3ds_default {
          type
          value
        }
        no_3ds_default {
          type
          value
        }
        rules {
          _id
          name
          description
          condition_1
          condition_2
          logical_operator
          result {
            type
            value
          }
        }
        authorization_failed
        authorization_error_codes
        authentication_failed
        authentication_succeed
      }
    }
  }
`;

export const FallbackSettingsMutation = gql`
  mutation FallbackSettingsMutation($id: ID!, $input: FallbackSettingsInput!) {
    updateMerchantAccountFallbackSettings(id: $id, input: $input) {
      __typename
      id
      fallbackSettings {
        status
        max_attempts
        max_retries
        count_psp_unavailability
        ask_3ds_default {
          type
          value
        }
        force_3ds_default {
          type
          value
        }
        no_3ds_default {
          type
          value
        }
        rules {
          _id
          name
          description
          condition_1
          condition_2
          logical_operator
          result {
            type
            value
          }
        }
        authorization_failed
        authorization_error_codes
        authentication_failed
        authentication_succeed
      }
    }
  }
`;

export default {};

import { gql } from '@apollo/client';

const query = `{
  _id
  last_transaction {
    _id
    customer_billing_first_name
    customer_billing_last_name
    customer_billing_street_name
    customer_billing_zip_code
    customer_billing_city
    customer_billing_state
    customer_billing_country
  }
}`;

export const orderBillingDetailsQuery = gql`
  query OrderBillingDetails($orderId: ID!) {
    order(id: $orderId) ${query}
  }
`;

export const activityBillingDetailsQuery = gql`
  query ActivityBillingDetails($orderId: ID!) {
    activity(id: $orderId) ${query}
  }
`;

/**
 * The downloadFile function creates a download link with the specified data, filename, and MIME type,
 * simulates a click on the link, and then removes the link from the DOM.
 */
export const downloadFile = (data, filename, type) => {
  const blob = new Blob([data], { type });
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const downloadAsJsonFile = (data, filename) =>
  downloadFile(JSON.stringify(data), `${filename}.json`, 'application/json');
export const downloadAsCsvFile = (data, filename) => {
  const { Parser } = require('@json2csv/plainjs');
  const csvParser = new Parser();
  const csv = csvParser.parse(data);
  downloadFile(csv, `${filename}.csv`, 'text/csv');
};

/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import { Button, Dropdown, Space } from 'antd';
import { DatabaseOutlined, DownloadOutlined, PictureOutlined, PrinterOutlined } from '@ant-design/icons';
import { clone } from '@amcharts/amcharts4/.internal/core/utils/Object';
import WidgetTypes from '../../constants/widgetTypes';
import { config as Indicators } from '../../constants/indicators';
import Units from '../../constants/units';

const exportingTraceableSankeyDiagramData = (chart, data) => {
  let deep = 1;
  const formattedData = {
    data: data.data.map((row) => ({
      value: row.value,
      ...row.states.reduce((memo, step, index) => {
        deep = Math.max(deep, index + 1);
        return { ...memo, [`state_${index}`]: step };
      }, {}),
    })),
  };
  chart.exporting.dataFields = [...new Array(deep).fill(null).map((v, index) => `state_${index}`), 'value'].reduce(
    (memo, key) => ({ ...memo, [key]: key }),
    {},
  );
  chart.exporting.adapter.add('data', () => formattedData);
};

const exportingMultipleValueAxesData = (chart, data, widget) => {
  // specific export mapping for Percent data
  if (Indicators[widget.indicators[0]].unit === Units.Percent) {
    const formattedData = {
      data: data.data.map((row) =>
        Object.keys(row).reduce(
          (memo, key) => ({ ...memo, [key]: typeof row[key] === 'object' ? row[key][widget.indicators[0]] : row[key] }),
          {},
        ),
      ),
    };
    chart.exporting.adapter.add('data', () => formattedData);
  } else {
    chart.exporting.adapter.add('data', () => clone(data));
  }
};

const ChartExportMenu = ({ chart, widget, data }) => {
  const items = [
    {
      key: 'image',
      label: (
        <Space>
          <PictureOutlined />
          Image
        </Space>
      ),
      children: [
        { key: 'png', label: 'PNG' },
        { key: 'jpg', label: 'JPG' },
        { key: 'svg', label: 'SVG' },
      ],
    },
    {
      key: 'data',
      label: (
        <Space>
          <DatabaseOutlined />
          Data
        </Space>
      ),
      children: [
        { key: 'json', label: 'JSON' },
        { key: 'csv', label: 'CSV' },
        { key: 'xlsx', label: 'Excel' },
      ],
    },
    {
      key: 'print',
      label: (
        <Space>
          <PrinterOutlined />
          Print
        </Space>
      ),
    },
  ];

  const handleMenuClick = useCallback(
    async (e) => {
      switch (widget.type) {
        case WidgetTypes.traceableSankeyDiagram:
          exportingTraceableSankeyDiagramData(chart, data);
          break;
        case WidgetTypes.multipleValueAxes:
          exportingMultipleValueAxesData(chart, data, widget);
          break;
        default:
          chart.exporting.adapter.add('data', () => clone(data));
      }

      chart.exporting.filePrefix = widget.name;
      chart.exporting.title = widget.name;
      await chart.exporting.export(e.key);
    },
    [chart],
  );

  return (
    <Dropdown
      menu={{
        items,
        onClick: handleMenuClick,
      }}
      trigger="click"
      placement="bottomRight"
    >
      <Button icon={<DownloadOutlined />} type="text" />
    </Dropdown>
  );
};

export default ChartExportMenu;

import { gql } from '@apollo/client';

export const certificateQuery = gql`
  query CertificateQuery($id: ID!) {
    certificate(id: $id) {
      id
      name
      status
      deleted
      csr
      pem
      expireAt
      used
      merchantIdentifier
      countryCode
      displayName
      initiativeContext
    }
  }
`;

export const updateCertificateMutation = gql`
  mutation UpdateCertificate($id: ID!, $input: CertificateInput!) {
    updateCertificate(id: $id, input: $input) {
      id
      status
      deleted
      name
      csr
      pem
      expireAt
      used
      merchantIdentifier
      countryCode
      displayName
      initiativeContext
    }
  }
`;

export const removeCertificateMutation = gql`
  mutation RemoveCertificate($id: ID!) {
    removeCertificate(id: $id) {
      id
      status
      deleted
    }
  }
`;
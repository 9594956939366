import React from 'react';
import classnames from 'classnames';
import { Button, Col, Row, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ChevronDownIcon from '@2fd/ant-design-icons/lib/ChevronDown';
import ChevronRightIcon from '@2fd/ant-design-icons/lib/ChevronRight';
import styles from './BlockTitle.module.scss';

const BlockTitle = ({ title, description, onBackClick, collapsable, collapsed, onCollapse, extra = [] }) => (
  <Row
    className={classnames(styles.root, {
      [styles.withBackButton]: !!onBackClick,
    })}
    justify="space-between"
    align="middle"
  >
    <Col>
      <Space>
        {!!onBackClick && (
          <Button className={styles.backButton} onClick={onBackClick} type="text" size="small">
            <ArrowLeftOutlined />
          </Button>
        )}
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </Space>
    </Col>
    <Col>
      <Space>
        {extra}
        {collapsable && (
          <Button
            onClick={onCollapse}
            shape="circle"
            type="text"
          >
            {collapsed ? <ChevronRightIcon /> : <ChevronDownIcon />}
          </Button>
        )}
      </Space>
    </Col>
  </Row>
);

export default BlockTitle;

import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Drawer, Form, Input, message, Select, Space } from 'antd';

import { useResetFormOnCloseModal } from '../../../Common/Form/utils';
import { InternalAclProfilesQuery } from '../UserView/query';
import { InternalUserCreateMutation } from './query';
import { compareByProp } from '../../../../../util/array';
import useNavigateWithSearch from "../../../../../util/navigate";

const UserCreate = ({ visible, onCancel }) => {
  const navigate = useNavigateWithSearch();

  const aclProfilesQuery = useQuery(InternalAclProfilesQuery);

  const [create, { loading }] = useMutation(InternalUserCreateMutation, {
    refetchQueries: ['InternalUserListQuery'],
    onCompleted: ({ createInternalUser }) => {
      message.success('User successfully created');
      navigate(`/internal-users/${createInternalUser.id}`);
    },
    onError: (err) => message.error(err.message),
  });

  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const submit = (values) => {
    create({
      variables: {
        input: values,
      },
    });
  };

  return (
    <Drawer
      title="Create Internal User"
      open={visible}
      onClose={onCancel}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
    >
      <Form form={form} layout="vertical" name="userForm" onFinish={submit}>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true },
            { type: 'email' },
            { pattern: /@norbr\.com$/, message: 'Internal users must sign-in with NORBr account.' },
          ]}
        >
          <Input placeholder="mail@norbr.com" />
        </Form.Item>
        <Form.Item name="first_name" label="Firstname" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="last_name" label="Lastname" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Phone">
          <Space>
            <Form.Item name={['phone', 'code']} noStyle>
              <Input style={{ width: 65 }} placeholder="31" prefix="+" />
            </Form.Item>
            <Form.Item name={['phone', 'number']} noStyle>
              <Input placeholder="123456789" />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item name="aclProfile" label="Role" rules={[{ required: true }]}>
          <Select
            options={aclProfilesQuery.data?.internalAclProfiles
              .map((p) => ({ value: p.id, label: p.name }))
              .sort(compareByProp('label', 'asc', 'string'))}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Space>
            <Button type="text" disabled={loading} onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" size="large" loading={loading}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UserCreate;

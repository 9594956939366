import React from 'react';
import classnames from 'classnames';
import { Space, Tag } from 'antd';
import CloseCircleIcon from '@2fd/ant-design-icons/lib/CloseCircle';
import styles from './FilterTag.module.scss';

const FilterTag = ({ id, onClick, onRemove, label, title, excluded = false, disabled = false, children }) => (
  <Tag
    className={classnames(styles.filterTag, {
      [styles.disabled]: disabled,
    })}
    id={id}
    onClick={onClick}
    title={title || undefined}
  >
    <Space size={4} wrap>
      <span>{label} :</span>
      {excluded && <span>NOT</span>}
      {children}
      {onRemove && <CloseCircleIcon className={styles.removeFilter} onClick={onRemove} />}
    </Space>
  </Tag>
);

export default FilterTag;

const heightByName = {
  'order-status': 6,
  'order-reference': 4,
  'payment-details': 8,
  'basket-summary': 4,
  'billing-details': 4,
  authentication: 4,
  'account-history': 4,
  'payment-operations': 3,
  balances: 2,
  'customer-details': 6,
  'customer-address': 3,
  'shipping-details': 5,
  voucher: 2,
  'basket-details': 2,
  'browser-information': 5,
  routing: 2,
  notification: 2,
  checkout: 4,
};

const fullWidthTiles = ['payment-operations', 'basket-details', 'routing', 'notification'];

export default {
  lg: [
    { x: 0, y: 0, i: 'order-status' },
    { x: 0, y: 5, i: 'order-reference' },
    { x: 0, y: 8, i: 'payment-details' },
    { x: 0, y: 14, i: 'routing' },
    { x: 0, y: 24, i: 'basket-summary' },
    { x: 0, y: 47, i: 'billing-details' },
    { x: 0, y: 18, i: 'authentication' },
    { x: 0, y: 38, i: 'account-history' },
    { x: 0, y: 16, i: 'payment-operations' },
    { x: 0, y: 30, i: 'balances' },
    { x: 0, y: 30, i: 'customer-details' },
    { x: 0, y: 42, i: 'shipping-details' },
    { x: 0, y: 22, i: 'voucher' },
    { x: 0, y: 28, i: 'basket-details' },
    { x: 0, y: 51, i: 'browser-information' },
    { x: 0, y: 57, i: 'notification' },
    { x: 0, y: 63, i: 'checkout' },
    { x: 0, y: 67, i: 'customer-address' },
  ],
  xl: [
    { x: 0, y: 3, i: 'order-status' },
    { x: 0, y: 0, i: 'order-reference' },
    { x: 1, y: 0, i: 'payment-details' },
    { x: 1, y: 10, i: 'basket-summary' },
    { x: 1, y: 22, i: 'billing-details' },
    { x: 0, y: 10, i: 'authentication' },
    { x: 1, y: 16, i: 'account-history' },
    { x: 0, y: 8, i: 'payment-operations' },
    { x: 1, y: 20, i: 'balances' },
    { x: 0, y: 16, i: 'customer-details' },
    { x: 0, y: 22, i: 'shipping-details' },
    { x: 1, y: 6, i: 'voucher' },
    { x: 0, y: 14, i: 'basket-details' },
    { x: 1, y: 26, i: 'browser-information' },
    { x: 0, y: 31, i: 'notification' },
    { x: 0, y: 36, i: 'checkout' },
    { x: 1, y: 36, i: 'customer-address' },
  ],
  xxl: [
    { x: 0, y: 0, i: 'order-status' },
    { x: 1, y: 0, i: 'order-reference' },
    { x: 2, y: 0, i: 'payment-details' },
    { x: 1, y: 8, i: 'basket-summary' },
    { x: 1, y: 14, i: 'billing-details' },
    { x: 0, y: 8, i: 'authentication' },
    { x: 0, y: 20, i: 'account-history' },
    { x: 0, y: 6, i: 'payment-operations' },
    { x: 2, y: 8, i: 'balances' },
    { x: 0, y: 14, i: 'customer-details' },
    { x: 2, y: 14, i: 'shipping-details' },
    { x: 1, y: 3, i: 'voucher' },
    { x: 0, y: 12, i: 'basket-details' },
    { x: 1, y: 18, i: 'browser-information' },
    { x: 0, y: 24, i: 'notification' },
    { x: 0, y: 30, i: 'checkout' },
    { x: 1, y: 30, i: 'customer-address' },
  ],
};

const columnBySize = {
  lg: 1,
  xl: 2,
  xxl: 3,
};

/**
 * Init layout tile size (h & w)
 */
export const initLayoutTileSize = (prevLayout) =>
  ['lg', 'xl', 'xxl'].reduce(
    (memo, size) => ({
      ...memo,
      [size]: prevLayout[size].map((tile) => ({
        ...tile,
        h: heightByName[tile.i],
        w: fullWidthTiles.includes(tile.i) ? columnBySize[size] : 1,
      })),
    }),
    {},
  );

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorBoundary from '../../Common/ErrorBoundary/ErrorBoundary';
import Page from '../../Common/Page/Page';
import CompanyView from './CompanyView/CompanyView';
import CompanyList from './CompanyList/CompanyList';

const Company = () => (
  <ErrorBoundary>
    <Routes>
      <Route index element={<CompanyList />} />
      <Route
        path=":companyId"
        element={
          <Page>
            <CompanyView />
          </Page>
        }
      />
    </Routes>
  </ErrorBoundary>
);

export default Company;

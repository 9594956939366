import React, { useContext, useState } from 'react';
import { useMutation as useApolloMutation } from '@apollo/client';
import { Checkbox, Form, Select, Skeleton, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { ContractEditionContext } from '../../../../PaymentNetwork';
import { PaymentMethodContext } from '../PaymentMethodForm';
import styles from './RoutesForm.module.scss';
import { MerchantContractContext } from '../../MerchantContractDrawer';
import AvailableServices from './AvailableServices/AvailableServices';
import { merchantContractAttachRoutesMutation, merchantContractDetachRoutesMutation } from './query';
import HandleRoutesModal from '../../HandleRoutesModal/HandleRoutesModal';
import CheckboxSkeleton from '../Common/CheckboxSkeleton/CheckboxSkeleton';

const { Item } = Form;
const { Option } = Select;

const RoutesForm = ({ loading }) => {
  const { merchantContract } = useContext(MerchantContractContext);
  const { isEdited } = useContext(ContractEditionContext);
  const paymentMethod = useContext(PaymentMethodContext);

  const { t } = useTranslation();

  const [pmIssues, setPmIssues] = useState(null);

  const [routesToDetach, setRoutesToDetach] = useState(null);

  const availableChannels = [...new Set(paymentMethod?.partner_routes.map((partnerRoute) => partnerRoute.channel))];

  const checkedChannels = availableChannels.filter((channel) =>
    merchantContract?.merchant_routes.find((merchantRoute) =>
      merchantRoute.partner_route.channel === channel &&
      merchantRoute.partner_route.payment_method.id === paymentMethod.id),
  );

  const availableCurrencies = [
    ...new Set(
      paymentMethod?.partner_routes
        .filter((partnerRoute) => checkedChannels.includes(partnerRoute.channel))
        .map((partnerRoute) => partnerRoute.authorization_currency),
    ),
  ];

  const selectedCurrencies = availableCurrencies.filter((currency) =>
    merchantContract?.merchant_routes.find(
      (merchantRoute) =>
        merchantRoute.partner_route.authorization_currency === currency &&
        merchantRoute.partner_route.payment_method.id === paymentMethod.api_name,
    ),
  );

  const [detachRoutes, { loading: loadingDetach }] = useApolloMutation(merchantContractDetachRoutesMutation);

  const [attachRoutes, { loading: loadingAttach }] = useApolloMutation(merchantContractAttachRoutesMutation);

  if (loading) {
    return (
      <Form layout="vertical" className={styles.root}>
        <Item label="Sales channel(s)" className={styles.checkboxContainer}>
          {new Array(4).fill(undefined).map(() => (
            <CheckboxSkeleton />
          ))}
        </Item>
        <Item label="Authorization currencies">
          <Skeleton.Button
            active
            size="small"
            shape="square"
            block={false}
            style={{
              width: 310,
              height: 32,
            }}
          />
        </Item>
        <Item label="Available services" className={styles.checkboxContainer}>
          {new Array(7).fill(undefined).map(() => (
            <CheckboxSkeleton />
          ))}
        </Item>
      </Form>
    );
  }

  return (
    <Form layout="vertical" className={classnames(styles.root, { [styles.isLoading]: loadingAttach || loadingDetach })}>
      <Item label="Sales channel(s)">
        {['e-commerce', 'recurring', 'moto', 'pos']
          .filter((option) => availableChannels.includes(option))
          .map((option) => (
            <div className={styles.checkboxContainer}>
              <Checkbox
                disabled={!isEdited}
                checked={checkedChannels.includes(option)}
                onChange={(event) => {
                  if (event.target.checked) {
                    attachRoutes({
                      variables: {
                        id: merchantContract?.id,
                        routes: paymentMethod?.partner_routes
                          ?.filter(
                            (partnerRoute) =>
                              partnerRoute.channel === option &&
                              selectedCurrencies.includes(partnerRoute.authorization_currency),
                          )
                          ?.map((partnerRoute) => partnerRoute.id),
                        status: merchantContract?.status,
                        update: {
                          added: [
                            'payment_methods/sales_channels',
                            option,
                          ],
                          paymentMethod: paymentMethod.api_name,
                        }
                      },
                    });
                  } else {
                    const routes = merchantContract?.merchant_routes
                      ?.filter(
                        (merchantRoute) =>
                          merchantRoute.partner_route.channel === option &&
                          merchantRoute.partner_route.payment_method.id === paymentMethod.api_name,
                      )
                      ?.map((merchantRoute) => merchantRoute.partner_route.id);
                    detachRoutes({
                      variables: {
                        id: merchantContract?.id,
                        routes,
                        update: {
                          removed: [
                            'payment_methods/sales_channels',
                            option,
                          ],
                          paymentMethod: paymentMethod.api_name,
                        }
                      },
                    }).catch((e) => {
                      const error = e.graphQLErrors.find((ee) => ee.extensions.code === '409')
                      if (error) {
                        setRoutesToDetach(routes);
                        setPmIssues(error.extensions.issues);
                      }
                    });
                  }
                }}
              >
                {t(`andMe.paymentNetwork.merchantContract.channels.${option}`)}
              </Checkbox>
            </div>
          ))}
      </Item>
      <Item label="Authorization currencies">
        <Select
          disabled={!isEdited}
          mode="multiple"
          // hide 'all' tag
          tagRender={(props) => {
            if (props.value === 'all') {
              return null;
            }
            return (
              <Tag
                {...props}
                onClose={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  const routes = merchantContract?.merchant_routes
                    ?.filter(
                      (merchantRoute) =>
                        (props.value === 'all' || merchantRoute.partner_route.authorization_currency === props.value) &&
                        merchantRoute.partner_route.payment_method.id === paymentMethod.api_name,
                    )
                    ?.map((merchantRoute) => merchantRoute.partner_route.id);
                  detachRoutes({
                    variables: {
                      id: merchantContract?.id,
                      routes,
                      update: {
                        removed: [
                          'payment_methods/authorization_currencies',
                          props.value,
                        ],
                        paymentMethod: paymentMethod.api_name,
                      }
                    },
                  }).catch((e) => {
                    const error = e.graphQLErrors.find((ee) => ee.extensions.code === '409')
                    if (error) {
                      setRoutesToDetach(routes);
                      setPmIssues(error.extensions.issues);
                    }
                  });
                }}
              >
                {props.label}
              </Tag>
            );
          }}
          onSelect={(value) => {
            attachRoutes({
              variables: {
                id: merchantContract?.id,
                routes: paymentMethod?.partner_routes
                  ?.filter(
                    (partnerRoute) =>
                      (value === 'all' || partnerRoute.authorization_currency === value) &&
                      checkedChannels.includes(partnerRoute.channel),
                  )
                  ?.map((partnerRoute) => partnerRoute.id),
                status: merchantContract?.status,
                update: {
                  added: [
                    'payment_methods/authorization_currencies',
                    value,
                  ],
                  paymentMethod: paymentMethod.api_name,
                }
              },
            });
          }}
          onDeselect={(value) => {
            const routes = merchantContract?.merchant_routes
              ?.filter(
                (merchantRoute) =>
                  (value === 'all' || merchantRoute.partner_route.authorization_currency === value) &&
                  merchantRoute.partner_route.payment_method.id === paymentMethod.api_name,
              )
              ?.map((merchantRoute) => merchantRoute.partner_route.id);
            detachRoutes({
              variables: {
                id: merchantContract?.id,
                routes,
                update: {
                  removed: [
                    'payment_methods/authorization_currencies',
                    value,
                  ],
                  paymentMethod: paymentMethod.api_name,
                }
              },
            }).catch((e) => {
              const error = e.graphQLErrors.find((ee) => ee.extensions.code === '409');
              if (error) {
                setRoutesToDetach(routes);
                setPmIssues(error.extensions.issues);
              }
            });
          }}
          value={
            selectedCurrencies.length === availableCurrencies.length
              ? ['all', ...selectedCurrencies]
              : selectedCurrencies
          }
        >
          <Option value="all">All available (recommended)</Option>
          {availableCurrencies.map((choice) => (
            <Option value={choice}>{choice}</Option>
          ))}
        </Select>
      </Item>
      <AvailableServices />
      <HandleRoutesModal
        issues={pmIssues}
        onCancel={() => {
          setPmIssues(null);
          setRoutesToDetach(null);
        }}
        onConfirm={() => {
          detachRoutes({
            variables: {
              id: merchantContract?.id,
              routes: routesToDetach,
              force: true,
            },
          });
          setPmIssues(null);
          setRoutesToDetach(null);
        }}
      />
    </Form>
  );
};

export default RoutesForm;

import { PlusCircleOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import React from 'react';
import styles from './SelectBranchInput.module.scss';
import Loader from '../../../../../../../Common/Loader/Loader';

const { Option } = Select;

const SelectBranchInput = ({
  onChange,
  value,
  isLoading,
  options,
  placeholder,
  searchValue,
  setSearchValue,
  unique= false,
}) => (
  <Select
    autoFocus
    className={styles.select}
    placeholder={placeholder}
    mode={unique ? null : "multiple"}
    allowClear
    maxTagCount={10}
    value={value}
    searchValue={searchValue}
    filterOption={false}
    onSearch={(search) => setSearchValue(search)}
    onChange={(newValue) => {
      onChange(newValue);
      setSearchValue('');
    }}
    autoClearSearchValue
    showSearch
    loading={isLoading}
  >
    {isLoading ? null : (
      options?.map((option) => (
        <Option key={option.value} disabled={option.disabled} value={option.value}>
          {option.imgUrl && (
            <img
              src={option.imgUrl}
              alt=""
              width={24}
              style={{
                marginRight: 8,
              }}
            />
          )}
          {option.label}
        </Option>
      ))
    )}
  </Select>
);

export default SelectBranchInput;

import React, { useState } from 'react';
import { Button, Form, Input, message, Space } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { initializeApp } from 'firebase/app';
import { getAuth, SAMLAuthProvider, signInWithPopup } from 'firebase/auth';
import {CopyOutlined, EditOutlined} from '@ant-design/icons';
import {
  applySsoConfigMutation, companySsoConfigQuery,
  updateSsoDraftMutation
} from './query';
import InfoTile, { InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { useCan } from '../../../../../../contexts/ability.context';
import styles from './CompanySso.module.scss';
import {useParams} from 'react-router-dom';

const CompanySso = () => {
  const app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  }, 'company');

  const can = useCan();
  const { companyId } = useParams();

  const [isTestOk, setIsTestOk] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [form] = Form.useForm();

  const {
    data: { merchantCompany: { sso } } = { merchantCompany: { ssoConfig: null } }, loading
  } = useQuery(
    companySsoConfigQuery,
    {
      variables: {
        companyId
      },
    }
  );

  const [updateSso, {loading: loadingUpdateSso}] = useMutation(
    updateSsoDraftMutation,
    {
      variables: {
        companyId,
      },
      onError: async (err) => {
        message.error(err.graphQLErrors[0].message);
      }
    }
  );

  const [applySsoConfig] = useMutation(
    applySsoConfigMutation,
    {
      variables: {
        companyId,
      },
      onError: async (err) => {
        message.error(err.graphQLErrors[0].message);
      }
    }
  );

  if (editMode) {
    return (
      <InfoTile
        name='company-sso'
        title='Single Sign On'
        className={styles.root}
      >
        <Form
          form={form}
          disabled
          className="tile-form"
          layout="vertical"
          requiredMark={false}
          initialValues={{
            ...sso?.config,
            x509Certificates: sso?.config?.x509Certificates?.[0],
            acsUrl: process.env.REACT_APP_ACS_URL,
            nameIdFormat: 'EMAIL',
          }}
          onFieldsChange={() => setIsTestOk(false)}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onFinish={({acsUrl, nameIdFormat, ...values}) => {
            form.validateFields()
              .then(() => {
                updateSso({
                  variables: {
                    input: {
                      ...values,
                      x509Certificates: [values.x509Certificates],
                    }
                  }
                }).then(({ data: { updateCompanySsoDraft: company } } = { data: { updateCompanySsoDraft: null } }) => {
                  const provider = new SAMLAuthProvider(company?.sso?.draft?.providerId);
                  const auth = getAuth(app);
                  auth.tenantId = company?.sso?.draft?.tenantId;
                  auth.useDeviceLanguage();
                  signInWithPopup(auth, provider).then(() => {
                    message.success('Single Sign On connexion successful, you can now activate it');
                    setIsTestOk(true);
                  }).catch(e =>
                    message.error('An error occurred, please check your configuration or make sure the account you logged in with has access to your SSO service')
                  )
                })
              })
          }}
        >
          <Form.Item
            name='acsUrl'
            label={
              <div className={styles.labelContainer}>
                <div>ACS URL</div>
                <CopyOutlined
                  className={styles.copyIcon}
                  onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_ACS_URL).then(() => message.success('Copied !'))}
                />
              </div>
            }
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name='nameIdFormat'
            label={
              <div className={styles.labelContainer}>
                <div>Name ID format</div>
                <CopyOutlined
                  className={styles.copyIcon}
                  onClick={() => navigator.clipboard.writeText('EMAIL').then(() => message.success('Copied !'))}
                />
              </div>
            }
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name='idpEntityId'
            label='IdP-provided Entity ID'
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='ssoURL'
            label='SSO URL'
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='rpEntityId'
            label='Customer-defined SAML Entity ID'
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='x509Certificates'
            label='Certificate'
            rules={[{required: true}]}
          >
            <Input.TextArea
              rows={6}
            />
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button
                onClick={() => {
                  setIsTestOk(false);
                  setEditMode(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setIsTestOk(false);
                  form.resetFields();
                }}
              >
                Reset
              </Button>
              {isTestOk ? (
                <Button
                  type='primary'
                  onClick={() => applySsoConfig().then(() => {
                    message.success('Your Single Sign On configuration is now saved, you can define it as your login method');
                    setEditMode(false);
                  })}
                >
                  Apply configuration
                </Button>
              ) : (
                <Button
                  loading={loadingUpdateSso}
                  type='primary'
                  htmlType='submit'
                >
                  Test configuration
                </Button>
              )}
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  // return info tile
  return (
    <InfoTile
      name='company-sso'
      title='Single Sign On'
      extra={
        can('update', 'company-login') && !loading && (
          <Button onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        )
      }
      className={styles.root}
    >
      <InfoTextRow label="ACS URL" value={process.env.REACT_APP_ACS_URL} loading={loading} />
      <InfoTextRow label="Name ID format" value='EMAIL' loading={loading} />
      <InfoTextRow label="IdP-provided Entity ID" value={sso?.config?.idpEntityId} loading={loading} />
      <InfoTextRow label="SSO URL" value={sso?.config?.ssoURL} loading={loading} />
      <InfoTextRow label="Customer-defined SAML Entity ID" value={sso?.config?.rpEntityId} loading={loading} />
      <InfoTextRow className={styles.certificate} label="Certificate" value={sso?.config?.x509Certificates?.[0]} loading={loading} />
    </InfoTile>
  );
};

export default CompanySso;
import React from 'react';
import { Space, Radio, Tooltip } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import InfoTile from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import {
  metaProgramManagerLoginMethodQuery, updateMetaProgramManagerLoginMethodMutation
} from './query';
import { useCan } from '../../../../../../contexts/ability.context';

const MetaProgramManagerLoginMethod = ({ id }) => {
  const can = useCan();

  const {
    data: { metaProgramManager: { sso, loginMethod } } = { metaProgramManager: { sso: null, loginMethod: null } },
    loading,
  } = useQuery(
    metaProgramManagerLoginMethodQuery,
    {
      variables: {
        id
      },
    }
  );

  const [updateLoginMethod, updateState] = useMutation(
    updateMetaProgramManagerLoginMethodMutation,
    {
      variables: {
        id,
      }
    }
  )

  return (
    <InfoTile
      name='meta-program-manager-login-method'
      title='Login method'
    >
      <Radio.Group
        onChange={(event) => updateLoginMethod({
          variables: {
            input: {
              loginMethod: event.target.value,
            }
          },
          optimisticResponse: {
            updateMetaProgramManagerLoginMethod: {
              id,
              loginMethod: event.target.value,
              __typename: 'MetaProgramManager',
            }
          }
        })}
        value={loginMethod}
        disabled={!can('update', 'meta-program-manager-login') || loading || updateState.loading}
      >
        <Space direction="vertical">
          <Radio value="form">Email / password form</Radio>
          <Tooltip
            align='center'
            title={!(sso?.config?.tenantId && sso?.config?.providerId) && 'Please fill your Single Sign On configuration first'}
          >
            <Radio value="sso" disabled={!(sso?.config?.tenantId && sso?.config?.providerId)}>Single sign on</Radio>
          </Tooltip>
        </Space>
      </Radio.Group>
    </InfoTile>
  );
};

export default MetaProgramManagerLoginMethod;
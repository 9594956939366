import { gql } from '@apollo/client';

export const merchantContractAttachRoutesMutation = gql`
  mutation MerchantContractAttachRoutes($id: ID!, $routes: [ID], $status: String, $update: Object) {
    merchantContractAttachRoutes(id: $id, routes: $routes, status: $status, update: $update) {
      id
      merchant_routes(filters: { status: { in: ["active", "inactive"] } }) {
        id
        status
        partner_route {
          id
          authorization_currency
        }
      }
    }
  }
`;

export const merchantContractDetachRoutesMutation = gql`
  mutation MerchantContractDetachRoutes($id: ID!, $routes: [ID], $update: Object, $force: Boolean) {
    merchantContractDetachRoutes(id: $id, routes: $routes, update: $update, force: $force) {
      id
      merchant_routes(filters: { status: { in: ["active", "inactive"] } }) {
        id
        status
        partner_route {
          id
          authorization_currency
        }
      }
      payment_methods {
        id
      }
    }
  }
`;

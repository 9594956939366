import React from 'react';
import { Button, Col, Layout, message, Modal, Row, Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  BankOutlined,
  BgColorsOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
  DeploymentUnitOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  IdcardOutlined,
  KeyOutlined, LinkOutlined,
  NotificationOutlined,
  SettingOutlined,
  TableOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import AccountKeyOutlineIcon from '@2fd/ant-design-icons/lib/AccountKeyOutline';
import ProgramManagerAvatar from '../../../Common/ProgramManager/Avatar';
import { programManagerQuery, removeProgramManagerMutation } from './query';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import HandleApolloError from '../../../Common/HandleApolloError/HandleApolloError';
import styles from '../../Company/CompanyView/CompanyView.module.scss';
import AnchorList from '../../../Common/AnchorList/AnchorList';
import ActivePartners from './ActivePartners/ActivePartners';
import ProgramManagerTheme from './ProgramManagerTheme/ProgramManagerTheme';
import ProgramManagerCompanyInformation from './ProgramManagerCompanyInformation/ProgramManagerCompanyInformation';
import ProgramManagerCompanyActivity from './ProgramManagerCompanyActivity/ProgramManagerCompanyActivity';
import ProgramManagerCompanyMisc from './ProgramManagerCompanyMisc/ProgramManagerCompanyMisc';
import ProgramManagerCompanyAddress from './ProgramManagerCompanyAddress/ProgramManagerCompanyAddress';
import ProgramManagerCompanyExecutive from './ProgramManagerCompanyExecutive/ProgramManagerCompanyExecutive';
import ProgramManagerContacts from './ProgramManagerContacts/ProgramManagerContacts';
import AvailableInterfaces from './AvailableInterfaces/AvailableInterfaces';
import { useCan } from '../../../../../contexts/ability.context';
import { useProgramManager } from '../../../../../contexts/programManager.context';
import ProgramManagerOptions from './ProgramManagerOptions/ProgramManagerOptions';
import ProgramManagerUsers from './ProgramManagerUsers/ProgramManagerUsers';
import AvailableServices from './AvailableServices/AvailableServices';
import useNavigateWithSearch from '../../../../../util/navigate';
import ProgramManagerLoginMethod from './ProgramManagerLoginMethod/ProgramManagerLoginMethod';
import ProgramManagerSso from './ProgramManagerSso/ProgramManagerSso';
import ProgramManagerApiKeys from './ProgramManagerApiKeys/ProgramManagerApiKeys';
import MetaProgramManager from './MetaProgramManager/MetaProgramManager';

const { Content, Sider } = Layout;

const ProgramManagerView = () => {
  const { programManagerId } = useParams();
  const navigate = useNavigateWithSearch();
  const can = useCan();
  const programManagerContext = useProgramManager();

  const { data: { programManager } = { programManager: null }, error } = useQuery(programManagerQuery, {
    variables: {
      id: programManagerId,
    },
  });

  const [removeProgramManager] = useMutation(removeProgramManagerMutation, {
    variables: {
      id: programManagerId,
    },
    refetchQueries: ['ProgramManagers'],
    onCompleted: () => {
      navigate('/program-managers');
      message.success('Program manager deleted.');
    },
  });

  const handleDelete = () => {
    Modal.confirm({
      title: `Remove ${programManager?.name}`,
      content: 'Are you sure you want to delete this program manager?',
      onOk: () => removeProgramManager(),
    });
  };

  const anchors = [
    {
      hash: '#tile-program-manager-company',
      icon: BankOutlined,
      label: 'Company',
    },
    {
      hash: '#tile-program-manager-company-activity',
      icon: BankOutlined,
      label: 'Activity',
    },
    {
      hash: '#tile-program-manager-company-misc',
      icon: UnorderedListOutlined,
      label: 'Financial',
    },
    {
      hash: '#tile-program-manager-company-address',
      icon: EnvironmentOutlined,
      label: 'Address',
    },
    {
      hash: '#tile-program-manager-company-executive',
      icon: TeamOutlined,
      label: 'Executive',
    },
    {
      hash: '#tile-settings',
      icon: SettingOutlined,
      label: 'Settings',
    },
    {
      hash: '#tile-program-manager-theme',
      icon: BgColorsOutlined,
      label: 'Program Manager Theme',
    },
    {
      hash: '#tile-program-manager-login-method',
      icon: AccountKeyOutlineIcon,
      label: 'Login method / SSO',
      hidden: !can('read', 'program-manager-login'),
    },
    {
      hash: '#tile-program-manager-api-keys',
      icon: KeyOutlined,
      label: 'Api Keys',
      hidden: !can('read', 'program-manager-api-keys'),
    },
    {
      hash: '#tile-program-manager-contacts',
      icon: IdcardOutlined,
      label: 'Contacts',
    },
    {
      hash: '#tile-program-manager-active-partners',
      icon: DeploymentUnitOutlined,
      label: 'Active Partners',
    },
    {
      hash: '#tile-program-manager-users',
      icon: UserOutlined,
      label: 'Users',
      hidden: !can('read', 'program-manager-user'),
    },
    {
      hash: '#tile-interfaces-overview',
      icon: TableOutlined,
      label: 'Available Interfaces',
    },
    {
      hash: '#tile-available-services',
      icon: CloudDownloadOutlined,
      label: 'Available Services',
      hidden: !can('read', 'program-manager-available-services'),
    },
  ];

  return (
    <PageHeader
      title={programManager?.name ?? '...'}
      subTitle={programManager?.payfac ? <Tag>Payment Facilitator</Tag> : ''}
      breadcrumbRender={() =>
        !programManagerContext && (
          <Breadcrumb
            map={{
              '/': <HomeOutlined />,
              '/program-managers': 'Program managers',
              [`/program-managers/${programManagerId}`]: programManager?.name ?? '...',
            }}
          />
        )
      }
      onBack={() => navigate('/program-managers')}
      extra={[
        can('delete', 'program-manager') && (
          <Button
            key="delete-program-manager"
            title="Delete program manager"
            icon={<DeleteOutlined />}
            shape="circle"
            onClick={handleDelete}
            disabled={programManager?.merchantCompanies.length}
          />
        ),
        <MetaProgramManager
          programManager={programManager}
        />,
        <ProgramManagerAvatar programManager={programManager} />,
      ]}
    >
      <HandleApolloError error={error}>
        <Layout className={styles.subPage}>
          <Sider className={styles.anchorSider} width={24}>
            <AnchorList anchors={anchors} />
          </Sider>
          <Content>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <ProgramManagerCompanyInformation id={programManagerId} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <ProgramManagerCompanyActivity id={programManagerId} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                <ProgramManagerCompanyMisc id={programManagerId} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                <ProgramManagerCompanyAddress id={programManagerId} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                <ProgramManagerCompanyExecutive id={programManagerId} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <ProgramManagerOptions id={programManagerId} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <ProgramManagerTheme id={programManagerId} />
              </Col>
              {can('read', 'program-manager-login') && (
                <>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <ProgramManagerLoginMethod id={programManagerId} />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <ProgramManagerSso id={programManagerId} />
                  </Col>
                </>
              )}
              {can('read', 'program-manager-api-keys') && (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <ProgramManagerApiKeys id={programManagerId} />
                </Col>
              )}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <ProgramManagerContacts id={programManagerId} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <ActivePartners id={programManagerId} />
              </Col>
              {can('read', 'program-manager-user') && (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <ProgramManagerUsers id={programManagerId} />
                </Col>
              )}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <AvailableInterfaces id={programManagerId} />
              </Col>
              {can('read', 'program-manager-available-services') && (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <AvailableServices id={programManagerId} />
                </Col>
              )}
            </Row>
          </Content>
        </Layout>
      </HandleApolloError>
    </PageHeader>
  );
};

export default ProgramManagerView;

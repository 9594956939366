import { gql } from '@apollo/client';

export const ProgramManagerQuery = gql`
  query ProgramManagerQuery($programManager: ID!) {
    programManager(id: $programManager) {
      __typename
      id
      name
      merchantCompanies {
        id
        name
        theme {
          icon
          primaryColor
        }
      }
      merchantAclProfiles {
        id
        name
      }
      theme {
        icon
        primaryColor
        primaryColor
        secondaryColor
      }
    }
  }
`;

export const CompanyListQuery = gql`
  {
    merchantCompanies {
      id
      name
      programManager {
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
    }
  }
`;

export const CompanyUserListQuery = gql`
  query CompanyUserListQuery($companyId: ID!) {
    merchantCompany(id: $companyId) {
      __typename
      id
      users {
        __typename
        id
        user {
          id
          full_name
          email
          avatar
        }
      }
    }
  }
`;

export const MerchantAccountCreateMutation = gql`
  mutation MerchantAccountCreate($input: MerchantAccountCreateInput!) {
    createMerchantAccount(input: $input) {
      id
      name
      company {
        id
        programManager {
          id
        }
      }
    }
  }
`;

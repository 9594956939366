import { createContext, useContext } from 'react';

const LayoutContext = createContext({
  isMenuCollapsed: true,
  toggleMenu: null,
});

export const useLayoutMenu = () => {
  const { isMenuCollapsed, toggleMenu } = useContext(LayoutContext);
  return [isMenuCollapsed, toggleMenu];
};

export default LayoutContext;

import { gql } from '@apollo/client';

export const programManagerCompanyMiscQuery = gql`
  query ProgramManagerCompanyMisc($id: ID!) {
    programManager(id: $id) {
      id
      company {
        id
        vatNumber
        ape
        duns
        capital
        numberEmployee
      }
    }
  }
`;

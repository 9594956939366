import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

const useFieldAsyncOptionList = (field) => {
  const { data, loading, error } = useQuery(field.filterOptions.asyncOptions.query, {
    errorPolicy: 'ignore',
  });

  const options = useMemo(() => field.filterOptions.asyncOptions.formatData(data) ?? [], [data]);

  return { options, loading, error };
};

export default useFieldAsyncOptionList;

import { gql } from '@apollo/client';
import { FragmentPartnerRouteOptions } from '../constants';

export const PartnerRoutesCreateMutation = gql`
  mutation PartnerRoutesCreate($input: [PartnerRouteInput!]!) {
    createPartnerRoutes(input: $input) {
      __typename
      id
      status
      authorization_currency
      settlement_currency
      channel
      paymentMethodName
      ${FragmentPartnerRouteOptions}
    }
  }
`;

export default {};

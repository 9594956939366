import {
  ApartmentOutlined,
  DeploymentUnitOutlined,
  NotificationOutlined,
  SafetyCertificateOutlined,
} from '@ant-design/icons';
import AccountMultipleIcon from '@2fd/ant-design-icons/lib/AccountMultiple';
import React from 'react';
import {useProgramManager} from '../../../contexts/programManager.context';

const ProgramManagerSettings = (can) => {
  const programManager = useProgramManager();

  return [
    {
      type: 'group',
      label: programManager.displayName,
      children: [
        ...(can('read', 'program-manager')
          ? [{ key: 'program-settings', icon: <ApartmentOutlined />, label: 'Settings' }]
          : []),
        ...(can('read', 'program-manager-active-partner')
          ? [{ key: 'program-partners', icon: <DeploymentUnitOutlined />, label: 'Partners' }]
          : []),
        ...(can('read', 'program-manager-user')
          ? [{ key: 'program-users', icon: <AccountMultipleIcon />, label: 'Internal users' }]
          : []),
        ...(can('read', 'program-manager-access-control')
          ? [
            {
              key: 'program-access-overview',
              icon: <SafetyCertificateOutlined />,
              label: 'Internal rights',
            },
          ]
          : []),
        ...(can('read', 'announcement')
          ? [{ key: 'announcements', icon: <NotificationOutlined />, label: 'Announcements' }]
          : []),
      ],
    },
  ]
}

export default ProgramManagerSettings;
import React, { useState } from 'react';
import { Button, Divider, Form, Input, message, Row, Select, Space, Tag, Typography } from 'antd';
import { EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useCan } from '../../../../../../contexts/ability.context';
import usePaymentMethods from '../../../../../../hooks/common/usePaymentMethods';
import InfoTile, { BaseRow, InfoRow, InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { httpMethodList } from '../../constants';
import styles from '../PartnerView.module.scss';
import { PartnerUpdateMutation } from '../query';
import SpecificEndpoints from './SpecificEndpoints';
import { validateTrimmedValue } from '../../../../Common/Form/utils';

const Endpoints = ({ partner }) => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);

  const paymentMethods = usePaymentMethods();

  const [updatePartner, { loading }] = useMutation(PartnerUpdateMutation, {
    onCompleted: () => {
      message.success('Partner successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    updatePartner({
      variables: {
        id: partner.id,
        input: values,
      },
    });
  };

  const initialValues = {
    base_url: partner.base_url,
    endpoints:
      partner.endpoints?.map((endpoint) => ({
        name: endpoint.name,
        method: endpoint.method,
        value: endpoint.value,
        specific: endpoint.specific || {},
      })) || [],
  };

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="endpoints" title="Endpoints">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          requiredMark={false}
          colon={false}
          className="tile-form"
          initialValues={initialValues}
        >
          <Form.Item
            name="base_url"
            label="Base URL"
            rules={[{ required: true, message: 'Missing value' }, { validator: validateTrimmedValue }]}
          >
            <Input />
          </Form.Item>
          <Form.List
            name="endpoints"
            rules={[
              {
                // eslint-disable-next-line consistent-return
                validator: async (_, endpoints) => {
                  // check endpoint with the same name
                  const names = endpoints?.map((e) => e?.name);
                  if (new Set(names).size !== names.length) {
                    return Promise.reject(new Error('Name should be unique.'));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => [
              ...fields.map(({ key, name, ...restField }) => (
                <Row key={key}>
                  <div style={{ display: 'inline-flex', gap: 8, width: '100%' }}>
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[{ required: true, message: 'Missing name' }]}
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'method']} initialValue="POST">
                      <Select style={{ width: 100 }} options={httpMethodList} />
                    </Form.Item>
                    <Form.Item
                      style={{ flex: 1 }}
                      {...restField}
                      name={[name, 'value']}
                      rules={[{ required: true, message: 'Missing value' }, { validator: validateTrimmedValue }]}
                    >
                      <Input placeholder="fake/path/PSP_TRANSACTION_ID" />
                    </Form.Item>
                    <Form.Item name={[name, 'specific']}>
                      <SpecificEndpoints partner={partner} baseForm={form} name={name} />
                    </Form.Item>
                    <Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} title="Remove endpoint" />
                    </Form.Item>
                  </div>
                </Row>
              )),
              <Form.Item key="add-endpoint">
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add endpoint
                </Button>
              </Form.Item>,
              <Form.ErrorList errors={errors} />,
            ]}
          </Form.List>
          <BaseRow label="Variables">
            <Typography.Text copyable>PSP_TRANSACTION_ID</Typography.Text>
            <Divider type="vertical" />
            <Typography.Text copyable>PSP_OPERATION_ID</Typography.Text>
          </BaseRow>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  return (
    <InfoTile
      name="endpoints"
      title="Endpoints"
      extra={[
        can('update', 'partner') && (
          <Button key="update-partner" onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        ),
      ]}
    >
      <InfoTextRow label="Base URL" value={partner.base_url} />
      {partner.endpoints?.map(({ name, method, value, specific }) => (
        <InfoRow key={name} label={name} value={value}>
          <Row>
            <Space>
              <Tag className={styles.methodTag}>{method}</Tag>
              {value}
            </Space>
          </Row>
          {Object.values(specific || {}).map((spe) => (
            <Row style={{ marginLeft: 56, marginTop: 8 }}>
              <Space>
                <img
                  src={paymentMethods[spe.payment_method]?.imgUrl}
                  title={paymentMethods[spe.payment_method]?.name || spe.payment_method}
                  alt=""
                  style={{ width: 24 }}
                />
                <Tag className={styles.methodTag}>{spe.method || method}</Tag>
                {spe.base_url}
                {spe.value}
              </Space>
            </Row>
          ))}
        </InfoRow>
      ))}
    </InfoTile>
  );
};

export default Endpoints;

import React from 'react';

import {Input, message, Modal, Form, Select, Space} from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { COUNTRY_LIST } from '../../../../../constants/COUNTRIES';
import { searchByProp } from '../../../../../util/string';
import { useResetFormOnCloseModal } from '../../../Common/Form/utils';
import styles from '../CompanyView/CompanyView.module.scss';
import { CompanyCreateMutation, ProgramManagerListQuery } from '../query';
import ProgramManagerAvatar from '../../../Common/ProgramManager/Avatar';
import useNavigateWithSearch from "../../../../../util/navigate";

const CompanyCreate = ({ visible, onCancel, programManager }) => {
  const navigate = useNavigateWithSearch();

  const [create, { loading }] = useMutation(CompanyCreateMutation, {
    refetchQueries: ['CompanyList'],
    onCompleted: ({ createMerchantCompany }) => {
      message.success('Company successfully created');
      navigate(`/companies/${createMerchantCompany.id}`);
    },
  });

  const [form] = Form.useForm();

  const programManagersQuery = useQuery(ProgramManagerListQuery);

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const onOk = () => {
    form.submit();
  };

  const submit = (values) => {
    create({
      variables: {
        createCompanyInput: values,
      },
    });
  };

  return (
    <Modal
      style={{
        maxHeight: '80vh',
        overflowY: 'auto',
        padding: 0,
      }}
      title="Create company"
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical" name="companyForm" onFinish={submit}>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Country" name="country">
          <Select
            showSearch
            placeholder="Select a country"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {COUNTRY_LIST.map((c) => (
              <Select.Option key={c.value} value={c.value}>
                <Space>
                  <img src={c.img} alt={c.value} className={styles.imgOption} />
                  {c.label}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="programManager"
          label="Program Manager"
          rules={[{ required: true }]}
          initialValue={programManager}
          hidden={programManager}
        >
          <Select
            allowClear
            showSearch
            filterOption={searchByProp('label')}
            optionLabelProp="label"
            loading={programManagersQuery.loading}
            disabled={!!programManager}
          >
            {programManagersQuery.data?.programManagers?.map((pm) => (
              <Select.Option key={pm.id} value={pm.id} label={pm.name}>
                <Space>
                  <ProgramManagerAvatar programManager={pm} />
                  {pm.name}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CompanyCreate;

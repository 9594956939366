import { amountFormatList } from '../../../../../constants/amountFormats';

export enum WidgetOptionTypes {
  Boolean,
  Select,
  MultiSelect,
  Slider,
}

enum WidgetOptions {
  logarithmicAxis = 'logarithmic_axis',
  sliceItems = 'slice_items',
  customerIdentifier = 'customer_identifier',
  complexAnalysisPeriod = 'complex_analysis_period',
  orientation = 'orientation',
  kpi = 'kpi',
  amountFormat = 'amount_format',
  displayUser = 'display_user',
}

export const config = {
  [WidgetOptions.logarithmicAxis]: {
    name: WidgetOptions.logarithmicAxis,
    label: 'Logarithmic Y axis',
    description: 'Apply logarithmic scale on Y axis',
    type: WidgetOptionTypes.Boolean,
  },
  [WidgetOptions.sliceItems]: {
    name: WidgetOptions.sliceItems,
    label: (value: number) => `Display ${value} items`,
    description: 'Limit the number of displayed dimension items',
    type: WidgetOptionTypes.Slider,
  },
  [WidgetOptions.customerIdentifier]: {
    name: WidgetOptions.customerIdentifier,
    label: 'Customer identifier',
    description: 'Field to use as customer identifier',
    type: WidgetOptionTypes.Select,
    options: [
      { value: 'customer_email', label: 'Customer email' },
      { value: 'customer_id', label: 'Customer ID' },
    ],
  },
  [WidgetOptions.complexAnalysisPeriod]: {
    name: WidgetOptions.complexAnalysisPeriod,
    label: 'Period',
    type: WidgetOptionTypes.Select,
    options: [
      { value: 'week', label: 'Week' },
      { value: 'month', label: 'Month' },
      { value: 'quarter', label: 'Quarter' },
      { value: 'year', label: 'Year' },
    ],
  },
  [WidgetOptions.orientation]: {
    name: WidgetOptions.orientation,
    label: 'Orientation',
    type: WidgetOptionTypes.Select,
    options: [
      { value: 'horizontal', label: 'Horizontal' },
      { value: 'vertical', label: 'Vertical' },
    ],
  },
  [WidgetOptions.kpi]: {
    name: WidgetOptions.kpi,
    label: 'KPI',
    type: WidgetOptionTypes.Select,
    options: [
      { value: 'authorize', label: 'Authorized Turnover' },
      { value: 'average_basket', label: 'Average Basket' },
      { value: 'capture', label: 'Capture Turnover' },
      { value: 'chargeback', label: ' Chargebacks Amount' },
      { value: 'refund', label: 'Refunds Amount' },
      { value: 'success_rate', label: 'Success Rate' },
      { value: 'matched_operations', label: 'Matched Tickets', isForbidden: (can: any) => !can('read', 'matcher') },
      { value: 'unmatched_operations', label: 'Unmatched Tickets', isForbidden: (can: any) => !can('read', 'matcher') },
      { value: 'pending_operations', label: 'Pending Tickets', isForbidden: (can: any) => !can('read', 'matcher') },
      {
        value: 'withdraw_operations',
        label: 'Withdraw Tickets',
        isForbidden: (can: any) => !(can('read', 'matcher') && can('read', 'matcher-withdraw')),
      },
      {
        value: 'matched_notifications',
        label: 'Matched Notifications',
        isForbidden: (can: any) => !can('read', 'matcher'),
      },
      {
        value: 'unmatched_notifications',
        label: 'Unmatched Notifications',
        isForbidden: (can: any) => !can('read', 'matcher'),
      },
    ],
  },
  [WidgetOptions.amountFormat]: {
    name: WidgetOptions.amountFormat,
    label: 'Amount format',
    type: WidgetOptionTypes.Select,
    options: amountFormatList.filter((f) => f.currency),
  },
  [WidgetOptions.displayUser]: {
    name: WidgetOptions.displayUser,
    label: 'Display author',
    type: WidgetOptionTypes.Boolean,
  },
};

export default WidgetOptions;

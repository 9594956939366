import { gql } from '@apollo/client';

export const FragmentCompanyInfo = `
  country
  legalForm
  address
  city
  state
  zipCode
  vatNumber
  ape
  duns
  mcc
  website
  numberEmployee
  dateStart
  directors
  capital
  turnover
  averageBasket
  ceo
  executive
  sharesHolder
  activity
  specialization
`;

export const CompanyListQuery = gql`
  query CompanyList {
    merchantCompanies {
      id
      name
      status
      country
      createdAt
      activity
      mcc
      userCount
      merchantAccountCount
      theme {
        icon
        primaryColor
      }
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
        metaProgramManager {
          id
          name
          theme {
            icon
            primaryColor
            secondaryColor
          }
        }
      }
      useCheckout
    }
  }
`;

export const CompanyViewQuery = gql`
  query CompanyViewQuery($companyId: ID!) {
    merchantCompany(id: $companyId) {
      id
      name
      status
      useCheckout
      theme {
        primaryColor
        secondaryColor
        icon
      }
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
    }
  }
`;

export const CompanyAuditLogsQuery = gql`
  query CompanyAuditLogsQuery($id: ID!) {
    merchantCompany(id: $id) {
      id
      createdAt
      createdBy {
        id
        full_name
        email
        avatar
      }
      updatedAt
      updatedBy {
        id
        full_name
        email
        avatar
      }
      auditLogs {
        id
        action
        entityId
        entityName
        timestamp
        createdBy {
          email
          full_name
          avatar
        }
      }
    }
  }
`;

export const CompanyCreateMutation = gql`
  mutation CompanyCreateMutation($createCompanyInput: CompanyCreateInput!) {
    createMerchantCompany(input: $createCompanyInput) {
      id
      name
      country
      createdAt
      useCheckout
      createdBy {
        id
        full_name
        avatar
      }
      updatedAt
      updatedBy {
        id
        full_name
        avatar
      }
      status
      programManager {
        id
        name
      }
    }
  }
`;

export const CompanyUpdateMutation = gql`
  mutation CompanyUpdateMutation($id: ID!, $input: CompanyUpdateInput!) {
    updateCompany(id: $id, input: $input) {
      id
      name
      ${FragmentCompanyInfo}
    }
  }
`;

export const CompanyUserProfileMutation = gql`
  mutation CompanyUserProfileMutation($id: ID!, $aclProfile: ID!) {
    updateCompanyUserProfile(id: $id, aclProfile: $aclProfile) {
      __typename
      id
      aclProfile {
        id
        name
      }
    }
  }
`;

export const CompanyUserIsGdprMutation = gql`
  mutation CompanyUserIsGdprMutation($id: ID!, $isGdpr: Boolean!) {
    updateCompanyUserIsGdpr(id: $id, isGdpr: $isGdpr) {
      id
      isGdpr
    }
  }
`;

export const CompanyUpdateCheckoutMutation = gql`
  mutation CompanyUpdateCheckoutMutation($id: ID!, $useCheckout: Boolean!) {
    updateCompany(id: $id, input: { useCheckout: $useCheckout }) {
      id
      useCheckout
    }
  }
`;

export const CompanyUserAuthorizedMerchantAccountMutation = gql`
  mutation CompanyUserAuthorizedMerchantAccountMutation($id: ID!, $merchantAccount: ID!, $toAdd: Boolean!) {
    updateCompanyUserAuthorizedMerchantAccount(id: $id, merchantAccount: $merchantAccount, toAdd: $toAdd) {
      id
      ... on MerchantCompanyUser {
        authorizedMerchantAccounts {
          id
        }
      }
    }
  }
`;

export const CompanyUserCreateMutation = gql`
  mutation CompanyUserCreateMutation($user: ID!, $company: ID!, $aclProfile: ID!, $isGdpr: Boolean) {
    createCompanyUser(user: $user, company: $company, aclProfile: $aclProfile, isGdpr: $isGdpr) {
      id
    }
  }
`;

export const CompanyUserDeleteMutation = gql`
  mutation CompanyUserDeleteMutation($id: ID!) {
    deleteCompanyUser(id: $id)
  }
`;

export const CompanyThemeUpdate = gql`
  mutation CompanyThemeUpdate($id: ID!, $theme: CompanyThemeInput!) {
    updateCompanyTheme(id: $id, theme: $theme) {
      __typename
      id
      theme {
        logo
        icon
        banner
        primaryColor
        secondaryColor
      }
    }
  }
`;

export const CompanyThemeImgUpload = gql`
  mutation CompanyThemeImgUpload($id: ID!, $type: CompanyImgType!, $file: Upload) {
    uploadCompanyThemeImg(id: $id, type: $type, file: $file) {
      __typename
      id
      theme {
        logo
        icon
        banner
        primaryColor
        secondaryColor
      }
    }
  }
`;

export const ProgramManagerListQuery = gql`
  query ProgramManagerListQuery {
    programManagers {
      __typename
      id
      name
      theme {
        icon
        primaryColor
        secondaryColor
      }
    }
  }
`;

export default {};

import React from 'react';
import { Row, Col } from 'antd';
import Page from '../../Common/Page/Page';
import Grid from './Grid/Grid';
import Header from './Header/Header';
import Timeline from './Timeline/Timeline';
import ProvidersDetails from './ProvidersDetails/ProvidersDetails';
import Maintenance from './Maintenance/Maintenance';
import styles from './OrderDetail.module.scss';

const OrderDetail = () => (
  <Page headerContent={<Maintenance />}>
    <div className={styles.root}>
      <Header />
      <div className={styles.body}>
        <Row>
          <Col flex="1 1 300px">
            <Timeline />
            <ProvidersDetails />
          </Col>
          <Col flex="10 1 386px">
            <Grid />
          </Col>
        </Row>
      </div>
    </div>
  </Page>
);

export default OrderDetail;

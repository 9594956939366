import { gql } from '@apollo/client';

export const createNotificationMutation = gql`
  mutation CreateWebhook($id: ID!, $input: WebhookInput!) {
    addWebhookMerchantAccount(id: $id, input: $input) {
      id
      webhooks {
        id
        name
        url
        status
        trigger
      }
    }
  }
`;

export default {};

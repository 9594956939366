import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Alert, Avatar, Button, Drawer, Popover, Space, Spin, Table } from 'antd';
import { Link } from '../../../../../util/navigate';
import ProgramManagerAvatar from '../../../Common/ProgramManager/Avatar';
import { ImpactMerchantContractsQuery } from './query';

const ImpactedContractsDetail = (props) => {
  const { selectedRouteIdList } = props;

  const [detailIsVisible, setDetailVisible] = useState(false);
  const showDetail = () => setDetailVisible(true);
  const hideDetail = () => setDetailVisible(false);

  const { data, loading } = useQuery(ImpactMerchantContractsQuery, {
    variables: { filters: { _id: selectedRouteIdList } },
  });

  const merchantContracts = useMemo(() => {
    if (!data) return [];
    return Object.values(
      data.partnerRoutes.data.reduce(
        (memo, partnerRoute) => ({
          ...memo,
          ...partnerRoute.merchantRoutes.reduce(
            (memo2, merchantRoute) =>
              merchantRoute.merchant_contract
                ? {
                    ...memo2,
                    [merchantRoute.merchant_contract.id]: {
                      key: merchantRoute.merchant_contract?.id,
                      merchantContractName: merchantRoute.merchant_contract?.name,
                      merchantAccountId: merchantRoute.merchant_account?.id,
                      merchantAccountName: merchantRoute.merchant_account?.name,
                      company: merchantRoute.merchant_account?.company,
                      programManager: merchantRoute.merchant_account?.programManager,
                      paymentMethods: {
                        ...memo[merchantRoute.merchant_contract.id]?.paymentMethods,
                        ...memo2[merchantRoute.merchant_contract.id]?.paymentMethods,
                        [partnerRoute.payment_method.id]: partnerRoute.payment_method,
                      },
                    },
                  }
                : memo2,
            {},
          ),
        }),
        {},
      ),
    );
  }, [data]);

  if (loading) {
    return (
      <Spin spinning>
        <Alert showIcon type="info" message="Checking merchant contracts impacts." />
      </Spin>
    );
  }

  if (merchantContracts.length === 0) {
    return <Alert showIcon type="success" message="No merchant contracts are impacted by this update." />;
  }

  const columns = [
    {
      title: 'Contract',
      dataIndex: 'merchantContractName',
      key: 'merchantContractName',
      onFilter: (value, record) => value === record.merchantContractName,
      fixed: 'left',
      ellipsis: true,
    },
    {
      title: 'Payment methods',
      dataIndex: 'paymentMethods',
      key: 'paymentMethods',
      render: (value) => (
        <Space>
          {Object.values(value)
            .slice(0, 5)
            .map((pm) => (
              <img key={pm.id} src={pm.imgUrl} alt="" style={{ width: 24 }} title={pm.name} />
            ))}
          {Object.values(value).length > 5 && (
            <Popover
              content={
                <Space>
                  {Object.values(value).map((pm) => (
                    <img key={pm.id} src={pm.imgUrl} alt="" style={{ width: 32 }} title={pm.name} />
                  ))}
                </Space>
              }
            >
              <span style={{ fontSize: 11 }}>+{Object.values(value).length - 5}</span>
            </Popover>
          )}
        </Space>
      ),
    },
    {
      title: 'Merchant Account',
      dataIndex: 'merchantAccountName',
      key: 'merchantAccountName',
      render: (value, record) => (
        <Link to={`/merchant-accounts/${record.merchantAccountId}`}>
          <Space>
            <Avatar src={record.company?.theme?.icon} size="small" />
            {value}
          </Space>
        </Link>
      ),
      onFilter: (value, record) => value === record.merchantAccountName,
      ellipsis: true,
    },
    {
      title: 'PM',
      dataIndex: 'programManager',
      key: 'programManager',
      width: 40,
      render: (value, record) => <ProgramManagerAvatar programManager={record.programManager} />,
      onFilter: (value, record) => value === record.programManager?.name,
      ellipsis: true,
    },
  ];

  return [
    <Alert
      key="alert"
      type="warning"
      showIcon
      message={`${merchantContracts.length} merchant contracts are impacted by this update.`}
      action={
        <Button key="show-detail" onClick={showDetail} size="small">
          Details
        </Button>
      }
    />,
    <Drawer
      key="contracts-drawer"
      title="Impacted merchant contracts"
      onClose={hideDetail}
      open={detailIsVisible}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 660, width: '70%' }} // responsive width
      width="100%"
    >
      <Table
        columns={columns}
        dataSource={merchantContracts}
        size="small"
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} routes`,
          defaultPageSize: 1000,
          pageSizeOptions: [20, 50, 100, 500, 1000],
          hideOnSinglePage: true,
        }}
      />
    </Drawer>,
  ];
};

export default ImpactedContractsDetail;

import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Button, Modal, Row, Space } from 'antd';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import useToggle from '../../../../../../hooks/useToggle';
import { useProgramManager } from '../../../../../../contexts/programManager.context';
import useSearchFilters from '../../../hooks/useSearchFilters';
import useBaseCurrency from '../../../hooks/useBaseCurrency';
import useBaseGranularity from '../../../hooks/useBaseGranularity';
import { config as WidgetTypes } from '../../constants/widgetTypes';
import ChartExportMenu from '../Common/ChartExportMenu';
import LoadingChart from '../Common/LoadingChart';
import ErrorChart from '../Common/ErrorChart';
import NoDataChart from '../Common/NoDataChart';
import { brainpowerQuery } from '../Common/query';
import generateChart from '../Charts';
import styles from './chart.module.scss';
import { config as Dimensions } from '../../constants/dimensions';
import { config as Indicators } from '../../constants/indicators';
import Axis from '../../constants/axis';
import { useCan } from '../../../../../../contexts/ability.context';
import ForbiddenChart from '../Common/ForbiddenChart';

const Chart = (props) => {
  const { widget } = props;

  const can = useCan();

  const [baseCurrency] = useBaseCurrency();
  const [baseGranularity] = useBaseGranularity();
  const { list } = useSearchFilters();
  const timezone = 'UTC';

  const widgetType = useMemo(() => {
    if (!WidgetTypes[widget.type]) {
      throw new Error(`Widget type ${widget.type} does not exist.`, widget.i);
    }
    return WidgetTypes[widget.type];
  }, [widget]);

  const isForbidden = useMemo(
    () =>
      widget.dimensions?.some((d) => {
        const dimension = Dimensions[d];
        if (!dimension) throw new Error(`Dimension ${d} does not exist.`, widget.id);
        return dimension.isForbidden?.(can);
      }) ||
      widget.indicators?.some((i) => {
        const indicator = Indicators[i];
        if (!indicator) throw new Error(`Indicator ${i} does not exist.`, widget.id);
        return indicator.isForbidden?.(can);
      }),
    [widget, can],
  );

  const params = useMemo(
    () => ({
      filters: list.map((filter) => ({
        name: filter.key,
        value: filter.value,
        excluded: filter.excluded,
      })),
      indicators: widget.indicators?.slice(0, widgetType.maxIndicators) ?? [],
      dimensions:
        widget.dimensions
          ?.slice(0, widgetType.maxDimensions)
          .filter((d, index) => !(Dimensions[d]?.axis === Axis.Date && index > 0)) ?? [],
      timezone,
      currency: baseCurrency,
      granularity: baseGranularity,
      ...widgetType.params?.(widget),
    }),
    [list, baseCurrency, baseGranularity, widget, widgetType],
  );

  const { data, loading, error } = useApolloQuery(brainpowerQuery, {
    variables: {
      service: widgetType.endpoint,
      parameters: params,
    },
    skip: isForbidden,
  });

  const isEmpty = !(data?.brainpower?.data && Object.keys(data?.brainpower?.data).length > 0);

  const [isFullscreen, { toggle: toggleFullscreen }] = useToggle(false);

  const [chartObject, setChartObject] = useState();
  const [chartFullscreenObject, setChartFullscreenObject] = useState();

  const programManager = useProgramManager();

  useLayoutEffect(() => {
    if (!isEmpty) {
      const chart = generateChart(
        `chartdiv-${widget.i}`,
        widget,
        { ...data?.brainpower },
        {
          currency: baseCurrency,
          granularity: baseGranularity,
          programManagerName: programManager?.displayName ?? 'NORBr',
        },
      );
      setChartObject(chart);

      let fullscreenChart;
      if (isFullscreen) {
        fullscreenChart = generateChart(
          `fullscreen-chartdiv-${widget.i}`,
          widget,
          { ...data?.brainpower },
          {
            currency: baseCurrency,
            granularity: baseGranularity,
            programManagerName: programManager?.displayName ?? 'NORBr',
          },
        );
        setChartFullscreenObject(fullscreenChart);
      }

      return () => {
        chart.dispose();
        if (fullscreenChart) fullscreenChart.dispose();
      };
    }
    return () => {};
  }, [isEmpty, data, widget.options, isFullscreen]);

  let content;
  if (loading && isEmpty) {
    content = <LoadingChart />;
  } else if (isForbidden) {
    content = <ForbiddenChart />;
  } else if (error) {
    content = <ErrorChart error={error} />;
  } else if (isEmpty) {
    content = <NoDataChart />;
  } else {
    return (
      <div className={styles.root}>
        <Row justify="space-between" align="middle">
          <div className={styles.title}>{widget.name}</div>
          <Space size={2}>
            {widgetType.exportable && <ChartExportMenu chart={chartObject} widget={widget} data={data?.brainpower} />}
            <Button type="text" icon={<FullscreenOutlined />} onClick={toggleFullscreen} />
          </Space>
        </Row>
        <div id={`chartdiv-${widget.i}`} className={styles.chartdiv} />
        <Modal
          open={isFullscreen}
          footer={null}
          closable={false}
          width="95%"
          destroyOnClose
          onCancel={toggleFullscreen}
          forceRender
          bodyStyle={{ height: '95vh', padding: '8px 12px' }}
          className={styles.modal}
        >
          <Row justify="space-between" align="middle">
            <div className={styles.title}>{widget.name}</div>
            <Space>
              {widgetType.exportable && (
                <ChartExportMenu chart={chartFullscreenObject} widget={widget} data={data?.brainpower} />
              )}
              <Button type="text" icon={<FullscreenExitOutlined />} onClick={toggleFullscreen} />
            </Space>
          </Row>
          <div id={`fullscreen-chartdiv-${widget.i}`} className={styles.fullscreenChartdiv} />
        </Modal>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Row justify="space-between" align="middle">
        <div className={styles.title}>{widget.name}</div>
      </Row>
      {content}
    </div>
  );
};

export default Chart;

import React from 'react';
import PageFallback from './PageFallback';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.
    // eslint-disable-next-line no-console
    console.warn(error, errorInfo);
  }

  render() {
    const { children, fallback = <PageFallback /> } = this.props;
    const { hasError } = this.state;
    return hasError ? fallback : children;
  }
}

export default ErrorBoundary;

import React, { useMemo } from 'react';
import { Row, Table as AntTable } from 'antd';
import { useQuery as useApolloQuery } from '@apollo/client';
import moment from 'moment-timezone';
import WITHDRAW_REASONS from 'norbr-shared-lib/constants/transaction/withdrawReasons';
import { brainpowerQuery } from '../Common/query';
import useSearchFilters from '../../../hooks/useSearchFilters';
import { DATETIME2_FORMAT } from '../../../../../../constants/DATES';
import { getPreviousDateRange, periodTypeToCustom } from '../../../../../../util/date';
import useBaseCurrency from '../../../hooks/useBaseCurrency';
import styles from './chart.module.scss';
import DataExportMenu from '../Common/DataExportMenu';
import { compareByProp } from '../../../../../../util/array';
import useBaseGranularity from '../../../hooks/useBaseGranularity';
import { renderListCell } from '../../../Common/AdvancedTable/buildColumns';
import { useCan } from '../../../../../../contexts/ability.context';
import useAuthors from '../../../../../../hooks/common/useAuthors';

const MatcherWithdrawReasons = (props) => {
  const { widget, layout } = props;

  const can = useCan();
  const [currency] = useBaseCurrency();
  const [baseGranularity] = useBaseGranularity();
  const authors = useAuthors();

  const { dateFilter, list } = useSearchFilters();

  const parameters = {
    widget: widget.options?.kpi,
    filters: list.map((filter) => {
      if (filter.key === dateFilter.key) {
        return {
          dates: filter.value.period
            ? periodTypeToCustom(filter.value.period)
            : {
                from: moment(filter.value.from, DATETIME2_FORMAT).startOf('minute'),
                to: moment(filter.value.to, DATETIME2_FORMAT).endOf('minute'),
                ...getPreviousDateRange(
                  moment(filter.value.from, DATETIME2_FORMAT),
                  moment(filter.value.to, DATETIME2_FORMAT),
                ),
              },
        };
      }
      return {
        name: filter.key,
        value: filter.value,
        excluded: filter.excluded,
      };
    }),
    granularity: baseGranularity,
    currency,
    display_user: can('read', 'user') && widget.options?.display_user,
  };

  const { data, loading, error } = useApolloQuery(brainpowerQuery, {
    variables: {
      service: 'matcher_withdraw_reasons',
      parameters,
    },
  });

  const columns = useMemo(() => [
    {
      title: 'Store',
      dataIndex: 'store',
      key: 'store',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      sorter: {
        compare: compareByProp('store', 'asc', 'string'),
        multiple: 1,
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      defaultSortOrder: 'descend',
      sorter: {
        compare: compareByProp('date', 'asc', 'string'),
        multiple: 2,
      },
    },
    {
      title: 'Reason',
      dataIndex: 'withdraw_reason',
      key: 'withdraw_reason',
      render: renderListCell({
        displayOptions: {
          getOption: (value) => WITHDRAW_REASONS[value],
        },
      }),
      defaultSortOrder: 'descend',
      sorter: {
        compare: compareByProp('withdraw_reason', 'asc', 'string'),
        multiple: 3,
      },
    },
    ...(can('read', 'user') && widget.options?.display_user
      ? [
          {
            title: 'Author',
            dataIndex: 'user_id',
            key: 'user_id',
            render: renderListCell({
              displayOptions: {
                avatar: true,
                getOption: (value) => {
                  const author = authors?.[value];
                  if (!author) return null;
                  return {
                    value: author.id,
                    label: author.full_name,
                    img: author.avatar,
                    title: author.email,
                  };
                },
              },
            }),
            defaultSortOrder: 'ascend',
            sorter: {
              compare: compareByProp('user_id', 'asc', 'string'),
              multiple: 4,
            },
          },
        ]
      : []),
    {
      title: 'Operations',
      dataIndex: 'operations',
      key: 'operations',
      align: 'right',
      width: 120,
    },
  ]);

  // height * unit + (height-1) * margin - tile padding - tile header - table header
  const tableHeight = layout.h * 36 + (layout.h - 1) * 24 - 2 * 8 - 32 - 39;

  return (
    <div className={styles.root}>
      <Row justify="space-between" align="middle">
        <div className={styles.title}>{widget.name}</div>
        <DataExportMenu data={data?.brainpower.data} widget={widget} />
      </Row>
      <AntTable
        dataSource={data?.brainpower.data}
        columns={columns}
        loading={loading}
        scroll={{ x: 'max-content', y: tableHeight }}
        size="small"
        pagination={false}
      />
    </div>
  );
};

export default MatcherWithdrawReasons;

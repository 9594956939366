import React, { useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useQuery, useMutation } from '@apollo/client';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useDebounce, useMap } from 'react-use';
import useBreakpoint from '../../../../../hooks/useBreakpoint';
import LoadingPage from '../../../Common/Pages/Loading';
import TileBuilder from './DataTiles/TileBuilder';
import defaultLayouts, { initLayoutTileSize } from './defaultLayouts';
import styles from './Grid.module.scss';
import { settingsQuery, updateOrderLayoutsMutation } from './query';

const GridLayout = WidthProvider(Responsive);

const HEIGHT_UNIT = 100;
const WIDTH_UNIT = 400;
const GRID_MARGIN = 40;
const GRID_PADDING = 12;
const TILE_PADDING = 12;

const MENU_WIDTH = 206;
const TIMELINE_WIDTH = 300;
const USED_WIDTH = MENU_WIDTH + TIMELINE_WIDTH;

// compute grid width from number of columns based on defined constants
const computeGridWidthFromColumns = (nbCol) => nbCol * WIDTH_UNIT + (nbCol - 1) * GRID_MARGIN + 2 * GRID_PADDING;

// screen breakpoints - optimize render with defined constants
const breakpoints = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: computeGridWidthFromColumns(2) + USED_WIDTH,
  xxl: computeGridWidthFromColumns(3) + USED_WIDTH,
};

// grid breakpoints - optimize render with defined constants
const gridBreakpoints = {
  lg: 0,
  xl: computeGridWidthFromColumns(2) - 20,
  xxl: computeGridWidthFromColumns(3) - 20,
};

const COLS = { xxl: 3, xl: 2, lg: 1 };

// hook - get grid width from current breakpoint
const useGridWidth = () => {
  const breakpoint = useBreakpoint(breakpoints);
  switch (breakpoint) {
    case undefined:
      return undefined;
    case 'xxl': // > 1600
      return computeGridWidthFromColumns(3);
    case 'xl': // > 1200 (macbook pro = 1440)
      return computeGridWidthFromColumns(2);
    case 'lg': // > 992
    case 'md': // > 768
    case 'sm': // > 576
    case 'xs': // < 576
    default:
      return computeGridWidthFromColumns(1);
  }
};

const Grid = () => {
  const gridWidth = useGridWidth();

  // layouts are manage in local state
  // init by query on user settings or defaultLayouts
  // then update by a debounced call
  const [layouts, setLayouts] = useState();
  useQuery(settingsQuery, {
    onCompleted: ({ me }) => {
      if (layouts === undefined) {
        setLayouts(initLayoutTileSize(me.orderLayouts ?? defaultLayouts));
      }
    },
  });
  const [setSettings] = useMutation(updateOrderLayoutsMutation);
  useDebounce(() => layouts && setSettings({ variables: { layouts } }), 400, [layouts]);

  // callback function used to dynamically update height of block in layout (full width table blocks)
  const [heightChanges, { set: handleChangeHeight, reset }] = useMap({});
  useEffect(() => {
    if (Object.keys(heightChanges).length) {
      setLayouts((prevLayout) =>
        Object.keys(prevLayout).reduce(
          (memo, screenSize) => ({
            ...memo,
            [screenSize]: prevLayout[screenSize].map((item) => {
              if (heightChanges[item.i]) {
                const x = (heightChanges[item.i] + GRID_MARGIN + 2 * TILE_PADDING) / (HEIGHT_UNIT + GRID_MARGIN);
                return { ...item, h: Math.ceil(x) };
              }
              return item;
            }),
          }),
          {},
        ),
      );
      reset();
    }
  }, [heightChanges]);

  // do not render grid if width is not yet computed
  if (!gridWidth || !layouts) {
    return <LoadingPage />;
  }

  return (
    <div style={{ margin: 'auto', width: gridWidth }}>
      <GridLayout
        layouts={layouts}
        margin={[GRID_MARGIN, GRID_MARGIN]} // margin between items
        containerPadding={[GRID_PADDING, GRID_PADDING]} // grid padding
        rowHeight={HEIGHT_UNIT} // height unit
        isBounded
        isDroppable
        isResizable={false}
        cols={COLS}
        width={gridWidth}
        breakpoints={gridBreakpoints}
        onLayoutChange={(layout, _layouts) => {
          setLayouts(_layouts);
        }}
        // verticalCompact={false}
      >
        {layouts.lg.map((item) => (
          <div key={item.i} className={styles.tileContainer}>
            <TileBuilder name={item.i} onChangeHeight={handleChangeHeight} />
          </div>
        ))}
      </GridLayout>
    </div>
  );
};

export default Grid;

import {
  DeploymentUnitOutlined,
  NotificationOutlined, PartitionOutlined,
  SafetyCertificateOutlined,
} from '@ant-design/icons';
import AccountMultipleIcon from '@2fd/ant-design-icons/lib/AccountMultiple';
import React from 'react';
import {useMetaProgramManager} from '../../../contexts/metaProgramManager.context';

const MetaProgramManagerSettings = (can) => {
  const metaProgramManager = useMetaProgramManager();

  return [
    {
      type: 'group',
      label: metaProgramManager?.displayName,
      children: [
        ...(can('read', 'meta-program-manager')
          ? [{ key: 'meta-program-settings', icon: <PartitionOutlined />, label: 'Settings' }]
          : []),
        ...(can('read', 'meta-program-manager-active-partner')
          ? [{ key: 'meta-program-partners', icon: <DeploymentUnitOutlined />, label: 'Partners' }]
          : []),
        ...(can('read', 'meta-program-manager-user')
          ? [{ key: 'meta-program-users', icon: <AccountMultipleIcon />, label: 'Internal users' }]
          : []),
        ...(can('read', 'meta-program-manager-access-control')
          ? [
            {
              key: 'meta-program-access-overview',
              icon: <SafetyCertificateOutlined />,
              label: 'Internal rights',
            },
          ]
          : []),
        ...(can('read', 'announcement')
          ? [{ key: 'announcements', icon: <NotificationOutlined />, label: 'Announcements' }]
          : []),
      ],
    },
  ];
}

export default MetaProgramManagerSettings;
import moment from 'moment-timezone';
import { DATE_FORMAT } from './DATES';

export const Periods = {
  LAST_HOUR: 'last_hour',
  LAST_4_HOURS: 'last_4_hours',
  LAST_12_HOURS: 'last_12_hours',
  LAST_24_HOURS: 'last_24_hours',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  CURRENT_WEEK: 'current_week',
  PREVIOUS_WEEK: 'previous_week',
  CURRENT_MONTH: 'current_month',
  PREVIOUS_MONTH: 'previous_month',
  CURRENT_QUARTER: 'current_quarter',
  PREVIOUS_QUARTER: 'previous_quarter',
  ALL: 'all',
};

const periods = () => ({
  [Periods.LAST_HOUR]: {
    value: Periods.LAST_HOUR,
    label: 'Last hour',
    from: moment().subtract(1, 'hour'),
    to: moment(),
    description: [`from ${moment().subtract(1, 'hour').format('HH:mm')} to ${moment().format('HH:mm')}`],
  },
  [Periods.LAST_4_HOURS]: {
    value: Periods.LAST_4_HOURS,
    label: 'Last 4 hours',
    from: moment().subtract(4, 'hours'),
    to: moment(),
    description: [`from ${moment().subtract(4, 'hours').format('HH:mm')} to ${moment().format('HH:mm')}`],
  },
  [Periods.LAST_12_HOURS]: {
    value: Periods.LAST_12_HOURS,
    label: 'Last 12 hours',
    from: moment().subtract(12, 'hours'),
    to: moment(),
    description: [`from ${moment().subtract(12, 'hours').format('HH:mm')} to ${moment().format('HH:mm')}`],
  },
  [Periods.LAST_24_HOURS]: {
    value: Periods.LAST_24_HOURS,
    label: 'Last 24 hours',
    from: moment().subtract(24, 'hours'),
    to: moment(),
    description: [`from ${moment().subtract(24, 'hours').format('HH:mm')} to ${moment().format('HH:mm')}`],
  },
  [Periods.TODAY]: {
    value: Periods.TODAY,
    label: 'Today',
    from: moment().startOf('day'),
    to: moment().endOf('day'),
    description: [moment().format(DATE_FORMAT)],
  },
  [Periods.YESTERDAY]: {
    value: Periods.YESTERDAY,
    label: 'Yesterday',
    from: moment().subtract(1, 'day').startOf('day'),
    to: moment().subtract(1, 'day').endOf('day'),
    description: [moment().subtract(1, 'day').format(DATE_FORMAT)],
  },
  [Periods.CURRENT_WEEK]: {
    value: Periods.CURRENT_WEEK,
    label: 'Current week',
    from: moment().startOf('week'),
    to: moment().endOf('week'),
    description: [`from ${moment().startOf('week').format('DD MMM')}`, `to ${moment().endOf('week').format('DD MMM')}`],
  },
  [Periods.PREVIOUS_WEEK]: {
    value: Periods.PREVIOUS_WEEK,
    label: 'Previous week',
    from: moment().subtract(1, 'week').startOf('week'),
    to: moment().subtract(1, 'week').endOf('week'),
    description: [
      `from ${moment().subtract(1, 'week').startOf('week').format('DD MMM')}`,
      `to ${moment().subtract(1, 'week').endOf('week').format('DD MMM')}`,
    ],
  },
  [Periods.CURRENT_MONTH]: {
    value: Periods.CURRENT_MONTH,
    label: 'Current month',
    from: moment().startOf('month'),
    to: moment().endOf('month'),
    description: [moment().format('MMMM YYYY')],
  },
  [Periods.PREVIOUS_MONTH]: {
    value: Periods.PREVIOUS_MONTH,
    label: 'Previous month',
    from: moment().subtract(1, 'month').startOf('month'),
    to: moment().subtract(1, 'month').endOf('month'),
    description: [moment().subtract(1, 'month').format('MMMM YYYY')],
  },
  [Periods.CURRENT_QUARTER]: {
    value: Periods.CURRENT_QUARTER,
    label: 'Current quarter',
    from: moment().startOf('quarter'),
    to: moment().endOf('quarter'),
    description: [
      `from ${moment().startOf('quarter').format(DATE_FORMAT)}`,
      `to ${moment().endOf('quarter').format(DATE_FORMAT)}`,
    ],
  },
  [Periods.PREVIOUS_QUARTER]: {
    value: Periods.PREVIOUS_QUARTER,
    label: 'Previous quarter',
    from: moment().subtract(1, 'quarter').startOf('quarter'),
    to: moment().subtract(1, 'quarter').endOf('quarter'),
    description: [
      `from ${moment().subtract(1, 'quarter').startOf('quarter').format(DATE_FORMAT)}`,
      `to ${moment().subtract(1, 'quarter').endOf('quarter').format(DATE_FORMAT)}`,
    ],
  },
  [Periods.ALL]: {
    value: Periods.ALL,
    label: 'All',
    from: moment('2020-01-01').startOf('day'),
    to: moment().endOf('day'),
    description: [`from ${moment('2020-01-01').format('YYYY')}`],
  },
});

export default periods;

import { List, Space } from 'antd';
import React from 'react';
import classnames from 'classnames';
import PinBadge from '../PinBadge/PinBadge';
import styles from './DrawerListItem.module.scss';

const DrawerListItem = ({ label, icon: Icon, extra, onClick, pinned = false, active = false }) => (
  <List.Item
    onClick={onClick}
    extra={<Space className={styles.editBtn}>{extra}</Space>}
    className={classnames(styles.listItem, { [styles.active]: active })}
  >
    {pinned && <PinBadge />}
    <div className={styles.flexContent}>
      {Icon && <Icon />}
      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {label}
      </div>
    </div>
  </List.Item>
);

export default DrawerListItem;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Page from '../../Common/Page/Page';
import ErrorBoundary from '../../Common/ErrorBoundary/ErrorBoundary';
import MetaProgramManagerList from './MetaProgramManagerList/MetaProgramManagerList';
import MetaProgramManagerView from './MetaProgramManagerView/MetaProgramManagerView';

const MetaProgramManagers = () => (
  <Page>
    <ErrorBoundary>
      <Routes>
        <Route index element={<MetaProgramManagerList />} />
        <Route path=":metaProgramManagerId" element={<MetaProgramManagerView />} />
      </Routes>
    </ErrorBoundary>
  </Page>
);

export default MetaProgramManagers;

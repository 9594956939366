import { gql } from '@apollo/client';

export const updateApiKeyMutation = gql`
  mutation UpdateApiKey($id: ID!, $input: ApiKeyInput!) {
    updateApiKey(id: $id, input: $input) {
      id
      name
      is_public
      status
    }
  }
`;

export const apiKeyQuery = gql`
  query ApiKey($id: ID!) {
    apiKey(id: $id) {
      id
      key
      status
      createdAt
      name
      is_public
    }
  }
`;

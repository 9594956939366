import { DataRoles } from '../Matcher/MatcherCashier/fields';
import useColumnSet from './useColumnSet';
import usePage from './usePage';

// Dimensions are deduced from columnSet and dataRole
const useDimensions = () => {
  const page = usePage();
  const [columnSet] = useColumnSet();
  const dimensions = columnSet
    .map((c) => c.field)
    .filter((fieldKey) => page.fields[fieldKey]?.dataRole === DataRoles.DIMENSION);

  return [dimensions];
};

export default useDimensions;

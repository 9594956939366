import React, { useContext, useEffect } from 'react';
import { Form, Select } from 'antd';
import { useLazyQuery as useApolloLazyQuery } from '@apollo/client/react/hooks/useLazyQuery';
import { MerchantContractContext } from '../../../MerchantContractDrawer';
import { contractsByAccountQuery } from '../../../query';
import { useMerchantAccount } from '../../../../../../merchantAccount.context';
import { ContractEditionContext } from '../../../../../PaymentNetwork';

const { Item } = Form;

const MerchantContractAuthentication = () => {
  const { merchantContract, updateMerchantContract } = useContext(MerchantContractContext);
  const { isEdited } = useContext(ContractEditionContext);
  const { selectedMerchantAccount } = useMerchantAccount();
  const [getAuthenticationPartners, { loading, data }] = useApolloLazyQuery(contractsByAccountQuery);

  useEffect(() => {
    if (selectedMerchantAccount) {
      getAuthenticationPartners({
        variables: {
          merchantAccountId: selectedMerchantAccount,
        },
      });
    }
  }, [selectedMerchantAccount]);

  return (
    <Item label="Authentication">
      <Select
        disabled={!isEdited}
        value={merchantContract?.authentication_contract?.id || merchantContract?.authentication_managed}
        onChange={(value) => {
          let input;
          let optimistic;
          if (value === 'byPsp') {
            input = {
              authentication_managed: value,
              authentication_contract: null,
            };
            optimistic = input;
          } else {
            input = {
              authentication_managed: 'by3rdParty',
              authentication_contract: value,
            };
            optimistic = {
              authentication_managed: 'by3rdParty',
              authentication_contract: data?.merchantAccount?.merchant_contracts.find(
                (merchantContractElem) => merchantContractElem.id === value,
              ),
            };
          }
          updateMerchantContract({
            variables: {
              input,
            },
            optimisticResponse: {
              updateContract: {
                ...merchantContract,
                ...optimistic,
              },
            },
          });
        }}
        options={[
          {
            value: 'byPsp',
            label: 'Managed by PSP',
          },
          ...(data?.merchantAccount?.merchant_contracts
            ?.filter((merchantContractElem) => merchantContractElem.type === 'authentication')
            ?.map((merchantContractElem) => ({
              value: merchantContractElem.id,
              label: merchantContractElem.partner.name,
            })) ?? []),
        ]}
        loading={loading}
      />
    </Item>
  );
};

export default MerchantContractAuthentication;

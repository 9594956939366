import { gql } from '@apollo/client';

export const WebhookAddMutation = gql`
  mutation WebhookAddMutation($merchantAccountId: ID!, $input: WebhookInput!) {
    addWebhookMerchantAccount(id: $merchantAccountId, input: $input) {
      __typename
      id
      webhooks {
        __typename
        id
        name
        url
        trigger
        status
        notify_last_transaction_only
      }
    }
  }
`;

export const WebhookUpdateMutation = gql`
  mutation WebhookUpdateMutation($id: ID!, $input: WebhookInput!) {
    updateWebhook(id: $id, input: $input) {
      __typename
      id
      name
      url
      trigger
      status
      notify_last_transaction_only
    }
  }
`;

export default {};

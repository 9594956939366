import { gql } from '@apollo/client';

export const programManagerCompanyQuery = gql`
  query ProgramManagerCompany($id: ID!) {
    programManager(id: $id) {
      id
      company {
        id
        name
        country
      }
    }
  }
`;

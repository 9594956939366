import React, { useState } from 'react';
import { Layout, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import ForwardburgerIcon from '@2fd/ant-design-icons/lib/Forwardburger';
import BackburgerIcon from '@2fd/ant-design-icons/lib/Backburger';
import SwapVerticalCircleIcon from '@2fd/ant-design-icons/lib/SwapVerticalCircle';
import FeatureSearchIcon from '@2fd/ant-design-icons/lib/FeatureSearch';
import CogBoxIcon from '@2fd/ant-design-icons/lib/CogBox';
import brainpowerStyles from '../../Brainpower.module.scss';
import { formatEndUnderline } from '../../Common/utils';
import HeaderButton from '../../Common/HeaderButton/HeaderButton';
import Drawers from '../../Common/constants/drawers';
import SetUpDrawer from './SetUpDrawer';
import SearchDrawer from '../MatcherCashier/SearchDrawer/SearchDrawer';
import { DrawerContext } from '../../Common/useDrawer';
import { useLayoutMenu } from '../../../../layout.context';
import MatcherPaymentPartnerTable from './MatcherPaymentPartnerTable/MatcherPaymentPartnerTable';
import usePage from '../../hooks/usePage';
import useSearchFilters from '../../hooks/useSearchFilters';

const MatcherPaymentPartner = () => {
  const [isMenuCollapsed, toggleMenu] = useLayoutMenu();
  const page = usePage();

  const [drawerState, setDrawerState] = useState();

  const { searchFilters, setAllAndRedirect: setSearchFilters } = useSearchFilters();

  const setSearchFiltersWithPage = (value) => {
    setSearchFilters(
      {
        searchFilters: value,
      },
      page.path,
    );
  };

  const handleToggleDrawer = (key) => () => setDrawerState(drawerState !== key ? key : undefined);
  const handleCloseDrawer = () => setDrawerState(undefined);

  return (
    <DrawerContext.Provider value={[drawerState, setDrawerState]}>
      <Layout className={brainpowerStyles.root}>
        <PageHeader
          ghost={false}
          className={brainpowerStyles.pageHeader}
          backIcon={isMenuCollapsed ? <ForwardburgerIcon /> : <BackburgerIcon />}
          onBack={toggleMenu}
          title={
            <Space>
              <SwapVerticalCircleIcon />
              {formatEndUnderline(page.label)}
            </Space>
          }
          extra={[
            <HeaderButton
              drawerState={drawerState}
              key={Drawers.SEARCH}
              id={Drawers.SEARCH}
              label="SEARCH"
              icon={FeatureSearchIcon}
              onClick={handleToggleDrawer(Drawers.SEARCH)}
            />,
            <HeaderButton
              drawerState={drawerState}
              key={Drawers.SETUP}
              id={Drawers.SETUP}
              label="DISPLAY"
              icon={CogBoxIcon}
              onClick={handleToggleDrawer(Drawers.SETUP)}
            />,
          ]}
        />
        <MatcherPaymentPartnerTable searchFilters={searchFilters ?? {}} onSearch={setSearchFiltersWithPage} />
        <SearchDrawer
          open={drawerState === Drawers.SEARCH}
          onClose={handleCloseDrawer}
          containerId="matcher-payment-partner-table"
          onSearch={setSearchFiltersWithPage}
          searchFilters={searchFilters ?? {}}
        />
        <SetUpDrawer
          open={drawerState === Drawers.SETUP}
          onClose={handleCloseDrawer}
          containerId="matcher-payment-partner-table"
        />
      </Layout>
    </DrawerContext.Provider>
  );
};

export default MatcherPaymentPartner;

import React from 'react';
import { useQuery } from '@apollo/client';
import { Col, Layout, Row } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  BgColorsOutlined,
  CloudDownloadOutlined, HomeOutlined,
  IdcardOutlined,
  KeyOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import AccountKeyOutlineIcon from '@2fd/ant-design-icons/lib/AccountKeyOutline';
import { metaProgramManagerQuery } from '../MetaProgramManagers/MetaProgramManagerView/query';
import HandleApolloError from '../../Common/HandleApolloError/HandleApolloError';
import styles from '../Company/CompanyView/CompanyView.module.scss';
import AnchorList from '../../Common/AnchorList/AnchorList';
import MetaProgramManagerTheme from '../MetaProgramManagers/MetaProgramManagerView/MetaProgramManagerTheme/MetaProgramManagerTheme';
import MetaProgramManagerContacts from '../MetaProgramManagers/MetaProgramManagerView/MetaProgramManagerContacts/MetaProgramManagerContacts';
import MetaProgramManagerOptions from '../MetaProgramManagers/MetaProgramManagerView/MetaProgramManagerOptions/MetaProgramManagerOptions';
import Page from '../../Common/Page/Page';
import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
import { useMetaProgramManagerId } from '../../../../contexts/app.context';
import MetaProgramManagerSso from '../MetaProgramManagers/MetaProgramManagerView/MetaProgramManagerSso/MetaProgramManagerSso';
import MetaProgramManagerLoginMethod from '../MetaProgramManagers/MetaProgramManagerView/MetaProgramManagerLoginMethod/MetaProgramManagerLoginMethod';
import { useCan } from '../../../../contexts/ability.context';
import MetaProgramManagerApiKeys from '../MetaProgramManagers/MetaProgramManagerView/MetaProgramManagerApiKeys/MetaProgramManagerApiKeys';

const { Content, Sider } = Layout;

const MetaProgramManager = () => {
  const can = useCan();
  const [metaProgramManagerId] = useMetaProgramManagerId();

  const { data: { metaProgramManager } = { metaProgramManager: null }, error } = useQuery(metaProgramManagerQuery, {
    variables: {
      id: metaProgramManagerId,
    },
  });

  const anchors = [
    {
      hash: '#tile-meta-program-manager-theme',
      icon: BgColorsOutlined,
      label: 'Meta Program Manager Theme',
    },
    {
      hash: '#tile-settings',
      icon: SettingOutlined,
      label: 'Settings',
    },
    {
      hash: '#tile-meta-program-manager-contacts',
      icon: IdcardOutlined,
      label: 'Contacts',
    },
    {
      hash: '#tile-meta-program-manager-login-method',
      icon: AccountKeyOutlineIcon,
      label: 'Login method / SSO',
      hidden: !can('read', 'meta-program-manager-login'),
    },
    {
      hash: '#tile-meta-program-manager-api-keys',
      icon: KeyOutlined,
      label: 'Api Keys',
      hidden: !can('read', 'meta-program-manager-api-keys'),
    },
    {
      hash: '#tile-available-services',
      icon: CloudDownloadOutlined,
      label: 'Available Services',
      hidden: !can('read', 'program-manager-available-services'),
    },
  ];

  return (
    <Page>
      <PageHeader
        title={metaProgramManager?.name}
        breadcrumbRender={() => (
          <Breadcrumb
            map={{
              '/': <HomeOutlined />,
              '/meta-program-settings': metaProgramManager?.name,
            }}
          />
        )}
      >
        <HandleApolloError error={error}>
          <Layout className={styles.subPage}>
            <Sider className={styles.anchorSider} width={24}>
              <AnchorList anchors={anchors} />
            </Sider>
            <Content>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <MetaProgramManagerTheme id={metaProgramManagerId} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <MetaProgramManagerOptions id={metaProgramManagerId} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <MetaProgramManagerContacts id={metaProgramManagerId} />
                </Col>
                {can('read', 'meta-program-manager-login') && (
                  <>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <MetaProgramManagerLoginMethod id={metaProgramManagerId} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <MetaProgramManagerSso id={metaProgramManagerId} />
                    </Col>
                  </>
                )}
                {can('read', 'meta-program-manager-api-keys') && (
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <MetaProgramManagerApiKeys id={metaProgramManagerId} />
                  </Col>
                )}
              </Row>
            </Content>
          </Layout>
        </HandleApolloError>
      </PageHeader>
    </Page>
  );
};

export default MetaProgramManager;

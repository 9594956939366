import React, { useLayoutEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Button, Space, Tag } from 'antd';
import ArrowUpBoldIcon from '@2fd/ant-design-icons/lib/ArrowUpBold';
import TrashCanIcon from '@2fd/ant-design-icons/lib/TrashCan';
import moment from 'moment-timezone';
import Drawers from '../../../Common/constants/drawers';
import FieldTypes from '../../../Common/constants/fieldTypes';
import useDrawer from '../../../Common/useDrawer';
import styles from './SearchFilterTags.module.scss';
import ListFilterTag from '../../../../Common/FilterTag/ListFilterTag';
import NumberFilterTag from '../../../../Common/FilterTag/NumberFilterTag';
import DateFilterTag from '../../../../Common/FilterTag/DateFilterTag';
import TextFilterTag from '../../../../Common/FilterTag/TextFilterTag';
import {DATE_FORMAT, DATETIME_FORMAT} from '../../../../../../constants/DATES';
import FilterTag from '../../../../Common/FilterTag/FilterTag';
import {MatcherCashierFields} from '../fields';
import usePage from '../../../hooks/usePage';
import useFieldAsyncOptionList from '../../../hooks/useFieldAsyncOptionList';

const AsyncListFilterTag = ({ field, ...filterTagProps }) => {
  const { options, loading } = useFieldAsyncOptionList(field);
  return <ListFilterTag field={field} options={options} loading={loading} {...filterTagProps} />;
};
const FilterTagMapper = ({ fieldKey, value, onRemove }) => {
  const [, setDrawer] = useDrawer();
  const page = usePage();

  const handleRemove = (e) => {
    e.stopPropagation();
    onRemove(fieldKey);
  };

  const field = MatcherCashierFields[fieldKey];

  const { type, label, displayOptions = {}, filterOptions = { pages: [] } } = field;

  const filterTagProps = {
    id: `filter-${fieldKey}`,
    onClick: () => setDrawer(Drawers.SEARCH),
    onRemove: handleRemove,
    label,
    displayOptions,
    value,
    disabled: !filterOptions.pages.includes(page.name),
  };

  if (value === '__NULL__') return <FilterTag {...filterTagProps}><Tag>Null</Tag></FilterTag>;

  switch (type) {
    case FieldTypes.ASYNC_LIST:
      return <AsyncListFilterTag field={field} {...filterTagProps} />;
    case FieldTypes.LIST:
    case FieldTypes.BOOLEAN:
      return <ListFilterTag {...filterTagProps} />;
    case FieldTypes.AMOUNT:
    case FieldTypes.NUMBER:
      return <NumberFilterTag {...filterTagProps} />;
    case FieldTypes.DATE:
      return (
        <DateFilterTag
          {...filterTagProps}
          value={{ from: moment(value?.[0], DATETIME_FORMAT).format(DATE_FORMAT), to: moment(value?.[1], DATETIME_FORMAT).format(DATE_FORMAT) }}
        />
      );
    case FieldTypes.TEXT:
      return <TextFilterTag {...filterTagProps} value={[value]} />;
    default:
      throw new Error(`Field type does not exist. (id: ${fieldKey}, type: ${type})`);
  }
};

const SearchFilterTags = ({ list, onSearch }) => {
  const [drawer] = useDrawer();
  const containerRef = useRef();
  const [collapsed, setCollapsed] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);

  useLayoutEffect(() => {
    setHasOverflow(containerRef?.current?.scrollHeight > 34);
  });

  if (list.length === 0) return null;

  const handleRemove = (fieldKey) => {
    onSearch(
      list.filter(({ key }) => key !== fieldKey).reduce((memo, curr) => ({ ...memo, [curr.key]: curr.value }), {}),
    );
  };

  return (
    <div className={styles.filterTagsContainer}>
      <span>Filters</span>
      <div ref={containerRef} className={classnames(styles.collapsable, { [styles.collapsed]: collapsed })}>
        <Space size={[4, 2]} wrap>
          {list.map(
            ({ key, value }) =>
              value && <FilterTagMapper key={key} fieldKey={key} value={value} onRemove={handleRemove} />,
          )}
          {drawer !== Drawers.SEARCH && list.length > 0 && (
            <TrashCanIcon className={styles.trashIcon} onClick={() => onSearch({})} title="Remove all filters" />
          )}
        </Space>
      </div>
      {hasOverflow && (
        <Button
          className={classnames(styles.collapseButton, { [styles.collapsed]: collapsed })}
          shape="circle"
          type="default"
          onClick={() => setCollapsed((prev) => !prev)}
          title={collapsed ? 'Deploy filters' : 'Collapse filters'}
        >
          <ArrowUpBoldIcon />
        </Button>
      )}
    </div>
  );
};

export default SearchFilterTags;

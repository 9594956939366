import React, { useCallback } from 'react';
import OrderStatus from './OrderStatus/OrderStatus';
import OrderReference from './OrderReference/OrderReference';
import PaymentDetails from './PaymentDetails/PaymentDetails';
import BillingDetails from './BillingDetails/BillingDetails';
import BasketSummary from './BasketSummary/BasketSummary';
// import ProcessingCosts from './ProcessingCosts';
import Authentication from './Authentication/Authentication';
import PaymentOperations from './PaymentOperations/PaymentOperations';
import Balances from './Balances/Balances';
import CustomerDetails from './CustomerDetails/CustomerDetails';
import ShippingDetails from './ShippingDetails/ShippingDetails';
import Voucher from './Voucher/Voucher';
import BasketDetails from './BasketDetails/BasketDetails';
import BrowserInformation from './BrowserInformation/BrowserInformation';
import AccountHistory from './AccountHistory/AccountHistory';
import Routing from './Routing/Routing';
import Notification from './Notification/Notification';
import ErrorBoundary from '../../../../Common/ErrorBoundary/ErrorBoundary';
import { InfoTileFallback } from '../../Tile/Info/Info';
import { capitalize } from '../../../../../../util/string';
import CheckoutDetails from './CheckoutDetails/CheckoutDetails';
import CustomerAddress from './CustomerAddress/CustomerAddress';

const TileBuilder = ({ name, onChangeHeight }) => {
  const handleHeightReady = useCallback(
    (height) => {
      onChangeHeight(name, height);
    },
    [name, onChangeHeight],
  );

  const component = {
    authentication: <Authentication />,
    'account-history': <AccountHistory />,
    balances: <Balances />,
    'basket-summary': <BasketSummary />,
    'billing-details': <BillingDetails />,
    'browser-information': <BrowserInformation />,
    'customer-details': <CustomerDetails />,
    'customer-address': <CustomerAddress />,
    'order-reference': <OrderReference />,
    'order-status': <OrderStatus />,
    'payment-details': <PaymentDetails />,
    // 'processing-costs': <ProcessingCosts />,
    'shipping-details': <ShippingDetails />,
    voucher: <Voucher />,
    'basket-details': <BasketDetails onHeightReady={handleHeightReady} />,
    'payment-operations': <PaymentOperations onHeightReady={handleHeightReady} />,
    routing: <Routing onHeightReady={handleHeightReady} />,
    notification: <Notification onHeightReady={handleHeightReady} />,
    checkout: <CheckoutDetails onHeightReady={handleHeightReady} />,
  }[name];

  return (
    <ErrorBoundary fallback={<InfoTileFallback name={name} title={capitalize(name)} />}>{component}</ErrorBoundary>
  );
};

export default TileBuilder;

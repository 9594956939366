import { gql } from '@apollo/client';

export const PartnersQuery = gql`
  {
    partners {
      id
      name
      api_name
      payment_methods {
        id
        name
        api_name
      }
    }
  }
`;

export const CompanyUserListQuery = gql`
    query CompanyUserListQuery($companyId: ID!) {
        merchantCompany(id: $companyId) {
            __typename
            id
            users {
                __typename
                id
                user {
                    id
                    full_name
                    email
                    avatar
                }
            }
        }
    }
`;

export const createDemoMerchantAccountMutation = gql`
  mutation CreateDemoMerchantAccount ($input: DemoMerchantAccountCreateInput!) {
    createDemoMerchantAccount (input: $input) {
      id
    }
  }
`;
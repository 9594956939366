const ReportDelimiters = {
  COMMA: 'comma',
  SEMICOLON: 'semicolon',
  TAB: 'tab',
  // PIPE: 'pipe',
};

export const reportDelimiters = {
  [ReportDelimiters.COMMA]: { value: ReportDelimiters.COMMA, label: '[ , ] Comma', symbol: ',' },
  [ReportDelimiters.SEMICOLON]: { value: ReportDelimiters.SEMICOLON, label: '[ ; ] Semicolon', symbol: ';' },
  [ReportDelimiters.TAB]: { value: ReportDelimiters.TAB, label: '[ \\t ] Tab', symbol: '\\t' },
  // [ReportDelimiters.PIPE]: { value: ReportDelimiters.PIPE, label: '[ | ] Pipe', symbol: '|' },
};
export const reportDelimiterList = Object.values(reportDelimiters);
export default ReportDelimiters;

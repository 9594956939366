import { gql } from '@apollo/client';

export const TeamUserListQuery = gql`
  query TeamUserListQuery($companyId: ID!) {
    merchantCompany(id: $companyId) {
      id
      users {
        user {
          id
          email
          full_name
          first_name
          last_name
          avatar
          status
          disabled
        }
        aclProfile {
          id
          name
        }
      }
    }
  }
`;

export default {};

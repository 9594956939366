import React, { useState } from "react";
import { Button, Modal, message, Radio, Space, Tooltip } from "antd";
import { CloudDownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import TrashCanIcon from "@2fd/ant-design-icons/lib/TrashCan";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import styles from "./ListActions.module.scss";
import { listContentQuery, removeListMutation } from "./query";
import Loader from "../../../../Common/Loader/Loader";
import { useCan } from "../../../../../../contexts/ability.context";
import useNavigateWithSearch from "../../../../../../util/navigate";

const { confirm } = Modal;

const ListActions = ({list}) => {
  const navigate = useNavigateWithSearch();
  const can = useCan();

  const [getListContent, {data, loading}] = useLazyQuery(listContentQuery);

  const [removeList] = useMutation(
    removeListMutation,
    {
      variables: {
        id: list.id,
      },
      optimisticResponse: {
        removeList: {
          id: list.id,
          __typename: 'List',
        }
      },
      update: (cache, {
        data: {
          removeList: {
            id,
          }
        }}) => {
        const normalizedId = cache.identify({ id, __typename: 'List' });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
      onCompleted: () => navigate(`/router/lists`),
      onError: (error) => {
        if (error?.graphQLErrors?.find((e) => e.extensions.code === '409')) {
          message.error(`This list is used in active rules`);
        }
      },
    }
  );

  const [separator, setSeparator] = useState('\n');
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {(() => {
        if (['product_sku', 'bin'].includes(list.type) || can('read', 'gdpr')) {
          if (list.length > 0) {
            return (
              <Button
                className={styles.actionButton}
                onClick={() => {
                  setIsModalOpen(true);
                  getListContent({
                    variables: {
                      id: list.id,
                    }
                  });
                }}
              >
                <CloudDownloadOutlined />
                Download list
              </Button>
            )
          }
          return (
            <div
              className={styles.emptyList}
            >
              The list is empty
            </div>
          )
        }
        return null;
      })()}
      <Tooltip
        title={list.rulesUsing?.inEntry.length ? (
          <div>
            This list is used in the following rules:
            {list.rulesUsing?.inEntry.map(rule => (
              <>
                <br/>
                <Link to={`/router/payment-network/rule/${rule.id}?maccount=${rule.merchant_account.id}`}>
                  {rule.name}
                </Link>
              </>
            ))}
          </div>
        ) : null}
      >
        <Button
          block
          icon={<TrashCanIcon />}
          onClick={() =>
            confirm({
              title: 'Are you sure you want to delete this list?',
              icon: <ExclamationCircleOutlined />,
              okText: 'Apply and proceed',
              okType: 'danger',
              cancelText: 'Cancel',
              onOk: removeList,
            })
          }
          disabled={list.rulesUsing?.inEntry.length}
        >
          DELETE LIST
        </Button>
      </Tooltip>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        okButtonProps={{
          disabled: loading,
        }}
        centered
        onOk={() => {
          const csvContent = `data:text/csv;charset=utf-8,${
             data.list.content.join(separator)}`;
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', `${list.name}.csv`);
          document.body.appendChild(link);
          link.click();
          setIsModalOpen(false);
        }}
      >
        <div
          className={styles.separatorLabel}
        >
          Select the separator:
        </div>
        <Radio.Group
          value={separator}
          onChange={(e) => setSeparator(e.target.value)}
        >
          <Space
            direction='vertical'
          >
            <Radio value={'\n'}>Carriage return</Radio>
            <Radio value=','>Comma</Radio>
            <Radio value=';'>Semicolon</Radio>
          </Space>
        </Radio.Group>
        {loading && (
          <div
            className={styles.loadingCsv}
          >
            <div>Downloading your file, please wait</div>
            <Loader />
          </div>
        )}
      </Modal>
    </>
  );
};

export default ListActions;
import React from 'react';
import { Table, Tag, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useQuery } from '@apollo/client';
import { CheckOutlined, HomeOutlined } from '@ant-design/icons';
import { snakeToCapitalize } from '../../../../util/string';
import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
import { ProfileOverviewQuery } from '../ProgramManagerAccessControl/query';
import { compareByProp } from '../../../../util/array';
import ErrorBoundary from '../../Common/ErrorBoundary/ErrorBoundary';
import Page from '../../Common/Page/Page';

// 'payment_networks_admin' -> ['Payment Networks', 'admin']
const parseRole = (role) => {
  const matches = role.match(/^([\w_]+)_(admin|editor|viewer)$/);
  if (!matches) return null;
  return [snakeToCapitalize(matches[1]), matches[2]];
};

const tagColors = { admin: '#595959', editor: '#8c8c8c', viewer: '#bfbfbf' };
const renderTag = (value) => <Tag color={tagColors[value]}>{value}</Tag>;
const renderCheck = (value) => value && <CheckOutlined style={{ fontSize: 16 }} />;

const ProgramManagerAccessControlOverview = () => {
  const { data, loading } = useQuery(ProfileOverviewQuery);

  const others = [];
  const modules = [];
  data?.programManagerAclRoles?.forEach((role) => {
    const res = parseRole(role.id);
    if (!res && !others.includes(role.id)) {
      others.push(role.id);
    } else if (res && !modules.includes(res[0])) {
      modules.push(res[0]);
    }
  });

  const columns = [
    {
      key: 'name',
      title: 'Profiles',
      dataIndex: 'name',
      fixed: 'left',
      // sort by name
      sorter: compareByProp('name'),
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
    },
    {
      key: 'modules',
      title: 'Modules',
      children: modules.map((role) => ({
        key: role,
        title: role,
        dataIndex: role,
        render: renderTag,
        align: 'center',
      })),
    },
    {
      key: 'others',
      title: 'Others',
      children: others.map((role) => ({
        key: role,
        title: snakeToCapitalize(role),
        dataIndex: role,
        render: renderCheck,
        align: 'center',
      })),
      align: 'center',
    },
  ];

  const dataSource =
    data?.programManagerAclProfiles?.map((profile) => ({
      key: profile.id,
      name: profile.name,
      // dedupe role by module and keep first value because roles are sorted by name (admin > editor > viewer)
      ...profile.roles?.reduce((memo, role) => {
        const res = parseRole(role.id);
        if (!res) return { ...memo, [role.id]: true };
        return { ...memo, [res[0]]: memo[res[0]] || res[1] };
      }, {}),
    })) || [];

  const breadcrumbRender = () => (
    <Breadcrumb
      map={{
        '/': <HomeOutlined />,
        '/program-access-overview': 'Access Control Overview',
      }}
    />
  );

  return (
    <Page>
      <ErrorBoundary>
        <PageHeader title="Access Control Overview" breadcrumbRender={breadcrumbRender}>
          <Typography.Paragraph>Module&apos;s roles by profile.</Typography.Paragraph>
          <Table
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            bordered
            scroll={{
              x: true,
              scrollToFirstRowOnChange: true,
            }}
            pagination={false}
          />
        </PageHeader>
      </ErrorBoundary>
    </Page>
  );
};

export default ProgramManagerAccessControlOverview;

import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import { Skeleton } from 'antd';
import {useLazyQuery} from '@apollo/client';
import InfoTile, { InfoTextRow } from '../Tile/Info/Info';
import { compareByProp } from '../../../../../util/array';
import styles from './ProvidersDetails.module.scss';
import TRANSACTION_STATUSES from '../../../../../constants/TRANSACTION_STATUSES';
import {activityTransactionsProvidersDetailsQuery, orderTransactionsProvidersDetailsQuery} from './query';

const ProviderIcon = ({ index, color }) => (
  <div className={styles.indexIcon} style={{ backgroundColor: color }}>
    {index}
  </div>
);

const ProviderDetails = ({ transaction, index }) => {
  const status = TRANSACTION_STATUSES[transaction?.status];

  if (!transaction) return null;

  return (
    <div className={styles.root}>
      <InfoTile
        name="provider-details"
        title="Provider details"
        titleIcon={<ProviderIcon index={index} color={status?.color} />}
        className={styles.tile}
      >
        <InfoTextRow label="PSP ID" value={transaction.psp_transaction_id} />
        <InfoTextRow label="Provider name" value={transaction.payin_partner} />
        <InfoTextRow label="Transaction status" value={status?.label} />
        {/* <InfoTextRow label="Type" value={"TODO"} /> */}
        <InfoTextRow label="Merchant contract" value={transaction.merchant_contract?.name} />
        <InfoTextRow label="Route ID" value={transaction.merchant_route?.id} />
        <InfoTextRow label="Provider card token" value={transaction.psp_token} />
        <InfoTextRow label="Authentication status" value={transaction.authentication_result} />
        <InfoTextRow label="Authorization code" value={transaction.authorization_code} />
        <InfoTextRow label="Result code" value={transaction.psp_result_code} />
        <InfoTextRow label="Result description" value={transaction.psp_result_description} />
      </InfoTile>
    </div>
  );
};

const ProvidersDetails = () => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(activityTransactionsProvidersDetailsQuery, {
    variables: {
      orderId,
    }
  });

  const { data: { order: orderStack } = { order: { transactions: [] } } } = useApolloQuery(
    orderTransactionsProvidersDetailsQuery,
    {
      variables: {
        orderId,
      },
      onCompleted: data => !data.order && fetchInsights(),
    },
  );

  const valueSkeleton = () => (
    <Skeleton
      title={false}
      paragraph={{
        rows: 1,
        width: Math.floor(Math.random() * 180 + 40),
      }}
      active
    />
  );

  const order = orderStack ?? orderInsights;

  return (
    [...(order?.transactions ?? [])]
      .sort(compareByProp('order_transaction_number', 'asc'))
      .map((trx, index) => <ProviderDetails key={trx.id} transaction={trx} index={index + 1} />)
      .reverse() ?? (
      <div className={styles.root}>
        <InfoTile
          name="provider-details"
          title="Provider details"
          titleIcon={<ProviderIcon index={1} color="grey" />}
          className={styles.tile}
        >
          {[
            'PSP ID',
            'Provider name',
            'Transaction status',
            'MerchantContract',
            'Route ID',
            'Provider card token',
            'Authentication status',
            'Authorization code',
            'Result code',
            'Result description',
          ].map((label) => (
            <InfoTextRow label={label} value={valueSkeleton()} />
          ))}
        </InfoTile>
      </div>
    )
  );
};

export default ProvidersDetails;

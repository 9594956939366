import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { getLinkTo, getText } from '../utils';
import { apiKeyQuery } from './query';
import { Link } from '../../../../../../util/navigate';
import { isModeMerchant } from '../../../../../../constants/mode';

const UpdateCreate = ({ log }) => {
  const { t } = useTranslation();

  const [getApiKey, { data }] = useLazyQuery(apiKeyQuery);

  useEffect(() => {
    if (log.log_entity_type === 'ApiKey') {
      getApiKey({
        variables: {
          id: log.log_entity_id,
        },
      });
    }
  }, [log.log_entity_type]);

  return (
    <>
      Created:
      <br />
      {`${getText('entityName', log.log_entity_type, t)}: `}
      {isModeMerchant ? (
        <Link to={getLinkTo(log)}>{log.log_entity_name || log.log_entity_id}</Link>
      ) : (
        log.log_entity_name || log.log_entity_id
      )}
      &nbsp;
      {(() => {
        switch (log.log_entity_type) {
          case 'MerchantContract':
            return `(${t(`partnerAccount.list.types.${log.log_update.partner_type}`)})`;
          case 'ApiKey':
            if (data?.apiKey) {
              return `(${data?.apiKey?.is_public ? 'Public' : 'Private'})`;
            }
            return '';
          default:
            return '';
        }
      })()}
    </>
  );
};

export default UpdateCreate;

import React, { useCallback } from 'react';
import { Tag } from 'antd';
import FilterTag from './FilterTag';
import styles from './FilterTag.module.scss';

const ListFilterTag = ({
  value = [],
  displayOptions = {},
  filterOptions = {},
  options = filterOptions.options,
  loading = false,
  ...filterTagProps
}) => {
  const tagRender = useCallback(
    (props) => {
      if (loading) return null;
      if (props.value === '__NULL__') return <Tag>Null</Tag>;
      const option = displayOptions.getOption?.(props.value) ?? options.find((o) => o.value === props.value);
      if (!option) return <Tag key={props.value}>{props.label}</Tag>;
      if (option.icon) {
        const Icon = option.icon;
        return (
          <Tag key={props.value} icon={<Icon />}>
            {option.label}
          </Tag>
        );
      }
      if (option.img) {
        return displayOptions.hideLabel ? (
          <img
            key={props.value}
            src={option.img}
            alt=""
            style={{ backgroundColor: option.color }}
            title={option.label}
          />
        ) : (
          <Tag
            key={props.value}
            className={styles.imageTag}
            icon={<img src={option.img} alt="" style={{ backgroundColor: option.color }} />}
          >
            {option.label}
          </Tag>
        );
      }
      return <Tag key={props.value}>{option.label}</Tag>;
    },
    [options, loading],
  );

  return <FilterTag {...filterTagProps}>{value.map((id) => tagRender({ value: id }))}</FilterTag>;
};

export default ListFilterTag;

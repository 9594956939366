import { gql } from '@apollo/client';

export const createProgramManagerMutation = gql`
  mutation CreateProgramManager($input: ProgramManagerInput!) {
    createProgramManager(input: $input) {
      id
      name
      displayName
      code
    }
  }
`;

export const PartnerCompaniesQuery = gql`
  {
    partnerCompanies {
      id
      name
    }
  }
`;


export const MetaProgramManagersQuery = gql`
  {
    metaProgramManagers {
      id
      name
    }
  }
`;

import React from 'react';

import { Space, Table, Tag, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useQuery } from '@apollo/client';
import { CheckOutlined, HomeOutlined } from '@ant-design/icons';
import { snakeToCapitalize } from '../../../../../util/string';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { ProfileOverviewQuery } from '../query';
import { compareByProp } from '../../../../../util/array';
import { useMetaProgramManager } from '../../../../../contexts/metaProgramManager.context';
import useNavigateWithSearch from '../../../../../util/navigate';
import MetaProgramManagerAvatar from '../../../Common/MetaProgramManager/Avatar';

// 'payment_networks_admin' -> ['Payment Networks', 'admin']
const parseRole = (role) => {
  const matches = role.match(/^([\w_]+)_(admin|editor|viewer)$/);
  if (!matches) return null;
  return [snakeToCapitalize(matches[1]), matches[2]];
};

const tagColors = { admin: '#595959', editor: '#8c8c8c', viewer: '#bfbfbf' };
const renderTag = (value) => <Tag color={tagColors[value]}>{value}</Tag>;
const renderCheck = (value) => value && <CheckOutlined style={{ fontSize: 16 }} />;

const MetaProgramManagerAccessControlOverview = () => {
  const metaProgramManager = useMetaProgramManager();
  const navigate = useNavigateWithSearch();
  const { data, loading } = useQuery(ProfileOverviewQuery, { fetchPolicy: 'cache-first' });

  const others = [];
  const modules = [];
  data?.metaProgramManagerAclRoles?.forEach((role) => {
    const res = parseRole(role.id);
    if (!res && !others.includes(role.id)) {
      others.push(role.id);
    } else if (res && !modules.includes(res[0])) {
      modules.push(res[0]);
    }
  });

  const columns = [
    {
      key: 'name',
      title: 'Profiles',
      dataIndex: 'name',
      fixed: 'left',
      sorter: compareByProp('name'),
      render: (value, row) => (
        <Space style={{ width: 200 }}>
          <MetaProgramManagerAvatar metaProgramManager={row.metaProgramManager} />
          {value}
        </Space>
      ),
    },
    {
      key: 'modules',
      title: 'Modules',
      children: modules.map((role) => ({
        key: role,
        title: role,
        dataIndex: role,
        render: renderTag,
        align: 'center',
      })),
    },
    {
      key: 'others',
      title: 'Others',
      children: others.map((role) => ({
        key: role,
        title: snakeToCapitalize(role),
        dataIndex: role,
        render: renderCheck,
        align: 'center',
      })),
      align: 'center',
    },
  ];

  const dataSource =
    data?.metaProgramManagerAclProfiles?.map((profile) => ({
      key: profile.id,
      name: profile.name,
      metaProgramManager: profile.metaProgramManager,
      // dedupe role by module and keep first value because roles are sorted by name (admin > editor > viewer)
      ...profile.roles?.reduce((memo, role) => {
        const res = parseRole(role.id);
        if (!res) return { ...memo, [role.id]: true };
        return { ...memo, [res[0]]: memo[res[0]] || res[1] };
      }, {}),
    })) || [];

  const breadcrumbRender = () => (
    <Breadcrumb
      map={{
        '/': <HomeOutlined />,
        '/meta-program-access-control-list': metaProgramManager ? 'Internal Access Control' : 'Meta Program Manager Access Control',
        '/meta-program-access-control-list/overview': 'Overview',
      }}
    />
  );

  return (
    <PageHeader
      title="Overview"
      breadcrumbRender={breadcrumbRender}
      onBack={() => navigate('/meta-program-access-control-list')}
    >
      <Typography.Paragraph>Module&apos;s roles by profile.</Typography.Paragraph>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        bordered
        onRow={(profile) => ({
          // redirect to profile view
          onClick: () => navigate(`/meta-program-access-control-list/${profile.key}`),
        })}
        scroll={{
          x: true,
          scrollToFirstRowOnChange: true,
        }}
        pagination={false}
      />
    </PageHeader>
  );
};

export default MetaProgramManagerAccessControlOverview;

import { gql } from '@apollo/client';

export const ProgramManagerRecipientsQuery = gql`
  query ProgramManagerRecipientsQuery($id: ID!) {
    programManager(id: $id) {
      id
      name
      merchantCompanies {
        id
        name
        theme {
          primaryColor
          secondaryColor
          icon
        }
      }
      merchantAclProfiles {
        id
        name
      }
      programManagerAclProfiles {
        id
        name
      }
    }
  }
`;
export const MetaProgramManagerRecipientsQuery = gql`
  query MetaProgramManagerRecipientsQuery($id: ID!) {
    metaProgramManager(id: $id) {
      id
      name
      metaProgramManagerAclProfiles {
        id
        name
      }
    }
    programManagers {
      id
      name
      theme {
        primaryColor
        secondaryColor
        icon
      }
      merchantCompanies {
        id
        name
        theme {
          primaryColor
          secondaryColor
          icon
        }
      }
      merchantAclProfiles {
        id
        name
      }
      programManagerAclProfiles {
        id
        name
      }
    }
  }
`;

export const TargetRecipientsQuery = gql`
  query TargetRecipientsQuery(
    $internalAclProfiles: [ID]
    $programManagers: [ID]
    $metaProgramManagers: [ID]
    $programManagerAclProfiles: [ID]
    $metaProgramManagerAclProfiles: [ID]
    $merchantCompanies: [ID]
    $merchantAclProfiles: [ID]
  ) {
    announcementRecipients(
      internalAclProfiles: $internalAclProfiles
      programManagers: $programManagers
      metaProgramManagers: $metaProgramManagers
      programManagerAclProfiles: $programManagerAclProfiles
      metaProgramManagerAclProfiles: $metaProgramManagerAclProfiles
      merchantCompanies: $merchantCompanies
      merchantAclProfiles: $merchantAclProfiles
    ) {
      internalUsers {
        id
      }
      programManagerUsers {
        id
      }
      metaProgramManagerUsers {
        id
      }
      merchantUsers {
        id
        email
        programManager {
          name
        }
      }
    }
  }
`;

export const InternalRecipientsQuery = gql`
  query InternalRecipientsQuery {
    programManagers {
      id
      name
      theme {
        primaryColor
        secondaryColor
        icon
      }
      merchantCompanies {
        id
        name
        theme {
          primaryColor
          secondaryColor
          icon
        }
      }
      merchantAclProfiles {
        id
        name
      }
      programManagerAclProfiles {
        id
        name
      }
      metaProgramManager {
        id
        name
        theme {
          primaryColor
          secondaryColor
          icon
        }
      }
    }
    metaProgramManagers {
      id
      name
      theme {
        primaryColor
        secondaryColor
        icon
      }
      programManagers {
        id
        name
        theme {
          primaryColor
          secondaryColor
          icon
        }
      }
      metaProgramManagerAclProfiles {
        id
        name
      }
    }
    internalAclProfiles {
      id
      name
    }
  }
`;

export const CreateAnnouncementMutation = gql`
  mutation CreateAnnouncementMutation($input: AnnouncementInput!) {
    createAnnouncement(input: $input) {
      id
      type
      title
      content
      body
      programManagers {
        id
        name
        theme {
          primaryColor
          secondaryColor
          icon
        }
      }
      programManagerAclProfiles {
        id
        name
      }
      merchantCompanies {
        id
        name
        theme {
          primaryColor
          secondaryColor
          icon
        }
      }
      merchantAclProfiles {
        id
        name
      }
      triggerAt
      expireAt
    }
  }
`;

export const UpdateAnnouncementMutation = gql`
  mutation UpdateAnnouncementMutation($id: ID!, $input: AnnouncementInput!) {
    updateAnnouncement(id: $id, input: $input) {
      id
      type
      title
      content
      body
      programManagers {
        id
        name
        theme {
          primaryColor
          secondaryColor
          icon
        }
      }
      programManagerAclProfiles {
        id
        name
      }
      merchantCompanies {
        id
        name
        theme {
          primaryColor
          secondaryColor
          icon
        }
      }
      merchantAclProfiles {
        id
        name
      }
      triggerAt
      expireAt
    }
  }
`;

import React, { useState } from 'react';
import { Button, Form, Input, message, Select, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import { CompanyUpdateMutation } from '../../query';
import InfoTile, { InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import styles from '../CompanyView.module.scss';
import { useCan } from '../../../../../../contexts/ability.context';
import { CompanyExecutiveQuery } from './query';

const CompanyExecutive = () => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);
  const { companyId } = useParams();

  const { data: { merchantCompany } = { merchantCompany: null}, loading} = useQuery(CompanyExecutiveQuery, {
    variables: {
      companyId
    }
  });

  const [updateCompany, { loading: loadingUpdate }] = useMutation(CompanyUpdateMutation, {
    onCompleted: () => {
      message.success('Company successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    updateCompany({
      variables: { id: companyId, input: values },
    });
  };

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="company-executive" title="Executive">
        <Form
          form={form}
          title="Executive"
          layout="vertical"
          initialValues={merchantCompany}
          onFinish={handleFinish}
          requiredMark={false}
          className="tile-form"
        >
          <Form.Item label="Executives" name="executive">
            <Select mode="tags" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label="CEO"
            name="ceo"
            rules={[
              {
                required: merchantCompany.attributeListRequiredByPartners.includes('company.ceo'),
                message: 'CEO is required by a partner configuration.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Directors" name="directors">
            <Select mode="tags" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loadingUpdate}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  // return info tile
  return (
    <InfoTile
      name="company-executive"
      title="Executive"
      extra={
        can('update', 'company') && !loading && <Button onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
      }
    >
      <InfoTextRow label="Executives" value={merchantCompany?.executive?.join(', ')} loading={loading} />
      <InfoTextRow label="CEO" value={merchantCompany?.ceo} loading={loading} />
      <InfoTextRow label="Directors" value={merchantCompany?.directors?.join(', ')} loading={loading} />
    </InfoTile>
  );
};

export default CompanyExecutive;

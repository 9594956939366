import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Divider, message, Space, Table, Tag, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useMutation, useQuery } from '@apollo/client';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { snakeToCapitalize } from '../../../../../util/string';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { InternalProfileViewQuery, InternalProfileUpdateMutation, useInternalProfileDeleteMutation } from '../query';
import styles from './InternalAccessControlView.module.scss';
import HandleApolloError from '../../../Common/HandleApolloError/HandleApolloError';
import useNavigateWithSearch from '../../../../../util/navigate';
import { useCan } from '../../../../../contexts/ability.context';

const InternalAccessControlView = () => {
  const can = useCan();
  const navigate = useNavigateWithSearch();
  const { profileId } = useParams();
  const { data, loading, error } = useQuery(InternalProfileViewQuery, {
    fetchPolicy: 'cache-first',
    variables: { profileId },
  });

  const [updateProfile, { loading: updateLoading }] = useMutation(InternalProfileUpdateMutation, {
    onCompleted: () => message.success('Profile successfully updated'),
  });

  const [deleteProfile] = useInternalProfileDeleteMutation();

  const profileName = data?.internalAclProfile?.name || '...';

  const breadcrumbRender = () => (
    <Breadcrumb
      map={{
        '/': <HomeOutlined />,
        '/internal-access-control-list': 'Internal Access Control',
        [`/internal-access-control-list/${profileId}`]: profileName,
      }}
    />
  );

  const initialSelectedRowKeys = useMemo(() => data?.internalAclProfile?.roles.map((r) => r.id) ?? [], [data]);
  const [selectedRowKeys, setSelectedRowKeys] = useState(initialSelectedRowKeys);

  // Init & reset selectedRowKeys from remote data
  useEffect(() => {
    setSelectedRowKeys(initialSelectedRowKeys);
  }, [setSelectedRowKeys, initialSelectedRowKeys]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnTitle: 'Allowed',
    columnWidth: 120,
  };

  const handleSave = useCallback(
    () =>
      updateProfile({
        variables: {
          id: profileId,
          updateProfileInput: {
            roles: selectedRowKeys,
          },
        },
      }),
    [updateProfile, selectedRowKeys],
  );

  const handleReset = useCallback(
    () => setSelectedRowKeys(initialSelectedRowKeys),
    [setSelectedRowKeys, initialSelectedRowKeys],
  );

  const handleDelete = () => deleteProfile(data?.internalAclProfile);

  // disable reset & save if loading or there is no changes
  const disabled = useMemo(
    () => loading || JSON.stringify(initialSelectedRowKeys) === JSON.stringify(selectedRowKeys),
    [loading, initialSelectedRowKeys, selectedRowKeys],
  );

  const dataSource = data?.internalAclRoles.map((role) => ({
    key: role.id,
    name: snakeToCapitalize(role.name),
    description: (
      <div className={styles.description}>
        Include privileges : {role.privileges.map((p) => p.id).join(', ')}
        <>
          <Divider className={styles.divider} orientation="left">
            Used by
          </Divider>
          {role.useBy.filter((p) => p.type === 'InternalAclProfile').length > 0 ? (
            <Space>
              {role.useBy
                .filter((p) => p.type === 'InternalAclProfile')
                .map((p) => (
                  <Link key={p.id} to={`/internal-access-control-list/${p.id}`}>
                    <Tag color="lightgrey">{p.name}</Tag>
                  </Link>
                ))}
            </Space>
          ) : (
            'Not used'
          )}
        </>
      </div>
    ),
  }));

  const columns = [
    {
      title: 'Role',
      dataIndex: 'name',
      key: 'name',
    },
    Table.SELECTION_COLUMN,
  ];

  return (
    <PageHeader
      title={profileName}
      breadcrumbRender={breadcrumbRender}
      onBack={() => navigate('/internal-access-control-list')}
      extra={[
        <Tag key="useCount" icon={<UserOutlined />}>
          {data?.internalAclProfile?.useCount}
        </Tag>,
        ...(can('delete', 'internal-access-control')
          ? [
              <Button
                key="delete-profile"
                onClick={handleDelete}
                disabled={!(data?.internalAclProfile?.useCount === 0)}
              >
                Delete
              </Button>,
            ]
          : []),
        ...(can('update', 'internal-access-control')
          ? [
              <Button key="reset-profile" onClick={handleReset} disabled={disabled}>
                Reset
              </Button>,
              <Button key="save-profile" onClick={handleSave} disabled={disabled} loading={updateLoading}>
                Save
              </Button>,
            ]
          : []),
      ]}
    >
      <HandleApolloError error={error}>
        <>
          <Typography.Paragraph>{data?.internalAclProfile?.description}</Typography.Paragraph>
          <Table
            loading={loading}
            columns={columns}
            expandable={{
              expandedRowRender: ({ description }) => description,
            }}
            dataSource={dataSource}
            pagination={false}
            rowSelection={rowSelection}
          />
        </>
      </HandleApolloError>
    </PageHeader>
  );
};

export default InternalAccessControlView;

import React, { useEffect, useRef } from 'react';
import { Select } from 'antd';

/**
 * Add "-- Select all --" on top of select which onClick select every options
 * @param form
 * @param name
 * @param options
 * @returns {{onChange: getSelectAllProps.onChange, options: [{label: string, value: string},...*[]]}}
 */
export const getSelectAllProps = ({ form, name, options = [] }) => ({
  options: [{ value: 'all', label: '-- Select all --' }, ...options],
  onChange: (values) => {
    if (values.includes('all')) {
      form.setFieldsValue({ [name]: options.map((o) => o.value) });
    }
  },
});

export const handleChangeSelectAll =
  ({ form, name, options = [] }) =>
  (values) => {
    if (values.includes('all')) {
      form.setFieldsValue({ [name]: options.map((o) => o.value) });
    }
  };

/**
 * Return ref with autoFocus to use with Input
 * @returns {React.MutableRefObject<null>}
 */
export const useAutoFocusRef = () => {
  const inputTagRef = useRef(null);
  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus();
    }
  }, [inputTagRef.current]);
  return inputTagRef;
};

export default {};

import { gql } from "@apollo/client";

export const listQuery = gql`
  query List ($id: ID!) {
    list (id: $id) {
      id
      name
      type
      length
      updatedAt
      rulesUsing {
        inEntry {
          id
          name
          merchant_account {
            id
          }
        }
        inDraft {
          id
          name
          merchant_account {
            id
          }
        }
      }
    }
  }
`;

export const addListContentMutation = gql`
  mutation AddListContent ($id: ID!, $content: [String!]!) {
    addListContent (id: $id, content: $content) {
      id
      length
    }
  }
`;

export const removeListContentMutation = gql`
  mutation RemoveListContent ($id: ID!, $content: [String!]!) {
    removeListContent (id: $id, content: $content) {
      id
      length
    }
  }
`;

export const replaceListContentMutation = gql`
  mutation ReplaceListContent ($id: ID!, $content: [String!]!) {
    replaceListContent (id: $id, content: $content) {
      id
      length
    }
  }
`;
import { gql } from '@apollo/client';

export const MetaProgramManagerUserListQuery = gql`
  query MetaProgramManagerUserListQuery {
    metaProgramManagerUsers {
      id
      email
      full_name
      first_name
      last_name
      avatar
      status
      disabled
      createdAt
      lastConnection
      isGdpr
      aclProfile {
        id
        name
      }
      metaProgramManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
    }
  }
`;

export const MetaProgramManagerAclProfilesQuery = gql`
  {
    metaProgramManagerAclProfiles {
      id
      name
    }
  }
`;

export default {};

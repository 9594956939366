import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import { useLazyQuery } from '@apollo/client';
import InfoTile, { InfoAmountRow, InfoDateTimeRow, InfoTextRow } from '../../../Tile/Info/Info';
import { activityCheckoutDetailsQuery, checkoutDetailsQuery } from './query';
import CheckoutDetailsSkeleton from './CheckoutDetailsSkeleton';
import CHECKOUT_TYPES from '../../../../../../../constants/CHECKOUT_TYPES';

const CheckoutDetails = () => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(
    activityCheckoutDetailsQuery,
    {
      variables: {
        orderId,
      },
    },
  );

  const { data: { order: orderStack } = { order: null }, loading } = useApolloQuery(checkoutDetailsQuery, {
    variables: {
      orderId,
    },
    onCompleted: (data) => !data.order && fetchInsights(),
  });

  if (loading) return <CheckoutDetailsSkeleton />;

  const checkout = orderStack?.checkout ?? orderInsights?.checkout;

  if (!checkout)
    return (
      <InfoTile name="checkout-details" title="Checkout details">
        No checkout information available
      </InfoTile>
    );

  return (
    <InfoTile name="checkout-details" title="Checkout details">
      <InfoTextRow label="Checkout ID" value={checkout._id} />
      <InfoDateTimeRow label="Checkout date" value={checkout.createdAt} />
      <InfoAmountRow label="Amount" value={checkout.amount} currency={checkout.currency} />
      <InfoTextRow label="Currency" value={checkout.currency} />
      <InfoTextRow label="Customer email" value={checkout.customer_email} />
      <InfoTextRow label="Customer ID" value={checkout.customer_id} />
      <InfoTextRow label="Type" value={CHECKOUT_TYPES[checkout.type]?.label} />
      <InfoDateTimeRow label="Expiration" value={checkout.expiration_date} />
    </InfoTile>
  );
};

export default CheckoutDetails;

import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Button, Typography } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import useCopyToClipboard from '../../../../../../hooks/useCopyToClipboard';
import InfoTile from '../../../OrderDetail/Tile/Info/Info';
import RequestBodySkeleton from './RequestBodySkeleton';
import { requestBodyQuery } from './query';

const RequestBody = () => {
  const { notificationId } = useParams();

  const { data, loading } = useQuery(requestBodyQuery, {
    variables: {
      notificationId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const response = JSON.stringify(data?.notification?.payload, null, 2);
  const [copyStatus, copy] = useCopyToClipboard(response);

  if (!data?.notification?.payload || loading) return <RequestBodySkeleton />;

  return (
    <InfoTile
      name="request-body"
      title="Request body"
      extra={
        <Button type="text" onClick={copy} icon={<CopyOutlined />}>
          {copyStatus === 'copied' ? 'Copied' : 'Copy'}
        </Button>
      }
    >
      <Typography.Text>Payload</Typography.Text>
      <Typography.Paragraph style={{ fontSize: 12 }}>
        <div>
          <pre>{response}</pre>
        </div>
      </Typography.Paragraph>
    </InfoTile>
  );
};

export default RequestBody;

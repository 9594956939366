import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Button, message, Modal, Space, Typography } from 'antd';
import { DeploymentUnitOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import InfoTile from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { useCan } from '../../../../../../contexts/ability.context';
import {
  MerchantAccountGeneratePartnerDataGenerateMutation,
  MerchantAccountPartnerDataQuery,
  MerchantAccountPartnerDataUpdateMutation,
} from './query';
import Loader from '../../../../Common/Loader/Loader';
import TableTile from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import { components } from '../../../../Common/EditableCell/EditableCell';
import { partnerTypes } from '../../../Partners/constants';

const PartnerData = ({ id }) => {
  const can = useCan();

  const { data, loading } = useQuery(MerchantAccountPartnerDataQuery, { variables: { id } });

  const [update] = useMutation(MerchantAccountPartnerDataUpdateMutation, {
    onCompleted: () => {
      message.success('Merchant account successfully updated');
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [generate, { loading: generateLoading }] = useMutation(MerchantAccountGeneratePartnerDataGenerateMutation, {
    variables: { id },
  });

  if (!data) {
    return (
      <InfoTile name="ma-partner-data" title="Partner Data">
        <Loader />
      </InfoTile>
    );
  }

  const { merchantAccount } = data;

  const dataSource = merchantAccount.programManager.activePartners?.flatMap((activePartner) =>
    Object.keys(activePartner.partner.merchant_attribute_configuration ?? {})
      .map((key) => ({
        key,
        ...activePartner.partner.merchant_attribute_configuration[key],
      }))
      .filter(
        ({ attribute, sources }) =>
          ['partner_id_string', 'partner_id_generated'].includes(attribute) &&
          sources[merchantAccount.source] !== 'excluded',
      )
      .map((attribute) => ({
        ...attribute,
        partner: activePartner.partner,
        required: attribute.sources[merchantAccount.source] === 'required',
        value: merchantAccount.partnerData?.[activePartner.partner.api_name]?.[attribute.key] ?? undefined,
      })),
  );

  const updatePartnerData = (row) => {
    update({
      variables: {
        id, // merchantAccount
        partner: row.partner.api_name,
        key: row.key,
        value: row.value,
      },
      optimisticResponse: {
        updateMerchantAccountPartnerData: {
          id,
          partnerData: {
            ...(merchantAccount.partnerData ?? {}),
            [row.partner.api_name]: {
              ...(merchantAccount.partnerData?.[row.partner.api_name] ?? {}),
              [row.key]: row.value,
            },
          },
        },
      },
    });
  };

  const handleSave = (row) => {
    // Ask for validation before updating or removing an existing value for required key
    if (row.required && merchantAccount.partnerData?.[row.partner.api_name]?.[row.key]) {
      Modal.confirm({
        title: `Are you sure you want to ${row.value ? 'update' : 'remove'} this value ?`,
        icon: <ExclamationCircleOutlined />,
        width: 500,
        content: (
          <Typography.Paragraph>
            <Typography.Text code strong ellipsis="rows" type="warning">
              {row.key}
            </Typography.Text>
            <Typography.Text> value is required by </Typography.Text>
            <Space>
              <Avatar
                size="small"
                src={row.partner.company.theme.icon}
                icon={<DeploymentUnitOutlined />}
                style={{ backgroundColor: partnerTypes[row.partner.type].color }}
                shape="square"
                title={row.partner.name}
              />
              {row.partner.name}.
            </Space>
          </Typography.Paragraph>
        ),
        okText: 'Yes',
        cancelText: 'Cancel',
        onOk() {
          updatePartnerData(row);
        },
      });
    } else {
      updatePartnerData(row);
    }
  };

  const generatePartnerData = (row) => {
    generate({
      variables: {
        id, // merchantAccount
        partner: row.partner.api_name,
        key: row.key,
      },
    });
  };

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      fixed: 'left',
      render: (key, { partner, required }) => (
        <Space>
          <Avatar
            src={partner.company.theme.icon}
            icon={<DeploymentUnitOutlined />}
            style={{ backgroundColor: partnerTypes[partner.type].color }}
            shape="square"
            title={partner.name}
          />
          {key}
          {required ? <Typography.Text type="danger">*</Typography.Text> : ''}
        </Space>
      ),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (value, row) => {
        if (row.attribute === 'partner_id_string') return value;
        return value ? (
          <Typography.Text copyable>{value}</Typography.Text>
        ) : (
          <Button
            onClick={() => generatePartnerData(row)}
            loading={generateLoading}
            disabled={!can('update', 'merchant_account')}
          >
            Generate
          </Button>
        );
      },
      ...(can('update', 'merchant_account')
        ? {
            onCell: (record) =>
              record.attribute === 'partner_id_string'
                ? {
                    title: 'Value',
                    dataIndex: 'value',
                    key: 'value',
                    editable: true,
                    record,
                    handleSave,
                    formItemProps: { rules: [] },
                  }
                : {},
          }
        : {}),
    },
  ];

  // return info tile
  return (
    <>
      <TableTile
        name="ma-partner-data"
        title={
          <Space>
            Partner Data
            {loading && <Loader />}
          </Space>
        }
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size="small"
        scroll={{ x: 1000 }}
        components={components}
        rowClassName={can('update', 'webhook') && 'editable-row'}
      />
    </>
  );
};

export default PartnerData;

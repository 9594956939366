import React, { useState } from 'react';
import { Button, Form, Input, message, Space } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { initializeApp } from 'firebase/app';
import { getAuth, SAMLAuthProvider, signInWithPopup } from 'firebase/auth';
import { EditOutlined } from '@ant-design/icons';
import {
  applySsoConfigMutation,
  metaProgramManagerSsoConfigQuery,
  updateSsoDraftMutation
} from './query';
import InfoTile, { InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { useCan } from '../../../../../../contexts/ability.context';
import styles from '../MetaProgramManagerCompanyExecutive/MetaProgramManagerCompanyExecutive.module.scss';

const MetaProgramManagerSso = ({ id }) => {
  const app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  }, 'metaProgramManager');

  const can = useCan();

  const [isTestOk, setIsTestOk] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [form] = Form.useForm();

  const {
    data: { metaProgramManager: { sso } } = { metaProgramManager: { ssoConfig: null } },
  } = useQuery(
    metaProgramManagerSsoConfigQuery,
    {
      variables: {
        id
      },
    }
  );

  const [updateSso, {loading: loadingUpdateSso}] = useMutation(
    updateSsoDraftMutation,
    {
      variables: {
        id,
      },
      onError: async (err) => {
        message.error(err.graphQLErrors[0].message);
      }
    }
  );

  const [applySsoConfig] = useMutation(
    applySsoConfigMutation,
    {
      variables: {
        id,
      },
      onError: async (err) => {
        message.error(err.graphQLErrors[0].message);
      }
    }
  );

  if (editMode) {
    return (
      <InfoTile
        name='program-manager-sso'
        title='Single Sign On'
      >
        <Form
          form={form}
          disabled
          className="tile-form"
          layout="vertical"
          requiredMark={false}
          initialValues={{
            ...sso?.config,
            x509Certificates: sso?.config?.x509Certificates?.[0],
          }}
          onFieldsChange={() => setIsTestOk(false)}
          onFinish={(values) => {
            form.validateFields()
              .then(() => {
                updateSso({
                  variables: {
                    input: {
                      ...values,
                      x509Certificates: [values.x509Certificates]
                    }
                  }
                }).then(({ data: { updateProgramManagerSsoDraft: programManager } } = { data: { updateProgramManagerSsoDraft: null } }) => {
                  const provider = new SAMLAuthProvider(programManager?.sso?.draft?.providerId);
                  const auth = getAuth(app);
                  auth.tenantId = programManager?.sso?.draft?.tenantId;
                  auth.useDeviceLanguage();
                  signInWithPopup(auth, provider).then(() => {
                    message.success('Single Sign On connexion successful, you can now activate it');
                    setIsTestOk(true);
                  }).catch(e =>
                    message.error('An error occurred, please check your configuration or make sure the account you logged in with has access to your SSO service')
                  )
                })
              })
          }}
        >
          <Form.Item
            name='idpEntityId'
            label='IdP-provided Entity ID'
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='ssoURL'
            label='SSO URL'
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='rpEntityId'
            label='Customer-defined SAML Entity ID'
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='x509Certificates'
            label='Certificate'
            rules={[{required: true}]}
          >
            <Input.TextArea
              rows={6}
            />
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button
                onClick={() => {
                  setIsTestOk(false);
                  setEditMode(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setIsTestOk(false);
                  form.resetFields();
                }}
              >
                Reset
              </Button>
              {isTestOk ? (
                <Button
                  type='primary'
                  onClick={() => applySsoConfig().then(() => {
                    message.success('Your Single Sign On configuration is now saved, you can define it as your login method');
                    setEditMode(false);
                  })}
                >
                  Apply configuration
                </Button>
              ) : (
                <Button
                  loading={loadingUpdateSso}
                  type='primary'
                  htmlType='submit'
                >
                  Test configuration
                </Button>
              )}
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  // return info tile
  return (
    <InfoTile
      name='program-manager-sso'
      title='Single Sign On'
      extra={
        can('update', 'program-manager-login') && (
          <Button onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        )
      }
    >
      <InfoTextRow label="IdP-provided Entity ID" value={sso?.config?.idpEntityId} />
      <InfoTextRow label="SSO URL" value={sso?.config?.ssoURL} />
      <InfoTextRow label="Customer-defined SAML Entity ID" value={sso?.config?.rpEntityId} />
      <InfoTextRow label="Certificate" value={sso?.config?.x509Certificates?.[0]} />
    </InfoTile>
  );
};

export default MetaProgramManagerSso;
import React, { useMemo } from 'react';
import { Row, Table as AntTable } from 'antd';
import { useQuery as useApolloQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { brainpowerQuery } from '../Common/query';
import useSearchFilters from '../../../hooks/useSearchFilters';
import { DATETIME2_FORMAT } from '../../../../../../constants/DATES';
import { getPreviousDateRange, periodTypeToCustom } from '../../../../../../util/date';
import useBaseCurrency from '../../../hooks/useBaseCurrency';
import styles from './chart.module.scss';
import DataExportMenu from '../Common/DataExportMenu';
import { compareByProp } from '../../../../../../util/array';
import useBaseGranularity from '../../../hooks/useBaseGranularity';
import usePartners from '../../../../../../hooks/common/usePartners';
import { renderListCell } from '../../../Common/AdvancedTable/buildColumns';

const MatcherUnreconciledOperations = (props) => {
  const { widget, layout } = props;

  const [currency] = useBaseCurrency();
  const [baseGranularity] = useBaseGranularity();
  const partners = usePartners();

  const { dateFilter, list } = useSearchFilters();

  const parameters = {
    widget: widget.options?.kpi,
    filters: list.map((filter) => {
      if (filter.key === dateFilter.key) {
        return {
          dates: filter.value.period
            ? periodTypeToCustom(filter.value.period)
            : {
                from: moment(filter.value.from, DATETIME2_FORMAT).startOf('minute'),
                to: moment(filter.value.to, DATETIME2_FORMAT).endOf('minute'),
                ...getPreviousDateRange(
                  moment(filter.value.from, DATETIME2_FORMAT),
                  moment(filter.value.to, DATETIME2_FORMAT),
                ),
              },
        };
      }
      return {
        name: filter.key,
        value: filter.value,
        excluded: filter.excluded,
      };
    }),
    granularity: baseGranularity,
    currency,
  };

  const { data, loading, error } = useApolloQuery(brainpowerQuery, {
    variables: {
      service: 'matcher_unreconciled_operations',
      parameters,
    },
  });

  const columns = useMemo(() => [
    {
      title: 'Store',
      dataIndex: 'store',
      key: 'store',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      sorter: {
        compare: compareByProp('store', 'asc', 'string'),
        multiple: 1,
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      defaultSortOrder: 'descend',
      sorter: {
        compare: compareByProp('date', 'asc', 'string'),
        multiple: 2,
      },
    },
    {
      title: 'Cashier',
      dataIndex: 'cashier_partner',
      key: 'cashier_partner',
      render: renderListCell({
        displayOptions: {
          avatar: true,
          avatarShape: 'square',
          hideLabel: true,
          getOption: (value) => {
            const partner = partners?.[value];
            if (!partner) return null;
            return {
              value: partner.api_name,
              label: partner.name,
              img: partner.company.theme.icon,
              color: partner.company.theme.primaryColor,
            };
          },
        },
      }),
      defaultSortOrder: 'ascend',
      sorter: {
        compare: compareByProp('cashier_partner', 'asc', 'string'),
        multiple: 2,
      },
    },
    {
      title: 'Capture',
      dataIndex: 'unreconciled_capture_operations',
      key: 'unreconciled_capture_operations',
      align: 'right',
      width: 80,
    },
    {
      title: 'Refund',
      dataIndex: 'unreconciled_refund_operations',
      key: 'unreconciled_refund_operations',
      align: 'right',
      width: 80,
    },
    {
      title: 'Total',
      dataIndex: 'unreconciled_total_operations',
      key: 'unreconciled_total_operations',
      align: 'right',
      width: 80,
    },
  ]);

  // height * unit + (height-1) * margin - tile padding - tile header - table header
  const tableHeight = layout.h * 36 + (layout.h - 1) * 24 - 2 * 8 - 32 - 39;

  return (
    <div className={styles.root}>
      <Row justify="space-between" align="middle">
        <div className={styles.title}>{widget.name}</div>
        <DataExportMenu data={data?.brainpower.data} widget={widget} />
      </Row>
      <AntTable
        dataSource={data?.brainpower.data}
        columns={columns}
        loading={loading}
        scroll={{ x: 'max-content', y: tableHeight }}
        size="small"
        pagination={false}
      />
    </div>
  );
};

export default MatcherUnreconciledOperations;

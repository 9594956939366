import { gql } from '@apollo/client';

export const addSalesChannelMutation = gql`
  mutation AddSalesChannel($id: ID!, $channel: String) {
    addSalesChannel(id: $id, channel: $channel) {
        id
        channels
        status
        payment_methods {
            id
            imgUrl
            partner_routes {
                id
                channel
                authorization_currency
                partner {
                    id
                    name
                }
                payment_method {
                    id
                }
            }
        }
        merchant_routes(filters: { status: { in: ["active", "inactive"] } }) {
            id
            status
            partner_route {
                id
                authorization_currency
                partner {
                    id
                }
                payment_method {
                    id
                }
                settlement_currency
                channel
            }
        }
        partner {
            id
            settlement_currencies
            partner_data_configuration
            partner_reconciliation_configuration
            risk_assessment_service
        }
    }
  }
`;

export const removeSalesChannelMutation = gql`
  mutation RemoveSalesChannel($id: ID!, $channel: String, $force: Boolean) {
    removeSalesChannel(id: $id, channel: $channel, force: $force) {
        id
        channels
        status
        payment_methods {
            id
            imgUrl
            partner_routes {
                id
                channel
                authorization_currency
                partner {
                    id
                    name
                }
                payment_method {
                    id
                }
            }
        }
        merchant_routes(filters: { status: { in: ["active", "inactive"] } }) {
            id
            status
            partner_route {
                id
                authorization_currency
                partner {
                    id
                }
                payment_method {
                    id
                }
                settlement_currency
                channel
            }
        }
        partner {
            id
            settlement_currencies
            partner_data_configuration
            partner_reconciliation_configuration
            risk_assessment_service
        }
    }
  }
`;


import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Avatar, Button, Drawer, Form, Input, message, Radio, Select, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import referenceCurrencies from 'norbr-shared-lib/constants/referenceCurrencies';

import DomainIcon from '@2fd/ant-design-icons/lib/Domain';
import { SOURCE_LIST } from '../../../../../constants/SOURCES';
import { search, searchByProp } from '../../../../../util/string';
import { useResetFormOnCloseModal } from '../../../Common/Form/utils';
import { ProgramManagerListQuery } from '../../Company/query';
import ProgramManagerAvatar from '../../../Common/ProgramManager/Avatar';
import useNavigateWithSearch from '../../../../../util/navigate';
import { CompanyUserListQuery, MerchantAccountCreateMutation, ProgramManagerQuery } from './query';
import styles from './MerchantAccountCreate.module.scss';
import {useCan} from '../../../../../contexts/ability.context';

const MerchantAccountCreate = ({ visible, onCancel, companyId, programManager }) => {
  const navigate = useNavigateWithSearch();
  const can = useCan();

  const [form] = Form.useForm();

  const [selectedProgramManager, setSelectedProgramManager] = useState(programManager);
  const handleProgramManagerChange = (pm) => {
    setSelectedProgramManager(pm);
    form.resetFields(['company', 'authorizedUsers']);
  };

  const [selectedCompany, setSelectedCompany] = useState(companyId);
  const handleCompanyChange = (c) => {
    setSelectedCompany(c);
    form.resetFields(['authorizedUsers']);
  };

  const programManagersQuery = useQuery(ProgramManagerListQuery, { skip: !!programManager });
  const programManagerQuery = useQuery(ProgramManagerQuery, {
    variables: { programManager: selectedProgramManager },
    skip: !selectedProgramManager,
  });

  const usersQuery = useQuery(CompanyUserListQuery, {
    variables: { companyId: selectedCompany },
    skip: !selectedCompany,
  });

  const [create, { loading }] = useMutation(MerchantAccountCreateMutation, {
    refetchQueries: ['MerchantAccountListQuery', 'CompanyMerchantListQuery'],
    onCompleted: ({ createMerchantAccount }) => {
      message.success('Merchant account successfully created');
      navigate(`/merchant-accounts/${createMerchantAccount.id}`);
    },
  });

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const submit = (values) => {
    create({
      variables: {
        input: values,
      },
    });
    onCancel();
  };

  return (
    <Drawer
      title="Create Merchant Account"
      open={visible}
      onClose={onCancel}
      confirmLoading={loading}
      wrapClassName={styles.modal}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
    >
      <Form form={form} layout="vertical" name="merchantAccountForm" onFinish={submit}>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="programManager"
          label="Program Manager"
          rules={[{ required: true }]}
          initialValue={programManager}
          hidden={!!programManager}
        >
          <Select
            allowClear
            showSearch
            filterOption={searchByProp('label')}
            optionLabelProp="label"
            loading={programManagersQuery.loading}
            onChange={handleProgramManagerChange}
            disabled={!!programManager}
          >
            {programManagersQuery.data?.programManagers?.map((pm) => (
              <Select.Option key={pm.id} value={pm.id} label={pm.name}>
                <Space>
                  <ProgramManagerAvatar programManager={pm} />
                  {pm.name}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="source" label="Source" rules={[{ required: true }]}>
          <Radio.Group options={SOURCE_LIST} />
        </Form.Item>
        <Form.Item
          name="company"
          label="Company"
          rules={[{ required: true }]}
          initialValue={companyId}
          hidden={!!companyId}
        >
          <Select
            allowClear
            showSearch
            filterOption={searchByProp('label')}
            optionLabelProp="label"
            loading={programManagerQuery.loading}
            onChange={handleCompanyChange}
            disabled={!selectedProgramManager || !!companyId}
          >
            {programManagerQuery.data?.programManager.merchantCompanies.map((company) => (
              <Select.Option key={company.id} value={company.id} label={company.name}>
                <Space>
                  <Avatar src={company.theme?.icon} icon={<DomainIcon />} size="small" />
                  {company.name}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {can('read', 'merchant-user') && (
          <Form.Item name="authorizedUsers" label="Authorized users" initialValue={[]}>
            <Select
              loading={usersQuery.loading}
              mode="multiple"
              disabled={!selectedCompany}
              showSearch
              filterOption={(inputValue, option) => search(inputValue, option.label)}
              optionLabelProp="label"
            >
              {usersQuery.data?.merchantCompany.users.map(({ user }) => (
                <Select.Option key={user.id} value={user.id} label={user.full_name}>
                  <Space>
                    <Avatar src={user.avatar} icon={<UserOutlined />} size="small" />
                    {user.full_name} ({user.email})
                  </Space>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item name="reference_currency" label="Reference currency" rules={[{ required: true }]}>
          <Select
            options={Object.values(referenceCurrencies).map((currency) => ({
              label: currency.label,
              value: currency.id,
            }))}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Space>
            <Button type="text" disabled={loading} onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" size="large" loading={loading}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default MerchantAccountCreate;

import React from 'react';
import { ConfigProvider, Spin } from 'antd';
import LoaderFromTheme from '../Layout/Routes/Common/Loader/LoaderFromTheme';

// return black or white as contrast from color
export const contrast = (color, darkColor = '#000000', lightColor = '#FFFFFF') => {
  if (!color) return darkColor;
  let hex = color;
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  // http://stackoverflow.com/a/3943023/112731
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
};

// Set SCSS variables
export const setScssVariables = (theme) => {
  if (theme) {
    document.documentElement.style.setProperty('--primaryColor', theme.primaryColor);
    document.documentElement.style.setProperty('--primaryColorContrast', contrast(theme.primaryColor));
    document.documentElement.style.setProperty('--secondaryColor', theme.secondaryColor);
    document.documentElement.style.setProperty('--secondaryColorContrast', contrast(theme.secondaryColor));
    document.documentElement.style.setProperty('--thirdColor', theme.thirdColor);
    document.documentElement.style.setProperty('--positive', '#52c41a');
    document.documentElement.style.setProperty('--warning', '#faad14');
    document.documentElement.style.setProperty('--negative', '#ff4d4f');
  }
};

// Set AntDesign variables
export const setAntVariables = (theme) => {
  if (theme) {
    Spin.setDefaultIndicator(<LoaderFromTheme theme={theme} />);
    ConfigProvider.config({
      theme: {
        primaryColor: theme.primaryColor,
      },
    });
  }
};

export const defaultTheme = {
  primaryColor: '#00A8A8',
  secondaryColor: '#000000',
  thirdColor: '#FFFFFF',
};

export const applyTheme = (_theme) => {
  const theme = {
    primaryColor: _theme?.primaryColor || defaultTheme.primaryColor,
    secondaryColor: _theme?.secondaryColor || defaultTheme.secondaryColor,
    thirdColor: defaultTheme.thirdColor,
    loader: _theme?.loader,
  };
  setScssVariables(theme);
  setAntVariables(theme);
};

// colors available in color pickers
export const panelColors = [
  /* Turquoise */
  '#1abc9c',
  // '#e8f8f5',
  '#d1f2eb',
  // '#a3e4d7',
  '#76d7c4',
  '#48c9b0',
  // '#1abc9c',
  '#17a589',
  '#148f77',
  // '#117864',
  '#0e6251',

  /* Green Sea */
  // '#16a085',
  // '#e8f6f3',
  // '#d0ece7',
  // '#a2d9ce',
  // '#73c6b6',
  // '#45b39d',
  // '#16a085',
  // '#138d75',
  // '#117a65',
  // '#0e6655',
  // '#0b5345',

  /* Emerald */
  '#2ecc71',
  // '#eafaf1',
  '#d5f5e3',
  // '#abebc6',
  '#82e0aa',
  '#58d68d',
  // '#2ecc71',
  '#28b463',
  '#239b56',
  // '#1d8348',
  '#186a3b',

  /* Nephritis */
  // '#27ae60',
  // // '#e9f7ef',
  // '#d4efdf',
  // // '#a9dfbf',
  // '#7dcea0',
  // '#52be80',
  // // '#27ae60',
  // '#229954',
  // '#1e8449',
  // // '#196f3d',
  // '#145a32',

  /* Peter River */
  '#3498db',
  // '#ebf5fb',
  '#d6eaf8',
  // '#aed6f1',
  '#85c1e9',
  '#5dade2',
  // '#3498db',
  '#2e86c1',
  '#2874a6',
  // '#21618c',
  '#1b4f72',

  /* Belize Hole */
  // '#2980b9',
  // '#eaf2f8',
  // '#d4e6f1',
  // '#a9cce3',
  // '#7fb3d5',
  // '#5499c7',
  // '#2980b9',
  // '#2471a3',
  // '#1f618d',
  // '#1a5276',
  // '#154360',

  /* Amethyst */
  '#9b59b6',
  // '#f5eef8',
  '#ebdef0',
  // '#d7bde2',
  '#c39bd3',
  '#af7ac5',
  // '#9b59b6',
  '#884ea0',
  '#76448a',
  // '#633974',
  '#512e5f',

  /* Wisteria */
  // '#8e44ad',
  // '#f4ecf7',
  // '#e8daef',
  // '#d2b4de',
  // '#bb8fce',
  // '#a569bd',
  // '#8e44ad',
  // '#7d3c98',
  // '#6c3483',
  // '#5b2c6f',
  // '#4a235a',

  /* Wet Asphalt */
  '#34495e',
  // '#ebedef',
  '#d6dbdf',
  // '#aeb6bf',
  '#85929e',
  '#5d6d7e',
  // '#34495e',
  '#2e4053',
  '#283747',
  // '#212f3c',
  '#1b2631',

  /* Midnight Blue */
  // '#2c3e50',
  // // '#eaecee',
  // '#d5d8dc',
  // // '#abb2b9',
  // '#808b96',
  // '#566573',
  // // '#2c3e50',
  // '#273746',
  // '#212f3d',
  // // '#1c2833',
  // '#17202a',

  /* Sunflower */
  '#f1c40f',
  // '#fef9e7',
  '#fcf3cf',
  // '#f9e79f',
  '#f7dc6f',
  '#f4d03f',
  // '#f1c40f',
  '#d4ac0d',
  '#b7950b',
  // '#9a7d0a',
  '#7d6608',

  /* Orange */
  // '#f39c12',
  // '#fef5e7',
  // '#fdebd0',
  // '#fad7a0',
  // '#f8c471',
  // '#f5b041',
  // '#f39c12',
  // '#d68910',
  // '#b9770e',
  // '#9c640c',
  // '#7e5109',

  /* Carrot */
  '#e67e22',
  // '#fdf2e9',
  '#fae5d3',
  // '#f5cba7',
  '#f0b27a',
  '#eb984e',
  // '#e67e22',
  '#ca6f1e',
  '#af601a',
  // '#935116',
  '#784212',

  /* Pumpkin */
  // '#d35400',
  // '#fbeee6',
  // '#f6ddcc',
  // '#edbb99',
  // '#e59866',
  // '#dc7633',
  // '#d35400',
  // '#ba4a00',
  // '#a04000',
  // '#873600',
  // '#6e2c00',

  /* Alizarin */
  '#e74c3c',
  // '#fdedec',
  '#fadbd8',
  // '#f5b7b1',
  '#f1948a',
  '#ec7063',
  // '#e74c3c',
  '#cb4335',
  '#b03a2e',
  // '#943126',
  '#78281f',

  /* Pomegranate */
  // '#c0392b',
  // // '#f9ebea',
  // '#f2d7d5',
  // // '#e6b0aa',
  // '#d98880',
  // '#cd6155',
  // // '#c0392b',
  // '#a93226',
  // '#922b21',
  // // '#7b241c',
  // '#641e16',

  /* Clouds */
  // '#ecf0f1',
  // // '#fdfefe',
  // '#fbfcfc',
  // // '#f7f9f9',
  // '#f4f6f7',
  // '#f0f3f4',
  // // '#ecf0f1',
  // '#d0d3d4',
  // '#b3b6b7',
  // // '#979a9a',
  // '#7b7d7d',

  /* Silver */
  // '#bdc3c7',
  // '#f8f9f9',
  // '#f2f3f4',
  // '#e5e7e9',
  // '#d7dbdd',
  // '#cacfd2',
  // '#bdc3c7',
  // '#a6acaf',
  // '#909497',
  // '#797d7f',
  // '#626567',

  /* Concrete */
  '#95a5a6',
  // '#f4f6f6',
  '#eaeded',
  // '#d5dbdb',
  '#bfc9ca',
  '#aab7b8',
  // '#95a5a6',
  '#839192',
  '#717d7e',
  // '#5f6a6a',
  '#4d5656',

  /* Asbestos */
  // '#7f8c8d',
  // '#f2f4f4',
  // '#e5e8e8',
  // '#ccd1d1',
  // '#b2babb',
  // '#99a3a4',
  // '#7f8c8d',
  // '#707b7c',
  // '#616a6b',
  // '#515a5a',
  // '#424949',

  /* NorbR colors */
  // '#11898B',
  // '#242424',
  // '#FFFFFF',
  '#d0d3d4',
  '#b3b6b7',
  '#7b7d7d',
];

export default {};

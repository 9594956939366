import React from 'react';
import { Button, Col, Drawer, Form, Input, Row, Switch } from 'antd';
import ArrowLeftIcon from '@2fd/ant-design-icons/lib/ArrowLeft';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import TrashCanIcon from '@2fd/ant-design-icons/lib/TrashCan';
import moment from 'moment-timezone';
import { DATETIME_FORMAT } from '../../../../../../constants/DATES';
import { useAutoFocusRef } from '../../../../../../util/antd';
import { useBrainpowerSearchSettings } from '../../../hooks/useBrainpowerSettings';

const SavedSearchEditContent = ({ search, onClose }) => {
  const [{ favorite, searches }, update] = useBrainpowerSearchSettings();

  const [form] = Form.useForm();

  const handleDelete = () => {
    update({
      favorite: favorite === search._id ? 'default' : favorite,
      searches: searches.filter((s) => s._id !== search._id),
    }).then(() => onClose());
  };

  const handleFinish = (values) => {
    update({
      favorite: values.favorite ? search._id : favorite,
      searches: searches.map((s) => (s._id === search._id ? { ...s, label: values.label } : s)),
    }).then(() => onClose());
  };

  // autoFocus on input
  const inputRef = useAutoFocusRef();

  return [
    <Row
      key="info-row"
      justify="space-between"
      align="middle"
      style={{ padding: '8px 24px', backgroundColor: '#f9f8fa', color: '#868686' }}
      hidden={!search._id || search._id === 'default'}
    >
      <Col>Created {moment(search.createdAt).format(DATETIME_FORMAT)}</Col>
      <Col>
        <Button
          icon={<TrashCanIcon />}
          type="text"
          style={{ color: '#868686', backgroundColor: 'whitesmoke' }}
          onClick={handleDelete}
          hidden={search._id === 'default'}
        >
          DELETE
        </Button>
      </Col>
    </Row>,
    <Form
      key="saved-search-edit-form"
      form={form}
      onFinish={handleFinish}
      layout="vertical"
      style={{ padding: '24px 24px' }}
      initialValues={search}
      requiredMark={false}
    >
      <Form.Item name="label" label="Name" rules={[{ required: true, message: '* required' }]}>
        <Input ref={inputRef} disabled={search._id === 'default'} />
      </Form.Item>
      {search._id && (
        <Form.Item
          name="favorite"
          label="Pin search as default"
          valuePropName="checked"
          initialValue={search._id === favorite}
        >
          <Switch disabled={search._id === favorite} />
        </Form.Item>
      )}
      <Form.Item shouldUpdate style={{ marginTop: 40 }}>
        {() => (
          <Button block type="primary" htmlType="submit" disabled={!form.getFieldValue('label')}>
            SAVE
          </Button>
        )}
      </Form.Item>
    </Form>,
  ];
};

const SavedSearchEditDrawer = ({ open, onClose, onBack, containerId, search = {} }) => (
  <Drawer
    width={470}
    open={open}
    getContainer={`#${containerId}`}
    style={{ position: 'absolute', zIndex: 50 }}
    bodyStyle={{ padding: 0 }}
    onClose={onClose}
    destroyOnClose
    title={`Search / ${search.label ?? 'Save search filters'}`}
    closable
    closeIcon={<WindowCloseIcon />}
    extra={<ArrowLeftIcon onClick={onBack} />}
    mask={false}
    push={false}
    autoFocus={false}
  >
    <SavedSearchEditContent search={search} onClose={onBack} />
  </Drawer>
);

export default SavedSearchEditDrawer;

import { gql } from '@apollo/client';

export const activityMatcherQuery = gql`
  query ActivityMatcher($orderId: ID!) {
    matcherActivity(id: $orderId) {
      _id
      id
      order_merchant_id
      amount
      currency
      is_reconciled
      reconciled_amount
      unreconciled_amount
      createdAt
      order_id
      transactions {
        id
        is_reconciled
        reconciled_amount
        unreconciled_amount
        order_id
        psp_transaction_id
        psp_transaction_date
        payment_method_name
        payin_partner_api_name
        createdAt
        amount
        currency
        cashier_partner_api_name
        pos_file_id
        pos_file_name
        pos_file_date
        merchant_terminal_id
        operations {
          id
          type
          request_response
          request_date
          request_status
          result_response
          result_date
          result_status
          partner_operation_id
          amount
          currency
          is_reconciled
          is_withdraw
          is_pending
          reconciliation_type
          reconciliation_date
          reconciliation_information {
            author {
              id
              email
              avatar
              full_name
            }
            author_id
            author_email
            reconciliation_reason
            withdraw_reason
            note
          }
          notification_date
          createdAt
          updatedAt
        }
      }
      merchant_account {
        id
        name
      }
    }
  }
`;

export const reconciliationCandidatesQuery = gql`
  query ReconciliationCandidates($operationId: ID!, $offset: Int, $limit: Int) {
    reconciliationCandidates(id: $operationId, offset: $offset, limit: $limit) {
      psp_transaction_id
      psp_operation_id
      operation_type
      action_date
      paymentMethod {
        api_name
      }
      amount
      currency
      partner {
        api_name
      }
      merchant_account {
        id
      }
      score
    }
  }
`;

export const reconcileMutation = gql`
  mutation ReconcileMutation(
    $operationId: ID!
    $pspOperationId: ID
    $reason: ReconciliationReason
    $note: String
    $type: ReconciliationType!
  ) {
    reconcile(operationId: $operationId, pspOperationId: $pspOperationId, reason: $reason, note: $note, type: $type)
  }
`;

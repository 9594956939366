import React from 'react';
import classnames from 'classnames';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import styles from './SortableList.module.scss';

const getListStyle = (isDraggingOver, size) => ({
  minHeight: size * 40,
  background: isDraggingOver ? '#f8f8f8' : 'inherit',
});

const SortableList = (props) => {
  const { children, droppableId, type, label, separatorIndex, onSort } = props;

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination || result.destination.droppableId !== droppableId) {
      return;
    }
    onSort(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.slotContainer}>
        {children.map((c, index) => (
          <div
            key={`slot-${index + 1}`}
            className={classnames(styles.slot, {
              [styles.slotWithSeparator]: index + 1 === separatorIndex,
            })}
          >{`${index + 1}.`}</div>
        ))}
      </div>
      <Droppable droppableId={droppableId} type={type}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver, children.length)}
          >
            {children.slice(0, separatorIndex)}
            {separatorIndex < children.length && [
              <div key="separator" className={styles.separator}>
                <span>max</span>
              </div>,
              children.slice(separatorIndex),
            ]}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SortableList;

import { gql } from '@apollo/client';

export const PartnerCompaniesQuery = gql`
  {
    partnerCompanies {
      id
      name
    }
  }
`;

export const PartnersQuery = gql`
  {
    partners {
      id
      name
      type
      company {
        theme {
          icon
        }
      }
      allow_payfac
    }
  }
`;


export const MetaProgramManagerPartnersQuery = gql`
    query MetaProgramManagerActivePartners($id: ID!){
        metaProgramManager(id: $id) {
            id
            activePartners {
                id
                partner {
                    id
                    name
                    type
                    company {
                        theme {
                            icon
                        }
                    }
                    allow_payfac
                }
            }
        }
    }
`;

export default {};

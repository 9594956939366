import React, { useMemo } from 'react';
import { Table } from 'antd';
import classnames from 'classnames';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import OPERATION_TYPE_ENUM from 'norbr-shared-lib/constants/reconciliation/operationTypes/enum';
import LinkVariantIcon from '@2fd/ant-design-icons/lib/LinkVariant';
import LinkVariantOffIcon from '@2fd/ant-design-icons/lib/LinkVariantOff';
import RECONCILIATION_TYPE_ENUM from 'norbr-shared-lib/constants/transaction/reconciliationTypes/enum';
import { buildColumnProps, renderTextCell } from '../../Common/AdvancedTable/buildColumns';
import fields from '../../Common/constants/fields';
import styles from './MatcherDrawer.module.scss';
import { amountFormatter } from '../../../../../util/formatter';
import DateFormats from '../../../../../constants/dateFormats';
import { filterTransactionalOperationsByType } from '../utils';

const OperationsTable = ({ transaction, selectedOperationId, setSelectedOperationId }) => {
  const dataSource = useMemo(
    () =>
      transaction?.operations
        // filter "transactional" operations
        .filter(filterTransactionalOperationsByType)
        .map((op) => ({
          [Fields.PSP_TRANSACTION_ID]: transaction.psp_transaction_id,
          [Fields.PAYMENT_METHOD]: transaction.payment_method_name,
          [Fields.PAYIN_PARTNER]: transaction.cashier_partner_api_name,
          [Fields.CURRENCY]: transaction.currency,
          [Fields.OPERATION_ID]: op.id,
          [Fields.OPERATION_IS_RECONCILED]: op.is_reconciled,
          [Fields.RECONCILIATION_TYPE]: op.reconciliation_type,
          [Fields.PSP_OPERATION_ID]: op.partner_operation_id,
          [Fields.OPERATION_CREATION_DATE]: op.createdAt,
          [Fields.OPERATION_TYPE]: op.type,
          [Fields.OPERATION_AMOUNT]: op.amount,
        })),
    [transaction],
  );

  return (
    <Table
      className={classnames(styles.table)}
      showHeader={false}
      size="small"
      columns={[
        {
          dataIndex: Fields.OPERATION_IS_RECONCILED,
          width: 44,
          render: (value, row) => (row[Fields.OPERATION_IS_RECONCILED] ? <LinkVariantIcon /> : <LinkVariantOffIcon />),
        },
        {
          dataIndex: Fields.PSP_OPERATION_ID,
          title: 'ID PARTNER',
          width: 150,
          ...buildColumnProps(fields[Fields.PSP_OPERATION_ID]),
          render: (value) =>
            value ? (
              renderTextCell(fields[Fields.PSP_OPERATION_ID])(value)
            ) : (
              <span style={{ fontStyle: 'italic', color: 'lightgrey', fontSize: '12px' }}>Missing identifier</span>
            ),
        },
        {
          dataIndex: Fields.PAYMENT_METHOD,
          title: 'PM',
          width: 56,
          ...buildColumnProps(fields[Fields.PAYMENT_METHOD]),
        },
        {
          dataIndex: Fields.OPERATION_CREATION_DATE,
          title: 'CREATION',
          width: 180,
          ...buildColumnProps(fields[Fields.OPERATION_CREATION_DATE], { format: DateFormats.EN_DATETIME }),
        },
        {
          dataIndex: Fields.OPERATION_AMOUNT,
          title: 'OP AMOUNT',
          width: 150,
          ...buildColumnProps(fields[Fields.OPERATION_AMOUNT]),
          render: (value = null, row) => {
            if (value === null) return null;
            return row[Fields.OPERATION_TYPE] === OPERATION_TYPE_ENUM.REFUND ||
              row[Fields.OPERATION_TYPE] === OPERATION_TYPE_ENUM.CREDIT ? (
              <span style={{ color: '#ff4f4e' }}>{amountFormatter(-value, row[Fields.CURRENCY])}</span>
            ) : (
              <span style={{ color: '#00cc7e' }}>{amountFormatter(value, row[Fields.CURRENCY])}</span>
            );
          },
        },
        {
          dataIndex: Fields.PAYIN_PARTNER,
          title: 'PARTNER',
          // width: 150,
          ...buildColumnProps({
            ...fields[Fields.CASHIER_PARTNER],
            displayOptions: {
              avatar: true,
              avatarShape: 'square',
              hideLabel: false,
            },
          }),
        },
        { width: 44 },
      ]}
      dataSource={dataSource}
      pagination={false}
      rowKey={Fields.OPERATION_ID}
      rowClassName={(row) =>
        classnames(styles.row, {
          [styles.selectedRow]: row[Fields.OPERATION_ID] === selectedOperationId,
          [styles.unselectedRow]: row[Fields.OPERATION_ID] !== selectedOperationId,
          [styles.reconciledRow]: row[Fields.OPERATION_IS_RECONCILED],
          [styles.pendingRow]: row[Fields.RECONCILIATION_TYPE] === RECONCILIATION_TYPE_ENUM.PENDING,
          [styles.unreconciledRow]:
            !row[Fields.OPERATION_IS_RECONCILED] &&
            row[Fields.RECONCILIATION_TYPE] !== RECONCILIATION_TYPE_ENUM.PENDING,
        })
      }
      onRow={(row) => ({
        onClick: () => setSelectedOperationId(row[Fields.OPERATION_ID]),
      })}
      scroll={{ x: 'auto' }}
    />
  );
};

export default OperationsTable;

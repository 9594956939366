import React from 'react';
import { Skeleton } from 'antd';
import TableTile from '../../../Tile/Table/Table';

const PaymentOperationsSkeleton = () => {
  const columns = ['Date', 'Type', 'Result', 'Amount', 'Transaction', 'Reference'].map((column, index) => ({
    title: column,
    key: index,
    render: () => (
      <Skeleton.Button
        title={false}
        style={{
          height: 20,
          width: Math.floor(Math.random() * 180 + 40),
        }}
        active
      />
    ),
  }));

  return (
    <TableTile
      name="payment-operations"
      title="Payment operations"
      columns={columns}
      dataSource={[{ key: 1 }, { key: 2 }]}
      scroll={{ x: 1000 }}
    />
  );
};

export default PaymentOperationsSkeleton;

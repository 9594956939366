import { gql } from '@apollo/client';

export const companyContactsQuery = gql`
  query CompanyContacts($companyId: ID!) {
    merchantCompany(id: $companyId) {
      id
      name
      contacts {
        _id
        firstname
        surname
        email
        phoneNumber
        title
        type
        priorityLevel
        main
      }
      theme {
        icon
        primaryColor
        secondaryColor
      }
      attributeListRequiredByPartners
    }
  }
`;

export const removeCompanyContactMutation = gql`
  mutation RemoveCompanyContact($companyId: ID!, $contactId: ID!) {
    removeCompanyContact(id: $companyId, contactId: $contactId) {
      id
      contacts {
        _id
        firstname
        surname
        email
        phoneNumber
        title
        type
        priorityLevel
        main
      }
    }
  }
`;

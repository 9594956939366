import React, { useState } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styles from './AddableList.module.scss';
import AddableItem from './AddableItem';
import useToggle from '../../../../../../../../hooks/useToggle';

const AddableList = (props) => {
  const { itemList, onAdd, placeholder, subtitle } = props;

  const [searchInput, setSearchInput] = useState();
  const [displayAll, { toggle }] = useToggle(false);
  return (
    <>
      <Input
        className={styles.searchInput}
        placeholder={placeholder}
        allowClear
        size="normal"
        prefix={<SearchOutlined />}
        onChange={(event) => setSearchInput(event.target.value.toLowerCase())}
      />
      <div className={styles.searchResults}>
        {searchInput &&
          itemList
            .filter((item) => item.label.toLocaleLowerCase().includes(searchInput)) // apply search
            .map((item) => <AddableItem key={item.id} onAdd={onAdd} {...item} />)}
      </div>
      <div className={styles.subtitle}>{subtitle}</div>
      {itemList
        .slice(0, displayAll ? itemList.length : 5) // only 5
        .sort((a, b) => (a.label > b.label ? 1 : -1))
        .filter((item) => !searchInput || !item.label.toLocaleLowerCase().includes(searchInput)) // filter current searched
        .map((item) => (
          <AddableItem key={item.id} onAdd={onAdd} {...item} />
        ))}
      <div className={styles.seeAll} tabIndex={0} role="button" onClick={toggle} onKeyPress={toggle}>
        {displayAll ? 'see less' : 'see all'}
      </div>
    </>
  );
};

export default AddableList;

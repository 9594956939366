import { gql } from '@apollo/client';

export const ProgramManagerUsersQuery = gql`
  query ProgramManagerUsers($id: ID!) {
    programManager(id: $id) {
      id
      users {
        id
        email
        full_name
        avatar
        status
        disabled
        aclProfile {
          id
          name
        }
      }
      programManagerAclProfiles {
        id
        name
      }
    }
  }
`;

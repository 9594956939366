import React from 'react';
import {Space, Radio, Tooltip, Modal} from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import InfoTile from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import {
  programManagerLoginMethodQuery, updateProgramManagerLoginMethodMutation
} from './query';
import { useCan } from '../../../../../../contexts/ability.context';

const ProgramManagerLoginMethod = ({ id }) => {
  const can = useCan();
  const [modal, contextHolder] = Modal.useModal();

  const {
    data: { programManager: { sso, loginMethod } } = { programManager: { sso: null, loginMethod: null } },
    loading,
  } = useQuery(
    programManagerLoginMethodQuery,
    {
      variables: {
        id
      },
    }
  );

  const [updateLoginMethod, updateState] = useMutation(
    updateProgramManagerLoginMethodMutation,
    {
      variables: {
        id,
      }
    }
  )

  return (
    <InfoTile
      name='program-manager-login-method'
      title='Login method'
    >
      <Radio.Group
        onChange={(event) => modal.confirm({
          title: event.target.value === 'sso' ? 'Are you sure you want to activate SSO for this program manager?' : 'Are you sure you want to deactivate SSO for this program manager?',
          onOk: () => updateLoginMethod({
            variables: {
              input: {
                loginMethod: event.target.value,
              }
            },
            optimisticResponse: {
              updateProgramManagerLoginMethod: {
                id,
                loginMethod: event.target.value,
                __typename: 'ProgramManager',
              }
            }
          }),
        })}
        value={loginMethod}
        disabled={!can('update', 'program-manager-login') || loading || updateState.loading}
      >
        <Space direction="vertical">
          <Radio value="form">Email / password form</Radio>
          <Tooltip
            align='center'
            title={!(sso?.config?.tenantId && sso?.config?.providerId) && 'Please fill your Single Sign On configuration first'}
          >
            <Radio value="sso" disabled={!(sso?.config?.tenantId && sso?.config?.providerId)}>Single sign on</Radio>
          </Tooltip>
        </Space>
      </Radio.Group>
      {contextHolder}
    </InfoTile>
  );
};

export default ProgramManagerLoginMethod;
import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { compareByProp } from '../../../../../../util/array';
import { config as dimensionsConfig } from '../../constants/dimensions';

export default (divId, widget, data, args) => {
  const { dimensions, indicators, options } = widget;

  const dimension = dimensionsConfig[dimensions[0]];

  // Create chart
  const chart = am4core.create(divId, am4charts.TreeMap);
  chart.numberFormatter.numberFormat = '#.';

  if (dimensions.length >= 2) {
    // Map 2 dimensions data
    chart.data = [...data.data]
      .map(({ [dimension.id]: name, ...dim2Values }) => ({
        name,
        children: Object.keys(dim2Values).map((key) => ({ name: key, value: dim2Values[key] })),
      }))
      .map((row) => ({ ...row, total: row.children.reduce((memo, child) => memo + child.value, 0) }))
      .sort(compareByProp('total', 'desc'))
      .slice(0, options?.slice_items || 8);
  } else {
    // Map 1 dimension data
    chart.data = [...data.data]
      // use dimension config
      .map((row) => ({
        name: row[dimension.id],
        value: row[indicators[0]],
      }))
      .sort(compareByProp('value', 'desc'))
      .slice(0, options?.slice_items || 8);
  }

  // only one level visible initially
  chart.maxLevels = 1;
  // define data fields
  chart.dataFields.value = 'value';
  chart.dataFields.name = 'name';
  chart.dataFields.children = 'children';

  // enable navigation
  chart.navigationBar = new am4charts.NavigationBar();

  const labelBullet = new am4charts.LabelBullet();
  labelBullet.locationX = 0.5;
  labelBullet.locationY = 0.5;
  labelBullet.label.text = '{name}: {value} ({percent}%)';
  labelBullet.label.fill = am4core.color('#ffffff');

  // level 0 series template
  const level0SeriesTemplate = chart.seriesTemplates.create('0');
  level0SeriesTemplate.bullets.push(labelBullet);
  level0SeriesTemplate.tooltip.disabled = true;
  // level1 series template
  const level1SeriesTemplate = chart.seriesTemplates.create('1');
  level1SeriesTemplate.bullets.push(labelBullet);
  level1SeriesTemplate.tooltip.disabled = true;

  // let level1_column = level0SeriesTemplate.columns.template;
  // level1_column.column.cornerRadius(10, 10, 10, 10);
  // level1_column.fillOpacity = 0.8;
  // level1_column.stroke = am4core.color("#fff");
  // level1_column.strokeWidth = 5;
  // level1_column.strokeOpacity = 1;

  chart.homeText = dimension.label;

  return chart;
};

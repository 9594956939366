import React from 'react';
import {useTranslation} from 'react-i18next';
import {getText, getValue} from '../utils';

const UpdateEdited = ({log}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      Edited:
      <br/>
      {log.log_update.edited.map(edit => (
        <div key={edit[0]}>
          {getText(edit[0], log.log_entity_type, t)} changed to {getValue(edit[0], log.log_entity_type, t, i18n, edit[2])} (initial value was {getValue(edit[0], log.log_entity_type, t, i18n, edit[1])})
          <br/>
        </div>
      ))}
    </>
  );
};

export default UpdateEdited;
import React from 'react';
import { useParams } from 'react-router-dom';
import {message, Skeleton, Space} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import { CompanyThemeImgUpload, CompanyThemeUpdate } from '../../query';
import InfoTile from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import CropImgUploader from '../../../../Common/Uploader/CropImgUploader';
import ColorPicker from '../../../../../ThemeEditor/ColorPicker/ColorPicker';
import { useCan } from '../../../../../../contexts/ability.context';
import styles from '../../../MerchantAccounts/MerchantAccountView/CompanyInformation/CompanyInformation.module.scss';
import { CompanyThemeQuery } from './query';

const CompanyTheme = () => {
  const can = useCan();
  const { companyId } = useParams();

  const { data: { merchantCompany } = { merchantCompany: null}, loading} = useQuery(CompanyThemeQuery, {
    variables: {
      companyId
    }
  });

  const primaryColor = merchantCompany.theme?.primaryColor || merchantCompany.programManager.theme.primaryColor || '#00a8a8';
  const secondaryColor = merchantCompany.theme?.secondaryColor || merchantCompany.programManager.theme.secondaryColor || '#000000';

  const [uploadCompanyThemeImg] = useMutation(CompanyThemeImgUpload, {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleUpload = (type) => (file) => {
    uploadCompanyThemeImg({ variables: { id: companyId, type, file } });
  };

  const [updateCompanyTheme] = useMutation(CompanyThemeUpdate, {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleChangeColor = (name) => (color) => {
    updateCompanyTheme({
      variables: {
        id: companyId,
        theme: {
          primaryColor,
          secondaryColor,
          [name]: color,
        },
      },
      optimisticResponse: {
        updateCompanyTheme: {
          __typename: 'MerchantCompany',
          id: companyId,
          theme: {
            ...merchantCompany.theme,
            primaryColor,
            secondaryColor,
            [name]: color,
          },
        },
      },
    });
  };

  if (can('update', 'company')) {
    return (
      <InfoTile name="company-theme" title="Company Theme">
        <Space direction="vertical">
          <CropImgUploader
            label="Company logo"
            value={merchantCompany.theme.logo}
            onChange={handleUpload('logo')}
            aspect={4} // ratio 4/1 - horizontal rectangle
            info="Recommended: PNG, ratio 4/1"
            loading={loading}
          />
          <CropImgUploader
            label="Company icon"
            value={merchantCompany.theme?.icon}
            onChange={handleUpload('icon')}
            aspect={1} // ratio 1/1 - square
            info="Recommended: PNG, ratio 1/1"
            loading={loading}
          />
          <CropImgUploader
            label="Company banner"
            value={merchantCompany.theme?.banner}
            onChange={handleUpload('banner')}
            aspect={4} // ratio 4/1 - horizontal rectangle
            info="Recommended: PNG, ratio 4/1"
            loading={loading}
          />
          <ColorPicker
            label="Primary color"
            value={primaryColor}
            onChange={handleChangeColor('primaryColor')}
            description="Color applied on active/focused elements."
          />
          <ColorPicker
            label="Background color"
            value={secondaryColor}
            onChange={handleChangeColor('secondaryColor')}
            description="Color used on some backgrounds."
          />
        </Space>
      </InfoTile>
    );
  }

  return (
    <InfoTile name="company-theme" title="Company Theme">
      <Space
        direction='vertical'
      >
        {merchantCompany?.theme?.icon ? (
          <Space>
            <img src={merchantCompany?.theme?.icon} alt="Company icon" className={styles.companyLogo}/>
            <div>
              Company icon
            </div>
          </Space>
        ) : 'No company icon'}
        {merchantCompany?.theme?.logo ? (
          <Space>
            <img src={merchantCompany?.theme?.logo} alt="Company logo" className={styles.companyLogo}/>
            <div>
              Company logo
            </div>
          </Space>
        ) : ' No company logo'}
        <Space>
          <div className={styles.colorBlock} style={{backgroundColor: primaryColor, objectFit: 'contain', width: 58, height: 58 }} />
          <div>
            Primary color
          </div>
        </Space>
        <Space>
          <div className={styles.colorBlock} style={{ backgroundColor: secondaryColor, objectFit: 'contain', height: 58 }} />
          <div>
            Secondary color
          </div>
        </Space>
      </Space>
    </InfoTile>
  );
};

export default CompanyTheme;

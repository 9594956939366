import React from 'react';
import { Avatar, Space } from 'antd';
import TagCell from './TagCell';

const OptionCell = ({ field, option }) => {
  const { label, img, icon: Icon, color } = option;
  if (!field.displayOptions) return label;
  if (field.displayOptions.tag) return <TagCell field={field} option={option} />;
  return (
    <Space>
      {field.displayOptions.avatar && (
        <Avatar
          key="avatar"
          src={img}
          shape={field.displayOptions.avatarShape ?? 'circle'}
          size={28}
          title={label}
          style={{ backgroundColor: color }}
        >
          {label?.[0]?.toUpperCase() ?? '?'}
        </Avatar>
      )}
      {field.displayOptions.image && img && (
        <img
          key="img"
          src={img}
          alt={label}
          title={label}
          height="24"
          style={{ borderRadius: 2, backgroundColor: color }}
        />
      )}
      {field.displayOptions.icon && <Icon key="icon" title={label} style={{ fontSize: 18, marginTop: 4 }} />}
      {!field.displayOptions.hideLabel && label}
    </Space>
  );
};

export default OptionCell;

import React, { useState } from 'react';
import { Layout, PageHeader, Space } from 'antd';
import FeatureSearchIcon from '@2fd/ant-design-icons/lib/FeatureSearch';
import ForwardburgerIcon from '@2fd/ant-design-icons/lib/Forwardburger';
import BackburgerIcon from '@2fd/ant-design-icons/lib/Backburger';
import InboxArrowDownIcon from '@2fd/ant-design-icons/lib/InboxArrowDown';
import { useLayoutMenu } from '../../../layout.context';
import { DrawerContext } from '../Common/useDrawer';
import Drawers from '../Common/constants/drawers';
import SearchDrawer from './SearchDrawer/SearchDrawer';
import brainpowerStyles from '../Brainpower.module.scss';
import { formatEndUnderline } from '../Common/utils';
import HeaderButton from '../Common/HeaderButton/HeaderButton';
import ExportTable from './ExportTable/ExportTable';

const ExportList = () => {
  const [isMenuCollapsed, toggleMenu] = useLayoutMenu();

  const [drawerState, setDrawerState] = useState();
  const handleToggleDrawer = (key) => () => setDrawerState(drawerState !== key ? key : undefined);
  const handleCloseDrawer = () => setDrawerState(undefined);

  const [searchFilters, setSearchFilters] = useState({});

  return (
    <DrawerContext.Provider value={[drawerState, setDrawerState]}>
      <Layout className={brainpowerStyles.root}>
        <PageHeader
          className={brainpowerStyles.pageHeader}
          backIcon={isMenuCollapsed ? <ForwardburgerIcon /> : <BackburgerIcon />}
          onBack={toggleMenu}
          title={
            <Space>
              <InboxArrowDownIcon />
              {formatEndUnderline('Exports')}
            </Space>
          }
          extra={[
            <HeaderButton
              drawerState={drawerState}
              key={Drawers.SEARCH}
              id={Drawers.SEARCH}
              label="SEARCH"
              icon={FeatureSearchIcon}
              onClick={handleToggleDrawer(Drawers.SEARCH)}
            />,
          ]}
        />
        <ExportTable searchFilters={searchFilters} onSearch={setSearchFilters} />
        <SearchDrawer
          open={drawerState === Drawers.SEARCH}
          onClose={handleCloseDrawer}
          containerId="export-table"
          onSearch={setSearchFilters}
          searchFilters={searchFilters}
        />
      </Layout>
    </DrawerContext.Provider>
  );
};

export default ExportList;

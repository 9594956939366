import { gql } from '@apollo/client';

export const CompanyListQuery = gql`
  {
    merchantCompanies {
      id
      name
    }
  }
`;

export const CompanyMerchantAccountListQuery = gql`
  query CompanyMerchantAccountListQuery($companyId: ID!) {
    merchantCompany(id: $companyId) {
      __typename
      id
      merchantAccounts {
        id
        name
      }
    }
  }
`;

export const UserCreateMutation = gql`
  mutation UserCreate($input: MerchantUserCreationInput!) {
    createMerchantUser(input: $input) {
      __typename
      id
      full_name
      phone {
        code
        number
      }
      status
      companies {
        company {
          id
          name
        }
        aclProfile {
          id
          name
        }
        isGdpr
      }
    }
  }
`;

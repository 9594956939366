import React, { useMemo, useState } from 'react';
import { Button, Checkbox, Col, Empty, Input, message, Modal, Row, Select, Space, Table, Typography } from 'antd';
import { useMutation } from '@apollo/client';
import { EditOutlined, ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { difference } from '../../../../../../util/array';
import { searchByProp } from '../../../../../../util/string';
import { useCan } from '../../../../../../contexts/ability.context';
import InfoTile from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { PartnerUpdateMutation } from '../query';
import styles from '../PartnerView.module.scss';

/** Factorized components used for
 * - PartnerDataConfiguration
 * - PartnerReconciliationConfiguration
 * - PartnerPayFacConfiguration
 *
 * @param title
 * @param partner
 * @param configurationKey
 * @param hasLevel: display "Level" param, used by PartnerPayFacConfiguration to determine who will configure the field, either the PayFac (Master) or the Merchant.
 * @returns {JSX.Element}
 * @constructor
 */
const PartnerFieldsConfiguration = ({ title, partner, configurationKey, hasLevel = false }) => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);

  const initialConfigurationFields = Object.keys(partner[configurationKey] ?? {}).reduce(
    (memo, key, index) => [
      ...memo,
      { key, ...partner[configurationKey][key], ...partner[configurationKey][key].sources, uniqKey: `${index}_${key}` },
    ],
    [],
  );

  const [configurationFields, setConfigurationFields] = useState(initialConfigurationFields);

  // fields validation
  const errors = useMemo(
    () =>
      configurationFields.reduce(
        (memo, row, index) => [
          ...memo,
          ...(row.key === '' ? [{ index, message: `Missing key` }] : []),
          ...(!/^[\w-]+$/.test(row.key) ? [{ index, message: `Invalid format` }] : []),
          ...(configurationFields.filter(({ key }) => key === row.key).length > 1
            ? [{ index, message: `Duplicate key` }]
            : []),
          ...(row.type === 'Enum' && !(row.enum?.length > 0) ? [{ index, message: `Missing enum values` }] : []),
        ],
        [],
      ),
    [configurationFields],
  );

  const onCellChange = (rowIndex, key, value) => {
    setConfigurationFields((prevFields) =>
      prevFields.map((row, index) => (index === rowIndex ? { ...row, [key]: value } : row)),
    );
  };

  const [updatePartner, { loading }] = useMutation(PartnerUpdateMutation, {
    onCompleted: () => {
      message.success('Partner successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const onSubmit = (value) => {
    setConfigurationFields(
      Object.keys(value ?? {}).reduce((memo, key) => [...memo, { key, ...value[key], ...value[key].sources }], []),
    );
    updatePartner({
      variables: {
        id: partner.id,
        input: {
          [configurationKey]: value,
        },
      },
    });
  };

  const handleFinish = () => {
    const newFieldsConfiguration = configurationFields.reduce(
      (memo, { key, stack, insights, insights_pos, ...field }) =>
        key === '' ? memo : { ...memo, [key]: { ...field, sources: { stack, insights, insights_pos } } },
      {},
    );

    const removedKeys = difference(Object.keys(partner[configurationKey] ?? {}), Object.keys(newFieldsConfiguration));

    // if a key is removed or updated, confirm before apply changes
    if (removedKeys.length > 0) {
      Modal.confirm({
        title: 'Are you sure you want to delete this keys?',
        icon: <ExclamationCircleOutlined />,
        content: [
          <Typography.Paragraph>
            You are about to delete this keys:
            <br />
            {removedKeys.map((k) => (
              <Typography.Text code strong ellipsis="rows" type="warning">
                {k}
              </Typography.Text>
            ))}
            .
          </Typography.Paragraph>,
          <Typography.Text>
            If you want to update the name of existing keys, please contact technical support.
          </Typography.Text>,
        ],
        okText: 'Yes',
        cancelText: 'Cancel',
        onOk: () => onSubmit(newFieldsConfiguration),
      });
    } else {
      onSubmit(newFieldsConfiguration);
    }
  };

  const addField = () => {
    setConfigurationFields([
      ...configurationFields,
      {
        key: `field_${configurationFields.length + 1}`,
        type: 'String',
        payment_methods: [],
        level: hasLevel ? 'master' : undefined,
        stack: 'optional',
        insights: 'optional',
        insights_pos: 'optional',
      },
    ]);
  };

  const removeField = (index) => setConfigurationFields(configurationFields.toSpliced(index, 1));

  const resetFields = () => setConfigurationFields(initialConfigurationFields);

  const renderTextCell = (key) => (value, row, index) => {
    if (editMode) {
      const handleChange = (e) => onCellChange(index, key, e.target.value);
      return <Input onBlur={handleChange} bordered={false} defaultValue={value} />;
    }
    return value;
  };
  const renderTypeCell = (key) => (value, row, index) => {
    const { enum: enumValues } = row;
    if (editMode) {
      const handleChange = (_value) => {
        // exception - exclude insights & insights_pos for Certificate
        if (_value === 'Certificate') {
          onCellChange(index, 'insights', 'excluded');
          onCellChange(index, 'insights_pos', 'excluded');
        }
        onCellChange(index, key, _value);
      };
      const handleChangeEnum = (_values) => onCellChange(index, 'enum', _values);
      return (
        <Space>
          <Select
            defaultValue={value}
            onChange={handleChange}
            options={[
              { value: 'String', label: 'String' },
              { value: 'Boolean', label: 'Boolean' },
              { value: 'Enum', label: 'Enum' },
              { value: 'Certificate', label: 'Certificate' },
            ]}
            bordered={false}
          />
          {value === 'Enum' && (
            <Select
              defaultValue={enumValues}
              onChange={handleChangeEnum}
              style={{ width: 240 }}
              mode="tags"
              maxTagCount="responsive"
              placeholder="Enum values"
            />
          )}
        </Space>
      );
    }
    return <span title={value === 'Enum' ? enumValues : null}>{value}</span>;
  };

  const renderBooleanCell = (key) => (value, row, index) => {
    const handleChange = () => onCellChange(index, key, !value);
    return <Checkbox checked={value} onChange={handleChange} disabled={!editMode} />;
  };

  const renderPaymentMethodsCell = (key) => (value, row, index) => {
    if (editMode) {
      const handleChange = (selectedValues) => onCellChange(index, key, selectedValues);
      return (
        <Select
          defaultValue={value}
          onChange={handleChange}
          style={{ minWidth: 240 }}
          maxTagCount="responsive"
          mode="multiple"
          showSearch
          filterOption={searchByProp('label')}
          bordered={false}
          placeholder="Select payment methods"
        >
          {partner.payment_methods.map((pm) => (
            <Select.Option key={pm.id} value={pm.id} label={pm.name}>
              <Space>
                <img src={pm.imgUrl} alt={pm.name} width={24} />
                {pm.name}
              </Space>
            </Select.Option>
          ))}
        </Select>
      );
    }
    return (
      <Space>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {value?.map((paymentMethodId) => {
          const paymentMethod = partner.payment_methods.find((pm) => pm.id === paymentMethodId);
          return paymentMethod ? (
            <img alt={paymentMethod.name} src={paymentMethod.imgUrl} height={24} title={paymentMethod.name} />
          ) : (
            paymentMethodId
          );
        })}
      </Space>
    );
  };

  const levels = {
    master: {
      label: 'Master',
      value: 'master',
    },
    merchant: {
      label: 'Merchant',
      value: 'merchant',
    },
  };
  const renderLevelCell = (key) => (value, row, index) => {
    if (editMode) {
      const handleChange = (selectedValue) => onCellChange(index, key, selectedValue);
      return <Select defaultValue={value} onChange={handleChange} options={Object.values(levels)} bordered={false} />;
    }
    return levels[value].label;
  };

  const sourceModes = {
    required: {
      label: 'Required',
      value: 'required',
    },
    optional: {
      label: 'Optional',
      value: 'optional',
    },
    excluded: {
      label: 'Excluded',
      value: 'excluded',
    },
  };
  const renderSourceCell =
    (key) =>
    (value, { type }, index) => {
      if (editMode) {
        // exception - exclude insights & insights_pos for Certificate
        if (['insights', 'insights_pos'].includes(key) && type === 'Certificate') {
          return (
            <Select
              value={value}
              options={[
                {
                  label: 'Excluded',
                  value: 'excluded',
                  title: 'Cannot use Certificate on Insights and Insights POS',
                },
              ]}
              bordered={false}
              disabled
            />
          );
        }
        const handleChange = (selectedValue) => onCellChange(index, key, selectedValue);
        return <Select value={value} onChange={handleChange} options={Object.values(sourceModes)} bordered={false} />;
      }
      return sourceModes[value]?.label;
    };

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      render: renderTextCell('key'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 350,
      render: renderTypeCell('type'),
    },
    {
      title: 'Payment methods',
      dataIndex: 'paymentMethods',
      render: renderPaymentMethodsCell('paymentMethods'),
    },
    {
      title: 'Merchant',
      dataIndex: 'merchant',
      render: renderBooleanCell('merchant'),
      align: 'center',
    },
    {
      title: 'PayFac',
      dataIndex: 'payfac',
      render: renderBooleanCell('payfac'),
      align: 'center',
    },
    ...(hasLevel
      ? [
          {
            title: 'Level',
            dataIndex: 'level',
            width: 140,
            render: renderLevelCell('level'),
          },
        ]
      : []),
    ...(partner.allow_stack
      ? [
          {
            title: 'Stack',
            dataIndex: 'stack',
            width: 140,
            render: renderSourceCell('stack'),
          },
        ]
      : []),
    ...(partner.allow_insights
      ? [
          {
            title: 'Insights',
            dataIndex: 'insights',
            width: 140,
            render: renderSourceCell('insights'),
          },
        ]
      : []),
    ...(partner.allow_insights_pos
      ? [
          {
            title: 'Insights POS',
            dataIndex: 'insights_pos',
            width: 140,
            render: renderSourceCell('insights_pos'),
          },
        ]
      : []),
    ...(editMode
      ? [
          {
            dataIndex: 'actions',
            align: 'center',
            render: (_, __, index) => [
              <MinusCircleOutlined key="remove-field" onClick={() => removeField(index)} title="Remove field" />,
            ],
          },
        ]
      : []),
  ];

  return (
    <InfoTile
      name={configurationKey}
      title={title}
      extra={[
        !editMode && can('update', 'partner') && (
          <Button key="update-partner" onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        ),
      ]}
    >
      {configurationFields?.length > 0 || editMode ? (
        <Row gutter={[12, 12]} justify="end">
          <Col span={24}>
            <Table
              size="small"
              bordered={false}
              dataSource={configurationFields}
              columns={columns}
              rowKey="uniqKey"
              rowClassName={(row, index) =>
                errors?.find((error) => error.index === index) ? styles.errorFieldRow : null
              }
              pagination={false}
            />
          </Col>
          {editMode && [
            <Col span={24}>
              <Button type="dashed" onClick={addField} block icon={<PlusOutlined />}>
                Add field
              </Button>
            </Col>,
            <Col span={24}>
              {errors.map((error) => (
                <div>
                  <Typography.Text type="danger">
                    #{error.index + 1} - {error.message}
                  </Typography.Text>
                </div>
              ))}
            </Col>,
            <Col>
              <Button
                onClick={() => {
                  resetFields();
                  setEditMode(false);
                }}
              >
                Cancel
              </Button>
            </Col>,
            <Col>
              <Button onClick={() => resetFields()}>Reset</Button>
            </Col>,
            <Col>
              <Button type="primary" onClick={handleFinish} loading={loading} disabled={errors.length > 0}>
                Save
              </Button>
            </Col>,
          ]}
        </Row>
      ) : (
        <Empty />
      )}
    </InfoTile>
  );
};

export default PartnerFieldsConfiguration;

import { gql } from '@apollo/client';

export const registerQuery = gql`
  query Register($date: String!, $merchantAccount: ID!) {
    register(date: $date, merchantAccount: $merchantAccount) {
      _id {
        merchant_account
        operation_date
      }
      details {
        matcher_operation_date
        matcher_merchant_account
        matcher_notification_date
        matcher_matched_transactions
        matcher_unmatched_transactions
        matcher_matched_amount
        matcher_matched_balance
        matcher_unmatched_amount
        matcher_unmatched_balance
        matcher_total_transactions
        matcher_total_operations
        matcher_total_amount
        matcher_total_balance
        matcher_reconciliation_type
        matcher_reason
        matcher_withdraw_reason
        currency
      }
      createdAt
    }
  }
`;

import {gql} from '@apollo/client';

export const CompanyOverviewQuery = gql`
  query CompanyOverviewQuery($companyId: ID!) {
    merchantCompany(id: $companyId) {
      id
      users {
        id
        user {
          id
          full_name
          email
          status
          disabled
          avatar
        }
        aclProfile {
          id
          name
        }
        isGdpr
        guest
        authorizedMerchantAccounts {
          id
        }
        authorizedMerchantAccountsCount
      }
      merchantAccounts {
        id
        name
        status
        cms_name
        cms_version
        userCount
        source
      }
    }
  }
`;
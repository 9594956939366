import OPERATION_TYPE_ENUM from 'norbr-shared-lib/constants/reconciliation/operationTypes/enum';

// In Matcher, we consider only "transactional" operations
export const TransactionalOperationTypeEnumList = [
  OPERATION_TYPE_ENUM.CAPTURE,
  OPERATION_TYPE_ENUM.CREDIT,
  OPERATION_TYPE_ENUM.REFUND,
];

export const filterTransactionalOperationsByType = (operation) =>
  TransactionalOperationTypeEnumList.includes(operation.type);
export const filterTransactionalOperationsByOpType = (operation) =>
  TransactionalOperationTypeEnumList.includes(operation.operation_type);

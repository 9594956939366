import React from 'react';
import { Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { HomeOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { ProfileOverviewQuery } from '../query';
import ProfilesOverviewTable from '../../Common/ProfilesOverviewTable/ProfilesOverviewTable';
import useNavigateWithSearch from "../../../../../util/navigate";

const breadcrumbRender = () => (
  <Breadcrumb
    map={{
      '/': <HomeOutlined />,
      '/access-control-list': 'Access Control',
      '/access-control-list/overview': 'Overview',
    }}
  />
);

const AccessControlOverview = () => {
  const navigate = useNavigateWithSearch();
  const { data, loading } = useQuery(ProfileOverviewQuery, { fetchPolicy: 'cache-first' });

  return (
    <PageHeader title="Overview" breadcrumbRender={breadcrumbRender} onBack={() => navigate('/access-control-list')}>
      <Typography.Paragraph>Module&apos;s roles by profile.</Typography.Paragraph>
      <ProfilesOverviewTable loading={loading} aclProfiles={data?.merchantAclProfiles} />
    </PageHeader>
  );
};

export default AccessControlOverview;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorBoundary from '../../Common/ErrorBoundary/ErrorBoundary';
import Page from '../../Common/Page/Page';
import AccessControlView from './AccessControlView/AccessControlView';
import AccessControlList from './AccessControlList/AccessControlList';
import AccessControlOverview from './AccessControlOverview/AccessControlOverview';

const AccessControl = () => (
  <Page>
    <ErrorBoundary>
      <Routes>
        <Route index element={<AccessControlList />} />
        <Route path="overview" element={<AccessControlOverview />} />
        <Route path=":profileId" element={<AccessControlView />} />
      </Routes>
    </ErrorBoundary>
  </Page>
);

export default AccessControl;

const _ = {
  STACK: 'stack',
  INSIGHTS: 'insights',
  INSIGHTS_POS: 'insights_pos',
};

export const SOURCES = {
  [_.STACK]: { value: _.STACK, label: 'Stack', color: '#179a98' },
  [_.INSIGHTS]: { value: _.INSIGHTS, label: 'Insights', color: '#db0054' },
  [_.INSIGHTS_POS]: { value: _.INSIGHTS_POS, label: 'Insights POS', color: '#db0054' },
};

export const SOURCE_LIST = Object.values(SOURCES);

export default _;

import React from 'react';
import moment from 'moment-timezone';
import { useQuery } from '@apollo/client';
import { Badge, Button, List, Skeleton, Space, Tag } from 'antd';
import AlarmCheckIcon from '@2fd/ant-design-icons/lib/AlarmCheck';
import InboxArrowDownIcon from '@2fd/ant-design-icons/lib/InboxArrowDown';
import useNavigateWithSearch from '../../../../../util/navigate';
import { DATE_FORMAT, DATETIME2_FORMAT } from '../../../../../constants/DATES';
import { Block } from '../../../Common/Sider';
import { exportListQuery } from '../../ExportList/ExportTable/query';
import { exportStatuses } from '../../ExportList/constants/exportStatuses';
import usePage from '../../hooks/usePage';
import styles from './ExportDrawer.module.scss';

const LastExportList = () => {
  const page = usePage();
  const { data, loading } = useQuery(exportListQuery);

  const exportList = data?.exports.filter((e) => page.displayEntities.includes(e.view?.entity));

  const navigate = useNavigateWithSearch();

  return (
    <Block
      key="last-export"
      title="Last exports"
      description="You can find all your export history in the Exports menu."
      collapsable
    >
      {loading && <Skeleton />}
      {!loading &&
        exportList && [
          <div key="sub-header" className={styles.subHeader}>
            <span>{page.label} exports</span>
            <Tag>{exportList.length}</Tag>
          </div>,
          <List
            key="last-export-list"
            className={styles.list}
            bordered={false}
            dataSource={exportList?.slice(0, 5)}
            renderItem={(exp) => (
              <List.Item key={exp.id} onClick={() => navigate(`/brainpower/exports/${exp.id}`)}>
                <Space>
                  <Badge status={exportStatuses[exp.status].badge} title={exportStatuses[exp.status].label} />
                  {exp.name ?? moment(exp.createdAt).format(DATETIME2_FORMAT)}
                  {exp.schedule && <Tag icon={<AlarmCheckIcon />}>{exp.reportCount} reports</Tag>}
                </Space>
                <span className={styles.date}>{moment(exp.createdAt).format(DATE_FORMAT)}</span>
              </List.Item>
            )}
          />,
        ]}
      <Button
        block
        type="link"
        className={styles.linkButton}
        onClick={() => navigate('/brainpower/exports')}
        icon={<InboxArrowDownIcon style={{ fontSize: 22, verticalAlign: 'middle' }} />}
      >
        ACCESS TO ALL EXPORTS
      </Button>
    </Block>
  );
};

export default LastExportList;

import React from 'react';
import { Tag } from 'antd';
import styles from './Cell.module.scss';

const TagCell = ({ field, option }) => {
  const { label, color, icon: Icon } = option;
  return (
    <Tag
      className={styles.tagCell}
      color={field.displayOptions.active && color}
      icon={field.displayOptions.icon ? <Icon /> : undefined}
    >
      {!field.displayOptions.active && <div style={{ backgroundColor: color }} />}
      <span>{label}</span>
    </Tag>
  );
};

export default TagCell;

import React, { useCallback, useState } from 'react';
import { Button, Col, Drawer, Input, List, message, Row, Space, Tag } from 'antd';
import { CodeSandboxOutlined, FunctionOutlined, SearchOutlined, StarFilled } from '@ant-design/icons';
import CogIcon from '@2fd/ant-design-icons/lib/Cog';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import moment from 'moment-timezone';
import { useSearchParams } from 'react-router-dom';
import { useSet } from 'react-use';
import { compareByProp, reorder } from '../../../../../../util/array';
import useRandom from '../../../../../../hooks/useRandom';
import { search } from '../../../../../../util/string';
import { Block } from '../../../../Common/Sider';
import SortableList from '../../../../Common/SortableList/SortableList';
import SortableItem from '../../../../Common/SortableList/SortableItem';
import { updateSortInSearchParams } from '../../../hooks/useSort';
import useColumnSet, { updateColumnSetInSearchParams } from '../../../hooks/useColumnSet';
import { useBrainpowerViewSettings } from '../../../hooks/useBrainpowerSettings';
import { updateTableDisplayInSearchParams } from '../../../hooks/useTableDisplay';
import usePage from '../../../hooks/usePage';
import { TableDisplay } from '../../../Common/constants/tableDisplays';
import AddableItem from '../../../Common/SetUpDrawer/Item/AddableItem';
import Item from '../../../Common/SetUpDrawer/Item/Item';
import SavedViewEditDrawer from '../../../Common/SetUpDrawer/SavedViewEditDrawer/SavedViewEditDrawer';
import SavedViewSaveDrawer from '../../../Common/SetUpDrawer/SavedViewSaveDrawer/SavedViewSaveDrawer';
import styles from '../../../Common/SetUpDrawer/SetUpDrawer.module.scss';
import DrawerListItem from '../../../Common/DrawerListItem/DrawerListItem';
import { DataRoles } from '../fields';
import useDimensions from '../../../hooks/useDimensions';
import { useCan } from '../../../../../../contexts/ability.context';

const { CheckableTag } = Tag;

const SetUpContent = ({ containerId }) => {
  const page = usePage();
  const can = useCan();
  const [settings, updateSettings] = useBrainpowerViewSettings();

  const { favorite = 'default', views = [] } = settings;

  const [key, refreshKey] = useRandom();

  const [columnSet, setColumnSet] = useColumnSet();
  const [dimensions] = useDimensions();

  const handleSort = useCallback(
    (sourceIndex, destinationIndex) => {
      // reorder columns
      const newColumnSet = reorder(columnSet, sourceIndex, destinationIndex);
      setColumnSet(newColumnSet);
    },
    [columnSet, setColumnSet],
  );

  const addColumn = useCallback(
    (fieldKey) => {
      setColumnSet([
        ...columnSet,
        {
          field: fieldKey,
          label: page.fields[fieldKey].label.toUpperCase(),
          ...page.fields[fieldKey].defaultSettings,
        },
      ]);
      message.success(
        <span>
          <b>{page.fields[fieldKey].label}</b> column added to the right of the table.
        </span>,
      );
    },
    [columnSet, setColumnSet],
  );

  const removeColumn = useCallback(
    (fieldKey) => {
      setColumnSet(columnSet.filter((c) => c.field !== fieldKey));
    },
    [columnSet, setColumnSet],
  );

  const [openColumn, setOpenColumn] = useState();
  const handleToggleOpen = useCallback(
    (fieldKey) => setOpenColumn(openColumn === fieldKey ? null : fieldKey),
    [openColumn, setOpenColumn],
  );

  const handleColumnChange = useCallback(
    (column) => {
      setColumnSet(columnSet.map((c) => (c.field === column.field ? column : c)));
    },
    [columnSet, setColumnSet],
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const applyView = (view) => () => {
    updateSettings({
      favorite,
      views: views.map((s) =>
        s._id === view._id
          ? {
              ...s,
              lastUse: moment().toISOString(),
            }
          : s,
      ),
    });
    const newSearchParams = new URLSearchParams(searchParams);
    updateColumnSetInSearchParams(newSearchParams, view.columnSet ?? [], page.prefix);
    updateSortInSearchParams(newSearchParams, view.sort ?? [], page.prefix);
    updateTableDisplayInSearchParams(newSearchParams, view.display ?? TableDisplay.RICH, page.prefix);
    setSearchParams(newSearchParams);
    refreshKey();
  };

  const [editView, setEditView] = useState();
  const [saveView, setSaveView] = useState();
  const [searchInput, setSearchInput] = useState();

  const [searchDataRoleSet, { toggle, has }] = useSet(new Set([DataRoles.DIMENSION, DataRoles.INDICATOR]));

  return [
    <Block key="saved-views" title="Saved views" description="Click to apply view" collapsable>
      <List className={styles.list} bordered={false}>
        {[page.defaultView, ...views]
          .sort((a, b) => {
            switch (favorite) {
              case a._id:
                return -1;
              case b._id:
                return 1;
              default:
                return b.lastUse > a.lastUse ? 1 : -1;
            }
          }) // pinned first then sorted by last use
          .map((view) => (
            <DrawerListItem
              key={view._id}
              label={view.label}
              extra={[
                <Button
                  key="edit"
                  type="text"
                  icon={<CogIcon />}
                  title="Edit saved view"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditView(view);
                  }}
                />,
              ]}
              onClick={applyView(view)}
              pinned={favorite === view._id}
            />
          ))}
      </List>
      <Button className={styles.addViewBtn} block icon={<StarFilled />} onClick={() => setSaveView(true)}>
        SAVE VIEW
      </Button>
    </Block>,
    <Block
      key={`set-up-form-${key}`}
      title="Display"
      description="Drag to sort columns, click to edit settings"
      collapsable
    >
      <div className={styles.subHeader}>
        <span>Active columns</span>
        <Tag>{columnSet.length}</Tag>
      </div>
      <SortableList droppableId="columns" type="columns" onSort={handleSort}>
        {columnSet.map((column, index) => (
          <SortableItem key={`column-${column.field}`} id={`column-${column.field}`} index={index}>
            <Item
              index={index}
              column={column}
              field={page.fields[column.field]}
              open={openColumn === column.field}
              toggleOpen={handleToggleOpen}
              onRemove={removeColumn}
              // the last dimension should not be removable
              removable={!(dimensions.length === 1 && page.fields[column.field]?.dataRole === DataRoles.DIMENSION)}
              onChange={handleColumnChange}
            />
          </SortableItem>
        ))}
      </SortableList>
    </Block>,
    <Block key={`set-up-available-${key}`} title="Available columns" description="Click to add column" collapsable>
      <div style={{ padding: '0 16px 16px 16px' }}>
        <Input
          prefix={<SearchOutlined />}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Search a column ..."
          allowClear
        />
      </div>
      <Space style={{ marginBottom: 16 }}>
        <span style={{ color: 'grey' }} >Category</span>
        <div>
          <CheckableTag
            key={DataRoles.DIMENSION}
            checked={has(DataRoles.DIMENSION)}
            onChange={() => toggle(DataRoles.DIMENSION)}
          >
            <Space size={2}>
              <CodeSandboxOutlined />
              Dimensions
            </Space>
          </CheckableTag>
          <CheckableTag
            key={DataRoles.INDICATOR}
            checked={has(DataRoles.INDICATOR)}
            onChange={() => toggle(DataRoles.INDICATOR)}
          >
            <Space size={2}>
              <FunctionOutlined />
              Indicators
            </Space>
          </CheckableTag>
        </div>
      </Space>
      {Object.values(page.fields)
        .filter(
          (field) =>
            !columnSet.find((c) => c.field === field.key) && // remove selected
            (search(searchInput, field.label) || search(searchInput, field.key)) && // filter by search
            searchDataRoleSet.has(field.dataRole) && // filter by dataRole
            !field?.isForbidden?.(can),
        )
        .sort(compareByProp('label'))
        .map((field) => (
          <AddableItem
            key={field.key}
            field={field}
            onAdd={addColumn}
            open={openColumn === field.key}
            toggleOpen={handleToggleOpen}
          />
        ))}
    </Block>,
    <SavedViewEditDrawer
      key="saved-view-edit-drawer"
      view={editView}
      open={!!editView}
      onClose={() => setEditView(undefined)}
      onBack={() => setEditView(undefined)}
      containerId={containerId}
    />,
    <SavedViewSaveDrawer
      key="saved-view-save-drawer"
      open={saveView}
      onClose={() => setSaveView(false)}
      onBack={() => setSaveView(false)}
      containerId={containerId}
    />,
  ];
};

const SetUpDrawer = ({ open, onClose, containerId }) => (
  <Drawer
    width={470}
    open={open}
    getContainer={`#${containerId}`}
    style={{ position: 'absolute', zIndex: 50 }}
    bodyStyle={{ padding: 0 }}
    onClose={onClose}
    destroyOnClose
    title="Display"
    closable
    closeIcon={<WindowCloseIcon />}
    mask={false}
    push={false}
  >
    <SetUpContent onClose={onClose} containerId={containerId} />
  </Drawer>
);

export default SetUpDrawer;

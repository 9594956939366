import React from 'react';
import LordIcon from '../../../../assets/icons/LordIcon';
import style from './NotAuthorized.module.scss';

function NotAuthorized({ message }) {
  // eslint-disable-next-line no-console
  console.log(`Page Not Authorized`, message);
  return (
    <div className={style.root}>
      <div>
        <LordIcon
          type="amusment-park-bumper-car-outline-edited"
          height={250}
          isStopped={false}
          autoplay
          loop
          width={250}
        />
        <div>You are not authorized to view this page.</div>
      </div>
    </div>
  );
}

export default NotAuthorized;

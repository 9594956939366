import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Drawer, Form, Input, Modal, Radio, Select, Space, Switch, Table, Tag, Typography } from 'antd';
import ChallengePreferences from 'norbr-shared-lib/constants/strongCustomerAuthentication/challengePreferences/list';
import ExemptionReasons from 'norbr-shared-lib/constants/strongCustomerAuthentication/exemptionReasons/list';
import fallbackErrorCodeList from 'norbr-shared-lib/constants/merchantAccounts/fallbackSettings/codes/list';
import { ExclamationCircleOutlined, RollbackOutlined } from '@ant-design/icons';
import { useDebounce } from 'react-use';
import classnames from 'classnames';
import { useMerchantAccount } from '../../merchantAccount.context';
import styles from '../Optimizer.module.scss';
import { FallbackSettingsMutation, FallbackSettingsQuery } from '../query';
import { ResultType } from '../ScaRules/constants';
import { Block } from '../../../Common/Sider';
import { compareByProp } from '../../../../../util/array';
import { isEqual, mergeDeep } from '../../../../../util/object';
import { search } from '../../../../../util/string';
import Loader from '../../../Common/Loader/Loader';

export const challengePreferenceOptions = ChallengePreferences.map((o) => ({
  value: o.id,
  label: o.label,
  selectLabel: `Challenge - ${o.label}`,
  type: ResultType.CHALLENGE_PREFERENCE,
}));
export const exemptionReasonOptions = ExemptionReasons.map((o) => ({
  value: o.id,
  label: o.label,
  selectLabel: `Exemption reason - ${o.label}`,
  type: ResultType.SCA_EXEMPTION_REASON,
}));

const formDefaultValue = {
  authorization_failed: true,
  authorization_error_codes: fallbackErrorCodeList
    .filter((code) => code.editable)
    .reduce((memo, code) => ({ ...memo, [code.id]: code.default }), {}),
};

const CellActivateFallback = ({ id, default: defaultValue, editable, form }) => {
  if (editable) {
    Form.useWatch(['authorization_error_codes', id], form);
    return (
      <Form.Item noStyle name={['authorization_error_codes', id, 'active']} valuePropName="checked">
        <Switch checkedChildren="Yes" unCheckedChildren="No" disabled={!editable} />
      </Form.Item>
    );
  }
  return <Switch checkedChildren="Yes" unCheckedChildren="No" disabled checked={defaultValue.active} />;
};

const CellRetryOn = ({ id, default: defaultValue, editable, form }) => {
  if (editable) {
    const { active } = Form.useWatch(['authorization_error_codes', id], form) ?? {};
    return (
      <Form.Item name={['authorization_error_codes', id, 'retry_on_same_psp']} hidden={!active} noStyle>
        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          options={[
            { value: true, label: 'Same PSP' },
            { value: false, label: 'Other PSP' },
          ]}
          disabled={!editable}
        />
      </Form.Item>
    );
  }

  return defaultValue.active ? (
    <Typography.Text disabled>{defaultValue.retry_on_same_psp ? 'Same PSP' : 'Other PSP'}</Typography.Text>
  ) : null;
};

const CellAction = ({ id, default: defaultValue, editable, form }) => {
  if (editable) {
    const { active } = Form.useWatch(['authorization_error_codes', id], form) ?? {};
    return (
      <Form.Item
        name={['authorization_error_codes', id, 'result']}
        getValueFromEvent={(v, o) => ({ value: o.value, type: o.type })}
        hidden={!active}
        noStyle
      >
        <Select
          options={[
            { label: 'Challenge preferences', options: challengePreferenceOptions },
            { label: 'SCA exemption reasons', options: exemptionReasonOptions },
            {
              label: 'Inherit - previous transaction parameters',
              selectLabel: 'Inherit - previous transaction parameters',
              value: 'inherit',
              type: 'inherit',
            },
          ]}
          optionLabelProp="selectLabel"
          disabled={!editable}
          style={{ width: '100%' }}
        />
      </Form.Item>
    );
  }

  return defaultValue.active ? (
    <Typography.Text disabled>
      {
        [...challengePreferenceOptions, ...exemptionReasonOptions]?.find((o) => o.value === defaultValue.result.value)
          ?.selectLabel
      }
    </Typography.Text>
  ) : null;
};

const CellButton = ({ id, default: defaultValue, editable, form }) => {
  if (!editable) return <Typography.Text disabled>NOT EDITABLE</Typography.Text>;

  const currentValue = Form.useWatch(['authorization_error_codes', id], form);

  if (!currentValue) return null;

  if (isEqual(defaultValue, currentValue)) return <Typography.Text disabled>DEFAULT</Typography.Text>;

  const handleReset = () => {
    form.setFieldValue(['authorization_error_codes', id], defaultValue);
  };

  return (
    <Button
      type="text"
      icon={<RollbackOutlined />}
      shape="default"
      onClick={handleReset}
      title="Reset to default values"
      size="small"
    >
      Reset to default
    </Button>
  );
};

const DrawerForm = ({ open, onClose }) => {
  const { selectedMerchantAccount } = useMerchantAccount();

  const { data } = useQuery(FallbackSettingsQuery, { variables: { id: selectedMerchantAccount } });

  const [updateSettings, { loading }] = useMutation(FallbackSettingsMutation);

  const [form] = Form.useForm();
  Form.useWatch([], form);

  const [searchInput, setSearchInput] = useState();
  const [debouncedSearchInput, setDebouncedSearchInput] = useState();
  useDebounce(() => setDebouncedSearchInput(searchInput), 400, [searchInput]);

  const errorCodes = useMemo(
    () =>
      fallbackErrorCodeList
        .filter((code) => search(debouncedSearchInput, code.id) || search(debouncedSearchInput, code.label))
        .map((code) => ({ ...code, key: code.id }))
        .sort((a, b) => {
          // 1st - non editable + active
          // 2nd - editable
          // 3rd - non editable + non active
          // default code
          if (!a.editable && a.default.active && (b.editable || !b.default.active)) return -1;
          if (!b.editable && b.default.active && (a.editable || !a.default.active)) return 1;
          if (a.editable && !b.editable) return -1;
          if (b.editable && !a.editable) return 1;
          return 0;
        })
    ,
    [debouncedSearchInput],
  );

  const fallbackSettings = useMemo(
    () => ({
      authorization_failed: data?.merchantAccount.fallbackSettings.authorization_failed,
      authorization_error_codes: data?.merchantAccount.fallbackSettings.authorization_error_codes,
    }),
    [data],
  );

  const hasChanges = useMemo(
    () => !isEqual(form.getFieldsValue(), fallbackSettings),
    [form.getFieldsValue(), fallbackSettings],
  );

  if (!data) return <Loader />;

  const handleClose = () => {
    setSearchInput(null);
    if (hasChanges) {
      Modal.confirm({
        title: 'There are unsaved changes. Are you sure you want to leave this page ?',
        icon: <ExclamationCircleOutlined />,
        onOk: () => {
          form.resetFields();
          onClose();
        },
      });
    } else {
      onClose();
    }
  };

  const handleFinish = (values) => {
    updateSettings({
      variables: {
        id: selectedMerchantAccount,
        input: mergeDeep(formDefaultValue, values),
      },
    }).then((res) => {
      if (res.data) onClose();
    });
  };

  const handleResetAll = () => {
    Modal.confirm({
      title: 'Do you want to reset all to default ?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        form.setFieldsValue(formDefaultValue);
      },
    });
  };

  const fallbacksAreInactive = data?.merchantAccount.fallbackSettings.status === 'inactive';

  return (
    <Drawer
      id="drawer-form"
      title="Fallbacks based on authorization results"
      placement="right"
      closable
      onClose={handleClose}
      open={open}
      getContainer={false}
      style={{ position: 'absolute', marginTop: 1 }}
      bodyStyle={{ padding: 0 }}
      width={global.window.innerWidth - 240}
      extra={
        <Space>
          {hasChanges && [
            <Button key="cancel" type="text" htmlType="submit" onClick={() => form.resetFields()}>
              CANCEL
            </Button>,
            <Button key="submit" type="primary" htmlType="submit" loading={loading} onClick={form.submit}>
              SAVE
            </Button>,
          ]}
          <Button type="text" loading={loading} icon={<RollbackOutlined />} onClick={handleResetAll}>
            Reset all to default
          </Button>
        </Space>
      }
      destroyOnClose
    >
      <Form
        layout="vertical"
        className={styles.drawerForm}
        form={form}
        onFinish={handleFinish}
        initialValues={fallbackSettings}
      >
        <Block
          title="If authorization failed"
          description="During the authorization the transaction may fail. In this case, set the behaviour."
        >
          <Form.Item label="Activate fallback" name="authorization_failed" valuePropName="checked">
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              className={classnames({ [styles.warningSwitch]: fallbacksAreInactive })}
            />
          </Form.Item>
          {fallbacksAreInactive && form.getFieldValue('authorization_failed') && (
            <Typography.Text italic className={styles.warningText}>
              <ExclamationCircleOutlined /> Fallback service is inactive in General Settings.
            </Typography.Text>
          )}
        </Block>
        <Block title="If authorization is declined" description="Associate error codes with fallback rules.">
          <Table
            title={() => (
              <Input.Search
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder="Search by error code or description"
                style={{ width: 400 }}
                allowClear
              />
            )}
            columns={[
              {
                key: 'id',
                title: 'Code',
                dataIndex: 'id',
                width: 80,
                height: 57,
                fixed: 'left',
                render: (id) => <Tag color="#CCCCCC">{id}</Tag>,
                sorter: compareByProp('id'),
              },
              {
                key: 'label',
                title: 'Error',
                dataIndex: 'label',
                width: 200,
                render: (label) => (
                  <Typography.Text style={{ textTransform: 'capitalize' }} ellipsis title={label}>
                    {label}
                  </Typography.Text>
                ),
                sorter: compareByProp('label'),
              },
              {
                key: 'activateFallback',
                title: 'Activate fallback',
                width: 150,
                render: (_, row) => <CellActivateFallback key={row.id} {...row} form={form} />,
                sorter: (a, b) => {
                  const aValue = form.getFieldValue(['authorization_error_codes', a.id, 'active']) ?? a.default.active;
                  const bValue = form.getFieldValue(['authorization_error_codes', b.id, 'active']) ?? b.default.active;
                  if (aValue === bValue) return 0;
                  return aValue < bValue ? 1 : -1;
                },
              },
              {
                key: 'retryOn',
                title: 'Retry on',
                width: 228,
                render: (_, row) => <CellRetryOn key={row.id} {...row} form={form} />,
              },
              {
                key: 'action',
                title: 'Action',
                width: 240,
                render: (_, row) => <CellAction key={row.id} {...row} form={form} />,
              },
              {
                key: 'button',
                align: 'center',
                width: 180,
                render: (_, row) => <CellButton key={row.id} {...row} form={form} />,
                sorter: compareByProp('editable'),
                // defaultSortOrder: 'descend',
                // sortDirections: ['descend'],
              },
            ]}
            dataSource={errorCodes}
            loading={!data}
            size="middle"
            pagination={false}
            scroll={{ x: 1080 }}
            rowClassName={styles.tableRow}
            sticky
          />
        </Block>
      </Form>
    </Drawer>
  );
};

export default DrawerForm;

import React, { useState } from 'react';
import { Avatar, Button, Select, Space, Typography } from 'antd';
import { DeploymentUnitOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { searchByProp } from '../../../../../util/string';
import { PartnersQuery } from '../../query';
import { partnerTypes } from '../../Partners/constants';

const PartnerSelection = (props) => {
  const { onSubmit } = props;

  const partnersQuery = useQuery(PartnersQuery);

  const [selectedPartner, selectPartner] = useState();
  const handleSubmit = () => onSubmit(selectedPartner);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Paragraph key="info">Select a target partner</Typography.Paragraph>
      <Select
        style={{ width: '100%' }}
        size="large"
        allowClear
        showSearch
        filterOption={searchByProp('label')}
        optionLabelProp="label"
        loading={partnersQuery.loading}
        onChange={selectPartner}
      >
        {partnersQuery.data?.partners.map((partner) => (
          <Select.Option key={partner.id} value={partner.id} label={partner.name}>
            <div style={{ display: 'inline-flex', gap: 8, width: '100%' }}>
              <Avatar
                src={partner.company?.theme?.icon}
                icon={<DeploymentUnitOutlined />}
                size="small"
                shape="square"
                style={{ backgroundColor: !partner.company?.theme?.icon && partnerTypes[partner.type].color }}
                title={partnerTypes[partner.type].label}
              />
              <span style={{ flex: 1 }}>{partner.name}</span>
              <i>{partnerTypes[partner.type].label}</i>
            </div>
          </Select.Option>
        ))}
      </Select>
      <Button type="primary" onClick={handleSubmit} disabled={!selectedPartner}>
        Next
      </Button>
    </Space>
  );
};

export default PartnerSelection;

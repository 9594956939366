import _ from 'norbr-shared-lib/constants/transaction/status/results/enum';
import StatusResultList from 'norbr-shared-lib/constants/transaction/status/results/list';
import { compareByProp } from '../util/array';
import { TAGS } from './TRANSACTION_STATUSES';

const statusResults = {
  [_.ACCEPTED]: { color: 'green', tag: TAGS.ok },
  [_.ATTEMPTED]: { color: 'grey', tag: TAGS.ok },
  [_.DECLINED]: { color: 'orange', tag: TAGS.ko },
  [_.EXPIRED]: { color: 'red', tag: TAGS.expired },
  [_.FAILURE]: { color: 'red', tag: TAGS.ko },
  [_.MANUAL_REVIEW]: { color: 'orange', tag: TAGS.pending },
  [_.NOT_AVAILABLE]: { color: 'orange', tag: TAGS.ko },
  [_.PENDING]: { color: 'orange', tag: TAGS.pending },
  [_.UNAVAILABLE]: { color: 'orange', tag: TAGS.ko },
  [_.SUCCESS]: { color: 'green', tag: TAGS.ok },
};

// + color : string
// + tag : TAG
const STATUS_RESULTS = StatusResultList.reduce(
  (memo, result) => ({
    ...memo,
    [result.id]: {
      value: result.id,
      label: result.label,
      color: statusResults[result.id].color,
      tag: statusResults[result.id].tag,
    },
  }),
  {},
);

export const STATUS_RESULT_LIST = Object.values(STATUS_RESULTS).sort(compareByProp('label'));

export default STATUS_RESULTS;

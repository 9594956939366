import React from 'react';
import { Alert, Tag, Typography } from 'antd';
import useSearchFilters from '../../hooks/useSearchFilters';
import fields from '../constants/fields';
import FieldTypes from '../constants/fieldTypes';

const AbsolutePeriodWarning = () => {
  const { list } = useSearchFilters();

  const staticDateFilter = list.find((f) => fields[f.key].type === FieldTypes.DATE && !f.value.period);

  if (staticDateFilter) {
    return (
      <Alert
        message={
          <Typography.Text>
            <Tag color="warning">{fields[staticDateFilter.key].label}</Tag>
            {`filter uses an absolute period
            (from ${staticDateFilter.value.from} to ${staticDateFilter.value.to}).
            Once this report is saved, you will receive results for this period only.
            To increase the relevance of your report, you are invited to choose
            a relative period (e.g. last 24 hours).`}
          </Typography.Text>
        }
        type="warning"
        showIcon
      />
    );
  }

  return null;
};

export default AbsolutePeriodWarning;

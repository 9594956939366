import React, { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Divider, Form, Input, InputNumber, Segmented, Select, Space, TreeSelect } from 'antd';
import { EditOutlined, PlusOutlined, SwapRightOutlined, UnorderedListOutlined } from '@ant-design/icons';
import TrashCanIcon from '@2fd/ant-design-icons/lib/TrashCan';
import referenceCurrencies from 'norbr-shared-lib/constants/referenceCurrencies';
import fields from 'norbr-shared-lib/constants/merchantAccounts/fallbackSettings/scaRuleConditionFields';
import fieldList from 'norbr-shared-lib/constants/merchantAccounts/fallbackSettings/scaRuleConditionFields/list';
import FieldTypes from 'norbr-shared-lib/constants/merchantAccounts/fallbackSettings/scaRuleConditionFields/types';
import { TREE_COUNTRY_LIST } from '../../../../../constants/COUNTRIES';
import { useCompany } from '../../../../../contexts/app.context';
import { omitTypename } from '../../../../../util/object';
import Loader from '../../../Common/Loader/Loader';
import SiderContainer from '../../../Common/Sider/SiderContainer/SiderContainer';
import { Block, BlockContent } from '../../../Common/Sider';
import { companyListsQuery } from '../../Lists/query';
import { useMerchantAccount } from '../../merchantAccount.context';
import styles from '../Optimizer.module.scss';
import { FallbackSettingsMutation, FallbackSettingsQuery } from '../query';
import { challengePreferenceOptions, exemptionReasonOptions } from './constants';

const fieldOptions = fieldList.map((f) => ({ value: f.id, label: f.label }));

const SiderForm = ({ ruleId, onClose }) => {
  const [company] = useCompany();

  const [form] = Form.useForm();

  const { selectedMerchantAccount } = useMerchantAccount();

  const { data } = useQuery(FallbackSettingsQuery, { variables: { id: selectedMerchantAccount } });

  const listsQuery = useQuery(companyListsQuery, { variables: { id: company } });

  const listMenu = useMemo(
    () =>
      listsQuery.data?.merchantCompany.lists.map((list) => ({
        key: list.id,
        value: list.id,
        label: list.name,
        type: list.type,
      })) ?? [],
    [listsQuery],
  );

  const [updateSettings, { loading }] = useMutation(FallbackSettingsMutation);

  const rules = useMemo(() => omitTypename(data?.merchantAccount.fallbackSettings.rules) || [], [data]);

  const rule = useMemo(() => (ruleId === -1 ? undefined : rules[ruleId]), [rules]);

  const handleFinish = (values) => {
    const newRules = [...rules];
    if (ruleId === -1) newRules.push(values);
    else newRules.splice(ruleId, 1, values);

    updateSettings({
      variables: {
        id: selectedMerchantAccount,
        input: {
          rules: newRules,
        },
      },
    }).then((res) => {
      if (res.data) onClose();
    });
  };

  const handleRemove = () => {
    const newRules = [...rules];
    newRules.splice(ruleId, 1);
    updateSettings({
      variables: {
        id: selectedMerchantAccount,
        input: {
          rules: newRules,
        },
      },
    }).then((res) => {
      if (res.data) onClose();
    });
  };

  if (!data) return <Loader />;

  const currency = referenceCurrencies[data.merchantAccount.reference_currency ?? 'EUR'];

  const renderConditionFields = (fieldName) => (
    <>
      <Form.Item name={[fieldName, 'field']} rules={[{ required: true, message: 'required' }]}>
        <Select options={fieldOptions} onChange={() => form.setFieldValue([fieldName, 'value'], undefined)} />
      </Form.Item>
      <Form.Item name={[fieldName, 'excluded']}>
        <Segmented
          options={[
            { value: false, label: 'IN' },
            { value: true, label: 'NOT IN' },
          ]}
        />
      </Form.Item>
      <Form.Item shouldUpdate={(prev, curr) => prev[fieldName] !== curr[fieldName]}>
        {() => {
          const field = fields[form.getFieldValue([fieldName, 'field'])];
          switch (field?.type) {
            case FieldTypes.AMOUNT_RANGE:
              return (
                <Form.Item>
                  <Space>
                    <Form.Item
                      name={[fieldName, 'value', 'min']}
                      style={{ margin: 0 }}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator() {
                            const min = getFieldValue([fieldName, 'value', 'min']);
                            const max = getFieldValue([fieldName, 'value', 'max']);
                            if (min == null && max == null) {
                              return Promise.reject(new Error('At least one input is required'));
                            }
                            if (min && max && min > max) {
                              return Promise.reject(new Error('Should min < max'));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <InputNumber placeholder="Min" style={{ width: '100%' }} min={0} addonAfter={currency.symbol} />
                    </Form.Item>
                    <SwapRightOutlined style={{ fontSize: 16, color: 'lightgrey' }} />
                    <Form.Item name={[fieldName, 'value', 'max']} style={{ margin: 0 }}>
                      <InputNumber placeholder="Max" style={{ width: '100%' }} min={0} addonAfter={currency.symbol} />
                    </Form.Item>
                  </Space>
                </Form.Item>
              );
            case FieldTypes.MULTI_SELECT:
              switch (field.references) {
                case 'countries':
                  return (
                    <Form.Item name={[fieldName, 'value']} rules={[{ required: true, message: 'required' }]} required>
                      <TreeSelect
                        multiple
                        maxTagCount={10}
                        allowClear
                        showSearch
                        treeData={TREE_COUNTRY_LIST}
                        treeCheckable
                        showCheckedStrategy={TreeSelect.SHOW_CHILD}
                        treeNodeFilterProp="label"
                      />
                    </Form.Item>
                  );

                default:
                  // no references -> field options
                  return (
                    <Form.Item name={[fieldName, 'value']} rules={[{ required: true, message: 'required' }]} required>
                      <Select
                        optionFilterProp="label"
                        mode="multiple"
                        maxTagCount="responsive"
                        allowClear
                        showSearch
                        options={field.options}
                        fieldNames={{ value: 'id' }}
                      />
                    </Form.Item>
                  );
              }
            case FieldTypes.MULTI_INPUT:
              // eslint-disable-next-line no-case-declarations
              const displayList = form.getFieldValue([fieldName, 'list']);
              return (
                <div style={{ display: 'flex', gap: 8 }}>
                  <Form.Item name={[fieldName, 'list']} noStyle hidden={!field.listType}>
                    <Segmented
                      options={[
                        { value: false, label: <EditOutlined />, title: 'Manual input' },
                        { value: true, label: <UnorderedListOutlined />, title: 'Registered list' },
                      ]}
                      onChange={() => form.setFieldValue([fieldName, 'value'], undefined)}
                    />
                  </Form.Item>

                  <Form.Item
                    noStyle
                    name={[fieldName, 'value']}
                    rules={[{ required: true, message: 'required' }]}
                    required
                  >
                    {displayList ? (
                      <Select
                        key={`${fieldName}-select-list`}
                        loading={listsQuery.loading}
                        options={listMenu.filter((l) => l.type === field.listType)}
                        optionFilterProp="label"
                        placeholder="Select list"
                        showSearch
                      />
                    ) : (
                      <Select
                        key={`${fieldName}-select-input`}
                        mode="tags"
                        optionFilterProp="label"
                        maxTagCount="responsive"
                        placeholder="Type values"
                        allowClear
                        showSearch
                      />
                    )}
                  </Form.Item>
                </div>
              );
            default:
              return (
                <Form.Item name={[fieldName, 'value']}>
                  <Input disabled />
                </Form.Item>
              );
          }
        }}
      </Form.Item>
    </>
  );

  return (
    <SiderContainer title={rule?.name || 'New SCA rule'} id="sca-rules">
      <Form form={form} layout="vertical" className={styles.drawerForm} onFinish={handleFinish} initialValues={rule}>
        <Form.Item hidden name="_id" />
        <Block title="Rule information" description="Name and description" onBackClick={onClose}>
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input autoFocus />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
        </Block>
        <Block title="Condition 1" description="Set up the first condition">
          {renderConditionFields('condition_1')}
        </Block>
        <Form.Item
          shouldUpdate={(prevValues, nextValues) =>
            (!prevValues.condition_2 && !!nextValues.condition_2) ||
            (!!prevValues.condition_2 && !nextValues.condition_2)
          }
        >
          {() =>
            form.getFieldValue('condition_2') ? (
              <Block title="Condition 2" description="Set up the second condition">
                <Button
                  key="remove-condition_2"
                  className={styles.removeConditionBtn}
                  type="text"
                  shape="circle"
                  icon={<TrashCanIcon />}
                  onClick={() => form.setFieldValue('condition_2', null)}
                />
                <Form.Item name="logical_operator">
                  <Segmented
                    options={[
                      { value: 'and', label: 'AND' },
                      { value: 'or', label: 'OR' },
                    ]}
                  />
                </Form.Item>
                {renderConditionFields('condition_2')}
              </Block>
            ) : (
              <BlockContent>
                <Button
                  key="add-condition"
                  type="dashed"
                  icon={<PlusOutlined />}
                  block
                  onClick={() => form.setFieldValue('condition_2', {})}
                >
                  Add condition
                </Button>
              </BlockContent>
            )
          }
        </Form.Item>

        <Block title="Define action to be performed" description="What should happen if the condition above are met ?">
          <Form.Item
            label="Action to perform"
            name="result"
            getValueFromEvent={(v, o) => ({ value: o.value, type: o.type })}
            rules={[{ required: true }]}
          >
            <Select
              options={[
                { label: 'Challenge preferences', options: challengePreferenceOptions },
                { label: 'SCA exemption reasons', options: exemptionReasonOptions },
              ]}
              optionLabelProp="selectLabel"
            />
          </Form.Item>
          <Divider />
          <div className={styles.bottomButtons}>
            <Button type="text" onClick={onClose}>
              CANCEL
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              SAVE
            </Button>
          </div>
          {ruleId !== -1 && (
            <Button block icon={<TrashCanIcon />} onClick={handleRemove}>
              DELETE RULE
            </Button>
          )}
        </Block>
      </Form>
    </SiderContainer>
  );
};

export default SiderForm;

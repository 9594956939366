/* eslint-disable @typescript-eslint/no-use-before-define */
import { gql } from '@apollo/client';

export const splitterFragment = () => {
  const addBranchFragment = (i) => {
    if (i > 0) {
      return `
        id
        color
        condition
        contract {
          id
          name
          status
          partner {
            id
            name
            company {
              theme {
                icon
              }
            }
          }
        }
        next {
          ${addSplitterFragment(i - 1)}
        }
        blocking
      `;
    }
    return `
      id
      color
      condition
      contract {
        id
        name
        status
        partner {
          id
          name
          company {
            theme {
              icon
            }
          }
        }
      }
    `;
  };

  const addSplitterFragment = (i) => {
    if (i > 0) {
      return `
        id
        color
        type
        other {
          ${addSplitterFragment(i - 1)}
        }
        branches {
          ${addBranchFragment(i)}
        }
        contract {
          id
          name
          status
          partner {
            id
            name
            company {
              theme {
                icon
              }
            }
          }
        }
        blocking
        merchant_data_key
        merchant_data_type
      `;
    }
    return `
      id
      color
      type
      branches {
        ${addBranchFragment(i)}
      }
      contract {
        id
        name
        status
        partner {
          id
          name
          company {
            theme {
              icon
            }
          }
        }
      }
    `;
  };

  return `
    fragment SplitterRecursive on Splitter {
      ${addSplitterFragment(7)}
    }
  `;
};

export const ruleQuery = gql`
  ${splitterFragment()}
  query Rule($id: ID!) {
    rule(id: $id) {
      id
      name
      status
      merchant_account {
        id
        reference_currency
        name
        company {
          theme {
            icon
          }
        }
      }
      fullEntrySplitter
      draft_splitter {
        ...SplitterRecursive
      }
      previous
      v
    }
  }
`;

export const merchantAccountContractsQuery = gql`
  query MerchantAccountContracts($id: ID!) {
    merchantAccount(id: $id) {
      id
      reference_currency
      merchant_contracts {
        id
        type
        status
        name
        payment_methods {
          id
        }
        authorization_currencies
        partner {
          id
          name
          company {
            theme {
              icon
            }
          }
          is_3ds_available
        }
        merchant_routes {
          id
          partner_route {
            id
            authorization_currency
            payment_method {
              api_name
              type
            }
            is_3ds_available
            channel
          }
        }
      }
    }
  }
`;

export const createDraftMutation = gql`
  ${splitterFragment()}
  mutation CreateDraft($id: ID!) {
    createDraft(id: $id) {
      id
      name
      status
      fullEntrySplitter
      draft_splitter {
        ...SplitterRecursive
      }
      merchant_account {
        id
      }
    }
  }
`;

export const updateRuleMutation = gql`
  ${splitterFragment()}
  mutation UpdateRule($id: ID!, $input: RuleInput!) {
    updateRule(id: $id, input: $input) {
      id
      name
      draft_splitter {
        ...SplitterRecursive
      }
      merchant_account {
        id
      }
    }
  }
`;

export const saveRuleMutation = gql`
  ${splitterFragment()}
  mutation SaveRule($id: ID!, $name: String!) {
    saveRule(id: $id, name: $name) {
      id
      name
      draft_splitter {
        ...SplitterRecursive
      }
      fullEntrySplitter
      merchant_account {
        id
      }
      previous
      v
    }
  }
`;

import React from 'react';
import { Space, Table, Tag } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { compareByProp } from '../../../../../util/array';
import ProgramManagerAvatar from '../../../Common/ProgramManager/Avatar';
import { snakeToCapitalize } from '../../../../../util/string';
import { merchantAclRolesQuery } from './query';
import useNavigateWithSearch from "../../../../../util/navigate";

// 'payment_networks_admin' -> ['Payment Networks', 'admin']
export const parseRole = (role) => {
  const matches = role.match(/^([\w_]+)_(admin|editor|viewer)$/);
  if (!matches) return null;
  return [snakeToCapitalize(matches[1]), matches[2]];
};

const ProfilesOverviewTable = ({ loading, aclProfiles }) => {
  const navigate = useNavigateWithSearch();

  const dataSource =
    aclProfiles
      ?.map((profile) => ({
        key: profile.id,
        name: profile.name,
        programManager: profile.programManager,
        programManagerName: profile.programManager?.name ?? '',
        // dedupe role by module and keep first value because roles are sorted by name (admin > editor > viewer)
        ...profile.roles?.reduce((memo, role) => {
          const res = parseRole(role.id);
          if (!res) return { ...memo, [role.id]: true };
          return { ...memo, [res[0]]: memo[res[0]] || res[1] };
        }, {}),
      }))
      .sort(compareByProp('name'))
      .sort(compareByProp('programManagerName')) || [];

  const {
    data: { merchantAclRoles } = {
      merchantAclRoles: [],
    },
  } = useQuery(merchantAclRolesQuery);

  const others = [];
  const modules = [];
  merchantAclRoles?.forEach((role) => {
    const res = parseRole(role.id);
    if (!res && !others.includes(role.id)) {
      others.push(role.id);
    } else if (res && !modules.includes(res[0])) {
      modules.push(res[0]);
    }
  });

  const tagColors = { admin: '#595959', editor: '#8c8c8c', viewer: '#bfbfbf' };
  const renderTag = (value) => <Tag color={tagColors[value]}>{value}</Tag>;
  const renderCheck = (value) => value && <CheckOutlined style={{ fontSize: 16 }} />;

  const columns = [
    {
      key: 'name',
      title: 'Profiles',
      dataIndex: 'name',
      fixed: 'left',
      sorter: compareByProp('name'),
      render: (value, { programManager }) => (
        <Space style={{ width: 200 }}>
          <ProgramManagerAvatar programManager={programManager} />
          {value}
        </Space>
      ),
    },
    {
      key: 'modules',
      title: 'Modules',
      children: modules.map((role) => ({
        key: role,
        title: role,
        dataIndex: role,
        render: renderTag,
        align: 'center',
      })),
    },
    {
      key: 'others',
      title: 'Others',
      children: others.map((role) => ({
        key: role,
        title: snakeToCapitalize(role),
        dataIndex: role,
        render: renderCheck,
        align: 'center',
      })),
      align: 'center',
    },
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      bordered
      onRow={(profile) => ({
        // redirect to profile view
        onClick: () => navigate(`/access-control-list/${profile.key}`),
      })}
      scroll={{
        x: true,
        scrollToFirstRowOnChange: true,
      }}
      pagination={false}
    />
  );
};

export default ProfilesOverviewTable;

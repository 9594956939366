import { useQuery } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  Layout,
  Pagination,
  Row,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import ReactHeight from 'react-height';
import FeatureSearchIcon from '@2fd/ant-design-icons/lib/FeatureSearch';
import classnames from 'classnames';
import DomainIcon from '@2fd/ant-design-icons/lib/Domain';
import useColumnSet from '../../../../Brainpower/hooks/useColumnSet';
import useSort from '../../../../Brainpower/hooks/useSort';
import useTableDisplay from '../../../../Brainpower/hooks/useTableDisplay';
import { useGdpr } from '../../../../../../contexts/me.context';
import { useCan } from '../../../../../../contexts/ability.context';
import { TableDisplay, tableDisplayList } from '../../../../Brainpower/Common/constants/tableDisplays';
import useNavigateWithSearch from '../../../../../../util/navigate';
import useDrawer from '../../../../Brainpower/Common/useDrawer';
import usePagination from '../../../../Brainpower/hooks/usePagination';
import useSearchFilters from '../../../../Brainpower/hooks/useSearchFilters';
import fields, { Fields } from '../../fields';
import styles from '../CompanyList.module.scss';
import SearchFilterTags from '../../../../Brainpower/Common/SearchFilterTags/SearchFilterTags';
import SearchInput from '../../../../Common/ListFilter/SearchInput';
import Drawers from '../../../../Brainpower/Common/constants/drawers';
import CompanyCreate from '../../CompanyCreate/CompanyCreate';
import { buildColumns, filterFnBuilder, mapFnBuilder, quickSearchFilterFnBuilder, sortFnBuilder } from '../../../utils';
import { CompanyListQuery } from '../../query';
import { useProgramManager } from '../../../../../../contexts/programManager.context';
import { useMode } from '../../../../../../contexts/mode.context';

const CompanyTable = () => {
  const can = useCan();
  const programManager = useProgramManager();
  const mode = useMode();

  const navigate = useNavigateWithSearch();

  const { data, loading, error } = useQuery(CompanyListQuery, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  });

  const [, setDrawer] = useDrawer();

  // Create modal
  const [visible, setVisible] = useState(false);
  const showCreationModal = () => setVisible(true);
  const hideCreationModal = () => setVisible(false);

  const [height, setHeight] = useState();
  const gdpr = useGdpr();

  const [columnSet] = useColumnSet();
  const [tableDisplay, setTableDisplay] = useTableDisplay();
  const { page, pageSize, setPage, offset, limit } = usePagination();
  const [sort, setSort] = useSort();
  const { list } = useSearchFilters();
  const [quickSearch, setQuickSearch] = useState();

  const mapFn = useMemo(() => mapFnBuilder(fields(mode)), [fields]);
  const filterFn = useMemo(() => filterFnBuilder(list, fields(mode)), [list, fields]);
  const quicksearchFilterFn = useMemo(
    () =>
      quickSearchFilterFnBuilder(quickSearch, [
        Fields.PROGRAM_MANAGER,
        Fields.PROGRAM_MANAGER_NAME,
        Fields.COMPANY,
        Fields.COMPANY_NAME,
      ]),
    [quickSearch],
  );
  const sortFn = useMemo(() => sortFnBuilder(sort, fields(mode)), [sort, fields]);

  // Map & Filter & Sort
  const dataSource = data?.merchantCompanies.map(mapFn).filter(filterFn).filter(quicksearchFilterFn).sort(sortFn);

  // Build table columns
  const columns = buildColumns(columnSet, fields(mode), sort, can, gdpr, tableDisplay);

  const handleTableChange = useCallback(
    (_p, _f, sorter) => {
      // handle single sort, default from favorite view
      if (Array.isArray(sorter)) {
        setSort([{ field: sorter[1].field, sortOrder: sorter[1].order }]);
      } else if (sorter.order) {
        setSort([{ field: sorter.field, sortOrder: sorter.order }]);
      } else {
        setSort();
      }
    },
    [setSort],
  );

  const handleChangeQuickSearch = useCallback(
    (e) => {
      setPage(1);
      setQuickSearch(e.target.value);
    },
    [setPage, setQuickSearch],
  );

  return (
    <Layout>
      <Layout.Header className={styles.layoutHeader}>
        <Row gutter={24} style={{ position: 'relative' }} align="middle">
          <Col>
            <Space>
              Results
              <Tag style={{ color: 'darkgrey' }}>{dataSource?.length ?? '...'}</Tag>
            </Space>
          </Col>
          <Col flex="1" style={{ position: 'initial' }}>
            <SearchFilterTags
              prefixComponent={<SearchInput onChange={handleChangeQuickSearch} placeholder="Search by ID, name..." />}
            />
          </Col>
          {can('create', 'company') && (
            <Col>
              <Button type="primary" onClick={showCreationModal} icon={<DomainIcon />} title="Create company">
                CREATE
              </Button>
            </Col>
          )}
        </Row>
      </Layout.Header>
      <Layout id="company-table" className={styles.layoutContent}>
        <Layout.Content>
          <ReactHeight onHeightReady={setHeight} style={{ height: '100%' }}>
            {height ? (
              <ConfigProvider
                renderEmpty={() => (
                  <Empty
                    className={styles.empty}
                    style={{ height: height - 74 }}
                    image={loading ? null : undefined}
                    description={(() => {
                      if (loading) {
                        return null;
                      }
                      if (error) {
                        return (
                          <Typography.Text type="danger">An error occurred, please contact support.</Typography.Text>
                        );
                      }
                      return [
                        <div key="no-results-found">No results found</div>,
                        <Button
                          key="adjust-filters"
                          type="link"
                          icon={<FeatureSearchIcon style={{ fontSize: 18 }} />}
                          onClick={() => setDrawer(Drawers.SEARCH)}
                        >
                          Adjust filters
                        </Button>,
                      ];
                    })()}
                  />
                )}
              >
                <Table
                  className={classnames(styles.table, {
                    [styles.richDisplay]: tableDisplay === TableDisplay.RICH,
                  })}
                  rowClassName={styles.tableRow}
                  columns={columns}
                  dataSource={dataSource?.slice(offset, offset + limit)}
                  loading={loading && { size: 'large' }}
                  size="small"
                  scroll={{ x: 'max-content', y: height - 40 }} // available height - head row height
                  pagination={false}
                  onRow={(record) => ({
                    onClick: () => navigate(record[Fields.COMPANY]), // click row,
                  })}
                  onChange={handleTableChange}
                />
              </ConfigProvider>
            ) : (
              <div />
            )}
          </ReactHeight>
        </Layout.Content>
        <Layout.Footer className={styles.layoutFooter}>
          <Row gutter={24} justify="space-between">
            <Col>
              <Space>
                <Pagination
                  className={styles.pagination}
                  total={dataSource?.length}
                  loading={loading}
                  current={page}
                  pageSize={pageSize}
                  onChange={setPage}
                  showSizeChanger={false}
                  showLessItems
                  size="small"
                  hideOnSinglePage
                />
                <Select
                  value={pageSize}
                  onSelect={(value) => setPage(1, value)}
                  options={[{ value: 10 }, { value: 20 }, { value: 50 }]}
                  bordered={false}
                />
              </Space>
            </Col>
            <Col>
              <Segmented
                value={tableDisplay}
                onChange={setTableDisplay}
                options={tableDisplayList}
                style={{ fontSize: 18 }}
              />
            </Col>
          </Row>
        </Layout.Footer>
      </Layout>
      <CompanyCreate visible={visible} onCancel={hideCreationModal} programManager={programManager?.id} />
    </Layout>
  );
};

export default CompanyTable;

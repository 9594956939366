import React from 'react';
import InfoTile, { InfoSkeletonRow, ResponsiveBodyTile } from '../../../OrderDetail/Tile/Info/Info';

const WebhookSkeleton = () => (
  <InfoTile name="webhook" title="Webhook">
    <ResponsiveBodyTile>
      <InfoSkeletonRow label="Webhook ID" />
      <InfoSkeletonRow label="Creation" />
      <InfoSkeletonRow label="Name" />
      <InfoSkeletonRow label="URL" />
    </ResponsiveBodyTile>
    <InfoSkeletonRow label="Triggers" />
  </InfoTile>
);

export default WebhookSkeleton;

import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Progress, Row, Space, Table } from 'antd';
import RECONCILIATION_TYPE_ENUM from 'norbr-shared-lib/constants/transaction/reconciliationTypes/enum';
import LinkVariantPlusIcon from '@2fd/ant-design-icons/lib/LinkVariantPlus';
import OPERATION_TYPE_ENUM from 'norbr-shared-lib/constants/reconciliation/operationTypes/enum';
import classnames from 'classnames';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import { useQuery } from '@apollo/client';
import { useBoolean, useList } from 'react-use';
import styles from './MatcherDrawer.module.scss';
import { useCan } from '../../../../../contexts/ability.context';
import TargetEntities, { TargetEntity } from '../../Common/constants/targetEntities';
import useStatePagination from '../../hooks/useStatePagination';
import { reconciliationCandidatesQuery } from './query';
import { buildColumnProps } from '../../Common/AdvancedTable/buildColumns';
import fields from '../../Common/constants/fields';
import DateFormats from '../../../../../constants/dateFormats';
import { amountFormatter } from '../../../../../util/formatter';
import ReconciliationModal from './Modals/ReconciliationModal';
import MatchLaterModal from './Modals/MatchLaterModal';
import WithdrawModal from './Modals/WithdrawModal';

const PAGE_SIZE = 5;

const NotificationCandidateTable = ({ operationId, reconciliationCandidate, onSelectReconciliationCandidate }) => {
  const [dataSource, { push, reset }] = useList([]);
  const [noMoreCandidates, setNoMoreCandidates] = useBoolean(false);

  const { nextPage, offset, limit } = useStatePagination(PAGE_SIZE);

  const { loading } = useQuery(reconciliationCandidatesQuery, {
    variables: { operationId, offset, limit },
    skip: !operationId,
    onCompleted: (data) => {
      push(
        ...data?.reconciliationCandidates.map((row) => ({
          [Fields.PSP_OPERATION_ID]: row.psp_operation_id,
          [Fields.OPERATION_CREATION_DATE]: row.action_date,
          [Fields.PAYMENT_METHOD]: row.paymentMethod.api_name,
          [Fields.OPERATION_AMOUNT]: row.amount,
          [Fields.OPERATION_TYPE]: row.operation_type,
          [Fields.PAYIN_PARTNER]: row.partner.api_name,
          [Fields.CURRENCY]: row.currency,
          score: (row.score * 20).toFixed(0),
        })),
      );
      if (!(data?.reconciliationCandidates?.length === PAGE_SIZE)) {
        setNoMoreCandidates(true);
      }
    },
  });

  useEffect(() => {
    reset();
    setNoMoreCandidates(false);
  }, [operationId]);

  if (!loading && dataSource.length === 0)
    return (
      <div
        style={{
          fontSize: '12px',
          color: '#868686',
          textAlign: 'center',
          marginTop: 16,
        }}
      >
        No candidate notifications found for this transaction.
      </div>
    );

  return (
    <Table
      className={classnames(styles.table)}
      rowClassName={(row) =>
        classnames(styles.row, styles.candidateRow, {
          [styles.selectedRow]: row[Fields.PSP_OPERATION_ID] === reconciliationCandidate,
        })
      }
      size="small"
      showHeader={false}
      columns={[
        {
          width: 44,
          render: () => <LinkVariantPlusIcon />,
        },
        {
          dataIndex: Fields.PSP_OPERATION_ID,
          title: 'ID PARTNER',
          width: 150,
          ...buildColumnProps(fields[Fields.PSP_OPERATION_ID]),
        },
        {
          dataIndex: Fields.PAYMENT_METHOD,
          title: 'PM',
          width: 56,
          ...buildColumnProps(fields[Fields.PAYMENT_METHOD]),
        },
        {
          dataIndex: Fields.OPERATION_CREATION_DATE,
          title: 'CREATION',
          width: 180,
          ...buildColumnProps(fields[Fields.OPERATION_CREATION_DATE], { format: DateFormats.EN_DATETIME }),
        },
        {
          dataIndex: Fields.OPERATION_AMOUNT,
          title: 'AMOUNT',
          width: 150,
          ...buildColumnProps(fields[Fields.OPERATION_AMOUNT]),
          render: (value = null, row) => {
            if (value === null) return null;
            return row[Fields.OPERATION_TYPE] === OPERATION_TYPE_ENUM.REFUND ||
              row[Fields.OPERATION_TYPE] === OPERATION_TYPE_ENUM.CREDIT ? (
              <span style={{ color: '#ff4f4e' }}>{amountFormatter(-value, row[Fields.CURRENCY])}</span>
            ) : (
              <span style={{ color: '#00cc7e' }}>{amountFormatter(value, row[Fields.CURRENCY])}</span>
            );
          },
        },
        {
          dataIndex: Fields.PAYIN_PARTNER,
          title: 'PARTNER',
          // width: 150,
          ...buildColumnProps({
            ...fields[Fields.PAYIN_PARTNER],
            displayOptions: {
              avatar: true,
              avatarShape: 'square',
              hideLabel: false,
            },
          }),
        },
        {
          dataIndex: 'score',
          title: '%',
          align: 'center',
          fixed: 'right',
          width: 44,
          render: (value) => (
            <Progress
              type="circle"
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#87d068',
              }}
              percent={value}
              width={28}
            />
          ),
        },
      ]}
      scroll={{ x: 'auto' }}
      loading={loading}
      dataSource={dataSource}
      pagination={false}
      rowKey={Fields.PSP_OPERATION_ID}
      onRow={(row) => ({
        onClick: () => onSelectReconciliationCandidate(row[Fields.PSP_OPERATION_ID]),
      })}
      footer={() => (
        <div style={{ textAlign: 'center' }}>
          {dataSource.length > 0 && noMoreCandidates && <span>All candidate notifications are displayed.</span>}
          {dataSource.length > 0 && !noMoreCandidates && (
            <Button className={styles.button} onClick={nextPage}>
              SEE MORE
            </Button>
          )}
        </div>
      )}
    />
  );
};

const MatcherNotificationCandidates = ({ operation }) => {
  const can = useCan();

  const [reconciliationCandidate, setReconciliationCandidate] = useState();
  const [matchLater, setMatchLater] = useState();
  const [withdraw, setWithdraw] = useState();

  if (!operation || operation.is_reconciled) return null;

  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: '#f5f4f4',
        margin: '0 8px',
        borderRadius: 8,
        overflow: 'hidden',
      }}
    >
      <Row justify="space-between" style={{ padding: '8px 12px' }}>
        <Col>
          <Space style={{ fontSize: '14px' }}>
            {TargetEntities[TargetEntity.TRANSACTION].icon()}
            <span>Notification candidates</span>
          </Space>
        </Col>
      </Row>
      <Row
        justify="space-between"
        style={{
          backgroundColor: '#eaeaea',
          padding: '8px 12px',
          borderTop: '#cbcbcb 1px solid',
          display: 'block',
        }}
      >
        <NotificationCandidateTable
          key={`notification-candidates-${operation.id}`}
          operationId={operation.id}
          reconciliationCandidate={reconciliationCandidate}
          onSelectReconciliationCandidate={setReconciliationCandidate}
        />
        <Divider />
        <div style={{ textAlign: 'center', marginBottom: 16 }}>
          <Space>
            {can('create', 'matcher-withdraw') && (
              <Button className={classnames(styles.button, styles.action)} onClick={() => setWithdraw(true)}>
                WITHDRAW
              </Button>
            )}
            {operation.reconciliation_type !== RECONCILIATION_TYPE_ENUM.PENDING && (
              <Button className={classnames(styles.button, styles.action)} onClick={() => setMatchLater(true)}>
                MATCH LATER
              </Button>
            )}
          </Space>
        </div>
      </Row>

      <ReconciliationModal
        key={`reconciliation-modal-${reconciliationCandidate}`}
        operationId={operation.id}
        reconciliationCandidate={reconciliationCandidate}
        onClose={() => setReconciliationCandidate(undefined)}
      />
      <MatchLaterModal operationId={operation.id} isOpen={matchLater} onClose={() => setMatchLater(false)} />
      <WithdrawModal operationId={operation.id} isOpen={withdraw} onClose={() => setWithdraw(false)} />
    </div>
  );
};

export default MatcherNotificationCandidates;

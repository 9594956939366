import { gql } from '@apollo/client';

export const notificationStatusQuery = gql`
  query NotificationStatus($notificationId: ID!) {
    notification(id: $notificationId) {
      _id
      status
      url
      headers
      payload
      createdAt
      merchantAccount {
        secret_key
      }
    }
  }
`;

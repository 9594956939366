import React from 'react';
import moment from 'moment-timezone';
import { Avatar, Badge, Layout, Menu as AntMenu } from 'antd';
import LogoutVariantIcon from '@2fd/ant-design-icons/lib/LogoutVariant';
import FormatColorFillIcon from '@2fd/ant-design-icons/lib/FormatColorFill';
import { useLocation } from 'react-router-dom';
import { initials } from '../../util/string';
import { useLogout } from '../../contexts/app.context';
import { useMe } from '../../contexts/me.context';
import useToggle from '../../hooks/useToggle';
import { isModeAdmin, isModeMerchant } from '../../constants/mode';
import ThemeEditor from '../ThemeEditor/ThemeEditor';
import { useLayoutMenu } from '../layout.context';
import MerchantMenu from './MerchantMenu/MerchantMenu';
import Countdown from './Countdown';
import TopLogo from './TopLogo/TopLogo';
import styles from './Menu.module.scss';

if (isModeAdmin) {
  // eslint-disable-next-line no-var, vars-on-top, block-scoped-var
  var AdminMenu = require('./AdminMenu/AdminMenu').default;
}

const Menu = () => {
  const me = useMe();
  const logout = useLogout();
  const location = useLocation();
  const [isMenuCollapsed, toggleMenu] = useLayoutMenu();
  const [isThemeEditorOpened, { set: setThemeEditor, toggle: toggleThemeEditor }] = useToggle(false);

  const avatar = (
    <Avatar className={styles.avatar} src={me?.avatar}>
      {initials(me?.full_name)}
    </Avatar>
  );

  const [, module, page] = location.pathname.split('/');

  return (
    <Layout.Sider
      trigger={null}
      onCollapse={toggleMenu}
      collapsible
      collapsed={isMenuCollapsed}
      className={styles.root}
    >
      <Badge.Ribbon text={process.env.REACT_APP_ENVIRONMENT} className={styles.envBadge}>
        <TopLogo />
        {!isModeAdmin && (
          <div className={styles.companyName} title={me?.companies?.[0]?.company.name}>
            {me?.companies?.[0]?.company.name}
          </div>
        )}
        <AntMenu
          mode="inline"
          theme="dark"
          selectedKeys={[`/${module}/${page}`]}
          items={[
            {
              key: 'user',
              label: !isMenuCollapsed && `${me?.first_name} ${me?.last_name}`,
              icon: avatar,
              children: [
                ...(isModeMerchant && me.type === 'InternalUser'
                  ? [
                      {
                        key: 'guest_countdown',
                        label: (
                          <small>
                            Guest <Countdown tillDate={moment(me?.companies?.[0]?.createdAt).add(60, 'minutes')} />
                          </small>
                        ),
                      },
                    ]
                  : []),
                {
                  key: 'theme-editor',
                  label: 'Theme',
                  icon: <FormatColorFillIcon />,
                  onClick: toggleThemeEditor,
                },
                {
                  key: 'logout',
                  label: 'Logout',
                  icon: <LogoutVariantIcon />,
                  onClick: logout,
                },
              ],
            },
          ]}
        />
        {/* eslint-disable-next-line block-scoped-var */}
        {isModeAdmin ? <AdminMenu /> : <MerchantMenu />}
        <ThemeEditor isOpen={isThemeEditorOpened} handleClose={() => setThemeEditor(false)} />
      </Badge.Ribbon>
    </Layout.Sider>
  );
};

export default Menu;

import React, { useMemo, useState } from 'react';
import { Col, Form, InputNumber, Modal, Row, Select, Tabs, TimePicker, Typography } from 'antd';
import moment from 'moment-timezone';
import cronstrue from 'cronstrue';
import { useResetFormOnCloseModal } from '../utils';
import WeekDaySelect from '../WeekDaySelect/WeekDaySelect';
import MonthDaySelect from '../MonthDaySelect/MonthDaySelect';
import Schedules, { schedules } from './schedules';

const ModalFormCustomRecurrence = ({ open, onCancel, onSubmit }) => {
  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    open,
  });

  const [activeTab, setActiveTab] = useState('weekly');

  const handleSubmit = ({ weekdays, monthdays, everyXmonth }) => {
    switch (activeTab) {
      case 'weekly':
        onSubmit(`* * ${weekdays.sort().join(',')}`);
        break;
      case 'monthly':
        onSubmit(`${monthdays.sort().join(',')} 1/${everyXmonth} *`);
        break;
      default:
    }
  };

  const initialValues = {
    weekdays: [Number.parseInt(moment().format('d'), 10)],
    monthdays: [Number.parseInt(moment().format('D'), 10)],
    everyXmonth: 1,
  };

  return (
    <Modal title="Custom recurrence" open={open} onOk={form.submit} onCancel={onCancel}>
      <Form form={form} layout="horizontal" onFinish={handleSubmit} initialValues={initialValues}>
        <Tabs
          value={activeTab}
          onChange={setActiveTab}
          items={[
            {
              label: 'Weekly',
              key: 'weekly',
              children: (
                <>
                  <Form.Item name="weekdays" label="Repeat every week on">
                    <WeekDaySelect />
                  </Form.Item>
                </>
              ),
            },
            {
              label: 'Monthly',
              key: 'monthly',
              children: (
                <>
                  <Typography.Text>
                    Repeat every&nbsp;
                    <Form.Item name="everyXmonth" noStyle>
                      <InputNumber style={{ width: 60 }} max={12} min={1} />
                    </Form.Item>
                    &nbsp;months
                    <Form.Item name="monthdays" label="On">
                      <MonthDaySelect />
                    </Form.Item>
                  </Typography.Text>
                </>
              ),
            },
          ]}
        />
      </Form>
    </Modal>
  );
};

/**
 * @param value : Cron or NULL = One time
 * @param onChange
 * @param disableOneTime
 * @returns {JSX.Element}
 * @constructor
 */
const CronScheduleInput = ({ value, onChange, disableOneTime = false }) => {
  const [minutes, hours, ...subCrons] = (value ?? '0 6').split(' ');
  const cron = value ? subCrons.join(' ') : null;

  const type = useMemo(() => {
    if (!cron) return 'one_time';
    return Object.keys(schedules).find((key) => schedules[key].cron === cron) ?? 'custom';
  }, [cron]);

  const [openCustom, setOpenCustom] = useState(false);

  const handleSubmitCustom = (customCron) => {
    onChange(`${minutes} ${hours} ${customCron}`);
    setOpenCustom(false);
  };

  const handleCancelCustom = () => {
    setOpenCustom(false);
  };

  const handleChangeRecurrencyType = (newType) => {
    switch (newType) {
      case 'one_time':
        onChange(null);
        break;
      case 'custom':
        setOpenCustom(true);
      // eslint-disable-next-line no-fallthrough
      default:
        onChange(`${minutes} ${hours} ${schedules[newType].cron}`);
        break;
    }
  };

  const handleChangeRecurrencyTime = (newTime) => {
    onChange(`${newTime.format('m')} ${newTime.format('H')} ${cron}`);
  };

  return (
    <Row gutter={8}>
      <Col span="15">
        <Form.Item label="Schedule" required>
          <Select
            value={type}
            onSelect={handleChangeRecurrencyType}
            options={[
              { value: Schedules.ONE_TIME, label: 'One time', disabled: disableOneTime },
              ...Object.values(schedules),
            ]}
          />
        </Form.Item>
      </Col>
      <Col span="9">
        <Form.Item label="At" required>
          <TimePicker
            style={{ width: '100%' }}
            value={moment(`${hours}:${minutes}`, 'HH:mm')}
            onChange={handleChangeRecurrencyTime}
            format="HH:mm"
            minuteStep={15}
            disabled={type === 'one_time'}
            allowClear={false}
          />
        </Form.Item>
      </Col>
      {type === 'custom' && (
        <Typography.Text type="secondary" style={{ margin: '-12px 8px 12px 8px', fontSize: 11 }}>
          {cronstrue.toString(value)}.
        </Typography.Text>
      )}
      <ModalFormCustomRecurrence open={openCustom} onSubmit={handleSubmitCustom} onCancel={handleCancelCustom} />
    </Row>
  );
};

export default CronScheduleInput;

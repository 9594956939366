import React, { useCallback, useContext, useMemo } from 'react';
import { Space } from 'antd';
import { Block } from '../../../../../../Common/Sider';
import { config as WidgetTypes } from '../../../../constants/widgetTypes';
import OptionBuilder from './OptionBuilder';
import WidgetContext from '../widget.context';
import { useCan } from '../../../../../../../../contexts/ability.context';

const Options = () => {
  const { widget, updateWidget, editable } = useContext(WidgetContext);

  const can = useCan();

  const widgetType = useMemo(() => {
    if (widget) {
      if (WidgetTypes[widget.type]) {
        return WidgetTypes[widget.type];
      }
      throw new Error(`Widget type ${widget.type} does not exist. (${widget.i})`);
    }
    return null;
  }, [widget]);

  const handleChange = useCallback(
    (key, value) => {
      updateWidget({
        ...widget,
        options: {
          ...widget.options,
          [key]: value,
        },
      });
    },
    [widget, updateWidget],
  );

  const options = widgetType.options?.filter((option) => !option.isForbidden?.(can)) ?? [];

  if (!options.length) return null;

  return (
    <Block key="options" title="Set options" description="Configure chart settings">
      <Space direction="vertical">
        {options.map((option) => (
          <OptionBuilder
            key={option.name}
            name={option.name}
            value={widget.options?.[option.name] ?? option.defaultValue}
            onChange={handleChange}
            disabled={!editable}
          />
        ))}
      </Space>
    </Block>
  );
};

export default Options;

import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { compareByProp } from '../../../../../../../util/array';
import { Link } from '../../../../../../../util/navigate';
import TableTile from '../../../Tile/Table/Table';
import { orderRoutingQuery, activityRoutingQuery } from './query';
import RoutingSkeleton from './RoutingSkeleton';

const Routing = ({ onHeightReady }) => {
  const { t } = useTranslation();
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(
    activityRoutingQuery,
    {
      variables: {
        orderId,
      },
    },
  );

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(orderRoutingQuery, {
    variables: {
      orderId,
    },
    onCompleted: (data) => !data.order && fetchInsights(),
  });

  const order = orderStack ?? orderInsights;

  if (!order) return <RoutingSkeleton />;
  const columns = [
    {
      title: 'Trx number',
      dataIndex: 'order_transaction_number',
      key: 'order_transaction_number',
      sorter: compareByProp('order_transaction_number'),
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
    },
    {
      title: 'Mode',
      dataIndex: 'routingMode',
      key: 'mode',
      render: (mode) => (mode ? t(`order.detail.routing.mode.${mode}`) : 'n/a'),
    },
    {
      title: 'Merchant contract',
      dataIndex: 'merchant_contract',
      key: 'merchant_contract',
      render: (_, transaction) => transaction.merchant_contract?.name ?? 'n/a',
    },
    {
      title: 'Reason',
      dataIndex: 'routingResponse',
      key: 'reason',
      render: (response, transaction) => {
        if (transaction.routingMode === 'default_provider' || transaction.routingMode === 'merchant_contract') {
          return 'n/a';
        }
        const routingResponse = response?.find((elem) => elem.merchant_contract === transaction.merchant_contract?.id);
        if (routingResponse?.reason === 'smart routing') {
          return 'Smart routing';
        }
        if (!routingResponse?.reason?.[0].rule_id) {
          if (routingResponse?.reason?.[0].rule) {
            return routingResponse?.reason?.[0].rule;
          }
          return 'n/a';
        }
        return (
          <Link
            to={`/router/payment-network/rule/${routingResponse.reason?.[0].rule_id}/${routingResponse.reason?.[0].rule_version}`}
          >
            Rule: {routingResponse.reason?.[0].rule}
          </Link>
        );
      },
    },
  ];

  return (
    <TableTile
      name="routing"
      title="Routing information"
      columns={columns}
      dataSource={order?.transactions}
      rowKey="id"
      onHeightReady={onHeightReady}
    />
  );
};

export default Routing;

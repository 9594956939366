import React from 'react';
import { Col, Row } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment-timezone';
import ErrorBoundary from '../../Common/ErrorBoundary/ErrorBoundary';
import Page from '../../Common/Page/Page';
import styles from './HomePage.module.scss';
import NotePad from './NotePad/NotePad';
import UserCard from './UserCard/UserCard';
import BrainpowerCard from './BrainpowerCard/BrainpowerCard';
import RecentAccess from './RecentAccess/RecentAccess';

const HomePage = () => (
  <Page>
    <ErrorBoundary>
      <PageHeader>
        <div className={styles.header}>
          <div className={styles.date}>{moment().format('dddd LL')}</div>
        </div>
        <Row gutter={[24, 24]}>
          <Col lg={8} xs={24}>
            <UserCard />
          </Col>
          <Col lg={16} xs={24}>
            <NotePad />
          </Col>
          <Col span={24}>
            <BrainpowerCard />
          </Col>
          <Col span={24}>
            <RecentAccess />
          </Col>
        </Row>
      </PageHeader>
    </ErrorBoundary>
  </Page>
);

export default HomePage;

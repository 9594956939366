// @ts-ignore
import SharedFieldTypes from 'norbr-shared-lib/constants/order/fields/fieldTypes';
// @ts-ignore
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import { isModeMerchant } from '../../../../../constants/mode';
import Axis from './axis';
import { ColorGenerator } from './colors';

const colorGenerator = new ColorGenerator({ startIndex: 10 });

// /!\ items are sorted for the most used /!\
enum Dimensions {
  // --- Most used dimensions
  payinPartner = 'payin_partner',
  paymentMethodName = 'payment_method',
  paymentChannel = 'payment_channel',
  transactionStatus = 'transaction_status',
  currency = 'currency',
  // --- End of most used dimensions
  amountRange = 'amount_range',
  authenticationPartner = 'authentication_partner',
  authenticationResult = 'authentication_result',
  authorizationDate = 'authorization_date',
  authorizationResult = 'authorization_result',
  cancellationDate = 'cancellation_date',
  cancellationResult = 'cancellation_result',
  captureDate = 'capture_date',
  captureResult = 'capture_result',
  card6Digits = 'card_6_digits',
  cardCountry = 'card_country',
  cardCountryName = 'card_country_name',
  cardIssuer = 'card_issuer',
  cardRegion = 'card_region',
  cardType1 = 'card_category',
  cardType2 = 'card_type',
  cashierFileDate = 'cashier_file_date',
  cashierFileId = 'cashier_file_id',
  cashierFileName = 'cashier_file_name',
  cashierPartner = 'cashier_partner',
  category = 'category',
  challengePreference = 'challenge_preference',
  chargebackDate = 'chargeback_date',
  chargebackReason = 'chargeback_reason',
  checkoutCreationDate = 'checkout_creation_date',
  checkoutCurrency = 'checkout_currency',
  checkoutCustomerId = 'checkout_customer_id',
  checkoutExpirationDate = 'checkout_expiration_date',
  checkoutId = 'checkout_id',
  checkoutType = 'checkout_type',
  checkoutUpdateDate = 'checkout_update_date',
  cmsName = 'cms_name',
  cmsVersion = 'cms_version',
  companyId = 'company',
  companyName = 'company_name',
  customerCountry = 'customer_country',
  customerCountryName = 'customer_country_name',
  customerId = 'customer_id',
  customerRegion = 'customer_region',
  customerZipCode = 'customer_zip_code',
  deviceType = 'device_type',
  giftCardUsed = 'giftcard_used',
  integrationType = 'integration_type',
  isAuthenticated = 'is_authenticated',
  isAuthorized = 'is_authorized',
  isCancelled = 'is_cancelled',
  isCaptured = 'is_captured',
  isChargebacked = 'is_chargebacked',
  isOrderLastTransaction = 'is_order_last_transaction',
  isPending = 'is_pending',
  isReconciled = 'is_reconciled',
  isRefunded = 'is_refunded',
  isWithdraw = 'is_withdraw',
  // matchAuthor = 'match_author',
  matchAuthorEmail = 'match_author_email',
  matchDate = 'match_date',
  matchReason = 'match_reason',
  matchStatusExclWithdraws = 'match_status_excl_withdraws',
  matchStatusInclWithdraws = 'match_status_incl_withdraws',
  matchType = 'match_type',
  merchantAccountId = 'merchant_account',
  merchantAccountName = 'merchant_account_name',
  merchantContractId = 'merchant_contract',
  merchantContractName = 'merchant_contract_name',
  merchantStoreId = 'merchant_store_id',
  merchantTerminalId = 'merchant_terminal_id',
  metaProgramManagerId = 'meta_program_manager',
  metaProgramManagerName = 'meta_program_manager_name',
  noDimension = 'NO_DIMENSION',
  norbrResultCode = 'result_code',
  norbrResultDescription = 'result_description',
  notificationDate = 'notification_date',
  operationType = 'operation_type',
  orderCreationDate = 'order_creation_date',
  orderId = 'order_id',
  orderIsReconciled = 'order_is_reconciled',
  orderMerchantId = 'order_merchant_id',
  orderStatus = 'order_status',
  orderType = 'order_type',
  paymentMethodType = 'payment_method_type',
  programManagerId = 'program_manager',
  programManagerName = 'program_manager_name',
  pspResultCode = 'psp_result_code',
  pspResultDescription = 'psp_result_description',
  pspTransactionDate = 'psp_transaction_date',
  pspTransactionId = 'psp_transaction_id',
  refundDate = 'refund_date',
  refundReason = 'refund_reason',
  retryScenario = 'retry_scenario',
  riskAssessmentPartner = 'risk_assessment_partner',
  riskAssessmentResult = 'risk_assessment_result',
  routingMode = 'routing_mode',
  routingResult = 'routing_result',
  routingRule = 'routing_rule',
  routingRuleVersion = 'routing_rule_version',
  scaExemptionReason = 'sca_exemption_reason',
  scheduledCaptureDate = 'scheduled_capture_date',
  schemeCustomer = 'scheme_customer',
  schemeReal = 'scheme_real',
  schemeSecondary = 'scheme_secondary',
  sellerId = 'seller_id',
  sellerName = 'seller_name',
  source = 'source',
  token = 'token',
  tokenType = 'token_type',
  transactionCreationDate = 'transaction_creation_date',
  transactionId = 'transaction_id',
  transactionType = 'type',
  transactionUpdateDate = 'transaction_update_date',
  updatedAt = 'update_date',
  websiteUrl = 'website_url',
  withdrawReason = 'withdraw_reason',
}

export const config = {
  [Dimensions.checkoutCreationDate]: {
    id: Dimensions.checkoutCreationDate,
    label: 'Checkout creation date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.CHECKOUT_CREATION_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.checkoutCurrency]: {
    id: Dimensions.checkoutCurrency,
    label: 'Checkout currency',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.CURRENCY,
    field: Fields.CHECKOUT_CURRENCY,
    color: colorGenerator.next(),
  },
  [Dimensions.checkoutCustomerId]: {
    id: Dimensions.checkoutCustomerId,
    label: 'Checkout customer ID',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.CHECKOUT_CUSTOMER_ID,
    color: colorGenerator.next(),
  },
  [Dimensions.checkoutUpdateDate]: {
    id: Dimensions.checkoutUpdateDate,
    label: 'Checkout update date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.CHECKOUT_UPDATE_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.checkoutExpirationDate]: {
    id: Dimensions.checkoutExpirationDate,
    label: 'Checkout expiration date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.CHECKOUT_EXPIRATION_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.checkoutId]: {
    id: Dimensions.checkoutId,
    label: 'Checkout ID',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.ID,
    field: Fields.CHECKOUT_ID,
    color: colorGenerator.next(),
  },
  [Dimensions.checkoutType]: {
    id: Dimensions.checkoutType,
    label: 'Checkout type',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.CHECKOUT_TYPE,
    color: colorGenerator.next(),
  },
  [Dimensions.token]: {
    id: Dimensions.token,
    label: 'Token',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.ID,
    field: Fields.TOKEN,
    color: colorGenerator.next(),
  },
  [Dimensions.tokenType]: {
    id: Dimensions.tokenType,
    label: 'Token type',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.TOKEN_TYPE,
    color: colorGenerator.next(),
  },
  [Dimensions.websiteUrl]: {
    id: Dimensions.websiteUrl,
    label: 'Website URL',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.WEBSITE_URL,
    color: colorGenerator.next(),
  },
  [Dimensions.withdrawReason]: {
    id: Dimensions.withdrawReason,
    label: 'Withdraw reason',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.WITHDRAW_REASON,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher-withdraw'),
  },
  [Dimensions.chargebackReason]: {
    id: Dimensions.chargebackReason,
    label: 'Chargeback reason',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.CHARGEBACK_REASON,
    field: Fields.CHARGEBACK_REASON,
    color: colorGenerator.next(),
  },
  [Dimensions.chargebackDate]: {
    id: Dimensions.chargebackDate,
    label: 'Chargeback date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.CHARGEBACK_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.orderCreationDate]: {
    id: Dimensions.orderCreationDate,
    label: 'Order creation date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.ORDER_CREATION_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.transactionCreationDate]: {
    id: Dimensions.transactionCreationDate,
    label: 'Transaction creation date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.TRANSACTION_CREATION_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.customerCountry]: {
    id: Dimensions.customerCountry,
    label: 'Customer country',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.COUNTRY,
    field: Fields.CUSTOMER_COUNTRY,
    color: colorGenerator.next(),
  },
  [Dimensions.customerZipCode]: {
    id: Dimensions.customerZipCode,
    label: 'Customer ZIP code',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.CUSTOMER_ZIP_CODE,
    color: colorGenerator.next(),
  },
  [Dimensions.payinPartner]: {
    id: Dimensions.payinPartner,
    label: 'Payin partner',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.PAYIN_PARTNER,
    field: Fields.PAYIN_PARTNER,
    color: colorGenerator.next(),
  },
  [Dimensions.paymentChannel]: {
    id: Dimensions.paymentChannel,
    label: 'Payment channel',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.PAYMENT_CHANNEL,
    field: Fields.PAYMENT_CHANNEL,
    color: colorGenerator.next(),
  },
  [Dimensions.paymentMethodName]: {
    id: Dimensions.paymentMethodName,
    label: 'Payment method',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.PAYMENT_METHOD,
    field: Fields.PAYMENT_METHOD,
    color: colorGenerator.next(),
  },
  [Dimensions.refundDate]: {
    id: Dimensions.refundDate,
    label: 'Refund date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.REFUND_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.refundReason]: {
    id: Dimensions.refundReason,
    label: 'Refund reason',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.REFUND_REASON,
    field: Fields.REFUND_REASON,
    color: colorGenerator.next(),
  },
  [Dimensions.riskAssessmentResult]: {
    id: Dimensions.riskAssessmentResult,
    label: 'Risk assessment result',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.STATUS_RESULT,
    field: Fields.RISK_ASSESSMENT_RESULT,
    color: colorGenerator.next(),
  },
  [Dimensions.updatedAt]: {
    id: Dimensions.updatedAt,
    label: 'Updated date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.UPDATE_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.authenticationPartner]: {
    id: Dimensions.authenticationPartner,
    label: 'Authentication partner',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.AUTHENTICATION_PARTNER,
    field: Fields.AUTHENTICATION_PARTNER,
    color: colorGenerator.next(),
  },
  [Dimensions.authenticationResult]: {
    id: Dimensions.authenticationResult,
    label: 'Authentication result',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.STATUS_RESULT,
    field: Fields.AUTHENTICATION_RESULT,
    color: colorGenerator.next(),
  },
  [Dimensions.authorizationDate]: {
    id: Dimensions.authorizationDate,
    label: 'Authorization date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.AUTHORIZATION_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.authorizationResult]: {
    id: Dimensions.authorizationResult,
    label: 'Authorization result',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.STATUS_RESULT,
    field: Fields.AUTHORIZATION_RESULT,
    color: colorGenerator.next(),
  },
  [Dimensions.cancellationDate]: {
    id: Dimensions.cancellationDate,
    label: 'Cancellation date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.CANCELLATION_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.cancellationResult]: {
    id: Dimensions.cancellationResult,
    label: 'Cancellation result',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.STATUS_RESULT,
    field: Fields.CANCELLATION_RESULT,
    color: colorGenerator.next(),
  },
  [Dimensions.captureDate]: {
    id: Dimensions.captureDate,
    label: 'Capture date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.CAPTURE_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.captureResult]: {
    id: Dimensions.captureResult,
    label: 'Capture result',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.STATUS_RESULT,
    field: Fields.CAPTURE_RESULT,
    color: colorGenerator.next(),
  },
  [Dimensions.riskAssessmentPartner]: {
    id: Dimensions.riskAssessmentPartner,
    label: 'Risk assessment partner',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.RISK_ASSESSMENT_PARTNER,
    field: Fields.RISK_ASSESSMENT_PARTNER,
    color: colorGenerator.next(),
  },
  [Dimensions.transactionId]: {
    id: Dimensions.transactionId,
    label: 'Transaction ID',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.ID,
    field: Fields.TRANSACTION_ID,
    color: colorGenerator.next(),
  },
  [Dimensions.transactionUpdateDate]: {
    id: Dimensions.transactionUpdateDate,
    label: 'Transaction update date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.TRANSACTION_UPDATE_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.transactionStatus]: {
    id: Dimensions.transactionStatus,
    label: 'Transaction status',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.STATUS,
    field: Fields.TRANSACTION_STATUS,
    color: colorGenerator.next(),
  },
  // [Dimensions.referenceDate]: {
  //   id: Dimensions.referenceDate,
  //   label: 'Reference date',
  //   axis: Axis.Date,
  //   color: colorGenerator.next(),
  // },
  [Dimensions.customerId]: {
    id: Dimensions.customerId,
    label: 'Customer ID',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.ID,
    field: Fields.CUSTOMER_ID,
    color: colorGenerator.next(),
  },
  [Dimensions.customerCountryName]: {
    id: Dimensions.customerCountryName,
    label: 'Customer country name',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  [Dimensions.customerRegion]: {
    id: Dimensions.customerRegion,
    label: 'Customer region',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  [Dimensions.amountRange]: {
    id: Dimensions.amountRange,
    label: 'Amount range',
    axis: Axis.Category,
    fieldType: 'AMOUNT_RANGE',
    field: Fields.TRANSACTION_AMOUNT,
    color: colorGenerator.next(),
  },
  [Dimensions.currency]: {
    id: Dimensions.currency,
    label: 'Currency',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.CURRENCY,
    field: Fields.CURRENCY,
    color: colorGenerator.next(),
  },
  [Dimensions.orderType]: {
    id: Dimensions.orderType,
    label: 'Order type',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  [Dimensions.orderMerchantId]: {
    id: Dimensions.orderMerchantId,
    label: 'Order merchant ID',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.ID,
    field: Fields.ORDER_MERCHANT_ID,
    color: colorGenerator.next(),
  },
  [Dimensions.orderStatus]: {
    id: Dimensions.orderStatus,
    label: 'Order status',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.ORDER_STATUS,
    color: colorGenerator.next(),
  },
  [Dimensions.cardIssuer]: {
    id: Dimensions.cardIssuer,
    label: 'Card issuer',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.CARD_ISSUER,
    color: colorGenerator.next(),
  },
  [Dimensions.cardCountry]: {
    id: Dimensions.cardCountry,
    label: 'Card country',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.COUNTRY,
    field: Fields.CARD_COUNTRY,
    color: colorGenerator.next(),
  },
  [Dimensions.cardCountryName]: {
    id: Dimensions.cardCountryName,
    label: 'Card country name',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  [Dimensions.cardRegion]: {
    id: Dimensions.cardRegion,
    label: 'Card region',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  [Dimensions.isAuthenticated]: {
    id: Dimensions.isAuthenticated,
    label: 'Authenticated',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.BOOLEAN,
    field: Fields.IS_AUTHENTICATED,
    color: colorGenerator.next(),
  },
  // [Dimensions.isSuspicious]: {
  //   id: Dimensions.isSuspicious,
  //   label: 'Suspicious',
  //   axis: Axis.Category,
  // fieldType: SharedFieldTypes.BOOLEAN,
  //   color: colorGenerator.next(),
  // },
  [Dimensions.isAuthorized]: {
    id: Dimensions.isAuthorized,
    label: 'Authorized',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.BOOLEAN,
    field: Fields.IS_AUTHORIZED,
    color: colorGenerator.next(),
  },
  [Dimensions.isCancelled]: {
    id: Dimensions.isCancelled,
    label: 'Cancelled',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.BOOLEAN,
    field: Fields.IS_CANCELLED,
    color: colorGenerator.next(),
  },
  [Dimensions.isCaptured]: {
    id: Dimensions.isCaptured,
    label: 'Captured',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.BOOLEAN,
    field: Fields.IS_CAPTURED,
    color: colorGenerator.next(),
  },
  [Dimensions.isRefunded]: {
    id: Dimensions.isRefunded,
    label: 'Refunded',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.BOOLEAN,
    field: Fields.IS_REFUNDED,
    color: colorGenerator.next(),
  },
  [Dimensions.isChargebacked]: {
    id: Dimensions.isChargebacked,
    label: 'Chargebacked',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.BOOLEAN,
    field: Fields.IS_CHARGEBACKED,
    color: colorGenerator.next(),
  },
  [Dimensions.isOrderLastTransaction]: {
    id: Dimensions.isOrderLastTransaction,
    label: 'Order last transaction',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.BOOLEAN,
    field: Fields.IS_ORDER_LAST_TRANSACTION,
    color: colorGenerator.next(),
  },
  [Dimensions.retryScenario]: {
    id: Dimensions.retryScenario,
    label: 'Retry scenario',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  // [Dimensions.isCapturable]: {
  //   id: Dimensions.isCapturable,
  //   label: 'Capturable',
  //   axis: Axis.Category,
  //   fieldType: SharedFieldTypes.BOOLEAN,
  //   color: colorGenerator.next(),
  // },
  // [Dimensions.isRefundable]: {
  //   id: Dimensions.isRefundable,
  //   label: 'Refundable',
  //   axis: Axis.Category,
  //   fieldType: SharedFieldTypes.BOOLEAN,
  //   color: colorGenerator.next(),
  // },
  // [Dimensions.isCancellable]: {
  //   id: Dimensions.isCancellable,
  //   label: 'Cancellable',
  //   axis: Axis.Category,
  //   fieldType: SharedFieldTypes.BOOLEAN,
  //   color: colorGenerator.next(),
  // },
  // [Dimensions.partnerRoute]: {
  //   id: Dimensions.partnerRoute,
  //   label: 'Partner route',
  //   axis: Axis.Category,
  //   fieldType: SharedFieldTypes.TEXT,
  //   color: colorGenerator.next(),
  // },
  [Dimensions.noDimension]: {
    id: Dimensions.noDimension,
    label: 'No dimension',
    axis: Axis.Category,
    fieldType: 'NO_DIMENSION',
    field: 'NO_DIMENSION',
    color: colorGenerator.next(),
  },
  [Dimensions.transactionType]: {
    id: Dimensions.transactionType,
    label: 'Transaction type',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.TYPE,
    color: colorGenerator.next(),
  },
  [Dimensions.sellerName]: {
    id: Dimensions.sellerName,
    label: 'Seller name',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  [Dimensions.sellerId]: {
    id: Dimensions.sellerId,
    label: 'Seller id',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.ID,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  [Dimensions.giftCardUsed]: {
    id: Dimensions.giftCardUsed,
    label: 'Gift card used',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.BOOLEAN,
    field: Fields.GIFTCARD_USED,
    color: colorGenerator.next(),
  },
  [Dimensions.integrationType]: {
    id: Dimensions.integrationType,
    label: 'Integration type',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.INTEGRATION_TYPE,
    field: Fields.INTEGRATION_TYPE,
    color: colorGenerator.next(),
  },
  [Dimensions.cmsName]: {
    id: Dimensions.cmsName,
    label: 'CMS name',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  [Dimensions.cmsVersion]: {
    id: Dimensions.cmsVersion,
    label: 'CMS version',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  [Dimensions.deviceType]: {
    id: Dimensions.deviceType,
    label: 'Device type',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.DEVICE_TYPE,
    field: Fields.DEVICE_TYPE,
    color: colorGenerator.next(),
  },
  [Dimensions.routingMode]: {
    id: Dimensions.routingMode,
    label: 'Routing mode',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.ROUTING_MODE,
    color: colorGenerator.next(),
  },
  [Dimensions.routingRule]: {
    id: Dimensions.routingRule,
    label: 'Routing rule',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.ID,
    field: Fields.ROUTING_RULE,
    color: colorGenerator.next(),
  },
  [Dimensions.routingRuleVersion]: {
    id: Dimensions.routingRuleVersion,
    label: 'Routing rule version',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.ROUTING_RULE_VERSION,
    color: colorGenerator.next(),
  },
  [Dimensions.routingResult]: {
    id: Dimensions.routingResult,
    label: 'Routing result',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  [Dimensions.norbrResultCode]: {
    id: Dimensions.norbrResultCode,
    label: 'NORBr result code',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.RESULT_CODE,
    color: colorGenerator.next(),
  },
  [Dimensions.norbrResultDescription]: {
    id: Dimensions.norbrResultDescription,
    label: 'NORBr result description',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.RESULT_DESCRIPTION,
    color: colorGenerator.next(),
  },
  [Dimensions.programManagerId]: {
    id: Dimensions.programManagerId,
    label: 'Program manager',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.PROGRAM_MANAGER,
    field: Fields.PROGRAM_MANAGER,
    color: colorGenerator.next(),
    internalOnly: true,
  },
  [Dimensions.programManagerName]: {
    id: Dimensions.programManagerName,
    label: 'Program manager name',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
    internalOnly: true,
  },
  [Dimensions.metaProgramManagerId]: {
    id: Dimensions.metaProgramManagerId,
    label: 'Meta program manager',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.META_PROGRAM_MANAGER,
    field: Fields.META_PROGRAM_MANAGER,
    color: colorGenerator.next(),
    internalOnly: true,
  },
  [Dimensions.metaProgramManagerName]: {
    id: Dimensions.metaProgramManagerName,
    label: 'Meta program manager name',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
    internalOnly: true,
  },
  [Dimensions.companyId]: {
    id: Dimensions.companyId,
    label: 'Company',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.COMPANY,
    field: Fields.COMPANY,
    color: colorGenerator.next(),
    internalOnly: isModeMerchant,
  },
  [Dimensions.companyName]: {
    id: Dimensions.companyName,
    label: 'Company name',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
    internalOnly: isModeMerchant,
  },
  [Dimensions.merchantAccountId]: {
    id: Dimensions.merchantAccountId,
    label: 'Merchant account',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.MERCHANT_ACCOUNT,
    field: Fields.MERCHANT_ACCOUNT,
    color: colorGenerator.next(),
  },
  [Dimensions.merchantAccountName]: {
    id: Dimensions.merchantAccountName,
    label: 'Merchant account name',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  [Dimensions.scaExemptionReason]: {
    id: Dimensions.scaExemptionReason,
    label: 'SCA exemption reason',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.SCA_EXEMPTION_REASON,
    field: Fields.SCA_EXEMPTION_REASON,
    color: colorGenerator.next(),
  },
  [Dimensions.scheduledCaptureDate]: {
    id: Dimensions.scheduledCaptureDate,
    label: 'Scheduled capture date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.SCHEDULED_CAPTURE_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.challengePreference]: {
    id: Dimensions.challengePreference,
    label: 'Challenge preference',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.CHALLENGE_PREFERENCE,
    field: Fields.CHALLENGE_PREFERENCE,
    color: colorGenerator.next(),
  },
  [Dimensions.paymentMethodType]: {
    id: Dimensions.paymentMethodType,
    label: 'Payment method type',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.PAYMENT_METHOD_TYPE,
    field: Fields.PAYMENT_METHOD_TYPE,
    color: colorGenerator.next(),
  },
  [Dimensions.merchantContractId]: {
    id: Dimensions.merchantContractId,
    label: 'Merchant contract ID',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.MERCHANT_CONTRACT,
    field: Fields.MERCHANT_CONTRACT,
    color: colorGenerator.next(),
  },
  [Dimensions.merchantContractName]: {
    id: Dimensions.merchantContractName,
    label: 'Merchant contract name',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  [Dimensions.cardType1]: {
    id: Dimensions.cardType1,
    label: 'Card category',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.CARD_CATEGORY,
    color: colorGenerator.next(),
  },
  [Dimensions.cardType2]: {
    id: Dimensions.cardType2,
    label: 'Card type',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.CARD_TYPE,
    color: colorGenerator.next(),
  },
  [Dimensions.schemeCustomer]: {
    id: Dimensions.schemeCustomer,
    label: 'Scheme customer',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  [Dimensions.schemeReal]: {
    id: Dimensions.schemeReal,
    label: 'Scheme real',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  [Dimensions.schemeSecondary]: {
    id: Dimensions.schemeSecondary,
    label: 'Scheme secondary',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null, // not on overview
    color: colorGenerator.next(),
  },
  [Dimensions.source]: {
    id: Dimensions.source,
    label: 'Source',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.SOURCE,
    field: Fields.SOURCE,
    color: colorGenerator.next(),
  },
  [Dimensions.merchantStoreId]: {
    id: Dimensions.merchantStoreId,
    label: 'Merchant store ID',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.MERCHANT_STORE_ID,
    color: colorGenerator.next(),
  },
  [Dimensions.merchantTerminalId]: {
    id: Dimensions.merchantTerminalId,
    label: 'Merchant Terminal ID',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.MERCHANT_TERMINAL_ID,
    color: colorGenerator.next(),
  },
  [Dimensions.orderId]: {
    id: Dimensions.orderId,
    label: 'Order ID',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.ID,
    field: Fields.ORDER_ID,
    color: colorGenerator.next(),
  },
  [Dimensions.orderIsReconciled]: {
    id: Dimensions.orderIsReconciled,
    label: 'Order matched',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.BOOLEAN,
    field: Fields.ORDER_IS_RECONCILED,
    color: colorGenerator.next(),
  },
  [Dimensions.isReconciled]: {
    id: Dimensions.isReconciled,
    label: 'Transaction matched',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.BOOLEAN,
    field: Fields.IS_RECONCILED,
    color: colorGenerator.next(),
  },
  [Dimensions.cashierFileId]: {
    id: Dimensions.cashierFileId,
    label: 'Cashier file ID',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.ID,
    field: Fields.CASHIER_FILE_ID,
    color: colorGenerator.next(),
  },
  [Dimensions.cashierFileName]: {
    id: Dimensions.cashierFileName,
    label: 'Cashier file name',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.CASHIER_FILE_NAME,
    color: colorGenerator.next(),
  },
  [Dimensions.cashierFileDate]: {
    id: Dimensions.cashierFileDate,
    label: 'Cashier file date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.CASHIER_FILE_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.cashierPartner]: {
    id: Dimensions.cashierPartner,
    label: 'Cashier partner',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.CASHIER_PARTNER,
    field: Fields.CASHIER_PARTNER,
    color: colorGenerator.next(),
  },
  [Dimensions.pspResultCode]: {
    id: Dimensions.pspResultCode,
    label: 'PSP result code',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.PSP_RESULT_CODE,
    color: colorGenerator.next(),
  },
  [Dimensions.pspResultDescription]: {
    id: Dimensions.pspResultDescription,
    label: 'PSP result description',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.PSP_RESULT_DESCRIPTION,
    color: colorGenerator.next(),
  },
  [Dimensions.pspTransactionDate]: {
    id: Dimensions.pspTransactionDate,
    label: 'PSP transaction date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.PSP_TRANSACTION_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.pspTransactionId]: {
    id: Dimensions.pspTransactionId,
    label: 'PSP transaction ID',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.ID,
    field: Fields.PSP_TRANSACTION_ID,
    color: colorGenerator.next(),
  },
  [Dimensions.card6Digits]: {
    id: Dimensions.card6Digits,
    label: 'Card 6 digits',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.CARD_6_DIGITS,
    color: colorGenerator.next(),
  },
  [Dimensions.category]: {
    id: Dimensions.category,
    label: 'Category',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.CATEGORY,
    field: Fields.CATEGORY,
    color: colorGenerator.next(),
  },
  [Dimensions.operationType]: {
    id: Dimensions.operationType,
    label: 'Operation type',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.OPERATION_TYPE,
    field: Fields.OPERATION_TYPE,
    color: colorGenerator.next(),
  },
  [Dimensions.matchReason]: {
    id: Dimensions.matchReason,
    label: 'Match reason',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.RECONCILIATION_REASON,
    field: Fields.RECONCILIATION_REASON,
    color: colorGenerator.next(),
  },
  [Dimensions.matchType]: {
    id: Dimensions.matchType,
    label: 'Match type',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.RECONCILIATION_TYPE,
    field: Fields.RECONCILIATION_TYPE,
    color: colorGenerator.next(),
  },
  [Dimensions.matchDate]: {
    id: Dimensions.matchDate,
    label: 'Match date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.MATCHER_OPERATION_DATE,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Dimensions.notificationDate]: {
    id: Dimensions.notificationDate,
    label: 'Notification date',
    axis: Axis.Date,
    fieldType: SharedFieldTypes.DATE,
    field: Fields.NOTIFICATION_DATE,
    color: colorGenerator.next(),
  },
  [Dimensions.matchStatusInclWithdraws]: {
    id: Dimensions.matchStatusInclWithdraws,
    label: 'Match status incl. withdraws',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher-withdraw'),
  },
  [Dimensions.matchStatusExclWithdraws]: {
    id: Dimensions.matchStatusExclWithdraws,
    label: 'Match status',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: null,
    color: colorGenerator.next(),
  },
  [Dimensions.withdrawReason]: {
    id: Dimensions.withdrawReason,
    label: 'Withdraw reason',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.WITHDRAW_REASON,
    field: Fields.WITHDRAW_REASON,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher-withdraw'),
  },
  [Dimensions.isWithdraw]: {
    id: Dimensions.isWithdraw,
    label: 'Is withdraw',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.BOOLEAN,
    field: null,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher-withdraw'),
  },
  [Dimensions.isPending]: {
    id: Dimensions.isPending,
    label: 'Is pending',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.BOOLEAN,
    field: null,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  // [Dimensions.matchAuthor]: {
  //   id: Dimensions.matchAuthor,
  //   label: 'Match author',
  //   axis: Axis.Category,
  //   fieldType: SharedFieldTypes.TEXT,
  //   field: null,
  //   color: colorGenerator.next(),
  //   isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  // },
  [Dimensions.matchAuthorEmail]: {
    id: Dimensions.matchAuthorEmail,
    label: 'Match author email',
    axis: Axis.Category,
    fieldType: SharedFieldTypes.TEXT,
    field: Fields.RECONCILIATION_AUTHOR_EMAIL,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'gdpr') || !can('read', 'matcher'),
  },
};

export const list = Object.values(Dimensions);

export default Dimensions;

import { useBrainpowerViewSettings } from './useBrainpowerSettings';
import usePage from './usePage';

const useFavoriteView = () => {
  const page = usePage();
  const [settings] = useBrainpowerViewSettings();
  if (!settings || settings.favorite === page.defaultView._id) return page.defaultView;
  const favoriteView = settings.views?.find((v) => v._id === settings.favorite);
  return favoriteView ?? page.defaultView;
};

export default useFavoriteView;

import React from 'react';
import { Space } from 'antd';
import CountryList from 'norbr-shared-lib/constants/countries/list';
import RegionList from 'norbr-shared-lib/constants/countries/regions/list';
import SubRegionList from 'norbr-shared-lib/constants/countries/subRegions/list';
import * as flags from '../assets/images/countries';
import { compareByProp } from '../util/array';

// + img : string
const COUNTRIES = CountryList.reduce(
  (memo, country) => ({
    ...memo,
    [country.id]: {
      ...country,
      value: country.id,
      label: country.label,
      img: flags[country.id],
    },
  }),
  {},
);

export const CountryFlag = ({ value, size = 24, hideTitle = false }) => {
  if (!value) return null;
  return (
    <img
      src={COUNTRIES[value]?.img}
      alt={COUNTRIES[value]?.label}
      style={{ width: size, borderRadius: 2, border: '1px solid #efefef', verticalAlign: 'sub' }}
      title={!hideTitle ? `${COUNTRIES[value]?.label} [${value}]` : undefined}
    />
  );
};

// as list
export const COUNTRY_LIST = Object.values(COUNTRIES).sort(compareByProp('label', 'asc', 'string'));

// as treeList
export const TREE_COUNTRY_LIST = RegionList.sort(compareByProp('label')).map((region) => ({
  ...region,
  key: `region_${region.id}`,
  value: `region_${region.id}`,
  children: SubRegionList.filter((subRegion) => subRegion.region === region.id)
    .sort(compareByProp('label'))
    .map((subRegion) => ({
      ...subRegion,
      key: `subRegion_${subRegion.id}`,
      value: `subRegion_${subRegion.id}`,
      children: CountryList.filter((country) => country.subregion === subRegion.id)
        .sort(compareByProp('label'))
        .map((country) => ({
          ...country,
          value: country.id,
          title: (
            <Space>
              <CountryFlag value={country.id} size={24} />
              {country.label}
            </Space>
          ),
        })),
    })),
}));

export default COUNTRIES;

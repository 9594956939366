import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { message, Modal } from 'antd';
import useNavigateWithSearch from '../../../../util/navigate';

export const CompanyProfileListQuery = gql`
  query CompanyProfileList($companyId: ID!) {
    merchantCompany(id: $companyId) {
      id
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
        merchantAclProfiles {
          id
          name
        }
      }
    }
  }
`;

export const ProfileListQuery = gql`
  query ProfileList {
    merchantAclProfiles {
      __typename
      id
      name
      description
      useCount
      roles {
        id
      }
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
    }
    merchantAclRoles {
      __typename
      id
      name
      privileges {
        id
      }
      useBy {
        id
        name
        type
      }
    }
  }
`;

export const ProfileOverviewQuery = gql`
  query ProfileOverview {
    merchantAclProfiles {
      __typename
      id
      name
      roles {
        id
      }
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
    }
  }
`;

export const ProfileViewQuery = gql`
  query ProfileViewQuery($profileId: ID!) {
    merchantAclProfile(id: $profileId) {
      __typename
      id
      name
      description
      roles {
        id
      }
      type
      useCount
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
    }
    merchantAclRoles {
      __typename
      id
      name
      privileges {
        id
      }
      useBy {
        id
        name
        type
      }
    }
  }
`;

export const ProfileCreateMutation = gql`
  mutation ProfileCreateMutation($createProfileInput: AclProfileCreationInput!, $baseProfile: ID) {
    createMerchantAclProfile(input: $createProfileInput, baseProfile: $baseProfile) {
      __typename
      id
      name
      roles {
        id
      }
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
    }
  }
`;

export const ProfileUpdateMutation = gql`
  mutation ProfileUpdateMutation($id: ID!, $updateProfileInput: AclProfileUpdateInput!) {
    updateMerchantAclProfile(id: $id, input: $updateProfileInput) {
      __typename
      id
      name
      description
      roles {
        id
      }
    }
  }
`;

export const ProfileDeleteMutation = gql`
  mutation ProfileDeleteMutation($id: ID!) {
    deleteMerchantAclProfile(id: $id) {
      __typename
      id
    }
  }
`;

/**
 * Hook to Profile Delete mutation
 * - add modal confirmation before submit
 * - add information messages (success, error)
 * @param {*} options
 * @returns
 */
export const useProfileDeleteMutation = (options = {}) => {
  const navigate = useNavigateWithSearch();
  const [deleteProfile, { data, loading, error }] = useMutation(ProfileDeleteMutation, {
    onCompleted: () => {
      if (options.onCompleted) options.onCompleted();
    },
    onError: (err) => {
      if (options.onError) options.onError(err);
      message.error(err.message);
    },
    // update ProfileList without refetch
    update(cache, { data: { deleteMerchantAclProfile } }) {
      const normalizedId = cache.identify(deleteMerchantAclProfile);
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const onDelete = ({ id, name }) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: (
        <span>
          Are you sure you want to delete <strong>{name}</strong>?
        </span>
      ),
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: () => {
        deleteProfile({
          variables: { id },
          optimisticResponse: { deleteMerchantAclProfile: { id, __typename: 'MerchantAclProfile' } },
        });
        navigate('/access-control-list');
      },
    });
  };

  return [onDelete, { data, loading, error }];
};

export default {};

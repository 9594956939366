import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import ReactHeight from 'react-height';
import moment from 'moment-timezone';
import { Button, Col, ConfigProvider, Empty, Layout, Row, Space, Table, Tag, Typography } from 'antd';
import EyeIcon from '@2fd/ant-design-icons/lib/Eye';
import FeatureSearchIcon from '@2fd/ant-design-icons/lib/FeatureSearch';
import { DATETIME2_FORMAT } from '../../../../../constants/DATES';
import { useMessages } from '../../../../../contexts/messages/messages.context';
import { compareByProp } from '../../../../../util/array';
import useNavigateWithSearch from '../../../../../util/navigate';
import { search } from '../../../../../util/string';
import useDrawer from '../../Common/useDrawer';
import Drawers from '../../Common/constants/drawers';
import targetEntities from '../../Common/constants/targetEntities';
import { renderDateCell, renderListCell } from '../../Common/AdvancedTable/buildColumns';
import { exportStatuses } from '../constants/exportStatuses';
import { reportFormats } from '../constants/reportFormats';
import { sendingMethods } from '../constants/sendingMethods';
import { reportDelimiters } from '../constants/reportDelimiters';
import { redirectToEntityList } from '../utils';
import SearchFilterTags from './SearchFilterTags/SearchFilterTags';
import styles from './ExportTable.module.scss';
import { exportListQuery } from './query';
import { useMode } from '../../../../../contexts/mode.context';

const ExportTable = ({ searchFilters, onSearch }) => {
  const navigate = useNavigateWithSearch();
  const mode = useMode();

  const { ack, messagesByType } = useMessages();

  const [, setDrawer] = useDrawer();

  const [height, setHeight] = useState();

  const { data, loading, error } = useQuery(exportListQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => ack(messagesByType('report_completed').map((message) => message.id)),
  });

  useEffect(() => {
    ack(messagesByType('report_completed').map((message) => message.id));
  }, [messagesByType('report_completed')]);

  const searchFilterList = useMemo(
    () => Object.keys(searchFilters).reduce((memo, key) => [...memo, { key, value: searchFilters[key] }], []),
    [searchFilters],
  );

  const filterExport = useCallback(
    (exp) => {
      if (searchFilterList.length < 1) return true;
      return searchFilterList.every(({ key, value }) => {
        switch (key) {
          case 'name':
          case 'description':
            return search(value, exp[key]);
          case 'status':
          case 'sendingMethod':
          case 'delimiter':
          case 'format':
            return !value || value.length === 0 || value.includes(exp[key]);
          case 'createdAt':
          case 'lastExecutionDate':
            return !value || moment(exp[key]).isBetween(value[0], value[1], 'day', '[]');
          default:
            return true;
        }
      });
    },
    [searchFilterList],
  );

  if (!data) return null;

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      sorter: compareByProp('name', 'asc', 'string'),
      fixed: 'left',
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      sorter: compareByProp('description', 'asc', 'string'),
      render: (v) => (
        <div className={styles.ellipsisCell} title={v}>
          <span>{v}</span>
        </div>
      ),
      width: 100,
    },
    {
      title: 'MODULE',
      dataIndex: 'view',
      render: (view) =>
        renderListCell({
          displayOptions: {
            getOption: (value) => targetEntities[value],
            icon: true,
            hideLabel: true,
          },
        })(view.entity),
      sorter: compareByProp('entity'),
      width: 100,
      align: 'center',
    },
    {
      title: 'CREATION',
      dataIndex: 'createdAt',
      render: renderDateCell(null, { format: DATETIME2_FORMAT }),
      sorter: compareByProp('createdAt'),
      width: 160,
    },
    {
      title: 'LAST EXECUTION',
      dataIndex: 'lastExecutionDate',
      render: renderDateCell(null, { format: DATETIME2_FORMAT }),
      sorter: compareByProp('lastExecutionDate'),
      width: 160,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: renderListCell({
        displayOptions: {
          getOption: (value) => exportStatuses[value],
          tag: true,
        },
      }),
      sorter: compareByProp('status'),
    },
    {
      title: 'FORMAT',
      dataIndex: 'format',
      render: renderListCell({
        displayOptions: {
          getOption: (value) => reportFormats[value],
        },
      }),
      sorter: compareByProp('format'),
      width: 80,
    },
    {
      title: 'METHOD',
      dataIndex: 'sendingMethod',
      render: renderListCell({
        displayOptions: {
          getOption: (value) => sendingMethods[value],
        },
      }),
      sorter: compareByProp('sendingMethod'),
      width: 120,
    },
    {
      title: '/',
      dataIndex: 'delimiter',
      render: renderListCell({
        displayOptions: {
          getOption: (value) => ({ label: reportDelimiters[value].symbol }),
        },
      }),
      sorter: compareByProp('delimiter'),
      width: 32,
    },
    {
      title: 'FREQUENCY',
      dataIndex: 'schedule',
      render: (schedule) => {
        if (!schedule) return 'One time';
        const [minutes, hours, dayOfMonth, month, dayOfWeek] = schedule.split(' ');
        if (dayOfWeek !== '*') {
          const weekDays = dayOfWeek.split(',').map((d) => Number.parseInt(d, 10));
          return (
            <Space size={2}>
              <Tag key="weekly" icon={<CalendarOutlined />}>
                Weekly
              </Tag>
              {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((tag, index) => (
                <Tag.CheckableTag
                  key={`${tag}-${index + 1}`}
                  className={styles.scheduleTag}
                  checked={weekDays.includes(index + 1)}
                >
                  {tag}
                </Tag.CheckableTag>
              ))}
              &nbsp;
              <Tag key="time" icon={<ClockCircleOutlined />}>
                {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}
              </Tag>
            </Space>
          );
        }
        if (dayOfMonth !== '*') {
          const monthDays = dayOfMonth.split(',').map((d) => Number.parseInt(d, 10));
          return (
            <Space size={2}>
              <Tag key="monthly" icon={<CalendarOutlined />}>
                {month === '*' || month === '1/1' ? 'Monthly' : `Every ${month.split('/')[1]} months`}
              </Tag>
              {monthDays.map((tag, index) => (
                <Tag.CheckableTag key={`${tag}-${index + 1}`} className={styles.scheduleTag} checked>
                  {tag}
                </Tag.CheckableTag>
              ))}
              &nbsp;
              <Tag key="time" icon={<ClockCircleOutlined />}>
                {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}
              </Tag>
            </Space>
          );
        }
        return (
          <Space size={2}>
            <Tag key="daily" icon={<CalendarOutlined />}>
              Daily
            </Tag>
            <Tag key="time" icon={<ClockCircleOutlined />}>
              {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: 'REPORTS',
      dataIndex: 'reportCount',
      sorter: compareByProp('reportCount'),
      width: 80,
      align: 'center',
      render: (reportCount) => (
        <Button type="primary" size="small">
          {reportCount}
        </Button>
      ),
    },
    {
      title: 'SHOW',
      dataIndex: 'id',
      render: (id, exp) => (
        <Button
          size="small"
          type="text"
          onClick={(event) => {
            event.stopPropagation();
            return redirectToEntityList(navigate, exp, mode);
          }}
          icon={<EyeIcon style={{ color: 'grey' }} />}
        />
      ),
      width: 80,
      align: 'center',
    },
  ];

  return (
    <Layout>
      <Layout.Header className={styles.layoutHeader}>
        <Row gutter={24} style={{ position: 'relative' }}>
          <Col>
            <Space>
              Results
              <Tag style={{ color: 'darkgrey' }}>{data.exports.length}</Tag>
            </Space>
          </Col>
          <Col flex="1" style={{ position: 'initial' }}>
            <SearchFilterTags list={searchFilterList} onSearch={onSearch} />
          </Col>
        </Row>
      </Layout.Header>
      <Layout id="export-table" className={styles.layoutContent}>
        <Layout.Content>
          <ReactHeight onHeightReady={setHeight} style={{ height: '100%' }}>
            {height && (
              <ConfigProvider
                renderEmpty={() => (
                  <Empty
                    className={styles.empty}
                    style={{ height: height - 74 }}
                    image={loading ? null : undefined}
                    description={(() => {
                      if (loading) {
                        return null;
                      }
                      if (error) {
                        return (
                          <Typography.Text type="danger">An error occurred, please contact support.</Typography.Text>
                        );
                      }
                      return [
                        <div key="no-results-found">No results found</div>,
                        <Button
                          key="adjust-filters"
                          type="link"
                          icon={<FeatureSearchIcon style={{ fontSize: 18 }} />}
                          onClick={() => setDrawer(Drawers.SEARCH)}
                        >
                          Adjust filters
                        </Button>,
                      ];
                    })()}
                  />
                )}
              >
                <Table
                  className={styles.table}
                  columns={columns}
                  dataSource={data.exports.filter(filterExport)}
                  loading={loading && { size: 'large' }}
                  size="small"
                  scroll={{ x: 'max-content', y: height - 40 }} // available height - head row height
                  pagination={false}
                  onRow={(record) => ({
                    onClick: () => navigate(record.id), // click row
                  })}
                  rowKey={(record) => record.id}
                  showSorterTooltip={false}
                  sortDirections={['descend', 'ascend']}
                />
              </ConfigProvider>
            )}
          </ReactHeight>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default ExportTable;

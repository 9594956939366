import { gql } from '@apollo/client';

export const MerchantGroupMembersSetMutation = gql`
  mutation MerchantGroupMembersSetMutation($id: ID!, $memberIds: [ID!]!) {
    setMerchantGroupMembers(id: $id, memberIds: $memberIds) {
      id
      users {
        id
        avatar
        full_name
      }
      userCount
    }
  }
`;
export const MerchantGroupMemberAddMutation = gql`
  mutation MerchantGroupMemberAddMutation($id: ID!, $memberId: ID!) {
    addMerchantGroupMember(id: $id, memberId: $memberId) {
      id
      users {
        id
        avatar
        full_name
      }
      userCount
    }
  }
`;
export const MerchantGroupMemberRemoveMutation = gql`
  mutation MerchantGroupMemberRemoveMutation($id: ID!, $memberId: ID!) {
    removeMerchantGroupMember(id: $id, memberId: $memberId) {
      id
      users {
        id
        avatar
        full_name
      }
      userCount
    }
  }
`;

export default {};

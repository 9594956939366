const SendingMethods = {
  NONE: 'none',
  SFTP: 'sftp',
};

export const sendingMethods = {
  [SendingMethods.NONE]: { value: SendingMethods.NONE, label: 'In App' },
  [SendingMethods.SFTP]: { value: SendingMethods.SFTP, label: 'SFTP' },
};
export const sendingMethodList = Object.values(sendingMethods);
export default SendingMethods;

export const MerchantAccountStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const merchantAccountStatuses = {
  [MerchantAccountStatuses.ACTIVE]: {
    value: MerchantAccountStatuses.ACTIVE,
    badge: 'success',
    color: 'green',
    label: 'Active',
  },
  [MerchantAccountStatuses.INACTIVE]: {
    value: MerchantAccountStatuses.INACTIVE,
    badge: 'default',
    color: 'lightgrey',
    label: 'Initialized',
  },
};

export const merchantAccountStatusList = Object.values(merchantAccountStatuses);

export default {};

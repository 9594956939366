import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery as useApolloQuery } from '@apollo/client';
import { Space, Row, Col, Radio, Skeleton, Button } from 'antd';
import { useParams } from 'react-router-dom';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import { ArrowRightOutlined } from '@ant-design/icons';
import { isModeAdmin } from '../../../../../constants/mode';
import usePaymentMethods from '../../../../../hooks/common/usePaymentMethods';
import TRANSACTION_STATUSES, {
  getAuthenticationStatus,
  getRiskAssessmentStatus,
} from '../../../../../constants/TRANSACTION_STATUSES';
import TooltipWithCopyBtn from '../../../Common/TableCell/TooltipWihCopyBtn/TooltipWithCopyBtn';
import useNavigateWithSearch from '../../../../../util/navigate';
import { useSearchFilter } from '../../hooks/useSearchFilters';
import styles from './Header.module.scss';
import { orderLastTransactionQuery, activityLastTransactionQuery } from './query';

const StatusCol = ({ status = {} }) => {
  const { color = 'grey', label = 'N/A' } = status || {};
  return (
    <div className={styles.statusCol}>
      <Space>
        <Radio checked className={styles[`radio-${color}`]} />
        {label}
      </Space>
    </div>
  );
};

const StatusColSkeleton = () => (
  <div className={styles.statusCol}>
    <Space>
      <Radio checked className={styles['radio-grey']} />
      <Skeleton
        className={styles.statusColSkeleton}
        style={{
          marginBottom: 0,
        }}
        title={false}
        paragraph={{
          rows: 1,
          width: 100,
        }}
        active
      />
    </Space>
  </div>
);

const OperationCol = ({ transaction }) => {
  if (!transaction) {
    return <StatusColSkeleton />;
  }
  return <StatusCol status={TRANSACTION_STATUSES[transaction.status]} />;
};

const RiskAssessmentCol = ({ transaction }) =>
  transaction ? <StatusCol status={getRiskAssessmentStatus(transaction)} /> : <StatusColSkeleton />;

const AuthenticationCol = ({ transaction }) =>
  transaction ? <StatusCol status={getAuthenticationStatus(transaction)} /> : <StatusColSkeleton />;

const Header = () => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(
    activityLastTransactionQuery,
    {
      variables: {
        orderId,
      },
    },
  );

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(orderLastTransactionQuery, {
    variables: {
      orderId,
    },
    onCompleted: (data) => !data.order && fetchInsights(),
  });

  const paymentMethods = usePaymentMethods();

  // To navigate to Finance page, it should first update searchFilters then navigate
  const navigate = useNavigateWithSearch();
  const [pendingRedirect, setPendingRedirect] = useState(false);
  const { set } = useSearchFilter(Fields.ORDER_ID);
  const redirectToFinance = () => {
    set({ value: [orderId], excluded: false });
    setPendingRedirect(true);
  };
  useEffect(() => {
    if (pendingRedirect) {
      navigate('/brainpower/financial-operations');
    }
  }, [pendingRedirect]);

  const order = orderStack ?? orderInsights;

  const transaction = order?.last_transaction;

  return (
    <div className={styles.root}>
      <Row align="middle">
        <Col flex="360px">
          <Space size="large">
            {transaction ? (
              <img
                src={paymentMethods[transaction.payment_method_name]?.imgUrl}
                style={{ height: 40 }}
                alt={`img-${transaction.payment_method_name}`}
              />
            ) : (
              <Skeleton.Button
                style={{
                  height: 40,
                  width: 62.5,
                }}
                active
              />
            )}
            <div>
              <div className={styles.orderId}>
                {transaction ? (
                  <TooltipWithCopyBtn value={transaction.order_id} trunc={false} />
                ) : (
                  <Skeleton
                    title={false}
                    paragraph={{
                      rows: 1,
                      width: 221,
                    }}
                    active
                  />
                )}
              </div>
              <div className={styles.amount}>
                {transaction ? (
                  transaction.amount.toLocaleString('fr-FR', {
                    style: 'currency',
                    currency: transaction.currency,
                  })
                ) : (
                  <Skeleton
                    className={styles.amountSkeleton}
                    title={false}
                    paragraph={{
                      rows: 1,
                      width: 70,
                    }}
                    active
                  />
                )}
              </div>
            </div>
          </Space>
        </Col>
        <Col flex="fill" align="center" style={{ margin: 'auto' }}>
          <OperationCol transaction={transaction} />
          <RiskAssessmentCol transaction={transaction} />
          <AuthenticationCol transaction={transaction} />
        </Col>
        <Col>
          <Button type="link" icon={<ArrowRightOutlined />} onClick={redirectToFinance}>
            FINANCE
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Header;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, DatePicker, Menu, Row, Space, Tabs, Tag, TimePicker } from 'antd';
import { LineOutlined, SwapRightOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { useCounter } from 'react-use';
import useDebounce from '../../../../../../hooks/useDebounce';
import useBlur from '../../../../../../hooks/useBlur';
import { DATE_FORMAT, DATETIME2_FORMAT } from '../../../../../../constants/DATES';
import periods from '../../../../../../constants/periods';
import styles from './FormItem.module.scss';

const Tab = {
  ABSOLUTE: 'absolute',
  RELATIVE: 'relative',
  NULL: 'null',
};

const TIME_FORMAT = 'HH:mm';

const HourMinutePicker = ({ value, onChange, disabled }) => (
  <TimePicker
    value={value}
    onChange={(v) => {
      onChange(moment(value).set({
        hour: v.get('hour'),
        minute: v.get('minute'),
      }));
    }}
    popupClassName={styles.timePicker}
    format="HH:mm"
    minuteStep={10}
    getPopupContainer={(triggerNode) => triggerNode.parentElement}
    disabled={disabled}
    allowClear={false}
  />
);

const momentToTime = (date) => {
  const [hour, minute] = date.format(TIME_FORMAT).split(':');
  return { hour, minute };
};

// { from, to, period }
const FormItemDate = ({ field, value, onChange }) => {
  const from = useMemo(() => (value.period ? periods()[value.period].from : value.from), [value]);
  const to = useMemo(() => (value.period ? periods()[value.period].to : value.to), [value]);

  const fromDate = useMemo(() => (from ? moment(from, DATETIME2_FORMAT) : null), [from]);
  const toDate = useMemo(() => (to ? moment(to, DATETIME2_FORMAT) : null), [to]);

  // { hour, minute }
  const [fromTime, setFromTime] = useState(from ? momentToTime(moment(from, DATETIME2_FORMAT)) : null);
  const [toTime, setToTime] = useState(to ? momentToTime(moment(to, DATETIME2_FORMAT)) : null);

  const wrapperRef = useRef(null);
  const [tab, setTab] = useState(() => {
    if (value === '__NULL__') return Tab.NULL;
    return value.period ? Tab.RELATIVE : Tab.ABSOLUTE;
  }); // absolute | relative | null
  const [count, { inc }] = useCounter(0);

  const focus = () => {
    const firstInput = document.getElementById(`date-range-picker-${field.key}`);
    firstInput.focus();
  };

  // focus on first input each 2 clicks
  useEffect(() => {
    if (count % 2 === 0) focus();
  }, [count]);

  // controlled open state, close on blur
  const [open, setOpen] = useState(false);
  const debounceOpen = useDebounce(open, 0);
  const handleOpenChange = (_open) => {
    if (_open) setOpen(_open);
  };
  const handleBlur = useCallback(() => debounceOpen && setOpen(false), [debounceOpen, setOpen]);
  useBlur(wrapperRef, handleBlur);

  const handleChangeDateRange = (v) => {
    inc();
    onChange({
      from: v[0].set(fromTime).format(DATETIME2_FORMAT),
      to: v[1].set(toTime).format(DATETIME2_FORMAT),
      period: undefined,
    });
  };

  const handleChangeFromTime = (v) => {
    setFromTime(momentToTime(v));
    onChange({
      to,
      from: v.format(DATETIME2_FORMAT),
      period: undefined,
    });
  };

  const handleChangeToTime = (v) => {
    setToTime(momentToTime(v));
    onChange({
      from,
      to: v.format(DATETIME2_FORMAT),
      period: undefined,
    });
  };

  const handleChangePeriod = ({ key }) => {
    setFromTime(momentToTime(periods()[key].from));
    setToTime(momentToTime(periods()[key].to));
    onChange({ period: key });
    setOpen(false);
  };

  const handleChangeTab = (newTab) => {
    setTab(newTab);
    if (newTab === Tab.NULL) {
      onChange('__NULL__');
      // setOpen(false);
    }
  };

  const panelRender = (originalPanel) => (
    <div ref={wrapperRef} key="custom-panel" className={styles.panel}>
      <Space>
        <DatePicker format={DATE_FORMAT} value={fromDate} disabled />
        <SwapRightOutlined className={styles.lightgrey} />
        <DatePicker format={DATE_FORMAT} value={toDate} disabled />
      </Space>
      <Space>
        <HourMinutePicker
          id="from-time-picker"
          value={fromDate}
          onChange={handleChangeFromTime}
          disabled={tab !== Tab.ABSOLUTE}
        />
        <LineOutlined className={styles.lightgrey} rotate={90} />
        <HourMinutePicker
          id="to-time-picker"
          value={toDate}
          onChange={handleChangeToTime}
          disabled={tab !== Tab.ABSOLUTE}
        />
      </Space>
      <Tabs
        defaultActiveKey={Tab.RELATIVE}
        size="small"
        centered
        activeKey={tab}
        onChange={handleChangeTab}
        items={[
          {
            key: Tab.RELATIVE,
            label: 'Relative period',
            children: (
              <Menu
                className={styles.periodsMenu}
                theme="light"
                selectedKeys={[value.period]}
                onClick={handleChangePeriod}
                items={Object.values(periods()).map((p) => ({
                  key: p.value,
                  label: (
                    <Row id={`period-${p.value}`} justify="space-between">
                      <Col>{p.label}</Col>
                      <Col>
                        <div className={styles.periodDescription}>{p.description?.join(' ')}</div>
                      </Col>
                    </Row>
                  ),
                }))}
              />
            ),
          },
          {
            key: Tab.ABSOLUTE,
            label: 'Absolute period',
            children: <div className={styles.absolutePanel}>{originalPanel}</div>,
          },
          {
            key: Tab.NULL,
            label: <Tag>Null</Tag>,
            children: (
              <div>
                Filter on <Tag>Null</Tag> or undefined values.
              </div>
            ),
          },
        ]}
      />
    </div>
  );

  return (
    <DatePicker.RangePicker
      id={`date-range-picker-${field.key}`}
      popupClassName={styles.datePickerPopup}
      allowClear={false}
      value={[fromDate, toDate]}
      placeholder={value === '__NULL__' ? ['Null'] : undefined}
      format={DATETIME2_FORMAT}
      onOpenChange={handleOpenChange}
      open={open}
      inputReadOnly
      destroyPopupOnHide
      onChange={handleChangeDateRange}
      disabled={[false, !open]}
      panelRender={panelRender}
    />
  );
};

export default FormItemDate;

import {gql} from '@apollo/client';

export const CompanyThemeQuery = gql`
  query CompanyThemeQuery($companyId: ID!) {
    merchantCompany(id: $companyId) {
      id
      programManager {
        id
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
      theme {
        primaryColor
        secondaryColor
      }
    }
  }
`;
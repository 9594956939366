import React from 'react';
import { Tag } from 'antd';

const MonthDaySelect = ({ value = [], onChange }) => {
  const handleChange = (day) => () => onChange(value.includes(day) ? value.filter((d) => d !== day) : [...value, day]);
  return (
    <div style={{ width: 260, padding: 8 }}>
      {new Array(31).fill(null).map((_, index) => (
        <Tag.CheckableTag
          key={`day-${index + 1}`}
          checked={value.includes(index + 1)}
          onChange={handleChange(index + 1)}
          style={{
            borderRadius: 13,
            fontSize: '12px',
            width: 26,
            height: 26,
            padding: '3px 0',
            textAlign: 'center',
          }}
        >
          {index + 1}
        </Tag.CheckableTag>
      ))}
    </div>
  );
};

export default MonthDaySelect;

import React from 'react';
import { Avatar, Button, Popconfirm } from 'antd';
import ShareVariantOutlineIcon from '@2fd/ant-design-icons/lib/ShareVariantOutline';
import ContentDuplicateIcon from '@2fd/ant-design-icons/lib/ContentDuplicate';
import CogIcon from '@2fd/ant-design-icons/lib/Cog';
import { DeleteOutlined } from '@ant-design/icons';
import EyeIcon from '@2fd/ant-design-icons/lib/Eye';
import { initials } from '../../../../../util/string';
import DrawerListItem from '../../Common/DrawerListItem/DrawerListItem';

const DashboardListItem = ({
  dashboard,
  isActive,
  isFavorite,
  onClick,
  editable,
  onEdit,
  duplicable,
  onDuplicate,
  removable,
  onRemove,
  shareable,
  onShare,
}) => (
  <DrawerListItem
    key={dashboard.id}
    onClick={onClick}
    label={dashboard.name}
    extra={[
      shareable && (
        <Button key="share" type="text" icon={<ShareVariantOutlineIcon />} title="Share dashboard" onClick={onShare} />
      ),
      duplicable && (
        <Button
          key="duplicate"
          type="text"
          icon={<ContentDuplicateIcon />}
          title="Duplicate dashboard"
          onClick={onDuplicate}
        />
      ),
      removable && (
        <Popconfirm
          key="remove-confirm"
          overlayStyle={{ width: 200 }}
          title="Delete the dashboard"
          onConfirm={onRemove}
          okText="Yes"
          cancelText="No"
        >
          <Button key="remove" type="text" icon={<DeleteOutlined />} title="Delete dashboard" />
        </Popconfirm>
      ),
      dashboard.createdBy && (
        <Avatar
          key="author-avatar"
          src={dashboard.createdBy.avatar}
          size="small"
          title={`shared by ${dashboard.createdBy.full_name}`}
        >
          {initials(dashboard.createdBy.full_name)}
        </Avatar>
      ),
      editable ? (
        <Button key="edit" type="text" icon={<CogIcon />} title="Edit settings" onClick={onEdit} />
      ) : (
        <Button key="view" type="text" icon={<EyeIcon />} title="View settings" onClick={onEdit} />
      ),
    ]}
    active={isActive}
    pinned={isFavorite}
  />
);

export default DashboardListItem;

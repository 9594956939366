import { gql } from '@apollo/client';

export const requestBodyQuery = gql`
  query RequestBody($notificationId: ID!) {
    notification(id: $notificationId) {
      _id
        payload
    }
  }
`;

import React from 'react';
import DomainIcon from '@2fd/ant-design-icons/lib/Domain';
import ViewCarouselIcon from '@2fd/ant-design-icons/lib/ViewCarousel';
import AccountMultipleIcon from '@2fd/ant-design-icons/lib/AccountMultiple';
import { SafetyCertificateOutlined } from '@ant-design/icons';
import ViewListOutlineIcon from '@2fd/ant-design-icons/lib/ViewListOutline';
import GroupIcon from '@2fd/ant-design-icons/lib/Group';

const Merchants = (can) => {
  if (
    can('read', 'company') ||
    can('read', 'merchant_account') ||
    can('read', 'user') ||
    can('read', 'access-control') ||
    can('read', 'log')
  ) {
    return [
      {
        type: 'group',
        label: 'Merchants',
        children: [
          ...(can('read', 'company') ? [{ key: '/companies', icon: <DomainIcon />, label: 'Companies' }] : []),
          ...(can('read', 'merchant_account')
            ? [{ key: '/merchant-accounts', icon: <ViewCarouselIcon />, label: 'Accounts' }]
            : []),
          ...(can('read', 'merchant-group') ? [{ key: '/merchant-groups', icon: <GroupIcon />, label: 'Groups' }] : []),
          ...(can('read', 'user') ? [{ key: '/users', icon: <AccountMultipleIcon />, label: 'Users' }] : []),
          ...(can('read', 'access-control')
            ? [{ key: '/access-control-list', icon: <SafetyCertificateOutlined />, label: 'Access rights' }]
            : []),
          ...(can('read', 'log') ? [{ key: '/logs', icon: <ViewListOutlineIcon />, label: 'Logs' }] : []),
        ],
      },
    ];
  }
  return [];
};

export default Merchants;

import React, { useMemo, useState } from 'react';
import { Avatar, Button, Checkbox, Col, Row, Space, Table, Typography } from 'antd';
import { useMutation } from '@apollo/client';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import { useCan } from '../../../../../../contexts/ability.context';
import { search } from '../../../../../../util/string';
import { Link } from '../../../../../../util/navigate';
import tileStyles from '../../../../Brainpower/OrderDetail/Tile/Tile.module.scss';
import { MerchantGroupContentUpdateMutation } from './query';
import styles from './GroupContent.module.scss';
import SearchInput from '../../../../Common/ListFilter/SearchInput';
import TargetEntities, { TargetEntity } from '../../../../Brainpower/Common/constants/targetEntities';
import useStatePagination from '../../../../Brainpower/hooks/useStatePagination';
import useToggle from '../../../../../../hooks/useToggle';

const GroupContent = ({ merchantGroup }) => {
  const can = useCan();

  const [onlySelected, { toggle }] = useToggle(true);

  const [selectedRowKeys, setSelectedRowKeys] = useState([
    ...merchantGroup.merchantCompanies.map((mc) => mc.id),
    ...merchantGroup.merchantAccounts.map((ma) => ma.id),
  ]);

  const [update] = useMutation(MerchantGroupContentUpdateMutation);
  const updateGroup = (value) => {
    const merchantCompanies = value.filter((row) => row.type === 'MerchantCompany').map((row) => row.id);
    const merchantAccounts = value.filter((row) => row.type === 'MerchantAccount').map((row) => row.id);

    update({
      variables: {
        id: merchantGroup.id,
        merchantCompanies,
        merchantAccounts,
      },
      optimisticResponse: {
        updateMerchantGroupContent: {
          id: merchantGroup.id,
          merchantCompanies,
          merchantAccounts,
        },
      },
      context: {
        debounceKey: `updateGroupContent-${merchantGroup.id}`,
        debounceTimeout: 500,
      },
    });
  };

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    selectedRowKeys,
    renderCell: (checked, row, index, originNode) =>
      selectedRowKeys.includes(row.merchantCompanyId) ? <Checkbox checked disabled /> : originNode,
    getCheckboxProps: () => ({
      disabled: !can('update', 'merchant-group'),
    }),
    onChange: (_selectedRowKeys, _selectedRows) => {
      setSelectedRowKeys(_selectedRowKeys);
      updateGroup(_selectedRows);
    },
    preserveSelectedRowKeys: true,
    checkStrictly: true,
  };

  const { page, pageSize, setPage } = useStatePagination(20);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const expandRow = (rowKey) => setExpandedRowKeys([...expandedRowKeys, rowKey]);
  const collapseRow = (rowKey) => setExpandedRowKeys(expandedRowKeys.filter((key) => key !== rowKey));

  // build dataSource & numbers from data
  const {
    dataSource,
    totalAuthorizedMerchantCompanies,
    totalMerchantCompanies,
    totalAuthorizedMerchantAccounts,
    totalMerchantAccounts,
  } = useMemo(() => {
    if (!merchantGroup)
      return {
        dataSource: [],
        totalAuthorizedMerchantCompanies: null,
        totalMerchantCompanies: null,
        totalAuthorizedMerchantAccounts: null,
        totalMerchantAccounts: null,
      };

    const { programManager, merchantCompanies, merchantAccounts } = merchantGroup;

    const authorizedMerchantCompanyIdList = merchantCompanies.map((mc) => mc.id);
    const authorizedMerchantAccountIdList = merchantAccounts.map((ma) => ma.id);

    const res = programManager.merchantCompanies.map((mc) => {
      const children = mc.merchantAccounts.map((ma) => ({
        type: 'MerchantAccount',
        id: ma.id,
        merchantAccount: ma.name,
        merchantCompanyId: mc.id,
        authorized: authorizedMerchantAccountIdList.includes(ma.id) || authorizedMerchantCompanyIdList.includes(mc.id),
      }));

      return {
        type: 'MerchantCompany',
        id: mc.id,
        merchantCompany: mc.name,
        theme: mc.theme,
        children,
        authorized: authorizedMerchantCompanyIdList.includes(mc.id),
        authorizedMerchantAccounts: children.filter((child) => child.authorized).length,
        totalMerchantAccounts: children.length,
      };
    });

    return {
      dataSource: res,
      totalAuthorizedMerchantCompanies: res.filter((mc) => mc.authorized).length,
      totalMerchantCompanies: res.length,
      totalAuthorizedMerchantAccounts: res.reduce((memo, mc) => memo + mc.authorizedMerchantAccounts, 0),
      totalMerchantAccounts: res.reduce((memo, mc) => memo + mc.totalMerchantAccounts, 0),
    };
  }, [merchantGroup]);

  // QuickSearch on MerchantCompany & MerchantAccount
  const [quickSearch, setQuickSearch] = useState();

  // Filter dataSource by quickSearch && mode edit/read
  const filteredDataSource = useMemo(
    () =>
      dataSource
        .map((mc) => ({
          ...mc,
          children: mc.children.filter(
            (ma) =>
              (search(quickSearch, ma.id) ||
                search(quickSearch, ma.merchantAccount) ||
                search(quickSearch, mc.id) ||
                search(quickSearch, mc.merchantCompany)) &&
              (!onlySelected || ma.authorized),
          ),
        }))
        .filter(
          (mc) =>
            (mc.children.length > 0 || search(quickSearch, mc.id) || search(quickSearch, mc.merchantCompany)) &&
            (!onlySelected || mc.authorized || mc.authorizedMerchantAccounts > 0),
        ),
    [dataSource, quickSearch, onlySelected],
  );

  const columns = [
    {
      key: 'expand',
      render: (_, row) => {
        switch (row.type) {
          case 'MerchantCompany':
            if (expandedRowKeys.includes(row.id)) {
              return (
                <Button type="text" shape="circle" icon={<CaretDownOutlined />} onClick={() => collapseRow(row.id)} />
              );
            }
            return (
              <Button
                type="text"
                shape="circle"
                icon={<CaretRightOutlined />}
                onClick={() => expandRow(row.id)}
                disabled={row.children.length === 0}
              />
            );
          case 'MerchantAccount':
          default:
            return '';
        }
      },
      width: 40,
    },
    {
      dataIndex: 'merchantCompany',
      key: 'merchantCompany',
      render: (value, row) =>
        value && (
          <Link to={`/companies/${row.id}`}>
            <Space>
              <Avatar
                src={row.theme.icon}
                shape="circle"
                size={28}
                title={value}
                style={{ backgroundColor: row.theme.primaryColor }}
              >
                {value?.[0]?.toUpperCase() ?? '?'}
              </Avatar>
              {value}
            </Space>
          </Link>
        ),
      ...getColumnFilterSearchInput((value, record) => search(value, record.merchantCompany)),
      sorter: (a, b) => (a.merchantCompany?.toLowerCase() > b.merchantCompany?.toLowerCase() ? 1 : -1),
      defaultSortOrder: 'ascend',
      width: 280,
    },
    {
      dataIndex: 'merchantAccount',
      key: 'merchantAccount',
      render: (value, row) => {
        switch (row.type) {
          case 'MerchantCompany':
            return (
              <Button
                type="primary"
                size="small"
                style={{
                  border: 'none',
                  backgroundColor: 'var(--ant-primary-color-active-deprecated-d-02)',
                  color: 'var(--ant-primary-color)',
                }}
                icon={TargetEntities[TargetEntity.MERCHANT_ACCOUNT].icon()}
              >
                {`${row.authorizedMerchantAccounts} / ${row.totalMerchantAccounts}`}
              </Button>
            );
          case 'MerchantAccount':
            return <Link to={`/merchant-accounts/${row.id}`}>{value}</Link>;
          default:
            return '';
        }
      },
      ...getColumnFilterSearchInput((value, record) => search(value, record.merchantAccount)),
      sorter: (a, b) => (a.merchantAccount?.toLowerCase() > b.merchantAccount?.toLowerCase() ? 1 : -1),
      defaultSortOrder: 'ascend',
      width: 280,
    },
    Table.SELECTION_COLUMN,
  ];

  return (
    <div className={tileStyles.root} id="tile-mg-content">
      <Row justify="space-between" align="middle" gutter={8}>
        <Col flex={1} className={tileStyles.title}>
          Group companies & accounts
        </Col>
      </Row>
      <Typography.Paragraph>Select a company to includes all company&apos;s merchant accounts</Typography.Paragraph>
      <div>
        <Table
          title={() => (
            <Row justify="space-between">
              <Col>
                <Space size={48}>
                  <SearchInput
                    onChange={(e) => {
                      setQuickSearch(e.target.value);
                      setPage(1);
                    }}
                    placeholder="Search by ID or name"
                  />
                  <Checkbox checked={onlySelected} onClick={toggle}>
                    Only selected
                  </Checkbox>
                </Space>
              </Col>
              <Col>
                <Space>
                  <Button
                    type="primary"
                    size="small"
                    style={{
                      border: 'none',
                      backgroundColor: 'var(--ant-primary-color-active-deprecated-d-02)',
                      color: 'var(--ant-primary-color)',
                    }}
                    icon={TargetEntities[TargetEntity.MERCHANT_COMPANY].icon()}
                    title={`${totalAuthorizedMerchantCompanies} companies selected`}
                  >
                    {`${totalAuthorizedMerchantCompanies} / ${totalMerchantCompanies}`}
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    style={{
                      border: 'none',
                      backgroundColor: 'var(--ant-primary-color-active-deprecated-d-02)',
                      color: 'var(--ant-primary-color)',
                    }}
                    icon={TargetEntities[TargetEntity.MERCHANT_ACCOUNT].icon()}
                    title={`${totalAuthorizedMerchantAccounts} merchant accounts selected`}
                  >
                    {`${totalAuthorizedMerchantAccounts} / ${totalMerchantAccounts}`}
                  </Button>
                </Space>
              </Col>
            </Row>
          )}
          columns={columns}
          dataSource={filteredDataSource}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 20,
            position: 'bottomRight',
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} companies`,
            onChange: setPage,
            current: page,
            pageSize,
            total: filteredDataSource.length,
          }}
          expandable={{
            expandedRowKeys,
            showExpandColumn: false,
          }}
          indentSize={60}
          showHeader={false}
          bordered={false}
          rowSelection={rowSelection}
          rowClassName={(row) => (row.children ? styles.parentRow : styles.childRow)}
          rowKey="id"
          size="small"
        />
      </div>
    </div>
  );
};

export default GroupContent;

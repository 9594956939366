import TransactionStatusList from 'norbr-shared-lib/constants/transaction/status/list';
import _ from 'norbr-shared-lib/constants/transaction/status/enum';
import ShieldCheckIcon from '@2fd/ant-design-icons/lib/ShieldCheck';
import CheckCircleIcon from '@2fd/ant-design-icons/lib/CheckCircle';
import PlusCircleIcon from '@2fd/ant-design-icons/lib/PlusCircle';
import ArrowDownBoldBoxIcon from '@2fd/ant-design-icons/lib/ArrowDownBoldBox';
import ArrowLeftBoldBoxIcon from '@2fd/ant-design-icons/lib/ArrowLeftBoldBox';
import CancelIcon from '@2fd/ant-design-icons/lib/Cancel';
import CheckUnderlineCircleIcon from '@2fd/ant-design-icons/lib/CheckUnderlineCircle';
import BackspaceIcon from '@2fd/ant-design-icons/lib/Backspace';
import CheckDecagramIcon from '@2fd/ant-design-icons/lib/CheckDecagram';
import CloseCircleIcon from '@2fd/ant-design-icons/lib/CloseCircle';
import MinusCircleIcon from '@2fd/ant-design-icons/lib/MinusCircle';
import { compareByProp } from '../util/array';

// use only Tag predefined colors
export const TAGS = {
  ok: {
    id: 'ok',
    label: 'OK',
    color: 'green',
  },
  ko: {
    id: 'ko',
    label: 'KO',
    color: 'red',
  },
  pending: {
    id: 'pending',
    label: 'Pending',
    color: 'orange',
  },
  expired: {
    id: 'expired',
    label: 'Expired',
    color: 'red',
  },
};

const statusTypes = {
  authentication: { label: 'Authentication', icon: CheckCircleIcon },
  authorization: { label: 'Authorization', icon: CheckUnderlineCircleIcon },
  authorization_cancel: { label: 'Authorization cancel', icon: CloseCircleIcon },
  capture: { label: 'Capture', icon: ArrowDownBoldBoxIcon },
  charged_back: { label: 'Charged back', icon: BackspaceIcon },
  created: { label: 'Created', icon: PlusCircleIcon },
  credit: { label: 'Credit', icon: ArrowLeftBoldBoxIcon },
  paid: { label: 'Paid', icon: CheckDecagramIcon },
  refund: { label: 'Refund', icon: ArrowLeftBoldBoxIcon },
  risk_assessment: { label: 'Risk assessment', icon: ShieldCheckIcon },
  blocked: { label: 'Blocked', icon: CancelIcon },
  routing: { label: 'Routing', icon: MinusCircleIcon },
};

const statusResults = {
  attempted: { label: 'Attempted', color: 'grey', tag: TAGS.ok },
  declined: { label: 'Declined', color: 'orange', tag: TAGS.ko },
  request_expired: { label: 'Request expired', color: 'red', tag: TAGS.expired },
  request_failed: { label: 'Request failed', color: 'red', tag: TAGS.ko },
  requested: { label: 'Requested', color: 'grey', tag: TAGS.pending },
  successful: { label: 'Successful', color: 'green', tag: TAGS.ok },
  expired: { label: 'Expired', color: 'red', tag: TAGS.expired },
  failed: { label: 'Failed', color: 'red', tag: TAGS.ko },
  renewed: { label: 'Renewed', color: 'green', tag: TAGS.ok },
  charged_back: { label: 'Charged back', color: 'saddlebrown', tag: TAGS.ok },
  created: { label: 'Created', color: 'grey', tag: TAGS.ok },
  paid: { label: 'Paid', color: 'green', tag: TAGS.ok },
  approved: { label: 'Approved', color: 'green', tag: TAGS.ok },
  manual_review: { label: 'Manual review', color: 'orange', tag: TAGS.pending },
};

// + color : string
// + icon : Component
// + tag : TAG
const TRANSACTION_STATUSES = TransactionStatusList.reduce(
  (memo, status) => ({
    ...memo,
    [status.id]: {
      value: status.id,
      label: status.label,
      type: status.type,
      icon: statusTypes[status.type].icon,
      color: statusResults[status.result].color,
      tag: statusResults[status.result].tag,
    },
  }),
  {},
);

export const TRANSACTION_STATUS_LIST = Object.values(TRANSACTION_STATUSES).sort(compareByProp('label'));

export const getAuthenticationStatus = (transaction) =>
  TRANSACTION_STATUSES[
    {
      success: _.AUTHENTICATION_SUCCESSFUL,
      accepted: _.AUTHENTICATION_SUCCESSFUL,
      pending: _.AUTHENTICATION_REQUESTED,
      attempted: _.AUTHENTICATION_ATTEMPTED,
      failure: _.AUTHENTICATION_REQUEST_FAILED,
      declined: _.AUTHENTICATION_DECLINED,
      not_available: _.AUTHENTICATION_REQUEST_FAILED,
      unavailable: _.AUTHENTICATION_REQUEST_FAILED,
      manual_review: _.AUTHENTICATION_REQUESTED,
      expired: _.AUTHENTICATION_REQUEST_EXPIRED,
    }[transaction.authentication_result]
  ];

export const getRiskAssessmentStatus = (transaction) =>
  TRANSACTION_STATUSES[
    {
      accepted: _.RISK_ASSESSMENT_APPROVED,
      denied: _.RISK_ASSESSMENT_DECLINED,
      manual_review: _.RISK_ASSESSMENT_MANUAL_REVIEW,
      pending: _.RISK_ASSESSMENT_REQUESTED,
    }[transaction.risk_assessment_result]
  ];

export default TRANSACTION_STATUSES;

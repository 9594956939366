import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb as AntBreadcrumb } from 'antd';

// map sample
// const breadcrumbNameMap = {
//   '/': <HomeOutlined />,
//   '/acl': 'Access Control',
// };

const Breadcrumb = ({ map }) => (
  <AntBreadcrumb className="norbr-admin-breadcrumb">
    {Object.keys(map).map((url) => (
      <AntBreadcrumb.Item key={url}>
        <Link to={url}>{map[url]}</Link>
      </AntBreadcrumb.Item>
    ))}
  </AntBreadcrumb>
);

export default Breadcrumb;

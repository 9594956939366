import React, { useMemo } from 'react';
import Item from '../../../../Common/SiderList/Item';
import { config as WidgetTypes } from '../../constants/widgetTypes';
import { useDashboard, useSelectedWidgetId } from '../../dashboard.context';

const WidgetItem = (props) => {
  const { widgetId } = props;

  const { dashboard, editable, removeWidget } = useDashboard();

  const widget = dashboard.widgets[widgetId];

  const [, setSelectedWidgetId] = useSelectedWidgetId();

  const widgetType = useMemo(() => {
    if (widget) {
      if (WidgetTypes[widget.type]) {
        return WidgetTypes[widget.type];
      }
      throw new Error(`Widget type ${widget.type} does not exist. (${widgetId})`);
    }
    return null;
  }, [widget]);

  const handleClick = () => setSelectedWidgetId(widgetId);

  if (!widget) {
    return (
      <Item
        id={widgetId}
        isRemovable={editable}
        onRemove={(_, e) => {
          e.stopPropagation();
          removeWidget(widgetId);
        }}
        type="rounded"
      />
    );
  }

  return (
    <Item
      id={widgetId}
      label={widget.name}
      title={widget.name}
      icon={widgetType.icon}
      onClick={handleClick}
      isRemovable={editable}
      onRemove={(_, e) => {
        e.stopPropagation();
        removeWidget(widgetId);
      }}
      type="rounded"
    />
  );
};

export default WidgetItem;

import React from 'react';
import { Button, Col, Row, Space, Tag, Typography } from 'antd';
import classnames from 'classnames';
import InformationIcon from '@2fd/ant-design-icons/lib/Information';
import PlusIcon from '@2fd/ant-design-icons/lib/Plus';
import { CodeSandboxOutlined, FunctionOutlined } from '@ant-design/icons';
import FieldTypes from '../../constants/fieldTypes';
import targetEntities from '../../constants/targetEntities';
import styles from './Item.module.scss';
import { DataRoles } from '../../../Matcher/MatcherCashier/fields';

const AddableItem = ({ field, onAdd, open, toggleOpen }) => {
  const handleClick = () => onAdd(field.key);
  return (
    <div className={classnames(styles.addableItem, { [styles.open]: open })}>
      <Row align="middle">
        {field.dataRole === DataRoles.INDICATOR && (
          <Col flex="none" style={{ padding: '0 8px' }}>
            <FunctionOutlined style={{ fontSize: '1em' }} title="Indicator" />
          </Col>
        )}
        {field.dataRole === DataRoles.DIMENSION && (
          <Col flex="none" style={{ padding: '0 8px' }}>
            <CodeSandboxOutlined style={{ fontSize: '1em' }} title="Dimension" />
          </Col>
        )}
        {!field.dataRole && field.entity && (
          <Col flex="none" style={{ padding: '0 8px' }}>
            {targetEntities[field.entity]?.icon({ style: { fontSize: '1em' } })}
          </Col>
        )}
        <Col flex="auto">
          <div className={styles.labelInput}>{field.label}</div>
        </Col>
        <Col flex="none">
          <Button
            type="text"
            shape="circle"
            size="large"
            icon={<InformationIcon />}
            onClick={(e) => toggleOpen(field.key, e)}
          />
        </Col>
        <Col flex="none">
          <Button type="text" shape="circle" size="large" icon={<PlusIcon />} onClick={handleClick} />
        </Col>
      </Row>
      <div className={styles.content}>
        <Space direction="vertical" size={0}>
          <Tag icon={targetEntities[field.entity]?.icon()}>{field.label}</Tag>
          <Typography.Text>{field.description ?? 'Missing field description'}</Typography.Text>
          {[FieldTypes.LIST, FieldTypes.ASYNC_LIST].includes(field.type) && !field.displayOptions?.asId && (
            <Typography.Text>If you want to display the name, please activate the text mode.</Typography.Text>
          )}
          <Typography.Text disabled>
            {[...(field.entity ? [`Source : ${field.entity}`] : []), ...(field.key ? [`Key : ${field.key}`] : [])].join(
              ' | ',
            )}
          </Typography.Text>
        </Space>
      </div>
    </div>
  );
};

export default AddableItem;

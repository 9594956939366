import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, List, Tag, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useQuery } from '@apollo/client';
import { DeleteOutlined, EditOutlined, EyeOutlined, HomeOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { useCan } from '../../../../../contexts/ability.context';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { InternalProfileListQuery, useInternalProfileDeleteMutation } from '../query';
import InternalAccessControlCreate from '../InternalAccessControlCreate/InternalAccessControlCreate';

const breadcrumbRender = () => (
  <Breadcrumb
    map={{
      '/': <HomeOutlined />,
      '/internal-access-control-list': 'Internal Access Control',
    }}
  />
);

const InternalAccessControlList = () => {
  const can = useCan();

  const { data, loading } = useQuery(InternalProfileListQuery);

  const [deleteProfile] = useInternalProfileDeleteMutation();

  const [visible, setVisible] = useState(false);

  const showCreationModal = () => {
    setVisible(true);
  };

  const hideCreationModal = () => {
    setVisible(false);
  };

  const handleDelete =
    ({ id, name }) =>
    () => {
      deleteProfile({ id, name });
    };

  return (
    <PageHeader
      title="Internal Access Control List"
      breadcrumbRender={breadcrumbRender}
      extra={[
        can('create', 'internal-access-control') && (
          <Button key="create" icon={<PlusOutlined />} onClick={showCreationModal}>
            Create profile
          </Button>
        ),
        <Link key="overview" to="/internal-access-control-list/overview">
          <Button icon={<EyeOutlined />}>Overview</Button>
        </Link>,
      ]}
    >
      <Typography.Paragraph>Profiles, roles and privileges defining internal users rights.</Typography.Paragraph>
      <List
        itemLayout="horizontal"
        dataSource={data?.internalAclProfiles}
        loading={loading}
        bordered
        renderItem={(item) => (
          <List.Item
            actions={[
              <Tag key="useCount" icon={<UserOutlined />} title={`Used by ${item.useCount} users`}>
                {item.useCount}
              </Tag>,
              <Link key="edit" to={`/internal-access-control-list/${item.id}`}>
                <Button type="text" shape="circle" icon={<EditOutlined />} title="Edit profile" />
              </Link>,
              <Button
                key="delete"
                type="text"
                shape="circle"
                icon={<DeleteOutlined />}
                title={item.useCount > 0 ? 'Cannot delete used profile' : 'Delete profile'}
                disabled={item.useCount > 0}
                onClick={handleDelete(item)}
              />,
            ]}
          >
            <List.Item.Meta
              title={<Link to={`/internal-access-control-list/${item.id}`}>{item.name}</Link>}
              description={item.description || item.name}
            />
          </List.Item>
        )}
      />
      <InternalAccessControlCreate visible={visible} onCancel={hideCreationModal} />
    </PageHeader>
  );
};

export default InternalAccessControlList;

import React from 'react';
import {MODES, useMode} from '../../../../../../contexts/mode.context';
import SelectAnnouncementRecipientsAsInternal
  from './SelectAnnouncementRecipientsAsInternal/SelectAnnouncementRecipientsAsInternal';
import SelectAnnouncementRecipientsAsProgramManager
  from './SelectAnnouncementRecipientsAsProgramManager/SelectAnnouncementRecipientsAsProgramManager';
import SelectAnnouncementRecipientsAsMetaProgramManager
  from './SelectAnnouncementRecipientsAsMetaProgramManager/SelectAnnouncementRecipientsAsMetaProgramManager';

const StepSelectAnnouncementRecipients = (props) => {
  const mode = useMode();
  if (mode === MODES.INTERNAL) {
    return <SelectAnnouncementRecipientsAsInternal {...props} />
  }
  if (mode === MODES.META_PM) {
    return <SelectAnnouncementRecipientsAsMetaProgramManager {...props} />
  }
  if (mode === MODES.PM) {
    return <SelectAnnouncementRecipientsAsProgramManager {...props} />
  }
  return <></>;
};
export default StepSelectAnnouncementRecipients;

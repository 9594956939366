import { gql } from '@apollo/client';

export const programManagerThemeQuery = gql`
  query ProgramManagerTheme($id: ID!) {
    programManager(id: $id) {
      id
      theme {
        favicon
        logo
        icon
        background
        loader {
          img
          type
          animation
        }
        primaryColor
        secondaryColor
      }
    }
  }
`;

export const ProgramManagerThemeUpdate = gql`
  mutation ProgramManagerThemeUpdate($id: ID!, $theme: CompanyThemeInput!) {
    updateProgramManagerTheme(id: $id, theme: $theme) {
      id
      theme {
        favicon
        logo
        icon
        loader {
          img
          type
          animation
        }
        primaryColor
        secondaryColor
      }
    }
  }
`;

export const ProgramManagerThemeImgUpload = gql`
  mutation ProgramManagerThemeImgUpload($id: ID!, $type: PmImgType!, $file: Upload) {
    uploadProgramManagerThemeImg(id: $id, type: $type, file: $file) {
      id
      theme {
        favicon
        logo
        icon
        loader {
          img
          type
          animation
        }
        primaryColor
        secondaryColor
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const ProgramManagerUserListQuery = gql`
  query ProgramManagerUserListQuery {
    programManagerUsers {
      id
      email
      full_name
      first_name
      last_name
      avatar
      status
      disabled
      createdAt
      lastConnection
      isGdpr
      aclProfile {
        id
        name
      }
      authorizedMerchantGroups {
        id
        name
      }
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
        metaProgramManager {
          id
          name
          theme {
            icon
            primaryColor
            secondaryColor
          }
        }
      }
    }
  }
`;

export default {};

import React from 'react';
import { useMutation } from '@apollo/client';
import { Button, Drawer, Form, Typography, Select, Progress, Divider } from 'antd';
import { searchByProp } from '../../../../../util/string';
import { useResetFormOnCloseModal } from '../../../Common/Form/utils';
import {
  partnerRouteStatusList,
} from '../constants';
import ImpactedContractsDetail from './ImpactedContractsDetail';
import { PartnerRoutesUpdateMutation } from './query';
import PartnerRouteOptionsForm from "../Common/PartnerRouteOptionsForm/PartnerRouteOptionsForm";

const PartnerRoutesUpdate = ({ visible, onClose, partner, selectedRouteIdList }) => {
  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const [updateRoute, { called, data, loading, error, reset }] = useMutation(PartnerRoutesUpdateMutation);

  const closeAndReset = () => {
    reset();
    onClose();
  };


  const handleFinish = async () => {
    const values = await form.validateFields();
    updateRoute({
      variables: {
        idList: selectedRouteIdList,
        input: {
          status: values.status,
          ...values.options?.reduce((memo, option) => ({ ...memo, [option.key]: option.value }), {}),
        },
      },
    });
  };

  return (
    <Drawer
      title={
        partner ? (
          <span>
            Update {selectedRouteIdList.length} partner routes for {partner.name}
          </span>
        ) : (
          <span>Update {selectedRouteIdList.length} partner routes</span>
        )
      }
      onClose={closeAndReset}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
      destroyOnClose
    >
      <ImpactedContractsDetail selectedRouteIdList={selectedRouteIdList} />
      <Divider />
      <Typography.Paragraph key="info">Fill form and update selected routes.</Typography.Paragraph>
      <Form key="form" form={form} layout="vertical" name="partnerRoutesForm" onFinish={handleFinish}>
        <Form.Item name="status" label="Status">
          <Select
            options={partnerRouteStatusList.filter((s) => s.value !== 'deleted')}
            showSearch
            filterOption={searchByProp('label')}
            allowClear
          />
        </Form.Item>
        <Typography.Paragraph>
          Add options (select <i>inherit</i> to remove routes overridden options)
        </Typography.Paragraph>
        <PartnerRouteOptionsForm
          form={form}
          isUpdate
        />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update {selectedRouteIdList.length} routes
          </Button>
        </Form.Item>
      </Form>
      {called && (
        <div>
          <Progress percent={data ? 100 : 66} status={loading && 'active'} />
          {loading && <Typography.Paragraph>Updating partner routes...</Typography.Paragraph>}
          {error && <Typography.Paragraph>{error.message}</Typography.Paragraph>}
          {data && (
            <Typography.Paragraph>
              Successful update of {selectedRouteIdList.length} partner routes.
            </Typography.Paragraph>
          )}
          {!loading && (
            <Button type="primary" onClick={closeAndReset}>
              Done
            </Button>
          )}
        </div>
      )}
    </Drawer>
  );
};

export default PartnerRoutesUpdate;

import { gql } from '@apollo/client';

export const merchantAccountCredentialsQuery = gql`
  query MerchantAccount($id: ID!) {
    merchantAccount(id: $id) {
      id
      api_keys {
        id
        status
        createdAt
        name
        is_public
      }
    }
  }
`;

export const merchantAccountSecurityMutation = gql`
  mutation UpdateMerchantAccountSecurity($id: ID!, $input: MerchantAccountSecurityInput!) {
    updateMerchantAccountSecurity(id: $id, input: $input) {
      id
      ip_filters
      authorize_ip_adresses
      authorize_ip_ranges
      secret_key
    }
  }
`;

import React, { useState } from 'react';
import moment from 'moment-timezone';
import { useMutation } from '@apollo/client';
import { Alert, Button, Form, InputNumber, Modal, Result, Space, Table, Typography } from 'antd';
import Lottie from 'react-lottie-player';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import validationAnimation from '../../../../../../assets/icons/used/000-validation-green.json';
import styles from '../Maintenance.module.scss';
import { CAPTURE_ORDER_MUTATION } from './query';

const CaptureModal = ({ order, onClose }) => {
  const [captureAmount, setCaptureAmount] = useState(order.last_transaction.capturable_amount);

  const [capture, { called, error, loading, data: captureData }] = useMutation(CAPTURE_ORDER_MUTATION, {
    variables: { id: order.id },
  });

  const [form] = Form.useForm();

  const isPartialCaptureAvailable = order.last_transaction.is_partial_capture_available;
  const isMultipleCapturesAvailable = order.last_transaction.is_multiple_captures_available;

  const columns = [
    {
      title: 'Transaction ID',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      render: (value) => value && <div>{moment(value).format('L LT')}</div>,
    },
    {
      title: 'Amount',
      dataIndex: 'captureAmount',
      key: 'captureAmount',
      width: 140,
      align: 'right',
      render: (value, { capturableAmount, currency }) =>
        isPartialCaptureAvailable
          ? [
              <Space>
                {CURRENCIES[currency].symbol}
                <InputNumber
                  className={styles.amountInput}
                  value={value}
                  size="small"
                  stringMode
                  precision={2}
                  min={0}
                  max={capturableAmount}
                  onChange={(val) => setCaptureAmount(parseFloat(val))}
                  onFocus={(event) => event.target.select()}
                />
              </Space>,
              <div className={styles.maxAmountSpan}>
                max {CURRENCIES[currency].symbol} {capturableAmount.toFixed(2)}
              </div>,
            ]
          : `${CURRENCIES[currency].symbol} ${capturableAmount.toFixed(2)}`,
    },
  ];

  const dataSource = [
    {
      transaction_id: order.last_transaction.id,
      date: order.last_transaction.createdAt,
      capturableAmount: order.last_transaction.capturable_amount,
      captureAmount,
      currency: order.currency,
    },
  ];

  const submit = () => {
    capture({
      variables: {
        amount: captureAmount,
      },
    });
  };

  if (called && !loading && !error) {
    return (
      <Modal destroyOnClose visible onCancel={onClose} footer={false}>
        <Result
          icon={
            <Lottie
              loop={false}
              animationData={validationAnimation}
              play
              style={{ width: 150, height: 150, margin: 'auto' }}
              onComplete={() => onClose()}
            />
          }
          status="success"
          title={
            captureData.captureOrder.last_transaction.status === 'capture_successful' ? 'Captured' : 'Capture requested'
          }
          subTitle={
            <Space>
              {CURRENCIES[order.currency].symbol}
              {captureAmount.toFixed(2)}
            </Space>
          }
        />
      </Modal>
    );
  }

  return (
    <Modal
      destroyOnClose
      visible
      onCancel={onClose}
      width={664}
      footer={
        <Space>
          <Button type="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            loading={loading}
            disabled={captureAmount === 0 || !!error}
            onClick={form.submit}
          >
            Capture &nbsp;{CURRENCIES[order.currency].symbol}
            &nbsp;<b>{captureAmount.toFixed(2)}</b>
          </Button>
        </Space>
      }
    >
      <Typography.Title level={1}>Capture</Typography.Title>
      {isPartialCaptureAvailable && !isMultipleCapturesAvailable && (
        <Alert type="warning" showIcon message="This transaction only allows one capture operation." />
      )}
      <Form form={form} layout="vertical" className="tile-form" onFinish={submit} size="large">

        {order.last_transaction.scheduled_capture_date && (
          <>Capture scheduled on : {moment(order.last_transaction.scheduled_capture_date).format('L LT')}</>
        )}
        <Form.Item label={isPartialCaptureAvailable ? 'Enter the amount you want to capture' : ''}>
          <Table
            columns={columns}
            dataSource={dataSource}
            rowClassName="ant-table-row-selected"
            size="middle"
            pagination={false}
            showHeader={false}
          />
        </Form.Item>
      </Form>
      {!!error && <Alert type="error" showIcon message="An error occurred, please try again later." />}
    </Modal>
  );
};

export default CaptureModal;

import React from 'react';
import { Skeleton } from 'antd';
import TableTile from '../../../Tile/Table/Table';

const BasketDetails = () => {
  const columns = ['Product ID', 'Name', 'Quantity', 'Unit price', 'Tax', 'Amount'].map((column, index) => ({
    title: column,
    key: index,
    render: () => (
      <Skeleton.Button
        title={false}
        style={{
          height: 20,
          width: Math.floor(Math.random() * 180 + 40),
        }}
        active
      />
    ),
  }));

  return (
    <TableTile
      name="basket-details"
      title="Basket details"
      columns={columns}
      dataSource={[{ key: 1 }, { key: 2 }]}
      scroll={{ x: 1000 }}
    />
  );
};

export default BasketDetails;

import { gql } from '@apollo/client';

const query = `{
  _id
  checkout {
    _id
    createdAt
    amount
    currency
    customer_email
    customer_id
    type
    expiration_date
  }
}`;

export const checkoutDetailsQuery = gql`
    query CheckoutDetails($orderId: ID!) {
        order(id: $orderId) ${query}
    }
`;

export const activityCheckoutDetailsQuery = gql`
    query ActivityCheckoutDetails($orderId: ID!) {
        activity(id: $orderId) ${query}
    }
`;

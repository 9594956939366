import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Drawer, Form, Input, message, Modal, Radio, Row, Select, Space } from 'antd';
import moment from 'moment-timezone';
import TrashCanIcon from '@2fd/ant-design-icons/lib/TrashCan';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import useNavigateWithSearch from '../../../../../util/navigate';
import Block from '../../../Common/Sider/Block/Block';
import CronScheduleInput from '../../../Common/Form/CronScheduleInput/CronScheduleInput';
import SftpConnectionInput from '../../../Common/Form/SftpConnectionInput/SftpConnectionInput';
import styles from '../../Common/ExportDrawer/ExportDrawer.module.scss';
import { reportFormatList } from '../../ExportList/constants/reportFormats';
import { reportDelimiterList } from '../../ExportList/constants/reportDelimiters';
import SendingMethods, { sendingMethodList } from '../../ExportList/constants/sendingMethods';
import { deleteExportMutation, exportQuery, updateExportMutation } from '../query';

const ConfigurationDrawerContent = ({ onClose }) => {
  const navigate = useNavigateWithSearch();
  const { exportId } = useParams();
  const { data } = useQuery(exportQuery, { variables: { exportId } });

  if (!data) return null;

  const [updateExport, updateExportState] = useMutation(updateExportMutation, {
    variables: { exportId },
    refetchQueries: ['Export', 'ExportList'],
    onCompleted: (data) => {
      if (!data.updateExport) return;
      if (data.updateExport.schedule) {
        message.success('Export have been successfully scheduled.');
      } else {
        message.loading('The generation of the report is running.');
      }
      onClose();
    },
  });

  const [deleteExport, deleteExportState] = useMutation(deleteExportMutation, {
    variables: { exportId },
    refetchQueries: ['ExportList'],
    onCompleted: () => {
      message.success('Export have been successfully deleted.');
      onClose();
      navigate('/brainpower/exports');
    },
  });

  const handleDelete = () => {
    Modal.confirm({
      title: `Delete ${data.export.name}`,
      content: 'Are you sure you want to delete this export?',
      onOk: () => deleteExport(),
    });
  };

  const [form] = Form.useForm();

  const formSchedule = Form.useWatch('schedule', form);
  const formSendingMethod = Form.useWatch('sendingMethod', form);

  const onFinish = (input) => {
    const { format, delimiter, name, description, schedule, sendingMethod, sftpConnection } = input;

    return updateExport({
      variables: {
        input: {
          name,
          description,
          format,
          delimiter,
          sendingMethod,
          sftpConnection,
          schedule,
          timezone: moment.tz.guess(true),
        },
      },
    });
  };

  const initialValues = {
    format: data.export.format,
    delimiter: data.export.delimiter,
    schedule: data.export.schedule,
    name: data.export.name,
    description: data.export.description,
    sendingMethod: data.export.sendingMethod,
    sftpConnection: data.export.sftpConnection,
  };

  return (
    <Block key="update-export" title="Edit export" description="Edit export configuration">
      <Form
        name="update-export"
        initialValues={initialValues}
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        requiredMark="optional"
      >
        <Row gutter={8}>
          <Col span="15">
            <Form.Item label="Format" name="format" required>
              <Select options={reportFormatList} />
            </Form.Item>
          </Col>
          <Col span="9">
            <Form.Item label="Delimiter" name="delimiter" required>
              <Select options={reportDelimiterList} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item noStyle name="schedule" required>
          <CronScheduleInput />
        </Form.Item>
        <Form.Item label="Name" name="name" required rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input />
        </Form.Item>
        <Form.Item label="Sending method" name="sendingMethod" required>
          <Radio.Group>
            <Space direction="vertical">
              {sendingMethodList.map((o) => (
                <Radio key={o.value} value={o.value}>
                  {o.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        {formSendingMethod === SendingMethods.SFTP && (
          <Form.Item
            name="sftpConnection"
            label="SFTP connection"
            required
            rules={[{ required: true, message: 'SFTP connection is required.' }]}
          >
            <SftpConnectionInput />
          </Form.Item>
        )}
        <Form.Item className={styles.submitItem}>
          <Button block type="primary" htmlType="submit" loading={updateExportState.loading}>
            {formSchedule ? 'UPDATE' : 'RUN'}
          </Button>
        </Form.Item>
        <Form.Item className={styles.submitItem}>
          <Button block icon={<TrashCanIcon />} onClick={handleDelete} loading={deleteExportState.loading}>
            DELETE
          </Button>
        </Form.Item>
      </Form>
    </Block>
  );
};

const ConfigurationDrawer = ({ open, onClose, containerId }) => (
  <Drawer
    width={470}
    open={open}
    getContainer={`#${containerId}`}
    style={{ position: 'absolute', zIndex: 50 }}
    bodyStyle={{ padding: 0 }}
    onClose={onClose}
    destroyOnClose
    title="Export configuration"
    closable
    closeIcon={<WindowCloseIcon />}
    mask={false}
    push={false}
  >
    <ConfigurationDrawerContent containerId={containerId} onClose={onClose} />
  </Drawer>
);

export default ConfigurationDrawer;

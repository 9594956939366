import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Button, Checkbox, Col, Divider, message, Modal, Row, Select, Space, Switch, Table, Tag } from 'antd';
import { CaretDownOutlined, CaretRightOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import AlertCircleOutlineIcon from '@2fd/ant-design-icons/lib/AlertCircleOutline';
import InfoTile, { BaseRow, InfoBooleanRow, InfoLinkRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { useCan } from '../../../../../../contexts/ability.context';
import {
  AddMerchantAccountAuthorizationMutation,
  AddMerchantCompanyAuthorizationMutation,
  ProgramManagerUserAclProfileUpdateMutation,
  ProgramManagerUserAllCompaniesAccessUpdateMutation,
  ProgramManagerUserAuthorizationManagementQuery,
  ProgramManagerUserGdprUpdateMutation,
  RemoveMerchantAccountAuthorizationMutation,
  RemoveMerchantCompanyAuthorizationMutation,
} from './query';
import { Link } from '../../../../../../util/navigate';
import { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import { search } from '../../../../../../util/string';
import SearchInput from '../../../../Common/ListFilter/SearchInput';
import styles from './AuthorizationManagement.module.scss';
import MerchantGroupsTable from './MerchantGroupsTable';
import Loading from '../../../../Common/Pages/Loading';
import { useMe } from '../../../../../../contexts/me.context';
import TargetEntities, { TargetEntity } from '../../../../Brainpower/Common/constants/targetEntities';
import useStatePagination from '../../../../Brainpower/hooks/useStatePagination';
import useToggle from '../../../../../../hooks/useToggle';

/**
 * Rules of the Authorization table
 *
 * [x] selected or [ ] not
 * - a MerchantCompany is displayed as selected if
 *    -- or merchantCompany.id is in user.authorizedMerchantCompanies
 *    -- or merchantCompany.id is in some of the user.authorizedMerchantGroups[].merchantCompanies
 *
 * - a MerchantAccount is displayed as selected if
 *    -- or merchantAccount.id is in user.authorizedMerchantAccount
 *    -- or merchantAccount.merchantCompanyId is in user.authorizedMerchantCompanies
 *    -- or merchantAccount.id is in some of the user.authorizedMerchantGroups[].merchantAccounts
 *    -- or merchantAccount.merchantCompanyId is in some of the user.authorizedMerchantGroups[].merchantCompanies
 *
 * Editable or Disabled
 * - a MerchantCompany is editable if
 *    -- merchantCompany.id is NOT in some of the user.authorizedMerchantGroups[].merchantCompanies
 *
 * - a MerchantAccount is editable if
 *    -- and merchantAccount.merchantCompanyId is NOT in user.authorizedMerchantCompanies
 *    -- and merchantAccount.id is NOT in some of the user.authorizedMerchantGroups[].merchantAccounts
 *    -- and merchantAccount.merchantCompanyId is NOT in some of the user.authorizedMerchantGroups[].merchantCompanies
 *
 * @returns {JSX.Element}
 * @constructor
 */
const AuthorizationManagement = () => {
  const can = useCan();
  const me = useMe();

  const { userId } = useParams();

  const [onlySelected, { toggle }] = useToggle(true);

  const [updateProgramManagerUserAclProfile] = useMutation(ProgramManagerUserAclProfileUpdateMutation, {
    onCompleted: () => message.success('User role successfully updated'),
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      message.error('An error occurred, please try again later.');
    },
  });

  const [updateProgramManagerUserAllCompaniesAccess] = useMutation(ProgramManagerUserAllCompaniesAccessUpdateMutation, {
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      message.error('An error occurred, please try again later.');
    },
  });

  const [updateProgramManagerUserGdpr] = useMutation(ProgramManagerUserGdprUpdateMutation, {
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleChangeAclProfile = (value) => {
    if (me.id === userId) {
      return Modal.confirm({
        title: 'You are updating your own profile',
        content: 'You may not be able to re-update it after.',
        onOk: () =>
          updateProgramManagerUserAclProfile({
            variables: { id: userId, aclProfile: value },
            optimisticResponse: {
              updateRightsOfProgramManagerUser: {
                __typename: 'ProgramManagerUser',
                id: userId,
                aclProfile: value,
              },
            },
          }),
      });
    }
    return updateProgramManagerUserAclProfile({
      variables: { id: userId, aclProfile: value },
      optimisticResponse: {
        updateRightsOfProgramManagerUser: {
          __typename: 'ProgramManagerUser',
          id: userId,
          aclProfile: value,
        },
      },
    });
  };

  const handleChangeAllCompaniesAccess = (value) =>
    updateProgramManagerUserAllCompaniesAccess({
      variables: { id: userId, hasAllCompaniesAccess: value },
      optimisticResponse: {
        updateProgramManagerUserAllCompaniesAccess: {
          __typename: 'ProgramManagerUser',
          id: userId,
          hasAllCompaniesAccess: value,
        },
      },
    });

  const handleChangeGpdrAccess = (value) =>
    updateProgramManagerUserGdpr({
      variables: { id: userId, isGdpr: value },
      optimisticResponse: {
        updateProgramManagerUserGdpr: {
          __typename: 'ProgramManagerUser',
          id: userId,
          isGdpr: value,
        },
      },
    });

  const [addMerchantCompanyAuthorization] = useMutation(AddMerchantCompanyAuthorizationMutation, {
    variables: { id: userId },
  });
  const [removeMerchantCompanyAuthorization] = useMutation(RemoveMerchantCompanyAuthorizationMutation, {
    variables: { id: userId },
  });
  const [addMerchantAccountAuthorization] = useMutation(AddMerchantAccountAuthorizationMutation, {
    variables: { id: userId },
  });
  const [removeMerchantAccountAuthorization] = useMutation(RemoveMerchantAccountAuthorizationMutation, {
    variables: { id: userId },
  });

  const { page, pageSize, setPage } = useStatePagination(10);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const expandRow = (rowKey) => setExpandedRowKeys([...expandedRowKeys, rowKey]);
  const collapseRow = (rowKey) => setExpandedRowKeys(expandedRowKeys.filter((key) => key !== rowKey));

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { data, loading } = useQuery(ProgramManagerUserAuthorizationManagementQuery, {
    variables: { id: userId },
    onCompleted: (_data) => {
      // set selected rows, based on authorizedMerchantCompanies + authorizedMerchantAccounts
      setSelectedRowKeys([
        ..._data?.programManagerUser.authorizedMerchantCompanies.map((mc) => mc.id),
        ..._data?.programManagerUser.authorizedMerchantAccounts.map((ma) => ma.id),
      ]);
    },
    errorPolicy: 'ignore',
  });

  const userCanUpdateMerchantAccess = useMemo(
    () =>
      data?.programManagerUser.aclProfile.roles.some((role) =>
        role.privileges.some(({ action, subject }) => action === 'update-access' && subject === 'program-manager-user'),
      ),
    [data],
  );

  // build dataSource & numbers from data
  const {
    dataSource,
    totalAuthorizedMerchantCompanies,
    totalMerchantCompanies,
    totalAuthorizedMerchantAccounts,
    totalMerchantAccounts,
  } = useMemo(() => {
    if (!data)
      return {
        dataSource: [],
        totalAuthorizedMerchantCompanies: null,
        totalMerchantCompanies: null,
        totalAuthorizedMerchantAccounts: null,
        totalMerchantAccounts: null,
      };

    const { programManager, authorizedMerchantCompanies, authorizedMerchantAccounts, authorizedMerchantGroups } =
      data?.programManagerUser;

    const authorizedMerchantCompanyIdList = authorizedMerchantCompanies.map((mc) => mc.id);
    const authorizedMerchantAccountIdList = authorizedMerchantAccounts.map((ma) => ma.id);

    const res = programManager.merchantCompanies.map((mc) => {
      // merchantGroups that enable access to the merchantCompany
      const groupsRelativeToCompany = authorizedMerchantGroups.filter((mg) =>
        mg.merchantCompanies.find((gmc) => gmc.id === mc.id),
      );

      const children = mc.merchantAccounts.map((ma) => {
        // merchantGroups that enable access to the merchantAccount
        const groupsRelativeToAccount = authorizedMerchantGroups.filter(
          (mg) =>
            mg.merchantCompanies.find((gmc) => gmc.id === mc.id) || mg.merchantAccounts.find((gma) => gma.id === ma.id),
        );
        return {
          type: 'MerchantAccount',
          id: ma.id,
          merchantAccount: ma.name,
          merchantCompanyId: mc.id,
          groups: groupsRelativeToAccount,
          authorized:
            authorizedMerchantAccountIdList.includes(ma.id) ||
            authorizedMerchantCompanyIdList.includes(mc.id) ||
            groupsRelativeToAccount.length > 0,
        };
      });

      return {
        type: 'MerchantCompany',
        id: mc.id,
        merchantCompany: mc.name,
        theme: mc.theme,
        children,
        groups: groupsRelativeToCompany,
        authorized: authorizedMerchantCompanyIdList.includes(mc.id) || groupsRelativeToCompany.length > 0,
        authorizedMerchantAccounts: children.filter((child) => child.authorized).length,
        totalMerchantAccounts: children.length,
      };
    });

    return {
      dataSource: res,
      totalAuthorizedMerchantCompanies: res.filter((mc) => mc.authorized).length,
      totalMerchantCompanies: res.length,
      totalAuthorizedMerchantAccounts: res.reduce((memo, mc) => memo + mc.authorizedMerchantAccounts, 0),
      totalMerchantAccounts: res.reduce((memo, mc) => memo + mc.totalMerchantAccounts, 0),
    };
  }, [data]);

  // QuickSearch on MerchantCompany & MerchantAccount
  const [quickSearch, setQuickSearch] = useState();

  // Filter dataSource by quickSearch && mode edit/read
  const filteredDataSource = useMemo(
    () =>
      dataSource
        .map((mc) => ({
          ...mc,
          children: mc.children.filter(
            (ma) =>
              (search(quickSearch, ma.id) ||
                search(quickSearch, ma.merchantAccount) ||
                search(quickSearch, mc.id) ||
                search(quickSearch, mc.merchantCompany)) &&
              (!onlySelected || ma.authorized),
          ),
        }))
        .filter(
          (mc) =>
            (mc.children.length > 0 || search(quickSearch, mc.id) || search(quickSearch, mc.merchantCompany)) &&
            (!onlySelected || mc.authorized || mc.authorizedMerchantAccounts > 0),
        ),
    [dataSource, quickSearch, onlySelected],
  );

  const columns = [
    {
      key: 'expand',
      render: (_, row) => {
        switch (row.type) {
          case 'MerchantCompany':
            if (expandedRowKeys.includes(row.id)) {
              return (
                <Button type="text" shape="circle" icon={<CaretDownOutlined />} onClick={() => collapseRow(row.id)} />
              );
            }
            return (
              <Button
                type="text"
                shape="circle"
                icon={<CaretRightOutlined />}
                onClick={() => expandRow(row.id)}
                disabled={row.children.length === 0}
              />
            );
          case 'MerchantAccount':
          default:
            return '';
        }
      },
      width: 40,
    },
    {
      dataIndex: 'merchantCompany',
      key: 'merchantCompany',
      render: (value, row) => {
        if (row.type === 'MerchantCompany') {
          return (
            <Link to={`/companies/${row.id}`}>
              <Space>
                <Avatar
                  src={row.theme.icon}
                  shape="circle"
                  size={28}
                  title={value}
                  style={{ backgroundColor: row.theme.primaryColor }}
                >
                  {value?.[0]?.toUpperCase() ?? '?'}
                </Avatar>
                {value}
              </Space>
            </Link>
          );
        }
        return '';
      },
      ...getColumnFilterSearchInput((value, record) => search(value, record.merchantCompany)),
      sorter: (a, b) => (a.merchantCompany?.toLowerCase() > b.merchantCompany?.toLowerCase() ? 1 : -1),
      defaultSortOrder: 'ascend',
      width: 280,
    },
    {
      dataIndex: 'merchantAccount',
      key: 'merchantAccount',
      render: (value, row) => {
        switch (row.type) {
          case 'MerchantCompany':
            return (
              <Button
                type="primary"
                size="small"
                style={{
                  border: 'none',
                  backgroundColor: 'var(--ant-primary-color-active-deprecated-d-02)',
                  color: 'var(--ant-primary-color)',
                }}
                icon={TargetEntities[TargetEntity.MERCHANT_ACCOUNT].icon()}
              >
                {`${row.authorizedMerchantAccounts} / ${row.totalMerchantAccounts}`}
              </Button>
            );
          case 'MerchantAccount':
            return <Link to={`/merchant-accounts/${row.id}`}>{value}</Link>;
          default:
            return '';
        }
      },
      ...getColumnFilterSearchInput((value, record) => search(value, record.merchantAccount)),
      sorter: (a, b) => (a.merchantAccount?.toLowerCase() > b.merchantAccount?.toLowerCase() ? 1 : -1),
      defaultSortOrder: 'ascend',
      width: 280,
    },
    Table.SELECTION_COLUMN,
    {
      key: 'info',
      render: (_, row) => {
        switch (row.type) {
          case 'MerchantCompany':
          case 'MerchantAccount':
            return (
              <Space>
                {row.groups.map((mg) => (
                  <Tag key={mg.id} title={mg.description}>
                    {mg.name}
                  </Tag>
                ))}
              </Space>
            );
          default:
            return '';
        }
      },
    },
  ];

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    selectedRowKeys,
    /**
     * Editable or Disabled
     * - a MerchantCompany is editable if
     *    -- merchantCompany.id is NOT in some of the user.authorizedMerchantGroups[].merchantCompanies
     *
     * - a MerchantAccount is editable if
     *    -- and merchantAccount.merchantCompanyId is NOT in user.authorizedMerchantCompanies
     *    -- and merchantAccount.id is NOT in some of the user.authorizedMerchantGroups[].merchantAccounts
     *    -- and merchantAccount.merchantCompanyId is NOT in some of the user.authorizedMerchantGroups[].merchantCompanies
     * @returns {{disabled}}
     * @param row
     */
    renderCell: (checked, row, index, originNode) => {
      switch (row.type) {
        case 'MerchantCompany':
          if (row.groups.length > 0) return <Checkbox checked disabled />;
          return originNode;
        case 'MerchantAccount':
          if (selectedRowKeys.includes(row.merchantCompanyId) || row.groups.length > 0)
            return <Checkbox checked disabled />;
          return originNode;
        default:
          console.error('Unknown row.type');
          return null;
      }
    },
    getCheckboxProps: (row) => ({
      disabled: !can('update-access', 'program-manager-user'),
      // indeterminate: !row.authorized && row.authorizedMerchantAccounts > 0, // TODO - it doesn't work because of antD treeData
    }),
    onSelect: (row, selected) => {
      if (selected) setSelectedRowKeys((prev) => [...prev, row.id]);
      else setSelectedRowKeys((prev) => prev.filter((key) => key !== row.id));

      switch (row.type) {
        case 'MerchantCompany':
          return selected
            ? addMerchantCompanyAuthorization({ variables: { merchantCompanyId: row.id } })
            : removeMerchantCompanyAuthorization({ variables: { merchantCompanyId: row.id } });
        case 'MerchantAccount':
          return selected
            ? addMerchantAccountAuthorization({ variables: { merchantAccountId: row.id } })
            : removeMerchantAccountAuthorization({ variables: { merchantAccountId: row.id } });
        default:
          console.error('Unknown row.type');
          return null;
      }
    },
    preserveSelectedRowKeys: true,
    checkStrictly: true,
  };

  if (!data) return <Loading />;
  const { programManagerUser } = data;

  return (
    <InfoTile name="authorization-management" title="Authorization management">
      <Row>
        <Col lg={24} xl={8}>
          {can('update-rights', 'program-manager-user') ? (
            <BaseRow label="Profile">
              <Select
                value={programManagerUser.aclProfile.id}
                loading={loading}
                options={programManagerUser.programManager.programManagerAclProfiles.map((p) => ({
                  value: p.id,
                  label: p.name,
                }))}
                onChange={handleChangeAclProfile}
                bordered={false}
                dropdownMatchSelectWidth={false}
              />
            </BaseRow>
          ) : (
            <InfoLinkRow
              label="Profile"
              value={programManagerUser.aclProfile.name}
              to={`/program-access-control-list/${programManagerUser.aclProfile.id}`}
            />
          )}
        </Col>
        <Col lg={24} xl={8}>
          {can('update-rights', 'program-manager-user') ? (
            <BaseRow label="Access personal data">
              <Switch
                checked={programManagerUser.isGdpr}
                onChange={handleChangeGpdrAccess}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </BaseRow>
          ) : (
            <InfoBooleanRow label="Access personal data" value={programManagerUser.isGdpr} />
          )}
        </Col>
        {can('read-access', 'program-manager-user') && (
          <Col lg={24} xl={8}>
            {can('update-access', 'program-manager-user') ? (
              <BaseRow label="Access all companies">
                <Switch
                  checked={programManagerUser.hasAllCompaniesAccess}
                  onChange={handleChangeAllCompaniesAccess}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </BaseRow>
            ) : (
              <InfoBooleanRow label="Access all companies" value={programManagerUser.hasAllCompaniesAccess} />
            )}
            {!programManagerUser.hasAllCompaniesAccess && userCanUpdateMerchantAccess && (
              <p style={{ fontSize: 13, color: '#6d7172' }}>
                <AlertCircleOutlineIcon />
                &nbsp;User is allowed to define merchant access
                <br />
                (Role Program Manager Merchant Access Editor)
              </p>
            )}
          </Col>
        )}
      </Row>
      {can('read-access', 'program-manager-user') && !programManagerUser.hasAllCompaniesAccess && (
        <div>
          <Divider orientation="left">Authorized merchants</Divider>
          {data && (
            <Table
              title={() => (
                <Row justify="space-between">
                  <Col>
                    <Space size={48}>
                      <SearchInput
                        onChange={(e) => {
                          setQuickSearch(e.target.value);
                          setPage(1);
                        }}
                        placeholder="Search by ID or name"
                      />
                      <Checkbox checked={onlySelected} onClick={toggle}>
                        Only selected
                      </Checkbox>
                    </Space>
                  </Col>
                  <Col>
                    <Space>
                      <Button
                        type="primary"
                        size="small"
                        style={{
                          border: 'none',
                          backgroundColor: 'var(--ant-primary-color-active-deprecated-d-02)',
                          color: 'var(--ant-primary-color)',
                        }}
                        icon={TargetEntities[TargetEntity.MERCHANT_COMPANY].icon()}
                        title={`${totalAuthorizedMerchantCompanies} companies authorized`}
                      >
                        {`${totalAuthorizedMerchantCompanies} / ${totalMerchantCompanies}`}
                      </Button>
                      <Button
                        type="primary"
                        size="small"
                        style={{
                          border: 'none',
                          backgroundColor: 'var(--ant-primary-color-active-deprecated-d-02)',
                          color: 'var(--ant-primary-color)',
                        }}
                        icon={TargetEntities[TargetEntity.MERCHANT_ACCOUNT].icon()}
                        title={`${totalAuthorizedMerchantAccounts} merchant accounts authorized`}
                      >
                        {`${totalAuthorizedMerchantAccounts} / ${totalMerchantAccounts}`}
                      </Button>
                    </Space>
                  </Col>
                </Row>
              )}
              columns={columns}
              loading={loading}
              dataSource={filteredDataSource}
              pagination={{
                showSizeChanger: true,
                defaultPageSize: 20,
                position: 'bottomRight',
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} companies`,
                onChange: setPage,
                current: page,
                pageSize,
                total: filteredDataSource.length,
                loading,
              }}
              expandable={{
                expandedRowKeys,
                showExpandColumn: false,
              }}
              indentSize={60}
              showHeader={false}
              bordered={false}
              rowSelection={rowSelection}
              rowClassName={(row) => (row.children ? styles.parentRow : styles.childRow)}
              rowKey="id"
              size="small"
            />
          )}
        </div>
      )}
      {can('read-access', 'program-manager-user') &&
        can('read', 'merchant-group') &&
        !programManagerUser.hasAllCompaniesAccess && (
          <div>
            <Divider orientation="left">Merchant groups</Divider>
            {data && <MerchantGroupsTable user={programManagerUser} />}
          </div>
        )}
    </InfoTile>
  );
};

export default AuthorizationManagement;

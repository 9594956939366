import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorBoundary from '../../Common/ErrorBoundary/ErrorBoundary';
import Page from '../../Common/Page/Page';
import InternalAccessControlView from './InternalAccessControlView/InternalAccessControlView';
import InternalAccessControlList from './InternalAccessControlList/InternalAccessControlList';
import InternalAccessControlOverview from './InternalAccessControlOverview/InternalAccessControlOverview';

const InternalAccessControl = () => (
  <Page>
    <ErrorBoundary>
      <Routes>
        <Route index element={<InternalAccessControlList />} />
        <Route path="overview" element={<InternalAccessControlOverview />} />
        <Route path=":profileId" element={<InternalAccessControlView />} />
      </Routes>
    </ErrorBoundary>
  </Page>
);

export default InternalAccessControl;

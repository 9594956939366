import React from 'react';
import { Avatar, Button, Checkbox, Tooltip } from 'antd';
import { CheckOutlined, RotateLeftOutlined, RotateRightOutlined } from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Link } from '../../../../../../util/navigate';
import { initials } from '../../../../../../util/string';
import useToggle from '../../../../../../hooks/useToggle';
import TableTile from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import { CompanyUserAuthorizedMerchantAccountMutation } from '../../query';
import { useCan } from '../../../../../../contexts/ability.context';
import { CompanyOverviewQuery } from './query';


const renderCheck = (value) => value && <CheckOutlined style={{ fontSize: 16 }} />;

const CompanyOverview = () => {
  const can = useCan();
  const { companyId } = useParams();

  const { data: { merchantCompany } = { merchantCompany: null}, loading} = useQuery(CompanyOverviewQuery, {
    variables: {
      companyId
    }
  });

  // TRUE = Users by Account
  // FALSE = Accounts by User
  const [view, { toggle }] = useToggle(false);

  const [updateCompanyUser] = useMutation(CompanyUserAuthorizedMerchantAccountMutation);

  const renderCheckUser =
    (userId) =>
    (value, { key }) =>
      (
        <Checkbox
          onClick={() => {
            updateCompanyUser({
              variables: { id: userId, merchantAccount: key, toAdd: !value },
            });
          }}
          checked={value}
        />
      );

  const renderCheckMerchantAccount =
    (merchantAccountId) =>
    (value, { key }) =>
      (
        <Checkbox
          onClick={() => {
            updateCompanyUser({
              variables: {
                id: key,
                merchantAccount: merchantAccountId,
                toAdd: !value,
              },
            });
          }}
          checked={value}
        />
      );

  let dataSource = [];
  let columns = [];
  if (view) {
    columns = [
      {
        key: 'name',
        title: 'Account',
        dataIndex: 'name',
        fixed: 'left',
        // sort by name
        sorter: (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1),
        sortDirections: ['ascend', 'descend', 'ascend'],
        defaultSortOrder: 'ascend',
        width: 200,
        ellipsis: true,
      },
      ...(merchantCompany?.users
        .map((u) => ({
          key: u.id,
          name: u.user.full_name,
          title: (
            <Link to={`/users/${u.user?.id}`}>
              <Tooltip title={u.user?.full_name}>
                <Avatar src={u.user?.avatar}>{initials(u.user?.full_name)}</Avatar>
              </Tooltip>
            </Link>
          ),
          dataIndex: u.id,
          render: can('update', 'company') ? renderCheckUser(u.id) : renderCheck,
          align: 'center',
          width: 48,
        }))
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) || []),
    ];

    dataSource =
      merchantCompany?.merchantAccounts?.map((ma) => ({
        key: ma.id,
        name: ma.name,
        ...merchantCompany?.users?.reduce(
          (memo, u) => ({
            ...memo,
            [u.id]: u.authorizedMerchantAccounts.find((uma) => uma.id === ma.id),
          }),
          {},
        ),
      })) || [];
  } else {
    columns = [
      {
        key: 'name',
        title: 'User',
        dataIndex: 'name',
        fixed: 'left',
        // sort by name
        sorter: (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1),
        sortDirections: ['ascend', 'descend', 'ascend'],
        defaultSortOrder: 'ascend',
        width: 200,
        ellipsis: true,
      },
      ...(merchantCompany?.merchantAccounts
        .map((ma) => ({
          key: ma.id,
          name: ma.name,
          title: ma.name,
          dataIndex: ma.id,
          render: can('update', 'company') ? renderCheckMerchantAccount(ma.id) : renderCheck,
          align: 'center',
        }))
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) || []),
    ];

    dataSource =
      merchantCompany?.users?.map((u) => ({
        key: u.id,
        name: u.user?.full_name,
        ...merchantCompany?.merchantAccounts.reduce(
          (memo, ma) => ({
            ...memo,
            [ma.id]: u.authorizedMerchantAccounts.find((uma) => uma.id === ma.id),
          }),
          {},
        ),
      })) || [];
  }

  return (
    <TableTile
      name="company-overview"
      title={view ? 'Overview Users by Account' : 'Overview Accounts by User'}
      extra={[
        <Button
          key="switch-view"
          size="small"
          type="text"
          onClick={toggle}
          icon={view ? <RotateRightOutlined /> : <RotateLeftOutlined />}
        >
          {view ? 'Accounts by User' : 'Users by Account'}
        </Button>,
      ]}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        showSizeChanger: true,
        defaultPageSize: 10,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} ${view ? 'accounts' : 'users'}`,
      }}
      size="small"
      scroll={{ x: 'auto' }}
      bordered
      loading={loading}
    />
  );
};

export default CompanyOverview;

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import TableTile from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import { useCan } from '../../../../../../contexts/ability.context';
import PartnerRoutesCreate from '../../../PartnerRoutes/PartnerRoutesCreate/PartnerRoutesCreate';
import PartnerRoutesUpdate from '../../../PartnerRoutes/PartnerRoutesUpdate/PartnerRoutesUpdate';
import { linkedMerchantContractsExpandedRowRender } from '../../../PartnerRoutes/PartnerRouteList/PartnerRouteList';
import usePartnerRoutesColumns from '../../../Common/usePartnerRoutesColumns';
import useStatePagination from '../../../../Brainpower/hooks/useStatePagination';
import { PartnerRouteListQuery } from '../../../PartnerRoutes/PartnerRouteList/query';

const PartnerRoutes = ({ partner }) => {
  const can = useCan();

  const { partnerId } = useParams();

  const { page, pageSize, setPage, offset, limit } = useStatePagination();

  const [filters, setFilters] = useState({});

  const handleChange = (_, newFilters) => setFilters(newFilters);

  const { data, previousData, loading } = useQuery(PartnerRouteListQuery, {
    variables: { filters: { ...filters, partner: [partnerId] }, limit, offset },
    errorPolicy: 'all',
  });

  const [creationModalIsVisible, setCreationModalVisible] = useState(false);
  const showCreationModal = () => setCreationModalVisible(true);
  const hideCreationModal = () => setCreationModalVisible(false);

  const [updateModalIsVisible, setUpdateModalVisible] = useState(false);
  const showUpdateModal = () => setUpdateModalVisible(true);
  const hideUpdateModal = () => setUpdateModalVisible(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const dataSource = (loading ? previousData : data)?.partnerRoutes.data.map((partnerRoute) => ({
    ...partnerRoute,
    key: partnerRoute.id,
    status: partnerRoute.status,
    paymentMethod: partnerRoute.paymentMethodName,
    channel: partnerRoute.channel,
    authorizationCurrency: partnerRoute.authorization_currency,
    settlementCurrency: partnerRoute.settlement_currency,
    linkedContractCount: partnerRoute.merchantRoutes.filter((mr) => !!mr.merchant_contract).length,
    merchantContracts: Object.values(
      partnerRoute.merchantRoutes
        ?.filter((mr) => !!mr.merchant_contract)
        .reduce(
          (memo, merchantRoute) => ({
            ...memo,
            [merchantRoute.merchant_contract?.id]: {
              key: merchantRoute.merchant_contract?.id,
              merchantContractName: merchantRoute.merchant_contract?.name,
              merchantAccountId: merchantRoute.merchant_account?.id,
              merchantAccountName: merchantRoute.merchant_account?.name,
              companyName: merchantRoute.merchant_account?.company?.name,
              company: merchantRoute.merchant_account?.company,
            },
          }),
          {},
        ),
    ),
  }));

  const columns = usePartnerRoutesColumns();

  // return info tile
  return [
    <TableTile
      key="partner-routes"
      name="partner-routes"
      title="Routes"
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      onChange={handleChange}
      pagination={{
        showSizeChanger: true,
        defaultPageSize: 20,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} routes`,
        onChange: setPage,
        current: page,
        pageSize,
        total: (loading ? previousData : data)?.partnerRoutes.count,
        loading,
      }}
      size="small"
      scroll={{ x: 'max-content' }}
      bordered
      rowSelection={
        can('update', 'partner-route') && {
          selectedRowKeys,
          onChange: setSelectedRowKeys,
          fixed: true,
        }
      }
      extra={[
        can('update', 'partner-route') && selectedRowKeys.length > 0 && (
          <Button key="update-partner-routes" icon={<EditOutlined />} onClick={showUpdateModal}>
            Edit
          </Button>
        ),
        can('create', 'partner-route') && (
          <Button key="add-partner-routes" icon={<PlusOutlined />} onClick={showCreationModal}>
            Routes
          </Button>
        ),
      ]}
      expandable={
        can('read', 'merchant_account') && {
          expandedRowRender: linkedMerchantContractsExpandedRowRender,
          rowExpandable: (record) => record.merchantContracts?.length > 0,
          expandIconColumnIndex: columns.length - 1,
        }
      }
    />,
    !loading && (
      <PartnerRoutesCreate
        key="partner-route-create"
        visible={creationModalIsVisible}
        onClose={hideCreationModal}
        partner={partner}
      />
    ),
    !loading && selectedRowKeys.length > 0 && (
      <PartnerRoutesUpdate
        key="partner-routes-update"
        visible={updateModalIsVisible}
        onClose={hideUpdateModal}
        partner={partner}
        selectedRouteIdList={selectedRowKeys}
      />
    ),
  ];
};

export default PartnerRoutes;

import { useEffect, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import OPERATION_TYPE_ENUM from 'norbr-shared-lib/constants/reconciliation/operationTypes/enum';
import useSearchFilters from '../../hooks/useSearchFilters';
import usePagination from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { uniq } from '../../../../../util/array';
import { filterTransactionalOperationsByOpType } from '../utils';

export const matcherOrderListQuery = gql`
  query MatcherOrders($offset: Int, $limit: Int, $filters: [SearchFilter], $sort: [SearchSort]) {
    matcherOrders(offset: $offset, limit: $limit, filters: $filters, sort: $sort)
  }
`;

export const matcherOrdersCountQuery = gql`
  query MatcherOrdersCount($filters: [SearchFilter]) {
    matcherOrdersCount(filters: $filters)
  }
`;

const useMatcherOrderListQuery = () => {
  const { offset, limit } = usePagination();
  const { list } = useSearchFilters();
  const [sort] = useSort();

  const [fetch, { data, previousData, loading, error }] = useLazyQuery(matcherOrderListQuery, {
    variables: {
      offset,
      limit,
      filters: list,
      sort,
    },
  });

  const [fetchCount, { data: count, previousData: previousCount, loading: loadingCount, error: errorCount }] =
    useLazyQuery(matcherOrdersCountQuery, {
      variables: {
        filters: list,
      },
    });

  const result = useMemo(
    () =>
      ((loading ? previousData?.matcherOrders : data?.matcherOrders) ?? []).map(({ transactions, ...order }) => {
        const mappedTrx = transactions?.map((transaction) => {
          const transactionalOperations = transaction.operations.filter(filterTransactionalOperationsByOpType);
          return {
            ...order,
            ...transaction,
            // Fields order with displayed values from transactions
            [Fields.ORDER_ID]: transaction[Fields.TRANSACTION_ID],
            [Fields.ORDER_CREATION_DATE]: transaction[Fields.TRANSACTION_CREATION_DATE],
            [Fields.ORDER_AMOUNT]: transaction[Fields.TRANSACTION_AMOUNT],
            [Fields.ORDER_IS_RECONCILED]: transaction[Fields.TRANSACTION_IS_RECONCILED],
            [Fields.ORDER_RECONCILED_AMOUNT]: transaction[Fields.TRANSACTION_RECONCILED_AMOUNT],
            [Fields.ORDER_UNRECONCILED_AMOUNT]: transaction[Fields.TRANSACTION_UNRECONCILED_AMOUNT],
            [Fields.ORDER_RECONCILED_BALANCE]: transaction[Fields.TRANSACTION_RECONCILED_BALANCE],
            [Fields.ORDER_UNRECONCILED_BALANCE]: transaction[Fields.TRANSACTION_UNRECONCILED_BALANCE],
            [Fields.ORDER_TOTAL_BALANCE]: transaction[Fields.TRANSACTION_TOTAL_BALANCE],
            // Fields of operations - should be displayed as array values
            [Fields.OPERATION_TYPE]: uniq(
              transactionalOperations
                // merge CREDIT & REFUND
                .map((op) =>
                  op[Fields.OPERATION_TYPE] === OPERATION_TYPE_ENUM.CAPTURE
                    ? OPERATION_TYPE_ENUM.CAPTURE
                    : OPERATION_TYPE_ENUM.REFUND,
                ),
            ),
            [Fields.OPERATION_CREATION_DATE]: uniq(
              transactionalOperations.map((op) => op[Fields.OPERATION_CREATION_DATE]),
            ),
            [Fields.PARTNER_OPERATION_ID]: uniq(transactionalOperations.map((op) => op[Fields.PARTNER_OPERATION_ID])),
            [Fields.RECONCILIATION_TYPE]: uniq(transactionalOperations.map((op) => op[Fields.RECONCILIATION_TYPE])),
            [Fields.RECONCILIATION_REASON]: uniq(transactionalOperations.map((op) => op[Fields.RECONCILIATION_REASON])),
            [Fields.WITHDRAW_REASON]: uniq(transactionalOperations.map((op) => op[Fields.WITHDRAW_REASON])),
            // End of operations fields
            parent_id: order[Fields.ORDER_ID], // used to open drawer
          };
        });

        return {
          ...mappedTrx?.[0],
          ...order,
          children: mappedTrx,
        };
      }),
    [data, loading, previousData],
  );

  const total = useMemo(
    () => (loadingCount ? previousCount?.matcherOrdersCount : count?.matcherOrdersCount) ?? 0,
    [count, loading, previousCount],
  );

  useEffect(() => {
    fetch();
    fetchCount();
  }, []);

  return { result, total, loading, error, loadingCount };
};

export default useMatcherOrderListQuery;

import React from 'react';
import styles from './Loading.module.scss';
import Loader from '../Loader/Loader';

const LoadingPage = () => (
  <div className={styles.loader}>
    <Loader width={140} height={140} />
  </div>
);

export default LoadingPage;

import React from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {UserOutlined} from '@ant-design/icons';
import {Avatar, Button, Checkbox, Drawer, Form, Select, Space} from 'antd';
import styles from '../../../../MerchantAccounts/MerchantAccountCreate/MerchantAccountCreate.module.scss';
import {compareByProp} from '../../../../../../../util/array';
import {CompanyUserListQuery, createDemoMerchantAccountMutation, PartnersQuery} from './query';
import {search} from '../../../../../../../util/string';
import {CompanyViewQuery} from '../../../query';

const paymentMethodsByPartner = {
  checkout: ['visa', 'mastercard', 'amex'],
  iyzico: ['visa', 'mastercard', 'amex', 'troy'],
  trustpay: ['visa', 'mastercard'],
  paypal: ['paypal'],
  volt: ['volt'],
  axepta: ['visa', 'mastercard'],
  worldpay: ['visa', 'mastercard'],
}
const MerchantAccountDemoCreate = ({ visible, onClose, companyId, programManagerId }) => {
  const [form] = Form.useForm();

  const { data: { partners } = { partners: []}, loading: loadingPartners } = useQuery(PartnersQuery);
  const { data: { merchantCompany: {users} } = {merchantCompany: {users: []}}, loading: loadingUsers} = useQuery(CompanyUserListQuery, {
    variables: { companyId },
  });

  const [createDemoMerchantAccount, { loading: loadingCreate }] = useMutation(
    createDemoMerchantAccountMutation,
    {
      onCompleted: onClose,
      refetchQueries: [CompanyViewQuery]
    }
  );

  return (
    <Drawer
      title="Create Demo Merchant Account"
      open={visible}
      onClose={onClose}
      wrapClassName={styles.modal}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
    >
      <Form
        form={form}
        layout="vertical"
        name="merchantAccountForm"
        onFinish={(values) => createDemoMerchantAccount({
          variables: {
            input: {
              programManager: programManagerId,
              company: companyId,
              reference_currency: 'EUR',
              ...values
            }
          },
        })}
      >
        <Form.Item
          name='defaultPartner'
          initialValue='checkout'
        >
          <Select
            options={partners
              .filter(partner => ['checkout', 'iyzico', 'trustpay', 'paypal', 'volt', 'axepta', 'worldpay'].includes(partner.api_name))
              .sort(compareByProp('name'))
              .map(partner => ({
                label: partner.name,
                value: partner.api_name,
              }))}
          />
        </Form.Item>
        <Form.Item
          noStyle
          dependencies={['defaultPartner']}
        >
          {() => {
            const selectedPartner = form.getFieldValue('defaultPartner');
            form.setFieldValue('paymentMethods', paymentMethodsByPartner[selectedPartner] ?? [])
            return (
              <Form.Item
                name='paymentMethods'
                initialValue={paymentMethodsByPartner[selectedPartner]}
              >
                <Select
                  loading={loadingPartners}
                  options={partners
                    .find(partner => partner.api_name === selectedPartner).payment_methods.map(pm => ({
                      label: pm.name,
                      value: pm.api_name,
                    }))}
                  mode='multiple'
                />
              </Form.Item>
            )
          }}
        </Form.Item>
        <Form.Item name="authorizedUsers" label="Authorized users" initialValue={[]}>
          <Select
            loading={loadingUsers}
            mode="multiple"
            showSearch
            filterOption={(inputValue, option) => search(inputValue, option.label)}
            optionLabelProp="label"
          >
            {users.map(({ user }) => (
              <Select.Option key={user.id} value={user.id} label={user.full_name}>
                <Space>
                  <Avatar src={user.avatar} icon={<UserOutlined />} size="small" />
                  {user.full_name} ({user.email})
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='createTransactions'
          valuePropName='checked'
          initialValue={false}
        >
          <Checkbox>
            Automate transaction creation
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingCreate}
          >
            Create demo merchant account
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default MerchantAccountDemoCreate;
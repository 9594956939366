import React from 'react';
import moment from 'moment-timezone';
import { Avatar, Divider, Table } from 'antd';
import styles from './AuditLogs.module.scss';
import { initials } from '../../../../../util/string';
import InfoTile, { InfoDateTimeRow, InfoImgRow } from '../../../Brainpower/OrderDetail/Tile/Info/Info';
import { DATETIME_FORMAT } from '../../../../../constants/DATES';

const AuditLogs = ({ entity }) => {
  const dataSource = entity.auditLogs?.map((log) => ({
    key: log.id,
    timestamp: moment(log.timestamp).format(DATETIME_FORMAT),
    createdBy: log.createdBy,
    email: log.createdBy?.email,
    action: log.action,
    entityName: log.entityName,
    entityId: log.entityId,
  }));

  const columns = [
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'timestamp',
    },
    {
      title: 'User',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (createdBy) => (
        // eslint-disable-next-line react/destructuring-assignment
        <Avatar src={createdBy?.avatar} size="small" title={createdBy?.full_name}>
          {initials(createdBy?.full_name)}
        </Avatar>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: 'Entity',
      dataIndex: 'entityName',
      key: 'entityName',
    },
    {
      dataIndex: 'entityId',
      key: 'entityId',
    },
  ];

  return (
    <InfoTile name="entity-logs" title="Logs">
      <InfoImgRow
        label="Created by"
        value={`${entity.createdBy?.full_name} (${entity.createdBy?.email})`}
        img={entity.createdBy?.avatar}
      />
      <InfoDateTimeRow label="Created on" value={entity.createdAt} />
      <InfoImgRow
        label="Last update by"
        value={`${entity.updatedBy?.full_name} (${entity.updatedBy?.email})`}
        img={entity.updatedBy?.avatar}
      />
      <InfoDateTimeRow label="Last update on" value={entity.updatedAt} />
      <Divider />
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 10,
        }}
        size="small"
        // showHeader={false}
        rowClassName={styles.strippedRows}
        scroll={{ x: 1000 }}
      />
    </InfoTile>
  );
};

export default AuditLogs;

import { gql } from '@apollo/client';

export const MetaProgramManagerUserViewQuery = gql`
  query MetaProgramManagerUserViewQuery($id: ID!) {
    metaProgramManagerUser(id: $id) {
      id
      full_name
      first_name
      last_name
      email
      phone {
        code
        number
      }
      avatar
      aclProfile {
        id
        name
      }
      isGdpr
      status
      disabled
      disabledAt
      disabledBy {
        id
        email
        full_name
        avatar
      }
      activated
      email_verification_token
      email_verification_token_expires_at
      lastConnection
      metaProgramManager {
        id
        name
        adminDomain
        emailDomains
        theme {
          icon
          primaryColor
          secondaryColor
        }
        metaProgramManagerAclProfiles {
          id
          name
        }
      }
    }
  }
`;

export const MetaProgramManagerUserAuditLogsQuery = gql`
  query MetaProgramManagerUserAuditLogsQuery($id: ID!) {
    metaProgramManagerUser(id: $id) {
      id
      createdAt
      createdBy {
        id
        full_name
        email
        avatar
      }
      updatedAt
      updatedBy {
        id
        full_name
        email
        avatar
      }
      auditLogs {
        id
        action
        entityId
        entityName
        timestamp
        createdBy {
          email
          full_name
          avatar
        }
      }
    }
  }
`;

export const UserUpdateMutation = gql`
  mutation UserUpdateMutation($id: ID!, $input: UserUpdateInput!) {
    updateMetaProgramManagerUser(id: $id, input: $input) {
      id
      first_name
      last_name
      full_name
      phone {
        code
        number
      }
      avatar
    }
  }
`;

export const UserAvatarUpload = gql`
  mutation UserAvatarUpload($id: ID!, $file: Upload) {
    uploadUserAvatar(id: $id, file: $file) {
      id
      avatar
    }
  }
`;

export const MetaProgramManagerUserAclProfileUpdateMutation = gql`
  mutation MetaProgramManagerUserAclProfileUpdateMutation($id: ID!, $aclProfile: ID!) {
    updateRightsOfMetaProgramManagerUser(id: $id, aclProfile: $aclProfile) {
      id
      aclProfile {
        id
        name
      }
    }
  }
`;

export const MetaProgramManagerUserGdprUpdateMutation = gql`
  mutation MetaProgramManagerUserGdprUpdateMutation($id: ID!, $isGdpr: Boolean!) {
    updateMetaProgramManagerUserGdpr(id: $id, isGdpr: $isGdpr) {
      id
      isGdpr
    }
  }
`;

export const UserDisabledUpdateMutation = gql`
  mutation UserDisabledUpdateMutation($id: ID!, $disabled: Boolean!) {
    updateDisabledOfMetaProgramManagerUser(id: $id, disabled: $disabled) {
      id
      status
      disabled
      disabledAt
      disabledBy {
        id
        email
        full_name
        avatar
      }
    }
  }
`;

export const UserDeleteMutation = gql`
  mutation UserDeleteMutation($id: ID!) {
    deleteMetaProgramManagerUser(id: $id)
  }
`;

export default {};

import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Divider, Form, InputNumber, Select, Switch } from 'antd';
import ChallengePreferences from 'norbr-shared-lib/constants/strongCustomerAuthentication/challengePreferences/list';
import ExemptionReasons from 'norbr-shared-lib/constants/strongCustomerAuthentication/exemptionReasons/list';
import SiderContainer from '../../../Common/Sider/SiderContainer/SiderContainer';
import Loader from '../../../Common/Loader/Loader';
import { useMerchantAccount } from '../../merchantAccount.context';
import styles from '../Optimizer.module.scss';
import { FallbackSettingsMutation, FallbackSettingsQuery } from '../query';
import { Block } from '../../../Common/Sider';

const challengePreferenceOptions = ChallengePreferences.map((o) => ({ value: o.id, label: o.label }));
const exemptionReasonOptions = ExemptionReasons.map((o) => ({ value: o.id, label: o.label }));

const SiderForm = ({ onClose }) => {
  const { selectedMerchantAccount } = useMerchantAccount();

  const { data } = useQuery(FallbackSettingsQuery, { variables: { id: selectedMerchantAccount } });

  const [updateSettings, { loading }] = useMutation(FallbackSettingsMutation);

  const handleFinish = (values) => {
    updateSettings({ variables: { id: selectedMerchantAccount, input: values } }).then((res) => {
      if (res.data) onClose();
    });
  };

  if (!data) return <Loader />;

  const fallbackSettings = data?.merchantAccount.fallbackSettings;

  return (
    <SiderContainer title="General settings" id="general-settings">
      <Form layout="vertical" className={styles.drawerForm} onFinish={handleFinish}>
        <Block title="Fallback service" description="Activate/deactivate the fallback feature.">
          <Form.Item label="Fallback service" name="status" initialValue={fallbackSettings.status}>
            <Select
              options={[
                { value: 'active', label: 'Active' },
                { value: 'inactive', label: 'Inactive' },
              ]}
            />
          </Form.Item>
        </Block>
        <Block title="Fallback settings" description="Define the basic rules that should apply to your transactions.">
          <Form.Item label="Max. number of attempts" name="max_attempts" initialValue={fallbackSettings.max_attempts}>
            <InputNumber min={0} max={5} />
          </Form.Item>
          <Form.Item label="Max. number of retries" name="max_retries" initialValue={fallbackSettings.max_retries}>
            <InputNumber min={0} max={12} />
          </Form.Item>
          <Form.Item
            label="Include PSP downtimes in count"
            name="count_psp_unavailability"
            initialValue={fallbackSettings.count_psp_unavailability}
            valuePropName="checked"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
          <Form.Item hidden name={['ask_3ds_default', 'type']} initialValue="challenge_preference" />
          <Form.Item
            label="Default value for ask_3DS"
            name={['ask_3ds_default', 'value']}
            initialValue={fallbackSettings.ask_3ds_default.value}
          >
            <Select options={challengePreferenceOptions} />
          </Form.Item>
          <Form.Item hidden name={['force_3ds_default', 'type']} initialValue="challenge_preference" />
          <Form.Item
            label="Default value for force_3DS"
            name={['force_3ds_default', 'value']}
            initialValue={fallbackSettings.force_3ds_default.value}
          >
            <Select options={challengePreferenceOptions} />
          </Form.Item>
          <Form.Item hidden name={['no_3ds_default', 'type']} initialValue="sca_exemption_reason" />
          <Form.Item
            label="Default value for no_3DS"
            name={['no_3ds_default', 'value']}
            initialValue={fallbackSettings.no_3ds_default.value}
          >
            <Select options={exemptionReasonOptions} />
          </Form.Item>
          <Divider />
          <div className={styles.bottomButtons}>
            <Button type="text" onClick={onClose}>
              CANCEL
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              SAVE
            </Button>
          </div>
        </Block>
      </Form>
    </SiderContainer>
  );
};

export default SiderForm;

import React, { useMemo } from 'react';
import { useSet } from 'react-use';
import { gql, useQuery } from '@apollo/client';
import { Button, Space, Table, Tag, Typography } from 'antd';
import { DeleteOutlined, RollbackOutlined } from '@ant-design/icons';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import usePaymentMethods from '../../../../../hooks/common/usePaymentMethods';
import { partnerResponseTypes, partnerRouteOptions, partnerRouteStatuses } from '../constants';
import { BaseRow, InfoTextRow } from '../../../Brainpower/OrderDetail/Tile/Info/Info';
import PAYMENT_CHANNELS from '../../../../../constants/PAYMENT_CHANNELS';

const PartnerQuery = gql`
  query PartnerQuery($id: ID!) {
    partner(id: $id) {
      __typename
      id
      partner_routes {
        authorization_currency
        settlement_currency
        channel
        paymentMethodName
      }
    }
  }
`;

const ValidationStep = ({ formValues, partner, onSubmit }) => {
  const paymentMethods = usePaymentMethods();

  const [deleteIndexes, { has, add, remove }] = useSet(new Set([]));

  const partnerQuery = useQuery(PartnerQuery, { variables: { id: partner } });

  /**
   * Generate one route for each combinations of selected
   * - paymentMethods
   * - paymentChannels
   * - authorizationCurrencies
   * - settlementCurrencies
   */
  const [generatedRoutes, existingRoutes] = useMemo(() => {
    const res = [];
    const existing = [];
    let index = 0;
    formValues.paymentMethods.forEach((paymentMethod) => {
      formValues.paymentChannels.forEach((paymentChannel) => {
        formValues.authorizationCurrencies.forEach((authorizationCurrency) => {
          formValues.settlementCurrencies.forEach((settlementCurrency) => {
            const route = {
              key: index,
              status: formValues.status,
              options: formValues.options,
              paymentMethod,
              paymentChannel,
              authorizationCurrency,
              settlementCurrency,
              index,
            };
            index += 1;
            if (
              partnerQuery.data?.partner.partner_routes.find(
                (pr) =>
                  pr.paymentMethodName === paymentMethod &&
                  pr.channel === paymentChannel &&
                  pr.authorization_currency === authorizationCurrency &&
                  pr.settlement_currency === settlementCurrency,
              )
            ) {
              existing.push(route);
            } else {
              res.push(route);
            }
          });
        });
      });
    });
    return [res, existing];
  }, [formValues, partnerQuery]);

  const handleSubmit = () => {
    const validatedRoutes = generatedRoutes.filter((r, index) => !deleteIndexes.has(index));
    onSubmit(validatedRoutes);
  };

  const columns = [
    {
      title: 'Payment method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (paymentMethod) =>
        paymentMethods[paymentMethod] ? (
          <Space>
            {paymentMethods[paymentMethod].imgUrl && (
              <img src={paymentMethods[paymentMethod].imgUrl} alt="" style={{ width: 24 }} />
            )}
            {paymentMethods[paymentMethod].name}
          </Space>
        ) : (
          paymentMethod
        ),
      filters: formValues.paymentMethods.map(
        (pm) => paymentMethods[pm] && { value: paymentMethods[pm].api_name, text: paymentMethods[pm].name },
      ),
      onFilter: (value, record) => value === record.paymentMethod,
    },
    {
      title: 'Channel',
      dataIndex: 'paymentChannel',
      key: 'paymentChannel',
      render: (channel) => {
        if (PAYMENT_CHANNELS[channel]) {
          const Icon = PAYMENT_CHANNELS[channel].icon;
          return (
            <Space>
              {Icon && <Icon />}
              {PAYMENT_CHANNELS[channel].label}
            </Space>
          );
        }
        return channel;
      },
      filters: formValues.paymentChannels.map((c) => ({
        value: PAYMENT_CHANNELS[c].value,
        text: PAYMENT_CHANNELS[c].label,
      })),
      onFilter: (value, record) => value === record.paymentChannel,
    },
    {
      title: 'Auth',
      dataIndex: 'authorizationCurrency',
      key: 'authorizationCurrency',
      filters: formValues.authorizationCurrencies.map(
        (c) => CURRENCIES[c] && { value: CURRENCIES[c].alpha, text: `[${CURRENCIES[c].alpha}] ${CURRENCIES[c].name}` },
      ),
      onFilter: (value, record) => value === record.authorizationCurrency,
    },
    {
      title: 'Settl',
      dataIndex: 'settlementCurrency',
      key: 'settlementCurrency',
      filters: formValues.settlementCurrencies.map(
        (c) => CURRENCIES[c] && { value: CURRENCIES[c].alpha, text: `[${CURRENCIES[c].alpha}] ${CURRENCIES[c].name}` },
      ),
      onFilter: (value, record) => value === record.settlementCurrency,
    },
    {
      title: '',
      key: 'action',
      render: (text, record) =>
        has(record.index) ? (
          <Button type="text" icon={<RollbackOutlined />} onClick={() => remove(record.index)} />
        ) : (
          <Button type="text" icon={<DeleteOutlined />} onClick={() => add(record.index)} />
        ),
    },
  ];

  const SubmitButton = () => (
    <Button onClick={handleSubmit} type="primary" disabled={generatedRoutes.length - deleteIndexes.size < 1}>
      Create&nbsp;<b>{generatedRoutes.length - deleteIndexes.size}</b>&nbsp;routes
    </Button>
  );

  return [
    existingRoutes.length > 0 && (
      <Typography.Paragraph key="info-exist">
        <b>{existingRoutes.length}</b> routes matching selected properties already exist.
      </Typography.Paragraph>
    ),
    <Typography.Paragraph key="info">
      Valid or remove routes among the new <b>{generatedRoutes.length}</b> generated routes.
    </Typography.Paragraph>,
    <div key="info-options">
      <SubmitButton />
      <InfoTextRow value={partnerRouteStatuses[formValues.status].label} label="Status" />
      <BaseRow label="Options">
        <Space>
          {formValues.options.map((option) => {
            const currentOption = partnerRouteOptions[option.key];
            switch (currentOption.type) {
              case 'PartnerResponseType':
                return (
                  <Tag key={option.key} color="darkgrey">
                    {currentOption.label}: {partnerResponseTypes[option.value].label}
                  </Tag>
                );
              case 'Int':
                return (
                  <Tag key={option.key} color="darkgrey">
                    {currentOption.label}: {option.value}
                  </Tag>
                );
              case 'Boolean':
              default:
                return (
                  <Tag key={option.key} color="darkgrey">
                    {currentOption.label}: {option.value ? 'True' : 'False'}
                  </Tag>
                );
            }
          })}
        </Space>
      </BaseRow>
    </div>,
    <Table
      key="table-routes"
      size="small"
      style={{ margin: '24px 0' }}
      columns={columns}
      dataSource={generatedRoutes}
      rowClassName={(record) => has(record.index) && 'deleted-row'}
      pagination={{
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} routes`,
        defaultPageSize: 1000,
        pageSizeOptions: [20, 50, 100, 500, 1000],
        hideOnSinglePage: true,
      }}
    />,
    <SubmitButton key="submit-btn" />,
  ];
};

export default ValidationStep;

import React from 'react';
import { Col, Descriptions, Row, Space, Typography } from 'antd';
import moment from 'moment-timezone';
import styles from './MatcherDrawer.module.scss';
import TargetEntities, { TargetEntity } from '../../Common/constants/targetEntities';
import { DATETIME_FORMAT } from '../../../../../constants/DATES';

const MatcherOrderDescription = ({ order }) => {
  if (!order) return null;
  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: '#f5f4f4',
        margin: '0 8px',
        borderRadius: 8,
        overflow: 'hidden',
      }}
    >
      <Row justify="space-between" style={{ padding: '8px 12px' }}>
        <Col>
          <Space style={{ fontSize: '14px' }}>
            {TargetEntities[TargetEntity.ORDER].icon()}
            Order
          </Space>
        </Col>
      </Row>
      <Row justify="space-between" style={{ padding: '8px 12px' }}>
        <Descriptions
          column={2}
          size="small"
          colon={false}
          labelStyle={{ color: '#868686' }}
          className={styles.descriptions}
        >
          <Descriptions.Item label="Order">
            <Typography.Text ellipsis copyable>
              {order.order_id}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Date">{moment(order.createdAt).format(DATETIME_FORMAT)}</Descriptions.Item>
          <Descriptions.Item label="Receipt #">
            <Typography.Text ellipsis copyable>
              {order.order_merchant_id ?? 'n/a'}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Store">
            <Typography.Text ellipsis>{order.merchant_account?.name ?? 'n/a'}</Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      </Row>
    </div>
  );

  // return (
  //   <div
  //     style={{
  //       position: 'relative',
  //       zIndex: 20,
  //       backgroundColor: 'white',
  //       boxShadow: '0 1px 4px 0 rgba(23, 23, 23, 0.35)',
  //       padding: '8px 12px',
  //       margin: '0 8px',
  //       borderRadius: 6,
  //     }}
  //   >
  //     <Descriptions
  //       column={2}
  //       size="small"
  //       colon={false}
  //       labelStyle={{ color: 'lightgrey' }}
  //       className={styles.descriptions}
  //     >
  //       <Descriptions.Item label="Order">
  //         <Typography.Text ellipsis copyable>
  //           {order.order_id}
  //         </Typography.Text>
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Date">{moment(order.createdAt).format(DATETIME_FORMAT)}</Descriptions.Item>
  //       <Descriptions.Item label="Cashier">
  //         {cashierPartner.option ? (
  //           <Space>
  //             <Avatar
  //               src={cashierPartner.option.img}
  //               shape="square"
  //               size={28}
  //               title={cashierPartner.option.label}
  //               style={{ backgroundColor: cashierPartner.option.color }}
  //               icon={<DeploymentUnitOutlined />}
  //             />
  //             {cashierPartner.option.label}
  //           </Space>
  //         ) : (
  //           <Typography.Text ellipsis>{transaction.cashier_partner_api_name ?? 'n/a'}</Typography.Text>
  //         )}
  //       </Descriptions.Item>
  //       <Descriptions.Item label="File name">
  //         <Typography.Text
  //           ellipsis
  //           style={{
  //             width: 300,
  //           }}
  //           copyable
  //         >
  //           {transaction.pos_file_name ?? 'n/a'}
  //         </Typography.Text>
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Store">
  //         <Typography.Text ellipsis>{order.merchant_account?.name ?? 'n/a'}</Typography.Text>
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Receipt #">
  //         <Typography.Text ellipsis copyable>
  //           {order.order_merchant_id ?? 'n/a'}
  //         </Typography.Text>
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Order amount">
  //         {amountFormatter(order.amount, order.currency, AmountFormats.FR_CURRENCY)}
  //       </Descriptions.Item>
  //       <Descriptions.Item
  //         label="Unmatched amount"
  //         contentStyle={{ color: order.unreconciled_amount === 0 ? '#00cc7e' : '#fa9502' }}
  //       >
  //         {amountFormatter(order.unreconciled_amount, order.currency, AmountFormats.FR_CURRENCY)}
  //       </Descriptions.Item>
  //     </Descriptions>
  //   </div>
  // );
};

export default MatcherOrderDescription;

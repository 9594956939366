import { gql } from '@apollo/client';

export const metaProgramManagerCompanyQuery = gql`
  query MetaProgramManagerCompany($id: ID!) {
    metaProgramManager(id: $id) {
      id
      company {
        name
        country
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const REFUND_ORDER_MUTATION = gql`
  mutation RefundOrderMutation($id: ID!, $reason: RefundReason, $input: [RefundInput!]!) {
    refundOrder(id: $id, reason: $reason, input: $input) {
      _id
      id
      last_transaction {
        _id
        id
        status
        status_history {
          date
          status
        }
        is_cancellable
        is_refundable
        is_capturable
        currency
        capturable_amount
        refundable_amount
        captured_amount
        refunded_amount
        chargeback_amount
        refund_reason
        operations {
          _id
          id
          request_date
          request_status
          result_response
          result_date
          result_status
          type
          amount
          linked_operation_id
        }
      }
    }
  }
`;

export default {};

import React, { useState } from 'react';
import { Button, Divider, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import TableTile, { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import { search } from '../../../../../../util/string';
import { useCan } from '../../../../../../contexts/ability.context';
import useToggle from '../../../../../../hooks/useToggle';
import { compareByProp } from '../../../../../../util/array';
import ContactForm from './ContactForm/ContactForm';
import { metaProgramManagerContactsQuery, removeMetaProgramManagerContactMutation } from './query';
import { contactTypes } from './constants';

const MetaProgramManagerContacts = ({ id }) => {
  const can = useCan();

  const [showAddContact, { toggle }] = useToggle(false);
  const [editedContact, setEditedContact] = useState(null);

  const {
    data: { metaProgramManager } = {
      metaProgramManager: {
        contacts: [],
      },
    },
    loading,
  } = useQuery(metaProgramManagerContactsQuery, {
    variables: {
      id,
    },
  });

  const { contacts } = metaProgramManager;

  const [removeContact] = useMutation(removeMetaProgramManagerContactMutation, {
    variables: {
      id,
    },
  });

  const handleRemoveContact = (contact) => () => {
    Modal.confirm({
      title: `Remove contact ${contact.email ?? `${contact.firstname} ${contact.surname}`}`,
      content: 'Are you sure you want to delete this contact?',
      onOk: () =>
        removeContact({
          variables: {
            contactId: contact._id,
          },
          optimisticResponse: {
            removeMetaProgramManagerContact: {
              ...metaProgramManager,
              contacts: metaProgramManager.contacts.filter((contactElem) => contactElem._id !== contact._id),
            },
          },
        }),
    });
  };

  const columns = [
    {
      title: 'Level',
      dataIndex: 'priorityLevel',
      key: 'priorityLevel',
      width: 120,
      align: 'center',
      sorter: compareByProp('priorityLevel'),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, contact) => `${contact.firstname} ${contact.surname}`,
      ...getColumnFilterSearchInput((value, contact) => search(value, `${contact.firstname} ${contact.surname}`)),
      sorter: (a, b) =>
        `${a.firstname} ${a.surname}`.toLowerCase() > `${b.firstname} ${b.surname}`.toLowerCase() ? 1 : -1,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (_, contact) =>
        contact.phone.code && contact.phone.number && `+${contact.phone?.code} ${contact.phone?.number}`,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      filters: contactTypes.map((contactType) => ({
        ...contactType,
        text: contactType.label,
      })),
      onFilter: (value, contact) => value === contact.type,
      render: (type) => contactTypes.find((contactType) => contactType.value === type)?.label,
    },
  ];

  if (can('update', 'program-manager')) {
    columns.push({
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 160,
      render: (value, contact) => [
        <Button
          key="edit-contact"
          icon={<EditOutlined />}
          onClick={() => setEditedContact(contact === editedContact ? null : contact)}
          title="Edit contact"
          shape="circle"
          type="text"
        />,
        <Divider key="divider" type="vertical" />,
        <Button
          key="remove-contact"
          icon={<DeleteOutlined />}
          onClick={handleRemoveContact(contact)}
          title="Remove contact from program manager"
          shape="circle"
          type="text"
        />,
      ],
    });
  }

  return (
    <>
      <TableTile
        name="program-manager-contacts"
        // title={`Contacts at ${programManager.name}`}
        title="Contacts"
        columns={columns}
        loading={loading}
        dataSource={contacts
          .map((contact) => ({
            ...contact,
            key: contact._id,
          }))
          .sort(compareByProp('priorityLevel'))}
        extra={[
          can('update', 'program-manager') && (
            <Button key="add-partner" icon={showAddContact ? <RightOutlined /> : <PlusOutlined />} onClick={toggle}>
              Contact
            </Button>
          ),
        ]}
      />
      <ContactForm
        visible={showAddContact || editedContact}
        metaProgramManager={metaProgramManager}
        toggle={editedContact ? () => setEditedContact(null) : toggle}
        contact={editedContact}
      />
    </>
  );
};

export default MetaProgramManagerContacts;

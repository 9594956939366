import React from 'react';
import InfoTile, { InfoSkeletonRow } from '../../../OrderDetail/Tile/Info/Info';

const NotificationStatusSkeleton = () => (
  <InfoTile name="notification-status" title="Notification">
    <InfoSkeletonRow label="Creation" />
    <InfoSkeletonRow label="Status" />
    <InfoSkeletonRow label="Trigger" />
    <InfoSkeletonRow label="Notification ID" />
    <InfoSkeletonRow label="URL" />
    <InfoSkeletonRow label="Webhook" />
    <InfoSkeletonRow label="Source" />
  </InfoTile>
);

export default NotificationStatusSkeleton;

import { gql } from '@apollo/client';

export const MetaProgramManagerProgramManagersQuery = gql`
  query MetaProgramManagerProgramManagers($id: ID!) {
    metaProgramManager(id: $id) {
      id
      programManagers {
        id
        name
      }
    }
  }
`;
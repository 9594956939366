import {gql} from '@apollo/client';

export const companySsoQuery = gql`
  query MerchantCompany ($id: ID!) {
    merchantCompany(id: $id) {
      id
      loginMethod  
      sso {
        config {
          idpEntityId
          ssoURL
          rpEntityId
          x509Certificates
          tenantId
          providerId
        }
      }   
    }
  } 
`;
import React from 'react';
import { Button, Col, Drawer, Form, Input, Row, Switch } from 'antd';
import ArrowLeftIcon from '@2fd/ant-design-icons/lib/ArrowLeft';
import TrashCanIcon from '@2fd/ant-design-icons/lib/TrashCan';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import moment from 'moment-timezone';
import { useAutoFocusRef } from '../../../../../../util/antd';
import { DATETIME_FORMAT } from '../../../../../../constants/DATES';
import useColumnSet from '../../../hooks/useColumnSet';
import useSort from '../../../hooks/useSort';
import useTableDisplay from '../../../hooks/useTableDisplay';
import useTargetEntity from '../../../hooks/useTargetEntity';
import { useBrainpowerViewSettings } from '../../../hooks/useBrainpowerSettings';
import usePage from '../../../hooks/usePage';
import useDimensions from '../../../hooks/useDimensions';
import useIndicators from '../../../hooks/useIndicators';

const SavedViewEditForm = ({ view, favorite, form, onDelete, onFinish }) => {
  const inputRef = useAutoFocusRef();
  return [
    <Row
      key="info-row"
      justify="space-between"
      align="middle"
      style={{ padding: '8px 24px', backgroundColor: '#f9f8fa', color: '#868686' }}
      hidden={!view._id || view._id === 'default'}
    >
      <Col>
        <div>Created {moment(view.createdAt).format(DATETIME_FORMAT)}</div>
        <div>{view.columnSet?.length ?? 'n/a'} columns</div>
      </Col>
      <Col>
        <Button
          icon={<TrashCanIcon />}
          type="text"
          style={{ color: '#868686', backgroundColor: 'whitesmoke' }}
          onClick={onDelete}
          hidden={view._id === 'default'}
        >
          DELETE
        </Button>
      </Col>
    </Row>,
    <Form
      key="saved-view-edit-form"
      form={form}
      onFinish={onFinish}
      layout="vertical"
      style={{ padding: '24px 24px' }}
      initialValues={view}
      requiredMark={false}
    >
      <Form.Item name="label" label="Name" rules={[{ required: true, message: '* required' }]}>
        <Input ref={inputRef} disabled={view._id === 'default'} />
      </Form.Item>
      {view._id && (
        <Form.Item
          name="favorite"
          label="Pin this view as default"
          valuePropName="checked"
          initialValue={view._id === favorite}
        >
          <Switch disabled={view._id === favorite} />
        </Form.Item>
      )}
      <Form.Item shouldUpdate style={{ marginTop: 40 }}>
        {() => (
          <Button block type="primary" htmlType="submit" disabled={!form.getFieldValue('label')}>
            SAVE
          </Button>
        )}
      </Form.Item>
    </Form>,
  ];
};

const SavedListViewEdit = ({ view, onClose }) => {
  const [settings, update] = useBrainpowerViewSettings();
  const { favorite = 'default', views = [] } = settings;

  const [columnSet] = useColumnSet();
  const [sort] = useSort();
  const [display] = useTableDisplay();
  const [entity] = useTargetEntity();

  const [form] = Form.useForm();

  const handleDelete = () => {
    update({
      favorite: favorite === view._id ? 'default' : favorite,
      views: views.filter((s) => s._id !== view._id),
    }).then(() => onClose());
  };

  const handleFinish = (values) => {
    // update existing saved view
    if (view._id) {
      update({
        // eslint-disable-next-line no-nested-ternary
        favorite: values.favorite ? view._id : view._id === favorite ? 'default' : favorite,
        views: views.map((s) => (s._id === view._id ? { ...s, label: values.label } : s)),
      }).then(() => onClose());
    }
    // save new view
    else {
      update({
        favorite,
        views: [
          ...views,
          {
            ...values,
            columnSet,
            sort,
            display,
            entity,
            createdAt: moment().toISOString(),
          },
        ],
      }).then(() => onClose());
    }
  };

  return (
    <SavedViewEditForm view={view} favorite={favorite} form={form} onDelete={handleDelete} onFinish={handleFinish} />
  );
};

const SavedOverviewViewEdit = ({ view, onClose }) => {
  const [settings, update] = useBrainpowerViewSettings();
  const { favorite = 'default', views = [] } = settings;

  const [columnSet] = useColumnSet();
  const [dimensions] = useDimensions();
  const [indicators] = useIndicators();
  const [display] = useTableDisplay();
  const [sort] = useSort();

  const [form] = Form.useForm();

  const handleDelete = () => {
    update({
      favorite: favorite === view._id ? 'default' : favorite,
      views: views.filter((s) => s._id !== view._id),
    }).then(() => onClose());
  };

  const handleFinish = (values) => {
    // update existing saved view
    if (view._id) {
      update({
        // eslint-disable-next-line no-nested-ternary
        favorite: values.favorite ? view._id : view._id === favorite ? 'default' : favorite,
        views: views.map((s) => (s._id === view._id ? { ...s, label: values.label } : s)),
      }).then(() => onClose());
    }
    // save new view
    else {
      update({
        favorite,
        views: [
          ...views,
          {
            ...values,
            columnSet,
            dimensions,
            indicators,
            display,
            sort,
            createdAt: moment().toISOString(),
          },
        ],
      }).then(() => onClose());
    }
  };

  return (
    <SavedViewEditForm view={view} favorite={favorite} form={form} onDelete={handleDelete} onFinish={handleFinish} />
  );
};

const SavedViewEditContent = (props) => {
  const page = usePage();

  switch (page.type) {
    case 'list':
      return <SavedListViewEdit {...props} />;
    case 'overview':
      return <SavedOverviewViewEdit {...props} />;
    default:
      return null;
  }
};

const SavedViewEditDrawer = ({ open, onClose, onBack, view = {}, containerId }) => (
  <Drawer
    width={470}
    open={open}
    getContainer={`#${containerId}`}
    style={{ position: 'absolute', zIndex: 50 }}
    bodyStyle={{ padding: 0 }}
    onClose={onClose}
    destroyOnClose
    title={`View / ${view.label ?? 'Save view'}`}
    closable
    closeIcon={<WindowCloseIcon />}
    extra={<ArrowLeftIcon onClick={onBack} />}
    mask={false}
    push={false}
    autoFocus={false}
  >
    <SavedViewEditContent view={view} onClose={onBack} />
  </Drawer>
);

export default SavedViewEditDrawer;

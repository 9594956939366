import React from 'react';
import {useTranslation} from 'react-i18next';
import {getText, getValue} from '../utils';

const UpdateAdd = ({log}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      Added:
      <br/>
      {log.log_update.added.map(add => (
        <>
          {getText(add[0], log.log_entity_type, t)}: {getValue(add[0], log.log_entity_type, t, i18n, add[1])}
          <br/>
        </>
      ))}
    </>
  )
};

export default UpdateAdd;
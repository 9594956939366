import { gql } from '@apollo/client';

export const metaProgramManagerOptionsQuery = gql`
  query MetaProgramManagerOptions($id: ID!) {
    metaProgramManager(id: $id) {
      id
      displayName
      code
      adminDomain
      alternativeAdminDomain
      apiDomain
      metaToken
      emailDomains
      notificationUrl
      website
    }
  }
`;

export const updateMetaProgramManagerOptionsMutation = gql`
  mutation UpdateMetaProgramManagerOptions($id: ID!, $input: MetaProgramManagerOptionsInput!) {
    updateMetaProgramManagerOptions(id: $id, input: $input) {
      id
      displayName
      code
      adminDomain
      alternativeAdminDomain
      apiDomain
      metaToken
      emailDomains
      notificationUrl
      website
    }
  }
`;

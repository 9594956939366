import { gql } from '@apollo/client';

export const addPaymentMethodMutation = gql`
  mutation AddPaymentMethodMutation($id: ID!, $input: PaymentMethodInput!) {
    addPaymentMethod(id: $id, input: $input) {
        id
        payment_methods {
            id
            imgUrl
            partner_routes {
                id
                channel
                authorization_currency
                partner {
                    id
                    name
                }
                payment_method {
                    id
                }
            }
        }
        merchant_routes(filters: { status: { in: ["active", "inactive"] } }) {
            id
            status
            partner_route {
                id
                authorization_currency
                partner {
                    id
                }
                payment_method {
                    id
                }
                settlement_currency
                channel
            }
        }
        partner {
            id
            settlement_currencies
            partner_data_configuration
            partner_reconciliation_configuration
            risk_assessment_service
        }
    }
  }
`;

export const removePaymentMethodMutation = gql`
  mutation RemovePaymentMethodMutation($id: ID!, $input: PaymentMethodInput!, $force: Boolean) {
    removePaymentMethod(id: $id, input: $input, force: $force) {
        id
        payment_methods {
            id
            imgUrl
            partner_routes {
                id
                channel
                authorization_currency
                partner {
                    id
                    name
                }
                payment_method {
                    id
                }
            }
        }
        merchant_routes(filters: { status: { in: ["active", "inactive"] } }) {
            id
            status
            partner_route {
                id
                authorization_currency
                partner {
                    id
                }
                payment_method {
                    id
                }
                settlement_currency
                channel
            }
        }
        partner {
            id
            settlement_currencies
            partner_data_configuration
            partner_reconciliation_configuration
            risk_assessment_service
        }
    }
  }
`;
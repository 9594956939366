import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, Layout, message, Modal, Row, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { DeleteOutlined, HomeOutlined, TeamOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import GroupIcon from '@2fd/ant-design-icons/lib/Group';
import { useCan } from '../../../../../contexts/ability.context';
import useNavigateWithSearch from '../../../../../util/navigate';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import HandleApolloError from '../../../Common/HandleApolloError/HandleApolloError';
import AnchorList from '../../../Common/AnchorList/AnchorList';
import ProgramManagerAvatar from '../../../Common/ProgramManager/Avatar';
import Loader from '../../../Common/Loader/Loader';
import Members from './Members/Members';
import GroupContent from './GroupContent/GroupContent';
import { MerchantGroupDeleteMutation, MerchantGroupViewQuery } from './query';
import styles from './MerchantGroupView.module.scss';

const { Content, Sider } = Layout;

const breadcrumbRender = (id, name) => () => (
  <Breadcrumb
    map={{
      '/': <HomeOutlined />,
      '/merchant-groups': 'Groups',
      [`/merchant-groups/${id}`]: name,
    }}
  />
);

const MerchantGroupView = () => {
  const can = useCan();
  const navigate = useNavigateWithSearch();

  const { merchantGroupId } = useParams();

  const { data, loading, error } = useQuery(MerchantGroupViewQuery, {
    variables: {
      id: merchantGroupId,
    },
    errorPolicy: 'all',
  });

  const [deleteMerchantGroup, deleteMerchantGroupState] = useMutation(MerchantGroupDeleteMutation, {
    onCompleted: () => {
      message.success('Group successfully deleted');
      navigate('/merchant-groups');
    },
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      message.error('An error occurred, please try again later.');
    },
  });

  if (loading) {
    return (
      <PageHeader
        title="..."
        breadcrumbRender={breadcrumbRender(merchantGroupId, '...')}
        onBack={() => navigate('/merchant-groups')}
      >
        <Loader size="large" />
      </PageHeader>
    );
  }

  const { merchantGroup } = data;

  const handleDelete = () => {
    Modal.confirm({
      title: `Delete ${merchantGroup.name}`,
      content: 'Are you sure you want to delete this group?',
      onOk: () =>
        deleteMerchantGroup({
          variables: { id: merchantGroupId },
        }),
    });
  };

  const anchors = [
    {
      hash: '#tile-mg-content',
      icon: GroupIcon,
      label: 'Group companies & accounts',
    },
    ...(can('read-access', 'program_manager_user')
      ? [
          {
            hash: '#tile-mg-members',
            icon: TeamOutlined,
            label: 'Members',
          },
        ]
      : []),
  ];

  return (
    <PageHeader
      title={merchantGroup.name}
      breadcrumbRender={breadcrumbRender(merchantGroup.id, merchantGroup.name)}
      onBack={() => navigate('/merchant-groups')}
      extra={[
        can('delete', 'merchant-group') && (
          <Button
            key="delete-group"
            title={merchantGroup.userCount > 0 ? 'Cannot delete used group' : 'Delete group'}
            icon={<DeleteOutlined />}
            shape="circle"
            onClick={handleDelete}
            loading={deleteMerchantGroupState.loading}
            disabled={merchantGroup.userCount > 0}
          />
        ),
        <ProgramManagerAvatar key="program-manager-avatar" programManager={merchantGroup.programManager} />,
      ]}
    >
      <HandleApolloError error={error}>
        <Layout className={styles.subPage}>
          <Sider className={styles.anchorSider} width={24}>
            <AnchorList anchors={anchors} />
          </Sider>
          <Content>
            <Typography.Paragraph>{merchantGroup.description}</Typography.Paragraph>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <GroupContent merchantGroup={merchantGroup} />
              </Col>
              {can('read-access', 'program-manager-user') && (
                <Col span={24}>
                  <Members merchantGroup={merchantGroup} />
                </Col>
              )}
            </Row>
          </Content>
        </Layout>
      </HandleApolloError>
    </PageHeader>
  );
};

export default MerchantGroupView;

import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import {useLazyQuery} from '@apollo/client';
import InfoTile, { InfoAmountRow, InfoBooleanRow, InfoTextRow } from '../../../Tile/Info/Info';
import { orderVoucherQuery, activityVoucherQuery } from './query';
import VoucherSkeleton from './VoucherSkeleton';

const Voucher = () => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(activityVoucherQuery, {
    variables: {
      orderId,
    }
  });

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(orderVoucherQuery, {
    variables: {
      orderId,
    },
    onCompleted: (data) => !data.order && fetchInsights(),
  });

  const order = orderStack ?? orderInsights;

  if (!order) return <VoucherSkeleton />;

  return (
    <InfoTile name="voucher" title="Voucher / Gift cards">
      <InfoBooleanRow label="Used" value={order.giftcard_used} />
      <InfoAmountRow label="Amount" value={order.giftcard_amount} currency={order.giftcard_currency} />
      <InfoTextRow label="Voucher number" value={order.giftcard_number} />
    </InfoTile>
  );
};

export default Voucher;

import React from 'react';
import LordIcon from '../../../../assets/icons/LordIcon';
import style from './NotAuthorized.module.scss';

function Error({ message }) {
  // eslint-disable-next-line no-console
  console.error(`Page Error`, message);
  return (
    <div className={style.root}>
      <div>
        <LordIcon type="error" height={250} isStopped={false} autoplay loop width={250} />
        <div>An error occurred, please contact support.</div>
      </div>
    </div>
  );
}

export default Error;

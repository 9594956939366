import { gql } from "@apollo/client";

export const companyListsQuery = gql`
  query CompanyLists ($id: ID!) {
    merchantCompany (id: $id) {
      id
      lists {
        id
        name
        type
      }
    }
  }
`;
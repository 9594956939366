import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Button, Checkbox, Col, Form, message, Row, Space, Switch } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import InfoTile, { InfoBooleanRow, InfoTagsRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { useCan } from '../../../../../../contexts/ability.context';
import Sources from '../../../../../../constants/SOURCES';
import { PartnerUpdateMutation } from '../query';
import styles from '../PartnerView.module.scss';

const PartnerSources = ({ partner }) => {
  const can = useCan();
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);

  const [form] = Form.useForm();

  const [updatePartner, { loading: loadingUpdate }] = useMutation(PartnerUpdateMutation, {
    onCompleted: () => {
      message.success('Partner successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleFinish = (values) => {
    updatePartner({
      variables: {
        id: partner.id,
        input: {
          allow_stack: values.source.includes(Sources.STACK),
          allow_insights: values.source.includes(Sources.INSIGHTS),
          allow_insights_pos: values.source.includes(Sources.INSIGHTS_POS),
          allow_payfac: values.payfac,
        },
      },
    });
  };

  const partnerSources = [];

  if (partner.allow_stack) {
    partnerSources.push(Sources.STACK);
  }
  if (partner.allow_insights) {
    partnerSources.push(Sources.INSIGHTS);
  }
  if (partner.allow_insights_pos) {
    partnerSources.push(Sources.INSIGHTS_POS);
  }

  const hasActivePartnerRoutes = partner.partner_routes.some((partnerRoute) =>
    ['active', 'maintenance'].includes(partnerRoute.status),
  );

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="partner-sources" title="Partner sources">
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            source: partnerSources,
            payfac: partner.allow_payfac,
          }}
          onFinish={handleFinish}
          requiredMark={false}
          colon={false}
          className="tile-form"
        >
          <Form.Item
            label="Source"
            name="source"
            tooltip={`Defines the sources allowed by partner ${
              hasActivePartnerRoutes
                ? '(You can not deselect stack while there are active or maintenance partner routes)'
                : ''
            }`}
          >
            <Checkbox.Group
              options={[
                {
                  value: Sources.STACK,
                  label: t(`sources.${Sources.STACK}`),
                  disabled: hasActivePartnerRoutes,
                },
                {
                  value: Sources.INSIGHTS,
                  label: t(`sources.${Sources.INSIGHTS}`),
                },
                {
                  value: Sources.INSIGHTS_POS,
                  label: t(`sources.${Sources.INSIGHTS_POS}`),
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="Allow Payment Facilitator" name="payfac" valuePropName="checked" initialValue={false}>
            <Switch>Allow Payment Facilitator</Switch>
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loadingUpdate}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  return (
    <InfoTile
      name="partner-sources"
      title="Partner sources"
      extra={[
        can('update', 'partner') && (
          <Button key="update-partner" onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        ),
      ]}
    >
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <InfoTagsRow label="Sources" value={partnerSources.map((s) => t(`sources.${s}`))} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <InfoBooleanRow label="Allow Payment Facilitator" value={partner.allow_payfac} />
        </Col>
      </Row>
    </InfoTile>
  );
};

export default PartnerSources;

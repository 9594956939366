import React, { useContext } from 'react';
import { Form, Input } from 'antd';
import { Block } from '../../../../../../Common/Sider';
import { ContractEditionContext } from '../../../../PaymentNetwork';
import { MerchantContractContext } from '../../MerchantContractDrawer';
import styles from '../ContractForm.module.scss';
import { useMerchantAccount } from '../../../../../merchantAccount.context';

const { Item } = Form;

const ReconciliationSettings = () => {
  const { merchantAccount } = useMerchantAccount();
  const { merchantContract, updateMerchantContract } = useContext(MerchantContractContext);
  const { isEdited } = useContext(ContractEditionContext);

  const settingsToShow = Object.keys(merchantContract?.partner?.partner_reconciliation_configuration ?? {}).filter((config) => {
    const configParams = merchantContract?.partner?.partner_reconciliation_configuration[config];
    return configParams?.sources?.[merchantContract.source] !== 'excluded'
  });

  if (
    !merchantContract?.partner?.partner_reconciliation_configuration ||
    settingsToShow.length === 0
  ) {
    return null;
  }

  return (
    <Block title="Reconciliation settings" description={isEdited && 'Set up your reconciliation'}>
      <Form layout="vertical" className={styles.drawerForm}>
        {Object.keys(merchantContract?.partner?.partner_reconciliation_configuration ?? {})
          .filter(
            (config) =>
              (merchantAccount.programManager.payfac &&
                merchantContract.partner.partner_reconciliation_configuration?.[config]?.payfac) ||
              (!merchantAccount.programManager.payfac &&
                merchantContract.partner.partner_reconciliation_configuration?.[config]?.merchant),
          )
          .map((config) => {
          const configParams = merchantContract?.partner?.partner_reconciliation_configuration[config];
          const configuration = merchantContract?.reconciliation_configuration ?? {};

          if (configParams?.sources?.[merchantContract.source] !== 'excluded') {
            if (configParams?.type === 'String') {
              return (
                <Item
                  id={`config-${config}`}
                  key={`config-${config}`}
                  label={config}
                  required={configParams.sources[merchantContract.source] === 'required'}
                >
                  <Input
                    disabled={!isEdited || merchantContract.is_demo}
                    onChange={(event) =>
                      updateMerchantContract({
                        variables: {
                          input: {
                            reconciliation_configuration: {
                              ...configuration,
                              [config]: event.target.value.trim(),
                            },
                          },
                        },
                        context: {
                          debounceKey: `updateMerchantContractConfiguration-${merchantContract.id}`,
                          debounceTimeout: 1000,
                        },
                        optimisticResponse: {
                          updateContract: {
                            ...merchantContract,
                            reconciliation_configuration: {
                              ...configuration,
                              [config]: event.target.value,
                            },
                          },
                        },
                      })
                    }
                    value={configuration?.[config]}
                    defaultValue={configuration?.[config]}
                  />
                </Item>
              );
            }
          }
          return null;
        })}
      </Form>
    </Block>
  );
};

export default ReconciliationSettings;

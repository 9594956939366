import { gql } from '@apollo/client';

const query = `{
  _id
  last_transaction {
    _id
    capturable_amount
    refundable_amount
    currency
  }
}`;

export const orderBalancesQuery = gql`
  query OrderBalances($orderId: ID!) {
    order(id: $orderId) ${query}
  }
`;

export const activityBalancesQuery = gql`
  query ActivityBalances($orderId: ID!) {
    activity(id: $orderId) ${query}
  }
`;

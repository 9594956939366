import React, { useEffect } from 'react';
import { Avatar, Button, Divider, Form, Select, Space, Tag, TreeSelect } from 'antd';
import { useLazyQuery, useQuery } from '@apollo/client';
import { ApartmentOutlined } from '@ant-design/icons';
import { ProgramManagerRecipientsQuery, TargetRecipientsQuery } from '../../query';
import { useProgramManagerId } from '../../../../../../../contexts/app.context';
import { useCan } from '../../../../../../../contexts/ability.context';

const SelectAnnouncementRecipientsAsProgramManager = ({ announcement, prev, visible }) => {
  const can = useCan();
  const [programManagerId] = useProgramManagerId();
  const [form] = Form.useForm();
  const recipientsQuery = useQuery(ProgramManagerRecipientsQuery, {
    variables: { id: programManagerId },
    errorPolicy: 'ignore',
  });
  const [fetchTargetRecipients, targetRecipientsResult] = useLazyQuery(TargetRecipientsQuery);

  const onCheck = () => fetchTargetRecipients({ variables: form.getFieldsValue() });

  useEffect(() => {
    onCheck();
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      name="recipients"
      hidden={!visible}
      initialValues={
        announcement && {
          programManagerAclProfiles: announcement.programManagerAclProfiles.map((p) => p.id),
          merchantCompanies: announcement.merchantCompanies.map((p) => p.id),
          merchantAclProfiles: announcement.merchantAclProfiles.map((p) => p.id),
        }
      }
      onFieldsChange={onCheck}
    >
      <Divider orientation="left">{recipientsQuery.data?.programManager.name} Users</Divider>
      <Form.Item
        name="programManagerAclProfiles"
        label="Profiles"
        tooltip={`Select ${recipientsQuery.data?.programManager.name} profiles, which users will received the announcement.`}
        extra={
          targetRecipientsResult.loading
            ? '...'
            : `${
                targetRecipientsResult.data?.announcementRecipients.programManagerUsers.length ?? '...'
              } internal users found.`
        }
      >
        <TreeSelect
          mode="multiple"
          allowClear
          maxTagCount="responsive"
          placeholder="None"
          loading={recipientsQuery.loading}
          treeCheckable
          treeDefaultExpandedKeys={['all']}
          treeNodeFilterProp="label"
          tagRender={({ label, ...props }) => <Tag {...props}>{label}</Tag>}
          disabled={!can('read', 'program-manager-user')}
        >
          <Select.Option key="all" value="all" label="All profiles">
            {recipientsQuery.data?.programManager.programManagerAclProfiles?.map(({ id, name }) => (
              <Select.Option key={id} value={id} label={name}>
                {name}
              </Select.Option>
            ))}
          </Select.Option>
        </TreeSelect>
      </Form.Item>
      <Divider orientation="left">Merchants</Divider>
      <Form.Item
        name="merchantCompanies"
        label="Companies"
        tooltip="Select the Merchant companies from which users will receive the announcement (combined with Profiles)."
      >
        <TreeSelect
          mode="multiple"
          allowClear
          maxTagCount="responsive"
          placeholder="None"
          loading={recipientsQuery.loading}
          treeCheckable
          treeDefaultExpandedKeys={['all']}
          treeNodeFilterProp="label"
          tagRender={({ label, ...props }) => <Tag {...props}>{label}</Tag>}
          disabled={!can('read', 'access-control')}
        >
          <Select.Option key="all" value="all" label="All companies">
            {recipientsQuery.data?.programManager.merchantCompanies?.map(({ id, name, theme }) => (
              <Select.Option
                key={id}
                value={id}
                label={name}
                title={
                  <Space>
                    <Avatar
                      src={theme?.icon}
                      icon={<ApartmentOutlined />}
                      size="small"
                      style={{ backgroundColor: theme?.secondaryColor }}
                    />
                    {name}
                  </Space>
                }
              >
                {name}
              </Select.Option>
            ))}
          </Select.Option>
        </TreeSelect>
      </Form.Item>
      <Form.Item
        name="merchantAclProfiles"
        label="Profiles"
        tooltip="Select Merchant companies profiles, which users will receive the announcement (combined with Companies)."
        extra={
          targetRecipientsResult.loading
            ? '...'
            : `${
                targetRecipientsResult.data?.announcementRecipients.merchantUsers.length ?? '...'
              } merchant users found.`
        }
      >
        <TreeSelect
          mode="multiple"
          allowClear
          maxTagCount="responsive"
          placeholder="None"
          loading={recipientsQuery.loading}
          treeCheckable
          treeDefaultExpandedKeys={['all']}
          treeNodeFilterProp="label"
          tagRender={({ label, ...props }) => <Tag {...props}>{label}</Tag>}
          disabled={!can('read', 'access-control')}
        >
          <Select.Option key="all" value="all" label="All profiles">
            {recipientsQuery.data?.programManager.merchantAclProfiles?.map(({ id, name }) => (
              <Select.Option key={id} value={id} label={name}>
                {name}
              </Select.Option>
            ))}
          </Select.Option>
        </TreeSelect>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="default" onClick={prev}>
            Previous
          </Button>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default SelectAnnouncementRecipientsAsProgramManager;

import { gql } from '@apollo/client';

export const MetaProgramManagerUserCreateMutation = gql`
  mutation MetaProgramManagerUserCreate($input: MetaProgramManagerUserCreationInput!) {
    createMetaProgramManagerUser(input: $input) {
      id
      full_name
      phone {
        code
        number
      }
      status
      aclProfile {
        id
        name
      }
    }
  }
`;

export const MetaProgramManagerQuery = gql`
  query MetaProgramManagerQuery($id: ID!) {
    metaProgramManager(id: $id) {
      __typename
      id
      name
      emailDomains
      metaProgramManagerAclProfiles {
        id
        name
      }
    }
  }
`;

export const MetaProgramManagerListQuery = gql`
  query MetaProgramManagerListQuery {
    metaProgramManagers {
      __typename
      id
      name
      theme {
        icon
        primaryColor
        secondaryColor
      }
    }
  }
`;

export default {};

import { gql } from '@apollo/client';

export const merchantContractQuery = gql`
  query MerchantContract($id: ID!) {
    merchantContract(id: $id) {
      id
      name
      partner {
        id
        name
      }
      merchant_account {
        id
      }
    }
  }
`;
import React from 'react';
import { Input, message, Modal, Form, Select, Space } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { searchByProp } from '../../../../../util/string';
import { useResetFormOnCloseModal } from '../../../Common/Form/utils';
import { ProgramManagerListQuery } from '../../Company/query';
import { ProfileCreateMutation, ProfileListQuery } from '../query';
import ProgramManagerAvatar from '../../../Common/ProgramManager/Avatar';
import useNavigateWithSearch from "../../../../../util/navigate";

const AccessControlCreate = ({ visible, onCancel, programManager }) => {
  const navigate = useNavigateWithSearch();

  const profileQuery = useQuery(ProfileListQuery);
  const programManagersQuery = useQuery(ProgramManagerListQuery, { skip: programManager });

  const [create, { loading }] = useMutation(ProfileCreateMutation, {
    refetchQueries: ['ProfileList'],
    onCompleted: ({ createMerchantAclProfile }) => {
      message.success('Profile successfully created');
      navigate(`/access-control-list/${createMerchantAclProfile.id}`);
    },
  });

  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const onOk = () => {
    form.submit();
  };

  const submit = ({ baseProfile, ...values }) => {
    create({
      variables: {
        createProfileInput: values,
        baseProfile,
      },
    });
  };

  return (
    <Modal title="Create profile" open={visible} onOk={onOk} onCancel={onCancel} confirmLoading={loading}>
      <Form form={form} layout="vertical" name="profileForm" onFinish={submit}>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input />
        </Form.Item>
        <Form.Item name="baseProfile" label="Roles based on">
          <Select
            allowClear
            showSearch
            filterOption={searchByProp('label')}
            optionLabelProp="label"
            loading={profileQuery.loading}
          >
            {profileQuery.data?.merchantAclProfiles?.map((p) => (
              <Select.Option key={p.id} value={p.id} label={p.name}>
                <Space>
                  <ProgramManagerAvatar programManager={p.programManager} />
                  {p.name}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="programManager"
          label="Program Manager"
          rules={[{ required: true }]}
          initialValue={programManager}
          hidden={!!programManager}
        >
          <Select
            allowClear
            showSearch
            filterOption={searchByProp('label')}
            optionLabelProp="label"
            loading={programManagersQuery.loading}
            disabled={!!programManager}
          >
            {programManagersQuery.data?.programManagers.map((pm) => (
              <Select.Option key={pm.id} value={pm.id} label={pm.name}>
                <Space>
                  <ProgramManagerAvatar programManager={pm} />
                  {pm.name}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AccessControlCreate;

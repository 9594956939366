import { gql } from '@apollo/client';

export const currenciesQuery = gql`
    {
        currencies {
            id
            name
            alpha
            code
        }
    }
`;

export const companyListsQuery = gql`
    query CompanyLists ($id: ID!) {
        merchantCompany (id: $id) {
            id
            lists {
                id
                name
                type
            }
        }
    }
`;


export const paymentMethodsQuery = gql`
    {
        paymentMethods {
            id
            type
            name
            scheme
            api_name
            countries
            imgUrl
            devices_available
            is_available
            component_type
            api_required_fields
        }
    }
`;

import { gql } from '@apollo/client';

export const merchantAccountsQuery = gql`
  query MerchantAccounts {
    merchantAccounts {
      id
      name
      company {
        theme {
          icon
          primaryColor
        }
      }
    }
  }
`;

export const merchantCompaniesQuery = gql`
  query MerchantCompanies {
    merchantCompanies {
      id
      name
      theme {
        icon
        primaryColor
        secondaryColor
      }
    }
  }
`;

export const programManagersQuery = gql`
  query ProgramManagers {
    programManagers {
      id
      name
      theme {
        icon
        primaryColor
        secondaryColor
      }
    }
  }
`;

export const paymentMethodsQuery = gql`
  {
    partners {
      name
      payment_methods {
        id
        api_name
        name
        imgUrl
      }
    }
  }
`;

export const partnersQuery = gql`
  {
    partners {
      id
      api_name
      name
      type
      company {
        theme {
          icon
          primaryColor
        }
      }
    }
  }
`;

import React from 'react';
import SharedFieldTypes from 'norbr-shared-lib/constants/order/fields/fieldTypes';
import { Avatar } from 'antd';
import AccountIcon from '@2fd/ant-design-icons/lib/Account';
import { gql } from '@apollo/client';
import { fieldTypes } from '../../Brainpower/Common/constants/fields';
import { TargetEntity } from '../../Brainpower/Common/constants/targetEntities';
import FieldTypes from '../../Brainpower/Common/constants/fieldTypes';
import USER_STATUSES, { userStatusList } from '../../../../constants/USER_STATUSES';
import { MODES } from '../../../../contexts/mode.context';

const aclProfilesQuery = gql`
  {
    metaProgramManagerAclProfiles {
      id
      name
    }
  }
`;

export const Fields = {
  META_PROGRAM_MANAGER: 'meta_program_manager',
  META_PROGRAM_MANAGER_NAME: 'meta_program_manager_name',
  CREATION_DATE: 'creation_date',
  STATUS: 'status',
  EMAIL: 'email',
  AVATAR: 'avatar',
  FIRSTNAME: 'firstname',
  LASTNAME: 'lastname',
  FULL_NAME: 'full_name',
  ACL_PROFILE: 'acl_profile',
  ACL_PROFILE_NAME: 'acl_profile_name',
  USER_ID: 'user_id',
  GDPR: 'gdpr',
  LAST_CONNECTION_DATE: 'last_connection_date',
};

const fields = (mode) => ({
  ...(mode === MODES.INTERNAL
    ? {
        [Fields.META_PROGRAM_MANAGER]: {
          key: Fields.META_PROGRAM_MANAGER,
          label: 'Meta program manager',
          type: SharedFieldTypes.META_PROGRAM_MANAGER,
          entity: TargetEntity.META_PROGRAM_MANAGER_USER,
          path: 'metaProgramManager.id',
          description: 'The meta program manager of the user.',
          sortField: Fields.META_PROGRAM_MANAGER_NAME,
          filter: true,
          ...fieldTypes[SharedFieldTypes.META_PROGRAM_MANAGER],
        },
        [Fields.META_PROGRAM_MANAGER_NAME]: {
          key: Fields.META_PROGRAM_MANAGER_NAME,
          label: 'Meta program manager name',
          type: SharedFieldTypes.TEXT,
          entity: TargetEntity.META_PROGRAM_MANAGER_USER,
          path: 'metaProgramManager.name',
          description: 'The name of the meta program manager.',
          filter: false,
          ...fieldTypes[SharedFieldTypes.TEXT],
        },
      }
    : {}),
  [Fields.CREATION_DATE]: {
    key: Fields.CREATION_DATE,
    label: 'Creation date',
    type: SharedFieldTypes.DATE,
    entity: TargetEntity.META_PROGRAM_MANAGER_USER,
    path: 'createdAt',
    description: 'The creation date of the user.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.DATE],
  },
  [Fields.STATUS]: {
    key: Fields.STATUS,
    label: 'Status',
    entity: TargetEntity.META_PROGRAM_MANAGER_USER,
    path: 'status',
    description: 'The status of the user.',
    filter: true,
    type: FieldTypes.LIST,
    filterOptions: {
      options: userStatusList,
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => USER_STATUSES[value],
      tag: true,
      icon: true,
    },
  },
  [Fields.EMAIL]: {
    key: Fields.EMAIL,
    label: 'Email',
    type: FieldTypes.TEXT,
    entity: TargetEntity.META_PROGRAM_MANAGER_USER,
    path: 'email',
    description: 'The email of the user.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.TEXT],
    displayOptions: {
      link: true,
    },
  },
  [Fields.FIRSTNAME]: {
    key: Fields.FIRSTNAME,
    label: 'Firstname',
    type: SharedFieldTypes.TEXT,
    entity: TargetEntity.META_PROGRAM_MANAGER_USER,
    path: 'first_name',
    description: 'The firstname of the user.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.TEXT],
  },
  [Fields.LASTNAME]: {
    key: Fields.LASTNAME,
    label: 'Lastname',
    type: SharedFieldTypes.TEXT,
    entity: TargetEntity.META_PROGRAM_MANAGER_USER,
    path: 'last_name',
    description: 'The lastname of the user.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.TEXT],
  },
  [Fields.FULL_NAME]: {
    key: Fields.FULL_NAME,
    label: 'Full name',
    type: SharedFieldTypes.TEXT,
    entity: TargetEntity.META_PROGRAM_MANAGER_USER,
    path: 'full_name',
    description: 'The full name of the user.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.TEXT],
  },
  [Fields.AVATAR]: {
    key: Fields.AVATAR,
    label: 'Avatar',
    entity: TargetEntity.META_PROGRAM_MANAGER_USER,
    path: 'avatar',
    description: 'The avatar of the user.',
    filter: false,
    type: FieldTypes.CUSTOM,
    filterOptions: {},
    displayOptions: {
      customRender: (v) => (
        <div>
          <Avatar src={v} shape="circle" size={28} icon={<AccountIcon />} />
        </div>
      ),
    },
  },
  [Fields.USER_ID]: {
    key: Fields.USER_ID,
    label: 'User ID',
    type: SharedFieldTypes.OBJECT_ID,
    entity: TargetEntity.META_PROGRAM_MANAGER_USER,
    path: 'id',
    description: 'The ID of the user.',
    filter: false,
    ...fieldTypes[SharedFieldTypes.OBJECT_ID],
  },
  [Fields.ACL_PROFILE]: {
    key: Fields.ACL_PROFILE,
    label: 'Profile',
    entity: TargetEntity.META_PROGRAM_MANAGER_USER,
    path: 'aclProfile.id',
    description: 'The profile of the user.',
    filter: true,
    type: FieldTypes.ASYNC_LIST,
    sortField: Fields.ACL_PROFILE_NAME,
    filterOptions: {
      asyncOptions: {
        query: aclProfilesQuery,
        formatData: (data) =>
          data?.metaProgramManagerAclProfiles?.map((p) => ({
            value: p.id,
            label: p.name,
          })),
      },
      defaultValue: [],
      nullable: true,
    },
    displayOptions: {},
  },
  [Fields.ACL_PROFILE_NAME]: {
    key: Fields.ACL_PROFILE_NAME,
    label: 'Profile name',
    entity: TargetEntity.META_PROGRAM_MANAGER_USER,
    path: 'aclProfile.name',
    description: 'The profile name of the user.',
    filter: false,
    type: FieldTypes.TEXT,
    filterOptions: {},
    displayOptions: {},
  },
  [Fields.GDPR]: {
    key: Fields.GDPR,
    label: 'GDPR',
    entity: TargetEntity.META_PROGRAM_MANAGER_USER,
    path: 'isGdpr',
    description: 'The GDPR status of the user.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.BOOLEAN],
    // displayOptions: {
    //   trueElement: <CheckSquareOutlined style={{ fontSize: '24px' }} color="#0CA30A" />,
    //   falseElement: <CloseSquareOutlined style={{ fontSize: '24px' }} color="#F44F4D" />,
    // },
  },
  [Fields.LAST_CONNECTION_DATE]: {
    key: Fields.LAST_CONNECTION_DATE,
    label: 'Last connection date',
    type: SharedFieldTypes.DATE,
    entity: TargetEntity.META_PROGRAM_MANAGER_USER,
    path: 'lastConnection',
    description: 'The last connection date of the user.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.DATE],
  },
});
export default fields;

import SharedFieldTypes from 'norbr-shared-lib/constants/order/fields/fieldTypes';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import AlertCircleIcon from '@2fd/ant-design-icons/lib/AlertCircle';
import ProgressClockIcon from '@2fd/ant-design-icons/lib/ProgressClock';
import CheckCircleIcon from '@2fd/ant-design-icons/lib/CheckCircle';
import FieldTypes from '../../Common/constants/fieldTypes';
import { TAGS } from '../../../../../constants/TRANSACTION_STATUSES';
import AmountFormats from '../../../../../constants/amountFormats';
import {
  merchantAccountsQuery,
  paymentMethodsQuery,
  partnersQuery,
  merchantCompaniesQuery,
  programManagersQuery,
} from './SearchDrawer/query';
import DateFormats from '../../../../../constants/dateFormats';
import { MODES } from '../../../../../contexts/mode.context';

const registerStatuses = {
  pending: {
    id: 'pending',
    value: 'pending',
    label: 'Pending',
    color: 'grey',
    tag: TAGS.pending,
    icon: ProgressClockIcon,
  },
  matched_warning: {
    id: 'matched_warning',
    value: 'matched_warning',
    label: 'Matched',
    color: 'orange',
    tag: TAGS.ko,
    icon: AlertCircleIcon,
  },
  matched_success: {
    id: 'matched_success',
    value: 'matched_success',
    label: 'Matched',
    color: 'green',
    tag: TAGS.ok,
    icon: CheckCircleIcon,
  },
  closed_warning: {
    id: 'closed_warning',
    value: 'closed_warning',
    label: 'Closed',
    color: 'orange',
    tag: TAGS.ko,
    icon: AlertCircleIcon,
  },
  closed_success: {
    id: 'closed_success',
    value: 'closed_success',
    label: 'Closed',
    color: 'green',
    tag: TAGS.ok,
    icon: CheckCircleIcon,
  },
};

export const DataRoles = {
  DIMENSION: 'dimension',
  INDICATOR: 'indicator',
};

// Day formats used on summary date fields
export const dayFormatList = [
  { value: DateFormats.DATE, label: DateFormats.DATE },
  { value: DateFormats.EN_DATE, label: DateFormats.EN_DATE },
  { value: DateFormats.US_DATE, label: DateFormats.US_DATE },
];

export const MatcherPages = {
  MATCHER_ENGINE: 'matcher',
  MATCHER_CASHIER_SUMMARY: 'matcherCashierSummary',
  MATCHER_CASHIER_DETAILS: 'matcherCashierDetails',
  MATCHER_PAYMENT_PARTNER: 'matcherPaymentPartner',
};

export const MatcherCashierFieldsEnum = {
  MATCHER_CASHIER: 'matcher_cashier',
  MATCHER_NOTIFICATION_DATE: 'matcher_notification_date',
  MATCHER_OPERATION_DATE: 'matcher_operation_date',
  MATCHER_PAYMENT_CHANNEL: 'matcher_payment_channel',
  MATCHER_PAYMENT_METHOD: 'matcher_payment_method',
  MATCHER_REASON: 'matcher_reason',
  MATCHER_RECONCILIATION_TYPE: 'matcher_reconciliation_type',
  MATCHER_WITHDRAW_REASON: 'matcher_withdraw_reason',
  MATCHER_OPERATION_TYPE: 'matcher_operation_type',
  MATCHER_PAYIN_PARTNER: 'matcher_payin_partner',
  MATCHER_REGISTER_STATUS: 'matcher_register_status',
  MATCHER_MATCHED_AMOUNT: 'matcher_matched_amount',
  MATCHER_MATCHED_BALANCE: 'matcher_matched_balance',
  MATCHER_MATCHED_NOTIFICATIONS: 'matcher_matched_notifications',
  MATCHER_MATCHED_TRANSACTIONS: 'matcher_matched_transactions',
  MATCHER_MATCHED_OPERATIONS: 'matcher_matched_operations',
  MATCHER_MATCHED_ORDERS: 'matcher_matched_orders',
  MATCHER_TOTAL_AMOUNT: 'matcher_total_amount',
  MATCHER_TOTAL_BALANCE: 'matcher_total_balance',
  MATCHER_TOTAL_TRANSACTIONS: 'matcher_total_transactions',
  MATCHER_TOTAL_OPERATIONS: 'matcher_total_operations',
  MATCHER_TOTAL_ORDERS: 'matcher_total_orders',
  MATCHER_TOTAL_NOTIFICATIONS: 'matcher_total_notifications',
  MATCHER_UNMATCHED_AMOUNT: 'matcher_unmatched_amount',
  MATCHER_UNMATCHED_BALANCE: 'matcher_unmatched_balance',
  MATCHER_UNMATCHED_NOTIFICATIONS: 'matcher_unmatched_notifications',
  MATCHER_UNMATCHED_TRANSACTIONS: 'matcher_unmatched_transactions',
  MATCHER_UNMATCHED_OPERATIONS: 'matcher_unmatched_operations',
  MATCHER_UNMATCHED_ORDERS: 'matcher_unmatched_orders',
  MATCHER_PROGRAM_MANAGER: 'matcher_program_manager',
  MATCHER_COMPANY: 'matcher_company',
  MATCHER_MERCHANT_ACCOUNT: 'matcher_merchant_account',
};

export const MatcherCashierFields = {
  [MatcherCashierFieldsEnum.MATCHER_CASHIER]: {
    type: FieldTypes.ASYNC_LIST,
    label: 'Cashier',
    columnLabel: 'CASHIER',
    description: 'Point of sale Partner.',
    fieldType: SharedFieldTypes.CASHIER_PARTNER,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
      image: true,
    },
    filterOptions: {
      asyncOptions: {
        query: partnersQuery,
        formatData: (data) =>
          data?.partners
            ?.filter((partner) => partner.type === 'cashier')
            .map((partner) => ({
              value: partner.api_name,
              label: partner.name,
              img: partner.company.theme.icon,
            })),
      },
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    dataRole: DataRoles.DIMENSION,
  },
  [MatcherCashierFieldsEnum.MATCHER_NOTIFICATION_DATE]: {
    type: FieldTypes.DATE,
    label: 'Notification date',
    columnLabel: 'NOTIFICATION',
    description: 'Date of receiving payment service provider transaction.',
    fieldType: SharedFieldTypes.TEXT,
    displayOptions: {
      dateFormatList: dayFormatList,
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    filterOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_PAYMENT_PARTNER],
    },
    dataRole: DataRoles.DIMENSION,
  },
  [MatcherCashierFieldsEnum.MATCHER_OPERATION_DATE]: {
    type: FieldTypes.DATE,
    label: 'Operation date',
    columnLabel: 'OP. DATE',
    description: 'Date of point of sale system payments operation.',
    fieldType: SharedFieldTypes.TEXT,
    displayOptions: {
      dateFormatList: dayFormatList,
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    filterOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_SUMMARY, MatcherPages.MATCHER_CASHIER_DETAILS],
    },
    defaultSettings: {
      format: DateFormats.DATE,
    },
    dataRole: DataRoles.DIMENSION,
  },
  [MatcherCashierFieldsEnum.MATCHER_PAYMENT_CHANNEL]: {
    type: FieldTypes.TEXT,
    label: 'Payment channel',
    columnLabel: 'CHANNEL',
    description: 'The payment channel of the order.',
    fieldType: SharedFieldTypes.TEXT,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS],
    },
    filterOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS],
    },
    dataRole: DataRoles.DIMENSION,
  },
  [MatcherCashierFieldsEnum.MATCHER_PAYMENT_METHOD]: {
    type: FieldTypes.ASYNC_LIST,
    label: 'Payment method',
    columnLabel: 'PAYMENT METHOD',
    description: 'The name of the method payment used by the customer for the payment.',
    fieldType: SharedFieldTypes.PAYMENT_METHOD,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS],
      image: true,
      hideLabel: true,
    },
    filterOptions: {
      asyncOptions: {
        query: paymentMethodsQuery,
        formatData: (data) =>
          data?.partners
            ?.flatMap((p) => p.payment_methods)
            // dedupe
            ?.filter((pm, index, list) => list.findIndex((elem) => pm.api_name === elem.api_name) === index)
            ?.map((paymentMethod) => ({
              value: paymentMethod.api_name,
              label: paymentMethod.name,
              img: paymentMethod.imgUrl,
            })),
        defaultValue: [],
      },
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_PAYMENT_PARTNER],
    },
    dataRole: DataRoles.DIMENSION,
  },
  [MatcherCashierFieldsEnum.MATCHER_REASON]: {
    type: FieldTypes.TEXT,
    label: 'Reconciliation reason',
    columnLabel: 'REASON',
    description:
      'Reasons for manual reconciliations (Amount discrepancy, Duplicate, Missing identifier, MOTO - Offline mode, No reason, Payback, Other) as selected by user.',
    fieldType: SharedFieldTypes.TEXT,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    filterOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    dataRole: DataRoles.DIMENSION,
  },
  [MatcherCashierFieldsEnum.MATCHER_RECONCILIATION_TYPE]: {
    type: FieldTypes.TEXT,
    label: 'Reconciliation type',
    columnLabel: 'MATCH TYPE',
    description: 'Type of match (Automatic, Manual, Pending).',
    fieldType: SharedFieldTypes.TEXT,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    dataRole: DataRoles.DIMENSION,
  },
  [MatcherCashierFieldsEnum.MATCHER_OPERATION_TYPE]: {
    type: FieldTypes.TEXT,
    label: 'Operation type',
    columnLabel: 'OP. TYPE',
    description: 'Type of operation (Capture or Refund).',
    fieldType: SharedFieldTypes.TEXT,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    filterOptions: {
      pages: [
        MatcherPages.MATCHER_CASHIER_SUMMARY,
        MatcherPages.MATCHER_CASHIER_DETAILS,
        MatcherPages.MATCHER_PAYMENT_PARTNER,
      ],
    },
    dataRole: DataRoles.DIMENSION,
  },
  [MatcherCashierFieldsEnum.MATCHER_MERCHANT_ACCOUNT]: {
    type: FieldTypes.ASYNC_LIST,
    label: 'Store',
    columnLabel: 'STORE',
    description: 'Store of the transaction (same as merchant account).',
    fieldType: SharedFieldTypes.MERCHANT_ACCOUNT,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    filterOptions: {
      asyncOptions: {
        query: merchantAccountsQuery,
        formatData: (data) =>
          data?.merchantAccounts?.map((ma) => ({
            value: ma.id,
            label: ma.name,
            img: ma.company?.theme.icon,
          })),
      },
      pages: [
        MatcherPages.MATCHER_CASHIER_SUMMARY,
        MatcherPages.MATCHER_CASHIER_DETAILS,
        MatcherPages.MATCHER_PAYMENT_PARTNER,
      ],
    },
    dataRole: DataRoles.DIMENSION,
  },
  [MatcherCashierFieldsEnum.MATCHER_WITHDRAW_REASON]: {
    type: FieldTypes.TEXT,
    label: 'Withdraw reason',
    columnLabel: 'WITHDRAW REASON',
    description: 'Reasons for withdrawn reconciliations (Backup POS used, Profit and Loss, Other).',
    fieldType: SharedFieldTypes.TEXT,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    isForbidden: (can) => !can('read', 'matcher-withdraw'),
    dataRole: DataRoles.DIMENSION,
  },
  [MatcherCashierFieldsEnum.MATCHER_PAYIN_PARTNER]: {
    type: FieldTypes.ASYNC_LIST,
    label: 'Payin partner',
    columnLabel: 'PAYIN PARTNER',
    description: 'The provider who handled the payment processing of the transaction.',
    fieldType: SharedFieldTypes.PAYIN_PARTNER,
    filterOptions: {
      asyncOptions: {
        query: partnersQuery,
        formatData: (data) =>
          data?.partners
            ?.filter((partner) => partner.type === 'payin')
            ?.map((partner) => ({
              value: partner.api_name,
              label: partner.name,
              img: partner.company.theme.icon,
              color: partner.company.theme.primaryColor,
            })),
      },
      defaultValue: [],
      pages: [MatcherPages.MATCHER_PAYMENT_PARTNER],
    },
    displayOptions: {
      avatar: true,
      avatarShape: 'square',
      hideLabel: true,
      pages: [MatcherPages.MATCHER_PAYMENT_PARTNER],
    },
    dataRole: DataRoles.DIMENSION,
  },
  [MatcherCashierFieldsEnum.MATCHER_MATCHED_AMOUNT]: {
    type: FieldTypes.AMOUNT,
    label: 'Matched amount',
    columnLabel: 'MATCHED AMOUNT',
    description: 'Reconciled point of sale system payment amounts.',
    fieldType: SharedFieldTypes.AMOUNT,
    displayOptions: {
      currency: Fields.CURRENCY,
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    defaultSettings: {
      format: AmountFormats.FR_CURRENCY,
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_MATCHED_BALANCE]: {
    type: FieldTypes.AMOUNT,
    label: 'Matched balance',
    columnLabel: 'MATCHED BALANCE',
    description: 'Reconciled point of sale system payment balance.',
    fieldType: SharedFieldTypes.AMOUNT,
    displayOptions: {
      currency: Fields.CURRENCY,
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    defaultSettings: {
      format: AmountFormats.FR_CURRENCY,
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_MATCHED_NOTIFICATIONS]: {
    type: FieldTypes.NUMBER,
    label: 'Matched notifications',
    columnLabel: 'MATCHED NOTIFICATIONS',
    description: 'Number of reconciled payment service provider transactions.',
    fieldType: SharedFieldTypes.NUMBER,
    displayOptions: {
      pages: [MatcherPages.MATCHER_PAYMENT_PARTNER],
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_MATCHED_TRANSACTIONS]: {
    type: FieldTypes.NUMBER,
    label: 'Matched trx ratio',
    columnLabel: 'MATCHED TRX RATIO',
    description: 'Number of reconciled point of sale system transactions.',
    fieldType: SharedFieldTypes.NUMBER,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_MATCHED_OPERATIONS]: {
    type: FieldTypes.NUMBER,
    label: 'Matched opx ratio',
    columnLabel: 'MATCHED OPX RATIO',
    description: 'Number of reconciled point of sale system operations.',
    fieldType: SharedFieldTypes.NUMBER,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_TOTAL_ORDERS]: {
    type: FieldTypes.NUMBER,
    label: 'Total orders',
    columnLabel: 'TOTAL ORDERS',
    description: 'Number of point of sale system payments.',
    fieldType: SharedFieldTypes.NUMBER,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_MATCHED_ORDERS]: {
    type: FieldTypes.NUMBER,
    label: 'Matched orders',
    columnLabel: 'MATCHED ORDERS',
    description: 'Number of reconciled point of sale system payments.',
    fieldType: SharedFieldTypes.NUMBER,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_UNMATCHED_ORDERS]: {
    type: FieldTypes.NUMBER,
    label: 'Unmatched orders',
    columnLabel: 'UNMATCHED ORDERS',
    description: 'Number of unreconciled point of sale system payments.',
    fieldType: SharedFieldTypes.NUMBER,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_TOTAL_AMOUNT]: {
    type: FieldTypes.AMOUNT,
    label: 'Total amount',
    columnLabel: 'TOTAL AMOUNT',
    description: 'Amount of point of sale system payments.',
    fieldType: SharedFieldTypes.AMOUNT,
    displayOptions: {
      currency: Fields.CURRENCY,
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    defaultSettings: {
      format: AmountFormats.FR_CURRENCY,
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_TOTAL_BALANCE]: {
    type: FieldTypes.AMOUNT,
    label: 'Total balance',
    columnLabel: 'TOTAL BALANCE',
    description: 'Balance of point of sale system payments.',
    fieldType: SharedFieldTypes.AMOUNT,
    displayOptions: {
      currency: Fields.CURRENCY,
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    defaultSettings: {
      format: AmountFormats.FR_CURRENCY,
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_TOTAL_TRANSACTIONS]: {
    type: FieldTypes.NUMBER,
    label: 'Total transactions',
    columnLabel: 'TOTAL TRANSACTIONS',
    description: 'Number of point of sale system transactions.',
    fieldType: SharedFieldTypes.NUMBER,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_TOTAL_OPERATIONS]: {
    type: FieldTypes.NUMBER,
    label: 'Total operations',
    columnLabel: 'TOTAL OPERATIONS',
    description: 'Number of point of sale system operations.',
    fieldType: SharedFieldTypes.NUMBER,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_TOTAL_NOTIFICATIONS]: {
    type: FieldTypes.NUMBER,
    label: 'Total notifications',
    columnLabel: 'TOTAL NOTIFICATIONS',
    description: 'Number of payment service provider transactions.',
    fieldType: SharedFieldTypes.NUMBER,
    displayOptions: {},
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_UNMATCHED_AMOUNT]: {
    type: FieldTypes.AMOUNT,
    label: 'Unmatched amount',
    columnLabel: 'UNMATCHED AMOUNT',
    description: 'Unreconciled point of sale system payment amounts. Discrepancy between point of sale system payments and payment service provider transactions.',
    fieldType: SharedFieldTypes.AMOUNT,
    displayOptions: {
      currency: Fields.CURRENCY,
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    defaultSettings: {
      format: AmountFormats.FR_CURRENCY,
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_UNMATCHED_BALANCE]: {
    type: FieldTypes.AMOUNT,
    label: 'Unmatched balance',
    columnLabel: 'UNMATCHED BALANCE',
    description: 'Unreconciled point of sale system payment balance.',
    fieldType: SharedFieldTypes.AMOUNT,
    displayOptions: {
      currency: Fields.CURRENCY,
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    defaultSettings: {
      format: AmountFormats.FR_CURRENCY,
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_UNMATCHED_NOTIFICATIONS]: {
    type: FieldTypes.NUMBER,
    label: 'Unmatched notifications',
    columnLabel: 'UNMATCHED NOTIFICATIONS',
    description: 'Number of unreconciled payment service provider transactions.',
    fieldType: SharedFieldTypes.NUMBER,
    displayOptions: {
      pages: [MatcherPages.MATCHER_PAYMENT_PARTNER],
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_UNMATCHED_TRANSACTIONS]: {
    type: FieldTypes.NUMBER,
    label: 'Unmatched transactions',
    columnLabel: 'UNMATCHED TRANSACTIONS',
    description: 'Number of unreconciled point of sale system transactions.',
    fieldType: SharedFieldTypes.NUMBER,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_UNMATCHED_OPERATIONS]: {
    type: FieldTypes.NUMBER,
    label: 'Unmatched operations',
    columnLabel: 'UNMATCHED OPERATIONS',
    description: 'Number of unreconciled point of sale system operations.',
    fieldType: SharedFieldTypes.NUMBER,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_REGISTER_STATUS]: {
    type: FieldTypes.LIST,
    label: 'Status',
    columnLabel: 'STATUS',
    description: 'Status of the point of sale system’s daily report.',
    fieldType: SharedFieldTypes.STATUS,
    displayOptions: {
      getOption: (value) => registerStatuses[value],
      pages: [MatcherPages.MATCHER_CASHIER_SUMMARY],
      tag: true,
      icon: true,
    },
    filterOptions: {
      options: Object.values(registerStatuses),
      pages: [MatcherPages.MATCHER_CASHIER_SUMMARY],
    },
    dataRole: DataRoles.INDICATOR,
  },
  [MatcherCashierFieldsEnum.MATCHER_PROGRAM_MANAGER]: {
    type: FieldTypes.ASYNC_LIST,
    label: 'Program manager',
    columnLabel: 'PROGRAM MANAGER',
    description: 'Program manager of the transaction.',
    fieldType: SharedFieldTypes.PROGRAM_MANAGER,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
      image: true,
    },
    filterOptions: {
      asyncOptions: {
        query: programManagersQuery,
        formatData: (data) =>
          data?.programManagers?.map((pm) => ({
            value: pm.id,
            label: pm.name,
            img: pm.theme.icon,
            color: pm.theme.secondaryColor,
          })),
      },
      defaultValue: [],
      nullable: false,
      pages: [
        MatcherPages.MATCHER_CASHIER_SUMMARY,
        MatcherPages.MATCHER_CASHIER_DETAILS,
        MatcherPages.MATCHER_PAYMENT_PARTNER,
      ],
    },
    dataRole: DataRoles.DIMENSION,
  },
  [MatcherCashierFieldsEnum.MATCHER_COMPANY]: {
    type: FieldTypes.ASYNC_LIST,
    label: 'Company',
    columnLabel: 'COMPANY',
    description: 'Company of the transaction.',
    fieldType: SharedFieldTypes.COMPANY,
    displayOptions: {
      pages: [MatcherPages.MATCHER_CASHIER_DETAILS, MatcherPages.MATCHER_CASHIER_SUMMARY],
      avatar: true,
    },
    filterOptions: {
      asyncOptions: {
        query: merchantCompaniesQuery,
        formatData: (data) =>
          data?.merchantCompanies?.map((c) => ({
            value: c.id,
            label: c.name,
            img: c.theme.icon,
            color: c.theme.secondaryColor,
          })),
      },
      defaultValue: [],
      nullable: false,
      pages: [
        MatcherPages.MATCHER_CASHIER_SUMMARY,
        MatcherPages.MATCHER_CASHIER_DETAILS,
        MatcherPages.MATCHER_PAYMENT_PARTNER,
      ],
    },
    dataRole: DataRoles.DIMENSION,
  },
};

export const MatcherCashierSummaryFieldKeyList = (mode) => [
  // dimensions
  MatcherCashierFieldsEnum.MATCHER_CASHIER,
  MatcherCashierFieldsEnum.MATCHER_NOTIFICATION_DATE,
  MatcherCashierFieldsEnum.MATCHER_OPERATION_DATE,
  MatcherCashierFieldsEnum.MATCHER_REASON,
  MatcherCashierFieldsEnum.MATCHER_RECONCILIATION_TYPE,
  MatcherCashierFieldsEnum.MATCHER_MERCHANT_ACCOUNT,
  MatcherCashierFieldsEnum.MATCHER_WITHDRAW_REASON,
  MatcherCashierFieldsEnum.MATCHER_OPERATION_TYPE,
  MatcherCashierFieldsEnum.MATCHER_REGISTER_STATUS,
  // indicators
  MatcherCashierFieldsEnum.MATCHER_MATCHED_AMOUNT,
  MatcherCashierFieldsEnum.MATCHER_MATCHED_BALANCE,
  MatcherCashierFieldsEnum.MATCHER_UNMATCHED_AMOUNT,
  MatcherCashierFieldsEnum.MATCHER_UNMATCHED_BALANCE,
  MatcherCashierFieldsEnum.MATCHER_TOTAL_AMOUNT,
  MatcherCashierFieldsEnum.MATCHER_TOTAL_BALANCE,
  MatcherCashierFieldsEnum.MATCHER_TOTAL_ORDERS,
  MatcherCashierFieldsEnum.MATCHER_MATCHED_ORDERS,
  MatcherCashierFieldsEnum.MATCHER_UNMATCHED_ORDERS,
  MatcherCashierFieldsEnum.MATCHER_TOTAL_TRANSACTIONS,
  MatcherCashierFieldsEnum.MATCHER_MATCHED_TRANSACTIONS,
  MatcherCashierFieldsEnum.MATCHER_UNMATCHED_TRANSACTIONS,
  MatcherCashierFieldsEnum.MATCHER_MATCHED_OPERATIONS,
  MatcherCashierFieldsEnum.MATCHER_UNMATCHED_OPERATIONS,
  MatcherCashierFieldsEnum.MATCHER_TOTAL_OPERATIONS,
  ...([MODES.INTERNAL, MODES.META_PM].includes(mode) ? [MatcherCashierFieldsEnum.MATCHER_PROGRAM_MANAGER] : []),
  ...([MODES.INTERNAL, MODES.META_PM, MODES.PM].includes(mode) ? [MatcherCashierFieldsEnum.MATCHER_COMPANY] : []),
];

export const MatcherCashierSummaryFields = (mode) =>
  MatcherCashierSummaryFieldKeyList(mode).reduce(
    (memo, key) => ({
      ...memo,
      [key]: {
        key,
        ...MatcherCashierFields[key],
      },
    }),
    {},
  );

export const MatcherCashierDetailFieldKeyList = (mode) => [
  MatcherCashierFieldsEnum.MATCHER_CASHIER,
  MatcherCashierFieldsEnum.MATCHER_MATCHED_AMOUNT,
  MatcherCashierFieldsEnum.MATCHER_MATCHED_BALANCE,
  MatcherCashierFieldsEnum.MATCHER_MATCHED_OPERATIONS,
  MatcherCashierFieldsEnum.MATCHER_MATCHED_ORDERS,
  MatcherCashierFieldsEnum.MATCHER_MATCHED_TRANSACTIONS,
  MatcherCashierFieldsEnum.MATCHER_MERCHANT_ACCOUNT,
  MatcherCashierFieldsEnum.MATCHER_NOTIFICATION_DATE,
  MatcherCashierFieldsEnum.MATCHER_OPERATION_DATE,
  MatcherCashierFieldsEnum.MATCHER_PAYMENT_CHANNEL,
  MatcherCashierFieldsEnum.MATCHER_PAYMENT_METHOD,
  MatcherCashierFieldsEnum.MATCHER_REASON,
  MatcherCashierFieldsEnum.MATCHER_RECONCILIATION_TYPE,
  MatcherCashierFieldsEnum.MATCHER_TOTAL_AMOUNT,
  MatcherCashierFieldsEnum.MATCHER_TOTAL_BALANCE,
  MatcherCashierFieldsEnum.MATCHER_TOTAL_OPERATIONS,
  MatcherCashierFieldsEnum.MATCHER_TOTAL_ORDERS,
  MatcherCashierFieldsEnum.MATCHER_TOTAL_TRANSACTIONS,
  MatcherCashierFieldsEnum.MATCHER_UNMATCHED_AMOUNT,
  MatcherCashierFieldsEnum.MATCHER_UNMATCHED_BALANCE,
  MatcherCashierFieldsEnum.MATCHER_UNMATCHED_OPERATIONS,
  MatcherCashierFieldsEnum.MATCHER_UNMATCHED_ORDERS,
  MatcherCashierFieldsEnum.MATCHER_UNMATCHED_TRANSACTIONS,
  MatcherCashierFieldsEnum.MATCHER_WITHDRAW_REASON,
  ...([MODES.INTERNAL, MODES.META_PM].includes(mode) ? [MatcherCashierFieldsEnum.MATCHER_PROGRAM_MANAGER] : []),
  ...([MODES.INTERNAL, MODES.META_PM, MODES.PM].includes(mode) ? [MatcherCashierFieldsEnum.MATCHER_COMPANY] : []),
];

export const MatcherCashierDetailFields = (mode) =>
  MatcherCashierDetailFieldKeyList(mode).reduce(
    (memo, key) => ({
      ...memo,
      [key]: {
        key,
        ...MatcherCashierFields[key],
      },
    }),
    {},
  );

export const NotificationFieldKeyList = (mode) => [
  MatcherCashierFieldsEnum.MATCHER_NOTIFICATION_DATE,
  MatcherCashierFieldsEnum.MATCHER_PAYIN_PARTNER,
  MatcherCashierFieldsEnum.MATCHER_PAYMENT_METHOD,
  MatcherCashierFieldsEnum.MATCHER_MERCHANT_ACCOUNT,
  MatcherCashierFieldsEnum.MATCHER_TOTAL_NOTIFICATIONS,
  MatcherCashierFieldsEnum.MATCHER_MATCHED_NOTIFICATIONS,
  MatcherCashierFieldsEnum.MATCHER_UNMATCHED_NOTIFICATIONS,
  MatcherCashierFieldsEnum.MATCHER_TOTAL_AMOUNT,
  MatcherCashierFieldsEnum.MATCHER_MATCHED_AMOUNT,
  MatcherCashierFieldsEnum.MATCHER_UNMATCHED_AMOUNT,
  ...([MODES.INTERNAL, MODES.META_PM].includes(mode) ? [MatcherCashierFieldsEnum.MATCHER_PROGRAM_MANAGER] : []),
  ...([MODES.INTERNAL, MODES.META_PM, MODES.PM].includes(mode) ? [MatcherCashierFieldsEnum.MATCHER_COMPANY] : []),
];

export const MatcherPaymentPartnerFields = (mode) =>
  NotificationFieldKeyList(mode).reduce(
    (memo, key) => ({
      ...memo,
      [key]: {
        key,
        ...MatcherCashierFields[key],
      },
    }),
    {},
  );

import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import customerAccountLoginTypes from 'norbr-shared-lib/constants/customerAccountLoginTypes/customerAccountLoginTypes';
import {useLazyQuery} from '@apollo/client';
import InfoTile, { InfoCountryRow, InfoDateRow, InfoTextRow } from '../../../Tile/Info/Info';
import { orderCustomerDetailsQuery, activityCustomerDetailsQuery } from './query';
import CustomerDetailsSkeleton from './CustomerDetailsSkeleton';

const CustomerDetails = () => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(activityCustomerDetailsQuery, {
    variables: {
      orderId,
    }
  });

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(
    orderCustomerDetailsQuery,
    {
      variables: {
        orderId,
      },
      onCompleted: data => !data.order && fetchInsights(),
    },
  );

  const transaction = orderStack?.last_transaction ?? orderInsights?.last_transaction;

  if (!transaction) return <CustomerDetailsSkeleton />;

  return (
    <InfoTile name="customer-details" title="Customer details">
      <InfoTextRow label="Email address" value={transaction.customer_email} />
      <InfoTextRow label="Customer ID" value={transaction.customer_id} />
      <InfoTextRow label="First name" value={transaction.customer_first_name} />
      <InfoTextRow label="Surname" value={transaction.customer_last_name} />
      <InfoTextRow label="ID number" value={transaction.customer_identification_number} />
      <InfoTextRow label="Gender" value={transaction.customer_gender} />
      <InfoDateRow label="Date of birth" value={transaction?.customer_birth_date} />
      <InfoTextRow label="Phone number" value={transaction.customer_phone_number} />
      <InfoTextRow label="Phone number type" value={transaction.customer_phone_number_type} />
      <InfoTextRow label="IP address" value={transaction.customer_ip} />
      <InfoCountryRow label="IP country" value={transaction.customer_ip_country} />
      <InfoTextRow label="Device type" value={transaction.device_type} />
      <InfoTextRow
        label="Login type"
        value={customerAccountLoginTypes[transaction.customer_account_login_type]?.label}
      />
    </InfoTile>
  );
};

export default CustomerDetails;

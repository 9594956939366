import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import moment from 'moment-timezone';
import * as am4core from '@amcharts/amcharts4/core';
import { ColumnSeries, DateAxis, Legend, ValueAxis, XYChart, XYCursor } from '@amcharts/amcharts4/charts';
import { Col, Row, Statistic } from 'antd';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import LordIcon from '../../../../../assets/icons/LordIcon';
import { compareByProp } from '../../../../../util/array';
import { Link } from '../../../../../util/navigate';
import { useMe } from '../../../../../contexts/me.context';
import { brainpowerQuery } from '../../../Brainpower/Dashboard/Widget/Common/query';
import styles from './BrainpowerCard.module.scss';
import InfoTile from '../../../Brainpower/OrderDetail/Tile/Info/Info';
import Loader from '../../../Common/Loader/Loader';
import Indicators from '../../../Brainpower/Dashboard/constants/indicators';
import Dimensions from '../../../Brainpower/Dashboard/constants/dimensions';
import { DATETIME_FORMAT } from '../../../../../constants/DATES';

const RANGE_IN_DAYS = 7;
const MAX_COMPANIES = 5;

const generateStackedTrend = (divId, result, args) => {
  const dateDimension = Dimensions.orderCreationDate;

  // Create chart instance
  const chart = am4core.create(divId, XYChart);

  // Create X Axes
  const xAxis = chart.xAxes.push(new DateAxis());
  xAxis.groupData = true; // allow grouping data
  xAxis.groupCount = 72; // limit before grouping
  xAxis.renderer.grid.template.disabled = true;
  // Add cursor for date based axis (disable zoom)
  chart.cursor = new XYCursor();
  chart.cursor.behavior = 'none';

  // Add Y Axis
  const valueAxis = chart.yAxes.push(new ValueAxis());
  valueAxis.renderer.line.strokeOpacity = 1;
  valueAxis.renderer.line.strokeWidth = 2;
  valueAxis.renderer.opposite = true;
  valueAxis.renderer.grid.template.disabled = true;
  valueAxis.title.text = args.currency.label;

  // const seriesList = result.data
  //   .reduce((memo, row) => [...memo, ...Object.keys(row)], [])
  //   .filter((u, pos, l) => u !== dateDimension && l.indexOf(u) === pos); // remove dateDimension && dedupe;

  // Keep top companies
  const reduceData = result.data.reduce(
    (memo, row) => ({
      ...memo,
      ...Object.keys(row)
        .filter((key) => key !== dateDimension)
        .reduce((memo2, key) => ({ ...memo2, [key]: memo[key] || 0 + row[key] || 0 }), {}),
    }),
    {},
  );
  const seriesList = Object.keys(reduceData)
    .sort((a, b) => reduceData[b] - reduceData[a])
    .slice(0, MAX_COMPANIES);

  seriesList.forEach((name, index) => {
    if (name === dateDimension) return;
    const series = chart.series.push(new ColumnSeries());

    series.dataFields.valueY = name;
    series.dataFields.dateX = dateDimension;

    // Make it stacked
    series.stacked = true;
    series.groupFields.valueY = 'sum';
    series.strokeWidth = 2;

    series.yAxis = valueAxis;
    series.yAxis.renderer.line.stroke = series.stroke;
    series.yAxis.renderer.labels.template.fill = series.stroke;
    series.name = name;

    series.showOnInit = true;

    // Display only 1 aggregated tooltip
    if (index === 0) {
      series.adapter.add('tooltipText', () => {
        let text = '[#000]{category}[/]\n';
        chart.series.each((item) => {
          text += `[${item.stroke.hex}]●[/][#000] ${item.name}: {${item.name}} ${args.currency.symbol}[/]\n`;
        });
        return text;
      });
      series.tooltip.background.fill = am4core.color('#fff');
      series.tooltip.getFillFromObject = false;
    } else {
      series.tooltip.disabled = true;
    }
  });

  // Add legend
  chart.legend = new Legend();

  // format number
  chart.numberFormatter.numberFormat = '#,###.00';

  // Increase contrast by taking every second color
  chart.colors.step = 2;
  chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd HH:mm:ss';

  chart.data = result.data
    .map((row) => ({
      [dateDimension]: row[dateDimension],
      ...seriesList.reduce((memo, key) => ({ ...memo, [key]: row[key] || 0 }), {}),
    })) // fill empty series
    .sort(compareByProp(dateDimension));

  return chart;
};

const BrainpowerCard = () => {
  const me = useMe();

  const dates = useMemo(
    () => ({
      from: moment().subtract(RANGE_IN_DAYS, 'days').startOf('day'),
      to: moment().endOf('day'),
      previous_from: moment()
        .subtract(RANGE_IN_DAYS * 2, 'days')
        .startOf('day'),
      previous_to: moment()
        .subtract(RANGE_IN_DAYS + 1, 'days')
        .endOf('day'),
    }),
    [],
  );

  const { data: trendData, loading: trendLoading } = useApolloQuery(brainpowerQuery, {
    variables: {
      service: 'serial',
      parameters: {
        currency: me.baseCurrency || 'EUR',
        dimensions: [Dimensions.orderCreationDate, Dimensions.companyName],
        granularity: 'day',
        indicators: [Indicators.authorizedTurnover],
        timezone: 'UTC',
        filters: [
          {
            name: Fields.TRANSACTION_CREATION_DATE,
            value: {
              from: dates.from.format(DATETIME_FORMAT),
              to: dates.to.format(DATETIME_FORMAT),
            },
          },
        ],
      },
    },
  });

  const { data: kpiData, loading: kpiLoading } = useApolloQuery(brainpowerQuery, {
    variables: {
      service: 'kpis',
      parameters: {
        currency: me.baseCurrency || 'EUR',
        timezone: 'UTC',
        filters: [
          {
            dates: {
              from: dates.from,
              to: dates.to,
              previous_from: dates.previous_from,
              previous_to: dates.previous_to,
              label: 'last_7_days',
            },
          },
        ],
      },
    },
  });

  const isEmpty = !(trendData?.brainpower?.data && Object.keys(trendData?.brainpower?.data).length > 0);

  const chartElem = useRef(null);

  useLayoutEffect(() => {
    if (!isEmpty) {
      const chart = generateStackedTrend(`chartdiv-trend`, trendData?.brainpower, {
        currency: CURRENCIES[me.baseCurrency || 'EUR'],
      });
      chartElem.current = chart;

      return () => {
        chart.dispose();
      };
    }
    return () => {};
  }, [isEmpty, trendData]);

  const kpis = useMemo(() => {
    if (kpiData?.brainpower?.data) {
      return kpiData.brainpower.data.reduce((memo, kpi) => ({ ...memo, ...kpi }), {});
    }
    return {};
  }, [kpiData]);

  return (
    <Link to="/brainpower">
      <InfoTile name="brainpower-card" title="Top 5 companies - Last 7 days" className={styles.root}>
        <Row gutter={[24, 24]} justify="space-around" align="middle">
          <Col lg={4} xs={24}>
            <Row
              gutter={[24, 24]}
              justify="space-around"
              align="middle"
              style={{ textAlign: 'center', margin: '48px 0' }}
            >
              <Col lg={24} sm={12} xs={24}>
                <Statistic
                  loading={kpiLoading}
                  value={kpis.authorized_turnover?.toFixed(2)}
                  title="Authorized amount"
                  suffix={CURRENCIES[me.baseCurrency || 'EUR'].symbol}
                />
              </Col>
              <Col lg={24} sm={12} xs={24}>
                <Statistic loading={kpiLoading} value={kpis.success_rate?.toFixed(2)} title="Success rate" suffix="%" />
              </Col>
            </Row>
          </Col>
          <Col lg={20} xs={24}>
            {trendLoading && <Loader size="large" className={styles.loader} />}
            {!trendLoading && isEmpty && (
              <div className={styles.noData}>
                <LordIcon
                  type="settings-sliders-morph-outline-edited"
                  width={136}
                  height={136}
                  isStopped={false}
                  autoplay
                />
                <div>No orders found during the last 24 hours</div>
              </div>
            )}
            {!isEmpty && !trendLoading && <div id="chartdiv-trend" className={styles.chartdiv} />}
          </Col>
        </Row>
      </InfoTile>
    </Link>
  );
};

export default BrainpowerCard;

import { gql } from '@apollo/client';

export const TeamAccountListQuery = gql`
  query TeamAccountListQuery($companyId: ID!) {
    merchantCompany(id: $companyId) {
      id
      merchantAccounts {
        id
        name
        cms_name
        userCount
      }
    }
  }
`;

export default {};

import React, { useState } from 'react';
import { useQuery, useQuery as useApolloQuery } from '@apollo/client';
import { Card, Collapse, List, Tag, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { DeploymentUnitOutlined, HomeOutlined, PlusSquareOutlined } from '@ant-design/icons';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { useCan } from '../../../../../contexts/ability.context';
import { contrast } from '../../../../../util/theme';
import { ListFilterHOC } from '../../../Common/ListFilter';
import { Link } from '../../../../../util/navigate';
import { search } from '../../../../../util/string';
import { PartnerCompaniesQuery } from '../../query';
import PartnerCreate from '../PartnerCreate/PartnerCreate';
import { partnerTypeList } from '../constants';
import { PartnerListQuery } from './query';
import styles from './PartnerList.module.scss';

const { Panel } = Collapse;

const breadcrumbRender = () => (
  <Breadcrumb
    map={{
      '/': <HomeOutlined />,
      '/partners': 'Partners',
    }}
  />
);

const grid = {
  gutter: 16,
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 6,
  xxl: 6,
};

const PartnerList = () => {
  const can = useCan();
  const { data, loading } = useApolloQuery(PartnerListQuery, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
  });

  const companiesQuery = useQuery(PartnerCompaniesQuery);

  const [visibleType, setVisibleType] = useState(false);

  const showCreationModalByType =
    (type = 'payin') =>
    () => {
      setVisibleType(type);
    };

  const hideCreationModal = () => {
    setVisibleType(false);
  };

  const listFilterOptions = {
    searchInput: {
      placeholder: 'Partner/Company name or ID',
      filter: (value, item) =>
        search(value, item.name) ||
        search(value, item.id) ||
        search(value, item.company?.name) ||
        search(value, item.company?.id),
    },
    fields: [
      {
        name: 'partnerId',
        label: 'ID',
        fieldType: 'input',
        fieldProps: { placeholder: 'Partner ID' },
        filter: (value, item) => search(value, item.id),
      },
      {
        name: 'name',
        label: 'Name',
        fieldType: 'input',
        fieldProps: { placeholder: 'Partner name' },
        filter: (value, item) => search(value, item.name),
      },
      {
        name: 'company',
        label: 'Company',
        fieldType: 'select',
        fieldProps: {
          options: companiesQuery.data?.partnerCompanies.map((c) => ({ value: c.id, label: c.name })),
          loading: companiesQuery.loading,
          mode: 'multiple',
          showSearch: true,
          optionFilterProp: 'label',
          placeholder: 'Select companies',
        },
        filter: (value, item) => value.length === 0 || value.includes(item.company?.id),
      },
      {
        name: 'type',
        label: 'Type',
        fieldType: 'select',
        fieldProps: {
          options: partnerTypeList,
          mode: 'multiple',
          showSearch: true,
          optionFilterProp: 'label',
          placeholder: 'Select types',
        },
        filter: (value, item) => value.length === 0 || value.includes(item.type),
      },
      {
        name: 'allow_insights',
        label: 'Insights',
        fieldType: 'checkbox',
        filter: (value, item) => value ? item.allow_insights : true,
      },
      {
        name: 'allow_stack',
        label: 'Stack',
        fieldType: 'checkbox',
        filter: (value, item) => value ? item.allow_stack : true,
      },
      {
        name: 'allow_insights_pos',
        label: 'Insights POS',
        fieldType: 'checkbox',
        filter: (value, item) => value ? item.allow_insights_pos : true,
      },
    ],
  };

  return (
    <PageHeader title="Partners" breadcrumbRender={breadcrumbRender}>
      <Typography.Paragraph className={styles.description}>Manage partner accounts</Typography.Paragraph>
      <ListFilterHOC initialList={data?.partners} options={listFilterOptions}>
        {(filteredList) => (
          <Collapse key="collapse" defaultActiveKey={partnerTypeList.map((type) => type.value)} ghost>
            {partnerTypeList.map((type) => (
              <Panel
                header={
                  <Tag key="type-tag" color={type.color}>
                    {type.label}
                  </Tag>
                }
                key={type.value}
              >
                <List
                  key={`partner-${type.value}-list`}
                  itemLayout="horizontal"
                  dataSource={[
                    ...(can('create', 'partner') ? ['add-partner'] : []),
                    ...filteredList
                      .filter((partner) => partner.type === type.value)
                      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1)),
                  ]}
                  loading={loading}
                  grid={grid}
                  renderItem={(partner) =>
                    partner === 'add-partner' ? (
                      <List.Item onClick={showCreationModalByType(type.value)}>
                        <Card
                          size="small"
                          hoverable
                          cover={<PlusSquareOutlined style={{ fontSize: 64, color: type.color, padding: 18 }} />}
                        >
                          <Card.Meta title={`Create ${type.label} Partner`} description="and company" />
                        </Card>
                      </List.Item>
                    ) : (
                      <Link to={`/partners/${partner.id}`}>
                        <List.Item>
                          <Card
                            size="small"
                            hoverable
                            cover={
                              <div style={{ height: 100, textAlign: 'center' }}>
                                <div
                                  className={styles.coverBackground}
                                  style={{ backgroundColor: partner.company.theme?.primaryColor || type.color }}
                                />
                                {partner.company.theme?.icon ? (
                                  <img alt="" src={partner.company.theme?.icon} className={styles.coverImg} />
                                ) : (
                                  <DeploymentUnitOutlined
                                    className={styles.coverIcon}
                                    style={{ color: contrast(partner.company.theme?.primaryColor, type.color) }}
                                  />
                                )}
                              </div>
                            }
                          >
                            <Card.Meta title={partner.name} description={partner.company?.name} />
                          </Card>
                        </List.Item>
                      </Link>
                    )
                  }
                  pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 24,
                    pageSizeOptions: [12, 24, 48],
                  }}
                />
              </Panel>
            ))}
          </Collapse>
        )}
      </ListFilterHOC>
      <PartnerCreate type={visibleType} onCancel={hideCreationModal} />
    </PageHeader>
  );
};

export default PartnerList;

import React from 'react';
import { Avatar, Button, Card, List, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { HomeOutlined, PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { useCan } from '../../../../../contexts/ability.context';
import ProgramManagerCreate from './ProgramManagerCreate/ProgramManagerCreate';
import useToggle from '../../../../../hooks/useToggle';
import { contrast } from '../../../../../util/theme';
import { Link } from '../../../../../util/navigate';
import { programManagerListQuery } from './query';

const ProgramManagerList = () => {
  const can = useCan();

  const { data, loading } = useQuery(programManagerListQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const [showAddProgramManager, { toggle }] = useToggle(false);

  return (
    <PageHeader
      title="Program managers"
      breadcrumbRender={() => (
        <Breadcrumb
          map={{
            '/': <HomeOutlined />,
            '/program-managers': 'Program managers',
          }}
        />
      )}
      extra={[
        can('create', 'program-manager') && (
          <Button key="create" type="primary" shape="round" icon={<PlusOutlined />} onClick={toggle}>
            Create program manager
          </Button>
        ),
      ]}
    >
      <Typography.Paragraph>Manage the program managers, users, access rights and partners</Typography.Paragraph>
      <List
        itemLayout="vertical"
        size="large"
        dataSource={data?.programManagers}
        loading={loading}
        grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
        renderItem={(programManager) => (
          <Link to={`/program-managers/${programManager.id}`}>
            <List.Item key={programManager.id}>
              <Card
                style={{ backgroundColor: programManager.theme.secondaryColor }}
                cover={
                  programManager.theme.logo ? (
                    <img alt="logo" src={programManager.theme.logo} style={{ height: 80, objectFit: 'contain' }} />
                  ) : (
                    <div style={{ height: 80, width: '100%', backgroundColor: 'lightgrey' }} />
                  )
                }
                hoverable
              >
                <Card.Meta
                  avatar={<Avatar src={programManager.theme.icon} />}
                  title={
                    <span
                      style={{
                        color: contrast(programManager.theme.secondaryColor),
                      }}
                    >
                      {programManager.name}
                    </span>
                  }
                  description={programManager.payfac ? 'Payment Facilitator' : ''}
                />
              </Card>
            </List.Item>
          </Link>
        )}
      />
      <ProgramManagerCreate visible={showAddProgramManager} toggle={toggle} />
    </PageHeader>
  );
};

export default ProgramManagerList;

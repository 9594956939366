import {gql} from '@apollo/client';


export const merchantContractRoutesQuery = gql`
  query MerchantContractRoutes($id: ID!) {
    merchantContract(id: $id) {
      id
      status
      merchant_routes {
          id
          partner_route {
              id
              authorization_currency
              payment_method {
                  api_name
                  type
              }
              is_3ds_available
              channel
          }
      }
    }
  } 
`
import React from 'react';
import InfoTile, { InfoSkeletonRow } from '../../../Tile/Info/Info';

const CustomerAddress = () => (
  <InfoTile name="customer-address" title="Customer address">
    <InfoSkeletonRow label="Street name" />
    <InfoSkeletonRow label="City" />
    <InfoSkeletonRow label="ZIP code" />
    <InfoSkeletonRow label="State" />
    <InfoSkeletonRow label="Country" />
  </InfoTile>
);

export default CustomerAddress;

import { gql } from '@apollo/client';

const query = `{
  _id
  notifications {
    _id
    id
    url
    status
    payload
    webhook {
      id
      name
    }
    merchantAccount {
      id
    }
    createdAt
  }
}`;

export const orderNotificationQuery = gql`
  query OrderNotification($orderId: ID!) {
      order(id: $orderId) ${query}
  }
`;

export const activityNotificationQuery = gql`
  query ActivityNotification($orderId: ID!) {
      activity(id: $orderId) ${query}
  }
`;

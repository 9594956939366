import React, { useEffect, useState } from 'react';
import { Drawer, Steps, Form, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useResetFormOnCloseModal } from '../../../Common/Form/utils';
import ValidationStep from './ValidationStep';
import styles from './PartnerRoutesCreate.module.scss';
import RoutePropertiesForm from './RoutePropertiesForm';
import SubmitProgress from './SubmitProgress';
import PartnerSelection from './PartnerSelection';

const PartnerRoutesCreate = ({ visible, onClose, partner }) => {
  // skip step 0 (PartnerSelection) if come from PartnerView
  const [current, setCurrent] = useState(partner ? 1 : 0);
  const [selectedPartner, setSelectedPartner] = useState(partner?.id);
  const [formValues, setFormValues] = useState();
  const [routes, setRoutes] = useState();

  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    visible,
  });

  useEffect(() => {
    if (visible) {
      setSelectedPartner(partner?.id);
    }
  }, [visible]);

  const handleClose = () => {
    setSelectedPartner(null);
    setCurrent(partner ? 1 : 0);
    onClose();
  };

  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  const handlePartnerSubmit = (val) => {
    setSelectedPartner(val);
    next();
  };

  const handleSubmitForm = (val) => {
    setFormValues(val);
    next();
  };

  const handleSubmitRoutes = (val) => {
    setRoutes(val);
    next();
  };

  return (
    <Drawer
      title={
        <Space>
          {current >= (partner ? 2 : 1) && <ArrowLeftOutlined onClick={prev} />}
          {partner ? <span>Create partner routes for {partner.name}</span> : <span>Create partner routes</span>}
        </Space>
      }
      onClose={handleClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
    >
      <Steps current={partner ? current - 1 : current} size="small">
        {!partner && <Steps.Step title="Select partner" />}
        <Steps.Step title="Select properties" />
        <Steps.Step title="Validate routes" />
        <Steps.Step title="Process" />
      </Steps>
      <div className={styles.stepContainer}>
        {current === 0 && <PartnerSelection onSubmit={handlePartnerSubmit} />}
        {current === 1 && <RoutePropertiesForm form={form} onSubmit={handleSubmitForm} />}
        {current === 2 && (
          <ValidationStep partner={selectedPartner} formValues={formValues} onSubmit={handleSubmitRoutes} />
        )}
        {current === 3 && <SubmitProgress partner={selectedPartner} routes={routes} onClose={handleClose} />}
      </div>
    </Drawer>
  );
};

export default PartnerRoutesCreate;

import { gql } from '@apollo/client';

export const registersQuery = gql`
  query Registers($offset: Int, $limit: Int, $filters: [MatcherSearchFilter], $sort: [MatcherSort]) {
    registers(offset: $offset, limit: $limit, filters: $filters, sort: $sort) {
      data {
        _id {
          merchant_account
          operation_date
        }
        currency
        matcher_cashier
        matcher_company
        matcher_matched_amount
        matcher_matched_balance
        matcher_matched_operations
        matcher_matched_orders
        matcher_matched_transactions
        matcher_merchant_account
        matcher_notification_date
        matcher_operation_date
        matcher_program_manager
        matcher_register_status
        matcher_total_amount
        matcher_total_balance
        matcher_total_operations
        matcher_total_orders
        matcher_total_transactions
        matcher_unclosable_operations
        matcher_unclosable_transactions
        matcher_unmatched_amount
        matcher_unmatched_balance
        matcher_unmatched_operations
        matcher_unmatched_orders
        matcher_unmatched_transactions
      }
      total
    }
  }
`;
export const registerDetailsQuery = gql`
  query RegisterDetails($date: String!, $merchantAccount: ID!, $sort: [MatcherSort]) {
    register(date: $date, merchantAccount: $merchantAccount, sort: $sort) {
      _id {
        merchant_account
        operation_date
      }
      details {
        currency
        matcher_matched_amount
        matcher_matched_balance
        matcher_matched_operations
        matcher_matched_orders
        matcher_matched_transactions
        matcher_notification_date
        matcher_operation_type
        matcher_reason
        matcher_reconciliation_type
        matcher_total_amount
        matcher_total_balance
        matcher_total_operations
        matcher_total_orders
        matcher_total_transactions
        matcher_unmatched_amount
        matcher_unmatched_balance
        matcher_unmatched_operations
        matcher_unmatched_orders
        matcher_unmatched_transactions
        matcher_withdraw_reason
      }
    }
  }
`;

export const matcherOverviewQuery = gql`
  query MatcherOverviewQuery(
    $dimensions: [MatcherDimension]
    $filters: [MatcherSearchFilter]
    $sort: [MatcherSort]
    $limit: Int
    $offset: Int
  ) {
    matcherOverview(dimensions: $dimensions, filters: $filters, sort: $sort, limit: $limit, offset: $offset)
  }
`;

export const closeRegisterMutation = gql`
  mutation CloseRegisterMutation($date: String!, $merchantAccount: ID!) {
    closeRegister(date: $date, merchantAccount: $merchantAccount)
  }
`;

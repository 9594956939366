import React from 'react';
import { Input, Row } from 'antd';
import { useDashboard } from '../../dashboard.context';
import styles from './chart.module.scss';

const Notepad = (props) => {
  const { widget, layout } = props;

  const { updateWidget, editable } = useDashboard();

  const handleChangeContent = (e) =>
    updateWidget({ ...widget, options: { ...widget.options, content: e.target.value } });

  // height * unit + (height-1) * margin - tile padding - tile header - notepad margin-top
  const height = layout.h * 36 + (layout.h - 1) * 24 - 2 * 8 - 32 - 12;

  return (
    <div className={styles.root}>
      <Row justify="space-between" align="middle">
        <div className={styles.title}>{widget.name}</div>
      </Row>
      <Row>
        <Input.TextArea
          placeholder="Type here"
          value={widget?.options?.content}
          style={{ height }}
          onChange={handleChangeContent}
          className={styles.notepad}
          bordered={false}
          disabled={!editable}
        />
      </Row>
    </div>
  );
};

export default Notepad;

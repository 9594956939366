import { gql } from '@apollo/client';

export const PartnerListQuery = gql`
  query PartnerListQuery {
    partners {
      allow_insights
      allow_stack
      allow_insights_pos
      id
      status
      name
      type
      code
      company {
        id
        name
        status
        theme {
          primaryColor
          icon
        }
      }
    }
  }
`;

export default {};

import CurrencyEur from '@2fd/ant-design-icons/lib/CurrencyEur';
import FingerprintIcon from '@2fd/ant-design-icons/lib/Fingerprint';
import CurrencyUsdIcon from '@2fd/ant-design-icons/lib/CurrencyUsd';
import WebIcon from '@2fd/ant-design-icons/lib/Web';
import BankTransferOutIcon from '@2fd/ant-design-icons/lib/BankTransferOut';
import ClipboardTextIcon from '@2fd/ant-design-icons/lib/ClipboardText';
import StoreIcon from '@2fd/ant-design-icons/lib/Store';
import CogsIcon from '@2fd/ant-design-icons/lib/Cogs';
import CardsIcon from '@2fd/ant-design-icons/lib/Cards';
import CreditCardOutlineIcon from '@2fd/ant-design-icons/lib/CreditCardOutline';
import AbTestingIcon from '@2fd/ant-design-icons/lib/AbTesting';
import React from 'react';

export default [
  {
    id: 'amount',
    icon: <CurrencyEur />,
    name: 'Amount',
  },
  {
    id: 'authenticationLevel',
    icon: <FingerprintIcon />,
    name: 'Authentication level',
  },
  {
    id: 'authorizationCurrency',
    icon: <CurrencyUsdIcon />,
    name: 'Authorization currency',
  },
  {
    id: 'customerIpCountry',
    icon: <WebIcon />,
    name: 'Country (IP)',
  },
  {
    id: 'issuerCountry',
    icon: <WebIcon />,
    name: 'Country (issuer)',
  },
  {
    id: 'issuer',
    icon: <BankTransferOutIcon />,
    name: 'Issuer',
  },
  {
    id: 'list',
    icon: <ClipboardTextIcon />,
    name: 'List',
  },
  {
    id: 'merchantData',
    icon: <CogsIcon />,
    name: 'Merchant data',
  },
  {
    id: 'merchantStoreId',
    icon: <StoreIcon />,
    name: 'Merchant store ID',
  },
  {
    id: 'paymentChannel',
    icon: <ClipboardTextIcon />,
    name: 'Payment channel',
  },
  {
    id: 'paymentMethodName',
    icon: <CardsIcon />,
    name: 'Payment method name',
  },
  {
    id: 'paymentMethodType',
    icon: <CreditCardOutlineIcon />,
    name: 'Payment method type',
  },
  {
    id: 'splitter',
    icon: <AbTestingIcon />,
    name: 'A/B testing',
  },
]
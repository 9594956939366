import React from 'react';
import styles from './icon.module.scss';

const StackBar = ({ color = 'inherit' }) => (
  <span className={styles.icon}>
    <svg width="20" height="20" viewBox="0 0 300 300">
      <path
        fill={color}
        // eslint-disable-next-line max-len
        d="M15729,960h-300V660h25V935h275v25Zm-53-44V692h25V916h-25Zm6-145h0V910h13V771Zm-53,145V729h25V916h-25Zm6-106V910h13V810Zm-53,106V791h25V916h-25Zm6-75h0v69h13V841Zm-53,75V756h25V916h-25Zm6-90v84h13V826Zm-53,90V815h25V916h-25Zm6-62h0v56h13V854Z"
        transform="translate(-15429 -659.999)"
      />
    </svg>
  </span>
);

export default StackBar;

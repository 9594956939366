import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import NOTIFICATION_STATUSES from 'norbr-shared-lib/constants/notification/status';
import { Tag } from 'antd';
import TRANSACTION_STATUSES from '../../../../../../constants/TRANSACTION_STATUSES';
import InfoTile, {
  InfoDateTimeRow,
  InfoLinkRow,
  InfoRow,
  InfoTextRow,
  ResponsiveBodyTile,
} from '../../../OrderDetail/Tile/Info/Info';
import NotificationStatusSkeleton from './NotificationStatusSkeleton';
import { notificationStatusQuery } from './query';
import { useCan } from '../../../../../../contexts/ability.context';

const NotificationStatus = () => {
  const can = useCan();
  const { notificationId } = useParams();

  const { data, loading } = useQuery(notificationStatusQuery, {
    variables: {
      notificationId,
    },
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-and-network',
  });

  if (!data?.notification || loading) return <NotificationStatusSkeleton />;

  const { notification } = data;

  return (
    <InfoTile name="notification-status" title="Notification">
      <ResponsiveBodyTile>
        <InfoTextRow label="Notification ID" value={notification._id} />
        <InfoRow label="Status" value={notification.status}>
          <Tag color={NOTIFICATION_STATUSES[notification.status]?.color}>
            {NOTIFICATION_STATUSES[notification.status]?.label}
          </Tag>
        </InfoRow>
        <InfoDateTimeRow label="Creation" value={notification.createdAt} />
        <InfoRow label="Trigger" value={notification.payload?.status}>
          <Tag key={notification.payload?.status} color={TRANSACTION_STATUSES[notification.payload?.status]?.color}>
            {TRANSACTION_STATUSES[notification.payload?.status]?.label}
          </Tag>
        </InfoRow>
        <InfoTextRow label="URL" value={notification.url} />
        {can('read', 'api_key') && <InfoTextRow label="Secret key" value={notification.merchantAccount.secret_key} />}
        <InfoLinkRow
          label="Order ID"
          value={notification.payload?.order_id}
          to={`/brainpower/orders/${notification.payload?.order_id}`}
        />
        <InfoTextRow label="Transaction ID" value={notification.payload?.transaction_id} />
      </ResponsiveBodyTile>
    </InfoTile>
  );
};

export default NotificationStatus;

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Col, Divider, Form, Input, message, Row, Space, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import InfoTile, { BaseRow, InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { MerchantAccountUpdateMutation } from '../query';
import styles from '../MerchantAccountView.module.scss';
import { useCan } from '../../../../../../contexts/ability.context';

const RedirectUrls = ({ merchantAccount }) => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);

  const [updateMerchantAccount, { loading }] = useMutation(MerchantAccountUpdateMutation, {
    onCompleted: () => {
      message.success('Merchant account successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    updateMerchantAccount({
      variables: { id: merchantAccount.id, input: values },
    });
  };

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="ma-redirect-urls" title="Redirect Urls">
        <Form
          form={form}
          title="Redirect Urls"
          layout="vertical"
          initialValues={merchantAccount}
          onFinish={handleFinish}
          requiredMark={false}
          className="tile-form"
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label="Accept URL"
                name="accept_url"
                rules={[
                  {
                    required: merchantAccount.attributeListRequiredByPartners.includes('merchantAccount.accept_url'),
                    message: 'Accept URL is required by a partner configuration.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label="Decline URL"
                name="decline_url"
                rules={[
                  {
                    required: merchantAccount.attributeListRequiredByPartners.includes('merchantAccount.decline_url'),
                    message: 'Decline URL is required by a partner configuration.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label="Pending URL"
                name="pending_url"
                rules={[
                  {
                    required: merchantAccount.attributeListRequiredByPartners.includes('merchantAccount.pending_url'),
                    message: 'Pending URL is required by a partner configuration.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label="Exception URL"
                name="exception_url"
                rules={[
                  {
                    required: merchantAccount.attributeListRequiredByPartners.includes('merchantAccount.exception_url'),
                    message: 'Exception URL is required by a partner configuration.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {/*<BaseRow label="Variables">*/}
          {/*  <Typography.Text copyable>ORDER_ID</Typography.Text>*/}
          {/*  <Divider type="vertical" />*/}
          {/*  <Typography.Text copyable>TRANSACTION_ID</Typography.Text>*/}
          {/*  <Divider type="vertical" />*/}
          {/*  <Typography.Text copyable>MERCHANT_REFERENCE</Typography.Text>*/}
          {/*</BaseRow>*/}
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  return (
    <InfoTile
      name="ma-redirect-urls"
      title="Redirect Urls"
      extra={[
        can('update', 'merchant_account') && (
          <Button
            key="update-merchant_account"
            onClick={() => setEditMode(true)}
            shape="text"
            icon={<EditOutlined />}
          />
        ),
      ]}
    >
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <InfoTextRow label="Accept URL" value={merchantAccount.accept_url} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <InfoTextRow label="Decline URL" value={merchantAccount.decline_url} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <InfoTextRow label="Pending URL" value={merchantAccount.pending_url} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <InfoTextRow label="Exception URL" value={merchantAccount.exception_url} />
        </Col>
      </Row>
    </InfoTile>
  );
};

export default RedirectUrls;

import React from 'react';
import LordIcon from '../../../../assets/icons/LordIcon';
import styles from './ErrorBoundary.module.scss';

const PageFallback = () => (
  <div className={styles.root}>
    <LordIcon type="error-outline-edited" width={136} height={136} isStopped={false} autoplay />
    <div className={styles.message}>Something went wrong, please retry later</div>
  </div>
);
export default PageFallback;

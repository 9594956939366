import React from 'react';
import styles from './BlockContent.module.scss';

const BlockContent = (props) => {
  const { children } = props;

  return <div className={styles.root}>{children}</div>;
};

export default BlockContent;

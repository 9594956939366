import React from 'react';
import { Draggable } from '@hello-pangea/dnd';

const getStyle = (snapshot, style) => {
  if (style.transform) {
    // custom style to lock drag vertically
    const axisLockY = `translate(0px${style.transform.slice(style.transform.indexOf(','), style.transform.length)}`;
    return {
      ...style,
      transform: axisLockY,
    };
  }
  return style;
};

const SortableItem = (props) => {
  const { children, id, isDragDisabled, index } = props;

  return (
    <>
      <Draggable key={id} draggableId={id} index={index} isDragDisabled={isDragDisabled}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle(snapshot, provided.draggableProps.style)}
          >
            {children}
          </div>
        )}
      </Draggable>
    </>
  );
};

export default SortableItem;

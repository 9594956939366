import React from 'react';
import { Button, Drawer, Form, Input, Select } from 'antd';
import ArrowLeftIcon from '@2fd/ant-design-icons/lib/ArrowLeft';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import moment from 'moment-timezone';
import { useBrainpowerSearchSettings } from '../../../hooks/useBrainpowerSettings';

const SavedSearchSaveContent = ({ search, onClose }) => {
  const [{ favorite, searches }, update] = useBrainpowerSearchSettings();

  const [form] = Form.useForm();

  const searchId = Form.useWatch('search', form);
  const labelValue = Form.useWatch('label', form);

  const handleFinish = (values) => {
    // update existing saved search
    if (searchId) {
      update({
        favorite,
        searches: searches.map((s) => (s._id === searchId ? { ...s, searchFilters: search.searchFilters ?? {}, dateFilter: search.dateFilter } : s)),
      }).then(() => onClose());
    }
    // save new search
    else {
      update({
        favorite,
        searches: [...searches, { searchFilters: search.searchFilters ?? {}, dateFilter: search.dateFilter, label: values.label, createdAt: moment().toISOString() }],
      }).then(() => onClose());
    }
  };

  return [
    <Form
      key="saved-search-save-form"
      form={form}
      onFinish={handleFinish}
      layout="vertical"
      style={{ padding: '24px 24px' }}
      requiredMark={false}
    >
      {searches.length ? (
        <Form.Item name="search" label="Update search">
          <Select
            disabled={!!labelValue}
            options={searches.map((value) => ({
              value: value._id,
              label: value.label,
            }))}
            onChange={(value) =>
              form.setFieldsValue({
                search: value,
                label: '',
              })
            }
            onClear={() => form.setFieldValue('search', null)}
            allowClear
          />
        </Form.Item>
      ) : null}
      <Form.Item name="label" label="New search">
        <Input
          disabled={!!searchId}
          onChange={(event) =>
            form.setFieldsValue({
              search: null,
              label: event.target.value,
            })
          }
        />
      </Form.Item>
      <Form.Item shouldUpdate style={{ marginTop: 40 }}>
        {() => (
          <Button block type="primary" htmlType="submit" disabled={!labelValue && !searchId}>
            SAVE
          </Button>
        )}
      </Form.Item>
    </Form>,
  ];
};

const SavedSearchSaveDrawer = ({ open, onClose, onBack, containerId, search = {} }) => (
  <Drawer
    width={470}
    open={open}
    getContainer={`#${containerId}`}
    style={{ position: 'absolute', zIndex: 50 }}
    bodyStyle={{ padding: 0 }}
    onClose={onClose}
    destroyOnClose
    title="Search / Save search filters"
    closable
    closeIcon={<WindowCloseIcon />}
    extra={<ArrowLeftIcon onClick={onBack} />}
    mask={false}
    push={false}
    autoFocus={false}
  >
    <SavedSearchSaveContent search={search} onClose={onBack} />
  </Drawer>
);

export default SavedSearchSaveDrawer;

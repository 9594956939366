import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { compareByProp } from '../../../../../../util/array';
import { config as dimensionsConfig } from '../../constants/dimensions';

export default (divId, widget, data) => {
  const { dimensions, options } = widget;

  const dimension = dimensionsConfig[dimensions[0]];

  // Create container
  const container = am4core.create(divId, am4core.Container);
  container.width = am4core.percent(100);
  container.height = am4core.percent(100);
  container.layout = 'horizontal';

  // Create 1rst pie chart
  const chart = container.createChild(am4charts.PieChart);
  chart.numberFormatter.numberFormat = '#.';

  // Map data (2 dimensions)
  chart.data = [...data.data]
    .map(({ [dimension.id]: name, ...dim2Values }) => ({
      name,
      children: Object.keys(dim2Values).map((key) => ({ name: key, value: dim2Values[key] })),
    }))
    .map((row) => ({ ...row, total: row.children.reduce((memo, child) => memo + child.value, 0) }))
    .sort(compareByProp('total', 'desc'))
    .slice(0, options?.slice_items || 8);

  // ------------------------------------

  // Add and configure Series
  const pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = 'total';
  pieSeries.dataFields.category = 'name';
  pieSeries.slices.template.states.getKey('active').properties.shiftRadius = 0;

  const chart2 = container.createChild(am4charts.PieChart);
  chart2.width = am4core.percent(30);
  chart2.radius = am4core.percent(80);

  // Add and configure Series
  const pieSeries2 = chart2.series.push(new am4charts.PieSeries());
  pieSeries2.dataFields.value = 'value';
  pieSeries2.dataFields.category = 'name';
  pieSeries2.slices.template.states.getKey('active').properties.shiftRadius = 0;
  pieSeries2.labels.template.disabled = true;
  pieSeries2.ticks.template.disabled = true;
  pieSeries2.alignLabels = false;

  const interfaceColors = new am4core.InterfaceColorSet();

  const line1 = container.createChild(am4core.Line);
  line1.strokeDasharray = '2,2';
  line1.strokeOpacity = 0.5;
  line1.stroke = interfaceColors.getFor('alternativeBackground');
  line1.isMeasured = false;

  const line2 = container.createChild(am4core.Line);
  line2.strokeDasharray = '2,2';
  line2.strokeOpacity = 0.5;
  line2.stroke = interfaceColors.getFor('alternativeBackground');
  line2.isMeasured = false;

  let selectedSlice;

  function updateLines() {
    if (selectedSlice) {
      let p11 = {
        x: selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle),
        y: selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle),
      };
      let p12 = {
        x: selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle + selectedSlice.arc),
        y: selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle + selectedSlice.arc),
      };

      p11 = am4core.utils.spritePointToSvg(p11, selectedSlice);
      p12 = am4core.utils.spritePointToSvg(p12, selectedSlice);

      let p21 = { x: 0, y: -pieSeries2.pixelRadius };
      let p22 = { x: 0, y: pieSeries2.pixelRadius };

      p21 = am4core.utils.spritePointToSvg(p21, pieSeries2);
      p22 = am4core.utils.spritePointToSvg(p22, pieSeries2);

      line1.x1 = p11.x;
      line1.x2 = p21.x;
      line1.y1 = p11.y;
      line1.y2 = p21.y;

      line2.x1 = p12.x;
      line2.x2 = p22.x;
      line2.y1 = p12.y;
      line2.y2 = p22.y;
    }
  }
  function selectSlice(dataItem) {
    if (!dataItem) return;
    selectedSlice = dataItem.slice;

    const { fill } = selectedSlice;

    const count = dataItem.dataContext.children.length;
    pieSeries2.colors.list = [];
    for (let i = 0; i < count; i += 1) {
      pieSeries2.colors.list.push(fill.brighten((i * 2) / count));
    }

    chart2.data = dataItem.dataContext.children;
    pieSeries2.appear();

    const { middleAngle } = selectedSlice;
    const firstAngle = pieSeries.slices.getIndex(0).startAngle;
    const animation = pieSeries.animate(
      [
        { property: 'startAngle', to: firstAngle - middleAngle },
        { property: 'endAngle', to: firstAngle - middleAngle + 360 },
      ],
      600,
      am4core.ease.sinOut,
    );
    animation.events.on('animationprogress', updateLines);

    selectedSlice.events.on('transformed', updateLines);
  }

  pieSeries2.events.on('positionchanged', updateLines);
  pieSeries.slices.template.events.on('hit', (event) => {
    selectSlice(event.target.dataItem);
  });

  chart.events.on('datavalidated', () => {
    setTimeout(() => {
      selectSlice(pieSeries.dataItems.getIndex(0));
    }, 1000);
  });

  return chart;
};

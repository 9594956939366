import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorBoundary from '../../Common/ErrorBoundary/ErrorBoundary';
import MerchantAccountView from './MerchantAccountView/MerchantAccountView';
import MerchantAccountList from './MerchantAccountList/MerchantAccountList';
import Page from '../../Common/Page/Page';

const MerchantAccounts = () => (
  <ErrorBoundary>
    <Routes>
      <Route index element={<MerchantAccountList />} />
      <Route
        path=":merchantAccountId"
        element={
          <Page>
            <MerchantAccountView />
          </Page>
        }
      />
    </Routes>
  </ErrorBoundary>
);

export default MerchantAccounts;

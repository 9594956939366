import React from 'react';
import { useParams } from 'react-router-dom';
import {useLazyQuery} from '@apollo/client';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import InfoTile, { InfoTextRow } from '../../../Tile/Info/Info';
import BrowserInformationSkeleton from './BrowserInformationSkeleton';
import { orderBrowserInformationQuery, activityBrowserInformationQuery } from './query';

const BrowserInformation = () => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(activityBrowserInformationQuery, {
    variables: {
      orderId,
    }
  });

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(
    orderBrowserInformationQuery,
    {
      variables: {
        orderId,
      },
      onCompleted: data => !data.order && fetchInsights(),
    },
  );

  const transaction = orderStack?.last_transaction ?? orderInsights?.last_transaction;

  if (!transaction) return <BrowserInformationSkeleton />;

  return (
    <InfoTile name="browser-information" title="Browser information">
      <InfoTextRow label="Accept header value" value={transaction.customer_browser_info_accept_header} />
      <InfoTextRow label="Color depth" value={transaction.customer_browser_info_color_depth} />
      <InfoTextRow label="Java enabled" value={transaction.customer_browser_info_java_enabled} />
      <InfoTextRow label="JS enabled" value={transaction.customer_browser_info_js_enabled} />
      <InfoTextRow label="Language" value={transaction.customer_browser_info_language} />
      <InfoTextRow label="Screen height" value={transaction.customer_browser_info_screen_height} />
      <InfoTextRow label="Screen width" value={transaction.customer_browser_info_screen_width} />
      <InfoTextRow label="Time zone" value={transaction.customer_browser_info_time_zone} />
    </InfoTile>
  );
};

export default BrowserInformation;

import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { Col, Row, Skeleton, Space, Tag } from 'antd';
import { CheckCircleOutlined, CopyOutlined } from '@ant-design/icons';
import tileStyles from '../Tile.module.scss';
import styles from './Info.module.scss';
import useCopyToClipboard from '../../../../../../hooks/useCopyToClipboard';
import { isEmpty } from '../../../../../../util/string';
import { DATE_FORMAT, DATETIME_FORMAT } from '../../../../../../constants/DATES';
import COUNTRIES from '../../../../../../constants/COUNTRIES';

export const InfoRow = ({ children, label, value, extraClass, loading }) => {
  const [copyStatus, copy] = useCopyToClipboard(value);

  if (loading) {
    return (
      <div className={classnames(styles.row, extraClass)}>
        <div className={styles.rowLabel}>{label}</div>
        <Skeleton.Input
          active
          loading
          style={{
            width: Math.floor(Math.random() * 200 + 30),
            height: '22px',
          }}
        />
      </div>
    )
  }

  return (
    <div className={classnames(styles.row, extraClass)}>
      <div className={styles.rowLabel}>{label}</div>
      {isEmpty(value) ? (
        <div className={styles.rowNull}>n/a</div>
      ) : (
        <>
          {children}
          {copyStatus === 'copied' ? (
            <CheckCircleOutlined className={styles.checkCopyIcon} />
          ) : (
            <CopyOutlined className={styles.copyIcon} onClick={copy} role="button" tabIndex={0} onKeyDown={copy} />
          )}
        </>
      )}
    </div>
  );
};

export const BaseRow = ({ children, label }) => (
  <div className={styles.row}>
    <div className={styles.rowLabel}>{label}</div>
    <div>{children}</div>
  </div>
);

export const InfoBooleanRow = ({ label, value, loading }) => (
  <InfoRow label={label} value={value} loading={loading}>
    {value ? 'Yes' : 'No'}
  </InfoRow>
);

export const InfoDateRow = ({ label, value, loading }) => (
  <InfoRow label={label} value={value} loading={loading}>
    {value === '******' ? value : value && moment(value).format(DATE_FORMAT)}
  </InfoRow>
);

export const InfoDateTimeRow = ({ label, value, loading }) => (
  <InfoRow label={label} value={value} loading={loading}>
    {value && moment(value).format(DATETIME_FORMAT)}
  </InfoRow>
);

export const InfoTextRow = ({ label, value, className, loading }) => (
  <InfoRow label={label} value={value} extraClass={className} loading={loading}>
    {value}
  </InfoRow>
);

export const InfoIconRow = ({ label, value, icon: Icon, loading }) => (
  <InfoRow label={label} value={value} loading={loading}>
    <Space>
      {Icon && <Icon />}
      {value}
    </Space>
  </InfoRow>
);

export const InfoLinkRow = ({ label, value, to, target, disabled = false, loading }) => (
  <InfoRow label={label} value={value} loading={loading}>
    {disabled ? (
      value
    ) : (
      <Link to={to} target={target}>
        {value}
      </Link>
    )}
  </InfoRow>
);

export const InfoNumberRow = ({ label, value, unit, loading }) => (
  <InfoRow label={label} value={value} loading={loading}>
    {value} {unit}
  </InfoRow>
);

export const InfoAmountRow = ({ label, value, currency }) => (
  <InfoRow label={label} value={value}>
    {value?.toLocaleString('fr-FR', { style: 'currency', currency })}
  </InfoRow>
);

export const InfoSkeletonRow = ({ label }) => (
  <BaseRow label={label}>
    <Skeleton.Button
      style={{
        height: 18,
        width: Math.floor(Math.random() * 220 + 40),
      }}
    />
  </BaseRow>
);

export const InfoImgRow = ({ img, label, value }) => (
  <InfoRow label={label} value={value}>
    <Space>
      {img && <img src={img} alt={`img-${value}`} className={styles.img} />}
      {value}
    </Space>
  </InfoRow>
);

export const InfoTagsRow = ({ label, value }) => (
  <InfoRow label={label} value={value}>
    <Space>
      {value.map((tag) => (
        <Tag key={tag}>{tag}</Tag>
      ))}
    </Space>
  </InfoRow>
);

export const InfoCountryRow = ({ label, value }) => (
  <InfoImgRow value={COUNTRIES[value]?.label} label={label} img={COUNTRIES[value]?.img} />
);

export const ResponsiveBodyTile = ({ children }) => (
  <Row gutter={24}>
    {children?.map((child, index) => (
      <Col key={`col_${index + 1}`} xs={24} md={12} lg={8}>
        {child}
      </Col>
    ))}
  </Row>
);

export const InfoTileFallback = ({ className, name, title }) => (
  <div id={`tile-${name}-error`} className={classnames(className, tileStyles.root)}>
    <Row justify="space-between" align="middle" gutter={8} style={{ marginBottom: 8 }}>
      <Col flex={1} className={tileStyles.title}>
        {title}
      </Col>
    </Row>
    <div>An error occurred, please contact support.</div>
  </div>
);

const InfoTile = ({ children, className, name, title, titleIcon, extra }) => (
  <div id={`tile-${name}`} className={classnames(className, tileStyles.root)}>
    <Row justify="space-between" align="middle" gutter={8} style={{ marginBottom: 8 }}>
      {titleIcon}
      <Col flex={1} className={tileStyles.title}>
        {title}
      </Col>
      <Col>{extra}</Col>
    </Row>
    <div>{children}</div>
  </div>
);

export default InfoTile;

import { gql } from '@apollo/client';

export const exportListQuery = gql`
  query ExportList {
    exports {
      id
      name
      description
      status
      createdAt
      schedule
      format
      delimiter
      sendingMethod
      filters {
        dateFilter
        searchFilters
      }
      view
      reportCount
      lastExecutionDate
      scheduleCron {
        nextRunAt
        disabled
        failCount
      }
    }
  }
`;

import React, { useState } from 'react';
import { Layout, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import FeatureSearchIcon from '@2fd/ant-design-icons/lib/FeatureSearch';
import ForwardburgerIcon from '@2fd/ant-design-icons/lib/Forwardburger';
import BackburgerIcon from '@2fd/ant-design-icons/lib/Backburger';
import CogBoxIcon from '@2fd/ant-design-icons/lib/CogBox';
import AccountMultipleIcon from '@2fd/ant-design-icons/lib/AccountMultiple';
import Drawers from '../../../Brainpower/Common/constants/drawers';
import { DrawerContext } from '../../../Brainpower/Common/useDrawer';
import { useLayoutMenu } from '../../../../layout.context';
import brainpowerStyles from '../../../Brainpower/Brainpower.module.scss';
import { formatEndUnderline } from '../../../Brainpower/Common/utils';
import HeaderButton from'../../../Brainpower/Common/HeaderButton/HeaderButton';
import SearchDrawer from '../../../Brainpower/Common/SearchDrawer/SearchDrawer';
import SetUpDrawer from '../../../Brainpower/Common/SetUpDrawer/SetUpDrawer';
import UserTable from './UserTable/UserTable';

const UserList = () => {
  const [isMenuCollapsed, toggleMenu] = useLayoutMenu();

  const [drawerState, setDrawerState] = useState();
  const handleToggleDrawer = (key) => () => setDrawerState(drawerState !== key ? key : undefined);
  const handleCloseDrawer = () => setDrawerState(undefined);

  return (
    <DrawerContext.Provider value={[drawerState, setDrawerState]}>
      <Layout className={brainpowerStyles.root}>
        <PageHeader
          ghost={false}
          className={brainpowerStyles.pageHeader}
          backIcon={isMenuCollapsed ? <ForwardburgerIcon /> : <BackburgerIcon />}
          onBack={toggleMenu}
          title={
            <Space>
              <AccountMultipleIcon />
              {formatEndUnderline('Internal Users')}
            </Space>
          }
          extra={[
            <HeaderButton
              drawerState={drawerState}
              key={Drawers.SEARCH}
              id={Drawers.SEARCH}
              label="SEARCH"
              icon={FeatureSearchIcon}
              onClick={handleToggleDrawer(Drawers.SEARCH)}
            />,
            <HeaderButton
              drawerState={drawerState}
              key={Drawers.SETUP}
              id={Drawers.SETUP}
              label="DISPLAY"
              icon={CogBoxIcon}
              onClick={handleToggleDrawer(Drawers.SETUP)}
            />,
          ]}
        />
        <UserTable />
        <SearchDrawer open={drawerState === Drawers.SEARCH} onClose={handleCloseDrawer} containerId="internal-user-table" />
        <SetUpDrawer open={drawerState === Drawers.SETUP} onClose={handleCloseDrawer} containerId="internal-user-table" />
      </Layout>
    </DrawerContext.Provider>
  );
};

export default UserList;

import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'antd';
import { PartitionOutlined } from '@ant-design/icons';
import styles from './Badge.module.scss';
import { MODES, useMode } from '../../../../contexts/mode.context';
import { contrast } from '../../../../util/theme';

const MetaProgramManagerBadge = ({ metaProgramManager, children }) => {
  const mode = useMode();
  // displayed only for internals
  if (mode !== MODES.INTERNAL || !metaProgramManager) return children;
  return (
    <Badge.Ribbon
      className={styles.badge}
      text={
        <Link to={`/meta-program-managers/${metaProgramManager.id}`}>
          {metaProgramManager?.theme?.icon ? (
            <img
              alt=""
              src={metaProgramManager.theme.icon}
              title={metaProgramManager.name}
              style={{ backgroundColor: metaProgramManager.theme.secondaryColor }}
            />
          ) : (
            <div
              style={{
                backgroundColor: metaProgramManager.theme.secondaryColor,
                color: contrast(metaProgramManager.theme.secondaryColor),
                width: 22,
                textAlign: 'center',
              }}
            >
              <PartitionOutlined />
            </div>
          )}
        </Link>
      }
    >
      {children}
    </Badge.Ribbon>
  );
};

export default MetaProgramManagerBadge;

import { useMemo } from 'react';
import useWindowSize from './useWindowSize';

const defaultBreakpoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

const useBreakpoint = (breakpoints = defaultBreakpoints) => {
  const { width } = useWindowSize();
  const breakpoint = useMemo(() => {
    if (!width) return undefined;
    if (width >= breakpoints.xxl) return 'xxl';
    if (width >= breakpoints.xl) return 'xl';
    if (width >= breakpoints.lg) return 'lg';
    if (width >= breakpoints.md) return 'md';
    if (width >= breakpoints.sm) return 'sm';
    return 'xs';
  }, [width]);
  return breakpoint;
};

export default useBreakpoint;

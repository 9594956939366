import React, { useState } from 'react';
import {Avatar, Button, Checkbox, message, Select, Space, Tag} from 'antd';
import { CheckOutlined, DeleteOutlined, PlusOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import ViewCarouselIcon from '@2fd/ant-design-icons/lib/ViewCarousel';
import { useParams } from 'react-router-dom';
import TableTile, { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import { CompanyProfileListQuery } from '../../../AccessControl/query';
import {
  CompanyUserDeleteMutation,
  CompanyUserIsGdprMutation,
  CompanyUserProfileMutation
} from '../../query';
import styles from './CompanyUserList.module.scss';
import { initials, search } from '../../../../../../util/string';
import UserCreate from '../../../Users/UserCreate/UserCreate';
import { useCan } from '../../../../../../contexts/ability.context';
import { compareByProp } from '../../../../../../util/array';
import { Link } from '../../../../../../util/navigate';
import USER_STATUSES, { userStatusList } from '../../../../../../constants/USER_STATUSES';
import { CompanyUserQuery } from './query';

const CompanyUserList = () => {
  const can = useCan();
  const { companyId } = useParams();

  const { data: { merchantCompany } = { merchantCompany: null}, loading} = useQuery(CompanyUserQuery, {
    variables: {
      companyId
    }
  });

  const profilesQuery = useQuery(CompanyProfileListQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { companyId },
  });

  const [updateCompanyUserProfile] = useMutation(CompanyUserProfileMutation, {
    onCompleted: () => {
      message.success('User profile successfully updated.');
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleChangeProfile = (companyUserId) => (profileId) => {
    updateCompanyUserProfile({
      variables: {
        id: companyUserId,
        aclProfile: profileId,
      },
    });
  };

  const [updateCompanyUserIsGdpr] = useMutation(CompanyUserIsGdprMutation, {
    onCompleted: () => {
      message.success('User GDPR access successfully updated.');
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleChangeIsGdpr = (companyUserId) => (e) => {
    updateCompanyUserIsGdpr({
      variables: {
        id: companyUserId,
        isGdpr: e.target.checked,
      },
    });
  };

  const [detachUser] = useMutation(CompanyUserDeleteMutation, {
    onError: (err) => message.error(err.message),
    // update CompanyUser without refetch
    update(cache, { data: { deleteCompanyUser: id } }) {
      const normalizedId = cache.identify({
        id,
        __typename: 'MerchantCompanyUser',
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const handleDetach = (companyUserId) => () =>
    detachUser({
      variables: {
        id: companyUserId,
      },
    });

  const dataSource = merchantCompany?.users?.map(({ id, user, aclProfile, isGdpr, guest, authorizedMerchantAccountsCount }) => ({
    key: id,
    userId: user.id,
    avatar: user.avatar,
    name: user.full_name,
    email: user.email,
    status: user.status,
    disabled: user.disabled,
    aclProfile: aclProfile.id,
    aclProfileName: aclProfile.name,
    isGdpr,
    guest,
    authorizedMerchantAccountsCount,
  }));

  const columns = [
    {
      title: 'Fullname',
      dataIndex: 'name',
      key: 'name',
      render: (name, { userId, avatar, guest }) =>
        guest ? (
          <Link to={`/internal-users/${userId}`}>
            <Space>
              <Avatar src={avatar}>{initials(name)}</Avatar>
              {name}
              <UserSwitchOutlined className={styles.badgeUserGuest} title="Connected as guest" />
            </Space>
          </Link>
        ) : (
          <Link to={`/users/${userId}`}>
            <Space>
              <Avatar src={avatar}>{initials(name)}</Avatar>
              {name}
            </Space>
          </Link>
        ),
      fixed: 'left',
      ...getColumnFilterSearchInput((value, record) => search(value, record.name)),
      sorter: compareByProp('name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      ...getColumnFilterSearchInput((value, record) => search(value, record.email)),
      sorter: compareByProp('email'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, { disabled }) =>
        disabled ? (
          <Tag color="volcano">Locked</Tag>
        ) : (
          status &&
          USER_STATUSES[status] && <Tag color={USER_STATUSES[status].color}>{USER_STATUSES[status].label}</Tag>
        ),
      filters: [
        { value: 'disabled', text: 'Locked' },
        ...userStatusList.map((s) => ({
          value: s.value,
          text: s.label,
        })),
      ],
      onFilter: (value, record) => (record.disabled ? value === 'disabled' : value === record.status),
    },
    {
      dataIndex: 'authorizedMerchantAccountsCount',
      key: 'authorizedMerchantAccountsCount',
      width: 48,
      render: (value) => (
        <Tag icon={<ViewCarouselIcon />} title={`${value} merchant accounts`}>
          {value}
        </Tag>
      ),
    },
    {
      title: 'Profile',
      dataIndex: 'aclProfile',
      key: 'aclProfile',
      width: 220,
      render: can('update-rights', 'user')
        ? (value, { key: companyUserId }) => (
            <Select
              className={styles.profileSelect}
              value={value}
              loading={profilesQuery.loading}
              options={profilesQuery.data?.merchantCompany.programManager.merchantAclProfiles.map((p) => ({
                value: p.id,
                label: p.name,
              }))}
              onChange={handleChangeProfile(companyUserId)}
              bordered={false}
            />
          )
        : (value, { aclProfileName }) => aclProfileName,
      filters: profilesQuery.data?.merchantCompany.programManager.merchantAclProfiles.map((p) => ({
        value: p.name,
        text: p.name,
      })),
      onFilter: (value, record) => value === record.aclProfileName,
      sorter: compareByProp('aclProfileName'),
    },
    {
      title: 'GDPR',
      dataIndex: 'isGdpr',
      key: 'isGdpr',
      align: 'center',
      width: 80,
      render: can('update-rights', 'user')
        ? (value, { key: companyUserId }) => <Checkbox checked={value} onChange={handleChangeIsGdpr(companyUserId)} />
        : (value) => value && <CheckOutlined />,
      sorter: compareByProp('isGdpr'),
    },
  ];

  if (can('manage-users', 'company')) {
    columns.push({
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 80,
      render: (value, { key: companyUserId }) => [
        <Button
          key="delete-user"
          icon={<DeleteOutlined />}
          onClick={handleDetach(companyUserId)}
          title="Remove user from company"
          shape="circle"
          type="text"
        />,
      ],
    });
  }

  const [visible, setVisible] = useState(false);

  const showCreationModal = () => {
    setVisible(true);
  };

  const hideCreationModal = () => {
    setVisible(false);
  };

  // return info tile
  return (
    <>
      <TableTile
        name="company-users"
        title="Users"
        columns={columns}
        dataSource={dataSource}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} users`,
        }}
        size="small"
        scroll={{ x: 1000 }}
        loading={loading}
        extra={[
          can('create', 'user') && !loading && can('manage-users', 'company') && (
            <Button key="create" icon={<PlusOutlined />} onClick={showCreationModal}>
              Create user
            </Button>
          ),
        ]}
      />
      <UserCreate
        visible={visible}
        onCancel={hideCreationModal}
        companyId={companyId}
        programManager={merchantCompany?.programManager?.id}
      />
    </>
  );
};

export default CompanyUserList;

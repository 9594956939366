import { gql } from '@apollo/client';

export const merchantAccountQuery = gql`
  query MerchantAccount($id: ID!) {
    merchantAccount(id: $id) {
      id
      name
      source
      company {
        id
        name
        mainContact {
          email
          phoneNumber
        }
        address
        city
        zipCode
        country
        dateStart
        numberEmployee
        legalForm
        vatNumber
        ape
        duns
        mcc
        ceo
        sharesHolder
        activity
        specialization
      }
      programManager {
        payfac
      }
      reference_currency
      partnerData
      cms_name
      cms_version
      accept_url
      decline_url
      pending_url
      exception_url
    }
  }
`;

import { gql } from '@apollo/client';

export const merchantAccountNotificationsQuery = gql`
  query MerchantAccountWebhooks($id: ID!) {
    merchantAccount(id: $id) {
      id
      webhooks {
        id
        name
        url
        trigger
        status
        additional_headers
        notify_last_transaction_only
      }
    }
  }
`;

export const removeNotificationMutation = gql`
  mutation RemoveWebhook($id: ID!) {
    removeWebhook(id: $id) {
      id
    }
  }
`;

import moment from 'moment-timezone';
import DateFormats from '../constants/dateFormats';
import NumberFormats, { numberFormats } from '../constants/numberFormats';
import AmountFormats, { amountFormats } from '../constants/amountFormats';

export const dateFormatter = (value, format = DateFormats.DATETIME) => moment(value).format(format);

export const byteFormatter = (value, decimals = 2) => {
  if (!+value) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(value) / Math.log(k));

  return `${parseFloat((value / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const numberFormatter = (value, format = NumberFormats.FR, options = {}) =>
  Intl.NumberFormat(numberFormats[format].locale, options).format(value);

export const amountFormatter = (value, currency, format = AmountFormats.FR_CURRENCY, options = {}) =>
{
  try {
    return Intl.NumberFormat(amountFormats[format].locale, {
      currency,
      style: 'currency',
      currencyDisplay: amountFormats[format].currency ? 'symbol' : 'code',
      ...options,
    })
      .format(value)
      .replace(amountFormats[format].currency ? '' : currency, '')
      .trim();
  } catch (e) {
    console.warn(e.message);
    return 'err';
  }
}

import { gql } from '@apollo/client';

export const programManagerListQuery = gql`
  query ProgramManagers {
    programManagers {
      id
      name
      payfac
      company {
        name
      }
      theme {
        logo
        icon
        primaryColor
        secondaryColor
      }
    }
  }
`;

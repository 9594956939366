import React from 'react';
import { Layout } from 'antd';
import ForwardburgerIcon from '@2fd/ant-design-icons/lib/Forwardburger';
import BackburgerIcon from '@2fd/ant-design-icons/lib/Backburger';
import styles from './Header.module.scss';
import { useLayoutMenu } from '../../../../layout.context';

const { Header: AntHeader } = Layout;

/**
 * Fixed Header
 */
const Header = (props) => {
  const { children, centerContent, title } = props;

  const [isMenuCollapsed, toggleMenu] = useLayoutMenu();

  return (
    <AntHeader className={styles.root} style={{ width: `calc(100% - ${isMenuCollapsed ? 80 : 200}px)` }}>
      <div>
        {React.createElement(isMenuCollapsed ? ForwardburgerIcon : BackburgerIcon, {
          className: styles.trigger,
          onClick: toggleMenu,
        })}
        {title}
      </div>
      {children}
      {centerContent && (
        <div
          style={{
            opacity: 0,
            pointerEvents: 'none',
          }}
        >
          {React.createElement(isMenuCollapsed ? ForwardburgerIcon : BackburgerIcon, {
            className: styles.trigger,
            onClick: toggleMenu,
          })}
          {title}
        </div>
      )}
    </AntHeader>
  );
};

export default Header;

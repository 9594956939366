import React from 'react';
import { useQuery } from '@apollo/client';
import TableTile, { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import { search } from '../../../../../../util/string';
import { useCan } from '../../../../../../contexts/ability.context';
import { compareByProp } from '../../../../../../util/array';
import { Link } from '../../../../../../util/navigate';
import { MetaProgramManagerProgramManagersQuery } from './query';

const MetaProgramManagerProgramManagers = ({ id }) => {
  const can = useCan();

  const { data } = useQuery(MetaProgramManagerProgramManagersQuery, { variables: { id } });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, { id }) => (
        <Link to={`/program-managers/${id}`}>
          {name}
        </Link>
      ),
      fixed: 'left',
      ...getColumnFilterSearchInput((value, record) => search(value, record.name)),
      sorter: compareByProp('name'),
    },
    // {
    //   title: 'Fullname',
    //   dataIndex: 'name',
    //   key: 'name',
    //   render: (name, { userId, avatar }) => (
    //     <Link to={`/program-users/${userId}`}>
    //       <Space>
    //         <Avatar src={avatar}>{initials(name)}</Avatar>
    //         {name}
    //       </Space>
    //     </Link>
    //   ),
    //   fixed: 'left',
    //   ...getColumnFilterSearchInput((value, record) => search(value, record.name)),
    //   sorter: compareByProp('name'),
    // },
    // {
    //   title: 'Email',
    //   dataIndex: 'email',
    //   key: 'email',
    //   ellipsis: true,
    //   ...getColumnFilterSearchInput((value, record) => search(value, record.email)),
    //   sorter: compareByProp('email'),
    // },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   render: (status, { disabled }) =>
    //     disabled ? (
    //       <Tag color="volcano">Locked</Tag>
    //     ) : (
    //       status &&
    //       USER_STATUSES[status] && <Tag color={USER_STATUSES[status].color}>{USER_STATUSES[status].label}</Tag>
    //     ),
    //   filters: [
    //     { value: 'disabled', text: 'Locked' },
    //     ...userStatusList.map((s) => ({
    //       value: s.value,
    //       text: s.label,
    //     })),
    //   ],
    //   onFilter: (value, record) => (record.disabled ? value === 'disabled' : value === record.status),
    // },
    // {
    //   title: 'Profile',
    //   dataIndex: 'aclProfile',
    //   key: 'aclProfile',
    //   width: 220,
    //   render: (value, { aclProfileName }) => aclProfileName,
    //   filters: data?.metaProgramManagerAclProfiles.map((p) => ({
    //     value: p.name,
    //     text: p.name,
    //   })),
    //   onFilter: (value, record) => value === record.aclProfileName,
    //   sorter: compareByProp('aclProfileName'),
    // },
  ];

  // return info tile
  return (
    <>
      <TableTile
        name="meta-program-manager-program-managers"
        title="Program Managers"
        columns={columns}
        dataSource={data?.metaProgramManager.programManagers}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} program managers`,
        }}
        size="small"
        scroll={{ x: 1000 }}
      />
    </>
  );
};

export default MetaProgramManagerProgramManagers;

import React, { useMemo, useState } from 'react';
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  Layout,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import ReactHeight from 'react-height';
import FeatureSearchIcon from '@2fd/ant-design-icons/lib/FeatureSearch';
import classnames from 'classnames';
import SearchFilterTags from '../../Brainpower/Common/SearchFilterTags/SearchFilterTags';
import Drawers from '../../Brainpower/Common/constants/drawers';
import useDrawer from '../../Brainpower/Common/useDrawer';
import styles from './LogTable.module.scss';
import useSort from '../../Brainpower/hooks/useSort';
import useColumnSet from '../../Brainpower/hooks/useColumnSet';
import useTableDisplay from '../../Brainpower/hooks/useTableDisplay';
import buildColumns from '../../Brainpower/Common/AdvancedTable/buildColumns';
import { TargetEntity } from '../../Brainpower/Common/constants/targetEntities';
import { useGdpr } from '../../../../contexts/me.context';
import useLogListQuery from './useLogListQuery';
import { TableDisplay } from '../../Brainpower/Common/constants/tableDisplays';
import usePagination from '../../Brainpower/hooks/usePagination';

const LogTable = ({ searchFilters, onSearch }) => {
  const [columnSet] = useColumnSet();
  const { page, pageSize, setPage } = usePagination();

  const [sort, setSort] = useSort();
  const gdpr = useGdpr();

  const [tableDisplay] = useTableDisplay();

  const [, setDrawer] = useDrawer();

  const [height, setHeight] = useState();

  // List of effective filters (remove empty)
  const list = useMemo(
    () =>
      Object.keys(searchFilters)
        .reduce((memo, key) => [...memo, { key, ...searchFilters[key] }], [])
        // remove empty filters
        .filter(({ value }) => value !== undefined && value !== null && (!Array.isArray(value) || value.length > 0)),
    [searchFilters],
  );

  const columns = buildColumns(columnSet, sort, TableDisplay.RICH, TargetEntity.LOG, gdpr).map((column) => {
    if (column.key === 'log_entity_id') {
      return {
        ...column,
        render: (value) => (typeof value !== 'string' ? '' : value),
      };
    }
    return column;
  });

  const handleTableChange = (_p, _f, sorter) => {
    // handle single sort, default from favorite view
    if (Array.isArray(sorter)) {
      setSort([{ field: sorter[1].field, sortOrder: sorter[1].order }]);
    } else if (sorter.order) {
      setSort([{ field: sorter.field, sortOrder: sorter.order }]);
    } else {
      setSort();
    }
  };

  const { result, total, loading, error } = useLogListQuery({ list });

  return (
    <Layout>
      <Layout.Header className={styles.layoutHeader}>
        <Row gutter={24} style={{ position: 'relative' }}>
          <Col>
            <Space>
              Results
              <Tag style={{ color: 'darkgrey' }}>{total}</Tag>
            </Space>
          </Col>
          <Col flex="1" style={{ position: 'initial' }}>
            <SearchFilterTags list={list} onSearch={onSearch} />
          </Col>
        </Row>
      </Layout.Header>
      <Layout id="log-table" className={styles.layoutContent}>
        <Layout.Content>
          <ReactHeight onHeightReady={setHeight} style={{ height: '100%' }}>
            {height && (
              <ConfigProvider
                renderEmpty={() => (
                  <Empty
                    className={styles.empty}
                    style={{ height: height - 74 }}
                    image={loading ? null : undefined}
                    description={(() => {
                      if (loading) {
                        return null;
                      }
                      if (error) {
                        return (
                          <Typography.Text type="danger">An error occurred, please contact support.</Typography.Text>
                        );
                      }
                      return [
                        <div key="no-results-found">No results found</div>,
                        <Button
                          key="adjust-filters"
                          type="link"
                          icon={<FeatureSearchIcon style={{ fontSize: 18 }} />}
                          onClick={() => setDrawer(Drawers.SEARCH)}
                        >
                          Adjust filters
                        </Button>,
                      ];
                    })()}
                  />
                )}
              >
                <Table
                  style={{ minHeight: height - 74 }}
                  className={classnames(styles.table, {
                    [styles.richDisplay]: tableDisplay === TableDisplay.RICH,
                  })}
                  columns={columns}
                  dataSource={result}
                  loading={loading && { size: 'large' }}
                  size="small"
                  scroll={{ x: 'max-content', y: height - 40 }} // available height - head row height
                  pagination={false}
                  rowKey={(record) => record.id}
                  sortDirections={['descend', 'ascend']}
                  onChange={handleTableChange}
                />
              </ConfigProvider>
            )}
          </ReactHeight>
        </Layout.Content>
        <Layout.Footer className={styles.layoutFooter}>
          <Row gutter={24} justify="space-between">
            <Col>
              <Space>
                <Pagination
                  className={styles.pagination}
                  total={total}
                  current={page}
                  pageSize={pageSize}
                  onChange={setPage}
                  showSizeChanger={false}
                  showLessItems
                  size="small"
                />
                <Select
                  value={pageSize}
                  onSelect={(value) => setPage(1, value)}
                  options={[{ value: 10 }, { value: 20 }, { value: 50 }]}
                  bordered={false}
                />
              </Space>
            </Col>
          </Row>
        </Layout.Footer>
      </Layout>
    </Layout>
  );
};

export default LogTable;

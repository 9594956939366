import { gql } from '@apollo/client';

export const rulesQuery = gql`
  query MerchantAccountRules($merchantAccountId: ID!) {
    merchantAccount(id: $merchantAccountId) {
      id
      rules(filters: { status: "active" }) {
        id
        name
        status
        order
      }
    }
  }
`;

export const updateMerchantAccountRulesOrder = gql`
  mutation UpdateMerchantAccountRulesOrder($id: ID!, $input: MerchantAccountRulesInput!) {
    updateMerchantAccountRulesOrder(id: $id, input: $input) {
      id
      rules(filters: { status: "active" }) {
        id
        order
      }
    }
  }
`;

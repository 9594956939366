import {gql} from '@apollo/client';

export const CompanyMiscQuery = gql`
  query CompanyMiscQuery($companyId: ID!) {
    merchantCompany(id: $companyId) {
      id
      vatNumber
      ape
      duns
      capital
      numberEmployee
      attributeListRequiredByPartners
    }
  }
`;
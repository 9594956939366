import React, { useState } from 'react';
import { Button, Image, Space, Upload } from 'antd';
import { CameraOutlined, EyeOutlined } from '@ant-design/icons';
import TrashCanIcon from '@2fd/ant-design-icons/lib/TrashCan';
import styles from './Uploader.module.scss';

const ImgUploader = ({ value, onChange, label, info, accept = 'image/*', disabled }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Space>
      <Upload
        listType="picture-card"
        className={styles.upload}
        multiple={false}
        showUploadList={false}
        customRequest={({ file }) => onChange(file)}
        accept={accept}
        disabled={disabled}
      >
        {value ? (
          [
            <img src={value} alt="avatar" className={styles.img} />,
            <Space size={4} className={styles.btnContainer}>
              {disabled || (
                <Button
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={<TrashCanIcon />}
                  onClick={(e) => {
                    onChange(null);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              )}
              <Button
                type="primary"
                shape="circle"
                size="small"
                icon={<EyeOutlined />}
                onClick={(e) => {
                  setVisible(true);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            </Space>,
          ]
        ) : (
          <CameraOutlined style={{ fontSize: 'large', color: 'grey' }} />
        )}
      </Upload>
      <div>
        <div>{label}</div>
        {info && <div className={styles.info}>{info}</div>}
      </div>
      <Image
        width={0}
        style={{ display: 'none' }}
        src={value}
        preview={{
          visible,
          src: value,
          onVisibleChange: setVisible,
        }}
      />
    </Space>
  );
};

export default ImgUploader;

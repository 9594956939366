import { gql } from '@apollo/client';

export const settingsQuery = gql`
  {
    me {
      id
      orderLayouts
    }
  }
`;

export const updateOrderLayoutsMutation = gql`
  mutation UpdateOrderLayouts($layouts: Object!) {
    updateOrderLayouts(orderLayouts: $layouts) {
      id
      orderLayouts
    }
  }
`;

import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import {useLazyQuery} from '@apollo/client';
import InfoTile, { InfoAmountRow, InfoTextRow } from '../../../Tile/Info/Info';
import { orderBasketSummaryQuery, activityBasketSummaryQuery } from './query';
import BasketSummarySkeleton from './BasketSummarySkeleton';

const BasketSummary = () => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(activityBasketSummaryQuery, {
    variables: {
      orderId,
    }
  });

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(orderBasketSummaryQuery, {
    variables: {
      orderId,
    },
    onCompleted: data => !data.order && fetchInsights(),
  });

  const order = orderStack ?? orderInsights;

  if (!order) return <BasketSummarySkeleton />;

  const vat = order?.baskets?.reduce((sum, item) => sum + item.product_tax_amount, 0) || 0;
  const numberOfSellers = [...new Set(order?.baskets?.map((item) => item.seller_id))].length;

  return (
    <InfoTile name="basket-summary" title="Basket summary">
      <InfoAmountRow label="Amount" value={order?.amount} currency={order?.currency} />
      <InfoTextRow label="Number of items" value={order?.baskets?.length} />
      <InfoAmountRow label="VAT" value={vat} currency={order?.currency} />
      <InfoTextRow label="Number of sellers" value={numberOfSellers} />
    </InfoTile>
  );
};

export default BasketSummary;

import React from 'react';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Avatar,
  Button,
  Col,
  Divider,
  Layout,
  message,
  Modal,
  PageHeader,
  Row,
  Select,
  Switch,
  Tag,
  Typography,
} from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  CodeOutlined,
  DeleteOutlined,
  HomeOutlined,
  IdcardOutlined,
  LockFilled,
  SafetyCertificateOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import {
  MetaProgramManagerUserAclProfileUpdateMutation,
  MetaProgramManagerUserAuditLogsQuery,
  MetaProgramManagerUserGdprUpdateMutation,
  MetaProgramManagerUserViewQuery,
  UserDeleteMutation,
  UserDisabledUpdateMutation,
} from './query';
import styles from './UserView.module.scss';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import HandleApolloError from '../../../Common/HandleApolloError/HandleApolloError';
import AnchorList from '../../../Common/AnchorList/AnchorList';
import Profile from './Profile/Profile';
import AuditLogs from '../../../Common/Tile/AuditLogs/AuditLogs';
import { initials, isValidDomain } from '../../../../../util/string';
import { useCan } from '../../../../../contexts/ability.context';
import { useMe } from '../../../../../contexts/me.context';
import { getUserStatus } from '../../../../../constants/USER_STATUSES';
import Loader from '../../../Common/Loader/Loader';
import useNavigateWithSearch from '../../../../../util/navigate';
import { useMetaProgramManager } from '../../../../../contexts/metaProgramManager.context';
import MetaProgramManagerAvatar from '../../../Common/MetaProgramManager/Avatar';

const { Content, Sider } = Layout;

const SEND_INVITATION_EMAIL = gql`
  mutation SendInvitationEmail($id: ID!) {
    sendInvitationEmail(id: $id)
  }
`;

const EmailVerificationAlert = ({ user }) => {
  const {
    id,
    email_verification_token: emailVerificationToken,
    email_verification_token_expires_at: expiresAt,
    metaProgramManager,
  } = user;

  const [sendInvitationEmail, { called, loading }] = useMutation(SEND_INVITATION_EMAIL, {
    variables: { id },
    onCompleted: () => message.success(`Invitation email sent to ${user.email}`),
    onError: () => message.error('An error occurred, please retry later.'),
  });

  if (!emailVerificationToken) return null;
  if (moment() > moment(expiresAt)) {
    return (
      <Alert
        className={styles.alert}
        message="Password creation URL is expired."
        description="Please contact the support team."
        type="warning"
      />
    );
  }
  return (
    <Alert
      className={styles.alert}
      message="Password creation required"
      description={
        <div>
          <Typography.Paragraph>
            This user will be inactive until password is set. Please send him this URL.
          </Typography.Paragraph>
          <Typography.Paragraph copyable>
            {`https://${metaProgramManager.adminDomain}/set-password?email_verification_token=${emailVerificationToken}`}
          </Typography.Paragraph>
          <Typography.Paragraph>
            This URL will expire&nbsp;
            {moment(expiresAt).fromNow()}.
          </Typography.Paragraph>
        </div>
      }
      action={[
        <Button key="sendInvitationEmail" onClick={() => sendInvitationEmail()} loading={loading} disabled={called}>
          Send invitation email
        </Button>,
      ]}
      type="warning"
    />
  );
};

const UserDisabledInfo = ({ user }) => {
  if (!user?.disabled || !user?.activated) return null;

  if (!isValidDomain(user.email, user.metaProgramManager.emailDomains)) {
    return <div className={styles.description}>Email domain is unauthorized.</div>;
  }

  if (!user.disabledBy) {
    return (
      <div className={styles.description}>
        Locked by&nbsp;
        <b title="Potentials reasons : user failed 3 login attempts">Security</b>
        &nbsp;on&nbsp;
        <b>{moment(user.disabledAt).format('LLL')}</b>.
      </div>
    );
  }

  return (
    <div className={styles.description}>
      Locked by&nbsp;
      {user.disabledBy.avatar && <Avatar src={user.disabledBy.avatar} size={12} />}
      &nbsp;
      <b>{user.disabledBy.full_name}</b>
      &nbsp;on&nbsp;
      <b>{moment(user.disabledAt).format('LLL')}</b>.
    </div>
  );
};

const anchors = [
  {
    hash: '#tile-user-profile',
    icon: IdcardOutlined,
    label: 'Profile',
  },
  {
    hash: '#tile-entity-logs',
    icon: CodeOutlined,
    label: 'Logs',
  },
];

const AsyncLogs = () => {
  const { userId } = useParams();
  const { data, loading } = useQuery(MetaProgramManagerUserAuditLogsQuery, {
    variables: {
      id: userId,
    },
  });
  return loading ? <Loader /> : <AuditLogs entity={data?.metaProgramManagerUser} />;
};

const UserView = () => {
  const can = useCan();
  const metaProgramManager = useMetaProgramManager();
  const me = useMe();
  const navigate = useNavigateWithSearch();
  const { userId } = useParams();

  const { data, loading, error } = useQuery(MetaProgramManagerUserViewQuery, {
    variables: {
      id: userId,
    },
  });

  const [updateMetaProgramManagerUserAclProfile] = useMutation(MetaProgramManagerUserAclProfileUpdateMutation, {
    // onCompleted: () => message.success('User role successfully updated'),
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      message.error('An error occurred, please try again later.');
    },
  });

  const [updateMetaProgramManagerUserGdpr] = useMutation(MetaProgramManagerUserGdprUpdateMutation, {
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      message.error('An error occurred, please try again later.');
    },
  });

  const [updateUserDisabled] = useMutation(UserDisabledUpdateMutation, {
    // onCompleted: (res) => message.success(res.updateUserDisabled?.disabled ? 'User successfully disabled' : 'User successfully enabled'),
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      message.error('An error occurred, please try again later.');
    },
  });

  const [deleteUser, deleteUserState] = useMutation(UserDeleteMutation, {
    onCompleted: () => {
      message.success('User successfully deleted');
      navigate('/meta-program-users');
    },
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      message.error('An error occurred, please try again later.');
    },
  });

  const breadcrumbRender = (name) => () => (
    <Breadcrumb
      map={{
        '/': <HomeOutlined />,
        '/meta-program-users': metaProgramManager ? 'Internal Users' : ' Meta Program Manager Users',
        [`/meta-program-users/${userId}`]: name,
      }}
    />
  );

  if (loading) {
    return (
      <PageHeader title="..." breadcrumbRender={breadcrumbRender('...')} onBack={() => navigate('/program-users')}>
        <Loader size="large" />
      </PageHeader>
    );
  }

  const user = data.metaProgramManagerUser;

  const status = getUserStatus(user?.status);

  const handleChangeAclProfile = (value) => {
    if (me.id === userId) {
      return Modal.confirm({
        title: 'You are updating your own profile',
        content: 'You may not be able to re-update it after.',
        onOk: () =>
          updateMetaProgramManagerUserAclProfile({
            variables: { id: userId, aclProfile: value },
            optimisticResponse: {
              updateRightsOfMetaProgramManagerUser: {
                __typename: 'MetaProgramManagerUser',
                id: userId,
                aclProfile: value,
              },
            },
          }),
      });
    }
    return updateMetaProgramManagerUserAclProfile({
      variables: { id: userId, aclProfile: value },
      optimisticResponse: {
        updateRightsOfMetaProgramManagerUser: {
          __typename: 'MetaProgramManagerUser',
          id: userId,
          aclProfile: value,
        },
      },
    });
  };

  const handleChangeGpdrAccess = (value) =>
    updateMetaProgramManagerUserGdpr({
      variables: { id: userId, isGdpr: value },
      optimisticResponse: {
        updateMetaProgramManagerUserGdpr: {
          __typename: 'MetaProgramManagerUser',
          id: userId,
          isGdpr: value,
        },
      },
    });

  const handleDisabled = (value) =>
    updateUserDisabled({
      variables: { id: userId, disabled: !value },
      optimisticResponse: {
        updateDisabledOfMetaProgramManagerUser: {
          __typename: 'MetaProgramManagerUser',
          id: userId,
          disabled: !value,
        },
      },
    });

  const handleDelete = () => {
    Modal.confirm({
      title: `Delete ${user.full_name}`,
      content: 'Are you sure you want to delete this user?',
      onOk: () =>
        deleteUser({
          variables: { id: userId },
        }),
    });
  };

  let lockTitle = user.disabled ? 'Unlock user' : 'Lock user';
  if (!user.activated) {
    lockTitle = 'Cannot unlock - user is not activated';
  }
  if (!isValidDomain(user.email, user.metaProgramManager.emailDomains)) {
    lockTitle = 'Cannot unlock - email domain is unauthorized';
  }

  return (
    <PageHeader
      title={user.full_name}
      breadcrumbRender={breadcrumbRender(user.full_name)}
      onBack={() => navigate('/meta-program-users')}
      tags={
        user?.disabled ? (
          <Tag key="disabled-tag" color="volcano">
            Locked
          </Tag>
        ) : (
          <Tag key="status-tag" color={status.color}>
            {status.label}
          </Tag>
        )
      }
      avatar={{ src: user.avatar, children: initials(user.full_name) }}
      subTitle={user.email}
      extra={[
        <Select
          key="select-aclProfile"
          value={user.aclProfile?.id}
          onChange={handleChangeAclProfile}
          options={user.metaProgramManager.metaProgramManagerAclProfiles.map((p) => ({
            value: p.id,
            label: p.name,
          }))}
          disabled={!can('update-rights', 'meta-program-manager-user')}
          suffixIcon={<SafetyCertificateOutlined />}
          dropdownMatchSelectWidth={false}
        />,
        <Switch
          key="switch-gdpr"
          checked={user.isGdpr}
          onChange={handleChangeGpdrAccess}
          checkedChildren="GDPR"
          unCheckedChildren="No GDPR"
          title={user.isGdpr ? 'Remove access to personal data' : 'Grant access to personal data'}
          disabled={!can('update-rights', 'meta-program-manager-user')}
        />,
        <Divider type="vertical" />,
        me.id !== userId && (
          <Switch
            key="switch-disabled"
            checked={!user.disabled}
            onChange={handleDisabled}
            checkedChildren={<UnlockOutlined />}
            unCheckedChildren={<LockFilled />}
            title={lockTitle}
            disabled={
              !can('update-disabled', 'meta-program-manager-user') ||
              !user.activated ||
              !isValidDomain(user.email, user.metaProgramManager.emailDomains)
            }
          />
        ),
        me.id !== userId && can('delete', 'meta-program-manager-user') && (
          <Button
            key="delete-user"
            title="Delete user"
            icon={<DeleteOutlined />}
            shape="circle"
            onClick={handleDelete}
            loading={deleteUserState.loading}
          />
        ),
        <MetaProgramManagerAvatar key="meta-program-manager-avatar" metaProgramManager={user.metaProgramManager} />,
      ]}
    >
      <HandleApolloError error={error}>
        <UserDisabledInfo user={user} />
        <EmailVerificationAlert user={user} />
        <Layout className={styles.subPage}>
          <Sider className={styles.anchorSider} width={24}>
            <AnchorList anchors={anchors} />
          </Sider>
          <Content>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Profile user={user} />
              </Col>
              <Col span={24}>
                <AsyncLogs />
              </Col>
            </Row>
          </Content>
        </Layout>
      </HandleApolloError>
    </PageHeader>
  );
};

export default UserView;

import { gql } from '@apollo/client';

export const updateOtherSplitterMutation = gql`
    mutation UpdateOtherSplitterMutation($id: ID!, $input: SplitterInput!) {
        updateOtherSplitter(id: $id, input: $input) {
            id
            type
            color
            other {
                id
                type
                color
                other {
                    id
                }
                branches {
                    id
                }
                contract {
                    id
                    partner {
                        id
                    }
                }
                blocking
            }
            branches {
                id
            }
            contract {
                id
                partner {
                    id
                }
            }
            blocking
        }
    }
`;
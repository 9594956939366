import React from 'react';
import { useParams } from 'react-router-dom';
import {Col, Layout, Modal, Row, Select, Space, Tag} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {useMutation, useQuery} from '@apollo/client';
import {
  ApiOutlined,
  BankOutlined,
  BranchesOutlined,
  CheckSquareOutlined,
  CodeOutlined,
  ContactsOutlined,
  ContainerOutlined,
  DeploymentUnitOutlined,
  EnvironmentOutlined, ExclamationCircleOutlined,
  HomeOutlined,
  MergeCellsOutlined,
  NodeIndexOutlined,
  UnorderedListOutlined, WarningOutlined,
} from '@ant-design/icons';
import {PartnerViewQuery, PartnerAuditLogsQuery, PartnerUpdateMutation} from './query';
import styles from './PartnerView.module.scss';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import HandleApolloError from '../../../Common/HandleApolloError/HandleApolloError';
import AnchorList from '../../../Common/AnchorList/AnchorList';
import AuditLogs from '../../../Common/Tile/AuditLogs/AuditLogs';
import CompanyActivity from './CompanyActivity/CompanyActivity';
import CompanyAddress from './CompanyAddress/CompanyAddress';
import MainContact from './MainContact/MainContact';
import BillingContact from './BillingContact/BillingContact';
import PartnerRoutes from './PartnerRoutes/PartnerRoutes';
import { PartnerTypes, partnerTypes } from '../constants';
import PartnerRouteDefaultOptions from './PartnerRouteDefaultOptions/PartnerRouteDefaultOptions';
import PartnerReconciliationData from './PartnerReconciliationData/PartnerReconciliationData';
import CompanyMisc from './CompanyMisc/CompanyMisc';
import { useCan } from '../../../../../contexts/ability.context';
import Endpoints from './Endpoints/Endpoints';
import ActiveOnProgramManagers from './ActiveOnProgramManagers/ActiveOnProgramManagers';
import Loader from '../../../Common/Loader/Loader';
import useNavigateWithSearch from '../../../../../util/navigate';
import RiskOptions from './RiskOptions/RiskOptions';
import PartnerSources from './PartnerSources/PartnerSources';
import PartnerFieldsConfiguration from './PartnerFieldsConfiguration/PartnerFieldsConfiguration';
import MerchantAttributeConfiguration from './MerchantAttributeConfiguration/MerchantAttributeConfiguration';
import AuthenticationOptions from './AuthenticationOptions/AuthenticationOptions';

const { Content, Sider } = Layout;

const partnerStatuses = {
  active: {
    label: 'Active',
    color: 'green',
    badge: 'success',
  },
  inactive: {
    label: 'Inactive',
    color: 'gold',
    badge: 'warning',
  },
  maintenance: {
    label: 'Maintenance',
    color: 'red',
    badge: 'warning',
  },
};

const breadcrumbRender = (id, name) => () => (
  <Breadcrumb
    map={{
      '/': <HomeOutlined />,
      '/partners': 'Partners',
      [`/partners/${id}`]: name,
    }}
  />
);

const AsyncLogs = () => {
  const { partnerId } = useParams();
  const { data, loading } = useQuery(PartnerAuditLogsQuery, {
    variables: {
      id: partnerId,
    },
  });

  return loading ? <Loader /> : <AuditLogs entity={data?.partner} />;
};

const PartnerView = () => {
  const can = useCan();
  const navigate = useNavigateWithSearch();

  const { partnerId } = useParams();

  const [modal, contextHolder] = Modal.useModal();

  const { data, loading, error } = useQuery(PartnerViewQuery, {
    variables: {
      id: partnerId,
    },
  });

  const [updateStatus] = useMutation(PartnerUpdateMutation, {
    variables: {
      id: partnerId,
    },
  })

  if (loading) {
    return (
      <PageHeader
        title="..."
        breadcrumbRender={breadcrumbRender(partnerId, '...')}
        onBack={() => navigate('/partners')}
      >
        <Loader size="large" />
      </PageHeader>
    );
  }

  const { partner } = data;

  const status = partnerStatuses[partner?.status];

  const anchors = [
    {
      hash: '#tile-company-activity',
      icon: BankOutlined,
      label: 'Company',
    },
    {
      hash: '#tile-company-misc',
      icon: UnorderedListOutlined,
      label: 'Company Info',
    },
    {
      hash: '#tile-company-address',
      icon: EnvironmentOutlined,
      label: 'Company Address',
    },
    {
      hash: '#tile-main-contact',
      icon: ContactsOutlined,
      label: 'Main contact',
    },
    {
      hash: '#tile-billing-contact',
      icon: ContactsOutlined,
      label: 'Billing contact',
    },
    {
      hash: '#tile-partner-route-default-options',
      icon: CheckSquareOutlined,
      label: 'Default routes options',
    },
    {
      hash: '#tile-partner-routes',
      icon: NodeIndexOutlined,
      label: 'Routes',
    },
    {
      hash: '#tile-partner-sources',
      icon: BranchesOutlined,
      label: 'Partner sources',
    },
    {
      hash: '#tile-partner_data_configuration',
      icon: ContainerOutlined,
      label: 'Data configuration',
    },
    {
      hash: '#tile-partner_reconciliation_configuration',
      icon: MergeCellsOutlined,
      label: 'Reconciliation configuration',
    },
    {
      hash: '#tile-partner-reconciliation-data',
      icon: MergeCellsOutlined,
      label: 'Reconciliation data',
    },
    {
      hash: '#tile-merchant-attribute-data',
      icon: ContainerOutlined,
      label: 'Merchant attribute configuration',
    },
    ...(partner.allow_payfac
      ? [
          {
            hash: '#tile-partner_payfac_configuration',
            icon: ContainerOutlined,
            label: 'Payment Facilitator configuration',
          },
        ]
      : []),
    {
      hash: '#tile-endpoints',
      icon: ApiOutlined,
      label: 'Endpoints',
    },
    {
      hash: '#tile-program-managers',
      icon: DeploymentUnitOutlined,
      label: 'Program Managers',
    },
    {
      hash: '#tile-entity-logs',
      icon: CodeOutlined,
      label: 'Logs',
    },
  ];

  const confirmUpdateStatus = (value, option) => {
    modal.confirm({
      title: 'Update partner status',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to set the partner status to ${option.label}?`,
      okText: 'Apply',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => updateStatus({
        variables: {
          input: {
            status: value,
          }
        }
      })
    });
  };

  return (
    <PageHeader
      title={partner.name}
      subTitle={partner.api_name}
      avatar={{ src: partner.company.theme.icon, icon: <DeploymentUnitOutlined />, shape: 'square' }}
      breadcrumbRender={breadcrumbRender(partner.id, partner.name)}
      onBack={() => navigate('/partners')}
      tags={[
        <Tag key="type-tag" color={partnerTypes[partner.type]?.color}>
          {partnerTypes[partner.type]?.label}
        </Tag>,
        partner?.status && (
          <Tag key="status-tag" color={status.color}>
            {status.label}
          </Tag>
        ),
      ]}
      extra={can('update', 'partner') &&
        <Select
          style={{
            minWidth: 130,
          }}
          options={[
            {
              label: 'Active',
              value: 'active',
            },
            {
              label: 'Maintenance',
              value: 'maintenance',
            },
          ]}
          value={partner.status}
          onChange={(value, option) => confirmUpdateStatus(value, option)}
        />
      }
    >
      <HandleApolloError error={error}>
        <Layout className={styles.subPage}>
          <Sider className={styles.anchorSider} width={24}>
            <AnchorList anchors={anchors} />
          </Sider>
          <Content>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CompanyActivity company={partner.company} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CompanyMisc company={partner.company} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <CompanyAddress company={partner.company} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <MainContact partner={partner} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <BillingContact partner={partner} />
              </Col>
              {[PartnerTypes.PAYIN, PartnerTypes.PAYOUT].includes(partner.type) && can('read', 'partner-route') && (
                <Col span={24}>
                  <PartnerRouteDefaultOptions partner={partner} />
                </Col>
              )}
              {[PartnerTypes.PAYIN, PartnerTypes.PAYOUT].includes(partner.type) &&
                partner.allow_stack &&
                can('read', 'partner-route') && (
                  <Col span={24}>
                    <PartnerRoutes partner={partner} />
                  </Col>
                )}
              <Col span={24}>
                <PartnerSources partner={partner} />
              </Col>
              <Col lg={24} xl={12}>
                <AuthenticationOptions partner={partner} />
              </Col>
              <Col lg={24} xl={12}>
                <RiskOptions partner={partner} />
              </Col>
              <Col span={24}>
                <PartnerFieldsConfiguration
                  configurationKey="partner_data_configuration"
                  title="Data configuration"
                  partner={partner}
                />
              </Col>
              <Col span={24}>
                <PartnerFieldsConfiguration
                  configurationKey="partner_reconciliation_configuration"
                  title="Reconciliation configuration"
                  partner={partner}
                />
              </Col>
              <Col span={24}>
                <PartnerReconciliationData partner={partner} />
              </Col>
              {partner.allow_payfac && (
                <Col span={24}>
                  <MerchantAttributeConfiguration partner={partner} />
                </Col>
              )}
              {/*{partner.allow_payfac && (*/}
              {/*  <Col span={24}>*/}
              {/*    <PartnerFieldsConfiguration*/}
              {/*      configurationKey="partner_payfac_configuration"*/}
              {/*      title="Payment Facilitator configuration"*/}
              {/*      hasLevel*/}
              {/*      partner={partner}*/}
              {/*    />*/}
              {/*  </Col>*/}
              {/*)}*/}
              <Col span={24}>
                <Endpoints partner={partner} />
              </Col>
              <Col span={24}>
                <ActiveOnProgramManagers partnerId={partnerId} />
              </Col>
              {/* <Col span={24}> */}
              {/*  <Contracts partner={partner} /> */}
              {/* </Col> */}
              <Col span={24}>
                <AsyncLogs />
              </Col>
            </Row>
          </Content>
        </Layout>
      </HandleApolloError>
      {contextHolder}
    </PageHeader>
  );
};

export default PartnerView;

import { gql } from '@apollo/client';

export const metaProgramManagerCompanyMiscQuery = gql`
  query MetaProgramManagerCompanyMisc($id: ID!) {
    metaProgramManager(id: $id) {
      id
      company {
        vatNumber
        ape
        duns
        capital
        numberEmployee
      }
    }
  }
`;

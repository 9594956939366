import React from 'react';
import classnames from 'classnames';
import { CloseCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import styles from './Item.module.scss';
import Handle from './Handle';
import { Input } from 'antd';

const Item = (props) => {
  const {
    id,
    label,
    avatar,
    icon: Icon = QuestionCircleOutlined,
    color,
    isDisabled,
    isDraggable,
    isRemovable,
    onRemove,
    onClick,
    type,
    title,
    noPadding,
    hoverIcon,
    extra,
    isLabelEditable,
    onInputChange,
  } = props;

  const handleClick = (event) => {
    if (onClick) onClick(id, event);
  };

  const handleRemove = (event) => {
    if (onRemove) onRemove(id, event);
  };

  const handleInputChange = (event) => {
    if (onInputChange) onInputChange(id, event);
  };

  return (
    <div
      className={classnames(styles.root, {
        [styles.disabled]: isDisabled,
        [styles.clickable]: !!onClick,
        [styles.draggable]: !!isDraggable,
        [styles.rounded]: type === 'rounded',
      })}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyPress={handleClick}
    >
      {avatar ? (
        <img className={styles.avatar} src={avatar} alt="" />
      ) : (
        <div className={!noPadding && styles.iconContainer} style={{ backgroundColor: color }}>
          {Icon && <Icon />}
        </div>
      )}
      <div className={styles.label} title={`${title} (${id})`}>
        {isLabelEditable ? (
          <Input
            value={label}
            onChange={handleInputChange}
            bordered={false}
            className={styles.labelInput}
            maxLength={34}
          />
        ) : (
          label
        )}
      </div>
      {extra}
      {onClick && <div className={styles.hoverIcon}>{hoverIcon}</div>}
      {isDraggable && (
        <div className={styles.handleIcon}>
          <Handle />
        </div>
      )}
      {isRemovable && (
        <div
          className={styles.removeButton}
          title="Remove"
          tabIndex={0}
          role="button"
          onClick={handleRemove}
          onKeyPress={handleRemove}
        >
          <CloseCircleFilled className={styles.removeIcon} />
        </div>
      )}
    </div>
  );
};

export default Item;

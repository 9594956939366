import { gql } from '@apollo/client';

const query = `{
  _id
  last_transaction {
    _id
    customer_account_creation_date
    customer_account_change_date
    customer_account_change_password_date
    customer_account_attempts_order_day
    customer_account_attempts_order_year
    customer_account_add_payment_means_date
  }
}`;

export const orderAccountHistoryQuery = gql`
  query OrderAccountHistory($orderId: ID!) {
    order(id: $orderId) ${query}
  }
`;

export const activityAccountHistoryQuery = gql`
  query ActivityAccountHistory($orderId: ID!) {
    activity(id: $orderId) ${query}
  }
`;

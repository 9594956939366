import { gql } from '@apollo/client';

const query = `{
  _id
  shipping_address_street_name_line1
  shipping_address_street_name_line2
  shipping_delivery_method
  shipping_address_zip_code
  shipping_address_city
  shipping_address_country
  shipping_address_state
  shipping_id
  shipping_carrier_name
  shipping_price
  shipping_email
}`

export const orderShippingDetailsQuery = gql`
  query OrderShippingDetails($orderId: ID!) {
    order(id: $orderId) ${query}
  }
`;

export const activityShippingDetailsQuery = gql`
  query ActivityShippingDetails($orderId: ID!) {
    activity(id: $orderId) ${query}
  }
`;

import React from 'react';
import styles from './icon.module.scss';

const Donut = ({ color = 'inherit' }) => (
  <span className={styles.icon}>
    <svg width="20" height="20" viewBox="0 0 300 300">
      <path
        fill={color}
        // eslint-disable-next-line max-len
        d="M15583.608,986.326a150.519,150.519,0,0,1-30.23-3.062,148.926,148.926,0,0,1-53.638-22.67,151.127,151.127,0,0,1-22.2-18.4c-1.234-1.24-2.479-2.535-3.7-3.848l20.718-14.571c.205.21.42.428.657.667a125.2,125.2,0,0,0,39.735,26.906,123.9,123.9,0,0,0,23.462,7.313,125.709,125.709,0,0,0,50.385,0,123.92,123.92,0,0,0,44.694-18.891c2.231-1.514,4.449-3.128,6.593-4.8l18.484,17.313c-3.569,2.936-7.3,5.73-11.1,8.3a148.922,148.922,0,0,1-83.865,25.732Zm122.863-64.213h0l-18.5-17.326a125.135,125.135,0,0,0,18.1-43.822,127.357,127.357,0,0,0,0-50.608,125.13,125.13,0,0,0-15.765-40.137l19.09-16.667a150.945,150.945,0,0,1-1.406,166.345c-.5.736-1.007,1.481-1.518,2.215Zm-256.232-17.435h0c-1.744-3.393-3.373-6.882-4.843-10.37a150.746,150.746,0,0,1-8.738-28.282,152.732,152.732,0,0,1,0-60.729,150.655,150.655,0,0,1,88.563-108.462,148.85,148.85,0,0,1,28.157-8.777c3.363-.691,6.8-1.273,10.23-1.732V711.7c-1.729.279-3.477.6-5.192.952a124.1,124.1,0,0,0-44.7,18.895,125.537,125.537,0,0,0-52.571,78.807,127.357,127.357,0,0,0,0,50.608,125.545,125.545,0,0,0,7.285,23.567c.786,1.865,1.632,3.744,2.516,5.583l-20.707,14.563Zm213.238-165.6h0c-3.2-2.674-6.56-5.207-9.981-7.526a124.022,124.022,0,0,0-44.694-18.895c-1.379-.284-2.789-.548-4.189-.786V686.464c3.095.435,6.2.972,9.228,1.594a148.754,148.754,0,0,1,53.635,22.67,151.048,151.048,0,0,1,15.08,11.693l-19.075,16.656Z"
        transform="translate(-15433.608 -686.326)"
      />
    </svg>
  </span>
);

export default Donut;

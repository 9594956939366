import React from 'react';
import LordIcon from '../../../../../../assets/icons/LordIcon';
import styles from './common.module.scss';

const ErrorChart = () => (
  <div className={styles.container}>
    <LordIcon type="error-outline-edited" width={136} height={136} isStopped={false} autoplay />
    <div>Something went wrong, please retry later</div>
  </div>
);

export default ErrorChart;

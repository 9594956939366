import React, { useState } from 'react';
import { Layout, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import CogBoxIcon from '@2fd/ant-design-icons/lib/CogBox';
import FeatureSearchIcon from '@2fd/ant-design-icons/lib/FeatureSearch';
import ForwardburgerIcon from '@2fd/ant-design-icons/lib/Forwardburger';
import BackburgerIcon from '@2fd/ant-design-icons/lib/Backburger';
import CartIcon from '@2fd/ant-design-icons/lib/Cart';
import { useLayoutMenu } from '../../../layout.context';
import { DrawerContext } from '../Common/useDrawer';
import Drawers from '../Common/constants/drawers';
import SearchDrawer from '../Common/SearchDrawer/SearchDrawer';
import SetUpDrawer from '../Common/SetUpDrawer/SetUpDrawer';
import HeaderButton from '../Common/HeaderButton/HeaderButton';
// import ExportDrawer from '../Common/ExportDrawer/ExportDrawer';
import { formatEndUnderline } from '../Common/utils';
import brainpowerStyles from '../Brainpower.module.scss';
import CheckoutTable from './CheckoutTable/CheckoutTable';

const CheckoutList = () => {
  const [isMenuCollapsed, toggleMenu] = useLayoutMenu();

  const [drawerState, setDrawerState] = useState();
  const handleToggleDrawer = (key) => () => setDrawerState(drawerState !== key ? key : undefined);
  const handleCloseDrawer = () => setDrawerState(undefined);

  return (
    <DrawerContext.Provider value={[drawerState, setDrawerState]}>
      <Layout className={brainpowerStyles.root}>
        <PageHeader
          ghost={false}
          className={brainpowerStyles.pageHeader}
          backIcon={isMenuCollapsed ? <ForwardburgerIcon /> : <BackburgerIcon />}
          onBack={toggleMenu}
          title={
            <Space>
              <CartIcon />
              {formatEndUnderline('Checkouts')}
            </Space>
          }
          extra={[
            <HeaderButton
              drawerState={drawerState}
              key={Drawers.SEARCH}
              id={Drawers.SEARCH}
              label="SEARCH"
              icon={FeatureSearchIcon}
              onClick={handleToggleDrawer(Drawers.SEARCH)}
            />,
            // <HeaderButton
            //   drawerState={drawerState}
            //   key={Drawers.EXPORT}
            //   id={Drawers.EXPORT}
            //   label="EXPORT"
            //   icon={DownloadBoxIcon}
            //   onClick={handleToggleDrawer(Drawers.EXPORT)}
            // />,
            <HeaderButton
              drawerState={drawerState}
              key={Drawers.SETUP}
              id={Drawers.SETUP}
              label="DISPLAY"
              icon={CogBoxIcon}
              onClick={handleToggleDrawer(Drawers.SETUP)}
            />,
          ]}
        />
        <CheckoutTable />
        <SearchDrawer open={drawerState === Drawers.SEARCH} onClose={handleCloseDrawer} containerId="checkout-table" />
        <SetUpDrawer open={drawerState === Drawers.SETUP} onClose={handleCloseDrawer} containerId="checkout-table" />
        {/*<ExportDrawer open={drawerState === Drawers.EXPORT} onClose={handleCloseDrawer} containerId="checkout-table" />*/}
      </Layout>
    </DrawerContext.Provider>
  );
};

export default CheckoutList;

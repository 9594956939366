import { gql } from '@apollo/client';

const query = `{
  _id
  last_transaction {
    customer_street_name
    customer_city
    customer_zip_code
    customer_state
    customer_country
  }
}`;

export const orderCustomerAddressQuery = gql`
  query OrderCustomerAddress($orderId: ID!) {
      order(id: $orderId) ${query}
  }
`;

export const activityCustomerAddressQuery = gql`
  query ActivityCustomerAddress($orderId: ID!) {
      activity(id: $orderId) ${query}
  }
`;

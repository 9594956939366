import React from 'react';
import {Space, Radio, Tooltip, Modal} from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import InfoTile from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import {
  companyLoginMethodQuery, updateCompanyLoginMethodMutation
} from './query';
import { useCan } from '../../../../../../contexts/ability.context';

const CompanyLoginMethod = () => {
  const can = useCan();
  const [modal, contextHolder] = Modal.useModal();
  const { companyId } = useParams();

  const {
    data: { merchantCompany: { sso, loginMethod } } = { merchantCompany: { sso: null, loginMethod: null } },
    loading,
  } = useQuery(
    companyLoginMethodQuery,
    {
      variables: {
        companyId
      },
    }
  );

  const [updateLoginMethod, updateState] = useMutation(
    updateCompanyLoginMethodMutation,
    {
      variables: {
        companyId,
      }
    }
  )

  return (
    <InfoTile
      name='company-login-method'
      title='Login method'
    >
      <Radio.Group
        onChange={(event) => modal.confirm({
          title: event.target.value === 'sso' ? 'Are you sure you want to activate SSO for this company?' : 'Are you sure you want to deactivate SSO for this company?',
          onOk: () => updateLoginMethod({
            variables: {
              input: {
                loginMethod: event.target.value,
              }
            },
            optimisticResponse: {
              updateCompanyLoginMethod: {
                id: companyId,
                loginMethod: event.target.value,
                __typename: 'Company',
              }
            }
          }),
        })}
        value={loginMethod}
        disabled={!can('update', 'company-login') || loading || updateState.loading}
      >
        <Space direction="vertical">
          <Radio value="form">Email / password form</Radio>
          <Tooltip
            align='center'
            title={!(sso?.config?.tenantId && sso?.config?.providerId) && 'Please fill your Single Sign On configuration first'}
          >
            <Radio value="sso" disabled={!(sso?.config?.tenantId && sso?.config?.providerId)}>Single sign on</Radio>
          </Tooltip>
        </Space>
      </Radio.Group>
      {contextHolder}
    </InfoTile>
  );
};

export default CompanyLoginMethod;
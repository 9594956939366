import React from 'react';
import { Button, Col, Layout, message, Modal, Row, Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  BankOutlined,
  BgColorsOutlined,
  DeleteOutlined,
  DeploymentUnitOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  IdcardOutlined,
  KeyOutlined,
  SettingOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import AccountKeyOutlineIcon from '@2fd/ant-design-icons/lib/AccountKeyOutline';
import MetaProgramManagerAvatar from '../../../Common/MetaProgramManager/Avatar';
import { metaProgramManagerQuery, removeMetaProgramManagerMutation } from './query';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import HandleApolloError from '../../../Common/HandleApolloError/HandleApolloError';
import styles from '../../Company/CompanyView/CompanyView.module.scss';
import AnchorList from '../../../Common/AnchorList/AnchorList';
import ActivePartners from './ActivePartners/ActivePartners';
import MetaProgramManagerTheme from './MetaProgramManagerTheme/MetaProgramManagerTheme';
import MetaProgramManagerCompanyInformation from './MetaProgramManagerCompanyInformation/MetaProgramManagerCompanyInformation';
import MetaProgramManagerCompanyActivity from './MetaProgramManagerCompanyActivity/MetaProgramManagerCompanyActivity';
import MetaProgramManagerCompanyMisc from './MetaProgramManagerCompanyMisc/MetaProgramManagerCompanyMisc';
import MetaProgramManagerCompanyAddress from './MetaProgramManagerCompanyAddress/MetaProgramManagerCompanyAddress';
import MetaProgramManagerCompanyExecutive from './MetaProgramManagerCompanyExecutive/MetaProgramManagerCompanyExecutive';
import MetaProgramManagerContacts from './MetaProgramManagerContacts/MetaProgramManagerContacts';
import { useCan } from '../../../../../contexts/ability.context';
import { useMetaProgramManager } from '../../../../../contexts/metaProgramManager.context';
import useNavigateWithSearch from '../../../../../util/navigate';
import MetaProgramManagerLoginMethod from './MetaProgramManagerLoginMethod/MetaProgramManagerLoginMethod';
import MetaProgramManagerSso from './MetaProgramManagerSso/MetaProgramManagerSso';
import MetaProgramManagerApiKeys from './MetaProgramManagerApiKeys/MetaProgramManagerApiKeys';
import MetaProgramManagerProgramManagers from './MetaProgramManagerProgramManagers/MetaProgramManagerProgramManagers';
import MetaProgramManagerOptions from './MetaProgramManagerOptions/MetaProgramManagerOptions';
import MetaProgramManagerUsers from './MetaProgramManagerUsers/MetaProgramManagerUsers';

const { Content, Sider } = Layout;

const MetaProgramManagerView = () => {
  const { metaProgramManagerId } = useParams();
  const navigate = useNavigateWithSearch();
  const can = useCan();
  const metaProgramManagerContext = useMetaProgramManager();

  const { data: { metaProgramManager } = { metaProgramManager: null }, error } = useQuery(metaProgramManagerQuery, {
    variables: {
      id: metaProgramManagerId,
    },
  });

  const [removeMetaProgramManager] = useMutation(removeMetaProgramManagerMutation, {
    variables: {
      id: metaProgramManagerId,
    },
    refetchQueries: ['MetaProgramManagers'],
    onCompleted: () => {
      navigate('/meta-program-managers');
      message.success('Meta program manager deleted.');
    },
  });

  const handleDelete = () => {
    Modal.confirm({
      title: `Remove ${metaProgramManager?.name}`,
      content: 'Are you sure you want to delete this meta program manager?',
      onOk: () => removeMetaProgramManager(),
    });
  };

  const anchors = [
    {
      hash: '#tile-meta-program-manager-company',
      icon: BankOutlined,
      label: 'Company',
    },
    {
      hash: '#tile-meta-program-manager-company-activity',
      icon: BankOutlined,
      label: 'Activity',
    },
    {
      hash: '#tile-meta-program-manager-company-misc',
      icon: UnorderedListOutlined,
      label: 'Financial',
    },
    {
      hash: '#tile-meta-program-manager-company-address',
      icon: EnvironmentOutlined,
      label: 'Address',
    },
    {
      hash: '#tile-meta-program-manager-company-executive',
      icon: TeamOutlined,
      label: 'Executive',
    },
    {
      hash: '#tile-settings',
      icon: SettingOutlined,
      label: 'Settings',
    },
    {
      hash: '#tile-meta-program-manager-theme',
      icon: BgColorsOutlined,
      label: 'Meta Program Manager Theme',
    },
    {
      hash: '#tile-meta-program-manager-login-method',
      icon: AccountKeyOutlineIcon,
      label: 'Login method / SSO',
      hidden: !can('read', 'meta-program-manager-login'),
    },
    {
      hash: '#tile-meta-program-manager-api-keys',
      icon: KeyOutlined,
      label: 'Api Keys',
      hidden: !can('read', 'meta-program-manager-api-keys'),
    },
    {
      hash: '#tile-meta-program-manager-contacts',
      icon: IdcardOutlined,
      label: 'Contacts',
    },
    {
      hash: '#tile-meta-program-manager-active-partners',
      icon: DeploymentUnitOutlined,
      label: 'Active Partners',
    },
    {
      hash: '#tile-meta-program-manager-users',
      icon: UserOutlined,
      label: 'Users',
      hidden: !can('read', 'meta-program-manager-user'),
    },
  ];

  return (
    <PageHeader
      title={metaProgramManager?.name ?? '...'}
      subTitle={metaProgramManager?.payfac ? <Tag>Payment Facilitator</Tag> : ''}
      breadcrumbRender={() =>
        !metaProgramManagerContext && (
          <Breadcrumb
            map={{
              '/': <HomeOutlined />,
              '/meta-program-managers': 'Meta program managers',
              [`/meta-program-managers/${metaProgramManagerId}`]: metaProgramManager?.name ?? '...',
            }}
          />
        )
      }
      onBack={() => navigate('/meta-program-managers')}
      extra={[
        can('delete', 'meta-program-manager') && (
          <Button
            key="delete-meta-program-manager"
            title="Delete meta program manager"
            icon={<DeleteOutlined />}
            shape="circle"
            onClick={handleDelete}
            disabled={metaProgramManager?.programManagers.length}
          />
        ),
        <MetaProgramManagerAvatar metaProgramManager={metaProgramManager} />,
      ]}
    >
      <HandleApolloError error={error}>
        <Layout className={styles.subPage}>
          <Sider className={styles.anchorSider} width={24}>
            <AnchorList anchors={anchors} />
          </Sider>
          <Content>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <MetaProgramManagerCompanyInformation id={metaProgramManagerId} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <MetaProgramManagerCompanyActivity id={metaProgramManagerId} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                <MetaProgramManagerCompanyMisc id={metaProgramManagerId} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                <MetaProgramManagerCompanyAddress id={metaProgramManagerId} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                <MetaProgramManagerCompanyExecutive id={metaProgramManagerId} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <MetaProgramManagerOptions id={metaProgramManagerId} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <MetaProgramManagerTheme id={metaProgramManagerId} />
              </Col>
              {can('read', 'meta-program-manager-login') && (
                <>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <MetaProgramManagerLoginMethod id={metaProgramManagerId} />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <MetaProgramManagerSso id={metaProgramManagerId} />
                  </Col>
                </>
              )}
              {can('read', 'meta-program-manager-api-keys') && (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <MetaProgramManagerApiKeys id={metaProgramManagerId} />
                </Col>
              )}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <MetaProgramManagerContacts id={metaProgramManagerId} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <ActivePartners id={metaProgramManagerId} />
              </Col>
              {can('read', 'meta-program-manager-user') && (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <MetaProgramManagerUsers id={metaProgramManagerId} />
                </Col>
              )}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <MetaProgramManagerProgramManagers id={metaProgramManagerId} />
              </Col>
            </Row>
          </Content>
        </Layout>
      </HandleApolloError>
    </PageHeader>
  );
};

export default MetaProgramManagerView;
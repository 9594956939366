import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { RadarChart } from '@amcharts/amcharts4/charts';

/**
 * Create Range for group (dimension 3 optional)
 * @param categoryAxis
 * @param name
 * @param groupData
 * @param index
 */
function createRange(categoryAxis, name, groupData, index) {
  const colorSet = new am4core.ColorSet();

  const axisRange = categoryAxis.axisRanges.create();
  axisRange.axisFill.interactionsEnabled = true;
  axisRange.text = name;
  // first group element
  axisRange.category = `${groupData[0][0]} via ${name}`;
  // last group element
  axisRange.endCategory = `${groupData[groupData.length - 1][0]} via ${name}`;

  // every 3rd color for a bigger contrast
  axisRange.axisFill.fill = colorSet.getIndex(index * 3);
  axisRange.grid.disabled = true;
  axisRange.label.interactionsEnabled = false;
  axisRange.label.bent = true;

  const { axisFill } = axisRange;
  axisFill.innerRadius = -0.001; // almost the same as 100%, we set it in pixels as later we animate this property to some pixel value
  axisFill.radius = -20; // negative radius means it is calculated from max radius
  axisFill.disabled = false; // as regular fills are disabled, we need to enable this one
  axisFill.fillOpacity = 1;
  axisFill.togglable = true;

  axisFill.showSystemTooltip = true;
  axisFill.readerTitle = 'click to zoom';
  axisFill.cursorOverStyle = am4core.MouseCursorStyle.pointer;

  axisFill.events.on('hit', (event) => {
    const { dataItem } = event.target;
    if (!event.target.isActive) {
      categoryAxis.zoom({ start: 0, end: 1 });
    } else {
      categoryAxis.zoomToCategories(dataItem.category, dataItem.endCategory);
    }
  });

  // hover state
  const hoverState = axisFill.states.create('hover');
  hoverState.properties.innerRadius = -10;
  hoverState.properties.radius = -25;

  const axisLabel = axisRange.label;
  axisLabel.location = 0.5;
  axisLabel.fill = am4core.color('#ffffff');
  axisLabel.radius = 3;
  axisLabel.relativeRotation = 0;
}

/**
 * Generate Radar Data Column
 * @param dimData
 * @param dim
 * @param periods
 * @param groupName
 * @returns {*}
 */
function generateRadarDataColumn(dimData, dim, periods, groupName) {
  return dimData.reduce((memo, el, idx2) => {
    // first element is the name
    if (idx2 === 0) {
      return { [dim]: `${el} via ${groupName}` }; // dedupe category by suffixing with group name
    }
    return { ...memo, [`value${periods[idx2 - 1]}`]: el };
  }, {});
}

/**
 * Generate Radar Data
 * @param categoryAxis
 * @param rawData
 * @param dim
 * @param periods
 * @returns {[]}
 */
function generateRadarData(categoryAxis, rawData, dim, periods) {
  const data = [];

  if (typeof rawData === 'object') {
    Object.keys(rawData).forEach((groupName, idx1) => {
      const groupData = rawData[groupName];
      groupData.forEach((dimData) => {
        const rawDataItem = generateRadarDataColumn(dimData, dim, periods, groupName);
        data.push(rawDataItem);
      });
      createRange(categoryAxis, groupName, groupData, idx1);
    });
  } else {
    rawData.forEach((dimData) => {
      const rawDataItem = generateRadarDataColumn(dimData, dim, periods);
      data.push(rawDataItem);
    });
  }

  return data;
}

/**
 * Radar Timeline
 * @param divId
 * @param widget
 * @param data
 * @returns {RadarChart}
 */
export default (divId, widget, data) => {
  const { dimensions } = widget;
  // Create chart instance
  const chart = am4core.create(divId, RadarChart);

  // Dimension 1 : Period & Granularity (required)
  // const { granularity } = parameters.dimensions[0];

  // Dimension 2 : Evaluated dimension (required)
  const dimension = dimensions[1];

  // Dimension 3 : Group dimension (optional)

  const [{ periods: dataPeriods }, ...dataValues] = data.data;

  // Data
  const periods = dataPeriods?.map((p) => p.slice(0, 10)); // format day
  // map complex format (TODO - change radar return format)
  const rawData = dataValues.reduce(
    (memo, dataValue) => ({
      ...memo,
      [Object.keys(dataValue)[0]]: dataValue[Object.keys(dataValue)[0]].map(([el, ...values]) => [el, ...values]),
    }),
    {},
  );

  let currentPeriod = periods[periods.length - 1]; // last period

  // Chart style options
  chart.hiddenState.properties.opacity = 0;
  chart.startAngle = -180;
  chart.endAngle = 180;
  chart.padding(5, 15, 5, 10);
  chart.radius = am4core.percent(65);
  chart.innerRadius = am4core.percent(40);

  // Add period label in the middle
  const periodLabel = chart.radarContainer.createChild(am4core.Label);
  periodLabel.horizontalCenter = 'middle';
  periodLabel.verticalCenter = 'middle';
  periodLabel.fill = am4core.color('#673AB7');
  periodLabel.fontSize = 22;
  periodLabel.text = String(currentPeriod);

  // Place zoomOut button
  const { zoomOutButton } = chart;
  zoomOutButton.dx = 0;
  zoomOutButton.dy = 0;
  zoomOutButton.marginBottom = 15;
  zoomOutButton.parent = chart.rightAxesContainer;

  // Add right scrollbar
  chart.scrollbarX = new am4core.Scrollbar();
  chart.scrollbarX.parent = chart.rightAxesContainer;
  chart.scrollbarX.orientation = 'vertical';
  chart.scrollbarX.align = 'center';
  chart.scrollbarX.exportable = false;

  // vertical orientation for zoom out button and scrollbar to be positioned properly
  chart.rightAxesContainer.layout = 'vertical';
  chart.rightAxesContainer.padding(0, 20, 40, 20);

  // Add X category axis based on secondary dimension
  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.dataFields.category = dimension;

  const categoryAxisRenderer = categoryAxis.renderer;
  const categoryAxisLabel = categoryAxisRenderer.labels.template;
  // remove group suffix
  categoryAxis.renderer.labels.template.adapter.add('textOutput', (text) => text?.replace(/ via.*/, ''));
  categoryAxisLabel.location = 0.5;
  categoryAxisLabel.radius = 28;
  categoryAxisLabel.relativeRotation = 90;

  categoryAxisRenderer.fontSize = 11;
  categoryAxisRenderer.minGridDistance = 10;
  categoryAxisRenderer.grid.template.radius = -25;
  categoryAxisRenderer.grid.template.strokeOpacity = 0.05;
  categoryAxisRenderer.grid.template.interactionsEnabled = false;

  categoryAxisRenderer.ticks.template.disabled = true;
  categoryAxisRenderer.axisFills.template.disabled = true;
  categoryAxisRenderer.line.disabled = true;

  categoryAxisRenderer.tooltipLocation = 0.5;
  categoryAxis.tooltip.defaultState.properties.opacity = 0;

  // value axis
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.strictMinMax = true;
  valueAxis.tooltip.defaultState.properties.opacity = 0;
  valueAxis.tooltip.animationDuration = 0;
  valueAxis.cursorTooltipEnabled = true;
  valueAxis.zIndex = 10;

  const valueAxisRenderer = valueAxis.renderer;
  valueAxisRenderer.axisFills.template.disabled = true;
  valueAxisRenderer.ticks.template.disabled = true;
  valueAxisRenderer.minGridDistance = 20;
  valueAxisRenderer.grid.template.strokeOpacity = 0.05;

  // series
  const series = chart.series.push(new am4charts.RadarColumnSeries());
  series.columns.template.width = am4core.percent(90);
  series.columns.template.strokeOpacity = 0;
  series.dataFields.valueY = `value${currentPeriod}`;
  series.dataFields.categoryX = dimension;
  series.tooltipText = "{categoryX}: {valueY.value.formatNumber('#,###.00')}";

  // this makes columns to be of a different color, depending on value
  series.heatRules.push({
    target: series.columns.template,
    property: 'fill',
    min: am4core.color('#673AB7'),
    max: am4core.color('#F44336'),
    dataField: 'valueY',
  });

  // cursor
  const cursor = new am4charts.RadarCursor();
  chart.cursor = cursor;
  cursor.behavior = 'zoomX';
  cursor.xAxis = categoryAxis;
  cursor.innerRadius = am4core.percent(40);
  cursor.lineY.disabled = true;
  cursor.lineX.fillOpacity = 0.2;
  cursor.lineX.fill = am4core.color('#000000');
  cursor.lineX.strokeOpacity = 0;
  cursor.fullWidthLineX = true;

  // Add period slider
  const periodSliderContainer = chart.createChild(am4core.Container);
  periodSliderContainer.layout = 'vertical';
  periodSliderContainer.padding(0, 38, 0, 38);
  periodSliderContainer.width = am4core.percent(100);

  function updateRadarData(period) {
    if (currentPeriod !== period) {
      currentPeriod = period;
      periodLabel.text = String(currentPeriod);
      series.dataFields.valueY = `value${currentPeriod}`;
      chart.invalidateRawData();
    }
  }

  const periodSlider = periodSliderContainer.createChild(am4core.Slider);
  periodSlider.events.on('rangechanged', () => {
    updateRadarData(periods[Math.ceil(periodSlider.start * (periods.length - 1))]);
  });
  periodSlider.orientation = 'horizontal';
  periodSlider.start = 1;
  periodSlider.exportable = false;

  chart.data = generateRadarData(categoryAxis, rawData, dimension, periods);

  // TODO - get max from api ?
  // find max value to set fix axis value min/max
  const max = Math.max(...chart.data.map((d) => Math.max(...Object.values(d).filter((n) => typeof n === 'number'))));
  valueAxis.min = 0;
  valueAxis.max = max;

  return chart;
};

import React from 'react';
import { useQuery } from '@apollo/client';
import { Avatar, Col, Layout, Row, Space, Table, Tabs, Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { DeploymentUnitOutlined, HomeOutlined } from '@ant-design/icons';
import { useMetaProgramManager } from '../../../../contexts/metaProgramManager.context';
import HandleApolloError from '../../Common/HandleApolloError/HandleApolloError';
import styles from '../Company/CompanyView/CompanyView.module.scss';
import AnchorList from '../../Common/AnchorList/AnchorList';
import Page from '../../Common/Page/Page';
import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
import InfoTile from '../../Brainpower/OrderDetail/Tile/Info/Info';
import { partnerTypeList, partnerTypes, PartnerTypes } from '../Partners/constants';
import { MetaProgramManagerPartnersQuery } from '../MetaProgramManagers/MetaProgramManagerView/ActivePartners/query';
import { getColumnFilterSearchInput } from '../../Brainpower/OrderDetail/Tile/Table/Table';
import { search } from '../../../../util/string';
import LoadingPage from '../../Common/Pages/Loading';
import ErrorPage from '../../Common/Pages/Error';

const { Content, Sider } = Layout;

const anchors = [
  {
    hash: '#tile-active-partners',
    icon: DeploymentUnitOutlined,
    label: 'Active partners',
  },
  {
    hash: '#tile-available-partners',
    icon: DeploymentUnitOutlined,
    label: 'Available partners',
  },
];

const MetaProgramManagerPartners = () => {
  const metaProgramManager = useMetaProgramManager();

  const { data, loading, error } = useQuery(MetaProgramManagerPartnersQuery, { variables: { id: metaProgramManager.id } });

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage />;
  }

  const activePartners = data?.metaProgramManager.activePartners.reduce(
    (memo, activePartner) => ({
      ...memo,
      [activePartner.partner.type]: [
        ...(memo[activePartner.partner.type] ?? []),
        {
          key: activePartner.partner.id,
          partner: activePartner.partner,
          activePaymentMethods: activePartner.paymentMethods,
          availablePaymentMethods:
            data?.partners
              .find((partner) => partner.id === activePartner.partner.id)
              ?.payment_methods.filter(
                (paymentMethod) => !activePartner.paymentMethods.map((pm) => pm.id).includes(paymentMethod.id),
              ) ?? [],
        },
      ],
    }),
    {},
  );

  const availablePartners = data?.partners.reduce((memo, partner) => {
    if (data.metaProgramManager.activePartners.find((ap) => ap.partner.id === partner.id)) {
      return memo;
    }
    return {
      ...memo,
      [partner.type]: [
        ...(memo[partner.type] ?? []),
        {
          key: partner.id,
          partner,
          activePaymentMethods: partner.payment_methods,
          availablePaymentMethods: [],
        },
      ],
    };
  }, {});

  const columnsByType = (type) => [
    {
      title: 'Partner',
      dataIndex: 'partner',
      key: 'partner',
      fixed: 'left',
      width: 160,
      render: (partner) => (
        <Space>
          <Avatar
            src={partner.company.theme.icon}
            icon={<DeploymentUnitOutlined />}
            style={{ backgroundColor: partnerTypes[partner.type].color }}
            shape="square"
          />
          {partner.name}
        </Space>
      ),
      ...getColumnFilterSearchInput((value, activePartner) => search(value, activePartner.partner.name)),
      sorter: (a, b) => (a.partner.name.toLowerCase() > b.partner.name.toLowerCase() ? 1 : -1),
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
    },
    ...([PartnerTypes.PAYIN, PartnerTypes.PAYOUT].includes(type)
      ? [
          {
            title: 'Payment methods',
            dataIndex: 'activePaymentMethods',
            key: 'activePaymentMethods',
            render: (activePaymentMethods, activePartner) => (
              <div style={{ justifyContent: 'space-between', display: 'flex', gap: 12 }}>
                <Space>
                  {activePaymentMethods.map((paymentMethod) => (
                    <img
                      key={paymentMethod.id}
                      src={paymentMethod.imgUrl}
                      alt=""
                      style={{ width: 24 }}
                      title={paymentMethod.name}
                    />
                  ))}
                </Space>
                &nbsp;
                <Space>
                  {activePartner.availablePaymentMethods.map((paymentMethod) => (
                    <img
                      key={paymentMethod.id}
                      src={paymentMethod.imgUrl}
                      alt=""
                      style={{
                        width: 24,
                        filter: 'grayscale(90%)',
                        opacity: 0.7,
                      }}
                      title={paymentMethod.name}
                    />
                  ))}
                </Space>
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <Page>
      <PageHeader
        title="Partners"
        breadcrumbRender={() => (
          <Breadcrumb
            map={{
              '/': <HomeOutlined />,
              '/meta-program-partners': 'Partners',
            }}
          />
        )}
      >
        <HandleApolloError error={error}>
          <Layout className={styles.subPage}>
            <Sider className={styles.anchorSider} width={24}>
              <AnchorList anchors={anchors} />
            </Sider>
            <Content>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <InfoTile key="active-partners" name="active-partners" title="Active Partners">
                    <Tabs defaultActiveKey={PartnerTypes.PAYIN}>
                      {partnerTypeList.map((type) => (
                        <Tabs.TabPane key={type.value} tab={<Tag color={type.color}>{type.label}</Tag>}>
                          <Table
                            columns={columnsByType(type.value)}
                            loading={loading}
                            dataSource={activePartners[type.value]}
                            size="small"
                            scroll={{ x: 'auto' }}
                            pagination={false}
                          />
                        </Tabs.TabPane>
                      ))}
                    </Tabs>
                  </InfoTile>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <InfoTile key="available-partners" name="available-partners" title="Available Partners">
                    <Tabs defaultActiveKey={PartnerTypes.PAYIN}>
                      {partnerTypeList.map((type) => (
                        <Tabs.TabPane key={type.value} tab={<Tag color={type.color}>{type.label}</Tag>}>
                          <Table
                            columns={columnsByType(type.value)}
                            loading={loading}
                            dataSource={availablePartners[type.value]}
                            size="small"
                            scroll={{ x: 'auto' }}
                            pagination={false}
                          />
                        </Tabs.TabPane>
                      ))}
                    </Tabs>
                  </InfoTile>
                </Col>
              </Row>
            </Content>
          </Layout>
        </HandleApolloError>
      </PageHeader>
    </Page>
  );
};

export default MetaProgramManagerPartners;

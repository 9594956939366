import { gql } from '@apollo/client';

export const merchantAccountIntegrationQuery = gql`
  query MerchantAccount($id: ID!) {
    merchantAccount(id: $id) {
      id
      cms_name
      cms_version
      integration_type
    }
  }
`;

export const merchantAccountIntegrationMutation = gql`
  mutation UpdateMerchantAccountIntegration($id: ID!, $input: MerchantAccountIntegrationInput!) {
    updateMerchantAccountIntegration(id: $id, input: $input) {
      id
      cms_name
      cms_version
      integration_type
    }
  }
`;

export const integrationTypesQuery = gql`
  {
    integrationTypes
  }
`;

import React from 'react';
import { useToggle } from 'react-use';
import BlockTitle from '../BlockTitle/BlockTitle';
import BlockContent from '../BlockContent/BlockContent';

const Block = ({ children, title, description, onBackClick, className, id, collapsable, extra }) => {
  const [collapsed, toggleCollapse] = useToggle(false);
  return (
    <div id={id} className={className}>
      <BlockTitle
        title={title}
        description={description}
        onBackClick={onBackClick}
        collapsable={collapsable}
        collapsed={collapsed}
        onCollapse={toggleCollapse}
        extra={extra}
      />
      {collapsed || <BlockContent>{children}</BlockContent>}
    </div>
  );
};

export default Block;

import React, { useEffect } from 'react';
import { Avatar, Button, Divider, Form, Select, Space, Tag, TreeSelect } from 'antd';
import { useLazyQuery, useQuery } from '@apollo/client';
import { ApartmentOutlined } from '@ant-design/icons';
import { InternalRecipientsQuery, TargetRecipientsQuery } from '../../query';
import { useCan } from '../../../../../../../contexts/ability.context';

const SelectAnnouncementRecipientsAsInternal = ({ announcement, prev, visible }) => {
  const can = useCan();
  const [form] = Form.useForm();
  const recipientsQuery = useQuery(InternalRecipientsQuery, { errorPolicy: 'ignore' });
  const [fetchTargetRecipients, targetRecipientsResult] = useLazyQuery(TargetRecipientsQuery);

  const onCheck = () => fetchTargetRecipients({ variables: form.getFieldsValue() });

  useEffect(() => {
    onCheck();
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      name="recipients"
      hidden={!visible}
      initialValues={
        announcement && {
          internalAclProfiles: announcement.internalAclProfiles.map((p) => p.id),
          metaProgramManagerAclProfiles: announcement.metaProgramManagerAclProfiles.map((p) => p.id),
          programManagerAclProfiles: announcement.programManagerAclProfiles.map((p) => p.id),
          merchantCompanies: announcement.merchantCompanies.map((p) => p.id),
          merchantAclProfiles: announcement.merchantAclProfiles.map((p) => p.id),
        }
      }
      onFieldsChange={onCheck}
    >
      <Divider orientation="left">Internal Users</Divider>
      <Form.Item
        name="internalAclProfiles"
        label="Profiles"
        tooltip="Select internal profiles, which users will received the announcement."
        extra={
          targetRecipientsResult.loading
            ? '...'
            : `${
                targetRecipientsResult.data?.announcementRecipients.internalUsers.length ?? '...'
              } internal users found.`
        }
      >
        <TreeSelect
          mode="multiple"
          allowClear
          maxTagCount="responsive"
          placeholder="None"
          loading={recipientsQuery.loading}
          treeCheckable
          treeDefaultExpandedKeys={['all']}
          treeNodeFilterProp="label"
          tagRender={({ label, ...props }) => <Tag {...props}>{label}</Tag>}
          disabled={!can('read', 'internal-access-control')}
        >
          <Select.Option key="all" value="all" label="All profiles">
            {recipientsQuery.data?.internalAclProfiles?.map((profile) => (
              <Select.Option key={profile.id} value={profile.id} label={profile.name}>
                {profile.name}
              </Select.Option>
            ))}
          </Select.Option>
        </TreeSelect>
      </Form.Item>
      <Divider orientation="left">Meta Program Manager Users</Divider>
      <Form.Item
        name="metaProgramManagerAclProfiles"
        label="Profiles"
        tooltip="Select Meta program manager profiles, which users will receive the announcement."
        extra={
          targetRecipientsResult.loading
            ? '...'
            : `${
                targetRecipientsResult.data?.announcementRecipients.metaProgramManagerUsers.length ?? '...'
              } meta program manager users found.`
        }
      >
        <TreeSelect
          mode="multiple"
          allowClear
          maxTagCount="responsive"
          placeholder="None"
          loading={recipientsQuery.loading}
          treeCheckable
          treeDefaultExpandedKeys={['all']}
          treeNodeFilterProp="label"
          tagRender={({ label, ...props }) => <Tag {...props}>{label}</Tag>}
          disabled={!can('read', 'meta-program-manager-access-control')}
        >
          <Select.Option key="all" value="all" label="All profiles">
            {recipientsQuery.data?.metaProgramManagers?.map((mpm) => (
              <Select.Option
                key={mpm.id}
                value={mpm.id}
                label={mpm.name}
                title={
                  <Space>
                    <Avatar
                      src={mpm.theme?.icon}
                      icon={<ApartmentOutlined />}
                      size="small"
                      style={{ backgroundColor: mpm.theme?.secondaryColor }}
                    />
                    {mpm.name}
                  </Space>
                }
                disabled={mpm.metaProgramManagerAclProfiles?.length === 0}
              >
                {mpm.metaProgramManagerAclProfiles?.map(({ id, name }) => (
                  <Select.Option
                    key={id}
                    value={id}
                    label={name}
                    title={
                      <Space>
                        <Avatar
                          src={mpm.theme?.icon}
                          icon={<ApartmentOutlined />}
                          size="small"
                          style={{ backgroundColor: mpm.theme?.secondaryColor }}
                        />
                        {name}
                      </Space>
                    }
                  >
                    {name}
                  </Select.Option>
                ))}
              </Select.Option>
            ))}
          </Select.Option>
        </TreeSelect>
      </Form.Item>
      <Divider orientation="left">Program Manager Users</Divider>
      <Form.Item
        name="programManagerAclProfiles"
        label="Profiles"
        tooltip="Select Program manager profiles, which users will receive the announcement."
        extra={
          targetRecipientsResult.loading
            ? '...'
            : `${
                targetRecipientsResult.data?.announcementRecipients.programManagerUsers.length ?? '...'
              } program manager users found.`
        }
      >
        <TreeSelect
          mode="multiple"
          allowClear
          maxTagCount="responsive"
          placeholder="None"
          loading={recipientsQuery.loading}
          treeCheckable
          treeDefaultExpandedKeys={['all']}
          treeNodeFilterProp="label"
          tagRender={({ label, ...props }) => <Tag {...props}>{label}</Tag>}
          disabled={!can('read', 'program-manager-access-control')}
        >
          <Select.Option key="all" value="all" label="All profiles">
            {recipientsQuery.data?.programManagers?.map((pm) => (
              <Select.Option
                key={pm.id}
                value={pm.id}
                label={pm.name}
                title={
                  <Space>
                    <Avatar
                      src={pm.theme?.icon}
                      icon={<ApartmentOutlined />}
                      size="small"
                      style={{ backgroundColor: pm.theme?.secondaryColor }}
                    />
                    {pm.name}
                    {pm.metaProgramManager && <i>- {pm.metaProgramManager.name}</i>}
                  </Space>
                }
                disabled={pm.programManagerAclProfiles?.length === 0}
              >
                {pm.programManagerAclProfiles?.map(({ id, name }) => (
                  <Select.Option
                    key={id}
                    value={id}
                    label={name}
                    title={
                      <Space>
                        <Avatar
                          src={pm.theme?.icon}
                          icon={<ApartmentOutlined />}
                          size="small"
                          style={{ backgroundColor: pm.theme?.secondaryColor }}
                        />
                        {name}
                      </Space>
                    }
                  >
                    {name}
                  </Select.Option>
                ))}
              </Select.Option>
            ))}
          </Select.Option>
        </TreeSelect>
      </Form.Item>
      <Divider orientation="left">Merchants Users</Divider>
      <Form.Item
        name="merchantCompanies"
        label="Companies"
        tooltip="Select the Merchant companies from which users will receive the announcement (combined with Profiles)."
      >
        <TreeSelect
          mode="multiple"
          allowClear
          maxTagCount="responsive"
          placeholder="None"
          loading={recipientsQuery.loading}
          treeCheckable
          treeDefaultExpandedKeys={['all']}
          treeNodeFilterProp="label"
          tagRender={({ label, ...props }) => <Tag {...props}>{label}</Tag>}
          disabled={!can('read', 'access-control')}
        >
          <Select.Option key="all" value="all" label="All companies">
            {recipientsQuery.data?.programManagers?.map((pm) => (
              <Select.Option
                key={pm.id}
                value={pm.id}
                label={pm.name}
                title={
                  <Space>
                    <Avatar
                      src={pm.theme?.icon}
                      icon={<ApartmentOutlined />}
                      size="small"
                      style={{ backgroundColor: pm.theme?.secondaryColor }}
                    />
                    {pm.name}
                  </Space>
                }
                disabled={pm.merchantCompanies?.length === 0}
              >
                {pm.merchantCompanies?.map(({ id, name, theme }) => (
                  <Select.Option
                    key={id}
                    value={id}
                    label={name}
                    title={
                      <Space>
                        <Avatar
                          src={theme?.icon}
                          icon={<ApartmentOutlined />}
                          size="small"
                          style={{ backgroundColor: theme?.secondaryColor }}
                        />
                        {name}
                      </Space>
                    }
                  >
                    {name}
                  </Select.Option>
                ))}
              </Select.Option>
            ))}
          </Select.Option>
        </TreeSelect>
      </Form.Item>

      <Form.Item
        name="merchantAclProfiles"
        label="Profiles"
        tooltip="Select Merchant companies profiles, which users will receive the announcement (combined with Companies)."
        extra={
          targetRecipientsResult.loading
            ? '...'
            : `${
                targetRecipientsResult.data?.announcementRecipients.merchantUsers.length ?? '...'
              } merchant users found.`
        }
      >
        <TreeSelect
          mode="multiple"
          allowClear
          maxTagCount="responsive"
          placeholder="None"
          loading={recipientsQuery.loading}
          treeCheckable
          treeDefaultExpandedKeys={['all']}
          treeNodeFilterProp="label"
          tagRender={({ label, ...props }) => <Tag {...props}>{label}</Tag>}
          disabled={!can('read', 'access-control')}
        >
          <Select.Option key="all" value="all" label="All profiles">
            {recipientsQuery.data?.programManagers?.map((pm) => (
              <Select.Option
                key={pm.id}
                value={pm.id}
                label={pm.name}
                title={
                  <Space>
                    <Avatar
                      src={pm.theme?.icon}
                      icon={<ApartmentOutlined />}
                      size="small"
                      style={{ backgroundColor: pm.theme?.secondaryColor }}
                    />
                    {pm.name}
                  </Space>
                }
                disabled={pm.merchantAclProfiles?.length === 0}
              >
                {pm.merchantAclProfiles?.map(({ id, name }) => (
                  <Select.Option
                    key={id}
                    value={id}
                    label={name}
                    title={
                      <Space>
                        <Avatar
                          src={pm.theme?.icon}
                          icon={<ApartmentOutlined />}
                          size="small"
                          style={{ backgroundColor: pm.theme?.secondaryColor }}
                        />
                        {name}
                      </Space>
                    }
                  >
                    {name}
                  </Select.Option>
                ))}
              </Select.Option>
            ))}
          </Select.Option>
        </TreeSelect>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="default" onClick={prev}>
            Previous
          </Button>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default SelectAnnouncementRecipientsAsInternal;

import React, { useContext, useState } from 'react';
import {useMutation as useApolloMutation} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {Checkbox, Form, Space, Tooltip} from 'antd';
import { MerchantContractContext } from '../../../MerchantContractDrawer';
import { ContractEditionContext } from '../../../../../PaymentNetwork';
import styles from './MerchantContractChannels.module.scss';
import {addSalesChannelMutation, removeSalesChannelMutation} from './query';
import HandleRoutesModal from '../../../HandleRoutesModal/HandleRoutesModal';
import WarningIcon from '../../../../../../../../../assets/icons/customs/Warning';
import LordIcon from '../../../../../../../../../assets/icons/LordIcon';

const { Item } = Form;

const MerchantContractChannels = () => {
  const { merchantContract } = useContext(MerchantContractContext);
  const { isEdited } = useContext(ContractEditionContext);

  const [pmIssues, setPmIssues] = useState(null);

  const [addSalesChannel, { loading: loadingAdd }] = useApolloMutation(addSalesChannelMutation);
  const [removeSalesChannel, { loading: loadingRemove }] = useApolloMutation(removeSalesChannelMutation);

  const [toRemoveChannel, setToRemoveChannel] = useState(null);

  const { t } = useTranslation();

  return (
    <Item label='Sales channels' className={styles.checkboxContainer}>
      {[
        {
          value: 'e-commerce',
          disabled: merchantContract?.channels?.indexOf('pos') > -1,
        },
        {
          value: 'recurring',
          disabled: merchantContract?.channels?.indexOf('pos') > -1,
        },
        {
          value: 'moto',
          disabled: merchantContract?.channels?.indexOf('pos') > -1,
        },
        {
          value: 'pos',
          disabled: merchantContract?.channels?.indexOf('pos') < 0 && merchantContract?.channels?.length,
        },
      ].map((checkbox) => (
        <Space
          align='start'
          style={{
            justifyContent: 'space-between',
          }}
        >
          <Checkbox
            className={(loadingAdd || loadingRemove) && styles.loading}
            key={checkbox.value}
            disabled={!isEdited || checkbox.disabled}
            checked={merchantContract?.channels?.includes(checkbox.value)}
            onChange={async (event) => {
              const params = {
                variables: {
                  id: merchantContract?.id,
                  channel: checkbox.value,
                },
              };
              if (event.target.checked) {
                addSalesChannel(params)
              } else {
                removeSalesChannel(params).catch((e) => {
                  const error = e.graphQLErrors.find((ee) => ee.extensions.code === '409');
                  if (error) {
                    setToRemoveChannel(checkbox.value);
                    setPmIssues(error.extensions.issues);
                  }
                });
              }
            }}
          >
            {t(`andMe.paymentNetwork.merchantContract.channels.${checkbox.value}`)}
          </Checkbox>
          {!merchantContract?.partner?.partner_routes?.filter(partnerRoute => !merchantContract.settlement_currency || partnerRoute.settlement_currency === merchantContract.settlement_currency)?.find(partnerRoute => partnerRoute.paymentChannel.id === checkbox.value)
            ? (
              <Tooltip
                title={`${t(`andMe.paymentNetwork.merchantContract.channels.${checkbox.value}`)} is not available on ${merchantContract.partner.name}${merchantContract.settlement_currency ? ` with settlement currency in ${merchantContract.settlement_currency}` : ''}`}
                placement='topRight'
              >
                <LordIcon
                  key="warningIcon"
                  classes={{ [styles.warningIcon]: true }}
                  type="error"
                  isStopped={false}
                  autoplay
                  loop
                  height={32}
                />
              </Tooltip>
            ) : null
          }
        </Space>
      ))}
      <HandleRoutesModal
        issues={pmIssues}
        merchantContractId={merchantContract?.id}
        onCancel={() => {
          setToRemoveChannel(null);
          setPmIssues(null);
        }}
        onConfirm={async () => {
          setPmIssues(null);
          await removeSalesChannel({
            variables: {
              id: merchantContract?.id,
              channel: toRemoveChannel,
              force: true,
            },
          });
          setToRemoveChannel(null);
        }}
      />
    </Item>
  );
};

export default MerchantContractChannels;

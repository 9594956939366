import React from 'react';
import { Button, DatePicker, Drawer, Form, Input, Select } from 'antd';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import { Block } from '../../../Common/Sider';
import { exportStatusList } from '../constants/exportStatuses';
import { sendingMethodList } from '../constants/sendingMethods';
import { reportDelimiterList } from '../constants/reportDelimiters';
import { reportFormatList } from '../constants/reportFormats';

const SearchDrawerContent = ({ searchFilters, onSearch, onClose }) => {
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    onSearch(values);
    onClose();
  };

  return (
    <Block key="search-form" title="Search" description="Filter export list">
      <Form layout="vertical" form={form} onFinish={handleFinish} initialValues={searchFilters}>
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input />
        </Form.Item>
        <Form.Item label="Status" name="status">
          <Select
            options={exportStatusList}
            mode="multiple"
            maxTagCount="responsive"
            allowClear
            showSearch
          />
        </Form.Item>
        <Form.Item label="Method" name="sendingMethod">
          <Select options={sendingMethodList} mode="multiple" maxTagCount="responsive" allowClear showSearch />
        </Form.Item>
        <Form.Item label="Delimiter" name="delimiter">
          <Select options={reportDelimiterList} mode="multiple" maxTagCount="responsive" allowClear showSearch />
        </Form.Item>
        <Form.Item label="Format" name="format">
          <Select options={reportFormatList} mode="multiple" maxTagCount="responsive" allowClear showSearch />
        </Form.Item>
        <Form.Item label="Creation date" name="createdAt">
          <DatePicker.RangePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label="Last execution date" name="lastExecutionDate">
          <DatePicker.RangePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            SEARCH
          </Button>
        </Form.Item>
      </Form>
    </Block>
  );
};

const SearchDrawer = ({ open, onClose, onSearch, searchFilters, containerId }) => (
  <Drawer
    width={470}
    open={open}
    getContainer={`#${containerId}`}
    style={{ position: 'absolute', zIndex: 50 }}
    bodyStyle={{ padding: 0 }}
    onClose={onClose}
    destroyOnClose
    title="Search"
    closable
    closeIcon={<WindowCloseIcon />}
    mask={false}
    push={false}
  >
    <SearchDrawerContent onClose={onClose} onSearch={onSearch} searchFilters={searchFilters} />
  </Drawer>
);

export default SearchDrawer;

import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { downloadAsCsvFile, downloadAsJsonFile } from '../../../../../../util/file';

const DataExportMenu = ({ data = [], widget }) => {
  const items = [
    { key: 'json', label: 'JSON' },
    { key: 'csv', label: 'CSV' },
  ];

  const handleMenuClick = (e) => {
    switch (e.key) {
      case 'json':
        downloadAsJsonFile(data, widget.name);
        break;
      case 'csv':
        downloadAsCsvFile(data, widget.name);
        break;
      default:
        throw new Error('Unknown export format', e.key);
    }
  };

  return (
    <Dropdown menu={{ items, onClick: handleMenuClick }} trigger="click" disabled={data.length === 0}>
      <Button icon={<DownloadOutlined />} type="text" title="Export data" />
    </Dropdown>
  );
};

export default DataExportMenu;

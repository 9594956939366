import React from 'react';
import InfoTile, { InfoSkeletonRow } from '../../../Tile/Info/Info';

const BasketSummarySkeleton = () => (
  <InfoTile name="basket-summary" title="Basket summary">
    <InfoSkeletonRow label="Amount" />
    <InfoSkeletonRow label="Number of items" />
    <InfoSkeletonRow label="VAT" />
    <InfoSkeletonRow label="Number of sellers" />
  </InfoTile>
);

export default BasketSummarySkeleton;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Form, Input, message, Typography } from 'antd';
import { gql, useMutation } from '@apollo/client';
import styles from '../AppUnlogged.module.scss';
import useNavigateWithSearch from '../../../util/navigate';

export const SET_PASSWORD = gql`
  mutation SetPassword($password: String!, $token: String!) {
    setPassword(password: $password, token: $token)
  }
`;

const SetPassword = () => {
  const navigate = useNavigateWithSearch();
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('email_verification_token');

  const [setPassword, { loading, error }] = useMutation(SET_PASSWORD, {
    onCompleted: () => {
      message.success('Password successfully set');
      navigate(`/login`);
    },
  });

  const handleSubmit = ({ password }) =>
    setPassword({
      variables: {
        token,
        password,
      },
    });

  return (
    <div className={styles.block}>
      <Typography.Title level={4}>Set password</Typography.Title>
      <Typography.Paragraph className={styles.description}>
        In order to protect your account, make sure to use original complex password.
      </Typography.Paragraph>
      <Typography.Paragraph className={styles.description}>E.g. thinkofaphraseyouwillremember</Typography.Paragraph>
      <Form
        name="set-password"
        className={styles.form}
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          name="password"
          label="New Password"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
            {
              min: 16,
              message: '16 characters minimum.',
            },
            () => ({
              validator(_, value) {
                if (value === 'thinkofaphraseyouwillremember') {
                  return Promise.reject(new Error('This was just an example'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        {!!error && (
          <Typography.Text className={styles.error} type="danger">
            {error.message}
          </Typography.Text>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" className={styles.submitBtn} loading={loading}>
            Submit password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SetPassword;

import { gql } from '@apollo/client';

export const removeRuleMutation = gql`
  mutation RemoveRule($id: ID!) {
    removeRule(id: $id) {
      id
      status
    }
  }
`;

export const updateMerchantAccountRulesOrder = gql`
  mutation UpdateMerchantAccountRulesOrder($id: ID!, $input: MerchantAccountRulesInput!) {
    updateMerchantAccountRulesOrder(id: $id, input: $input) {
      id
      rules {
        id
        order
      }
    }
  }
`;

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import colors, { ColorGenerator } from '../../constants/colors';

/**
 * TraceableSankeyDiagram
 * @param divId
 * @param widget
 * @param data
 * @returns {SankeyDiagram}
 */
export default (divId, widget, data) => {
  const chart = am4core.create(divId, am4charts.SankeyDiagram);
  chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

  // defined one color by state (payin_parnter + success + failed)
  const colorGenerator = new ColorGenerator({ startIndex: 13 });
  const stateColors = { success: colors[12], failed: colors[2] };
  const getColorFromState = (state) => {
    if (!stateColors[state]) {
      stateColors[state] = colorGenerator.next();
    }
    return stateColors[state];
  };

  // define colors for all nodes
  const nodeColors = {};

  const sankeyData = data.data.reduce((memo, row, indexRow) => {
    let prevState;
    row.states.forEach((state, indexState) => {
      const name = `${indexState + 1} - ${state}`;

      // add link
      if (indexState > 0) {
        memo.push({
          id: `s${indexRow}-${indexState}`,
          from: `${indexState} - ${prevState}`,
          to: name,
          value: row.value,
        });
      }
      // keep node color
      if (!nodeColors[name]) {
        nodeColors[name] = {
          name,
          color: getColorFromState(state),
        };
      }
      prevState = state;
    });

    return memo;
  }, []);

  // add fake node to handle colors
  sankeyData.unshift(
    ...Object.values(nodeColors).map(({ name, color }) => ({
      id: `color-${name}`,
      from: name,
      nodeColor: color,
    })),
  );

  chart.data = sankeyData;

  const hoverState = chart.links.template.states.create('hover');
  hoverState.properties.fillOpacity = 0.6;

  chart.dataFields.fromName = 'from';
  chart.dataFields.toName = 'to';
  chart.dataFields.value = 'value';
  chart.dataFields.color = 'nodeColor';

  chart.links.template.propertyFields.id = 'id';
  chart.links.template.colorMode = 'solid';
  chart.links.template.fill = new am4core.InterfaceColorSet().getFor('alternativeBackground');
  chart.links.template.fillOpacity = 0.1;
  chart.links.template.tooltipText = '{value} orders';

  // highlight all links with the same id beginning
  chart.links.template.events.on('over', (event) => {
    const targetLink = event.target;
    const id = targetLink.id.split('-')[0];

    chart.links.each((link) => {
      if (link.id.indexOf(`${id}-`) !== -1) {
        // eslint-disable-next-line no-param-reassign
        link.fillOpacity = 0.6;
      }
    });
  });

  chart.links.template.events.on('out', () => {
    chart.links.each((link) => {
      // eslint-disable-next-line no-param-reassign
      link.fillOpacity = 0.1;
    });
  });

  // for right-most label to fit
  chart.paddingRight = 100;

  // make nodes draggable
  const nodeTemplate = chart.nodes.template;
  nodeTemplate.inert = true;
  nodeTemplate.width = 20;
  nodeTemplate.readerTitle = 'Click to show/hide or drag to rearrange';
  nodeTemplate.showSystemTooltip = true;
  nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;

  return chart;
};

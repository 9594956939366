import React from 'react';
import LordIcon from '../../../../../../assets/icons/LordIcon';
import styles from './common.module.scss';

const NoDataChart = () => (
  <div className={styles.container}>
    <LordIcon type="settings-sliders-morph-outline-edited" width={136} height={136} isStopped={false} autoplay />
    <div>No data found, try to adjust filters</div>
  </div>
);

export default NoDataChart;

import React, { useState } from 'react';
import { Badge, Button, message, Space, Switch, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import TableTile, { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import ApiKeyCreate from './ApiKeyCreate/ApiKeyCreate';
import { ApiKeyUpdateMutation } from './query';
import { components } from '../../../../Common/EditableCell/EditableCell';
import { useCan } from '../../../../../../contexts/ability.context';
import { middleEllipsis, search } from '../../../../../../util/string';
import { compareByProp } from '../../../../../../util/array';
import Loader from '../../../../Common/Loader/Loader';

const ApiKeys = ({ merchantAccount }) => {
  const can = useCan();
  const [visible, setVisible] = useState(false);

  const showCreationModal = () => {
    setVisible(true);
  };

  const hideCreationModal = () => {
    setVisible(false);
  };

  const [update, { loading }] = useMutation(ApiKeyUpdateMutation, {
    onCompleted: () => {
      message.success('Api key successfully updated');
    },
    onError: (err) => message.error(err.message),
  });

  const dataSource = merchantAccount.api_keys
    ?.map((apiKey) => ({
      key: apiKey.id,
      name: apiKey.name,
      privacy: apiKey.is_public ? 'Public' : 'Private',
      is_internal: apiKey.is_internal,
      keyString: apiKey.key,
      active: apiKey.status === 'active',
    }))
    .sort(compareByProp('name', 'asc', 'string'));

  const handleSave = (row) => {
    update({
      variables: {
        id: row.key,
        input: {
          name: row.name,
          status: row.active ? 'active' : 'inactive',
        },
      },
    });
  };

  const columns = [
    {
      title: 'Label',
      dataIndex: 'name',
      key: 'name',
      render: (label, { active }) => <Badge status={active ? 'success' : 'warning'} text={label} />,
      ...(can('update', 'api_key')
        ? {
            width: '30%',
            editable: can('update', 'api_key'),
            onCell: (record) => ({
              title: 'Label',
              dataIndex: 'name',
              editable: true,
              record,
              handleSave,
            }),
          }
        : {}),
      ...getColumnFilterSearchInput((value, record) => search(value, record.name)),
      sorter: compareByProp('name', 'asc', 'string'),
    },
    {
      title: 'Privacy',
      dataIndex: 'privacy',
      key: 'privacy',
      filters: [
        { text: 'Public', value: 'Public' },
        { text: 'Private', value: 'Private' },
      ],
      onFilter: (value, record) => value === record.privacy,
    },
    {
      title: 'Internal',
      dataIndex: 'is_internal',
      key: 'is_internal',
      filters: [
        { text: 'True', value: true },
        { text: 'False', value: false },
      ],
      onFilter: (value, record) => value === record.is_internal,
      render: (value) => (value ? 'Internal' : ''),
    },
    {
      title: 'Token',
      dataIndex: 'keyString',
      key: 'keyString',
      render: (value) =>
        value &&
        typeof value === 'string' && (
          <Typography.Text copyable={{ text: value }}>
            {middleEllipsis(value, 30)}
          </Typography.Text>
        ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active, row) => (
        <Switch
          checked={active}
          disabled={!can('update', 'api_key')}
          title={active ? 'Active' : 'Inactive'}
          onChange={(value) => handleSave({ ...row, active: value })}
        />
      ),
      filters: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
      onFilter: (value, record) => value === record.active,
      fixed: 'right',
      width: 100,
    },
  ];

  // return info tile
  return (
    <>
      <TableTile
        name="ma-api-keys"
        title={
          <Space>
            Api Keys
            {loading && <Loader />}
          </Space>
        }
        description="The API key acts as a unique identifier and a secret token for authentication, and have a set of access rights on the API associated with it."
        columns={columns}
        dataSource={dataSource}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 5,
          pageSizeOptions: [5, 10, 20, 50, 100],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} keys`,
        }}
        size="small"
        extra={[
          can('create', 'api_key') && (
            <Button key="add-api-key" icon={<PlusOutlined />} onClick={showCreationModal}>
              Api Key
            </Button>
          ),
        ]}
        components={components}
        rowClassName={can('update', 'api_key') && 'editable-row'}
      />
      <ApiKeyCreate visible={visible} onClose={hideCreationModal} merchantAccount={merchantAccount} />
    </>
  );
};

export default ApiKeys;

import React from 'react';
import styles from './Footer.module.scss';
import Time from './Time/Time';

const Footer = ({ children, createdAt }) => (
  <div
    className={styles.root}
  >
    <Time
      createdAt={createdAt}
    />
    <div
      className={styles.buttons}
    >
      {children}
    </div>
  </div>
);

export default Footer;
import { gql } from '@apollo/client';

export const MetaProgramManagerUsersQuery = gql`
  query MetaProgramManagerUsers($id: ID!) {
    metaProgramManager(id: $id) {
      id
      users {
        id
        email
        full_name
        avatar
        status
        disabled
        aclProfile {
          id
          name
        }
      }
    }
    metaProgramManagerAclProfiles {
      id
      name
    }
  }
`;

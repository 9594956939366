import React from 'react';
import { Select } from 'antd';
import _ from 'norbr-shared-lib/constants/currencies/enum';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import useBaseCurrency from '../../hooks/useBaseCurrency';
import styles from './CurrencySelector.module.scss';

const baseCurrencies = [_.CAD, _.EUR, _.GBP, _.HKD, _.SGD, _.TRY, _.USD].map((c) => ({
  value: c,
  label: `[${c}] ${CURRENCIES[c].label}`,
  symbol: CURRENCIES[c].symbol,
}));

const CurrencySelector = () => {
  const [baseCurrency, setBaseCurrency] = useBaseCurrency();
  return (
    <Select
      className={styles.root}
      defaultValue={baseCurrency}
      onChange={setBaseCurrency}
      options={baseCurrencies}
      optionLabelProp="value"
      dropdownMatchSelectWidth={false}
      bordered={false}
    />
  );
};

export default CurrencySelector;

import React, { useState } from 'react';

import { Button, Checkbox, Drawer, Form, Input, message, Select, Space } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import styles from '../../ProgramManagerView/ProgramManagerContacts/ContactForm/ContactForm.module.scss';
import {createProgramManagerMutation, MetaProgramManagersQuery, PartnerCompaniesQuery} from './query';
import { compareByProp } from '../../../../../../util/array';
import { search } from '../../../../../../util/string';
import useNavigateWithSearch from '../../../../../../util/navigate';
import {MODES, useMode} from '../../../../../../contexts/mode.context';

const ProgramManagerCreate = ({ visible, toggle }) => {
  const navigate = useNavigateWithSearch();
  const mode = useMode();
  const [form] = Form.useForm();

  const [selectedCompany, setSelectedCompany] = useState();

  const {
    data: { partnerCompanies } = {
      partnerCompanies: [],
    },
    loading: loadingCompanies,
  } = useQuery(PartnerCompaniesQuery);

  const {
    data: { metaProgramManagers } = {
      metaProgramManagers: [],
    },
    loading: loadingMetaProgramManagers,
  } = useQuery(MetaProgramManagersQuery);

  const [create, { loading: loadingCreate }] = useMutation(createProgramManagerMutation, {
    refetchQueries: ['ProgramManagers'],
    onCompleted: ({ createProgramManager }) => {
      message.success('Program manager successfully created');
      navigate(`/program-managers/${createProgramManager.id}`);
    },
    onError: (e) => {
      const error = e.graphQLErrors.find((ee) => ee.extensions.code === '409');
      if (error) {
        Object.keys(error.extensions.existing).forEach((eKey) =>
          message.error(
            `Unique field ${eKey} already exists : ${error.extensions.existing[eKey]}`,
          )
        )
      } else {
        message.error('An error occurred, please try again later.');
      }
    },
  });

  return (
    <Drawer
      open={visible}
      onClose={toggle}
      title="Create Program manager"
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(input) => {
          create({
            variables: {
              input,
            },
          });
        }}
      >
        {mode === MODES.INTERNAL && (
          <Form.Item label="Meta program manager" name="metaProgramManager">
            <Select
              loading={loadingMetaProgramManagers}
              options={metaProgramManagers
                ?.map((c) => ({
                  value: c.id,
                  label: c.name,
                }))
                .sort(compareByProp('label', 'asc', 'string'))}
              showSearch
              filterOption={(inputValue, option) => search(inputValue, option.label)}
              allowClear
            />
          </Form.Item>
        )}
        <Form.Item label="Name" name="name" required>
          <Input />
        </Form.Item>
        <Form.Item
          label="Display name"
          name="displayName"
          rules={[{ required: true }]}
          tooltip="Name displayed on the merchant console."
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Code"
          name="code"
          rules={[{ required: true }, { len: 4, message: 'Code should have 4 characters.' }]}
          tooltip="Technical code (e.g. used to prefix API keys like XXXX_PR_123456789...)."
        >
          <Input />
        </Form.Item>
        <Form.Item name="existingCompany" label="Select an existing company">
          <Select
            loading={loadingCompanies}
            options={partnerCompanies
              ?.map((c) => ({
                value: c.id,
                label: c.name,
              }))
              .sort(compareByProp('label', 'asc', 'string'))}
            showSearch
            filterOption={(inputValue, option) => search(inputValue, option.label)}
            allowClear
            onChange={setSelectedCompany}
          />
        </Form.Item>
        <Form.Item name="newCompany" label="Or create new company">
          <Input disabled={!!selectedCompany} />
        </Form.Item>
        <Form.Item
          name="merchantDomain"
          label="Merchant domain"
          rules={[
            { required: true },
            {
              validator: (_, value) =>
                value.indexOf('http') === 0 ? Promise.reject(new Error('Invalid format')) : Promise.resolve(),
              message: 'Invalid format. Please enter the domain, not the URL.',
            },
          ]}
          tooltip="Domain of merchant application."
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="adminDomain"
          label="Admin domain"
          rules={[
            { required: true },
            {
              validator: (_, value) =>
                value.indexOf('http') === 0 ? Promise.reject(new Error('Invalid format')) : Promise.resolve(),
              message: 'Invalid format. Please enter the domain, not the URL.',
            },
          ]}
          tooltip="Domain of admin application."
        >
          <Input />
        </Form.Item>
        <Form.Item name="payfac" valuePropName="checked" initialValue={false}>
          <Checkbox>Payment Facilitator</Checkbox>
        </Form.Item>
        <Form.Item className={styles.submitItem}>
          <Space>
            <Button onClick={toggle}>Cancel</Button>
            <Button onClick={() => form.resetFields()}>Reset</Button>
            <Button type="primary" htmlType="submit" loading={loadingCreate}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ProgramManagerCreate;

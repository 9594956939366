import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  CheckOutlined,
  DeleteOutlined,
  PlusOutlined,
  RightOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Select, Space, Tag } from 'antd';
import TableTile, { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import { useCan } from '../../../../../../contexts/ability.context';
import useToggle from '../../../../../../hooks/useToggle';
import { CompanyUserAuthorizedMerchantAccountMutation } from '../../../Company/query';
import USER_STATUSES from '../../../../../../constants/USER_STATUSES';
import { initials, search } from '../../../../../../util/string';
import { compareByProp } from '../../../../../../util/array';
import { ProfileListQuery } from '../../../AccessControl/query';
import styles from '../MerchantAccountView.module.scss';

const Users = ({ merchantAccount }) => {
  const can = useCan();

  const [showUserSelect, { toggle }] = useToggle(false);
  const [selectedUser, setSelectedUser] = useState();

  const [updateCompanyUser] = useMutation(CompanyUserAuthorizedMerchantAccountMutation, {
    refetchQueries: ['MerchantAccountViewQuery'],
  });

  const submitUser = () => {
    updateCompanyUser({
      variables: { id: selectedUser, merchantAccount: merchantAccount.id, toAdd: true },
    });
    setSelectedUser(null);
    toggle();
  };

  const profilesQuery = useQuery(ProfileListQuery);

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (name, { userId, avatar, guest }) =>
        guest ? (
          <Link to={`/internal-users/${userId}`}>
            <Space>
              <Avatar src={avatar}>{initials(name)}</Avatar>
              {name}
              <UserSwitchOutlined className={styles.badgeUserGuest} title="Connected as guest" />
            </Space>
          </Link>
        ) : (
          <Link to={`/users/${userId}`}>
            <Space>
              <Avatar src={avatar}>{initials(name)}</Avatar>
              {name}
            </Space>
          </Link>
        ),
      ...getColumnFilterSearchInput((value, record) => search(value, record.username)),
      fixed: 'left',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnFilterSearchInput((value, record) => search(value, record.email)),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, { disabled }) =>
        disabled ? (
          <Tag color="volcano">Locked</Tag>
        ) : (
          status &&
          USER_STATUSES[status] && <Tag color={USER_STATUSES[status].color}>{USER_STATUSES[status].label}</Tag>
        ),
    },
    {
      title: 'Profile',
      dataIndex: 'profile',
      key: 'profile',
      filters: profilesQuery.data?.merchantAclProfiles.map((p) => ({
        value: p.name,
        text: p.name,
      })),
      onFilter: (value, record) => value === record.profile,
    },
    {
      title: 'GDPR',
      dataIndex: 'isGdpr',
      key: 'isGdpr',
      align: 'center',
      width: 80,
      render: (value) => value && <CheckOutlined />,
      sorter: compareByProp('isGdpr'),
    },
  ];

  if (can('manage-users', 'company')) {
    columns.push({
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 80,
      render: (value, { key: companyUserId }) => [
        <Button
          key="remove-user-access"
          icon={<DeleteOutlined />}
          onClick={() => {
            updateCompanyUser({
              variables: { id: companyUserId, merchantAccount: merchantAccount.id, toAdd: false },
            });
          }}
          title="Remove user from merchant account"
          shape="circle"
          type="text"
        />,
      ],
    });
  }

  const dataSource = merchantAccount.users?.map(({ id, user, aclProfile, isGdpr, guest }) => ({
    key: id,
    userId: user.id,
    username: user.full_name,
    email: user.email,
    status: user.status,
    disabled: user.disabled,
    avatar: user.avatar,
    profile: aclProfile.name,
    isGdpr,
    guest,
  }));

  // return info tile
  return (
    <>
      <TableTile
        name="ma-users"
        title="Users"
        columns={columns}
        dataSource={dataSource}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 5,
          pageSizeOptions: [5, 10, 20, 50, 100],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} users`,
        }}
        size="small"
        scroll={{ x: 1000 }}
        extra={[
          can('manage-users', 'company') && (
            <Button key="add-api-key" icon={showUserSelect ? <RightOutlined /> : <PlusOutlined />} onClick={toggle}>
              Users
            </Button>
          ),
          can('manage-users', 'company') &&
            showUserSelect && [
              <Select
                key="select-user"
                style={{ width: 300 }}
                maxTagCount={1}
                onChange={setSelectedUser}
                showSearch
                optionFilterProp="label"
                optionLabelProp="label"
              >
                {merchantAccount?.company.users
                  .filter(({ id: cuid }) => !merchantAccount.users.find(({ id: muid }) => cuid === muid))
                  .map(({ id, user }) => (
                    <Select.Option key={id} value={id} label={user.full_name}>
                      <Space>
                        <Avatar src={user.avatar} icon={<UserOutlined />} size="small" />
                        {user.full_name} ({user.email})
                      </Space>
                    </Select.Option>
                  ))}
              </Select>,
              <Button
                key="submit-users"
                shape="circle"
                icon={<CheckOutlined />}
                type="primary"
                size="small"
                onClick={submitUser}
                disabled={!selectedUser}
              />,
            ],
        ]}
      />
    </>
  );
};

export default Users;

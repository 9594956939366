import { AlertOutlined, CodeOutlined, RocketOutlined, ThunderboltOutlined } from '@ant-design/icons';

export const AnnouncementTypes = {
  MAINTENANCE: 'maintenance',
  FEATURE: 'feature',
  RELEASE: 'release',
  ALERT: 'alert',
};

const announcementTypes = {
  [AnnouncementTypes.ALERT]: {
    value: AnnouncementTypes.ALERT,
    label: 'Alert',
    icon: AlertOutlined,
    color: '#F5222D',
  },
  [AnnouncementTypes.FEATURE]: {
    value: AnnouncementTypes.FEATURE,
    label: 'New Feature',
    icon: ThunderboltOutlined,
    color: '#FADB14',
  },
  [AnnouncementTypes.MAINTENANCE]: {
    value: AnnouncementTypes.MAINTENANCE,
    label: 'Maintenance',
    icon: CodeOutlined,
    color: '#FA8C16',
  },
  [AnnouncementTypes.RELEASE]: {
    value: AnnouncementTypes.RELEASE,
    label: 'Release',
    icon: RocketOutlined,
    color: '#722ED1',
  },
};

export const announcementTypeList = Object.values(announcementTypes);

export default announcementTypes;

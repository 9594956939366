import React, { useEffect } from 'react';
import { Button, Progress, Typography } from 'antd';
import { useMutation } from '@apollo/client';
import { PartnerRoutesCreateMutation } from './query';

const SubmitProgress = ({ partner, routes, onClose }) => {
  const [create, { data, loading, error }] = useMutation(PartnerRoutesCreateMutation, {
    refetchQueries: ['PartnerRoutesQuery', 'PartnerRouteListQuery', 'PartnerViewQuery'],
  });

  useEffect(() => {
    create({
      variables: {
        partner,
        input: routes.map((route) => ({
          partner,
          payment_method: route.paymentMethod,
          status: route.status,
          authorization_currency: route.authorizationCurrency,
          settlement_currency: route.settlementCurrency,
          channel: route.paymentChannel,
          ...route.options.reduce((memo, option) => ({ ...memo, [option.key]: option.value || false }), {}),
        })),
      },
    });
  }, []);

  return [
    <div key="progress-bar">
      <Progress percent={data ? 100 : 66} status={loading && 'active'} />
    </div>,
    loading && <Typography.Paragraph key="progress-info">Processing partner routes creation...</Typography.Paragraph>,
    error && <Typography.Paragraph key="error">An error occurred.</Typography.Paragraph>,
    data && (
      <Typography.Paragraph key="success-info">
        <b>{routes.length}</b> partner routes were successfully created.
      </Typography.Paragraph>
    ),
    <Button key="submit-btn" onClick={onClose} type="primary">
      Done
    </Button>,
  ];
};

export default SubmitProgress;

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Col, Form, message, Row, Select, Space, Switch } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import InfoTile, { InfoBooleanRow, InfoIconRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { PartnerUpdateMutation } from '../query';
import styles from '../PartnerView.module.scss';
import { useCan } from '../../../../../../contexts/ability.context';
import {
  partnerRiskAssessmentServiceList,
  partnerRiskAssessmentServices,
  partnerRiskOptionList,
} from '../../../PartnerRoutes/constants';

const RiskOptions = ({ partner }) => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);

  const [updatePartner, { loading }] = useMutation(PartnerUpdateMutation, {
    onCompleted: () => {
      message.success('Partner successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    updatePartner({
      variables: { id: partner.id, input: values },
    });
  };

  // return edit form tile
  if (editMode) {
    const renderFormItem = (option) => {
      switch (option.type) {
        case 'PartnerRiskAssessmentService':
          return (
            <Form.Item name={option.value} label={option.label}>
              <Select>
                <Select.Option value={null} label="None">
                  None
                </Select.Option>
                {partnerRiskAssessmentServiceList.map((responseType) => (
                  <Select.Option key={responseType.value} value={responseType.value} label={responseType.label}>
                    <Space>{responseType.label}</Space>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          );
        case 'Boolean':
        default:
          return (
            <Form.Item name={option.value} label={option.label} valuePropName="checked" colon={false}>
              <Switch />
            </Form.Item>
          );
      }
    };

    return (
      <InfoTile name="risk-options" title="Risk options">
        <Form
          form={form}
          title="Risk options"
          layout="vertical"
          size="small"
          initialValues={partner}
          onFinish={handleFinish}
          requiredMark={false}
          className="tile-form"
        >
          <Row gutter={24}>
            {partnerRiskOptionList.map((option) => (
              <Col key={option.value} xs={24} sm={24} md={12} lg={12} xl={8}>
                {renderFormItem(option)}
              </Col>
            ))}
          </Row>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  const renderOption = (option) => {
    switch (option.type) {
      case 'PartnerRiskAssessmentService':
        return (
          <InfoIconRow
            key={option.value}
            label={option.label}
            value={partnerRiskAssessmentServices[partner[option.value]]?.label}
          />
        );
      case 'Boolean':
      default:
        return <InfoBooleanRow key={option.value} value={partner[option.value]} label={option.label} />;
    }
  };

  return (
    <InfoTile
      name="risk-options"
      title="Risk options"
      extra={[
        can('update', 'partner') && (
          <Button key="update-partner" onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        ),
      ]}
    >
      <Row gutter={24}>
        {partnerRiskOptionList.map((option) => (
          <Col key={option.value} xs={24} sm={24} md={12} lg={12} xl={8}>
            {renderOption(option)}
          </Col>
        ))}
      </Row>
    </InfoTile>
  );
};

export default RiskOptions;

import { gql } from '@apollo/client';

export const createGuestAccessMutation = gql`
  mutation CreateGuestAccess($companyId: ID!, $userId: ID!, $aclProfileId: ID) {
    createGuestAccess(companyId: $companyId, userId: $userId, aclProfileId: $aclProfileId) {
      id
      createdAt
      company {
        ... on MerchantCompany {
          programManager {
            merchantDomain
          }
        }
      }
    }
  }
`;

export default {};

import React from 'react';
import InfoTile, { InfoSkeletonRow } from '../../../Tile/Info/Info';

const CustomerDetails = () => (
  <InfoTile name="checkout-details" title="Checkout details">
    <InfoSkeletonRow label="Checkout ID" />
    <InfoSkeletonRow label="Checkout date" />
    <InfoSkeletonRow label="Amount" />
    <InfoSkeletonRow label="Currency" />
    <InfoSkeletonRow label="Customer email" />
    <InfoSkeletonRow label="Customer ID" />
    <InfoSkeletonRow label="Type" />
    <InfoSkeletonRow label="Expiration" />
  </InfoTile>
);

export default CustomerDetails;

import React, { useMemo, useState } from 'react';
import { Button, Collapse, Modal, Row, Space, Table } from 'antd';
import { gql, useQuery } from '@apollo/client';
import feeTypes from 'norbr-shared-lib/constants/reconciliation/feeTypes';
import feeTypeEnumList from 'norbr-shared-lib/constants/reconciliation/feeTypes/enumList';
import operationTypes from 'norbr-shared-lib/constants/reconciliation/operationTypes';
import operationTypeEnumList from 'norbr-shared-lib/constants/reconciliation/operationTypes/enumList';
import feeCategories from 'norbr-shared-lib/constants/reconciliation/feeCategories';
import feeCategoryEnumList from 'norbr-shared-lib/constants/reconciliation/feeCategories/enumList';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { amountFormatter } from '../../../../../../util/formatter';
import useSearchFilters from '../../../hooks/useSearchFilters';
import useBaseCurrency from '../../../hooks/useBaseCurrency';
import { config as WidgetTypes } from '../../constants/widgetTypes';
import { brainpowerQuery } from '../Common/query';
import styles from './chart.module.scss';
import DataExportMenu from '../Common/DataExportMenu';

const partnerListQuery = gql`
  {
    partners {
      id
      api_name
      name
      type
      company {
        theme {
          icon
        }
      }
    }
  }
`;

const Unit = {
  FEE_AMOUNT: 'fee_amount',
  VOLUME: 'volume',
};
const units = {
  [Unit.FEE_AMOUNT]: { value: Unit.FEE_AMOUNT, label: 'Amount' },
  [Unit.VOLUME]: { value: Unit.VOLUME, label: 'Volume' },
};

const buildPartnerColumns = (data = [], partnerList = [], currency) =>
  data.reduce((memo, row) => {
    const partner = partnerList.find((p) => p.api_name === row.payin_partner);
    return [
      ...memo,
      {
        title: partner ? (
          <Space>
            <img
              src={partner.company?.theme?.icon}
              alt={partner.name}
              title={partner.name}
              height="24"
              style={{ borderRadius: 2 }}
            />
            {partner.name}
          </Space>
        ) : (
          row.payin_partner
        ),
        dataIndex: row.payin_partner,
        render: (value) => amountFormatter(value, currency),
        width: 140,
        align: 'right',
      },
    ];
  }, []);

const buildFullDataSource = (data) =>
  feeCategoryEnumList.reduce(
    (memo, feeCategory) => ({
      ...memo,
      [feeCategory]: operationTypeEnumList.flatMap((operationType) =>
        feeTypeEnumList
          .flatMap((feeType) =>
            [Unit.FEE_AMOUNT, Unit.VOLUME].map((unit) => ({
              fee_category: feeCategory,
              operation: operationType,
              fee_type: feeType,
              unit,
              ...data.reduce(
                (memo3, row) => ({
                  ...memo3,
                  total: memo3.total + (row[feeCategory]?.[operationType]?.fee_types?.[feeType]?.[unit] ?? 0),
                  [row.payin_partner]: row[feeCategory]?.[operationType]?.fee_types?.[feeType]?.[unit] ?? 0,
                }),
                { total: 0 },
              ),
            })),
          )
          .filter((r) => r.total !== 0)
          .map((r, index, list) => ({ ...r, rowSpan: index === 0 ? list.length : 0 })),
      ),
    }),
    {},
  );

const FullscreenModal = ({ open, onCancel, data }) => {
  const [baseCurrency] = useBaseCurrency();
  const partnersQuery = useQuery(partnerListQuery);

  const columns = useMemo(
    () => [
      {
        title: 'Operation',
        dataIndex: 'operation',
        onCell: ({ rowSpan }) => ({ rowSpan, colSpan: rowSpan ? 1 : 0 }),
        render: (value) => operationTypes[value]?.label ?? value,
        ellipsis: true,
      },
      {
        title: 'Fee type',
        dataIndex: 'fee_type',
        onCell: ({ unit }) => ({ rowSpan: unit === Unit.VOLUME ? 0 : 2 }),
        render: (value) => feeTypes[value]?.label ?? value,
        ellipsis: true,
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        render: (value) => <span style={{ color: 'lightgrey' }}>{units[value]?.label ?? value}</span>,
        width: 72,
      },
      {
        title: 'TOTAL',
        dataIndex: 'total',
        render: (value, { unit }) => (unit === Unit.FEE_AMOUNT ? amountFormatter(value, baseCurrency) : value),
        align: 'right',
      },
      ...buildPartnerColumns(data, partnersQuery?.data?.partners, baseCurrency).map((c) => ({
        ...c,
        render: (value, { unit }) => (unit === Unit.FEE_AMOUNT ? amountFormatter(value, baseCurrency) : value),
      })),
    ],
    [data],
  );

  const dataSource = buildFullDataSource(data);

  return (
    <Modal
      open={open}
      footer={null}
      closable={false}
      width="95%"
      destroyOnClose
      onCancel={onCancel}
      forceRender
      bodyStyle={{ height: '95vh', padding: '8px 12px', overflowY: 'scroll' }}
      className={styles.modal}
    >
      <Row justify="space-between" align="middle">
        <div className={styles.title}>Financial analysis</div>
        <Space size={2}>
          <DataExportMenu data={Object.values(dataSource).flat()} widget={{ name: 'Financial analysis' }} />
          <Button type="text" icon={<FullscreenExitOutlined />} onClick={onCancel} />
        </Space>
      </Row>
      <Space direction="vertical" style={{ width: '100%' }}>
        {feeCategoryEnumList.map((feeCategory) => (
          <Collapse collapsible="header" defaultActiveKey={[feeCategory]}>
            <Collapse.Panel header={feeCategories[feeCategory].label} key={feeCategory}>
              <Table
                columns={columns}
                size="small"
                pagination={false}
                bordered
                dataSource={dataSource[feeCategory]}
                summary={(pageData) => (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={3}>
                        TOTAL
                      </Table.Summary.Cell>
                      {pageData
                        .reduce(
                          (memo, row) =>
                            row.unit === Unit.FEE_AMOUNT
                              ? memo.map((v, i) => v + (i === 0 ? row.total : row[data[i - 1].payin_partner]))
                              : memo,
                          Array(data.length + 1).fill(0),
                        )
                        .map((total, length) => (
                          <Table.Summary.Cell index={length + 1} align="right">
                            {amountFormatter(total, baseCurrency)}
                          </Table.Summary.Cell>
                        ))}
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
            </Collapse.Panel>
          </Collapse>
        ))}
      </Space>
    </Modal>
  );
};

const FinancialAnalysis = (props) => {
  const { widget, layout } = props;

  const [open, setOpen] = useState(false);

  const [baseCurrency] = useBaseCurrency();
  const { list } = useSearchFilters();

  const partnersQuery = useQuery(partnerListQuery);

  const widgetType = useMemo(() => {
    if (!WidgetTypes[widget.type]) {
      throw new Error(`Widget type ${widget.type} does not exist.`, widget.i);
    }
    return WidgetTypes[widget.type];
  }, [widget]);

  const timezone = 'UTC';

  const params = useMemo(
    () => ({
      filters: list.map((filter) => ({
        name: filter.key,
        value: filter.value,
        excluded: filter.excluded,
      })),
      timezone,
      currency: baseCurrency,
    }),
    [baseCurrency, list],
  );

  const query = useQuery(brainpowerQuery, {
    variables: {
      service: widgetType.endpoint,
      parameters: params,
    },
  });

  const data = query.data?.brainpower?.data ?? [];

  // Fake data for test
  // const data = [
  //   {
  //     payin_partner: 'checkout',
  //     acquiring_fee: {
  //       capture: { volume: 539, fee_types: { psp_fee: { volume: 539, fee_amount: -187.87000000000003 } } },
  //       authorization: {
  //         volume: 1227,
  //         fee_types: {
  //           card_scheme_fee: { volume: 1227, fee_amount: -147.87300000000002 },
  //           psp_fee: { volume: 45, fee_amount: -85 },
  //         },
  //       },
  //       refund: { volume: 6, fee_types: { psp_fee: { volume: 6, fee_amount: -3.606 } } },
  //       cancel: { volume: 29, fee_types: { psp_fee: { volume: 29, fee_amount: -3.189999999999999 } } },
  //     },
  //     administrative_acquirer_fee: {
  //       chargeback: { volume: 34, fee_types: { chargeback_fee: { volume: 34, fee_amount: -17.183999999999997 } } },
  //     },
  //   },
  //   {
  //     payin_partner: 'garantipay',
  //     acquiring_fee: { refund: { volume: 1, fee_types: { psp_fee: { volume: 1, fee_amount: -41.54 } } } },
  //   },
  //   {
  //     payin_partner: 'iyzico',
  //     acquiring_fee: { refund: { volume: 1, fee_types: { psp_fee: { volume: 1, fee_amount: -41.54 } } } },
  //   },
  // ];

  const dataSource = useMemo(
    () =>
      feeCategoryEnumList.map((feeCategory) => ({
        fee_category: feeCategory,
        ...data.reduce(
          (memo, row) => {
            // Sum each combination operationType x feeType
            const rowCount = operationTypeEnumList
              .flatMap((operationType) => feeTypeEnumList.map((feeType) => ({ operationType, feeType })))
              .reduce(
                (count, { operationType, feeType }) =>
                  count + (row[feeCategory]?.[operationType]?.fee_types?.[feeType]?.[Unit.FEE_AMOUNT] ?? 0),
                0,
              );
            return {
              ...memo,
              total: memo.total + rowCount,
              [row.payin_partner]: rowCount,
            };
          },
          { total: 0 },
        ),
      })),
    [data],
  );

  // height * unit + (height-1) * margin - tile padding - tile header - table header - summary
  const tableHeight = layout.h * 36 + (layout.h - 1) * 24 - 2 * 8 - 32 - 39 - 39;

  return (
    <div className={styles.root}>
      <Row justify="space-between" align="middle">
        <div className={styles.title}>{widget.name}</div>
        <Space size={2}>
          <DataExportMenu data={Object.values(buildFullDataSource(data)).flat()} widget={widget} />
          <Button type="text" icon={<FullscreenOutlined />} onClick={() => setOpen(true)} />
        </Space>
      </Row>
      <Table
        columns={[
          {
            title: '',
            dataIndex: 'fee_category',
            render: (value) => feeCategories[value]?.label ?? value,
            fixed: 'left',
          },
          {
            title: 'TOTAL',
            dataIndex: 'total',
            render: (value) => amountFormatter(value, baseCurrency),
            align: 'right',
            width: 140,
          },
          ...buildPartnerColumns(data, partnersQuery?.data?.partners, baseCurrency),
        ]}
        loading={query.loading}
        scroll={{ x: 'max-content', y: tableHeight }}
        size="small"
        pagination={false}
        bordered
        dataSource={dataSource}
        summary={(pageData) => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>TOTAL</Table.Summary.Cell>
              {pageData
                .reduce(
                  (memo, row) => memo.map((v, i) => v + (i === 0 ? row.total : row[data[i - 1].payin_partner])),
                  Array(data.length + 1).fill(0),
                )
                .map((total, length) => (
                  <Table.Summary.Cell index={length + 1} align="right">
                    {amountFormatter(total, baseCurrency)}
                  </Table.Summary.Cell>
                ))}
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
      <FullscreenModal open={open} onCancel={() => setOpen(false)} data={data} />
    </div>
  );
};

export default FinancialAnalysis;

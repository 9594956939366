import React from 'react';
import {Form, Input, InputNumber, Switch, Tag} from 'antd';

const FormItemNumber = ({ field, ...formItemProps }) => {
  const { value, onChange } = formItemProps;
  return (
    <Input.Group compact {...formItemProps}>
      <Form.Item name={[field.key, 'value', 'min']}>
        <InputNumber disabled={value === '__NULL__'} autoFocus placeholder="Min" />
      </Form.Item>
      <Form.Item name={[field.key, 'value', 'max']}>
        <InputNumber disabled={value === '__NULL__'} placeholder="Max" />
      </Form.Item>
      <Form.Item  name={[field.key, 'value']}>
        <Switch
          checkedChildren='Null'
          unCheckedChildren='Null'
          checked={value === '__NULL__'}
          onChange={(val) => val ? onChange('__NULL__') : onChange(null)}
        />
      </Form.Item>
    </Input.Group>
  );
}

export default FormItemNumber;

import React from 'react';
import { capitalize } from '../../../../../util/string';
import ErrorBoundary from '../../../Common/ErrorBoundary/ErrorBoundary';
import { InfoTileFallback } from '../../OrderDetail/Tile/Info/Info';
import NotificationStatus from './NotificationStatus/NotificationStatus';
import Webhook from './Webhook/Webhook';
import Calls from './Calls/Calls';
import RequestBody from './RequestBody/RequestBody';
import {useCan} from '../../../../../contexts/ability.context';

const TileBuilder = ({ name }) => {
  const can = useCan();
  const component = {
    'notification-status': <NotificationStatus />,
    ...(can('read', 'webhook') ? {webhook: <Webhook />} : {}),
    'request-body': <RequestBody />,
    'call-list': <Calls />,
  }[name];

  return (
    <ErrorBoundary fallback={<InfoTileFallback name={name} title={capitalize(name)} />}>{component}</ErrorBoundary>
  );
};

export default TileBuilder;

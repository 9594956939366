import { gql } from '@apollo/client';

export const UserDetailQuery = gql`
  query UserDetailQuery($id: ID!) {
    merchantUser(id: $id) {
      id
      deleted
      full_name
      first_name
      last_name
      email
      phone {
        code
        number
      }
      avatar
      status
      disabled
      disabledAt
      disabledBy {
        id
        email
        full_name
        avatar
      }
      activated
      companies {
        __typename
        id
        company {
          id
          theme {
            logo
            icon
            primaryColor
            secondaryColor
          }
          merchantAccountCount
          merchantAccounts {
            id
            name
            status
            userCount
          }
        }
        aclProfile {
          id
          name
          roles {
            privileges {
              action
              subject
            }
          }
        }
        isGdpr
        authorizedMerchantAccounts {
          id
          name
          status
          userCount
        }
      }
      lastConnection
    }
  }
`;

export const UserUpdateMutation = gql`
  mutation UserUpdateMutation($id: ID!, $input: UserUpdateInput!) {
    updateMerchantUser(id: $id, input: $input) {
      id
      first_name
      last_name
      full_name
      phone {
        code
        number
      }
      avatar
    }
  }
`;

export const UserAvatarUpload = gql`
  mutation UserAvatarUpload($id: ID!, $file: Upload) {
    uploadUserAvatar(id: $id, file: $file) {
      id
      avatar
    }
  }
`;

export const UserDisabledUpdateMutation = gql`
  mutation UserDisabledUpdateMutation($id: ID!, $disabled: Boolean!) {
    updateUserDisabled(id: $id, disabled: $disabled) {
      __typename
      id
      disabled
      disabledBy {
        full_name
        avatar
      }
      disabledAt
    }
  }
`;

export const UserDeleteMutation = gql`
  mutation UserDeleteMutation($id: ID!) {
    deleteUser(id: $id)
  }
`;

export default {};

import { useEffect, useRef } from 'react';

// reset form fields when modal is form, closed
export const useResetFormOnCloseModal = ({ form, visible }) => {
  const prevVisibleRef = useRef();
  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;

  useEffect(() => {
    if (!visible && prevVisible) {
      form.resetFields();
    }
  }, [visible]);
};

export const validateTrimmedValue = (rule, value) =>
  value === value.trim() ? Promise.resolve() : Promise.reject(new Error('Invalid trailing whitespace'));

export default {};

import React from 'react';
import { Block } from '../../../../Common/Sider';
import ItemLoading from '../../../../Common/SiderList/ItemLoading';
import { useDashboard } from '../../dashboard.context';
import WidgetItem from './WidgetItem';

const WidgetList = () => {
  const { dashboard } = useDashboard();
  return (
    <Block key="widget-list" title="Dashboard widgets" description="Add, edit or delete widgets">
      {dashboard.layout.length ? (
        [...dashboard.layout]
          // Sort widgets by their order on the dashboard
          .sort((w1, w2) => (w1.y === w2.y ? w1.x - w2.x : w1.y - w2.y))
          .map((w) => (w.i === 'temp' ? <ItemLoading /> : <WidgetItem key={w.i} widgetId={w.i} />))
      ) : (
        <div>No chart on the dashboard !</div>
      )}
    </Block>
  );
};

export default WidgetList;

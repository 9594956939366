import { gql } from '@apollo/client';
import { FragmentPartnerRouteOptions } from '../constants';

export const PartnerRouteListQuery = gql`
  query PartnerRouteListQuery($filters: Object, $limit: Int, $offset: Int) {
    partnerRoutes(filters: $filters, limit: $limit, offset: $offset) {
      data {
        id
        status
        partner {
          id
          name
          company {
            theme {
              icon
            }
          }
          ${FragmentPartnerRouteOptions}
        }
        paymentMethodName
        channel
        authorization_currency
        settlement_currency
        ${FragmentPartnerRouteOptions}
        request_expiration_delay
        merchantRoutes {
          id
          merchant_contract {
            id
            name
          }
          merchant_account {
            id
            name
            company {
              id
              name
              theme {
                icon
              }
            }
            programManager {
              id
              name
              theme {
                icon
                primaryColor
                secondaryColor
              }
            }
          }
        }
      }
      count
    }
  }
`;

export default {};

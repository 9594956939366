import { gql } from '@apollo/client';

export const addRuleMutation = gql`
  mutation AddRule($id: ID!, $input: RuleInput!) {
    addRule(id: $id, input: $input) {
      id
      rules(filters: { status: "active" }) {
        id
        name
        order
        status
      }
    }
  }
`;

import {gql} from '@apollo/client';

export const merchantAccountProceduresQuery = gql`
  query MerchantAccount($id: ID!) {
    merchantAccount(id: $id) {
      id
      scheduled_capture {
        status
        delay
      }
    }
  }
`;

export const updateMerchantAccountProceduresMutation = gql`
  mutation UpdateMerchantAccountProcedures($id: ID!, $input: ProceduresInput!) {
    updateMerchantAccountProcedures(id: $id, input: $input) {
      id
      scheduled_capture {
        status
        delay
      }
    }
  }
`;
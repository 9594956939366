export default {
  hosted_fields: {
    value: 'hosted_fields',
    label: 'Hosted fields',
  },
  hosted_page: {
    value: 'hosted_page',
    label: 'Hosted page',
  },
  api: {
    value: 'api',
    label: 'API',
  },
  mobile_sdk: {
    value: 'mobile_sdk',
    label: 'Mobile SDK',
  },
  qrcode: {
    value: 'qrcode',
    label: 'QR code',
  },
  mail_to_pay: {
    value: 'mail_to_pay',
    label: 'Mail to pay',
  },
};
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import {useLazyQuery} from '@apollo/client';
import InfoTile, { InfoCountryRow, InfoTextRow } from '../../../Tile/Info/Info';
import { orderShippingDetailsQuery, activityShippingDetailsQuery } from './query';
import ShippingDetailsSkeleton from './ShippingDetailsSkeleton';

const ShippingDetails = () => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(activityShippingDetailsQuery, {
    variables: {
      orderId,
    }
  });

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(orderShippingDetailsQuery, {
    variables: {
      orderId,
    },
    onCompleted: (data) => !data.order && fetchInsights(),
  });

  const order = orderStack ?? orderInsights;

  if (!order) return <ShippingDetailsSkeleton />;

  let streetName;
  if (order.shipping_address_street_name_line1) {
    streetName = order.shipping_address_street_name_line1;
    if (order.shipping_address_street_name_line2) {
      streetName += `, ${order.shipping_address_street_name_line2}`;
    }
  }

  return (
    <InfoTile name="shipping-details" title="Shipping details">
      <InfoTextRow label="Delivery method" value={order.shipping_delivery_method} />
      <InfoTextRow label="Street" value={streetName} />
      <InfoTextRow label="ZIP code" value={order.shipping_address_zip_code} />
      <InfoTextRow label="City" value={order.shipping_address_city} />
      <InfoTextRow label="State" value={order.shipping_address_state} />
      <InfoCountryRow label="Country" value={order.shipping_address_country} />
      <InfoTextRow label="Email address" value={order.shipping_email} />
      <InfoTextRow label="Shipping ID" value={order.shipping_id} />
      <InfoTextRow label="Carrier" value={order.shipping_carrier_name} />
      <InfoTextRow label="Shipping price" value={order.shipping_price} />
    </InfoTile>
  );
};

export default ShippingDetails;

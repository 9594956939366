import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorBoundary from '../../Common/ErrorBoundary/ErrorBoundary';
import Page from '../../Common/Page/Page';
import UserList from './UserList/UserList';
import UserView from './UserView/UserView';

const MetaProgramManagerUsers = () => (
  <ErrorBoundary>
    <Routes>
      <Route index element={<UserList />} />
      <Route
        path=":userId"
        element={
          <Page>
            <UserView />
          </Page>
        }
      />
    </Routes>
  </ErrorBoundary>
);

export default MetaProgramManagerUsers;

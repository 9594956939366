import moment from 'moment-timezone';
import { compareByProp, intersection } from '../../../util/array';
import FieldTypes from '../Brainpower/Common/constants/fieldTypes';
import { getValueByPath } from '../../../util/object';
import { search } from '../../../util/string';
import { buildColumnProps } from '../Brainpower/Common/AdvancedTable/buildColumns';
import { TableDisplay } from '../Brainpower/Common/constants/tableDisplays';
import { periodTypeToCustom } from '../../../util/date';
import { DATETIME2_FORMAT } from '../../../constants/DATES';

/**
 * Map function
 * build row object from fields paths
 */
const mapFnBuilder = (fields) => (row) =>
  Object.values(fields).reduce((memo, { key, path }) => ({ ...memo, [key]: getValueByPath(row, path) }), {});

/**
 * Filter function
 * filter rows based on search filters list
 * @param list
 * @param fields
 * @returns {(function(*): (boolean|*))|*}
 */
const filterFnBuilder = (list, fields) => (row) => {
  if (list.length < 1) return true;
  return !list.some(({ key, value, excluded }) => {
    switch (fields[key].type) {
      // DATE - from/to
      case FieldTypes.DATE:
        if (!value) return excluded;
        if (row[key] == null) return value === '__NULL__' ? excluded : !excluded;
        // eslint-disable-next-line no-case-declarations
        const { from, to } = value.period ? periodTypeToCustom(value.period) : value;
        return moment(row[key]).isBetween(moment(from, DATETIME2_FORMAT), moment(to, DATETIME2_FORMAT), 'hour')
          ? excluded
          : !excluded;
      // BETWEEN - min/max
      case FieldTypes.COUNT:
      case FieldTypes.AMOUNT:
      case FieldTypes.NUMBER:
        return !(value.min != null && row[key] < value.min) &&
          !(value.max != null && row[key] > value.max) &&
          !(value === '__NULL__' && row[key] != null)
          ? excluded
          : !excluded;
      // SEARCH LIKE
      case FieldTypes.TEXT:
        return !value ||
          value.length === 0 ||
          value.some((v) => search(v, row[key])) ||
          (value.includes('__NULL__') && row[key] == null)
          ? excluded
          : !excluded;
      // SEARCH EXACT
      case FieldTypes.ASYNC_LIST:
      case FieldTypes.BOOLEAN:
      case FieldTypes.CUSTOM:
      case FieldTypes.LIST:
        return !value ||
          value.length === 0 ||
          value.includes(row[key]) ||
          intersection(value, row[key]).length > 0 ||
          (value.includes('__NULL__') && row[key] == null)
          ? excluded
          : !excluded;
      default:
        return true;
    }
  });
};

/**
 * Quicksearch filter function
 * filter rows based on quicksearch value
 * @param quickSearch
 * @param fieldKeyList
 * @returns {function(*): *}
 */
const quickSearchFilterFnBuilder = (quickSearch, fieldKeyList) => (row) =>
  fieldKeyList.some((key) => search(quickSearch, row[key]));

/**
 * Sort function
 * if sorting on ID fields, replace the field by the name field
 * impacts for PROGRAM_MANAGER, COMPANY, MERCHANT_ACCOUNT
 * @param sort
 * @param fields
 * @returns {(a: any, b: any) => number}
 */
const sortFnBuilder = (sort, fields) => {
  const { key, type, sortField } = fields[sort[0].field];
  return compareByProp(
    sortField ?? key,
    sort[0].sortOrder === 'descend' ? 'desc' : 'asc',
    [FieldTypes.AMOUNT, FieldTypes.BOOLEAN, FieldTypes.COUNT, FieldTypes.DATE, FieldTypes.NUMBER].includes(type)
      ? ''
      : 'string',
  );
};

/**
 * Build AntD Table columns from columnSet and context
 * @param columnSet
 * @param fields
 * @param sort
 * @param can
 * @param isGdpr
 * @param tableDisplay
 * @returns {*}
 */
const buildColumns = (columnSet, fields, sort, can, isGdpr = false, tableDisplay = TableDisplay.RICH) =>
  columnSet
    .filter((c) => fields[c.field] && !fields[c.field]?.isForbidden?.(can))
    .map((c, index, { length }) => {
      const field = fields[c.field];
      const sortIndex = sort.findIndex((s) => s.field === c.field);
      return {
        key: c.field,
        dataIndex: c.field,
        title: c.label,
        format: c.format,
        ellipsis: true,
        width: Math.max(c.label.length) * 14 + 30, // TODO - fix width manually or let Table handle it ?
        sorter: { multiple: sortIndex === -1 ? length : sortIndex },
        sortOrder: sort[sortIndex]?.sortOrder,
        sortDirections: ['descend', 'ascend', 'descend'],
        showSorterTooltip: false,
        fixed: index === 0 ? 'left' : false,
        ...buildColumnProps(field, c, isGdpr, tableDisplay === TableDisplay.TEXT),
      };
    });

export { mapFnBuilder, filterFnBuilder, quickSearchFilterFnBuilder, sortFnBuilder, buildColumns };

import { gql } from '@apollo/client';

export const paymentMethodsQuery = gql`
  query PaymentMethods {
    paymentMethods {
      id
      name
      type
    }
  }
`;

export const testRouterMutation = gql`
  mutation TestRouter ($input: TestRouterInput!) {
      testRouter(input: $input)
  }
`;
import React, { useState } from 'react';
import { Button, Form, Input, message, Space } from 'antd';
import { EditOutlined, PhoneOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import InfoTile, { InfoIconRow, InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import styles from '../PartnerView.module.scss';
import { useCan } from '../../../../../../contexts/ability.context';
import { PartnerUpdateMutation } from '../query';

const MainContact = ({ partner }) => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);

  const [updatePartner, { loading }] = useMutation(PartnerUpdateMutation, {
    onCompleted: () => {
      message.success('Partner successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    updatePartner({
      variables: { id: partner.id, input: { mainContact: values } },
    });
  };

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="main-contact" title="Main contact">
        <Form
          form={form}
          title="Main contact"
          layout="vertical"
          initialValues={partner.mainContact}
          onFinish={handleFinish}
          requiredMark={false}
          className="tile-form"
        >
          <Form.Item label="Firstname" name="firstname">
            <Input />
          </Form.Item>
          <Form.Item label="Surname" name="surname">
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input />
          </Form.Item>
          <Form.Item label="Phone">
            <Space>
              <Form.Item name={['phone', 'code']} noStyle>
                <Input style={{ width: 65 }} placeholder="31" prefix="+" />
              </Form.Item>
              <Form.Item name={['phone', 'number']} noStyle>
                <Input placeholder="123456789" />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  // return info tile
  return (
    <InfoTile
      name="main-contact"
      title="Main contact"
      extra={[
        can('update', 'partner') && (
          <Button key="update-partner" onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        ),
      ]}
    >
      <InfoTextRow label="Firstname" value={partner.mainContact?.firstname} />
      <InfoTextRow label="Surname" value={partner.mainContact?.surname} />
      <InfoTextRow label="Email" value={partner.mainContact?.email} />
      <InfoIconRow
        label="Phone"
        icon={PhoneOutlined}
        value={
          (partner.mainContact?.phone?.code || partner.mainContact?.phone?.number) &&
          `${partner.mainContact?.phone?.code} ${partner.mainContact?.phone?.number}`
        }
      />
    </InfoTile>
  );
};

export default MainContact;

import { gql } from '@apollo/client';

export const metaProgramManagerContactsQuery = gql`
  query MetaProgramManagerContacts($id: ID!) {
    metaProgramManager(id: $id) {
      id
      name
      contacts {
        _id
        firstname
        surname
        email
        phone {
          code
          number
        }
        title
        type
        priorityLevel
      }
    }
  }
`;

export const removeMetaProgramManagerContactMutation = gql`
  mutation RemoveMetaProgramManagerContact($id: ID!, $contactId: ID!) {
    removeMetaProgramManagerContact(id: $id, contactId: $contactId) {
      id
      contacts {
        _id
        firstname
        surname
        email
        phone {
          code
          number
        }
        title
        type
        priorityLevel
      }
    }
  }
`;

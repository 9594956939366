import React, { useContext, useMemo } from 'react';
import { createContextualCan } from '@casl/react';
import { Ability } from '@casl/ability';
import { message } from 'antd';
import { useMe } from './me.context';
import { useLogout } from './app.context';
import { useMode, MODES } from './mode.context';

export const AbilityContext = React.createContext(undefined);

export const useAbilty = () => useContext(AbilityContext);
export const useCan = () => {
  const ability = useContext(AbilityContext);
  return (...args) => ability.can(...args);
};
export const Can = createContextualCan(AbilityContext.Consumer);

// interface RawRule {
//   action: string | string[]
//   subject?: string | string[]
//   /** an array of fields to which user has (or not) access */
//   fields?: string[]
//   /** an object of conditions which restricts the rule scope */
//   conditions?: any
//   /** indicates whether rule allows or forbids something */
//   inverted?: boolean
//   /** message which explains why rule is forbidden */
//   reason?: string
// }

const AbilityProvider = (props) => {
  const { children } = props;
  const me = useMe();
  const logout = useLogout();
  const mode = useMode();

  // merge privileges from roles and dedup by id
  const privileges = useMemo(() => {
    if (!me) return [];

    // mode admin
    if ([MODES.INTERNAL, MODES.META_PM, MODES.PM].includes(mode)) {
      if (!['InternalUser', 'ProgramManagerUser', 'MetaProgramManagerUser'].includes(me.type)) {
        message.error('Admin module is restricted to internal users.');
        logout();
        return [];
      }
    }
    // mode merchant (enable guest access)
    else if (me.type !== 'MerchantUser' && !(me.companies?.length > 0)) {
      message.error('Merchant module is restricted to merchant users.');
      logout();
      return [];
    }

    // mode merchant
    return Object.values(
      ([MODES.INTERNAL, MODES.META_PM, MODES.PM].includes(mode)
        ? me.aclProfile
        : me.companies?.[0]?.aclProfile
      ).roles.reduce(
        (memo, role) => ({
          ...memo,
          ...role.privileges.reduce(
            (memo2, privilege) => ({
              ...memo2,
              [privilege.id]: privilege,
            }),
            {},
          ),
        }),
        {},
      ) ?? {},
    );
  }, [me]);

  if (me.companies?.[0]?.isGdpr || (me.type !== 'MerchantUser' && me.isGdpr)) {
    privileges.push({ action: 'read', subject: 'gdpr' });
  }

  const value = new Ability(privileges);

  return <AbilityContext.Provider value={value}>{children}</AbilityContext.Provider>;
};

export default AbilityProvider;

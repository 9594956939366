import { gql } from '@apollo/client';

export const maintenanceQuery = gql`
  query MaintenanceQuery($orderId: ID!) {
    order(id: $orderId) {
      _id
      id
      currency
      last_transaction {
        _id
        id
        createdAt
        amount
        currency
        is_cancellable
        is_capturable
        capturable_amount
        is_refundable
        refundable_amount
        is_multiple_captures_available
        is_partial_capture_available
        is_multiple_refunds_available
        is_partial_refund_available
        scheduled_capture_date
        operations {
          id
          type
          request_date
          request_response
          request_status
          result_date
          result_response
          result_status
          amount
          currency
          linked_operation_id
        }
      }
      program_manager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
      company {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
    }
  }
`;
export const activityContextQuery = gql`
  query ActivityContextQuery($orderId: ID!) {
    activity(id: $orderId) {
      _id
      id
      program_manager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
      company {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const merchantContractQuery = gql`
  query MerchantContract($id: ID!) {
    merchantContract(id: $id) {
      id
      name
      source
      type
      status
      channels
      settlement_currency
      payment_methods {
        id
        imgUrl
        partner_routes {
          id
          channel
          authorization_currency
          partner {
            id
            name
          }
          payment_method {
            id
          }
        }
      }
      has_authorized_transactions
      merchant_routes(filters: { status: { in: ["active", "inactive"] } }) {
        id
        status
        partner_route {
          id
          authorization_currency
          partner {
            id
          }
          payment_method {
            id
          }
          settlement_currency
          channel
        }
      }
      risk_assessment_managed
      risk_assessment_contract {
        id
        partner {
          name
        }
      }
      authentication_managed
      authentication_contract {
        id
        partner {
          name
        }
      }
      payin_contracts {
        id
        partner {
          name
        }
      }
      configuration
      reconciliation_configuration
      partner {
        id
        api_name
        name
        settlement_currencies
        partner_data_configuration
        partner_reconciliation_configuration
        merchant_attribute_configuration
        risk_assessment_service
        partner_routes {
          paymentChannel {
            id
          }
          settlement_currency
        }
      }
      authorization_currencies
      is_demo
    }
  }
`;

export const updateContractMutation = gql`
  mutation UpdateContract($id: ID!, $input: MerchantContractUpdateInput!, $force: Boolean) {
    updateContract(id: $id, input: $input, force: $force) {
      id
      name
      source
      status
      channels
      settlement_currency
      payment_methods {
        id
        imgUrl
      }
      has_authorized_transactions
      merchant_routes(filters: { status: { in: ["active", "inactive"] } }) {
        id
        partner_route {
          id
          authorization_currency
          partner {
            id
          }
          payment_method {
            id
          }
          settlement_currency
          channel
        }
      }
      risk_assessment_managed
      risk_assessment_contract {
        id
        partner {
          name
        }
      }
      authentication_managed
      authentication_contract {
        id
        partner {
          name
        }
      }
      payin_contracts {
        id
        partner {
          name
        }
      }
      configuration
      reconciliation_configuration
      partner {
        id
        settlement_currencies
        partner_data_configuration
        partner_reconciliation_configuration
      }
    }
  }
`;

export const contractsByAccountQuery = gql`
  query contractsByAccount($merchantAccountId: ID!) {
    merchantAccount(id: $merchantAccountId) {
      id
      merchant_contracts(filters: { status: { in: ["active", "inactive"] } }) {
        id
        name
        source
        type
        partner {
          name
        }
      }
    }
  }
`;

export const partnerPaymentMethodsQuery = gql`
  query PartnerPaymentMethods($id: ID!, $partnerRouteFilters: PartnerRouteFilters) {
    partner(id: $id) {
      id
      payment_methods(filters: $partnerRouteFilters) {
        id
        imgUrl
      }
      name
      partner_data_configuration
    }
  }
`;

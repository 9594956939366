import React, { useEffect, useMemo, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Input, Modal, Popconfirm, Radio, Row, Space, Table, Tag, Typography } from 'antd';
import CloseIcon from '@2fd/ant-design-icons/lib/Close';
import BlockHelperIcon from '@2fd/ant-design-icons/lib/BlockHelper';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import { useMe } from '../../../../../contexts/me.context';
import SshAlgorithms, { sshAlgorithmList, sshAlgorithms } from '../../../Brainpower/ExportList/constants/sshAlgorithms';
import { useResetFormOnCloseModal } from '../utils';

const sftpSshPublicKeyListQuery = gql`
  query SftpSshPublicKeyListQuery {
    sftpSshPublicKeys {
      ecdsa
      ed25519
      rsa
    }
  }
`;

const addSftpConnectionMutation = gql`
  mutation AddSftpConnectionMutation($input: SftpConnectionInput!) {
    addSftpConnection(input: $input) {
      _id
      sftpConnections {
        _id
        host
        port
        dirname
        username
        password
        sshAlgorithm
      }
    }
  }
`;

const removeSftpConnectionMutation = gql`
  mutation RemoveSftpConnectionMutation($id: ID!) {
    removeSftpConnection(id: $id) {
      _id
      sftpConnections {
        _id
        host
        port
        dirname
        username
        password
        sshAlgorithm
      }
    }
  }
`;

const ModalFormSftpConfiguration = ({ open, onCancel, onSubmit }) => {
  const { data } = useQuery(sftpSshPublicKeyListQuery);

  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    open,
  });

  const handleSubmit = ({ host, port, dirname, username, password, sshAlgorithm }) => {
    onSubmit({
      host,
      port,
      dirname,
      username,
      password,
      sshAlgorithm,
    });
  };

  const [method, setMethod] = useState('password');
  const formSshAlgorithm = Form.useWatch('sshAlgorithm', form);

  return (
    <Modal
      title="Add SFTP connection"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      okText="ADD"
      cancelText="CANCEL"
      cancelButtonProps={{ type: 'text' }}
      closeIcon={<WindowCloseIcon />}
      bodyStyle={{
        maxHeight: 'calc(100vh - 188px)',
        overflowY: 'auto',
      }}
      centered
    >
      <Form form={form} layout="vertical" requiredMark="optional" onFinish={handleSubmit}>
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item name="host" label="Host name" required>
              <Input placeholder="0.0.0.0" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="port" label="Port number" initialValue="22" required>
              <Input placeholder="22" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="dirname" label="Directory">
          <Input placeholder="upload" prefix="/" />
        </Form.Item>
        <Form.Item name="username" label="User name" required>
          <Input />
        </Form.Item>
        <Form.Item label="Authentication" required>
          <Radio.Group onChange={(e) => setMethod(e.target.value)} value={method}>
            <Space direction="vertical">
              <Radio value="password" style={{ lineHeight: '32px' }}>
                <Space>
                  Password
                  {method === 'password' ? (
                    <Form.Item
                      name="password"
                      noStyle
                      required
                      rules={[
                        {
                          required: true,
                          message: `Authentication is required.`,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  ) : null}
                </Space>
              </Radio>
              <Radio value="ssh-algorithm" style={{ lineHeight: '32px' }}>
                <Space>
                  SSH Algorithm
                  {method === 'ssh-algorithm' ? (
                    <Form.Item name="sshAlgorithm" noStyle initialValue={SshAlgorithms.ECDSA}>
                      <Radio.Group optionType="button" buttonStyle="solid" options={sshAlgorithmList} />
                    </Form.Item>
                  ) : null}
                </Space>
              </Radio>
            </Space>
          </Radio.Group>
          {method === 'ssh-algorithm' && data?.sftpSshPublicKeys && (
            <Typography.Paragraph>
              <pre>
                <Typography.Text copyable>{data.sftpSshPublicKeys[formSshAlgorithm]}</Typography.Text>
              </pre>
            </Typography.Paragraph>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

/**
 * @param value
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
const SftpConnectionInput = ({ value, onChange }) => {
  const me = useMe();
  const initialValue = useMemo(() => value, []);

  // Add initial value to list if previously removed
  const sftpConnections = useMemo(
    () => [
      ...(initialValue && !me.sftpConnections.find((c) => c._id === initialValue._id)
        ? [{ ...initialValue, disabled: true }]
        : []),
      ...me.sftpConnections,
    ],
    [me, initialValue],
  );

  const [addSftpConnection] = useMutation(addSftpConnectionMutation);
  const [removeSftpConnection] = useMutation(removeSftpConnectionMutation);

  const [openSftpModal, setOpenSftpModal] = useState(false);

  const handleSubmitSftpModal = async (sftpConfiguration) => {
    // add connection
    await addSftpConnection({ variables: { input: sftpConfiguration } }).then(({ data }) => {
      const newSftpConnection = data.addSftpConnection.sftpConnections.slice(-1)?.[0];
      onChange({
        _id: newSftpConnection._id,
        host: newSftpConnection.host,
        port: newSftpConnection.port,
        dirname: newSftpConnection.dirname,
        username: newSftpConnection.username,
        password: newSftpConnection.password,
        sshAlgorithm: newSftpConnection.sshAlgorithm,
      });
      setOpenSftpModal(false);
    });
  };

  const handleCancelSftpModal = () => {
    setOpenSftpModal(false);
  };
  const handleDeleteSftpConnection = (id) => removeSftpConnection({ variables: { id } });

  useEffect(() => {
    if (!value && sftpConnections.length > 0)
      onChange({
        _id: sftpConnections[0]._id,
        host: sftpConnections[0].host,
        port: sftpConnections[0].port,
        dirname: sftpConnections[0].dirname,
        username: sftpConnections[0].username,
        password: sftpConnections[0].password,
        sshAlgorithm: sftpConnections[0].sshAlgorithm,
      });
  }, []);

  return (
    <>
      {sftpConnections.length > 0 && (
        <Table
          dataSource={sftpConnections}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: value ? [value._id] : [],
            onSelect: (record) =>
              onChange({
                _id: record._id,
                host: record.host,
                port: record.port,
                dirname: record.dirname,
                username: record.username,
                password: record.password,
                sshAlgorithm: record.sshAlgorithm,
              }),
          }}
          rowKey="_id"
          scroll={{ x: 'auto' }}
          columns={[
            { key: 'username', dataIndex: 'username', title: 'User', fixed: 'left' },
            { key: '_@', render: () => `@` },
            { key: 'host', dataIndex: 'host', render: (host) => `${host}` },
            { key: '_:', render: () => `:` },
            { key: 'port', dataIndex: 'port', render: (port) => `${port}` },
            { key: '_/', render: () => `/` },
            { key: 'dirname', dataIndex: 'dirname', render: (dirname) => `${dirname}` },
            {
              key: 'authentication',
              title: 'Auth',
              dataIndex: 'sshAlgorithm',
              fixed: 'right',
              render: (sshAlgorithm) => (
                <Tag style={{ margin: 0 }}>{sshAlgorithm ? sshAlgorithms[sshAlgorithm]?.label : 'PWD'}</Tag>
              ),
            },
            {
              key: 'delete',
              dataIndex: '_id',
              fixed: 'right',
              render: (id, { disabled }) =>
                id !== value?._id && (
                  <Popconfirm
                    title="Are you sure to delete this connection?"
                    onConfirm={() => handleDeleteSftpConnection(id)}
                    okText="DELETE"
                    cancelText="CANCEL"
                    cancelButtonProps={{ type: 'text' }}
                  >
                    <Button
                      size="small"
                      shape="circle"
                      type="text"
                      title={disabled ? 'Cannot delete used connection' : 'Delete'}
                      disabled={disabled}
                      icon={disabled ? <BlockHelperIcon /> : <CloseIcon />}
                    />
                  </Popconfirm>
                ),
            },
          ]}
          size="small"
          showHeader={false}
          pagination={false}
          bordered={false}
          gutter={12}
        />
      )}
      <Button block type="dashed" onClick={() => setOpenSftpModal(true)}>
        Add SFTP connection
      </Button>
      <ModalFormSftpConfiguration
        open={openSftpModal}
        onSubmit={handleSubmitSftpModal}
        onCancel={handleCancelSftpModal}
      />
    </>
  );
};

export default SftpConnectionInput;

import React, { useState } from 'react';
import { Button, Form, Input, message, Select, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import InfoTile, { InfoCountryRow, InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import styles from '../PartnerView.module.scss';
import { COUNTRY_LIST } from '../../../../../../constants/COUNTRIES';
import { useCan } from '../../../../../../contexts/ability.context';
import { CompanyUpdateMutation } from '../../../Company/query';

const CompanyAddress = ({ company }) => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);

  const [updateCompany, { loading }] = useMutation(CompanyUpdateMutation, {
    onCompleted: () => {
      message.success('Company successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    updateCompany({
      variables: { id: company.id, input: values },
    });
  };

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="company-address" title="Company Address">
        <Form
          form={form}
          title="Address"
          layout="vertical"
          initialValues={company}
          onFinish={handleFinish}
          requiredMark={false}
          className="tile-form"
        >
          <Form.Item label="Country" name="country">
            <Select
              showSearch
              placeholder="Select a country"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {COUNTRY_LIST.map((c) => (
                <Select.Option key={c.value} value={c.value}>
                  <Space>
                    <img src={c.img} alt={c.value} className={styles.imgOption} />
                    {c.label}
                  </Space>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Street" name="address">
            <Input />
          </Form.Item>
          <Form.Item label="City" name="city">
            <Input />
          </Form.Item>
          <Form.Item label="ZIP code" name="zipCode">
            <Input />
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  // return info tile
  return (
    <InfoTile
      name="company-address"
      title="Company Address"
      extra={[
        can('update', 'company') && (
          <Button key="update-company" onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        ),
      ]}
    >
      <InfoCountryRow label="Country" value={company.country} />
      <InfoTextRow label="Street" value={company.address} />
      <InfoTextRow label="City" value={company.city} />
      <InfoTextRow label="ZIP code" value={company.zipCode} />
    </InfoTile>
  );
};

export default CompanyAddress;

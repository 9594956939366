export const capitalize = (str: string): string => {
  if (str) {
    return str[0].toUpperCase() + str.slice(1)?.toLowerCase();
  }
  return str;
};

export const snakeToCapitalize = (str: string): string => {
  if (str) {
    return str.split('_').map(capitalize).join(' ');
  }
  return str;
};

export const initials = (...args: [string]): string =>
  args
    .join(' ')
    .split(' ')
    .map((str) => str[0]?.toUpperCase())
    .join('');

export const middleEllipsis = (str: string, size = 10, suffixLength = 4): string => {
  if (str.length > size) {
    return `${str.substr(0, size - suffixLength - 3)}...${str.substr(str.length - suffixLength, str.length)}`;
  }
  return str;
};

export const isEmpty = (str: string): boolean => str === null || str === undefined || str.length === 0;

export const search = (needle: string, haystack: string): boolean =>
  !needle || haystack?.toLowerCase().includes(needle?.toLowerCase());

export const searchByProp = (propName: any) => (needle: string, haystack: string) => search(needle, haystack[propName]);

export const isValidDomain = (email: string, domains: [string]) =>
  RegExp(`@(${domains.map((d) => d.replace('.', '\\.')).join('|')})$`).test(email);

export default {};

import { useEffect, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import useSearchFilters from '../../hooks/useSearchFilters';
import usePagination from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';

export const financialOperationListQuery = gql`
  query FinancialOperations($offset: Int, $limit: Int, $filters: [SearchFilter], $sort: [SearchSort]) {
    financialOperations(offset: $offset, limit: $limit, filters: $filters, sort: $sort)
  }
`;
export const financialOperationsCountQuery = gql`
    query FinancialOperationsCount($filters: [SearchFilter]) {
        financialOperationsCount(filters: $filters)
    }
`;

const useFinancialOperationListQuery = () => {
  const { offset, limit } = usePagination();
  const { list } = useSearchFilters();
  const [sort] = useSort();

  const [fetch, { data, previousData, loading, error }] = useLazyQuery(financialOperationListQuery, {
    variables: {
      offset,
      limit,
      filters: list,
      sort,
    },
  });

  const [fetchCount, { data: count, previousData: previousCount, loading: loadingCount, error: errorCount }] = useLazyQuery(
    financialOperationsCountQuery,
    {
      variables: {
        filters: list,
      },
    },
  );

  const result = useMemo(
    () => (loading ? previousData?.financialOperations : data?.financialOperations) ?? [],
    [data, loading, previousData],
  );

  const total = useMemo(
    () => (loadingCount ? previousCount?.financialOperationsCount : count?.financialOperationsCount) ?? 0,
    [count, loadingCount, previousCount]
  )

  useEffect(() => {
    fetch();
    fetchCount();
  }, []);

  return { result, total, loading, error, loadingCount };
};

export default useFinancialOperationListQuery;

import React from 'react';
import { Button, Space } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import InfoTile, { BaseRow, InfoCountryRow, InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import styles from './CompanyInformation.module.scss';
import ConnectAsGuest from '../../../Company/CompanyView/ConnectAsGuest/ConnectAsGuest';
import { Link } from '../../../../../../util/navigate';
import { useCan } from '../../../../../../contexts/ability.context';

const CompanyInformation = ({ merchantAccount }) => {
  const can = useCan();
  return (
    <InfoTile
      name="ma-company"
      title="Company"
      extra={[
        can('create', 'guest_user') && <ConnectAsGuest key="connect-as-guest" company={merchantAccount?.company} />,
        <Link key="link-company" to={`/companies/${merchantAccount.company?.id}`}>
          <Button shape="text" icon={<ArrowRightOutlined />} />
        </Link>,
      ]}
    >
      <InfoTextRow label="Company name" value={merchantAccount.company?.name} />
      <InfoTextRow label="Company ID" value={merchantAccount.company?.id} />
      <InfoCountryRow label="Country" value={merchantAccount.company?.country} />
      <BaseRow label="Theme">
        <Space>
          {merchantAccount?.company?.theme?.icon && (
            <img src={merchantAccount?.company?.theme?.icon} alt="Company logo" className={styles.companyLogo} />
          )}
          {merchantAccount?.company?.theme?.logo && (
            <img src={merchantAccount?.company?.theme?.logo} alt="Company logo" className={styles.companyLogo} />
          )}
          <Space direction="vertical">
            <div
              className={styles.colorBlock}
              style={{
                backgroundColor: merchantAccount?.company?.theme?.primaryColor,
              }}
            />
            <div
              className={styles.colorBlock}
              style={{
                backgroundColor: merchantAccount?.company?.theme?.secondaryColor,
              }}
            />
          </Space>
        </Space>
      </BaseRow>
    </InfoTile>
  );
};

export default CompanyInformation;

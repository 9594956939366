import React, { useState } from 'react';
import styles from './MessagesContainer.module.scss';
import { AnnouncementMessage, DashboardMessage, ReportMessage } from './Message/Message';
import { compareByProp } from '../../../util/array';

const MessagesContainer = ({ type, messages, ackMessages }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className={styles.root} key={type}>
      {messages.sort(compareByProp('createdAt', 'desc')).map((message, index) => {
        if (!isExpanded && index >= 3) {
          return <></>;
        }
        const commonProps = {
          key: message.id,
          ackMessages,
          message,
          index,
          expanded: isExpanded,
          count: messages.length,
          handleClick: () => {
            setIsExpanded(!isExpanded);
          },
          handleClose: () => {
            ackMessages({
              variables: {
                messageIds: isExpanded ? [message.id] : messages.map((m) => m.id),
              },
            });
          },
        };
        switch (type) {
          case 'report_completed':
            return <ReportMessage {...commonProps} />;
          case 'dashboard_shared':
            return <DashboardMessage {...commonProps} />;
          case 'announcement':
            return <AnnouncementMessage {...commonProps} />;
          default:
            console.log(`${type} message type is not handled`);
            return <></>;
        }
      })}
    </div>
  );
};

export default MessagesContainer;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorBoundary from '../../Common/ErrorBoundary/ErrorBoundary';
import PartnerList from './PartnerList/PartnerList';
import Page from '../../Common/Page/Page';
import PartnerView from './PartnerView/PartnerView';

const Partners = () => (
  <Page>
    <ErrorBoundary>
      <Routes>
        <Route index element={<PartnerList />} />
        <Route path=":partnerId" element={<PartnerView />} />
      </Routes>
    </ErrorBoundary>
  </Page>
);

export default Partners;

import React, { useState } from 'react';
import { Button, Form, Input, InputNumber, message, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import InfoTile, { InfoAmountRow, InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import styles from './MetaProgramManagerCompanyMisc.module.scss';
import { useCan } from '../../../../../../contexts/ability.context';
import { metaProgramManagerCompanyMiscQuery } from './query';
import {updateMetaProgramManagerCompanyMutation} from '../query';

const MetaProgramManagerCompanyMisc = ({ id }) => {
  const can = useCan();

  const [editMode, setEditMode] = useState(false);

  const { data: { metaProgramManager: { company } } = { metaProgramManager: { company: null } } } = useQuery(
    metaProgramManagerCompanyMiscQuery,
    {
      variables: {
        id,
      },
    },
  );

  const [updateCompany, { loading }] = useMutation(updateMetaProgramManagerCompanyMutation, {
    onCompleted: () => {
      message.success('Company successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    updateCompany({
      variables: { id, input: values },
    });
  };

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="program-manager-company-misc" title="Financial">
        <Form
          form={form}
          title="Financial"
          layout="vertical"
          initialValues={company}
          onFinish={handleFinish}
          requiredMark={false}
          className="tile-form"
        >
          <Form.Item
            label="VAT number"
            name="vatNumber"
            // eslint-disable-next-line max-len
            tooltip="A value added tax identification number or VAT identification number (VATIN) is an identifier used in many countries, including the countries of the European Union, for value added tax purposes."
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="APE"
            name="ape"
            // eslint-disable-next-line max-len
            tooltip="In France, the APE code (for 'Activité Principale Exercée') is a five-character code (four digits and one letter) assigned by Insee to any company and to each of its establishments when it is registered in the SIRENE directory. This code characterizes its main activity by reference to the French classification of activities (NAF) in its 2008 version, so the APE code is also called the NAF code. More precisely, a distinction is made between the APEN code for the company and the APET code for the establishments."
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="DUNS"
            name="duns"
            // eslint-disable-next-line max-len
            tooltip="The Data Universal Numbering System, abbreviated as DUNS or D-U-N-S, is a proprietary system developed and managed by Dun & Bradstreet (D&B) that assigns a unique numeric identifier, referred to as a 'DUNS number' to a single business entity. It was introduced in 1963 to support D&B's credit reporting practice. It is standard worldwide. DUNS users include the European Commission, the United Nations, the United States government, and Apple. More than 50 global industry and trade associations recognize, recommend, or require DUNS. The DUNS database contains over 300 million entries for businesses throughout the world."
          >
            <Input />
          </Form.Item>
          <Form.Item label="Capital" name="capital">
            <InputNumber style={{ width: '50%', minWidth: '200px' }} min={0} formatter={(value) => `€ ${value}`} />
          </Form.Item>
          <Form.Item label="Number of employee" name="numberEmployee">
            <InputNumber />
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  // return info tile
  return (
    <InfoTile
      name="program-manager-company-misc"
      title="Financial"
      extra={
        can('update', 'program-manager') && (
          <Button onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        )
      }
    >
      <InfoTextRow label="VAT number" value={company?.vatNumber} />
      <InfoTextRow label="APE" value={company?.ape} />
      <InfoTextRow label="DUNS" value={company?.duns} />
      <InfoAmountRow label="Capital" value={company?.capital} currency="EUR" />
      <InfoTextRow label="Number of employee" value={company?.numberEmployee} />
    </InfoTile>
  );
};

export default MetaProgramManagerCompanyMisc;

import { gql } from '@apollo/client';

export const metaProgramManagerCompanyExecutiveQuery = gql`
  query MetaProgramManagerCompanyExecutive($id: ID!) {
    metaProgramManager(id: $id) {
      id
      company {
        directors
        ceo
        executive
      }
    }
  }
`;

import React, { useState } from 'react';
import { Button, Empty, Form, Input, message, Row, Space } from 'antd';
import { EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useCan } from '../../../../../../contexts/ability.context';
import InfoTile, { InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import styles from '../PartnerView.module.scss';
import { PartnerUpdateMutation } from '../query';
import { validateTrimmedValue } from '../../../../Common/Form/utils';

const PartnerReconciliationData = ({ partner }) => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);

  const [updatePartner, { loading }] = useMutation(PartnerUpdateMutation, {
    onCompleted: () => {
      message.success('Partner successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = ({ fields }) => {
    updatePartner({
      variables: {
        id: partner.id,
        input: {
          partner_reconciliation_data: fields.reduce((memo, { key, value }) => ({ ...memo, [key]: value }), {}),
        },
      },
    });
  };

  const configurationFields = Object.keys(partner.partner_reconciliation_data || {}).reduce(
    (memo, key) => [...memo, { key, value: partner.partner_reconciliation_data[key] }],
    [],
  );

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="partner-reconciliation_data" title="Reconciliation data">
        <Form
          form={form}
          title="Reconciliation data"
          layout="horizontal"
          initialValues={partner.partner_reconciliation_data}
          onFinish={handleFinish}
          requiredMark={false}
          colon={false}
          className="tile-form"
        >
          <Form.List name="fields" initialValue={configurationFields}>
            {(fields, { add, remove }) => [
              ...fields.map(({ key, name, ...restField }) => (
                <Row key={key}>
                  <Space align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'key']}
                      rules={[
                        { required: true, message: 'Missing key' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value) {
                              if (getFieldValue('fields').filter((f) => f.key === value).length > 1) {
                                return Promise.reject(new Error('Duplicate key'));
                              }
                              if (!/^[\w-]+$/.test(value)) {
                                return Promise.reject(new Error('Invalid format'));
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input placeholder="Key" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'value']}
                      rules={[{ required: true, message: 'Missing value' }, { validator: validateTrimmedValue }]}
                    >
                      <Input placeholder="Value" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} title="Remove field" />
                  </Space>
                </Row>
              )),
              <Form.Item key="add-field">
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add field
                </Button>
              </Form.Item>,
            ]}
          </Form.List>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  return (
    <InfoTile
      name="partner-reconciliation-data"
      title="Reconciliation data"
      extra={[
        can('update', 'partner') && (
          <Button key="update-partner" onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        ),
      ]}
    >
      {configurationFields?.length > 0 || <Empty />}
      {configurationFields.map(({ key, value }) => (
        <InfoTextRow key={key} label={key} value={value} />
      ))}
    </InfoTile>
  );
};

export default PartnerReconciliationData;

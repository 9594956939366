const DateFormats = {
  DATE: 'DD/MM/YYYY',
  DATETIME: 'DD/MM/YYYY HH:mm:ss',
  EN_DATE: 'YYYY-MM-DD',
  EN_DATETIME: 'YYYY-MM-DD HH:mm:ss',
  US_DATE: 'MM-DD-YYYY',
  US_DATETIME: 'MM-DD-YYYY HH:mm:ss',
};

export const dateFormats = {
  [DateFormats.DATE]: { value: DateFormats.DATE, label: DateFormats.DATE },
  [DateFormats.DATETIME]: { value: DateFormats.DATETIME, label: DateFormats.DATETIME },
  [DateFormats.EN_DATE]: { value: DateFormats.EN_DATE, label: DateFormats.EN_DATE },
  [DateFormats.EN_DATETIME]: { value: DateFormats.EN_DATETIME, label: DateFormats.EN_DATETIME },
  [DateFormats.US_DATE]: { value: DateFormats.US_DATE, label: DateFormats.US_DATE },
  [DateFormats.US_DATETIME]: { value: DateFormats.US_DATETIME, label: DateFormats.US_DATETIME },
};

export const dateFormatList = Object.values(dateFormats);

export default DateFormats;

import React, { useState } from 'react';
import { Alert, Divider, Drawer, Form, message, Steps } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import StepCreateAnnouncementMessage from '../CreateAnnouncement/StepCreateAnnouncementMessage';
import StepSelectAnnouncementRecipients from '../CreateAnnouncement/StepSelectAnnouncementRecipients/StepSelectAnnouncementRecipients';
import StepScheduleAnnouncement from '../CreateAnnouncement/StepScheduleAnnouncement';
import { UpdateAnnouncementMutation } from '../CreateAnnouncement/query';
import { programManagerQuery } from '../../ProgramManagers/ProgramManagerView/query';

const UpdateAnnouncement = ({ announcement, programManagerId, visible, onClose }) => {
  const query = useQuery(programManagerQuery, {
    variables: {
      id: programManagerId,
    },
    skip: !programManagerId,
  });

  const [current, setCurrent] = useState(0);
  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  const [updateAnnouncement, { error }] = useMutation(UpdateAnnouncementMutation, {
    variables: { id: announcement?.id },
    onCompleted: () => {
      message.success('Announcement successfully updated !');
      onClose();
    },
    errorPolicy: 'all',
    refetchQueries: ['AnnouncementsQuery'],
  });

  return (
    <Drawer
      title={programManagerId ? `Update ${query.data?.programManager.name ?? ''} Announcement` : 'Update Announcement'}
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
    >
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === 'schedule') {
            const { content, recipients, schedule } = forms;
            updateAnnouncement({
              variables: {
                input: {
                  ...(programManagerId ? { programManagers: [programManagerId] } : {}),
                  ...content.getFieldsValue(),
                  ...recipients.getFieldsValue(),
                  ...schedule.getFieldsValue(),
                },
              },
            }).then(() => {
              content.resetFields();
              recipients.resetFields();
              schedule.resetFields();
              setCurrent(0);
            });
          } else {
            next();
          }
        }}
      >
        <Steps
          current={current}
          size="small"
          items={[{ title: 'Create Message' }, { title: 'Select Recipients' }, { title: 'Schedule' }]}
        />
        <Divider />
        <StepCreateAnnouncementMessage announcement={announcement} visible={current === 0} />
        <StepSelectAnnouncementRecipients
          announcement={announcement}
          programManagerId={programManagerId}
          prev={prev}
          visible={current === 1}
        />
        <StepScheduleAnnouncement
          announcement={announcement}
          programManagerId={programManagerId}
          prev={prev}
          visible={current === 2}
        />
      </Form.Provider>
      {error && <Alert type="error" message={error.message} />}
    </Drawer>
  );
};

export default UpdateAnnouncement;

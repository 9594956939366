import { gql } from '@apollo/client';

export const merchantAccountUrlsQuery = gql`
  query MerchantAccount($id: ID!) {
    merchantAccount(id: $id) {
      id
      accept_url
      decline_url
      pending_url
      exception_url
    }
  }
`;

export const merchantAccountUrlsMutation = gql`
  mutation UpdateMerchantAccountUrls($id: ID!, $input: MerchantAccountUrlsInput!) {
    updateMerchantAccountUrls(id: $id, input: $input) {
      id
      accept_url
      decline_url
      pending_url
      exception_url
    }
  }
`;

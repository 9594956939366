import React from 'react';
import { useMutation } from '@apollo/client';
import { Form, Input, message, Modal, Switch } from 'antd';
import { ApiKeyAddMutation } from '../query';
import { useResetFormOnCloseModal } from '../../../../../Common/Form/utils';

const initialValues = { active: true, is_public: false, is_internal: false };

const ApiKeyCreate = ({ visible, onClose, merchantAccount }) => {
  const [create, { loading }] = useMutation(ApiKeyAddMutation, {
    onCompleted: () => {
      message.success('Api key successfully created');
      onClose();
    },
    onError: (err) => message.error(err.message),
  });

  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const onOk = () => {
    form.submit();
  };

  const submit = (values) => {
    create({
      variables: {
        input: {
          name: values.name,
          status: values.active ? 'active' : 'inactive',
          is_public: values.is_public,
          is_internal: values.is_internal,
        },
        merchantAccountId: merchantAccount.id,
      },
    });
  };

  return (
    <Modal title="Add Api Key" open={visible} onOk={onOk} onCancel={onClose} confirmLoading={loading}>
      <Form form={form} layout="vertical" name="apiKeyForm" onFinish={submit} initialValues={initialValues}>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="active" label="Is active" valuePropName="checked" tooltip="Api Keys are active by default.">
          <Switch />
        </Form.Item>
        <Form.Item name="is_public" label="Is public" valuePropName="checked" tooltip="Public / Private Api Keys.">
          <Switch />
        </Form.Item>
        <Form.Item
          name="is_internal"
          label="Is internal"
          valuePropName="checked"
          tooltip="Internal Api Keys are only visible on Admin module."
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ApiKeyCreate;

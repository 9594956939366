import React from 'react';
import InfoTile, { InfoSkeletonRow } from '../../../Tile/Info/Info';

const AuthenticationSkeleton = () => (
  <InfoTile name="authentication" title="Authentication">
    <InfoSkeletonRow label="Status" />
    <InfoSkeletonRow label="Version" />
    <InfoSkeletonRow label="Authentication identifier" />
    <InfoSkeletonRow label="ECI" />
    <InfoSkeletonRow label="Issuer" />
    <InfoSkeletonRow label="ACS identifier" />
    <InfoSkeletonRow label="Directory server identifier" />
  </InfoTile>
);

export default AuthenticationSkeleton;

import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useFavoriteView from './useFavoriteView';
import usePage from './usePage';

/**
 * Update sort in SearchParams (react-router)
 * @param searchParams
 * @param value
 * @param prefix
 * @returns {Object}
 */
export const updateTableDisplayInSearchParams = (searchParams, value, prefix) =>
  searchParams.set(`${prefix}_display`, value);

const useTableDisplay = () => {
  const page = usePage();
  const { display: userTableDisplay } = useFavoriteView();

  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(`${page.prefix}_display`);

  const tableDisplay = paramValue ?? userTableDisplay;

  const setTableDisplay = useCallback(
    (value = userTableDisplay) => {
      const newSearchParams = new URLSearchParams(searchParams);
      updateTableDisplayInSearchParams(newSearchParams, value, page.prefix);
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams],
  );

  useEffect(() => {
    // init search url with user|default tableDisplay
    if (paramValue === null) setTableDisplay();
  }, []);

  return [tableDisplay, setTableDisplay];
};

export default useTableDisplay;

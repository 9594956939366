import { gql } from '@apollo/client';

export const MetaProgramManagerApiKeysQuery = gql`
  query MetaProgramManagerApiKeys($metaProgramManagerId: ID!) {
    metaProgramManager(id: $metaProgramManagerId) {
      id
      apiKeys {
        __typename
        id
        name
        status
        key
      }
    }
  }
`;

export const MetaProgramManagerApiKeyAddMutation = gql`
  mutation MetaProgramManagerApiKeyAdd($metaProgramManagerId: ID!, $input: MetaProgramManagerApiKeyInput!) {
    addMetaProgramManagerApiKey(id: $metaProgramManagerId, input: $input) {
      __typename
      id
      apiKeys {
        __typename
        id
        name
        status
        key
      }
    }
  }
`;

export const MetaProgramManagerApiKeyUpdateMutation = gql`
  mutation MetaProgramManagerApiKeyUpdate($id: ID!, $input: MetaProgramManagerApiKeyInput!) {
    updateMetaProgramManagerApiKey(id: $id, input: $input) {
      __typename
      id
      name
      status
      key
    }
  }
`;

export default {};

import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import RestrictedPage from '../RestrictedPage';
import AccessControl from './AccessControl/AccessControl';
import MerchantAccounts from './MerchantAccounts/MerchantAccounts';
import MerchantGroups from './MerchantGroups/MerchantGroups';
import Users from './Users/Users';
import InternalUsers from './InternalUsers/InternalUsers';
import Company from './Company/Company';
import Partners from './Partners/Partners';
import PartnerRoutes from './PartnerRoutes/PartnerRoutes';
import InternalAccessControl from './InternalAccessControl/InternalAccessControl';
import ProgramManagers from './ProgramManagers/ProgramManagers';
import ProgramManagerAccessControl from './ProgramManagerAccessControl/ProgramManagerAccessControl';
import ProgramManagerUsers from './ProgramManagerUsers/ProgramManagerUsers';
import ProgramManager from './ProgramManager/ProgramManager';
import ProgramManagerAccessOverview from './ProgramManagerAccessOverview/ProgramManagerAccessOverview';
import ProgramManagerPartners from './ProgramManagerPartners/ProgramManagerPartners';
import HomePage from './HomePage/HomePage';
import Brainpower from '../Brainpower/Brainpower';
import Announcements from './Announcements/Announcements';
import LogList from '../LogList/LogList';
import MetaProgramManagers from './MetaProgramManagers/MetaProgramManagers';
import MetaProgramManagerUsers from './MetaProgramManagerUsers/MetaProgramManagerUsers';
import MetaProgramManager from './MetaProgramManager/MetaProgramManager';
import MetaProgramManagerAccessOverview from './MetaProgramManagerAccessOverview/MetaProgramManagerAccessOverview';
import MetaProgramManagerAccessControl from './MetaProgramManagerAccessControl/MetaProgramManagerAccessControl';
import MetaProgramManagerPartners from './MetaProgramManagerPartners/MetaProgramManagerPartners';

const Admin = () => (
  <Routes>
    <Route path="home" element={<HomePage />} />
    <Route path="brainpower/*" element={<Brainpower />} />
    <Route path="companies/*" element={<Company />} />
    <Route path="merchant-accounts/*" element={<MerchantAccounts />} />
    <Route path="merchant-groups/*" element={<MerchantGroups />} />
    <Route path="users/*" element={<Users />} />
    <Route
      path="logs/*"
      element={
        <RestrictedPage action="read" subject="log">
          <LogList />
        </RestrictedPage>
      }
    />
    <Route
      path="partners/*"
      element={
        <RestrictedPage action="read" subject="partner" internalOnly>
          <Partners />
        </RestrictedPage>
      }
    />
    <Route
      path="partner-routes/*"
      element={
        <RestrictedPage action="read" subject="partner-route">
          <PartnerRoutes />
        </RestrictedPage>
      }
    />
    <Route
      path="access-control-list/*"
      element={
        <RestrictedPage action="read" subject="access-control">
          <AccessControl />
        </RestrictedPage>
      }
    />
    <Route
      path="program-managers/*"
      element={
        <RestrictedPage action="read" subject="program-manager">
          <ProgramManagers />
        </RestrictedPage>
      }
    />
    <Route
      path="meta-program-managers/*"
      element={
        <RestrictedPage action="read" subject="meta-program-manager">
          <MetaProgramManagers />
        </RestrictedPage>
      }
    />
    <Route
      path="program-settings"
      element={
        <RestrictedPage action="read" subject="program-manager">
          <ProgramManager />
        </RestrictedPage>
      }
    />
    <Route
      path="meta-program-settings"
      element={
        <RestrictedPage action="read" subject="meta-program-manager">
          <MetaProgramManager />
        </RestrictedPage>
      }
    />
    <Route
      path="program-partners"
      element={
        <RestrictedPage action="read" subject="program-manager-active-partner">
          <ProgramManagerPartners />
        </RestrictedPage>
      }
    />
    <Route
      path="meta-program-partners"
      element={
        <RestrictedPage action="read" subject="meta-program-manager-active-partner">
          <MetaProgramManagerPartners />
        </RestrictedPage>
      }
    />
    <Route
      path="program-users/*"
      element={
        <RestrictedPage action="read" subject="program-manager-user">
          <ProgramManagerUsers />
        </RestrictedPage>
      }
    />
    <Route
      path="meta-program-users/*"
      element={
        <RestrictedPage action="read" subject="meta-program-manager-user">
          <MetaProgramManagerUsers />
        </RestrictedPage>
      }
    />
    <Route
      path="program-access-overview"
      element={
        <RestrictedPage action="read" subject="program-manager-access-control">
          <ProgramManagerAccessOverview />
        </RestrictedPage>
      }
    />
    <Route
      path="meta-program-access-overview"
      element={
        <RestrictedPage action="read" subject="meta-program-manager-access-control">
          <MetaProgramManagerAccessOverview />
        </RestrictedPage>
      }
    />
    <Route
      path="program-access-control-list/*"
      element={
        <RestrictedPage action="read" subject="program-manager-access-control">
          <ProgramManagerAccessControl />
        </RestrictedPage>
      }
    />
    <Route
      path="meta-program-access-control-list/*"
      element={
        <RestrictedPage action="read" subject="meta-program-manager-access-control">
          <MetaProgramManagerAccessControl />
        </RestrictedPage>
      }
    />
    <Route
      path="internal-users/*"
      element={
        <RestrictedPage action="read" subject="internal_user" internalOnly>
          <InternalUsers />
        </RestrictedPage>
      }
    />
    <Route
      path="internal-access-control-list/*"
      element={
        <RestrictedPage action="update" subject="internal-access-control" internalOnly>
          <InternalAccessControl />
        </RestrictedPage>
      }
    />
    <Route
      path="announcements"
      element={
        <RestrictedPage action="read" subject="announcement">
          <Announcements />
        </RestrictedPage>
      }
    />
    <Route path="*" element={<Navigate to="home" />} />
  </Routes>
);

export default Admin;

import React from 'react';
import { Select } from 'antd';
import useBaseGranularity, { granularityList } from '../../hooks/useBaseGranularity';
import styles from './GranularitySelector.module.scss';

const GranularitySelector = () => {
  const [baseGranularity, setBaseGranularity] = useBaseGranularity();
  return (
    <Select
      className={styles.root}
      defaultValue={baseGranularity}
      onChange={setBaseGranularity}
      options={granularityList}
      dropdownMatchSelectWidth={false}
      bordered={false}
    />
  );
};

export default GranularitySelector;

import React, { useEffect } from 'react';
import { Button, Divider, Empty, Form, Input, Select, Space, Typography } from 'antd';
import { AnnouncementMessage } from '../../../../../contexts/messages/MessagesContainer/Message/Message';
import Markdown from '../../../Common/Markdown/Markdown';
import { announcementTypeList, AnnouncementTypes } from '../announcementTypes';

const StepCreateAnnouncementMessage = ({ announcement, visible }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (announcement) {
      form.setFieldsValue({
        type: announcement.type ?? AnnouncementTypes.ALERT,
        title: announcement.title,
        content: announcement.content,
        body: announcement.body,
      });
    }
  }, [announcement]);

  return (
    <Form form={form} layout="vertical" name="content" hidden={!visible}>
      <Form.Item name="type" label="Type" rules={[{ required: true }]} initialValue={AnnouncementTypes.ALERT}>
        <Select
          options={announcementTypeList.map(({ value, label, icon: Icon, color }) => ({
            value,
            label: (
              <Space>
                <Icon style={{ color }} />
                {label}
              </Space>
            ),
          }))}
        />
      </Form.Item>
      <Form.Item name="title" label="Title" rules={[{ required: true }]}>
        <Input placeholder="New announcement" />
      </Form.Item>
      <Form.Item name="content" label="Message content" rules={[{ required: true }]}>
        <Input placeholder="Announcement content" />
      </Form.Item>
      <Form.Item
        name="body"
        label={
          <span>
            Modal content (
            <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank" rel="noreferrer">
              Markdown cheatsheet
            </a>
            )
          </span>
        }
      >
        <Input.TextArea placeholder="Announcement content ..." rows={10} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </Form.Item>
      <Divider />
      <Typography.Title level={5}>Preview</Typography.Title>
      <Form.Item noStyle shouldUpdate={(prev, next) => prev !== next}>
        {() => (
          <Space direction="vertical" style={{ backgroundColor: 'whitesmoke', padding: 8, width: '100%' }}>
            <div style={{ width: 400, margin: 'auto' }}>
              <AnnouncementMessage message={{ content: form.getFieldsValue() }} preview />
            </div>
            <div style={{ backgroundColor: 'white', padding: 8, borderRadius: 2 }}>
              {form.getFieldValue('body') ? (
                <Markdown>{form.getFieldValue('body')}</Markdown>
              ) : (
                <Empty description="" />
              )}
            </div>
          </Space>
        )}
      </Form.Item>
      <Divider />
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </Form.Item>
    </Form>
  );
};

export default StepCreateAnnouncementMessage;

import { gql } from '@apollo/client';

export const createApiKeyMutation = gql`
  mutation CreateApiKey($merchantAccount: ID!, $input: ApiKeyInput!) {
    addApiKeyMerchantAccount(id: $merchantAccount, input: $input) {
      id
      __typename
      api_keys {
        __typename
        id
        name
        is_public
        key
      }
    }
  }
`;

export default {};

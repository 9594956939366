import React, {useState} from 'react';
import {LinkOutlined} from '@ant-design/icons';
import {Button, Form, Modal, Select} from 'antd';
import {useCan} from '../../../../../../contexts/ability.context';
import {useMutation, useQuery} from '@apollo/client';
import {AttachMetaProgramManagerMutation, MetaProgramManagersQuery} from './query';
import MetaProgramManagerAvatar from '../../../../Common/MetaProgramManager/Avatar';
import Loader from '../../../../Common/Loader/Loader';

const MetaProgramManager = ({ programManager }) => {
  const can = useCan();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMpm, setSelectedMpm] = useState(null);

  const {
    data: { metaProgramManagers } = {
      metaProgramManagers: [],
    },
    loading: loadingMetaProgramManagers
  } = useQuery(MetaProgramManagersQuery);

  const [attach, { loading: loadingAttach }] = useMutation(AttachMetaProgramManagerMutation, {
    variables: {
      id: programManager?.id,
    }
  })

  if (loadingMetaProgramManagers) {
    return <Loader />;
  }

  if (!programManager) {
    return null;
  }
  if (programManager.metaProgramManager) {
    return <MetaProgramManagerAvatar metaProgramManager={programManager.metaProgramManager} />
  }
  if (can('attach', 'meta-program-manager')) {
    return (
      <>
        <Button
          key="attach-meta-program-manager"
          title="Attach to meta program manager"
          icon={<LinkOutlined />}
          shape="circle"
          onClick={() => setIsModalOpen(true)}
        />
        <Modal
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          title='Attach this program manager to a meta program manager'
          okButtonProps={{
            disabled: !selectedMpm,
          }}
          onOk={() => attach({
            variables: {
              metaProgramManagerId: selectedMpm,
            }
          })}
        >
          <Form
            layout='vertical'
          >
            <Form.Item
              layout='vertical'
              label='Meta program manager to attach:'
            >
              <Select
                loading={loadingMetaProgramManagers}
                options={metaProgramManagers.map(mpm => ({
                  label: mpm.name,
                  value: mpm.id,
                }))}
                onChange={setSelectedMpm}
                value={selectedMpm}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
  return null
};

export default MetaProgramManager
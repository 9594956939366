import { BellOutlined, CodeOutlined, SwapOutlined } from '@ant-design/icons';
import { compareByProp } from '../../../../util/array';

export const FragmentPartnerRouteOptions = `
  is_capturable
  is_refundable
  is_cancellable
  is_multiple_captures_available
  is_differed_capture_available
  is_multiple_refunds_available
  is_partial_capture_available
  is_partial_refund_available
  is_3ds_available
  has_tokenization_system
  use_fixed_ip
  use_external_authorization
  always_tokenize
  use_instrument
  has_authentication_service
  accept_external_auth
  response_authorize
  response_capture
  response_cancel  
  response_refund
  has_qr_code
  accept_card_number
  has_redirect_page
  capture_retryable
  refund_retryable
  request_failed_max_retry
`;

export const partnerRouteDefaultOptions = {
  is_capturable: { value: 'is_capturable', label: 'Is capturable', type: 'Boolean' },
  is_refundable: { value: 'is_refundable', label: 'Is refundable', type: 'Boolean' },
  is_cancellable: { value: 'is_cancellable', label: 'Is cancellable', type: 'Boolean' },
  is_multiple_captures_available: {
    value: 'is_multiple_captures_available',
    label: 'Is multiple captures available',
    type: 'Boolean',
  },
  is_differed_capture_available: {
    value: 'is_differed_capture_available',
    label: 'Is differed capture available',
    type: 'Boolean',
  },
  is_multiple_refunds_available: {
    value: 'is_multiple_refunds_available',
    label: 'Is multiple refunds available',
    type: 'Boolean',
  },
  is_partial_capture_available: {
    value: 'is_partial_capture_available',
    label: 'Is partial capture available',
    type: 'Boolean',
  },
  is_partial_refund_available: {
    value: 'is_partial_refund_available',
    label: 'Is partial refund available',
    type: 'Boolean',
  },
  is_3ds_available: { value: 'is_3ds_available', label: 'Is 3DS available', type: 'Boolean' },
  has_tokenization_system: { value: 'has_tokenization_system', label: 'Has tokenization system', type: 'Boolean' },
  use_fixed_ip: { value: 'use_fixed_ip', label: 'Use fixed IP', type: 'Boolean' },
  use_external_authorization: {
    value: 'use_external_authorization',
    label: 'Use external authentication',
    type: 'Boolean',
  },
  has_authentication_service: {
    value: 'has_authentication_service',
    label: 'Has authentication service',
    type: 'Boolean',
  },
  always_tokenize: { value: 'always_tokenize', label: 'Always tokenize', type: 'Boolean' },
  use_instrument: { value: 'use_instrument', label: 'Use instrument', type: 'Boolean' },
  accept_external_auth: { value: 'accept_external_auth', label: 'Accept external auth', type: 'Boolean' },
  response_authorize: { value: 'response_authorize', label: 'Response authorize', type: 'PartnerResponseType' },
  response_capture: { value: 'response_capture', label: 'Response capture', type: 'PartnerResponseType' },
  response_cancel: { value: 'response_cancel', label: 'Response cancel', type: 'PartnerResponseType' },
  response_refund: { value: 'response_refund', label: 'Response refund', type: 'PartnerResponseType' },
  has_qr_code: { value: 'has_qr_code', label: 'Has QR code', type: 'Boolean' },
  accept_card_number: { value: 'accept_card_number', label: 'Accept card number', type: 'Boolean' },
  has_redirect_page: { value: 'has_redirect_page', label: 'Has redirect page', type: 'Boolean' },
  capture_retryable: { value: 'capture_retryable', label: 'Is capture retryable', type: 'Boolean' },
  refund_retryable: { value: 'refund_retryable', label: 'Is refund retryable', type: 'Boolean' },
  request_failed_max_retry: { value: 'request_failed_max_retry', label: 'Request failed max retry', type: 'Int' },
};

export const partnerRouteDefaultOptionList = Object.values(partnerRouteDefaultOptions).sort(compareByProp('label'));

export const partnerRouteOptions = {
  ...partnerRouteDefaultOptions,
  request_expiration_delay: { value: 'request_expiration_delay', label: 'Expiration delay (minutes)', type: 'Int' },
};

export const partnerRouteOptionList = Object.values(partnerRouteOptions);

export const partnerRouteStatuses = {
  active: {
    value: 'active',
    label: 'Active',
    color: 'green',
    badge: 'success',
  },
  deactivated: {
    value: 'deactivated',
    label: 'Deactivated',
    color: 'grey',
    badge: 'default',
  },
  deleted: {
    value: 'deleted',
    label: 'Deleted',
    color: 'red',
    badge: 'error',
  },
  maintenance: {
    value: 'maintenance',
    label: 'Maintenance',
    color: 'gold',
    badge: 'warning',
  },
  rfp_only: {
    value: 'rfp_only',
    label: 'RFP only',
    color: 'gold',
    badge: 'warning',
  },
};

export const partnerRouteStatusList = Object.values(partnerRouteStatuses);

export const partnerResponseTypes = {
  automatic: { value: 'automatic', label: 'Automatic', iconComponent: CodeOutlined },
  direct: { value: 'direct', label: 'Direct', iconComponent: SwapOutlined },
  notification: { value: 'notification', label: 'Notification', iconComponent: BellOutlined },
};

export const partnerResponseTypeList = Object.values(partnerResponseTypes);

export const partnerRiskOptions = {
  risk_assessment_service: {
    value: 'risk_assessment_service',
    label: 'Risk assessment service',
    type: 'PartnerRiskAssessmentService',
  },
};

export const partnerRiskOptionList = Object.values(partnerRiskOptions);

export const partnerRiskAssessmentServices = {
  embedded_not_deactivable: { value: 'embedded_not_deactivable', label: 'Embedded not deactivable' },
  embedded_deactivable: { value: 'embedded_deactivable', label: 'Embedded deactivable' },
  external: { value: 'external', label: 'External' },
};

export const partnerRiskAssessmentServiceList = Object.values(partnerRiskAssessmentServices);

export default {};

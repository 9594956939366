import { gql } from '@apollo/client';

export const MerchantGroupViewQuery = gql`
  query MerchantGroupViewQuery($id: ID!) {
    merchantGroup(id: $id) {
      id
      name
      description
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
        merchantCompanies {
          id
          name
          theme {
            logo
            icon
            primaryColor
            secondaryColor
          }
          merchantAccounts {
            id
            name
          }
        }
        users {
          id
          full_name
          avatar
          aclProfile {
            id
            name
          }
          status
        }
        programManagerAclProfiles {
          id
          name
        }
      }
      merchantCompanies {
        id
        name
        status
        country
        activity
        userCount
        merchantAccountCount
        theme {
          logo
          icon
          primaryColor
          secondaryColor
        }
      }
      merchantAccounts {
        id
        name
        status
        cms_name
        cms_version
        userCount
        source
        company {
          country
          theme {
            logo
            icon
            primaryColor
            secondaryColor
          }
        }
      }
      users {
        id
        full_name
        avatar
      }
      userCount
    }
  }
`;

export const MerchantGroupDeleteMutation = gql`
  mutation MerchantGroupDeleteMutation($id: ID!) {
    deleteMerchantGroup(id: $id)
  }
`;

export default {};

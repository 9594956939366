import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from 'antd';
import styles from '../AppUnlogged.module.scss';

const getEnvListByCurrentEnv = (currentEnv, programManager) => {
  let alternativeEnvUrl;
  if (process.env.REACT_APP_MODE === 'admin') {
    alternativeEnvUrl = programManager
      ? `https://${programManager?.alternativeAdminDomain}`
      : `https://${process.env.REACT_APP_ALTERNATIVE_ADMIN_DOMAIN}`;
  } else {
    alternativeEnvUrl = programManager?.alternativeMerchantDomain
      ? `https://${programManager.alternativeMerchantDomain}`
      : null;
  }

  switch (currentEnv) {
    case 'prod':
      return [
        { name: 'Production', url: '/login' },
        {
          name: 'Sandbox',
          url: `${alternativeEnvUrl}/login`,
          disabled: !alternativeEnvUrl,
        },
      ];
    case 'sandbox':
      return [
        {
          name: 'Production',
          url: `${alternativeEnvUrl}/login`,
          disabled: !alternativeEnvUrl,
        },
        { name: 'Sandbox', url: '/login' },
      ];
    case 'staging':
      return [
        { name: 'Staging', url: '/login' },
        {
          name: 'Development',
          url: `${alternativeEnvUrl}/login`,
          disabled: !alternativeEnvUrl,
        },
      ];
    case 'dev':
      return [
        {
          name: 'Staging',
          url: `${alternativeEnvUrl}/login`,
          disabled: !alternativeEnvUrl,
        },
        { name: 'Development', url: '/login' },
      ];
    case 'localhost':
    default:
      return [
        {
          name: 'Development',
          url: `${alternativeEnvUrl}/login`,
          disabled: !alternativeEnvUrl,
        },
        { name: 'Localhost', url: '/login' },
      ];
  }
};

const EnvSelector = ({ programManager, metaProgramManager }) => (
  <div className={styles.block}>
    <Typography.Title level={4}>Select an account type</Typography.Title>
    <div className={styles.list}>
      {getEnvListByCurrentEnv(process.env.REACT_APP_ENVIRONMENT, metaProgramManager ?? programManager).map(({ name, url, disabled }) =>
        url === '/login' ? (
          <Link key={name} to={url}>
            <Button id="env-btn" type="primary" className={styles.btn}>
              {name}
            </Button>
          </Link>
        ) : (
          <Button
            id="alternative-env-btn"
            key={name}
            type="primary"
            className={styles.btn}
            href={url}
            disabled={disabled}
            title={disabled ? `Alternative domain (${name}) is not configured.` : undefined}
          >
            {name}
          </Button>
        ),
      )}
    </div>
    {!programManager ? (
      <a id="website-link" className={styles.backLink} href="https://norbr.com">
        Back to norbr.com
      </a>
    ) : (
      <a id="website-link" className={styles.backLink} href={`https://${programManager.website}`}>
        {programManager.website}
      </a>
    )}
  </div>
);

export default EnvSelector;

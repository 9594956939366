import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { config as Indicators } from '../../constants/indicators';

const reverseGroupBy = (dimension, indicators, data) =>
  data.reduce((memo, row) => {
    const { [dimension]: name, ...categories } = row;
    if (memo.length === 0) {
      return indicators.map((indicator) => ({
        indicator,
        category: Indicators[indicator]?.label ?? indicator,
        [name]: categories[indicator],
      }));
    }
    return memo.map((newRow) => ({
      ...newRow,
      [name]: categories[newRow.indicator],
    }));
  }, []);

const colors = {};

/**
 * Radar Chart
 * 1 dimension
 * 3+ indicators
 * Axis and Series are reversed.
 * Dimension values are series.
 * Indicators are category axis.
 * @param divId
 * @param widget
 * @param data
 * @returns {RadarChart}
 */
export default (divId, widget, data) => {
  const { dimensions, indicators } = widget;

  const dimension = dimensions[0];

  const chart = am4core.create(divId, am4charts.RadarChart);

  chart.hiddenState.properties.opacity = 0;

  // map data to find present dim values
  const seriesList = data.data.map((row) => row[dimension]);

  // reverse dimension / indicator
  const reverseData = reverseGroupBy(dimension, indicators, data.data);

  // updated data to have efficient visual distribution
  const reevaluatedData = reverseData.map(({ category, indicator, ...row }) => {
    let max = Math.max(1, ...Object.values(row));
    let min = Math.min(99, ...Object.values(row));
    if (min === max) {
      min = 0;
    }
    const margin = (max - min) * 0.1;
    max += margin;
    min -= margin;
    return {
      category,
      indicator,
      ...Object.keys(row).reduce(
        (memo, key) => ({
          ...memo,
          [key]: (row[key] - min) * (100 / (max - min)),
          [`${key}-value`]: row[key],
        }),
        {},
      ),
    };
  });
  chart.data = reevaluatedData;

  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = 'category';

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.renderer.axisFills.template.fill = chart.colors.getIndex(2);
  valueAxis.renderer.axisFills.template.fillOpacity = 0.05;
  valueAxis.renderer.gridType = 'polygons';
  valueAxis.renderer.labels.template.disabled = true;
  valueAxis.min = 0;
  valueAxis.max = 100;
  valueAxis.strictMinMax = true;

  /* Create and configure series */
  seriesList.forEach((name, index) => {
    const series = chart.series.push(new am4charts.RadarSeries());
    series.dataFields.valueY = name;
    series.dataFields.categoryX = 'category';
    series.name = name;
    series.strokeWidth = 3;

    colors[name] = series.stroke;
    series.dataFields.label = `${name}-value`; // use with reevaluated data
    series.legendSettings.labelText = '[#242424]{name}[/]\n';
    series.clickable = true;
    series.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    series.strokeWidth = 3;
    series.fillOpacity = 0.2;

    // Display only 1 aggregated tooltip
    if (index === 0) {
      series.adapter.add('tooltipText', () => {
        let text = '[#000]{category}[/]\n';
        chart.series.each((item) => {
          text += `[${item.stroke.hex}]●[/][#000] ${item.name}: {${item.name}-value}[/]\n`;
        });
        return text;
      });
      series.tooltip.background.fill = am4core.color('#fff');
      series.tooltip.getFillFromObject = false;
    }
  });

  chart.padding(0, 0, 0, 0);
  // Add cursor
  chart.cursor = new am4charts.RadarCursor();
  chart.cursor.behavior = 'none'; // disable zooming
  categoryAxis.cursorTooltipEnabled = false;
  valueAxis.cursorTooltipEnabled = false;

  // Add legend.
  chart.legend = new am4charts.Legend();
  const markerTemplate = chart.legend.markers.template;
  markerTemplate.width = 15;
  markerTemplate.height = 15;
  return chart;
};

import { gql } from '@apollo/client';

export const PartnerCreateMutation = gql`
  mutation PartnerCreate($input: PartnerInput!) {
    createPartner(input: $input) {
      __typename
      allow_insights
      allow_stack
      allow_insights_pos
      allow_payfac
      id
      name
      api_name
      type
      code
      status
      company {
        id
        name
      }
    }
  }
`;

export default {};

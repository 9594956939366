import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Radio, Select } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { Block } from '../../../../../../Common/Sider';
import { ContractEditionContext } from '../../../../PaymentNetwork';
import { useMerchantAccount } from '../../../../../merchantAccount.context';
import { MerchantContractContext } from '../../MerchantContractDrawer';
import styles from './ConnectionSettings.module.scss';
import { merchantAccountCertificatesQuery } from './query';

const { Item } = Form;

const ConnectionSettings = () => {
  const { t } = useTranslation();

  const { merchantAccount } = useMerchantAccount();
  const { merchantContract, updateMerchantContract } = useContext(MerchantContractContext);
  const { isEdited } = useContext(ContractEditionContext);

  const [getCertificates, { data, loading }] = useLazyQuery(merchantAccountCertificatesQuery, {
    variables: {
      id: merchantAccount?.id,
    },
  });

  useEffect(() => {
    if (
      Object.values(merchantContract?.partner?.partner_data_configuration ?? {})
        .map((e) => e.type)
        .includes('Certificate') &&
      merchantAccount?.id
    ) {
      getCertificates();
    }
  }, [merchantContract?.partner?.partner_data_configuration, merchantAccount]);

  const handleChangeConfig = (key, value) => {
    const configuration = merchantContract?.configuration ?? {};
    updateMerchantContract({
      variables: {
        input: {
          configuration: {
            ...configuration,
            [key]: typeof value === 'string' ? value.trim() : value,
          },
        },
      },
      context: {
        debounceKey: `updateMerchantContractConfiguration-${merchantContract.id}`,
        debounceTimeout: 1000,
      },
      optimisticResponse: {
        updateContract: {
          ...merchantContract,
          configuration: {
            ...configuration,
            [key]: value,
          },
        },
      },
    });
  };

  const settingsToShow = Object.keys(merchantContract?.partner?.partner_data_configuration ?? {}).filter((config) => {
    const configParams = merchantContract?.partner?.partner_data_configuration[config];

    return ['optional', 'required'].includes(configParams?.sources?.[merchantContract.source]);
  });

  if (!merchantContract?.partner?.partner_data_configuration || settingsToShow.length === 0) {
    return null;
  }

  return (
    <Block title="Connection settings" description={isEdited && 'Set up your connection'}>
      <Form layout="vertical" className={styles.drawerForm}>
        {Object.keys(merchantContract?.partner?.partner_data_configuration ?? {})
          .filter(
            (config) =>
              (merchantAccount.programManager.payfac &&
                merchantContract.partner.partner_data_configuration?.[config]?.payfac) ||
              (!merchantAccount.programManager.payfac &&
                merchantContract.partner.partner_data_configuration?.[config]?.merchant),
          )
          .map((config) => {
            const configParams = merchantContract?.partner?.partner_data_configuration[config];
            const currentValue = merchantContract?.configuration?.[config];
            if (configParams?.sources?.[merchantContract.source] === 'excluded') {
              return null;
            }
            // configurations specific to paymentMethods are not considered as required
            const isRequired =
              !configParams.paymentMethods?.length && configParams.sources[merchantContract.source] === 'required';
            const tooltipMessage =
              configParams.paymentMethods?.length && configParams.sources[merchantContract.source] === 'required'
                ? `Required for the payment method(s) : ${configParams.paymentMethods.reduce((acc, pm) => `${acc}${t(`andMe.paymentNetwork.merchantContract.payment_methods.${pm}`)}, `, '').slice(0, -2)}`
                : null;
            switch (configParams?.type) {
              case 'String':
                return (
                  <Item
                    id={`config-${config}`}
                    key={`config-${config}`}
                    label={config}
                    required={isRequired}
                    disabled={merchantContract.has_authorized_transactions}
                    tooltip={tooltipMessage}
                  >
                    <Input
                      disabled={!isEdited || merchantContract.is_demo}
                      onChange={(event) => handleChangeConfig(config, event.target.value)}
                      value={currentValue}
                      defaultValue={currentValue}
                    />
                  </Item>
                );
              case 'Enum':
                return (
                  <Item
                    id={`config-${config}`}
                    key={`config-${config}`}
                    label={config}
                    // configurations specific to paymentMethods are not considered as required
                    required={isRequired}
                    disabled={merchantContract.has_authorized_transactions}
                    tooltip={tooltipMessage}
                  >
                    <Select
                      disabled={!isEdited || merchantContract.is_demo}
                      onChange={(value) => handleChangeConfig(config, value)}
                      value={currentValue}
                      defaultValue={currentValue}
                      options={configParams.enum?.map((value) => ({ value }))}
                    />
                  </Item>
                );
              case 'Boolean':
                return (
                  <Item
                    id={`config-${config}`}
                    key={`config-${config}`}
                    label={config}
                    // configurations specific to paymentMethods are not considered as required
                    required={isRequired}
                    disabled={merchantContract.has_authorized_transactions}
                    tooltip={tooltipMessage}
                    // Allow reset radio value
                    onClick={(e) => {
                      if (e.target.type === 'radio' && e.target.value === currentValue?.toString()) {
                        handleChangeConfig(config, null);
                      }
                    }}
                  >
                    <Radio.Group
                      options={[
                        {
                          label: 'True',
                          value: true,
                        },
                        {
                          label: 'False',
                          value: false,
                        },
                      ]}
                      optionType="button"
                      buttonStyle="solid"
                      disabled={!isEdited || merchantContract.is_demo}
                      onChange={(event) => handleChangeConfig(config, event.target.value)}
                      value={currentValue}
                      defaultValue={currentValue}
                    />
                  </Item>
                );
              case 'Certificate':
                return (
                  <Item
                    id={`config-${config}`}
                    key={`config-${config}`}
                    label={config}
                    // configurations specific to paymentMethods are not considered as required
                    required={isRequired}
                    disabled={merchantContract.has_authorized_transactions}
                    tooltip={tooltipMessage}
                  >
                    <Select
                      allowClear
                      disabled={!isEdited}
                      onChange={(value) => handleChangeConfig(config, value)}
                      value={currentValue}
                      defaultValue={currentValue}
                      options={data?.merchantAccount?.certificates
                        ?.filter((certificate) => certificate.status === 'active')
                        ?.map((certificate) => ({
                          value: certificate.id,
                          label: certificate.name ?? `Not named certificate`,
                        }))}
                      loading={loading}
                    />
                  </Item>
                );
              default:
                return null;
            }
          })}
      </Form>
    </Block>
  );
};

export default ConnectionSettings;

import { gql } from '@apollo/client';

export const MerchantAccountViewQuery = gql`
  query MerchantAccountViewQuery($id: ID!) {
    merchantAccount(id: $id) {
      id
      name
      status
      cms_name
      cms_version
      authorize_ip_adresses
      integration_type
      authorize_ip_ranges
      accept_url
      decline_url
      pending_url
      exception_url
      source
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
      company {
        id
        name
        status
        legalForm
        country
        userCount
        users {
          id
          user {
            id
            full_name
            email
            avatar
          }
        }
        theme {
          logo
          icon
          primaryColor
          secondaryColor
        }
      }
      webhooks {
        __typename
        id
        name
        url
        trigger
        status
        notify_last_transaction_only
      }
      api_keys {
        id
        name
        is_public
        is_internal
        key
        status
      }
      merchant_contracts {
        source
        status
        id
        name
        payment_methods {
          id
        }
        partner {
          id
          name
          type
        }
      }
      users {
        id
        user {
          id
          email
          full_name
          status
          disabled
          avatar
        }
        aclProfile {
          id
          name
        }
        isGdpr
        guest
      }
      reference_currency
      attributeListRequiredByPartners
    }
  }
`;

export const MerchantAccountAuditLogsQuery = gql`
  query MerchantAccountAuditLogsQuery($id: ID!) {
    merchantAccount(id: $id) {
      id
      createdAt
      createdBy {
        id
        full_name
        email
        avatar
      }
      updatedAt
      updatedBy {
        id
        full_name
        email
        avatar
      }
      auditLogs {
        id
        action
        entityId
        entityName
        timestamp
        createdBy {
          email
          full_name
          avatar
        }
      }
    }
  }
`;

export const MerchantAccountUpdateMutation = gql`
  mutation MerchantAccountUpdateMutation($id: ID!, $input: MerchantAccountInfoInput!) {
    updateMerchantAccount(id: $id, input: $input) {
      id
      name
      cms_name
      cms_version
      authorize_ip_adresses
      integration_type
      authorize_ip_ranges
      accept_url
      decline_url
      pending_url
      exception_url
      source
    }
  }
`;

export default {};

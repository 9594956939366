import React from 'react';
import InfoTile, { InfoSkeletonRow } from '../../../Tile/Info/Info';

const BrowserInformationSkeleton = () => (
  <InfoTile name="browser-information" title="Browser information">
    <InfoSkeletonRow label="Accept header value" />
    <InfoSkeletonRow label="Color depth" />
    <InfoSkeletonRow label="Java enabled" />
    <InfoSkeletonRow label="JS enabled" />
    <InfoSkeletonRow label="Language" />
    <InfoSkeletonRow label="Screen height" />
    <InfoSkeletonRow label="Screen width" />
    <InfoSkeletonRow label="Time zone" />
  </InfoTile>
);

export default BrowserInformationSkeleton;

import { gql } from '@apollo/client';

export const metaProgramManagerCompanyAddressQuery = gql`
  query MetaProgramManagerCompanyAddress($id: ID!) {
    metaProgramManager(id: $id) {
      id
      company {
        country
        address
        city
        zipCode
      }
    }
  }
`;

import { gql } from '@apollo/client';

const query = `{
  _id
  transactions {
    _id
    id
    order_transaction_number
    status
    psp_transaction_id
    payin_partner
    merchant_contract {
      name
    }
    merchant_route {
      id
    }
    psp_token
    authentication_result
    authorization_code
    psp_result_code
    psp_result_description
  }
}`;

export const orderTransactionsProvidersDetailsQuery = gql`
  query OrderTransactions($orderId: ID!) {
    order(id: $orderId) ${query}
  }
`;


export const activityTransactionsProvidersDetailsQuery = gql`
  query ActivityTransactions($orderId: ID!) {
    activity(id: $orderId) ${query}
  }
`;

import React from 'react';
import moment from 'moment-timezone';
import styles from './Time.module.scss';
import { renderDate } from '../../../../../../Layout/Routes/Brainpower/OrderDetail/Tile/Table/Table';

const Time = ({ createdAt }) => {
  const diff = moment().diff(moment(createdAt), 'minutes');

  let timeText = renderDate(createdAt);
  if (diff <= 1) {
    timeText = 'Now';
  } else if (diff < 60) {
    timeText = `${diff} minutes ago`;
  } else if (diff < 60*24) {
    timeText = `${Math.round(diff / 60)} hour${Math.round(diff / 60) === 1 ? '' : 's'} ago`;
  } else if (diff > 60*24*1 && diff < 60*24*2) {
    timeText = 'Yesterday';
  } else if (diff < 60*24*7) {
    timeText = `${Math.round(diff / (60*24))} day${Math.round(diff / (60*24)) === 1 ? '' : 's'} ago`;
  }
  return (
    <div
      className={styles.root}
    >
      {timeText}
    </div>
  );
};

export default Time;
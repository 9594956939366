import { gql } from '@apollo/client';

export const createContractMutation = gql`
  mutation AddMerchantContract($id: ID!, $partner: ID!, $partnerType: String) {
    addMerchantContract(id: $id, input: { partner: $partner, name: "" }) {
      id
      merchant_contracts(
        filters: { partner: $partner, merchant_account: $id, status: { in: ["active", "inactive"] } }
      ) {
        id
        name
        source
      }
      partners(filters: { type: $partnerType }) {
        id
        name
      }
    }
  }
`;

export const partnersQuery = gql`
  query ActivePartners {
    partners (filters: {status: "active"}) {
      id
      name
      type
      status
      allow_insights
      allow_insights_pos
      allow_stack
    }
  }
`;

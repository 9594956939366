import SharedFieldTypes from 'norbr-shared-lib/constants/order/fields/fieldTypes';
import { fieldTypes } from '../../Brainpower/Common/constants/fields';
import { TargetEntity } from '../../Brainpower/Common/constants/targetEntities';
import FieldTypes from '../../Brainpower/Common/constants/fieldTypes';
import { companyStatuses, companyStatusList } from './constants';
import {MODES} from '../../../../contexts/mode.context';

export const Fields = {
  META_PROGRAM_MANAGER: 'meta_program_manager',
  META_PROGRAM_MANAGER_NAME: 'meta_program_manager_name',
  PROGRAM_MANAGER: 'program_manager',
  PROGRAM_MANAGER_NAME: 'program_manager_name',
  COMPANY: 'company',
  COMPANY_NAME: 'company_name',
  CREATION_DATE: 'creation_date',
  COUNTRY: 'country',
  STATUS: 'status',
  USER_COUNT: 'user_count',
  MERCHANT_ACCOUNT_COUNT: 'merchant_account_count',
  ACTIVITY: 'activity',
  MCC: 'mcc',
};

const fields = (mode) =>  ({
  ...(mode === MODES.INTERNAL
    ? {
        [Fields.META_PROGRAM_MANAGER]: {
          key: Fields.META_PROGRAM_MANAGER,
          label: 'Meta program manager',
          type: SharedFieldTypes.META_PROGRAM_MANAGER,
          entity: TargetEntity.MERCHANT_COMPANY,
          path: 'programManager.metaProgramManager.id',
          description: 'The meta program manager of the company.',
          sortField: Fields.META_PROGRAM_MANAGER_NAME,
          filter: true,
          ...fieldTypes[SharedFieldTypes.META_PROGRAM_MANAGER],
        },
        [Fields.META_PROGRAM_MANAGER_NAME]: {
          key: Fields.META_PROGRAM_MANAGER_NAME,
          label: 'Meta program manager name',
          type: SharedFieldTypes.TEXT,
          entity: TargetEntity.MERCHANT_COMPANY,
          path: 'programManager.metaProgramManager.name',
          description: 'The name of the meta program manager.',
          filter: false,
          ...fieldTypes[SharedFieldTypes.TEXT],
        },
      }
    : {}),
  ...([MODES.INTERNAL, MODES.META_PM].includes(mode)
    ? {
        [Fields.PROGRAM_MANAGER]: {
          key: Fields.PROGRAM_MANAGER,
          label: 'Program manager',
          type: SharedFieldTypes.PROGRAM_MANAGER,
          entity: TargetEntity.MERCHANT_COMPANY,
          path: 'programManager.id',
          description: 'The program manager of the company.',
          sortField: Fields.PROGRAM_MANAGER_NAME,
          filter: true,
          ...fieldTypes[SharedFieldTypes.PROGRAM_MANAGER],
        },
        [Fields.PROGRAM_MANAGER_NAME]: {
          key: Fields.PROGRAM_MANAGER_NAME,
          label: 'Program manager name',
          type: SharedFieldTypes.TEXT,
          entity: TargetEntity.MERCHANT_COMPANY,
          path: 'programManager.name',
          description: 'The name of the program manager.',
          filter: false,
          ...fieldTypes[SharedFieldTypes.TEXT],
        },
      }
    : {}),
  ...([MODES.INTERNAL, MODES.META_PM, MODES.PM].includes(mode)
    ? {
        [Fields.COMPANY]: {
          key: Fields.COMPANY,
          label: 'Company',
          type: SharedFieldTypes.COMPANY,
          entity: TargetEntity.MERCHANT_COMPANY,
          path: 'id',
          description: 'The company of the merchant account.',
          sortField: Fields.COMPANY_NAME,
          filter: true,
          ...fieldTypes[SharedFieldTypes.COMPANY],
        },
        [Fields.COMPANY_NAME]: {
          key: Fields.COMPANY_NAME,
          label: 'Company name',
          type: SharedFieldTypes.TEXT,
          entity: TargetEntity.MERCHANT_COMPANY,
          path: 'name',
          description: 'The name of the company.',
          filter: false,
          ...fieldTypes[SharedFieldTypes.TEXT],
        },
      }
    : {}),
  [Fields.CREATION_DATE]: {
    key: Fields.CREATION_DATE,
    label: 'Creation date',
    type: SharedFieldTypes.DATE,
    entity: TargetEntity.MERCHANT_COMPANY,
    path: 'createdAt',
    description: 'The creation date of the company.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.DATE],
  },
  [Fields.USER_COUNT]: {
    key: Fields.USER_COUNT,
    label: 'User count',
    type: SharedFieldTypes.COUNT,
    entity: TargetEntity.MERCHANT_COMPANY,
    path: 'userCount',
    description: 'The count of users on the company.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.COUNT],
  },
  [Fields.MERCHANT_ACCOUNT_COUNT]: {
    key: Fields.MERCHANT_ACCOUNT_COUNT,
    label: 'Merchant account count',
    type: SharedFieldTypes.COUNT,
    entity: TargetEntity.MERCHANT_COMPANY,
    path: 'merchantAccountCount',
    description: 'The count of merchant accounts on the company.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.COUNT],
  },
  [Fields.COUNTRY]: {
    key: Fields.COUNTRY,
    label: 'Country',
    type: SharedFieldTypes.COUNTRY,
    entity: TargetEntity.MERCHANT_COMPANY,
    path: 'country',
    description: 'The country of the company.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.COUNTRY],
  },
  [Fields.ACTIVITY]: {
    key: Fields.ACTIVITY,
    label: 'Activity',
    type: SharedFieldTypes.TEXT,
    entity: TargetEntity.MERCHANT_COMPANY,
    path: 'activity',
    description: 'The activity of the company.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.TEXT],
  },
  [Fields.MCC]: {
    key: Fields.MCC,
    label: 'MCC',
    type: SharedFieldTypes.TEXT,
    entity: TargetEntity.MERCHANT_COMPANY,
    path: 'mcc',
    description: 'The MCC (Merchant Category Code) of the company.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.TEXT],
  },
  [Fields.STATUS]: {
    key: Fields.STATUS,
    label: 'Status',
    entity: TargetEntity.MERCHANT_COMPANY,
    path: 'status',
    description: 'The status of the company.',
    filter: true,
    type: FieldTypes.LIST,
    filterOptions: {
      options: companyStatusList,
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => companyStatuses[value],
      tag: true,
    },
  },
});
export default fields;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { useCan } from '../../../contexts/ability.context';
import useNavigateWithSearch from '../../../util/navigate';
import styles from '../Menu.module.scss';
import Brainpower from './Brainpower';
import Merchants from './Merchants';
import Partners from './Partners';
import ProgramManagers from './ProgramManagers';
import MetaProgramManagers from './MetaProgramManagers';
import MetaProgramManagerSettings from './MetaProgramManagerSettings';
import Internal from './Internal';
import ProgramManagerSettings from './ProgramManagerSettings';
import { useMode, MODES } from '../../../contexts/mode.context';

const AdminMenu = () => {
  const can = useCan();
  const navigate = useNavigateWithSearch();
  const location = useLocation();
  const mode = useMode();

  const handleClick = ({ key, domEvent }) => {
    if (domEvent.ctrlKey || domEvent.metaKey) {
      window.open(key);
    } else {
      navigate(key);
    }
  };

  const [, module, page] = location.pathname.split('/');

  let items = [];

  switch (mode) {
    case MODES.INTERNAL:
      items = [
        ...Brainpower(can),
        ...Merchants(can),
        ...Partners(can),
        ...ProgramManagers(can),
        ...MetaProgramManagers(can),
        ...Internal(can),
      ];
      break;
    case MODES.META_PM:
      items = [...Brainpower(can), ...Merchants(can), ...ProgramManagers(can), ...MetaProgramManagerSettings(can)];
      break;
    case MODES.PM:
      items = [...Brainpower(can), ...Merchants(can), ...ProgramManagerSettings(can)];
      break;
    default:
      console.error('No mode issue');
      items = [];
  }

  return (
    <Menu
      mode="inline"
      theme="dark"
      selectedKeys={[`/${module}`, `/${module}/${page}`]}
      onClick={handleClick}
      className={styles.menu}
      items={items}
    />
  );
};

export default AdminMenu;

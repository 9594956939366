import { gql } from '@apollo/client';

export const ProgramManagerQuery = gql`
  query ProgramManagerQuery($programManager: ID!) {
    programManager(id: $programManager) {
      __typename
      id
      name
      merchantGroups {
        id
        name
        description
        merchantCompanyCount
        merchantAccountCount
      }
      theme {
        icon
        primaryColor
        primaryColor
        secondaryColor
      }
    }
  }
`;

export const MerchantGroupCreateMutation = gql`
  mutation MerchantGroupCreate($input: MerchantGroupCreationInput!) {
    createMerchantGroup(input: $input) {
      id
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
      merchantCompanies {
        id
        name
        theme {
          primaryColor
        }
      }
      merchantCompanyCount
      merchantAccounts {
        id
        name
      }
      merchantAccountCount
      userCount
      createdBy {
        id
        full_name
        avatar
      }
      createdAt
    }
  }
`;

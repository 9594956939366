import React, { useState } from 'react';
import { Badge, Button, message, Switch, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import TableTile, { getColumnFilterSearchInput } from '../../../../Brainpower/OrderDetail/Tile/Table/Table';
import ProgramManagerApiKeyCreate from './ProgramManagerApiKeyCreate/ProgramManagerApiKeyCreate';
import { ProgramManagerApiKeysQuery, ProgramManagerApiKeyUpdateMutation } from './query';
import { components } from '../../../../Common/EditableCell/EditableCell';
import { useCan } from '../../../../../../contexts/ability.context';
import { middleEllipsis, search } from '../../../../../../util/string';
import { compareByProp } from '../../../../../../util/array';

const ProgramManagerApiKeys = ({ id }) => {
  const can = useCan();

  const { data, loading } = useQuery(ProgramManagerApiKeysQuery, { variables: { programManagerId: id } });

  const [visible, setVisible] = useState(false);

  const showCreationModal = () => {
    setVisible(true);
  };

  const hideCreationModal = () => {
    setVisible(false);
  };

  const [update, updateState] = useMutation(ProgramManagerApiKeyUpdateMutation, {
    onCompleted: () => {
      message.success('Api key successfully updated');
    },
    onError: (err) => message.error(err.message),
  });

  const dataSource = data?.programManager.apiKeys
    ?.map((apiKey) => ({
      key: apiKey.id,
      name: apiKey.name,
      keyString: apiKey.key,
      active: apiKey.status === 'active',
    }))
    .sort(compareByProp('name', 'asc', 'string'));

  const handleSave = (row) => {
    update({
      variables: {
        id: row.key,
        input: {
          name: row.name,
          status: row.active ? 'active' : 'inactive',
        },
      },
    });
  };

  const columns = [
    {
      title: 'Label',
      dataIndex: 'name',
      key: 'name',
      render: (label, { active }) => <Badge status={active ? 'success' : 'warning'} text={label} />,
      ...(can('update', 'api_key')
        ? {
            width: '30%',
            editable: can('update', 'program-manager-api-keys'),
            onCell: (record) => ({
              title: 'Label',
              dataIndex: 'name',
              editable: true,
              record,
              handleSave,
            }),
          }
        : {}),
      ...getColumnFilterSearchInput((value, record) => search(value, record.name)),
      sorter: compareByProp('name', 'asc', 'string'),
    },
    {
      title: 'Token',
      dataIndex: 'keyString',
      key: 'keyString',
      render: (value) =>
        value &&
        typeof value === 'string' && (
          <Typography.Text copyable={{ text: value }}>
            {middleEllipsis(value, 30)}
          </Typography.Text>
        ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active, row) => (
        <Switch
          checked={active}
          disabled={!can('update', 'program-manager-api-keys')}
          title={active ? 'Active' : 'Inactive'}
          onChange={(value) => handleSave({ ...row, active: value })}
        />
      ),
      filters: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
      onFilter: (value, record) => value === record.active,
      fixed: 'right',
      width: 100,
    },
  ];

  // return info tile
  return (
    <>
      <TableTile
        name="program-manager-api-keys"
        title="Api Keys"
        description="The API key acts as a unique identifier and a secret token for authentication, and have a set of access rights on the API associated with it."
        columns={columns}
        dataSource={dataSource}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 5,
          pageSizeOptions: [5, 10, 20, 50, 100],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} keys`,
        }}
        size="small"
        extra={[
          can('create', 'program-manager-api-keys') && (
            <Button key="add-api-key" icon={<PlusOutlined />} onClick={showCreationModal}>
              Api Key
            </Button>
          ),
        ]}
        components={components}
        rowClassName={can('update', 'api_key') && 'editable-row'}
        loading={loading || updateState.loading}
      />
      <ProgramManagerApiKeyCreate visible={visible} onClose={hideCreationModal} id={id} />
    </>
  );
};

export default ProgramManagerApiKeys;

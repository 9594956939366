import React from 'react';
import { Avatar, Button, Modal, Space } from 'antd';
import moment from 'moment-timezone';
import { BgColorsOutlined, EditOutlined, ExclamationCircleOutlined, LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import InfoTile from '../../../Brainpower/OrderDetail/Tile/Info/Info';
import { useCan } from '../../../../../contexts/ability.context';
import { SEND_PASSWORD_RESET_EMAIL } from '../../../../../App/AppUnlogged/RecoverPassword/RecoverPassword';
import useToggle from '../../../../../hooks/useToggle';
import ThemeEditor from '../../../../ThemeEditor/ThemeEditor';
import { useLogout } from '../../../../../contexts/app.context';
import { useMe } from '../../../../../contexts/me.context';
import { Link } from '../../../../../util/navigate';
import { initials } from '../../../../../util/string';
import styles from './UserCard.module.scss';

const UserCard = () => {
  const me = useMe();
  const can = useCan();

  const [isThemeEditorOpened, { toggle: toggleThemeEditor }] = useToggle(false);

  const logout = useLogout();
  const [sendPasswordResetEmail] = useMutation(SEND_PASSWORD_RESET_EMAIL);

  const handleClickResetPassword = () => {
    Modal.confirm({
      title: 'Are you sure to reset the password?',
      icon: <ExclamationCircleOutlined />,
      content: `You will logout and receive an email at ${me.email} to reset your password.`,
      okText: 'Apply and proceed',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        sendPasswordResetEmail({ variables: { email: me.email }, refetchQueries: ['UserMe'] });
        logout();
      },
    });
  };

  return (
    <InfoTile className={styles.root} name="user-card">
      <div className={styles.avatarHolder}>
        <div className={styles.hello}>Hello, {me.full_name}</div>
        <Avatar size={100} shape="circle" src={me.avatar}>
          {initials(me.full_name)}
        </Avatar>
        <div className={styles.info}>{me.email}</div>
        {(() => {
          if (me.type === 'ProgramManagerUser' && can('read', 'program-manager-access-control')) {
            return <Link to={`/program-access-control-list/${me.aclProfile.id}`}>{me.aclProfile.name}</Link>;
          }
          if (me.type === 'InternalUser' && can('update', 'internal-access-control')) {
            return <Link to={`/internal-access-control-list/${me.aclProfile.id}`}>{me.aclProfile.name}</Link>;
          }
          return <div>{me.aclProfile.name}</div>;
        })()}
        <div className={styles.info}>
          Previous connection : {me.previousConnection ? moment(me.previousConnection).calendar() : 'None'}
        </div>
      </div>
      <Space>
        {can('read', 'internal_user') && (
          <Link key="link-user" to={`/internal-users/${me.id}`}>
            <Button
              size="large"
              type="text"
              shape="circle"
              title="Edit your profile"
              icon={<EditOutlined />}
              className={styles.actionButton}
            />
          </Link>
        )}
        <Button
          shape="circle"
          size="large"
          type="text"
          onClick={toggleThemeEditor}
          icon={<BgColorsOutlined />}
          title="Edit theme"
          className={styles.actionButton}
        />
        <Button
          shape="circle"
          size="large"
          type="text"
          onClick={handleClickResetPassword}
          icon={<LockOutlined />}
          title="Reset password"
          className={styles.actionButton}
        />
      </Space>
      <ThemeEditor isOpen={isThemeEditorOpened} handleClose={toggleThemeEditor} />
    </InfoTile>
  );
};

export default UserCard;

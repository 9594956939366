import { gql, useQuery } from '@apollo/client';
import { unflattenByProp } from '../../util/array';

export const PaymentMethodsQuery = gql`
  {
    paymentMethods {
      id
      api_name
      name
      imgUrl
    }
  }
`;

export const usePaymentMethodList = () => {
  const { data } = useQuery(PaymentMethodsQuery);
  if (!data) return [];
  return data.paymentMethods;
};

const usePaymentMethods = () => {
  const paymentMethodList = usePaymentMethodList();
  return unflattenByProp(paymentMethodList, 'api_name');
};

export default usePaymentMethods;

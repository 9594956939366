import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Col, Form, message, Row, Space, Switch } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import InfoTile, { InfoBooleanRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { PartnerUpdateMutation } from '../query';
import styles from '../PartnerView.module.scss';
import { useCan } from '../../../../../../contexts/ability.context';

const AuthenticationOptions = ({ partner }) => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);

  const [updatePartner, { loading }] = useMutation(PartnerUpdateMutation, {
    onCompleted: () => {
      message.success('Partner successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    updatePartner({
      variables: { id: partner.id, input: values },
    });
  };

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="authentication-options" title="Authentication options">
        <Form
          form={form}
          title="Authentication options"
          layout="vertical"
          size="small"
          initialValues={partner}
          onFinish={handleFinish}
          requiredMark={false}
          className="tile-form"
        >
          <Form.Item
            label="Supports authentication"
            name="support_authentication"
            valuePropName="checked"
            initialValue={false}
          >
            <Switch>Supports authentication</Switch>
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  return (
    <InfoTile
      name="authentication-options"
      title="Authentication options"
      extra={[
        can('update', 'partner') && (
          <Button key="update-partner" onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        ),
      ]}
    >
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <InfoBooleanRow label="Supports authentication" value={partner.support_authentication} />
        </Col>
      </Row>
    </InfoTile>
  );
};

export default AuthenticationOptions;

import React from 'react';
import { Tag } from 'antd';
import FilterTag from './FilterTag';

const NumberFilterTag = ({ value, ...filterTagProps }) => {
  if (value === '__NULL__')
    return (
      <FilterTag {...filterTagProps}>
        <Tag>Null</Tag>
      </FilterTag>
    );
  const { min, max } = value;
  return (
    <FilterTag {...filterTagProps}>
      {min != null && max != null && `${min} to ${max}`}
      {min != null && max == null && `greater than ${min}`}
      {min == null && max != null && `lower than ${max}`}
    </FilterTag>
  );
};

export default NumberFilterTag;

export const PartnerStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const partnerStatuses = {
  [PartnerStatuses.ACTIVE]: {
    value: PartnerStatuses.ACTIVE,
    badge: 'success',
    color: 'green',
    label: 'Active',
  },
  [PartnerStatuses.INACTIVE]: {
    value: PartnerStatuses.INACTIVE,
    badge: 'default',
    color: 'lightgrey',
    label: 'Initialized',
  },
};

export const partnerStatusList = Object.values(partnerStatuses);

export const PartnerTypes = {
  RISK_ASSESSMENT: 'risk_assessment',
  PAYIN: 'payin',
  PAYOUT: 'payout',
  AUTHENTICATION: 'authentication',
  CASHIER: 'cashier',
};

export const partnerTypes = {
  [PartnerTypes.PAYIN]: {
    value: PartnerTypes.PAYIN,
    label: 'Payin',
    color: '#2B4B6F',
  },
  [PartnerTypes.PAYOUT]: {
    value: PartnerTypes.PAYOUT,
    label: 'Payout',
    color: '#257059',
  },
  [PartnerTypes.AUTHENTICATION]: {
    value: PartnerTypes.AUTHENTICATION,
    label: 'Authentication',
    color: '#AA7D39',
  },
  [PartnerTypes.RISK_ASSESSMENT]: {
    value: PartnerTypes.RISK_ASSESSMENT,
    label: 'Risk assessment',
    color: '#AA6039',
  },
  [PartnerTypes.CASHIER]: {
    value: PartnerTypes.CASHIER,
    label: 'Cashier',
    color: '#11B0B9',
  },
};

export const partnerTypeList = Object.values(partnerTypes);

export const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
};

export const httpMethods = {
  [HttpMethods.GET]: {
    value: HttpMethods.GET,
    label: 'GET',
  },
  [HttpMethods.POST]: {
    value: HttpMethods.POST,
    label: 'POST',
  },
};

export const httpMethodList = Object.values(httpMethods);

export default {};

import React, { useLayoutEffect, useMemo } from 'react';
import { Button, Modal, Row, Space } from 'antd';
import { useQuery as useApolloQuery } from '@apollo/client';
import moment from 'moment-timezone';
import * as am4charts from '@amcharts/amcharts4/charts';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import * as am4core from '@amcharts/amcharts4/core';
import { brainpowerQuery } from '../Common/query';
import useSearchFilters from '../../../hooks/useSearchFilters';
import { DATETIME2_FORMAT } from '../../../../../../constants/DATES';
import { getPreviousDateRange, periodTypeToCustom } from '../../../../../../util/date';
import useBaseCurrency from '../../../hooks/useBaseCurrency';
import styles from './chart.module.scss';
import useBaseGranularity from '../../../hooks/useBaseGranularity';
import { useCan } from '../../../../../../contexts/ability.context';
import useToggle from '../../../../../../hooks/useToggle';
import { useProgramManager } from '../../../../../../contexts/programManager.context';
import LoadingChart from '../Common/LoadingChart';
import ErrorChart from '../Common/ErrorChart';
import NoDataChart from '../Common/NoDataChart';

const generateMatcherDiscrepanciesAnalysisChart = (divId, widget, data) => {
  // Create chart
  const chart = am4core.create(divId, am4charts.TreeMap);
  chart.data = data;
  // only one level visible initially
  chart.maxLevels = 1;
  // define data fields
  chart.dataFields.value = 'value';
  chart.dataFields.name = 'name';
  chart.dataFields.color = 'color';
  chart.dataFields.children = 'children';
  chart.numberFormatter.numberFormat = '#.';

  // enable navigation
  chart.navigationBar = new am4charts.NavigationBar();

  const labelBullet = new am4charts.LabelBullet();
  labelBullet.locationX = 0.5;
  labelBullet.locationY = 0.5;
  labelBullet.label.text = '{name}: {value} ({percent}%)';
  labelBullet.label.fill = am4core.color('#ffffff');

  // level 0 series template
  const level0SeriesTemplate = chart.seriesTemplates.create('0');
  level0SeriesTemplate.bullets.push(labelBullet);
  level0SeriesTemplate.tooltip.disabled = true;
  // level1 series template
  const level1SeriesTemplate = chart.seriesTemplates.create('1');
  level1SeriesTemplate.bullets.push(labelBullet);
  level1SeriesTemplate.tooltip.disabled = true;

  return chart;
};

const MatcherDiscrepanciesAnalysis = (props) => {
  const { widget } = props;

  const can = useCan();
  const [baseCurrency] = useBaseCurrency();
  const [baseGranularity] = useBaseGranularity();

  const { dateFilter, list } = useSearchFilters();

  const parameters = {
    widget: widget.options?.kpi,
    filters: list.map((filter) => {
      if (filter.key === dateFilter.key) {
        return {
          dates: filter.value.period
            ? periodTypeToCustom(filter.value.period)
            : {
                from: moment(filter.value.from, DATETIME2_FORMAT).startOf('minute'),
                to: moment(filter.value.to, DATETIME2_FORMAT).endOf('minute'),
                ...getPreviousDateRange(
                  moment(filter.value.from, DATETIME2_FORMAT),
                  moment(filter.value.to, DATETIME2_FORMAT),
                ),
              },
        };
      }
      return {
        name: filter.key,
        value: filter.value,
        excluded: filter.excluded,
      };
    }),
    granularity: baseGranularity,
    currency: baseCurrency,
    display_user: can('read', 'user') && widget.options?.display_user,
  };

  const { data, loading, error } = useApolloQuery(brainpowerQuery, {
    variables: {
      service: 'matcher_discrepancies_analysis',
      parameters,
    },
  });

  const dataSource = useMemo(() => {
    if (!data?.brainpower?.data) return [];
    return Object.values(
      data?.brainpower?.data.reduce((memo, row) => {
        if (row.store === 'No discrepancy') return [{ name: 'No discrepancy', color: '#389e0d', value: 1 }];
        return {
          ...memo,
          [row.discrepancy]: {
            name: row.discrepancy,
            value: (memo[row.discrepancy]?.value ?? 0) + row.volume,
            children: [
              ...(memo[row.discrepancy]?.children ?? []),
              {
                name: row.store,
                value: row.volume,
              },
            ],
          },
        };
      }, {}),
    );
  }, [data]);

  const isEmpty = !(data?.brainpower?.data && Object.keys(data?.brainpower?.data).length > 0);

  const [isFullscreen, { toggle: toggleFullscreen }] = useToggle(false);

  const programManager = useProgramManager();

  useLayoutEffect(() => {
    if (!isEmpty) {
      const chart = generateMatcherDiscrepanciesAnalysisChart(`chartdiv-${widget.i}`, widget, dataSource, {
        currency: baseCurrency,
        granularity: baseGranularity,
        programManagerName: programManager?.displayName ?? 'NORBr',
      });

      let fullscreenChart;
      if (isFullscreen) {
        fullscreenChart = generateMatcherDiscrepanciesAnalysisChart(
          `fullscreen-chartdiv-${widget.i}`,
          widget,
          dataSource,
          {
            currency: baseCurrency,
            granularity: baseGranularity,
            programManagerName: programManager?.displayName ?? 'NORBr',
          },
        );
      }

      return () => {
        chart.dispose();
        if (fullscreenChart) fullscreenChart.dispose();
      };
    }
    return () => {};
  }, [isEmpty, dataSource, widget.options, isFullscreen]);

  let content;
  if (loading && isEmpty) {
    content = <LoadingChart />;
  } else if (error) {
    content = <ErrorChart error={error} />;
  } else if (isEmpty) {
    content = <NoDataChart />;
  } else {
    content = <div id={`chartdiv-${widget.i}`} className={styles.chartdiv} />;
  }

  return (
    <div className={styles.root}>
      <Row justify="space-between" align="middle">
        <div className={styles.title}>{widget.name}</div>
        <Space size={2}>
          <Button type="text" icon={<FullscreenOutlined />} onClick={toggleFullscreen} />
        </Space>
      </Row>
      {content}
      <Modal
        open={isFullscreen}
        footer={null}
        closable={false}
        width="95%"
        destroyOnClose
        onCancel={toggleFullscreen}
        forceRender
        bodyStyle={{ height: '95vh', padding: '8px 12px' }}
        className={styles.modal}
      >
        <Row justify="space-between" align="middle">
          <div className={styles.title}>{widget.name}</div>
          <Space>
            <Button type="text" icon={<FullscreenExitOutlined />} onClick={toggleFullscreen} />
          </Space>
        </Row>
        <div id={`fullscreen-chartdiv-${widget.i}`} className={styles.fullscreenChartdiv} />
      </Modal>
    </div>
  );
};

export default MatcherDiscrepanciesAnalysis;

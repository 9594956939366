import { useEffect, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import useSearchFilters from '../../hooks/useSearchFilters';
import usePagination from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';

export const notificationListQuery = gql`
  query Notifications($offset: Int, $limit: Int, $filters: [SearchFilter], $sort: [SearchSort]) {
    notifications(offset: $offset, limit: $limit, filters: $filters, sort: $sort)
    notificationsCount(filters: $filters)
  }
`;

const useNotificationListQuery = () => {
  const { offset, limit } = usePagination();
  const { list } = useSearchFilters();
  const [sort] = useSort();

  const [fetch, { data, previousData, loading, error }] = useLazyQuery(notificationListQuery, {
    variables: {
      offset,
      limit,
      filters: list,
      sort,
    },
  });

  const [result, total] = useMemo(
    () => [
      (loading ? previousData?.notifications : data?.notifications) ?? [],
      (loading ? previousData?.notificationsCount : data?.notificationsCount) ?? 0,
    ],
    [data, loading, previousData],
  );

  useEffect(() => {
    fetch();
  }, [offset, limit]);

  return { result, total, loading, error };
};

export default useNotificationListQuery;

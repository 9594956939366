import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { config as indicatorsConfig } from '../../constants/indicators';

export default (divId, widget, data, args) => {
  const { indicators, options, id } = widget;

  const indicatorList = indicators.map((i) => {
    if (indicatorsConfig[i]) return indicatorsConfig[i];
    throw new Error(`Indicator ${i} does not exist.`, id);
  });

  // Create chart
  const chart = am4core.create(divId, am4charts.SlicedChart);
  chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
  chart.numberFormatter.numberFormat = '#.';

  chart.data = indicatorList.map((indicator) => ({
    name: indicator.label,
    value: data.data[0][indicator.id],
  }));

  const series = chart.series.push(new am4charts.FunnelSeries());
  series.colors.step = 2;
  series.dataFields.value = 'value';
  series.dataFields.category = 'name';
  series.bottomRatio = 1;
  series.slices.template.tooltipText = "{category}: {value.value}";

  if (options?.orientation === 'vertical') {
    series.orientation = 'vertical';
    series.alignLabels = true;
    series.labels.template.text = '{name} : {value.value}'; // TODO - unit ?
  } else {
    series.orientation = 'horizontal';
    series.labels.template.disabled = true;
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.text = '{name} : {value.value}'; // TODO - unit ?
    chart.legend.valueLabels.template.disabled = true;
    // chart.legend.valueLabels.template.text = ": {value.value}";
  }

  return chart;
};

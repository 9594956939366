import React, { useEffect, useState } from 'react';
import { Popover, Space, Typography } from 'antd';
// https://casesandberg.github.io/react-color
import { TwitterPicker } from 'react-color';
import classnames from 'classnames';
import styles from './ColorPicker.module.scss';
import { panelColors } from '../../../util/theme';

const { Text } = Typography;

const ColorPicker = ({ onChange, value = '#A1A1A1', label, description, disabled, copyable = true }) => {
  const [color, setColor] = useState(value);
  const [visible, setVisible] = useState();

  const handleChange = ({ hex }) => {
    setColor(hex);
  };

  useEffect(() => setColor(value), [value]);

  useEffect(() => {
    if (visible === false) {
      onChange(color);
    }
  }, [visible]);

  return (
    <div>
      <Space>
        <Popover
          title={`Pick ${label ?? 'color'}`}
          placement="rightTop"
          content={
            <div className={styles.picker}>
              <TwitterPicker color={color ?? '#A1A1A1'} onChangeComplete={handleChange} colors={panelColors} triangle="hide" />
            </div>
          }
          trigger="click"
          open={!disabled && visible}
          onOpenChange={setVisible}
        >
          <div
            className={classnames(styles.trigger, { [styles.disabled]: disabled })}
            style={color ? {
                backgroundColor: color
              } : {
                background: 'repeating-linear-gradient(45deg, #d6d6d6, #d6d6d6 10px, #e7e7e7 10px, #e7e7e7 20px)',
            }}
          />
        </Popover>
        <div>{label}</div>
        <Text className={styles.copyableLabel} copyable={copyable}>
          {color || 'No color selected'}
        </Text>
      </Space>
      <div className={styles.description}>{description}</div>
    </div>
  );
};
export default ColorPicker;

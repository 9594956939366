import { gql } from '@apollo/client';

export const accountPartnersQuery = gql`
  query MerchantAccount($id: ID!, $type: String!) {
    merchantAccount(id: $id) {
      id
      source
      partners(filters: { type: $type }) {
        id
        name
        allow_stack
        allow_insights
        allow_insights_pos
      }
    }
  }
`;

export const merchantAccountContractsQuery = gql`
  query MerchantAccount($id: ID!) {
    merchantAccount(id: $id) {
      id
      merchant_contracts(filters: { status: { in: ["active", "inactive"] } }) {
        id
        name
        partner {
          id
          name
        }
      }
    }
  }
`;

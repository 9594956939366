import React, { useState } from 'react';
import { Button, Divider, Form, Input, Modal, Row, Select, Space, Tag, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CreditCardPlusIcon from '@2fd/ant-design-icons/lib/CreditCardPlus';
import { BaseRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import styles from '../PartnerView.module.scss';
import { httpMethodList } from '../../constants';
import usePaymentMethods from '../../../../../../hooks/common/usePaymentMethods';
import { searchByProp } from '../../../../../../util/string';
import { validateTrimmedValue } from '../../../../Common/Form/utils';

const SpecificEndpoints = ({ partner, baseForm, name: index, value = {}, onChange }) => {
  const paymentMethods = usePaymentMethods();

  const [open, setOpen] = useState(false);

  const baseUrl = Form.useWatch('base_url', baseForm);
  const baseEndpoint = Form.useWatch(['endpoints', index], baseForm) ?? {};

  const [form] = Form.useForm();
  const handleFinish = (values) => {
    const result = values.specificEndpoints.reduce((memo, spe) => ({ ...memo, [spe.payment_method]: spe }), {});
    onChange(result);
    setOpen(false);
  };

  return (
    <>
      <Space>
        {Object.values(value).map((spe) => (
          <img src={paymentMethods[spe.payment_method]?.imgUrl} alt="" style={{ width: 24 }} />
        ))}
        <Button
          title="Add specific endpoints by payment method."
          icon={<CreditCardPlusIcon />}
          onClick={() => setOpen(true)}
          type="text"
        />
      </Space>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={baseEndpoint.name}
        onOk={form.submit}
        width="80%"
        destroyOnClose
      >
        <Typography.Paragraph>
          Add specific endpoints by payment method to override the default endpoint.
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Space>
            <Tag className={styles.methodTag}>{baseEndpoint.method}</Tag>
            {baseUrl}
            {baseEndpoint.value}
          </Space>
        </Typography.Paragraph>
        <Form form={form} onFinish={handleFinish}>
          <Form.List
            name="specificEndpoints"
            initialValue={Object.values(value)}
            rules={[
              {
                // eslint-disable-next-line consistent-return
                validator: async (_, specificEndpoints) => {
                  // check endpoint with the same paymentMethod
                  const spePm = specificEndpoints.map((spe) => spe?.payment_method);
                  if (new Set(spePm).size !== spePm.length) {
                    return Promise.reject(new Error('Payment method should be unique.'));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => [
              ...fields.map(({ key, name, ...restField }) => (
                <Row key={key}>
                  <div style={{ display: 'inline-flex', gap: 8, width: '100%' }}>
                    <Form.Item
                      name={[name, 'payment_method']}
                      rules={[{ required: true, message: 'Payment method is required.' }]}
                    >
                      <Select
                        showSearch
                        filterOption={searchByProp('label')}
                        style={{ width: 200 }}
                        placeholder="Payment method"
                      >
                        {partner.payment_methods.map((pm) => (
                          <Select.Option key={pm.id} value={pm.id} label={pm.name}>
                            <Space>
                              <img src={pm.imgUrl} alt={pm.name} width={24} />
                              {pm.name}
                            </Space>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'method']}>
                      <Select
                        style={{ width: 100 }}
                        options={httpMethodList}
                        allowClear
                        placeholder={baseEndpoint.method}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ flex: 1 }}
                      {...restField}
                      name={[name, 'base_url']}
                      rules={[{ validator: validateTrimmedValue }]}
                    >
                      <Input placeholder={baseUrl || 'Base URL'} allowClear />
                    </Form.Item>
                    <Form.Item
                      style={{ flex: 1 }}
                      {...restField}
                      name={[name, 'value']}
                      rules={[{ validator: validateTrimmedValue }]}
                    >
                      <Input placeholder={baseEndpoint.value} allowClear />
                    </Form.Item>
                    <Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} title="Remove endpoint" />
                    </Form.Item>
                  </div>
                </Row>
              )),
              <Form.Item key="add-endpoint">
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add specific endpoint
                </Button>
              </Form.Item>,
              <Form.ErrorList errors={errors} />,
            ]}
          </Form.List>
        </Form>
        <BaseRow label="Variables">
          <Typography.Text copyable>PSP_TRANSACTION_ID</Typography.Text>
          <Divider type="vertical" />
          <Typography.Text copyable>PSP_OPERATION_ID</Typography.Text>
        </BaseRow>
      </Modal>
    </>
  );
};
export default SpecificEndpoints;

import React from 'react';
import { TreeSelect } from 'antd';
import styles from './TreeSelectBranchInput.module.scss';
import Loader from '../../../../../../../Common/Loader/Loader';

const { SHOW_CHILD } = TreeSelect;

const TreeSelectBranchInput = ({
  isLoading,
  onChange,
  options,
  placeholder,
  treeCheckable,
  value,
}) => (
      <TreeSelect
        autoFocus
        treeData={options}
        className={styles.select}
        placeholder={placeholder}
        maxTagCount={10}
        dropdownRender={(node) => (isLoading ? <Loader size="large" /> : node)}
        value={value}
        onChange={onChange}
        treeNodeFilterProp="search"
        treeCheckable={treeCheckable}
        showCheckedStrategy={SHOW_CHILD}
        placement='topLeft'
        listHeight={210}
      />
  )

export default TreeSelectBranchInput;

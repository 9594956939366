import { gql } from '@apollo/client';

export const teamUsersQuery = gql`
  query TeamUsersQuery {
    teamUsers {
      id
      full_name
      avatar
    }
  }
`;

export default {};

import { gql } from '@apollo/client';

export const removeBranchSplitterMutation = gql`
    mutation RemoveBranchSplitter($id: ID!, $branchId: ID!) {
        removeBranchSplitter(id: $id, branchId: $branchId) {
            id
            branches {
                id
            }
        }
    }
`;

export const addBranchSplitterMutation = gql`
    mutation AddBranchSplitter($id: ID!, $input: SplitterBranchInput!) {
        addBranchSplitter(id: $id, input: $input) {
            id
            branches {
                id
                color
                condition
                contract {
                    id
                    name
                }
                next {
                    id
                }
                blocking
            }
        }
    }
`;

export const updateBranchConditionMutation = gql`
    mutation UpdateBranchCondition($id: ID!, $condition: [String]) {
        updateBranchCondition(id: $id, condition: $condition) {
            id
            color
            condition
            contract {
                id
                name
            }
            next {
                id
            }
        }
    }
`;
import WidgetTypes from '../../constants/widgetTypes';
import generatePieChart from './pie';
import generateColumnLineMixChart from './columnLineMix';
import generateBubbleChart from './bubble';
import generateRadarChart from './radar';
import generateRadarTimelineChart from './radarTimeline';
import generateForceDirectTreeChart from './forceDirectTree';
import generatePieInColumnChart from './pieInColumn';
import generateStackedColumnChart from './stackedColumn';
import generateTraceableSankeyDiagram from './traceableSankeyDiagram';
import generateRfmTreemap from './rfmTreemap';
import generateLifetimeValueChart from './lifetimeValue';
import generateMultipleValueAxesChart from './multipleValueAxes';
import generateTreemap from './treemap';
import generatePieOfAPie from './pieOfAPie';
import generateHorizontalFunnel from './horizontalFunnel';
import generateCountriesMorphingToPie from './countriesMorphingToPie';

export default (divId, widget, data, args) => {
  switch (widget.type) {
    case WidgetTypes.columnLineMix:
      return generateColumnLineMixChart(divId, widget, data, args);
    case WidgetTypes.pie:
      return generatePieChart(divId, widget, data, args);
    case WidgetTypes.bubble:
      return generateBubbleChart(divId, widget, data, args);
    case WidgetTypes.radar:
      return generateRadarChart(divId, widget, data);
    case WidgetTypes.radarTimeline:
      return generateRadarTimelineChart(divId, widget, data);
    case WidgetTypes.forceDirectTree:
      return generateForceDirectTreeChart(divId, widget, data, args);
    case WidgetTypes.pieInColumn:
      return generatePieInColumnChart(divId, widget, data, args);
    case WidgetTypes.stackedColumn:
      return generateStackedColumnChart(divId, widget, data, args);
    case WidgetTypes.traceableSankeyDiagram:
      return generateTraceableSankeyDiagram(divId, widget, data, args);
    case WidgetTypes.rfmTreemap:
      return generateRfmTreemap(divId, widget, data, args);
    case WidgetTypes.lifetimeValue:
      return generateLifetimeValueChart(divId, widget, data, args);
    case WidgetTypes.multipleValueAxes:
      return generateMultipleValueAxesChart(divId, widget, data, args);
    case WidgetTypes.treemap:
      return generateTreemap(divId, widget, data, args);
    case WidgetTypes.pieOfAPie:
      return generatePieOfAPie(divId, widget, data, args);
    case WidgetTypes.horizontalFunnel:
      return generateHorizontalFunnel(divId, widget, data, args);
    case WidgetTypes.countriesMorphingToPie:
      return generateCountriesMorphingToPie(divId, widget, data, args);
    default:
      throw Error(`Unknown widget type ${widget.type}`);
  }
};

import { Col, Drawer, Row, Tag } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import CloseCircleIcon from '@2fd/ant-design-icons/lib/CloseCircle';
import CheckCircleIcon from '@2fd/ant-design-icons/lib/CheckCircle';
import RECONCILIATION_TYPE_ENUM from 'norbr-shared-lib/constants/transaction/reconciliationTypes/enum';
import AlertCircleIcon from '@2fd/ant-design-icons/lib/AlertCircle';
import useNavigateWithSearch from '../../../../../util/navigate';
import { activityMatcherQuery } from './query';
import useBreakpoint from '../../../../../hooks/useBreakpoint';
import MatcherOrderDescription from './MatcherOrderDescription';
import styles from './MatcherDrawer.module.scss';
import MatcherTransactionDescription from './MatcherTransactionDescription';
import MatcherReconciliationDetail from './MatcherReconciliationDetail';
import MatcherNotificationCandidates from './MatcherNotificationCandidates';
import Loader from '../../../Common/Loader/Loader';
import { filterTransactionalOperationsByType } from '../utils';
import { useCan } from '../../../../../contexts/ability.context';

const MatcherDrawer = () => {
  const can = useCan();
  const breakpoint = useBreakpoint();
  const { orderId, transactionId } = useParams();

  const [selectedOperationId, setSelectedOperationId] = useState();
  const navigate = useNavigateWithSearch();

  const { data, loading } = useQuery(activityMatcherQuery, { variables: { orderId }, skip: !orderId });

  const order = useMemo(() => data?.matcherActivity, [data]);
  const transaction = useMemo(
    () => order?.transactions.find((trx) => trx.id === transactionId),
    [order, transactionId],
  );
  const transactionalOperations = useMemo(
    () => transaction?.operations?.filter(filterTransactionalOperationsByType),
    [transaction],
  );
  const selectedOperation = useMemo(
    () => transactionalOperations?.find((op) => op.id === selectedOperationId),
    [transactionalOperations, selectedOperationId],
  );

  // Order state - matched / pending / unmatched
  const state = useMemo(
    () =>
      order?.transactions.reduce((accState, trx) => {
        // If we already found 'unmatched', skip the rest
        if (accState === 'unmatched') return accState;
        return trx.operations.filter(filterTransactionalOperationsByType).reduce((innerAccState, op) => {
          // If the operation is reconciled, skip it
          if (op.is_reconciled) return innerAccState;
          // If it's pending, set state to 'pending' but continue
          if (op.reconciliation_type === RECONCILIATION_TYPE_ENUM.PENDING) return 'pending';
          // If unmatched, stop further operations and transactions
          return 'unmatched';
        }, accState);
      }, 'matched'),
    [order],
  );

  useEffect(() => {
    if (transactionalOperations) {
      // init selection with the first unreconciled "transactional" operation
      const operation = transactionalOperations.find((op) => op.is_reconciled === false);
      if (operation) {
        setSelectedOperationId(operation.id);
      } else {
        setSelectedOperationId(transactionalOperations[0]?.id);
      }
    } else {
      setSelectedOperationId(undefined);
    }
  }, [transactionalOperations]);

  const handleClose = () => {
    setSelectedOperationId(undefined);
    navigate(`/brainpower/matcher-engine/${orderId}`);
  };

  if (!transactionId) return null;
  if (orderId && !order && !loading) throw new Error(`Order ${orderId} not found.`);
  if (transactionId && !transaction && !loading) throw new Error(`Transaction ${transactionId} not found.`);

  return (
    <Drawer
      open={orderId}
      title={
        <Row>
          <Col span={8}>
            <div style={{ fontWeight: 'bold' }}>MATCHER</div>
          </Col>
          <Col span={8} style={{ textAlign: 'center', margin: '-2px 0px' }}>
            {state === 'matched' && (
              <Tag className={styles.tagCell} icon={<CheckCircleIcon />}>
                <div style={{ backgroundColor: '#66c476' }} />
                <span>Order matched</span>
              </Tag>
            )}
            {state === 'pending' && (
              <Tag className={styles.tagCell} icon={<AlertCircleIcon />}>
                <div style={{ backgroundColor: '#fa9500' }} />
                <span>Order matched</span>
              </Tag>
            )}
            {state === 'unmatched' && (
              <Tag className={styles.tagCell} icon={<CloseCircleIcon />}>
                <div style={{ backgroundColor: '#ff4f4e' }} />
                <span>Unmatched order</span>
              </Tag>
            )}
          </Col>
          <Col span={8} />
        </Row>
      }
      headerStyle={{ borderBottom: 'none' }}
      contentWrapperStyle={{ width: ['xs', 'sm', 'md', 'lg'].includes(breakpoint) ? '100%' : 832 }} // responsive width
      width="100%"
      key="match-drawer"
      getContainer="#matcher-table"
      style={{ position: 'absolute', zIndex: 50 }}
      bodyStyle={{ padding: '8px 0' }}
      onClose={handleClose}
      destroyOnClose
      closable
      closeIcon={<WindowCloseIcon />}
      mask={false}
      push={false}
    >
      {loading && <Loader />}
      {data && (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <MatcherOrderDescription order={order} />
          </Col>
          <Col span={24}>
            <MatcherTransactionDescription
              transaction={transaction}
              selectedOperationId={selectedOperationId}
              setSelectedOperationId={setSelectedOperationId}
            />
          </Col>
          <Col span={24}>
            <MatcherReconciliationDetail operation={selectedOperation} />
          </Col>
          {can('create', 'matcher') && (
            <Col span={24}>
              <MatcherNotificationCandidates operation={selectedOperation} />
            </Col>
          )}
        </Row>
      )}
    </Drawer>
  );
};

export default MatcherDrawer;

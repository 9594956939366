import {gql} from '@apollo/client';

export const CompanyExecutiveQuery = gql`
  query CompanyExecutiveQuery($companyId: ID!) {
    merchantCompany(id: $companyId) {
      id
      ceo
      executive
      directors
    }
  }
`;
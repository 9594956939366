import React from 'react';
import ErrorBoundary from '../../Common/ErrorBoundary/ErrorBoundary';
import PartnerRouteList from './PartnerRouteList/PartnerRouteList';
import Page from '../../Common/Page/Page';

const PartnerRoutes = () => (
  <Page>
    <ErrorBoundary>
      <PartnerRouteList />
    </ErrorBoundary>
  </Page>
);

export default PartnerRoutes;

import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Drawer, message, Space } from 'antd';
import {
  PartnerPaymentMethodsQuery,
  MetaProgramManagerRemoveActivePartnerMutation,
  MetaProgramManagerSetActivePartnerMutation,
} from '../query';
import ProgramManagerAvatar from '../../../../../Common/ProgramManager/Avatar';
import { difference } from '../../../../../../../util/array';
import ImpactedContractsDetail from './ImpactedContractsDetail';
import SelectPaymentMethods from '../AddActivePartner/SelectPaymentMethods';

const EditActivePartner = ({ metaProgramManager, activePartner, visible, onClose, toRemove = false }) => {
  const partnerPaymentMethodsQuery = useQuery(PartnerPaymentMethodsQuery, {
    variables: { id: activePartner.partner.id },
  });

  const [setActivePartner, setActivePartnerMutation] = useMutation(MetaProgramManagerSetActivePartnerMutation, {
    variables: { id: metaProgramManager.id, partner: activePartner.partner.id },
    onCompleted: (res) => {
      if (res.metaProgramManagerSetActivePartner) {
        message.success('Partner successfully updated');
        onClose();
      }
    },
    errorPolicy: 'all',
  });

  const [removeActivePartner, removeActivePartnerMutation] = useMutation(MetaProgramManagerRemoveActivePartnerMutation, {
    variables: { id: metaProgramManager.id, partner: activePartner.partner.id },
    onCompleted: (res) => {
      if (res.metaProgramManagerRemoveActivePartner) {
        message.success('Partner successfully removed');
        onClose();
      }
    },
    errorPolicy: 'all',
  });

  const [checkedList, setCheckedList] = useState(toRemove ? [] : activePartner.activePaymentMethods.map((pm) => pm.id));

  const breakingChanges = useMemo(
    () =>
      difference(
        activePartner.activePaymentMethods.map((pm) => pm.id),
        checkedList,
      ),
    [activePartner, checkedList],
  );

  const apply = () =>
    setActivePartner({
      variables: { paymentMethods: checkedList },
    });

  const reset = () => {
    setCheckedList(activePartner.activePaymentMethods.map((pm) => pm.id));
  };

  const remove = () => removeActivePartner();

  return (
    <Drawer
      title={`${toRemove ? 'Remove' : 'Configure'} ${activePartner.partner.name} for ${metaProgramManager.name}`}
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
      extra={<ProgramManagerAvatar key="pm-avatar" programManager={metaProgramManager} />}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        {!toRemove && (
          <SelectPaymentMethods
            loading={partnerPaymentMethodsQuery.loading}
            options={partnerPaymentMethodsQuery.data?.partner.payment_methods}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
          />
        )}
        {!!breakingChanges.length && (
          <ImpactedContractsDetail
            partnerId={activePartner.partner.id}
            paymentMethods={breakingChanges}
            metaProgramManager={metaProgramManager.id}
          />
        )}
        {/* TODO - ImpactedMasterContractsDetail */}
        <Space>
          {!toRemove && (
            <Button type="text" onClick={reset}>
              Reset
            </Button>
          )}
          {!toRemove && (
            <Button type="primary" htmlType="submit" onClick={apply} loading={setActivePartnerMutation.loading}>
              Apply
            </Button>
          )}
          {checkedList.length === 0 && (
            <Button danger htmlType="submit" loading={removeActivePartnerMutation.loading} onClick={remove}>
              Remove {activePartner.partner.name} for {metaProgramManager.name}
            </Button>
          )}
        </Space>
        {setActivePartnerMutation.error && <Alert type="error" message={setActivePartnerMutation.error.message} />}
        {removeActivePartnerMutation.error && (
          <Alert type="error" message={removeActivePartnerMutation.error.message} />
        )}
      </Space>
    </Drawer>
  );
};

export default EditActivePartner;

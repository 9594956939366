import { gql } from '@apollo/client';

export const ApiKeyAddMutation = gql`
  mutation ApiKeyAddMutation($merchantAccountId: ID!, $input: ApiKeyInput!) {
    addApiKeyMerchantAccount(id: $merchantAccountId, input: $input) {
      __typename
      id
      api_keys {
        __typename
        id
        name
        status
        is_public
        is_internal
        key
      }
    }
  }
`;

export const ApiKeyUpdateMutation = gql`
  mutation ApiKeyUpdateMutation($id: ID!, $input: ApiKeyInput!) {
    updateApiKey(id: $id, input: $input) {
      __typename
      id
      name
      status
      is_public
      is_internal
      key
    }
  }
`;

export default {};

const SshAlgorithms = {
  ECDSA: 'ecdsa',
  ED25519: 'ed25519',
  RSA: 'rsa',
};

export const sshAlgorithms = {
  [SshAlgorithms.ECDSA]: { value: SshAlgorithms.ECDSA, label: 'ECDSA' },
  [SshAlgorithms.ED25519]: { value: SshAlgorithms.ED25519, label: 'ED25519' },
  [SshAlgorithms.RSA]: { value: SshAlgorithms.RSA, label: 'RSA' },
};
export const sshAlgorithmList = Object.values(sshAlgorithms);
export default SshAlgorithms;

import {gql} from '@apollo/client';

export const CompanyAddressQuery = gql`
  query CompanyAddressQuery($companyId: ID!) {
    merchantCompany(id: $companyId) {
      id
      country
      address
      city
      state
      zipCode
      attributeListRequiredByPartners
    }
  }
`;
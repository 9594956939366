import { gql, useQuery } from '@apollo/client';
import { unflattenByProp } from '../../util/array';

export const AuthorsQuery = gql`
  {
    authors {
      id
      full_name
      email
      avatar
    }
  }
`;

export const useAuthorList = () => {
  const { data } = useQuery(AuthorsQuery);
  if (!data) return [];
  return data.authors;
};

const useAuthors = () => {
  const authorList = useAuthorList();
  return unflattenByProp(authorList, 'id');
};

export default useAuthors;

import {gql} from '@apollo/client';

export const updateSsoDraftMutation = gql`
    mutation UpdateSsoDraft($id: ID!, $input: SsoConfigInput!) {
        updateCompanySsoDraft(id: $id, input: $input) {
            id
            sso {
                draft {
                    tenantId
                    providerId
                    idpEntityId
                    ssoURL
                    rpEntityId
                    x509Certificates
                }
            }
        }
    }
`;

export const applySsoConfigMutation = gql`
    mutation UpdateCompanyApplySsoDraftSso($id: ID!) {
        updateCompanyApplySsoDraft(id: $id) {
            id
            sso {
                config {
                    tenantId
                    providerId
                    idpEntityId
                    ssoURL
                    rpEntityId
                    x509Certificates
                }
                draft {
                    tenantId
                    providerId
                    idpEntityId
                    ssoURL
                    rpEntityId
                    x509Certificates
                }
            }
        }
    }
`;

export const updateCompanyLoginMethodMutation = gql`
    mutation UpdateCompanyLoginMethod($id: ID!, $input: LoginMethodInput!) {
        updateCompanyLoginMethod(id: $id, input: $input) {
            id
            loginMethod
        }
    }
`;
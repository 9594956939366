import React from 'react';
import { Typography } from 'antd';
import styles from './widget.module.scss';

const WidgetForbidden = () => (
  <div className={styles.root}>
    <div className={styles.error}>
      <Typography.Text type="danger">Permission required</Typography.Text>
    </div>
  </div>
);

export default WidgetForbidden;

import { gql } from '@apollo/client';

export const MerchantAccountListQuery = gql`
  query MerchantAccountListQuery {
    merchantAccounts {
      id
      source
      status
      name
      cms_name
      createdAt
      reference_currency
      integration_type
      userCount
      merchantContractCount
      company {
        id
        name
        status
        theme {
          primaryColor
        }
      }
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
        metaProgramManager {
          id
          name
          theme {
            icon
            primaryColor
            secondaryColor
          }
        }
      }
    }
  }
`;

export default {};

import React from 'react';
import { Result } from 'antd';

const HandleApolloError = ({ error, children }) => {
  if (error?.graphQLErrors.find((errorElem) => errorElem.extensions.code === '404')) {
    return <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;
  }
  if (error?.networkError) {
    return <Result status="500" title="500" subTitle="Sorry, something went wrong." />;
  }

  return children;
};

export default HandleApolloError;

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Avatar, Button, Form, Input, message, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import InfoTile, { InfoDateTimeRow, InfoRow, InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import { UserAvatarUpload, UserUpdateMutation } from '../query';
import styles from '../UserView.module.scss';
import CropImgUploader from '../../../../Common/Uploader/CropImgUploader';
import { useCan } from '../../../../../../contexts/ability.context';

const Profile = ({ user }) => {
  const can = useCan();
  const [editMode, setEditMode] = useState(false);

  const [uploadUserAvatar] = useMutation(UserAvatarUpload, {
    refetchQueries: ['UserViewQuery'],
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleUpload = (file) => {
    uploadUserAvatar({ variables: { id: user.id, file } });
  };

  const [updateUser, { loading }] = useMutation(UserUpdateMutation, {
    onCompleted: () => {
      message.success('User profile successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    updateUser({
      variables: { id: user.id, input: values },
    });
  };

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="user-profile" title="Profile">
        <InfoTextRow label="Email" value={user.email} />
        <Form
          form={form}
          title="Profile"
          layout="vertical"
          initialValues={user}
          onFinish={handleFinish}
          requiredMark={false}
          className="tile-form"
        >
          <Form.Item label="Firstname" name="first_name">
            <Input />
          </Form.Item>
          <Form.Item label="Lastname" name="last_name">
            <Input />
          </Form.Item>
          <Form.Item label="Phone" style={{ maxWidth: 300 }}>
            <Space>
              <Form.Item name={['phone', 'code']} noStyle>
                <Input style={{ width: 65 }} placeholder="31" prefix="+" />
              </Form.Item>
              <Form.Item name={['phone', 'number']} noStyle>
                <Input placeholder="123456789" />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <CropImgUploader
          label="Avatar"
          value={user.avatar}
          onChange={handleUpload}
          aspect={1} // ratio 1/1 - square
          info="Recommended: PNG, ratio 1/1"
        />
        <InfoDateTimeRow label="Last connection" value={user.lastConnection} />
      </InfoTile>
    );
  }

  return (
    <InfoTile
      name="user-profile"
      title="Profile"
      extra={[
        can('update', 'user') && (
          <Button key="update-user" onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        ),
      ]}
    >
      <InfoTextRow label="Email" value={user.email} />
      <InfoTextRow label="Firstname" value={user.first_name} />
      <InfoTextRow label="Lastname" value={user.last_name} />
      <InfoTextRow label="Phone" value={user.phone?.number && `+${user.phone.code} ${user.phone.number}`} />
      <InfoRow label="Avatar" value={user.avatar}>
        <Avatar src={user.avatar} size="large" />
      </InfoRow>
      <InfoDateTimeRow label="Last connection" value={user.lastConnection} />
    </InfoTile>
  );
};

export default Profile;

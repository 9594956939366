import React, { useCallback } from 'react';
import { Select, Space, Tag, TreeSelect } from 'antd';
import { compareByProp } from '../../../../../../util/array';
import { search } from '../../../../../../util/string';
import useFieldAsyncOptionList from '../../../hooks/useFieldAsyncOptionList';

const FormItemSelect = ({ field, options = field.filterOptions.options, loading = false, ...formFieldProps }) => {
  // TODO - display type + options format
  const tagRender = useCallback(
    (props) => {
      if (loading) return null;
      if (props.value === '__NULL__') return <Tag>Null</Tag>;
      const option = field.displayOptions.getOption?.(props.value) ?? options.find((o) => o.value === props.value);
      if (!option) return <Tag>{props.label}</Tag>;
      if (option.icon) {
        const Icon = option.icon;
        return <Tag icon={<Icon />}>{option.label}</Tag>;
      }
      if (option.img) {
        return field.displayOptions.hideLabel ? (
          <img
            src={option.img}
            alt=""
            style={{ height: 22, marginRight: 4, backgroundColor: option.color }}
            title={option.label}
          />
        ) : (
          <Tag
            key={props.value}
            icon={
              <img
                src={option.img}
                alt=""
                style={{ height: 21, marginRight: 4, padding: 2, borderRadius: 11, backgroundColor: option.color }}
              />
            }
            style={{ paddingLeft: 0 }}
          >
            {option.label}
          </Tag>
        );
      }
      return <Tag>{option.label}</Tag>;
    },
    [options, loading],
  );

  const labelRender = useCallback(
    (props) => {
      if (loading) return null;
      if (props.value === '__NULL__') return 'Null';
      const option = options.find((o) => o.value === props.value);
      return option ? option.label : props.label;
    },
    [options, loading],
  );

  if (field.filterOptions.treeSelect) {
    return (
      <TreeSelect
        multiple
        loading={loading}
        tagRender={tagRender}
        maxTagCount="responsive"
        allowClear
        showSearch
        treeData={[
          ...options,
          ...(field.filterOptions.nullable !== false ? [{ value: '__NULL__', label: <Tag>Null</Tag> }] : []),
        ]}
        treeCheckable
        showCheckedStrategy={TreeSelect.SHOW_CHILD}
        treeNodeFilterProp="label"
        {...formFieldProps}
      />
    );
  }

  return (
    <Select
      mode="multiple"
      loading={loading}
      tagRender={tagRender}
      maxTagCount="responsive"
      maxTagPlaceholder={(v) => <span title={v.map(labelRender).join(', ')}>+{v.length}</span>}
      allowClear
      filterOption={(searchValue, option) => search(searchValue, option.value) || search(searchValue, option.label)}
      filterSort={compareByProp('label', 'asc', 'string')}
      {...formFieldProps}
    >
      {options.map(({ value: _value, label, img, icon: Icon, color }, index) => (
        <Select.Option key={`${_value}-${index + 1}`} value={_value} label={label}>
          <Space>
            {Icon && <Icon />}
            {img && <img src={img} alt="" style={{ height: 22, backgroundColor: color }} />}
            {label}
          </Space>
        </Select.Option>
      ))}
      {field.filterOptions.nullable !== false && (
        <Select.Option key="__NULL__" value="__NULL__" label="~">
          <Tag>Null</Tag>
        </Select.Option>
      )}
    </Select>
  );
};

const FormItemList = ({ field, ...formItemProps }) => <FormItemSelect field={field} {...formItemProps} />;

export const FormItemAsyncList = ({ field, ...formItemProps }) => {
  const { options, loading } = useFieldAsyncOptionList(field);
  return <FormItemSelect field={field} options={options} loading={loading} {...formItemProps} />;
};

export default FormItemList;

import { config as dimensions } from '../Dashboard/constants/dimensions';
import fields, { checkFieldMode } from '../Common/constants/fields';
import { config as indicators } from '../Dashboard/constants/indicators';
import { DataRoles } from '../Matcher/MatcherCashier/fields';

const OverviewFields = (mode) => ({
  ...Object.values(dimensions)
    // include only dimensions that could redirect to a filter
    .filter((d) => d.field)
    // check field mode (remove PM and/or Company fields for PM/Merchant users)
    .filter((d) => checkFieldMode(d.field, mode))
    .reduce(
      (memo, d) => ({
        ...memo,
        [d.id]: { ...(d.field ? fields[d.field] : {}), ...d, key: d.id, dataRole: DataRoles.DIMENSION },
      }),
      {},
    ),
  ...Object.values(indicators).reduce(
    (memo, i) => ({ ...memo, [i.id]: { ...i, key: i.id, dataRole: DataRoles.INDICATOR } }),
    {},
  ),
});

export default OverviewFields;

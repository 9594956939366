import { gql } from '@apollo/client';

export const programManagerContactsQuery = gql`
  query ProgramManagerContacts($id: ID!) {
    programManager(id: $id) {
      id
      name
      contacts {
        _id
        firstname
        surname
        email
        phone {
          code
          number
        }
        title
        type
        priorityLevel
      }
    }
  }
`;

export const removeProgramManagerContactMutation = gql`
  mutation RemoveProgramManagerContact($id: ID!, $contactId: ID!) {
    removeProgramManagerContact(id: $id, contactId: $contactId) {
      id
      contacts {
        _id
        firstname
        surname
        email
        phone {
          code
          number
        }
        title
        type
        priorityLevel
      }
    }
  }
`;

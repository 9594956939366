import React, { useState } from 'react';
import { Button, Form, Input, message, Select, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { CompanyUpdateMutation } from '../../../Company/query';
import InfoTile, { InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import styles from './ProgramManagerCompanyExecutive.module.scss';
import { useCan } from '../../../../../../contexts/ability.context';
import { programManagerCompanyExecutiveQuery } from './query';

const ProgramManagerCompanyExecutive = ({ id }) => {
  const can = useCan();
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState(false);

  const { data: { programManager: { company } } = { programManager: { company: null } } } = useQuery(
    programManagerCompanyExecutiveQuery,
    {
      variables: {
        id,
      },
    },
  );

  const [updateCompany, { loading }] = useMutation(CompanyUpdateMutation, {
    onCompleted: () => {
      message.success('Company successfully updated');
      setEditMode(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleFinish = (values) => {
    updateCompany({
      variables: { id: company?.id, input: values },
    });
  };

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="program-manager-company-executive" title="Executive" className={styles.root}>
        <Form
          form={form}
          title="Executive"
          layout="vertical"
          initialValues={company}
          onFinish={handleFinish}
          requiredMark={false}
          className="tile-form"
        >
          <Form.Item label="Executives" name="executive">
            <Select mode="tags" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="CEO" name="ceo">
            <Input />
          </Form.Item>
          <Form.Item label="Directors" name="directors">
            <Select mode="tags" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  // return info tile
  return (
    <InfoTile
      name="program-manager-company-executive"
      title="Executive"
      extra={
        can('update', 'program-manager') && (
          <Button onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        )
      }
      className={styles.root}
    >
      <InfoTextRow label="Executives" value={company?.executive?.join(', ')} />
      <InfoTextRow label="CEO" value={company?.ceo} />
      <InfoTextRow label="Directors" value={company?.directors?.join(', ')} />
    </InfoTile>
  );
};

export default ProgramManagerCompanyExecutive;

import React from 'react';

const AppLoading = () => (
  <div id="app-loading">
    <div className="lds-ellipsis">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default AppLoading;

import { gql } from '@apollo/client';

export const metaProgramManagerCompanyActivityQuery = gql`
  query MetaProgramManagerCompanyActivity($id: ID!) {
    metaProgramManager(id: $id) {
      id
      company {
        activity
        specialization
        mcc
        website
        legalForm
        dateStart
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const programManagerCompanyActivityQuery = gql`
  query ProgramManagerCompanyActivity($id: ID!) {
    programManager(id: $id) {
      id
      company {
        id
        activity
        specialization
        mcc
        website
        legalForm
        dateStart
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const ProgramManagerApiKeysQuery = gql`
  query ProgramManagerApiKeys($programManagerId: ID!) {
    programManager(id: $programManagerId) {
      id
      apiKeys {
        __typename
        id
        name
        status
        key
      }
    }
  }
`;

export const ProgramManagerApiKeyAddMutation = gql`
  mutation ProgramManagerApiKeyAdd($programManagerId: ID!, $input: ProgramManagerApiKeyInput!) {
    addProgramManagerApiKey(id: $programManagerId, input: $input) {
      __typename
      id
      apiKeys {
        __typename
        id
        name
        status
        key
      }
    }
  }
`;

export const ProgramManagerApiKeyUpdateMutation = gql`
  mutation ProgramManagerApiKeyUpdate($id: ID!, $input: ProgramManagerApiKeyInput!) {
    updateProgramManagerApiKey(id: $id, input: $input) {
      __typename
      id
      name
      status
      key
    }
  }
`;

export default {};

import { gql } from '@apollo/client';

export const contractsByPartnerQuery = gql`
  query ContractsByPartner($partner: ID!, $merchantAccountId: ID!) {
    merchantAccount(id: $merchantAccountId) {
      id
      merchant_contracts(
        filters: { partner: $partner, merchant_account: $merchantAccountId, status: { in: ["active", "inactive"] } }
      ) {
        id
        name
        source
        status
        is_demo
      }
    }
  }
`;

import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { ExclamationCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import CloseCircleIcon from '@2fd/ant-design-icons/lib/CloseCircle';
import { useGdpr } from '../../../../../../contexts/me.context';
import FieldTypes from '../../constants/fieldTypes';
import usePage from '../../../hooks/usePage';
import FormItemList, { FormItemAsyncList } from './FormItemList';
import FormItemText from './FormItemText';
import FormItemDate from './FormItemDate';
import FormItemNumber from './FormItemNumber';
import FormItemBoolean from './FormItemBoolean';
import styles from './FormItem.module.scss';

const FormItemBuilder = React.forwardRef(({ fieldKey, onRemove }, ref) => {
  const page = usePage();
  const isGdpr = useGdpr();

  const field = page.fields[fieldKey];
  if (!field) return null;

  let formFilter;
  let formItemProps;
  let hasExcludedField = true;
  switch (field.type) {
    case FieldTypes.ASYNC_LIST:
      formFilter = <FormItemAsyncList field={field} />;
      break;
    case FieldTypes.LIST:
      formFilter = <FormItemList field={field} />;
      break;
    case FieldTypes.AMOUNT:
    case FieldTypes.NUMBER:
    case FieldTypes.COUNT:
      formFilter = <FormItemNumber field={field} />;
      break;
    case FieldTypes.TEXT:
    case FieldTypes.CUSTOM:
      formFilter = <FormItemText field={field} />;
      break;
    case FieldTypes.DATE:
      formFilter = <FormItemDate field={field} />;
      hasExcludedField = false;
      break;
    case FieldTypes.BOOLEAN:
      formFilter = <FormItemBoolean field={field} />;
      hasExcludedField = false;
      break;
    default:
      throw new Error(`Field type ${field.type} do not exist.`);
  }

  const disabledByEntity = !page.searchEntities.includes(field.entity);
  const disabledByGdpr = !isGdpr && field.gdpr;
  const disabled = disabledByEntity || disabledByGdpr;

  return (
    <Form.Item
      ref={ref}
      label={field.filterLabel ?? field.label}
      name={fieldKey}
      tooltip={{
        title: (
          <span>
            {disabledByEntity && [`Not applicable on ${page.label} module.`, <br />]}
            {disabledByGdpr && [`Not applicable without access to personal data.`, <br />]}
            {field.description}
          </span>
        ),
        icon: disabled ? <ExclamationCircleFilled style={{ color: 'var(--ant-error-color)' }} /> : <InfoCircleFilled />,
      }}
    >
      <Input.Group compact className={styles.inputGroup} style={{ width: '100%' }}>
        {hasExcludedField && (
          <Form.Item noStyle name={[fieldKey, 'excluded']}>
            <Select
              style={{ width: 100 }}
              options={[
                { value: false, label: 'IN' },
                { value: true, label: 'NOT IN' },
              ]}
            />
          </Form.Item>
        )}
        <Form.Item name={[fieldKey, 'value']} noStyle {...formItemProps}>
          {formFilter}
        </Form.Item>
        {!!onRemove && (
          <Button
            icon={<CloseCircleIcon />}
            onClick={onRemove}
            type="text"
          />
        )}
      </Input.Group>
    </Form.Item>
  );
});

export default FormItemBuilder;
